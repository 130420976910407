export const TEXT_PRESENCE = "TEXT_PRESENCE";
export const TEXT_NOT_PRESENTE = "TEXT_NOT_PRESENTE";
export const ASSERT_CHECKED = "ASSERT_CHECKED";
export const ASSERT_NOT_CHECKED = "ASSERT_NOT_CHECKED";
export const ASSERT_ALERT = "ASSERT_ALERT";
export const ASSERT_CONFIRMATION = "ASSERT_CONFIRMATION";
export const ASSERT_PROMPT = "ASSERT_PROMPT";
export const ELEMENT_PRESENCE = "ELEMENT_PRESENCE";
export const EQUALS = "EQUALS";
export const ASSERT_EDITABLE = "ASSERT_EDITABLE";
export const ASSERT_NOT_EDITABLE = "ASSERT_NOT_EDITABLE";
export const ASSERT_SELECTED_VALUE = "ASSERT_SELECTED_VALUE";
export const ASSERT_NOT_SELECTED_VALUE = "ASSERT_NOT_SELECTED_VALUE";
export const ASSERT_SELECTED_LABEL = "ASSERT_SELECTED_LABEL";
export const ASSERT_TITLE = "ASSERT_TITLE";
export const ASSERT_VALUE = "ASSERT_VALUE";