import React from "react";
import {FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(4),
		minWidth: 120
	}
}));

const NormalUserAddressFormJSX = props => {
	const intl = useIntl();
	const classes = useStyles();

	const showAllFields = props.loggedInUser !== null;

	return <>
		{showAllFields && <h2 className='text-center'>
			<FormattedMessage id='REGISTRATION.ADDRESS.TITLE' defaultMessage='Billing Address'/>
		</h2>}

					{/*<div className = "form-group fv-plugins-icon-container col-md-4 col-xs-12">*/}
					{/*	<label ><FormattedMessage id='REGISTRATION.LABEL.STREET.NUMBER'*/}
					{/*	                          defaultMessage='Street Number'/></label>*/}
					{/*	<input*/}
					{/*		placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.STREET.NUMBER',*/}
					{/*			defaultMessage: 'Street Number' })}*/}
					{/*		className = {`form-control form-control-solid h-auto py-5 px-6 `}*/}
					{/*		name = "streetNumber"*/}
					{/*		value = {props.streetNumber.value}*/}
					{/*		onChange = {props.streetNumberChangeHandler}*/}
					{/*		onBlur={() => props.syncValueWithParentContainer('streetNumber')}*/}
					{/*	/>*/}
					{/*	{!props.streetNumber.valid ?*/}
					{/*		(*/}
					{/*			<div className = "fv-plugins-message-container ml-3">*/}
					{/*				<div className = "fv-help-block">*/}
					{/*					{props.streetNumber.validationMessage}*/}
					{/*				</div>*/}
					{/*			</div>*/}
					{/*		) : null}*/}
					{/*</div>*/}
		{showAllFields && <div className = "form-group fv-plugins-icon-container">
						<label ><FormattedMessage id='REGISTRATION.LABEL.STREET'
						                          defaultMessage='Street'/></label>
						<input
							placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.STREET',
								defaultMessage: 'Street' })}
							className = {`form-control form-control-solid h-auto py-5 px-6 `}
							name = "route"
							value = {props.route.value}
							onChange = {props.routeChangeHandler}
							onBlur={() => props.syncValueWithParentContainer('route')}
						/>
						{!props.route.valid ?
							(
								<div className = "fv-plugins-message-container ml-3">
									<div className = "fv-help-block">
										{props.route.validationMessage}
									</div>
								</div>
							) : null}
					</div>
				}

		{showAllFields && <div className = "form-group fv-plugins-icon-container">
					<label ><FormattedMessage id='REGISTRATION.LABEL.CITY'
					                          defaultMessage='City'/></label>
					<input
						placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.CITY',
							defaultMessage: 'City' })}
						className = {`form-control form-control-solid h-auto py-5 px-6 `}
						name = "locality"
						value = {props.locality.value}
						onChange = {props.localityChangeHandler}
						onBlur={() => props.syncValueWithParentContainer('locality')}
					/>
					{!props.locality.valid ?
						(
							<div className = "fv-plugins-message-container ml-3">
								<div className = "fv-help-block">
									{props.locality.validationMessage}
								</div>
							</div>
						) : null}
				</div>}

		{showAllFields && <div className = "form-group fv-plugins-icon-container">
					<label ><FormattedMessage id='REGISTRATION.LABEL.ZIP'
					                          defaultMessage='Postal Code'/></label>
					<input
						placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.ZIP',
							defaultMessage: 'Postal Code' })}
						className = {`form-control form-control-solid h-auto py-5 px-6 `}
						name = "postalCode"
						value = {props.postalCode.value}
						onChange = {props.postalCodeChangeHandler}
						onBlur={() => props.syncValueWithParentContainer('postalCode')}
					/>
					{!props.postalCode.valid ?
						(
							<div className = "fv-plugins-message-container ml-3">
								<div className = "fv-help-block">
									{props.postalCode.validationMessage}
								</div>
							</div>
						) : null}
				</div>}

		{showAllFields && <div className = "form-group fv-plugins-icon-container">
					<label ><FormattedMessage id='REGISTRATION.LABEL.STATE'
					                          defaultMessage='State'/></label>
					<input
						placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.STATE',
							defaultMessage: 'State' })}
						className = {`form-control form-control-solid h-auto py-5 px-6 `}
						name = "state"
						value = {props.state.value}
						onChange = {props.stateChangeHandler}
						onBlur={() => props.syncValueWithParentContainer('state')}
					/>
					{!props.state.valid ?
						(
							<div className = "fv-plugins-message-container ml-3">
								<div className = "fv-help-block">
									{props.state.validationMessage}
								</div>
							</div>
						) : null}
				</div>}


				<div className = "form-group fv-plugins-icon-container">
					<FormControl className = {`form-control form-control-solid h-auto py-5 px-6`}
					             error = {!props.country.valid}>
						<InputLabel htmlFor = "select-country">
							<FormattedMessage id='REGISTRATION.LABEL.COUNTRY'
							                  defaultMessage='Country'/></InputLabel>
						<Select
							value = {props.country.selected}
							onChange = {props.countrySelectHandler}
							input = {<Input id = "select-country"/>}>

							{props.country.list.map(country => {
								return <MenuItem key = {country.alpha2Code}
								                 value = {country}>{country.name}</MenuItem>
							})}
						</Select>
						<FormHelperText>{props.country.validationMessage}</FormHelperText>
					</FormControl>
				</div>
			</>;
}

export default NormalUserAddressFormJSX;