import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import Display from "../../components/Project/Display";
import * as Role from "../Users/enum/Roles";

const Project = props => {
	const intl = useIntl();
	const [usersExceptAdmin, setUsersExceptAdmin] = useState(null);

	useEffect(() => {
		props.onLoadProject(props.match.params.projectID);
	}, [props.match.params.projectID]);

	useEffect(() => {
		if(localStorage.getItem('Top-Role') === Role.ROLE_MANAGER || localStorage.getItem('Top-Role') === Role.ROLE_ADMIN ||
			localStorage.getItem('Top-Role') === Role.ROLE_SUPER){
			if(!Array.isArray(props.users)){
				props.onLoadAllUsers(localStorage.getItem("username"));
			}

			if(Array.isArray(props.users)){
				// remove admin
				const usersTemp = [];
				for(let user of props.users){
					if(user.login !== localStorage.getItem('username')){
						usersTemp.push(user);
					}
				}
				// sort by role
				usersTemp.sort((first, second) => {
					if(first.authorities.length >= second.authorities.length){
						return -1;
					}
					return 1;
				})
				setUsersExceptAdmin(usersTemp);
			}
		}
	}, [props.users]);

	useEffect(() => {
		if (props.project) {
			document.title = intl.formatMessage({
					id: "PAGE.TITLE.PROJECT",
					defaultMessage: 'Project: '
				})
				+ props.project.label;
		}
	}, [props.project]);

	const deleteProject = () => {
		const redirect = '/projects';
		const isPreferredProject = props.loggedInUser.preferedProjectId === props.project.id;

		// here this qualifie as null
		// if preferred then: pref must be updated. to:
		// 1: if projects is empty, then null.
		// 2: if projects is not empty, then select first that is not prefe, redirect to /projects
		// not preferred: select first when done
		let newPreferred = null;
		if (props.projects.length > 1) {
			for (let project of props.projects) {
				if (project.id !== props.project.id) {
					newPreferred = project;
					break;
				}
			}
		}

		// setting loggedInUser pref
		const loggedInUser = {...props.loggedInUser};
		if (isPreferredProject) {
			if (newPreferred === null) {
				loggedInUser.preferedProjectId = null;
				loggedInUser.preferedProjectLabel = null;
				loggedInUser.preferedProjectType = null;
			} else {
				loggedInUser.preferedProjectId = newPreferred.id;
				loggedInUser.preferedProjectLabel = newPreferred.label;
				loggedInUser.preferedProjectType = newPreferred.type;
			}
		}
		props.onDeleteProject(props.project.id, redirect, isPreferredProject, newPreferred, loggedInUser);
	}

	const toggleExempt = (project) => {
		props.onToggleExemptProject(project.id, project.exempt);
	}

	const suggestDefault = new URLSearchParams(props.location.search).get('suggestDefault') === "true";

	return <Display project={props.project}
					error={props.error}
					delete={deleteProject}
					disableToggle={toggleExempt}
					showDescription={true}
					return={true}
					suggestDefault={suggestDefault}
					loggedInUser={props.loggedInUser}
					onUpdateUser={props.onUpdateUser}
					// onSetProject={props.onSetProject}
					users={usersExceptAdmin}
					onUpdatePreferred={props.onUpdatePreferred}
	/>;
}

const mapStateToProps = state => {
	return {
		project: state.project.projectView,
		// projectViewLoading
		error: state.project.projectViewError,
		projects: state.project.projects, // Toggle Refresh
		loggedInUser: state.authentication.loggedInUser,
		users: state.users.allUsers, // to show user info based on username
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadProject: (id) => dispatch(actions.loadProject(id, true)),
		onDeleteProject: (id, redirect) => dispatch(actions.deleteProject(id, redirect)),
		onToggleExemptProject: (id, exempt) => dispatch(actions.toggleExemptProject(id, exempt)),
		// onUpdateUser: (user) => dispatch(actions.updateUser(user, false, true)),
		// onSetProject: (project) => dispatch(actions.loadProjectSuccess(project)), // TODO Needed??? onUpdatePreferred take care of it
		onLoadAllUsers: (username) => dispatch(actions.loadAllUsers(username)),
		onUpdatePreferred: (userId, preferredId) => dispatch(actions.updatePreferredProject(userId, preferredId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);