import React from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import SprintsSection from "./SprintsSection";

const Sprints = (props) => {

	return (
		<Container className = 'p-0'>
			<Row>
				<Col sm = {12} md = {6}>
					<SprintsSection {...props}/>
				</Col>
				<Col>
					{/*{domainColumn}*/}
				</Col>
			</Row>
		</Container>
	);
}

const mapStateToProps = state => {
	return {

	};
};

const mapDispatchToProps = dispatch => {
	return {

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sprints);