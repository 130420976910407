import React, {useState} from "react";
import {Column} from "primereact/column";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {FormattedMessage, useIntl} from "react-intl";
import SVG from "react-inlinesvg";
import {Alert} from "@material-ui/lab";

import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../../UI/DeletionConfirmationDialog";
import * as actions from "../../../store/actions";

const DeviceGroupManagement = props => {
	const intl = useIntl();

	const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] = useState(false);
	const [deletionCandidat, setDeletionCandidat] = useState(null);

	const displayDeletionConfirmationDialog = () => {
		setShowDeletionConfirmationDialog(true);
	}

	const hideDeletionConfirmationDialog = () => {
		setShowDeletionConfirmationDialog(false);
		setDeletionCandidat(null);
	}

	const deleteGroup = (id) => {
		// back
		props.onDeleteDeviceGroup(id);
	}

	const hideDeletionError = () => {
		props.onNullifyDeletionDeviceGroupError();
	}

	const actionsBodyTemplate = (rowData) => {
		return <>
			{/*<UpdateDeviceGroupDialog groupID={rowData.id}/>*/}
			{/*<Link to={`/device-group/${rowData.id}/edit`}*/}
			{/*	  title={intl.formatMessage({*/}
			{/*		  id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.EDITTTT',*/}
			{/*		  defaultMessage: 'Edit Group'*/}
			{/*	  })}*/}
			{/*	  className="btn btn-icon btn-light btn-hover-primary btn-sm m-2"*/}
			{/*>*/}
			{/*        <span className="svg-icon svg-icon-md svg-icon-primary">*/}
			{/*          <SVG*/}
			{/*			  src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}*/}
			{/*			  title={intl.formatMessage({*/}
			{/*				  id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.EDITTTT',*/}
			{/*				  defaultMessage: 'Edit Group'*/}
			{/*			  })}*/}
			{/*		  />*/}
			{/*        </span>*/}
			{/*</Link>*/}
			<a
				title={intl.formatMessage({
					id: 'DEVICE.GROUP.MANAGEMENT.TABLE.ACTION.BUTTON.DELETE', defaultMessage: 'Delete Group'
				})}
				className="btn btn-icon btn-light btn-hover-danger btn-sm m-2"
				onClick={(event) => {
					event.preventDefault();
					setDeletionCandidat(rowData);
					displayDeletionConfirmationDialog();
				}}
			>
				<span className="svg-icon svg-icon-md svg-icon-danger">
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
						 title={intl.formatMessage({
							 id: 'DEVICE.GROUP.MANAGEMENT.TABLE.ACTION.BUTTON.DELETE', defaultMessage: 'Delete Group'
						 })}/>
				</span>
			</a>

		</>
	}

	const devicesNamesBodyTemplate = (rowData) => {
		return rowData.devices.map(dev => <span key={dev.id} className="label label-lg label-light-primary label-inline m-2 font-weight-bold">
			{dev.name}</span>);
	}

	// TODO remove this, because this component is embedded in UpdateProject
	if (props.project === null) {
		return null;
	}

	return <div className='bg-white rounded p-3'>
		{props.deletionDeviceGroupError && <Alert severity="error" className="mb-3" onClose={() => hideDeletionError()}>
			{props.deletionDeviceGroupError}
		</Alert>}
		<DataTable value={props.project.deviceGroups} className=''>

			<Column field="name" columnKey='name'
					header={intl.formatMessage({id: "DEVICE.GROUP.MANAGEMENT.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
					sortable filter
					filterPlaceholder={intl.formatMessage({id: "DEVICE.GROUP.MANAGEMENT.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER", defaultMessage: 'Search by name'})}
					filterMatchMode='contains'
			/>

			<Column field="devices" columnKey='devices' body={devicesNamesBodyTemplate}
					header={intl.formatMessage({id: "DEVICE.GROUP.MANAGEMENT.TABLE.COLUMN.DEVICES", defaultMessage: 'Devices'})}
			/>

			<Column header={intl.formatMessage({id: 'DEVICE.GROUP.MANAGEMENT.TABLE.COLUMN.ACTIONS', defaultMessage: 'Actions'})}
					body={actionsBodyTemplate}
					className='p-text-right'
					headerStyle={{width: '13rem'}}
			/>
		</DataTable>
		<DeletionConfirmationDialog open={showDeletionConfirmationDialog} closeDialog={hideDeletionConfirmationDialog}
									message={<FormattedMessage id="DEVICE.GROUP.MANAGEMENT.REMOVE.MESSAGE"
															   defaultMessage='Remove Device Group'/>}
									name={deletionCandidat ? `${deletionCandidat.name}` : ''}
									deleteAction={() => {
										hideDeletionConfirmationDialog();
										deleteGroup(deletionCandidat.id);
									}}
		/>
	</div>
}

const mapStateToProps = state => {
	return {
		project: state.project.projectView,
		deletionDeviceGroupError: state.project.deletionDeviceGroupError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		// onLoadDevices: () => dispatch(actions.loadDevices()),
		onDeleteDeviceGroup: (id) => dispatch(actions.deleteDeviceGroup(id)),
		onNullifyDeletionDeviceGroupError: () => dispatch(actions.nullifyDeletionDeviceGroupError()),

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroupManagement);