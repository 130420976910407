import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import SprintsSection from "../Sprint/SprintsSection";
import IterationDisplayTabs from "../../components/Iteration/IterationDisplayTabs";

const Iteration = (props) => {
	const intl = useIntl();

	const [suites, setSuites] = useState([]); // props.iteration ? props.iteration.suites :

	const [iteration, setIteration] = useState({id: 0}); // props.iteration ? props.iteration :

	const [previousSuites, setPreviousSuites] = useState([]);
	const [previousIteration, setPreviousIteration] = useState({id: 0});

	useEffect(() => {
		props.onLoadIteration(props.match.params.iterationID);
	}, [props.match.params.iterationID]);

	useEffect(() => {
		props.onLoadIteration(props.match.params.iterationID);
	}, []);

	useEffect(() => {
		if (props.iteration) {
			setIteration(props.iteration);
			setPreviousIteration(props.iteration)
			if(Array.isArray(props.iteration.suites)){
				setSuites(props.iteration.suites);
				setPreviousSuites(props.iteration.suites);
			}
			document.title = intl.formatMessage({
				id: "PAGE.TITLE.ITERATION",
				defaultMessage: 'Iteration: '
			}) + props.iteration.label;
		}
	}, [props.iteration]);

	useEffect(() => {
		if (props.error) {
			setIteration(previousIteration);
			setSuites(previousSuites);
		}
	}, [props.error]);

	const deleteSuite = id => {
		const updatedIteration = {...iteration};
		const updatedSuites = [...updatedIteration.suites];

		for (let suite of iteration.suites) {
			if (suite.id === id) {
				updatedSuites.splice(iteration.suites.indexOf(suite), 1);
				break;
			}
		}

		const patchIteration = {
			suites: {value: updatedSuites}
		};
		updatedIteration.suites = updatedSuites;
		setSuites(updatedSuites);
		setIteration(updatedIteration);
		props.onUpdateIteration(props.match.params.iterationID, patchIteration);
		props.onProjectSuites(props.project.id);
	}

	const [refreshIteration, setRefreshIteration] = useState(false);

	// TODO optimize: make the call only when success is returned, not right away
	useEffect(() => {
		if(refreshIteration === true && (props.updatingLoading === false)){
			props.onLoadIteration(props.match.params.iterationID);
			setRefreshIteration(false);
		}
	}, [refreshIteration, props.updatingLoading])

	const updateSuites = (suites) => {
		const id = props.iteration.id;
		const suitesPatch = {suites: {value: suites}};
		props.onUpdateIteration(id, suitesPatch);
		setRefreshIteration(true);
	}

	useEffect(() => {
		if(iteration.id !== 0){
			setRefreshIteration(true);
		}
	}, [props.project])

	const updateIteration = (updatedIteration) => {
		setIteration(updatedIteration);
	}

	return (
		<Container className = 'p-0'>
			<Row>
				<Col sm = {12} md = {3}>
					<SprintsSection {...props}/>
				</Col>
				<Col>
					<IterationDisplayTabs {...props}
					                      iteration = {iteration}
					                      suites = {suites}
					                      delete = {deleteSuite}
					                      updateSuites = {updateSuites}
					                      setSuites = {setSuites}
					                      updateIteration = {updateIteration}
					                      sprint = {props.sprint}
										  error = {props.error}
					/>
				</Col>
			</Row>
		</Container>
	);
}

const mapStateToProps = state => {
	return {
		sprint: state.sprint.sprint,
		iteration: state.iteration.iteration,
		error: state.iteration.updatingError,
		updatingLoading: state.iteration.updatingLoading,
		project: state.project.project,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadIteration: (id) => dispatch(actions.loadIteration(id)),
		onUpdateIteration: (id, iteration) => dispatch(actions.updateIteration(id, iteration)),
		onProjectSuites: (id) => dispatch(actions.loadProjectSuites(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Iteration);