import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import ReportDisplay from "../../components/Report/ReportDisplay";

const Report = props => {
	const intl = useIntl();

	useEffect(() => {
		props.onLoadReport(props.match.params.iterationID);
		document.title = intl.formatMessage({id: "PAGE.TITLE.REPORT", defaultMessage: 'Report'});
	}, [props.match.params.iterationID]);

	// TODO if report is null = error, then show error message instead in table
	return <ReportDisplay report={props.report} project={props.project}
						  iterationID={props.match.params.iterationID}
						  sprint={props.sprint}
						  onDownloadScreenshot={props.onDownloadScreenshot}
						  imageBlob={props.imageBlob}
						  imagekey={props.imagekey}
	/>
}

const mapStateToProps = state => {
	return {
		report: state.report.report,
		imageBlob: state.report.imageBlob,
		imagekey: state.report.imagekey,
		project: state.project.project,
		sprint: state.sprint.sprint
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadReport: (id) => dispatch(actions.loadReport(id)),
		onDownloadScreenshot: (imagekey) => dispatch(actions.downloadScreenshot(imagekey)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);