import React, {useEffect, useState} from "react";
import {Button, Snackbar} from "@material-ui/core";
import {default as BButton} from "react-bootstrap/Button";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";

import {history} from "../../../redux/store";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));
const UserActions = props => {
	const intl = useIntl();
	const classes = useStyles();

	// Handle updating/updated here
	const [userUpdateSnackbarOpen, setUserUpdateSnackbarOpen] = useState(false);
	const [userUpdateSnackbarMessage, setUserUpdateSnackbarMessage] = useState('');
	const [deleteMyAccountConfirmationDialogOpen, setDeleteMyAccountConfirmationDialogOpen] = useState(false);

	useEffect(() => {
		if(props.loading === true && props.type === 'update'){
			showUserSnackbar(intl.formatMessage({ id: "USER.SNACKBAR.UPDATING",
				defaultMessage: 'Updating User'}));
		}
		if (!props.loading && userUpdateSnackbarOpen) {
			showUserSnackbar(intl.formatMessage({ id: "USER.SNACKBAR.UPDATED",
				defaultMessage: 'User Updated'}));
		}
	}, [props.loading]);

	const showUserSnackbar = (message) => {
		setUserUpdateSnackbarOpen(true);
		setUserUpdateSnackbarMessage(message);
	}

	const hideUserSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setUserUpdateSnackbarOpen(false);
	}

	const closeDeleteMyAccountConfirmationDialog = () => {
		setDeleteMyAccountConfirmationDialogOpen(false);
	};

	return <>
		<div className={classes.buttons}>
			<Button variant = "contained" type = "submit" className='mr-6' 
			        disabled={props.loading}>
				{props.type === 'create' ?
					<FormattedMessage id="BUTTON.CREATE" defaultMessage='Create'/> :
					<FormattedMessage id="BUTTON.UPDATE" defaultMessage='Update'/>}
			</Button>
			<Button variant = "contained" className='mr-6'
			        onClick = {(event) => {
				        event.preventDefault();
						if(history.location.search === '?incompleteProfile=true'){
							history.push('/dashboard');
						} else {
							history.goBack();
						}
			        }}>
				{props.type === 'create' ?
					<FormattedMessage id="BUTTON.CANCEL" defaultMessage='Cancel'/> :
					<FormattedMessage id="BUTTON.BACK" defaultMessage='Back'/>}
			</Button>

			{props.myAccount && <BButton variant="outline-danger" className='float-right' onClick = {(event) => {
				// setToBeRemovedRequest(rowData);
				setDeleteMyAccountConfirmationDialogOpen(true);
			}}>
				<FormattedMessage id="USER.REMOVE.ACCOUNT" defaultMessage='Remove Account'/>
			</BButton>}

		</div>

		<Snackbar
			anchorOrigin = {{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open = {userUpdateSnackbarOpen}
			autoHideDuration = {2000}
			onClose = {hideUserSnackbar}
			message = {userUpdateSnackbarMessage}
		>
			<Alert onClose = {hideUserSnackbar} severity = "success">
				{userUpdateSnackbarMessage}
			</Alert>
		</Snackbar>
		<DeletionConfirmationDialog open={deleteMyAccountConfirmationDialogOpen} closeDialog={closeDeleteMyAccountConfirmationDialog}
									message={<FormattedMessage id="USER.DELETE.YOUR.ACCOUNT"
															   defaultMessage='Delete Your Account'/> }
									name=''
									deleteAction={() => {
										closeDeleteMyAccountConfirmationDialog();
										props.deleteMyAccount(props.userID);
									}}
		/>
		</>
}

export default UserActions;