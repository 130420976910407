export const WEB = "WEB";
export const ANDROID_WEB = "ANDROID_WEB";
export const ANDROID = "ANDROID";
export const API = "API";

export const ProjectTypeObjects = [
	{id: 1, label: 'WEB', value: WEB},
	{id: 2, label: 'ANDROID WEB', value: ANDROID_WEB},
	{id: 3, label: 'ANDROID', value: ANDROID},
	{id: 4, label: 'API', value: API},
];

export const ProjectTypeValues = [
	WEB, ANDROID_WEB, ANDROID, API
];