import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {LoadingDialog} from "../../_metronic/_partials/controls";
import {useIntl} from "react-intl";

export const RegistrationLoadingDialog = () => {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.authentication.registrationLoading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}
	/>;
};

export const UserCreationLoadingDialog = () => {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.users.creationLoading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}
	/>;
};

export const UserUpdatingLoadingDialog = () => {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.users.updatingLoading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}
	/>;
};

export function UserLoadingDialog() {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.users.loading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}
/>;
}

export function ProjectLoadingDialog() {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.project.loading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

export function ProjectCreationLoadingDialog() {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.project.creationLoading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

export function ProjectUpdatingLoadingDialog() {
	const intl = useIntl();
	// Customers Redux state
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.project.updatingLoading}),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

export const DomainLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.domain.loading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const DomainsLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.domain.listAllLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const DomainUpdatingLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.domain.updatingLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const TestLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.test.loading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const TestUpdatingLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.test.updatingLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export function SuiteLoadingDialog() {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.suite.loading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

export function SprintsLoadingDialog() {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.sprint.listAllLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING", defaultMessage: 'Loading ...'
	})}/>;
}

export function SprintOneLoadingDialog() {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.sprint.listOneLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING", defaultMessage: 'Loading ...'
	})}/>;
}

export function SprintLoadingDialog() {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.sprint.loading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

export function IterationLoadingDialog() {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.iteration.loading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

export function SummaryChartLoadingDialog() {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.summaryChart.loading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
}

// domain, test: for all views

// export function UpgradeLoadingDialog() {
// 	// Customers Redux state
// 	const {isLoading} = useSelector(
// 		(state) => ({isLoading: state.upgrade.loading}),
// 		shallowEqual
// 	);
// 	// looking for loading/dispatch
// 	useEffect(() => {
// 	}, [isLoading]);
// 	return <LoadingDialog isLoading = {isLoading} text = "Loading ..."/>;
// 	// return isLoading ? (
// 	// 	<div className = "header-progress-bar visible fixed-top">
// 	// 		<ProgressBar variant = "danger" now = {100} animated={true}
// 	// 		             style = {{height: "10px"}}/>
// 	// 	</div>
// 	// ) : null;
// 	// return isLoading ? (<AnimateLoading isLoading = {isLoading} />) : null;
// }


export const RestRequestCreationLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.restRequest.creationLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const RestRequestUpdatingLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.restRequest.updatingLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING", defaultMessage: 'Loading ...'
	})}/>;
};

export const RestRequestsLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.restRequest.listAllLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const RestRequestDeletionLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.restRequest.deletionLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};


export const RestRequestLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.restRequest.listOneLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const DeviceGroupCreationLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.project.createDeviceGroupLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};

export const DeviceGroupUpdatingLoadingDialog = () => {
	const intl = useIntl();
	const {isLoading} = useSelector(
		(state) => ({isLoading: state.project.updateDeviceGroupLoading}),
		shallowEqual
	);
	useEffect(() => {
	}, [isLoading]);
	return <LoadingDialog isLoading = {isLoading} text = {intl.formatMessage({
		id: "LOADING",
		defaultMessage: 'Loading ...'
	})}/>;
};