import React, {useEffect, useRef, useState} from "react";
import {makeStyles, TextField} from "@material-ui/core";
import {Col, Container, Row} from "react-bootstrap";
import {Autocomplete} from "@material-ui/lab";
import {useIntl} from "react-intl";

import {changeText} from "../Shared";
import {apiKeyDefault} from "../RestRequestTabAuthentication";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(4),
		minWidth: 120
	}
}));
const AuthTokenForm = props => {
	const intl = useIntl();
	const classes = useStyles();

	const mounted = useRef(false);

	useEffect(() => {
		// mounted:
		mounted.current = true;
		// submit clicked and auth type is basic
		if(props.validate.token === true){
			checkAllInputsValidity();
		}
		return () => mounted.current = false;
	}, []);

	const [token, setToken] = useState({
		value: props.token,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 5000
		},
		valid: true,
		validationMessage: null
	});

	useEffect(() => {
		if(props.token){
			const updatedToken = {...token};
			updatedToken.value = props.token;
			setToken(updatedToken);
		}
	}, [props.token]);

	useEffect(() => {
		if(props.isVariable){
			const updatedToken = {...token}
			updatedToken.valid = true;
			updatedToken.validationMessage = null;
			setToken(updatedToken);
		}
	}, [props.isVariable]);

	// const [isVariable, setIsVariable] = useState(false);

	const tokenChangeHandler = (event) => {
		if(event !== null){ // onLoad First Time
			// props.setIsVariable(false);
			const updatedToken = changeText(token, event.target.value, 'token', intl);
			setToken(updatedToken);
		}

	}

	const tokenVariableSelectionHandler = (event, newValue) => {
		if(newValue === null || !props.restVariables.includes(newValue)){
			newValue = '';
		}
		// props.setIsVariable(true);
		props.setTokenVariable(newValue);
		props.createAuthObject('','',token.value, apiKeyDefault, newValue);
	}

	const onBlur = () => {
		props.setToken(token.value);
		props.createAuthObject('','',token.value);
	}

	const checkAllInputsValidity = () => {
		let allValid = true;

		if(!props.isVariable){
			const updatedToken = changeText(token, token.value, 'token', intl);
			if(mounted.current === true ){
				setToken(updatedToken);
			}
			allValid = allValid && updatedToken.valid;
		}

		return allValid;

	}
	props.registerValidateAuthTokenFormCallback(checkAllInputsValidity);

	let form;
	if(props.forSuite === true){
		form = <div className="mb-3">
			<div className="mb-2">
				<div className="form-group">
					<div className="row">
						<div className="col-lg-12">
							<label className="option">
										<span className="option-control">
											<span className="radio radio-outline">
												<input type="radio" name="token_type" value={props.isVariable} checked={props.isVariable}
													   onChange={() => props.setIsVariable(true)}/>
												<span/>
											</span>
										</span>
								<Autocomplete
									id="tokenVariable"
									value={props.tokenVariable}
									disabled={!props.isVariable}
									className='w-100'
									options={props.restVariables ? props.restVariables?.map((variable) => variable) : []}
									onChange={tokenVariableSelectionHandler}
									// onKeyDown = {(event) => {
									// 	if (event.key === 'Enter' || event.keyCode === 13){
									// 		// console.log('Enter Key');
									// 		console.log('event.target.value', event.target.value);
									// 		console.log('props.tokenVariable', props.tokenVariable);
									// 		tokenVariableSelectionHandler(null, token.value);
									// 	}
									// }}
									renderInput={(params) => (
										// <TextField {...params} label="variable" margin="normal" variant="outlined" />
										<TextField {...params} label="variable" variant="outlined" margin="normal"/>
										// <Select
										// 	value = {props.projectType.selected}
										// 	onChange = {props.typeSelectHandler}
										// 	input = {<Input id = "select-type"/>}
										// >
										//
										// 	{props.projectType.types.map(type => {
										// 		return <MenuItem key = {type.id}
										// 						 value = {type}>{type.label}</MenuItem>
										// 	})}
										// </Select>
										// <TextField
										// 	{...params}
										// 	margin="normal" variant="outlined"
										// 	id="token" name="token"
										// 	multiline={true}
										// 	rows={10}
										// 	label={intl.formatMessage({
										// 		id: "REST.REQUEST.FORM.AUTH.TOKEN.LABEL",
										// 		defaultMessage: 'Token'
										// 	})}
										// 	className={`${classes.textField} bg-light-o-100`}
										// 	value={token.value}
										// 	onChange={tokenChangeHandler}
										// 	onBlur={onBlur}
										// 	placeholder={intl.formatMessage({
										// 		id: "REST.REQUEST.FORM.AUTH.TOKEN.PLACEHOLDER",
										// 		defaultMessage: 'Token'
										// 	})}
										// 	error={!token.valid}
										// 	helperText={token.validationMessage}
										// />
									)}
								/>
							</label>
						</div>
						<div className="col-lg-12">
							<label className="option">
									   <span className="option-control">
										<span className="radio radio-outline">
										 <input type="radio" name="token_type" value={props.isVariable} checked={!props.isVariable}
												onChange={() => props.setIsVariable(false)}/>
										 <span></span>
										</span>
									   </span>
								<TextField
									id = "token"  name = "token"
									multiline={true}
									rows={10}
									disabled={props.isVariable}
									label = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.TOKEN.LABEL",
										defaultMessage: 'Token'})}
									className = {`${classes.textField} bg-light-o-100`}
									value = {token.value}
									onChange = {tokenChangeHandler}
									onBlur={onBlur}
									margin = "normal"
									variant = "outlined"
									placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.TOKEN.PLACEHOLDER",
										defaultMessage: 'Token'})}
									error = {!token.valid}
									helperText = {token.validationMessage}
								/>
							</label>
						</div>
					</div>
					<div className="form-check"></div>
					<div className="form-text text-muted">
					</div>
				</div>
			</div>
		</div>
	} else {
		form = <div className="mb-3">
			<div className="mb-2">
				<div className="form-group">
					<div className="row">
						<div className="col-lg-12">
								<TextField
									id = "token"  name = "token"
									multiline={true}
									rows={10}
									disabled={props.isVariable}
									label = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.TOKEN.LABEL",
										defaultMessage: 'Token'})}
									className = {`${classes.textField} bg-light-o-100`}
									value = {token.value}
									onChange = {tokenChangeHandler}
									onBlur={onBlur}
									margin = "normal"
									variant = "outlined"
									placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.TOKEN.PLACEHOLDER",
										defaultMessage: 'Token'})}
									error = {!token.valid}
									helperText = {token.validationMessage}
								/>
						</div>
					</div>
					<div className="form-check"></div>
					<div className="form-text text-muted">
					</div>
				</div>
			</div>
		</div>
	}

	return <Container className='px-0'>
		<Row>
			<Col>
				{form}
			</Col>
		</Row>
	</Container>;

}

export default AuthTokenForm;