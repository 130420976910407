import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../../axios/axios";
import {push} from "connected-react-router";

export function* loadSprints(action) {
	yield put(actions.loadSprintsStart());
	try {
		// const allSprints = [];
		// let currentPage = 0;
		// while (true) {
		// 	const response = yield axios.get(`/sprints/project/${action.projectID}?currentPage=${currentPage}`);
		// 	currentPage++;
		// 	if(response.data.content.length === 0){
		// 		break;
		// 	}
		// 	allSprints.push(...response.data.content);
		// }
		//TODO
		const response = yield axios.get(`/sprints/project/${action.projectID}`, {
			params: {...action.config}
		});
		yield put(actions.loadSprintsSuccess(response.data.content, //TODO when the back is done
			response.data.totalElements));
		// yield put(actions.loadSprintsSuccess(allSprints));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadSprintsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadSprintsFail('Error Loading Sprints, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadSprintsFail(error.message));
		}
	}
}

export function* createSprint(action) {
	yield put(actions.createSprintStart());
	try {
		const response = yield axios.post(`/sprints/project/${action.projectID}`, action.sprint);
		if (response.status === 201) {
			yield put(actions.createSprintSuccess(response.data));
			yield put(push(`/sprint/${response.data.id}`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createSprintFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createSprintFail('Error Creating Sprint, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createSprintFail(error.message));
		}
	}
}

export function* loadSprint(action) {
	yield put(actions.loadSprintStart());
	try {
		const response = yield axios.get(`/sprints/${action.id}`);
		yield put(actions.loadSprintSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadSprintFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadSprintFail('Error Loading Sprint, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadSprintFail(error.message));
		}
	}
}

export function* updateSprint(action) {
	yield put(actions.updateSprintStart());
	try {
		const response = yield axios.patch(`/sprints/${action.id}`, action.sprint);

		yield put(actions.updateSprintSuccess(action.stateSprint));
		// yield put(push(`/sprints`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateSprintFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateSprintFail('Error Updating Sprint, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateSprintFail(error.message));
		}
	}
}

export function* deleteSprint(action) {
	yield put(actions.deleteSprintStart());
	try {
		const response = yield axios.delete(`/sprints/${action.id}`);
		yield put(actions.deleteSprintSuccess(action.id));
		if(action.redirect){
			yield put(push(action.redirect));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteSprintFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteSprintFail('Error Deleting Sprint, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteSprintFail(error.message));
		}
	}
}
