import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	loading: false,
	error: null,
	lastFiveExecutions: null,
	lastExecution: null,

	dashboard: null,
	dashboardLoading: false,
	dashboardError: null,
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.LOAD_SUMMARY_CHART_DATA_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_SUMMARY_CHART_DATA_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				lastFiveExecutions: action.lastFiveExecutions,
				lastExecution: action.lastExecution
			});
		case actionTypes.LOAD_SUMMARY_CHART_DATA_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				lastFiveExecutions: null,
				lastExecution: null
			});

		case actionTypes.LOAD_DASHBOARD_STATISTIQUES_START:
			return updateObject(state, {
				dashboard: null,
				dashboardLoading: true,
				dashboardError: null,
			});
		case actionTypes.LOAD_DASHBOARD_STATISTIQUES_SUCCESS:
			return updateObject(state, {
				dashboard: action.dashboard,
				dashboardLoading: false,
			});
		case actionTypes.LOAD_DASHBOARD_STATISTIQUES_FAIL:
			return updateObject(state, {
				dashboardLoading: false,
				dashboardError: action.error,
			});
		default: {
			return state;
		}
	}
}

export default reducer;