import React, {useEffect} from "react";
import {Button, Snackbar} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";

import {history} from "../../../redux/store";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));
const RestRequestActions = props => {
	const intl = useIntl();
	const classes = useStyles();

	// Handle updating/updated here
	const [requestUpdateSnackbarOpen, setRequestUpdateSnackbarOpen] = React.useState(false);
	const [requestUpdateSnackbarMessage, setRequestUpdateSnackbarMessage] = React.useState('');

	useEffect(() => {
		if(props.updatingLoading === true && props.type === 'update'){
			showRequestSnackbar(intl.formatMessage({ id: "REST.REQUEST.SNACKBAR.UPDATING",
				defaultMessage: 'Updating Rest Request'}));
		}
		if (!props.updatingLoading && requestUpdateSnackbarOpen) {
			showRequestSnackbar(intl.formatMessage({ id: "REST.REQUEST.SNACKBAR.UPDATED",
				defaultMessage: 'Rest Request Updated'}));
		}
	}, [props.updatingLoading]);

	const showRequestSnackbar = (message) => {
		setRequestUpdateSnackbarOpen(true);
		setRequestUpdateSnackbarMessage(message);
	}

	const hideRequestSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setRequestUpdateSnackbarOpen(false);
	}

	return <>
		<div className={classes.buttons}>
			<Button variant = "contained" type = "submit" className='mr-6'>
				{props.type === 'create' ?
					<FormattedMessage id="BUTTON.CREATE" defaultMessage='Create'/> :
					<FormattedMessage id="BUTTON.UPDATE" defaultMessage='Update'/>}
			</Button>
			<Button variant = "contained" className='mr-6'
			        onClick = {(event) => {
				        event.preventDefault();
				        history.goBack();
			        }}>
				{props.type === 'create' ?
					<FormattedMessage id="BUTTON.CANCEL" defaultMessage='Cancel'/> :
					<FormattedMessage id="BUTTON.BACK" defaultMessage='Back'/>}
			</Button>
		</div>

		<Snackbar
			anchorOrigin = {{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open = {requestUpdateSnackbarOpen}
			autoHideDuration = {2000}
			onClose = {hideRequestSnackbar}
			message = {requestUpdateSnackbarMessage}
		>
			<Alert onClose = {hideRequestSnackbar} severity = "success">
				{requestUpdateSnackbarMessage}
			</Alert>
		</Snackbar>
		</>
}

export default RestRequestActions;