export const PHONE = "PHONE";
export const TABLET = "TABLET";

export const FormFactorObjects = [
	{label: 'Phone', value: PHONE},
	{label: 'Tablet', value: TABLET},
];

export const getLabel = (value) => {
	if(!value) return '';
	return FormFactorObjects.find((formfactor) => {
		return formfactor.value === value;
	}).label;
}