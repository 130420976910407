export const UINames = (name, intl) => {
	switch (name) {
		case 'action':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.ACTION",
				defaultMessage: 'Action' });
		case 'operation':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.OPERATION",
				defaultMessage: 'Operation'});
		case 'component':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.COMPONENT",
				defaultMessage: 'Component'});
		case 'identifier':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.IDENTIFIER",
				defaultMessage: 'Identifier'});
		case 'name':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.NAME",
				defaultMessage: 'Name'});
		case 'value':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.VALUE",
				defaultMessage: 'Value'});
		case 'toComponent':
			return intl.formatMessage({ id: "INSTRUCTION.LABEL.TO.COMPONENT",
				defaultMessage: 'To Component'});
		default:
			return '';
	}
}