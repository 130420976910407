import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import SprintDisplay from "../../components/Sprint/SprintDisplay";

const SprintDisplaySection = props => {
	const intl = useIntl();

	const [iterations, setIterations] = useState(
		props.sprint ? props.sprint.iterations : []
	);

	const [sprint, setSprint] = useState(
		props.sprint ? props.sprint : null
	);

	useEffect(() => {
		const inter = setInterval(() => {
			props.onLoadSprint(props.match.params.sprintID, props.project.id);
		}, 20_000);
		return () => clearInterval(inter);
	});

	useEffect(() => {
		if(props.project){
			props.onLoadSprint(props.match.params.sprintID);
		}
	}, [props.match.params.sprintID, props.project]);

	useEffect(() => {
		if (props.sprint) {
			setSprint(props.sprint);
			setIterations(props.sprint.iterations);
			document.title = intl.formatMessage({ id: "PAGE.TITLE.SPRINT",
				defaultMessage: 'Sprint: ' }) + props.sprint.label;
		}
	}, [props.sprint]);

	// this delete test from the list of sprint.tests Not test itself
	const deleteIteration = index => {
		const updatedSprint = {...sprint};
		const updatedIterations = [...updatedSprint.iterations];
		updatedIterations.splice(index, 1);
		// make patch object
		const patchSprint = {
			iterations: {value: updatedIterations}
		};
		updatedSprint.iterations = updatedIterations;
		setIterations(updatedIterations);
		setSprint(updatedSprint);
		props.onUpdateSprint(props.match.params.sprintID, patchSprint);
	}

	const updateIterations = (iterations) => {
		const id = props.sprint.id;
		const iterationsPatch = {iterations: {value: iterations}};
		props.onUpdateSprint(id, iterationsPatch);
	}

	const updateIteration = (web, android, iterationID) => {
		let iterationPatch = null;
		if(web){
			iterationPatch = {platform: {value: web.os}, browser: {value: web.browser}, browserVersion: {value: web.version}};
		} else {
			iterationPatch = {deviceGroup: {value: android.deviceGroup.id}};
		}
		props.onUpdateIteration(iterationID, iterationPatch);
	}

	const updateSprint = (updatedSprint) => {
		setSprint(updatedSprint);
	}

	const createAndAddIterationToSprint = (id, iteration) => {
		props.onCreateIteration(id, iteration);
	}

	const onExecuteIteration = (iterationID, sprintID) => {
		props.onExecuteIteration(iterationID, sprintID, props.project.id);
	}

	const liveMode = props.project ? props.project.enableLive : false;

	return <SprintDisplay sprint = {sprint}
	                      updateSprint = {updateSprint}
	                      deleteIteration = {deleteIteration}
	                      sprintID = {props.match.params.sprintID}
	                      iterations = {iterations}
	                      setIterations = {setIterations}
	                      updateIterations = {updateIterations}
	                      updateIteration= {updateIteration}
	                      createAndAddIterationToSprint = {createAndAddIterationToSprint}
	                      onExecuteIteration = {onExecuteIteration}
	                      onLoadSprint={props.onLoadSprint}
	                      project= {props.project}
	                      viewPort= {props.viewPort}
	                      liveMode= {liveMode}
	                      tab={props.tab}
						  iterationCreationError={props.iterationCreationError}
						  launchedIterations={props.launchedIterations}
	/>;
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		sprint: state.sprint.sprint,
		viewPort: state.project.viewPort,
		iterationCreationError: state.iteration.error,
		launchedIterations: state.iteration.launchedIterations
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadSprint: (id) => dispatch(actions.loadSprint(id)),
		onUpdateSprint: (id, sprint) => dispatch(actions.updateSprint(id, sprint)),
		onCreateIteration: (sprintID, iteration) => dispatch(actions.createIteration(sprintID, iteration)),
		onUpdateIteration: (id, iteration) => dispatch(actions.updateIteration(id, iteration)),
		onExecuteIteration: (iterationID, sprintID, projectId) => dispatch(actions.executeIteration(iterationID, sprintID, projectId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SprintDisplaySection);
