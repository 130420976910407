import React, {useEffect} from "react";
import ApexCharts from "apexcharts";
import {FormattedMessage, useIntl} from "react-intl";

const getChartOptions = (timeDistribution, intl) => {

	const options = {
		series: timeDistribution.percentages,
		// series: [12, 36, 52],
		chart: {
			type: 'pie', height: 400,
		},
		// labels: timeDistribution.projects,
		labels: [intl.formatMessage({ id: "DASHBOARD.WEB", defaultMessage: 'Web' }),
			intl.formatMessage({ id: "DASHBOARD.MOBILE", defaultMessage: 'Mobile' }),
			intl.formatMessage({ id: "DASHBOARD.REMAINING", defaultMessage: 'Remaining' })],
		legend: {
			position: 'bottom'
		},
		fill: {
			type: 'gradient',
		},
		colors: ['#008FFB', '#FEB019', '#00E396']
	};
	return options;
}

const ExecutionTimeDistribution = props => {
	const intl = useIntl();

	useEffect(() => {
		if(props.executionTimeDistribution){
			const element = document.getElementById("execution_time_distribution_chart");
			if (!element) {
				return;
			}

			const options = getChartOptions(props.executionTimeDistribution, intl);

			const chart = new ApexCharts(element, options);
			chart.render();
			return () => {
				chart.destroy();
			};
		}

	}, [props.executionTimeDistribution]);

	return <div className="card card-custom gutter-b card-stretch">
		<div className="card-header border-0 pt-5">
			<div className="card-title">
				<div className="card-label">
					<div className="font-weight-bolder">
						<FormattedMessage id="DASHBOARD.EXEC.TIME.DISTRIBUTION" defaultMessage='Execution Time Distribution'/>
					</div>
					<div className="font-size-sm text-muted mt-2">
						<FormattedMessage id="DASHBOARD.EXEC.TIME.DISTRIBUTION.SUBHEADER" defaultMessage='Distribution in %'/>
					</div>
				</div>
			</div>
		</div>
		<div className="card-body d-flex flex-column">
			<div className="flex-grow-1">
				<div id="execution_time_distribution_chart" style={{height: "400px"}}/>
			</div>
		</div>
	</div>;
}

export default ExecutionTimeDistribution;