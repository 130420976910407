import * as actionTypes from "./actionTypes";

export const createRestRequest = (projectID, restRequest) => {
	return {
		type: actionTypes.CREATE_REST_REQUEST,
		restRequest: restRequest,
		projectID: projectID
	}
};

export const createRestRequestStart = () => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_START
	}
};

export const createRestRequestSuccess = (restRequest) => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_SUCCESS,
		restRequest: restRequest
	}
};

export const createRestRequestFail = (error) => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_FAIL,
		error: error
	}
};

export const loadRestRequests = (projectID, config) => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS,
		projectID: projectID,
		config: config
	}
};

export const loadRestRequestsStart = () => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_START
	}
};

export const loadRestRequestsSuccess = (restRequests, totalRecords) => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_SUCCESS,
		restRequests: restRequests,
		totalRecords: totalRecords
	}
};

export const loadRestRequestsFail = (error) => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_FAIL,
		error: error
	}
}

export const deleteRestRequest = (id, redirect) => {
	return {
		type: actionTypes.DELETE_REST_REQUEST,
		id: id,
		redirect: redirect
	}
};

export const deleteRestRequestStart = () => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_START
	}
};

export const deleteRestRequestSuccess = () => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_SUCCESS
	}
};

export const deleteRestRequestFail = (error) => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_FAIL,
		error: error
	}
};

export const loadRestRequest = (id) => {
	return {
		type: actionTypes.LOAD_REST_REQUEST,
		id: id
	}
};

export const loadRestRequestStart = () => {
	return {
		type: actionTypes.LOAD_REST_REQUEST_START
	}
};

export const loadRestRequestSuccess = (restRequest) => {
	return {
		type: actionTypes.LOAD_REST_REQUEST_SUCCESS,
		restRequest: restRequest
	}
};

export const loadRestRequestFail = (error) => {
	return {
		type: actionTypes.LOAD_REST_REQUEST_FAIL,
		error: error
	}
};

export const updateRestRequest = (restRequest) => {
	return {
		type: actionTypes.UPDATE_REST_REQUEST,
		restRequest: restRequest
	}
};

export const updateRestRequestStart = () => {
	return {
		type: actionTypes.UPDATE_REST_REQUEST_START
	}
};

export const updateRestRequestSuccess = () => {
	return {
		type: actionTypes.UPDATE_REST_REQUEST_SUCCESS,
		restRequest: null
	}
};

export const updateRestRequestFail = (error) => {
	return {
		type: actionTypes.UPDATE_REST_REQUEST_FAIL,
		error: error
	}
};

export const loadRestRequestsNames = (projectID) => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_NAMES,
		projectID: projectID
	}
};

export const loadRestRequestsNamesStart = () => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_NAMES_START
	}
};

export const loadRestRequestsNamesSuccess = (restRequests) => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_NAMES_SUCCESS,
		restRequests: restRequests
	}
};

export const loadRestRequestsNamesFail = (error) => {
	return {
		type: actionTypes.LOAD_REST_REQUESTS_NAMES_FAIL,
		error: error
	}
}

export const createRestRequestOfDomain = (domainId, restRequest) => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_OF_DOMAIN,
		restRequest: restRequest,
		domainId: domainId
	}
};

export const createRestRequestOfDomainStart = () => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_OF_DOMAIN_START
	}
};

export const createRestRequestOfDomainSuccess = () => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_OF_DOMAIN_SUCCESS,
	}
};

export const createRestRequestOfDomainFail = (error) => {
	return {
		type: actionTypes.CREATE_REST_REQUEST_OF_DOMAIN_FAIL,
		error: error
	}
};

export const loadProjectRestRequests = (id) => {
	return {
		type: actionTypes.LOAD_PROJECT_REST_REQUESTS,
		id: id
	}
};

export const loadProjectRestRequestsStart = () => {
	return {
		type: actionTypes.LOAD_PROJECT_REST_REQUESTS_START
	}
};

export const loadProjectRestRequestsSuccess = (requests) => {
	return {
		type: actionTypes.LOAD_PROJECT_REST_REQUESTS_SUCCESS,
		projectRestRequests: requests
	}
};

export const loadProjectRestRequestsFail = (error) => {
	return {
		type: actionTypes.LOAD_PROJECT_REST_REQUESTS_FAIL,
		error: error
	}
};

export const deleteRestRequestOfDomain = (domainId, requestId) => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_OF_DOMAIN,
		domainId: domainId,
		requestId: requestId,
		// redirect: redirect
	}
};

export const deleteRestRequestOfDomainStart = () => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_OF_DOMAIN_START
	}
};

export const deleteRestRequestOfDomainSuccess = () => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_OF_DOMAIN_SUCCESS
	}
};

export const deleteRestRequestOfDomainFail = (error) => {
	return {
		type: actionTypes.DELETE_REST_REQUEST_OF_DOMAIN_FAIL,
		error: error
	}
};