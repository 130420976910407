import React from "react";
import {Button, Input} from "@material-ui/core";
import ImportExport from "@material-ui/icons/ImportExport";
import {FormattedMessage} from "react-intl";

import * as Action from './Actions';
import * as ComponentTest from './Components';
import * as Operation from './Operations';
import {componentsValues} from "../../components/Instruction/ActionTypes";

const operationsStr = [];
operationsStr.push("assertText");
operationsStr.push("assertChecked");
operationsStr.push("assertNotChecked");
operationsStr.push("assertAlert");
operationsStr.push("assertConfirmation");
operationsStr.push("assertPrompt");
operationsStr.push("assertEditable");
operationsStr.push("assertNotEditable");
operationsStr.push("assertSelectedValue");
operationsStr.push("assertNotSelectedValue");
operationsStr.push("assertSelectedLabel");
operationsStr.push("assertTitle");
operationsStr.push("assertValue");
operationsStr.push("close");

const getAction = (command) => {
	switch (command.command) {
		/*case "open": {
		  return Action.OPEN;
		}*/
		case "click": {
			return Action.CLICK;
		}
		case "doubleClick": {
			return Action.DOUBLE_CLICK;
		}
		case "type": {
			return Action.TYPE;
		}
		case "select": {
			return Action.SELECT;
		}
		case "sendKeys": {
			return Action.SEND_KEYS;
		}
		case "waitSeconds": {
			return Action.WAIT_SECONDS;
		}
		case "chooseOkOnNextConfirmation": {
			return Action.CHOOSE_OK_ON_NEXT_CONFIRMATION;
		}
		case "webdriverChooseOkOnVisibleConfirmation": {
			return Action.WEBDRIVER_CHOOSE_OK_ON_VISIBLE_CONFIRMATION;
		}
		case "webdriverChooseCancelOnVisibleConfirmation": {
			return Action.WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_CONFIRMATION;
		}
		case "webdriverAnswerOnVisiblePrompt": {
			return Action.WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT;
		}
		case "webdriverChooseCancelOnVisiblePrompt": {
			return Action.WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_PROMPT;
		}
		case "mouseOver": {
			return Action.MOUSE_OUVER;
		}
		case "mouseOut": {
			return Action.MOUSE_OUT;
		}
		case "waitForElementPresent": {
			return Action.WAIT_FOR_ELEMENT_PRESENT;
		}
		case "addSelection": {
			return Action.ADD_SELECTION;
		}
		case "removeSelection": {
			return Action.REMOVE_SELECTION;
		}
		case "dragAndDropToObject": {
			return Action.DRAG_AND_DROP;
		}
		case "runScript": {
			return Action.RUN_SCRIPT;
		}
		case "selectWindow": {
			return Action.SELECT_WINDOW;
		}
		case "selectFrame": {
			return Action.SELECT_FRAME;
		}
		case "check": {
			return Action.CHECK;
		}
		case "uncheck": {
			return Action.UNCHECK;
		}
		case "storeValue": {
			return Action.STORE_VALUE;
		}
		case "storeText": {
			return Action.STORE_TEXT;
		}
		/* case "close": { // leve une exception car apres y a quit qui est applee
		  return Action.CLOSE;
		}*/
		default:
			return null;
	}
}

const getComponent = (value) => {
	switch (value.split("=")[0]) {
		case "id": {
			return ComponentTest.ID;
		}
		case "name": {
			return ComponentTest.NAME;
		}
		case "classname": {
			return ComponentTest.CLASSNAME;
		}
		case "css": {
			return ComponentTest.CSSSELECTOR;
		}
		case "linkText": {
			return ComponentTest.LINKTEXT;
		}
		case "partialLinkText": {
			return ComponentTest.PARTIALLINK;
		}
		case "xpath": {
			return ComponentTest.XPATH;
		}
		default:
			return null;
	}
}

const getComponentName = (value) => {
	return value.split("=")[1];
}

const parseAssert = (command) => {
	const testAssert = {};
	testAssert.description = command.comment;
	// console.log(command.command);
	switch (command.command) {
		case "assertText": {
			testAssert.operation = Operation.TEXT_PRESENCE;
			break;
		}
		case "assertNotText": {
			testAssert.operation = Operation.TEXT_NOT_PRESENTE;
			break;
		}
		case "assertChecked": {
			testAssert.operation = Operation.ASSERT_CHECKED;
			break;
		}
		case "assertNotChecked": {
			testAssert.operation = Operation.ASSERT_NOT_CHECKED;
			break;
		}
		case "assertAlert": {
			testAssert.operation = Operation.ASSERT_ALERT;
			break;
		}
		case "assertConfirmation": {
			testAssert.operation = Operation.ASSERT_CONFIRMATION;
			break;
		}
		case "assertPrompt": {
			testAssert.operation = Operation.ASSERT_PROMPT;
			break;
		}
		case "assertEditable": {
			testAssert.operation = Operation.ASSERT_EDITABLE;
			break;
		}
		case "assertNotEditable": {
			testAssert.operation = Operation.ASSERT_NOT_EDITABLE;
			break;
		}
		case "assertSelectedValue": {
			testAssert.operation = Operation.ASSERT_SELECTED_VALUE;
			break;
		}
		case "assertNotSelectedValue": {
			testAssert.operation = Operation.ASSERT_NOT_SELECTED_VALUE;
			break;
		}
		case "assertSelectedLabel": {
			testAssert.operation = Operation.ASSERT_SELECTED_LABEL;
			break;
		}
		case "assertTitle": {
			testAssert.operation = Operation.ASSERT_TITLE;
			break;
		}
		case "assertValue": {
			testAssert.operation = Operation.ASSERT_VALUE;
			break;
		}
	}
	if (command.target.split("=").length === 2) {
		testAssert.component = getComponent(command.target);
		testAssert.componentName = getComponentName(command.target);
		testAssert.checkedValue = command.value;
	} else if (command.target.split("=").length === 1) {
		testAssert.checkedValue = command.target;
	}

	return testAssert;
}

const parseInstruction = (command, index) => {
	const action = getAction(command);
	if (action == null) {
		return null;
	}

	const inst = {};

	inst.index = index;
	inst.action = action;
	inst.description = command.comment;

	if (!operationsStr.includes(command.command)) {
		inst.component = getComponent(command.target);
		inst.componentName = getComponentName(command.target);
	}

	if (
		inst.action === Action.TYPE ||
		inst.action === Action.SEND_KEYS ||
		inst.action === Action.WAIT_FOR_ELEMENT_PRESENT ||
		inst.action === Action.STORE_TEXT ||
		inst.action === Action.STORE_VALUE
	) {
		inst.typeValue = command.value;
	}

	if (
		inst.action === Action.SELECT ||
		inst.action === Action.ADD_SELECTION ||
		inst.action === Action.REMOVE_SELECTION
	) {
		const sp = command.value.split("=");
		if (sp.length > 1) {
			inst.typeValue = sp[1];
		} else {
			inst.typeValue = command.value;
		}
	}

	if (
		inst.action === Action.WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT ||
		inst.action === Action.RUN_SCRIPT
		//  || inst.action === Action.OPEN
	) {
		inst.typeValue = command.target;
	}

	if (inst.action === Action.DRAG_AND_DROP) {
		if(!inst.componentTo){
			const splitMe = command.value;
			const index = splitMe.indexOf('=');
			const typeValue = splitMe.substring(index + 1);
			const componentTo = splitMe.substring(0, index);
			// check if componentTo is valid
			if(componentsValues.indexOf(componentTo.toUpperCase()) > -1){
				inst.typeValue = typeValue;
				inst.componentTo = componentTo.toUpperCase();
			}
		}

		// inst.componentTo = getComponent(command.value);
		// inst.typeValue = getComponentName(command.value);
	}

	return inst;
}

const ImportTests = props => {

	const importTestFile = (event, add) => {

		let existingTests = [];
		const file = event.target.files[0];

		let reader = new FileReader();
		reader.readAsText(file);

		reader.onload = () => {
			const result = reader.result.toString();
			const json = JSON.parse(result);

			if (json.name) {
				// suite == json
				let testAssert = null;
				let label;
				if (add) {
					existingTests = [...props.domain.cases];
				}
				json.tests.forEach(test => {
					let newTest = {
						testAsserts : [],
						instructions : []
					};
					newTest.label = test.name;

					let count = 1;
					label = newTest.label;
					let commandType = "";
					let index = 0;
					test.commands.forEach(c => {
						const instruction = parseInstruction(c, index);
						if (instruction !== null) {
							if (commandType === "testAssert") {
								existingTests.push(newTest);
								index = 0;
								instruction.index = index;
								newTest = {
									testAsserts : [],
									instructions : []
								};
								newTest.label = label + " " + count + " to modify!";
								count++;

							}
							newTest.instructions.push(instruction);
							index++;
							commandType = "instruction";
						}
						if (operationsStr.includes(c.command)) {
							testAssert = parseAssert(c);
							if (testAssert !== null) {
								newTest.testAsserts.push(testAssert);
								commandType = "testAssert";
							}
						}
					});
					if (newTest.instructions.length > 0) {
						// console.log("before add : " + props.domain.cases.length);
						existingTests.push(newTest);
						// console.log("after add : " + props.domain.cases.length);
					}
				});
				if (existingTests.length > 0) {
					// tests = [...tests];
					const cases = {
						cases: { value: existingTests },
						add: add
					}
					props.importTests(props.domain.id, cases);
				}
			} else {
				if (json.cases.length > 0) {
					json.cases.forEach(c => existingTests.push(c));
					const cases = {
						cases: { value: existingTests },
						add: true
					}
					props.importTests(props.domain.id, cases);
				}
			}
		};
	}

	return (
		<>
			<Button
				component = "label"
				variant = "contained"
				size = 'small'
				startIcon = {<ImportExport/>}
				className = ''>
				<FormattedMessage id="DOMAINS.TESTS.ACTIONS.IMPORT.ADD"
				                  defaultMessage="Import"/>
				<Input type = 'file'
				       id = 'import-test'
				       hidden = {true}
				       onChange = {(event) => importTestFile(event, true)}/>
			</Button>
		</>
	);
}

export default ImportTests;
