import * as actionTypes from "./actionTypes";

export const loadSuites = (projectID, config) => {
	return {
		type: actionTypes.LOAD_SUITES,
		projectID: projectID,
		config: config
	}
};

export const loadSuitesStart = () => {
	return {
		type: actionTypes.LOAD_SUITES_START
	}
};

export const loadSuitesSuccess = (suites, totalRecords) => {
	return {
		type: actionTypes.LOAD_SUITES_SUCCESS,
		suites: suites,
		totalRecords: totalRecords
	}
};

export const loadSuitesFail = (error) => {
	return {
		type: actionTypes.LOAD_SUITES_FAIL,
		error: error
	}
};

export const deleteSuite = (id, redirect) => {
	return {
		type: actionTypes.DELETE_SUITE,
		id: id,
		redirect: redirect
	}
};

export const deleteSuiteStart = () => {
	return {
		type: actionTypes.DELETE_SUITE_START
	}
};

export const deleteSuiteSuccess = (id) => { // for removal from red state
	return {
		type: actionTypes.DELETE_SUITE_SUCCESS,
		id: id
	}
};

export const deleteSuiteFail = (error) => {
	return {
		type: actionTypes.DELETE_SUITE_FAIL,
		error: error
	}
};

export const createSuite = (suite, projectID) => {
	return {
		type: actionTypes.CREATE_SUITE,
		suite: suite,
		projectID: projectID
	}
};

export const createSuiteStart = () => {
	return {
		type: actionTypes.CREATE_SUITE_START
	}
};

export const createSuiteSuccess = () => {
	return {
		type: actionTypes.CREATE_SUITE_SUCCESS
	}
};

export const createSuiteFail = (error) => {
	return {
		type: actionTypes.CREATE_SUITE_FAIL,
		error: error
	}
};

export const loadSuite = (id) => {
	return {
		type: actionTypes.LOAD_SUITE,
		id: id
	}
};

export const loadSuiteStart = () => {
	return {
		type: actionTypes.LOAD_SUITE_START
	}
};

export const loadSuiteSuccess = (suite) => {
	return {
		type: actionTypes.LOAD_SUITE_SUCCESS,
		suite: suite
	}
};

export const loadSuiteFail = (error) => {
	return {
		type: actionTypes.LOAD_SUITE_FAIL,
		error: error
	}
};

export const updateSuite = (id, suite, stateSuite) => {
	return {
		type: actionTypes.UPDATE_SUITE,
		id: id,
		suite: suite,
		stateSuite: stateSuite
	}
};

export const updateSuiteStart = () => {
	return {
		type: actionTypes.UPDATE_SUITE_START
	}
};

export const updateSuiteSuccess = (suite) => {
	return {
		type: actionTypes.UPDATE_SUITE_SUCCESS,
		suite: suite
	}
};

export const updateSuiteFail = (error) => {
	return {
		type: actionTypes.UPDATE_SUITE_FAIL,
		error: error
	}
};

export const loadProjectSuites = (id) => {
	return {
		type: actionTypes.LOAD_PROJECT_SUITES,
		id: id
	}
};

export const loadProjectSuitesStart = () => {
	return {
		type: actionTypes.LOAD_PROJECT_SUITES_START
	}
};

export const loadProjectSuitesSuccess = (suites) => {
	return {
		type: actionTypes.LOAD_PROJECT_SUITES_SUCCESS,
		projectSuites: suites
	}
};

export const loadProjectSuitesFail = (error) => {
	return {
		type: actionTypes.LOAD_PROJECT_SUITES_FAIL,
		error: error
	}
};

export const loadVariables = (suiteID) => {
	return {
		type: actionTypes.LOAD_VARIABLES_FOR_SUITES,
		suiteID: suiteID,
	}
};

export const loadVariablesStart = () => {
	return {
		type: actionTypes.LOAD_VARIABLES_FOR_SUITES_START
	}
};

export const loadVariablesSuccess = (variables) => {
	return {
		type: actionTypes.LOAD_VARIABLES_FOR_SUITES_SUCCESS,
		variables: variables
	}
};

export const loadVariablesFail = (error) => {
	return {
		type: actionTypes.LOAD_VARIABLES_FOR_SUITES_FAIL,
		error: error
	}
};