import React, {useEffect, useRef, useState} from "react";
import {Alert} from "@material-ui/lab";
import {useIntl} from "react-intl";
import {Editor as TinyEditor} from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";

import {changeText} from "./Shared";
import Editor from "for-editor";

const RestRequestTabDescription = props => {
	const intl = useIntl();

	const [description, setDescription] = useState({
		value: props.description,
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage: null
	});

	const mounted = useRef(false);

	useEffect(() => {
		// mounted:
		mounted.current = true;
		// Always check validity: 1) switch from tab to Desc tab. 2) submit
		checkValidity();
		return () => mounted.current = false;
	}, []);

	useEffect(() => {
		if(props.description !== description.value){
			const updatedDescription = {...description};
			updatedDescription.value = props.description;
			setDescription(updatedDescription);
		}
	}, [props.description]);

	const descriptionChangeHandler = value => {
		const updatedDescription = changeText(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const onBlur = (event) => {
		props.setDescription(description.value);
	}

	const checkValidity = () => {
		const updatedDescription = changeText(description, description.value, 'description', intl);
		if(mounted.current === true ) {
			setDescription(updatedDescription);
		}
		return updatedDescription.valid;
	}
	props.registerValidateDescriptionFormCallback(checkValidity);

	let descriptionTextArea = <>
		{!description.valid && <Alert elevation = {3} variant = "standard"
		                              severity = "error" className = 'mb-4'>
			{description.validationMessage}
		</Alert>}

		{/*<TinyEditor value = {description.value}*/}
		{/*            init = {{*/}
		{/*	            height: 500,*/}
		{/*	            menubar: false,*/}
		{/*	            plugins: [*/}
		{/*		            'print preview paste autolink autosave save directionality code visualblocks visualchars ' +*/}
		{/*		            'fullscreen link codesample charmap hr pagebreak nonbreaking toc advlist lists textpattern ' +*/}
		{/*		            'noneditable charmap quickbars emoticons',*/}
		{/*	            ],*/}
		{/*	            toolbar:*/}
		{/*		            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +*/}
		{/*		            'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +*/}
		{/*		            'forecolor backcolor removeformat | pagebreak | charmap emoticons | ' +*/}
		{/*		            'fullscreen  preview save print | insertfile image media template link anchor codesample | ' +*/}
		{/*		            'ltr rtl',*/}
		{/*	            autosave_interval: '15s',*/}
		{/*	            autosave_retention: '2m',*/}
		{/*	            toolbar_mode: 'wrap',*/}
		{/*	            branding: false,*/}
		{/*	            statusbar: false*/}
		{/*            }}*/}
		{/*            onEditorChange = {(value) => descriptionChangeHandler(value)}*/}
		{/*            onBlur={onBlur}*/}
		{/*/>*/}
		<Editor value={description.value}
				onChange={(value) => {descriptionChangeHandler(value); onBlur();}}
				onSave={onBlur}
				language="en" subfield={true} preview={true}
				toolbar={{
					h1: true, // h1
					h2: true, // h2
					h3: true, // h3
					h4: true, // h4
					link: true, // 链接
					code: true, // 代码块
					preview: true,
					expand: true, // 全屏
					undo: true, // 撤销
					redo: true, // 重做
					save: true,
					subfield: true, // 单双栏模式
				}}/>
	</>

	return descriptionTextArea;
}

export default RestRequestTabDescription;