import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Row} from "react-bootstrap";
import {Divider, FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(4),
		minWidth: 120
	}
}));
const NormalUserFormJSX = props => {
	const classes = useStyles();
	const intl = useIntl();

	let authoritiesInput = null;
	if (props.type === 'create' || (props.type === 'update' && props.includeRoles)) {
		authoritiesInput = <FormControl className={`${classes.formControl}`} fullWidth={true}
										error={!props.authorities.valid}>
			<InputLabel htmlFor="select-role">
				<FormattedMessage id="USERS.TABLE.COLUMN.HEADER.AUTHORITIES"
								  defaultMessage='Authorities'/></InputLabel>
			<Select
				value={props.authorities.roles}
				onChange={(event) => props.authoritiesSelectHandler(event)}
				input={<Input id="select-role"/>}
			>

				{props.authorities.authorities.map(role => {
					return <MenuItem key={role.id}
									 value={role}>{role.label}</MenuItem>
				})}
			</Select>
			<FormHelperText>{props.authorities.validationMessage}</FormHelperText>
		</FormControl>;
	}

	const usernameInput = <TextField
		id="username"
		name="username"
		label={intl.formatMessage({
			id: 'REGISTRATION.LABEL.USERNAME', defaultMessage: 'User Name'
		})}
		className={`${classes.textField}`}
		value={props.username.value}
		onChange={props.usernameChangeHandler}
		onBlur={() => props.syncValueWithParentContainer('username')}
		margin="normal"
		variant="outlined"
		placeholder={intl.formatMessage({
			id: 'REGISTRATION.PLACEHOLDER.USERNAME', defaultMessage: 'UserName'
		})}
		error={!props.username.valid}
		helperText={props.username.validationMessage}
		disabled={props.type === 'update'}
	/>

	const emailInput = <TextField
		id="email"
		name="email"
		label={intl.formatMessage({
			id: 'REGISTRATION.LABEL.EMAIL', defaultMessage: 'Email'
		})}
		className={`${classes.textField} `}
		value={props.email.value}
		onChange={props.emailChangeHandler}
		onBlur={() => props.syncValueWithParentContainer('email')}
		margin="normal"
		variant="outlined"
		placeholder={intl.formatMessage({
			id: 'REGISTRATION.PLACEHOLDER.EMAIL', defaultMessage: 'Email'
		})}
		error={!props.email.valid}
		helperText={props.email.validationMessage}
	/>;

	let companyInput = null;
	if(props.loggedInUser === null || (props.type === 'update' && props.isAdmin === true )){
		companyInput = <TextField
				id="company"
				name="company"
				label={intl.formatMessage({
					id: 'REGISTRATION.LABEL.COMPANY', defaultMessage: 'Company Name'
				})}
				className={`${classes.textField} `}
				value={props.company.value}
				onChange={props.companyChangeHandler}
				onBlur={() => props.syncValueWithParentContainer('company')}
				margin="normal"
				variant="outlined"
				placeholder={intl.formatMessage({
					id: 'REGISTRATION.PLACEHOLDER.COMPANY', defaultMessage: 'Company Name'
				})}
				error={!props.company.valid}
				helperText={props.company.validationMessage}
			/>;
	}

	const firstNameInput = <TextField
		id="first-name"
		name="first-name"
		label={intl.formatMessage({
			id: 'REGISTRATION.LABEL.FIRSTNAME', defaultMessage: 'First Name'
		})}
		className={`${classes.textField}`}
		value={props.firstName.value}
		onChange={props.firstNameChangeHandler}
		onBlur={() => props.syncValueWithParentContainer('firstName')}
		margin="normal"
		variant="outlined"
		placeholder={intl.formatMessage({
			id: 'REGISTRATION.PLACEHOLDER.FIRSTNAME', defaultMessage: 'First Name'
		})}
		error={!props.firstName.valid}
		helperText={props.firstName.validationMessage}
	/>

	const lastNameInput = <TextField
		id="last-name"
		name="last-name"
		label={intl.formatMessage({
			id: 'REGISTRATION.LABEL.LASTNAME', defaultMessage: 'Last Name'
		})}
		className={`${classes.textField}`}
		value={props.lastName.value}
		onChange={props.lastNameChangeHandler}
		onBlur={() => props.syncValueWithParentContainer('lastName')}
		margin="normal"
		variant="outlined"
		placeholder={intl.formatMessage({
			id: 'REGISTRATION.PLACEHOLDER.LASTNAME', defaultMessage: 'Last Name'
		})}
		error={!props.lastName.valid}
		helperText={props.lastName.validationMessage}
	/>;

	let completeInformationMsg = null;
	if(props.completeInformationMsg){
		completeInformationMsg = <Alert elevation = {3} variant = "standard" severity = "error" className = 'mb-4 font-size-lg'>
			{props.completeInformationMsg}
		</Alert>;
	}

	return (
		<>
			{completeInformationMsg}
			<Row>
				<Col>
					<h1 className='text-center'>{props.normalUserHeaderTitle}</h1>
				</Col>
			</Row>
			<Divider/>
			<Row>
				<Col xs={12} sm={6}>
					{usernameInput}
				</Col>
				<Col xs={12} sm={6}>
					{emailInput}
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={6}>
					{firstNameInput}
				</Col>
				<Col xs={12} sm={6}>
					{lastNameInput}
				</Col>
			</Row>
			<Row>
				{companyInput && <Col xs={12} sm={6}>
					{companyInput}
				</Col>}
				<Col className='align-items-center'>
					{authoritiesInput}
				</Col>
			</Row>
		</>
	)
}

export default NormalUserFormJSX;