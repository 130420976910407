import * as actionTypes from "./actionTypes";

// export const loadSessionIDs = (projectID) => {
// 	return {
// 		type: actionTypes.LOAD_SESSION_IDS,
// 		projectID: projectID
// 	}
// };
//
// export const loadSessionIDsStart = () => {
// 	return {
// 		type: actionTypes.LOAD_SESSION_IDS_START
// 	}
// };
//
// export const loadSessionIDsSuccess = (sessionIDs) => {
// 	return {
// 		type: actionTypes.LOAD_SESSION_IDS_SUCCESS,
// 		sessionIDs: sessionIDs
// 	}
// };
//
// export const loadSessionIDsFail = (error) => {
// 	return {
// 		type: actionTypes.LOAD_SESSION_IDS_FAIL,
// 		error: error
// 	}
// };

// export const verifyPayment = (projectID, sessionID) => {
// 	return {
// 		type: actionTypes.VERIFY_PAYMENT,
// 		projectID: projectID,
// 		sessionID: sessionID
// 	}
// };
//
// export const verifyPaymentStart = () => {
// 	return {
// 		type: actionTypes.VERIFY_PAYMENT_START
// 	}
// };
//
// export const verifyPaymentSuccess = (outcome) => {
// 	return {
// 		type: actionTypes.VERIFY_PAYMENT_SUCCESS,
// 		outcome: outcome
// 	}
// };
//
// export const verifyPaymentFail = (error) => {
// 	return {
// 		type: actionTypes.VERIFY_PAYMENT_FAIL,
// 		error: error
// 	}
// };

// export const createCustomerPortalSession = (returnToUrl) => {
// 	return {
// 		type: actionTypes.CREATE_CUSTOMER_PORTAL_SESSION,
// 		returnToUrl: returnToUrl
// 	}
// };

export const loadCurrentPlan = () => {
	return {
		type: actionTypes.LOAD_CURRENT_PLAN,
	}
};

export const loadCurrentPlanStart = () => {
	return {
		type: actionTypes.LOAD_CURRENT_PLAN_START
	}
};

export const loadCurrentPlanSuccess = (plan) => {
	return {
		type: actionTypes.LOAD_CURRENT_PLAN_SUCCESS,
		plan: plan
	}
};

export const loadCurrentPlanFail = (error) => {
	return {
		type: actionTypes.LOAD_CURRENT_PLAN_FAIL,
		error: error
	}
};

export const loadPlans = (returnUrl) => {
	return {
		type: actionTypes.LOAD_PLANS,
		returnUrl: returnUrl
	}
};

export const loadPlansStart = () => {
	return {
		type: actionTypes.LOAD_PLANS_START
	}
};

export const loadPlansSuccess = (plans) => {
	return {
		type: actionTypes.LOAD_PLANS_SUCCESS,
		plans: plans
	}
};

export const loadPlansFail = (error) => {
	return {
		type: actionTypes.LOAD_PLANS_FAIL,
		error: error
	}
};

export const checkPayment = (sessionID) => {
	return {
		type: actionTypes.CHECK_PAYMENT,
		sessionID: sessionID
	}
};

export const checkPaymentStart = () => {
	return {
		type: actionTypes.CHECK_PAYMENT_START
	}
};

export const checkPaymentSuccess = (plan) => {
	return {
		type: actionTypes.CHECK_PAYMENT_SUCCESS,
		plan: plan
	}
};

export const checkPaymentFail = (error) => {
	return {
		type: actionTypes.CHECK_PAYMENT_FAIL,
		error: error
	}
};

export const cancelPaidSubscription = () => {
	return {
		type: actionTypes.CANCEL_PAID_SUBSCRIPTION
	}
};

export const cancelPaidSubscriptionStart = () => {
	return {
		type: actionTypes.CANCEL_PAID_SUBSCRIPTION_START
	}
};

export const cancelPaidSubscriptionSuccess = () => {
	return {
		type: actionTypes.CANCEL_PAID_SUBSCRIPTION_SUCCESS
	}
};

export const cancelPaidSubscriptionFail = (error) => {
	return {
		type: actionTypes.CANCEL_PAID_SUBSCRIPTION_FAIL,
		error: error
	}
};

export const changePaidSubscription = (priceId) => {
	return {
		type: actionTypes.CHANGE_PAID_SUBSCRIPTION,
		priceId: priceId
	}
};

export const changePaidSubscriptionStart = () => {
	return {
		type: actionTypes.CHANGE_PAID_SUBSCRIPTION_START
	}
};

export const changePaidSubscriptionSuccess = (plan) => {
	return {
		type: actionTypes.CHANGE_PAID_SUBSCRIPTION_SUCCESS,
		plan: plan
	}
};

export const changePaidSubscriptionFail = (error) => {
	return {
		type: actionTypes.CHANGE_PAID_SUBSCRIPTION_FAIL,
		error: error
	}
};

export const loadPaidCustomerPlans = () => {
	return {
		type: actionTypes.LOAD_PAID_CUSTOMER_PLANS
	}
};

export const loadPaidCustomerPlansStart = () => {
	return {
		type: actionTypes.LOAD_PAID_CUSTOMER_PLANS_START
	}
};

export const loadPaidCustomerPlansSuccess = (plans) => {
	return {
		type: actionTypes.LOAD_PAID_CUSTOMER_PLANS_SUCCESS,
		plans: plans
	}
};

export const loadPaidCustomerPlansFail = (error) => {
	return {
		type: actionTypes.LOAD_PAID_CUSTOMER_PLANS_FAIL,
		error: error
	}
};

export const deletePaymentSessions = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_SESSIONS
	}
};

export const deletePaymentSessionsStart = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_SESSIONS_START
	}
};

export const deletePaymentSessionsSuccess = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_SESSIONS_SUCCESS,
	}
};

export const deletePaymentSessionsFail = (error) => {
	return {
		type: actionTypes.DELETE_PAYMENT_SESSIONS_FAIL,
		error: error
	}
};

export const loadInvoices = (config) => {
	return {
		type: actionTypes.LOAD_INVOICES,
		config: config
	}
};

export const loadInvoicesStart = () => {
	return {
		type: actionTypes.LOAD_INVOICES_START
	}
};

export const loadInvoicesSuccess = (invoices, totalInvoices) => {
	return {
		type: actionTypes.LOAD_INVOICES_SUCCESS,
		invoices: invoices,
		totalInvoices: totalInvoices
	}
};

export const loadInvoicesFail = (error) => {
	return {
		type: actionTypes.LOAD_INVOICES_FAIL,
		error: error
	}
};