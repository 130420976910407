import React from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import SuitesSection from "./SuitesSection";
import SuiteDisplaySection from "./SuiteDisplaySection";

const Suite = (props) => {

	let suiteColumn = null;
	if (props.match.params.suiteID) {
		suiteColumn = <SuiteDisplaySection {...props} />
	}
	return (
		<Container className = 'p-0'>
			<Row>
				<Col sm = {12} md = {3}>
					<SuitesSection {...props} skipOnLoadSuites={false}/>
				</Col>
				<Col>
					{suiteColumn}
				</Col>
			</Row>
		</Container>
	);
}

const mapStateToProps = state => {
	return {

	};
};

const mapDispatchToProps = dispatch => {
	return {

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Suite);