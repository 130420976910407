/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import Bowser from "bowser";
import * as Type from "../../../../../tigmat/components/Project/enum/ProjectType";

const AsideMenuList = props => {
	const layoutProps = props.layoutProps;
	const location = useLocation();
	const getMenuItemActive = (url) => {
		return checkIsActive(location, url)
			? " menu-item-active menu-item-open"
			: "";
	};

	let list = <ul className = {`menu-nav ${layoutProps.ulClasses} text-white-50 m-4`}>
		<FormattedMessage id="METRONIC.ASIDE.MENU.LIST.NO.PROJECT.MESSAGE"
		                  defaultMessage='Create A Project or you must be assigned to one'/>
	</ul>;

	const browser = Bowser.getParser(window.navigator.userAgent);
	let pluginButton = null;
	if(browser.getBrowser().name === 'Chrome') {

		pluginButton = <li className = {`menu-item`} aria-haspopup = "true">
			<a className = "menu-link"
			   href = "https://chrome.google.com/webstore/detail/tigmat-ide-the-functional/chjoocjagcohmngaiciohfcjohfdaemg"
			   target='_blank'>
					<span className = "svg-icon menu-icon">
					  <SVG src = {toAbsoluteUrl("/media/svg/icons/Code/Code.svg")}/>
					</span>
				<span className = "menu-text">
							<FormattedMessage id="HEADER.PLUGIN" defaultMessage='Plugin'/>
					</span>
			</a>
		</li>;
	} else if(browser.getBrowser().name === 'Firefox'){
		pluginButton = <li className = {`menu-item`} aria-haspopup = "true">
			<a className = "menu-link"
			   href = "https://addons.mozilla.org/en-US/firefox/addon/tigmat-ide/"
			   target='_blank'>
					<span className = "svg-icon menu-icon">
					  <SVG src = {toAbsoluteUrl("/media/svg/icons/Code/Code.svg")}/>
					</span>
				<span className = "menu-text">
							<FormattedMessage id="HEADER.PLUGIN" defaultMessage='Plugin'/>
					</span>
			</a>
		</li>;
	} else if (browser.getBrowser().name === 'Microsoft Edge') {
		pluginButton = <li className={`menu-item`} aria-haspopup="true">
			<a className="menu-link"
			   href="https://microsoftedge.microsoft.com/addons/detail/mlmglfbigjdofppdfgifdeopcijljnbe" target='_blank'>
				<span className="svg-icon menu-icon">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Code/Code.svg")}/>
				</span>
				<span className="menu-text">
					<FormattedMessage id="HEADER.PLUGIN" defaultMessage='Plugin'/>
				</span>
			</a>
		</li>;
	}

	if(props.project){

		let restRequestMenu = null;
		if(props.project.type !== Type.API){
			restRequestMenu = <li className = {`menu-item ${getMenuItemActive("/rest-request")}`}
				aria-haspopup = "true">
				<NavLink className = "menu-link text-white" to = "/rest-requests">
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}/>
			            </span>
					<span className = "menu-text">
							<FormattedMessage id="CATEGORY.REST"
											  defaultMessage='Rest API'/></span>
				</NavLink>
			</li>;
		}

		list = <ul className = {`menu-nav ${layoutProps.ulClasses}`}>

			{/*begin::1 Level*/}
			<li className = {`menu-item ${getMenuItemActive("/dashboard")}`}
			    aria-haspopup = "true">
				<NavLink className = "menu-link" to = "/dashboard">
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
			            </span>
					<span className = "menu-text">Dashboard</span>
				</NavLink>
			</li>

			<li className = {`menu-item ${getMenuItemActive("/domain")}`}
			    aria-haspopup = "true">
				<NavLink className = "menu-link text-white" to = "/domains">
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")}/>
			            </span>
					<span className = "menu-text">
							<FormattedMessage id="CATEGORY.DOMAINS"
							                  defaultMessage='Domains'/></span>
				</NavLink>
			</li>

			{restRequestMenu}

			<li className = {`menu-item ${getMenuItemActive("/suite")}`}
			    aria-haspopup = "true">
				<NavLink className = "menu-link" to = "/suites">
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Design/Pixels.svg")}/>
			            </span>
					<span className = "menu-text">
							<FormattedMessage id="CATEGORY.SUITES"
							                  defaultMessage='Suites'/></span>
				</NavLink>
			</li>

			<li className = {`menu-item ${getMenuItemActive("/sprint")}`}
			    aria-haspopup = "true">
				<NavLink className = "menu-link" to = "/sprints">
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Devices/Cassete.svg")}/>
			            </span>
					<span className = "menu-text">
							<FormattedMessage id="CATEGORY.SPRINTS"
							                  defaultMessage='Sprints'/></span>
				</NavLink>
			</li>


			<li className="menu-section ">
				<h4 className="menu-text">Extra</h4>
				<i className="menu-icon flaticon-more-v2"></i>
			</li>

			<ul className = {`menu-nav ${layoutProps.ulClasses}`}>
				<li className = {`menu-item`} aria-haspopup = "true">
					<a className = "menu-link" href = "https://docs.tigmat.io" target='_blank'>
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}/>
			            </span>
						<span className = "menu-text">
							<FormattedMessage id = "HEADER.DOCUMENTATION" defaultMessage = 'Documentation'/>
						</span>
					</a>
				</li>

				{pluginButton}
			</ul>

		</ul>
	} else {
		list = <ul className = {`menu-nav ${layoutProps.ulClasses}`}>

			<li className = {`menu-item ${getMenuItemActive("/dashboard")}`}
				aria-haspopup = "true">
				<NavLink className = "menu-link" to = "/dashboard">
			            <span className = "svg-icon menu-icon">
			              <SVG src = {toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
			            </span>
					<span className = "menu-text">Dashboard</span>
				</NavLink>
			</li>
			<li className="menu-section ">
				<h4 className="menu-text">Extra</h4>
				<i className="menu-icon flaticon-more-v2"></i>
			</li>

			<ul className = {`menu-nav ${layoutProps.ulClasses}`}>
				<li className = {`menu-item`} aria-haspopup = "true">
					<a className = "menu-link" href = "https://docs.tigmat.io" target='_blank'>
							<span className = "svg-icon menu-icon">
							  <SVG src = {toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}/>
							</span>
						<span className = "menu-text">
								<FormattedMessage id = "HEADER.DOCUMENTATION" defaultMessage = 'Documentation'/>
							</span>
					</a>
				</li>

				{pluginButton}
			</ul>
		</ul>
	}

	return list;
};

const mapStateToProps = state => {
	return {
		project: state.project.project
	};
};
export default connect(mapStateToProps, null)(AsideMenuList);

