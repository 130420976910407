import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FormattedMessage, useIntl} from "react-intl";
import {MultiSelect} from "primereact/multiselect";
import {Alert} from "@material-ui/lab";
import {IconButton, Snackbar, TextField} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {FormFactorObjects, getLabel as getFormFactorLabel} from "../enum/FormFactor";
import {changeText} from "../Shared";

// const formFactorObjects = [{label: 'Phone', value: 'PHONE'}, {label: 'Tablet', value: 'TABLET'}]

// const useStyles = () => makeStyles(theme => ({
// 	// textField: {
// 	// 	marginLeft: theme.spacing(1),
// 	// 	marginRight: theme.spacing(1),
// 	// },
// 	root: {
// 		'& > *': {
// 			margin: theme.spacing(3)
// 		}
// 	}
// }));
const DeviceGroupJSX = props => {

	const intl = useIntl();
	// const classes = useStyles();

	const dt = useRef(null);
	const [manufacturers, setManufacturers] = useState([]);

	useEffect(() => {
		const updatedManufacturers = [];
		const updatedOSVersions = [];
		const updatedResolutions = [];

		props.devices.map((device) => {
			if(!updatedManufacturers.includes(device.manufacturer)){
				updatedManufacturers.push(device.manufacturer);
			}
			if(!updatedOSVersions.includes(device.os)){
				updatedOSVersions.push(device.os);
			}
			const res = `${device.resolution.height}x${device.resolution.width}`;
			if(!updatedResolutions.includes(res)){
				updatedResolutions.push(res);
			}

		});
		setManufacturers(updatedManufacturers);
		updatedOSVersions.sort((os1, os2) => Number(os1) > Number(os2) ? -1 : 1);
		setOSVersions(updatedOSVersions);
		setResolutions(updatedResolutions);

	}, [props.devices]);

	useEffect(() => {
		dt.current.filter();
	}, [manufacturers]);

	const [selectedManufacturers, setSelectedManufacturers] = useState([]);

	const onManufacturersChange = (e) => {
		dt.current.filter(e.value, 'manufacturer', 'in');
		setSelectedManufacturers(e.value);
	}

	const manufacturersFilter = <MultiSelect value={selectedManufacturers}
											 options={manufacturers}
											 onChange={onManufacturersChange}
											 placeholder={intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
											 className="p-column-filter"
	/>;

	const [selectedFormFactor, setSelectedFormFactor] = useState([]);
	const onFormFactorChange = (e) => {
		dt.current.filter(e.value, 'formFactor', 'in');
		setSelectedFormFactor(e.value);
	}

	const formFactorFilter = <MultiSelect value={selectedFormFactor}
										  options={FormFactorObjects} optionLabel = "label" optionValue = "value"
										  onChange={onFormFactorChange}
										  placeholder={intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
										  className="p-column-filter"
	/>;

	const formFactorBodyTemplate = (rowData) => {
		return getFormFactorLabel(rowData.formFactor);
	}

	const [selectedOSVersions, setSelectedOSVersions] = useState([]);
	const [osVersions, setOSVersions] = useState([]);

	const onOSVersionsChange = (e) => {
		dt.current.filter(e.value, 'os', 'in');
		setSelectedOSVersions(e.value);
	}

	const osVersionFilter = <MultiSelect value={selectedOSVersions}
											 options={osVersions}
											 onChange={onOSVersionsChange}
											 placeholder={intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
											 className="p-column-filter"
	/>;

	const resolutionBodyTemplate = (rowData) => {
		return `${rowData.resolution.height}x${rowData.resolution.width}`;
	}

	const [selectedResolutions, setSelectedResolutions] = useState([]);
	const [resolutions, setResolutions] = useState([]);

	const onResolutionChange = (e) => {
		dt.current.filter(e.value, 'resolution', 'custom');
		setSelectedResolutions(e.value);
	}

	const resolutionsFilter = <MultiSelect value={selectedResolutions}
										 options={resolutions}
										 onChange={onResolutionChange}
										 placeholder={intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
										 className="p-column-filter"
	/>;

	// TODO add to tricks learned
	const resolutionFilterFunction = (value, filter) => {
		const res = `${value.height}x${value.width}`;
		return filter.includes(res);
	}

	const [selectedDevices, setSelectedDevices] = useState([]);
	const [showDeviceLimitMessage, setShowDeviceLimitMessage] = useState(false);

	const hideDeviceLimitMessageSnackbar = () => {
		setShowDeviceLimitMessage(false);
	}

	const selectDevices = (devices) => {
		if(devices.length > 5){
			// show message that says you cannot select more than 5 devices
			setShowDeviceLimitMessage(true);
		} else {
			setSelectedDevices(devices);
		}
	}

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 250
		},
		valid: true,
		validationMessage: null
	});

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event.target.value, 'deviceGroupName', intl);
		setName(updatedName);
	}

	const [description, setDescription] = useState({
		value: '',
		validation: {
			required: false,
			maxLength: 250
		},
		valid: true,
		validationMessage: null
	});

	const descriptionChangeHandler = (event) => {
		const updatedDescription = changeText(description, event.target.value, 'description', intl);
		setDescription(updatedDescription);
	}

	useEffect(() => {
		if(props.toUpdateGroup){
			let updatedElement = {...name};
			updatedElement.value = props.toUpdateGroup.name
			setName(updatedElement);

			updatedElement = {...description};
			updatedElement.value = props.toUpdateGroup.description ? props.toUpdateGroup.description : '';
			setDescription(updatedElement);

			setSelectedDevices(props.toUpdateGroup.devices);
		}
	}, [props.toUpdateGroup])

	const saveDeviceGroup = (event) => {
		if(event) {event.preventDefault();}

		// validate all
		if (!validateForm()) {
			return null;
		}

		const deviceGroup = {
			name: name.value,
			description: description.value,
			devices: getDevicesIDsForBackend()
		}

		props.saveDeviceGroup(deviceGroup);
	}
	props.registerSaveGroupCallback(saveDeviceGroup);

	const getDevicesIDsForBackend = () => {
		const ids = selectedDevices.map(device => {
			return {id: device.id};
		});
		// console.log('ids', ids);
		return ids;
	}

	const validateForm = () => {
		let allValid = true;

		const updatedName = changeText(name, name.value, 'deviceGroupName', intl);
		setName(updatedName);
		allValid = allValid && updatedName.valid;

		const updatedDescription = changeText(description, description.value, 'description', intl);
		setDescription(updatedDescription);
		allValid = allValid && updatedDescription.valid;

		// there must be atleast 1 device selected

		if(selectedDevices.length === 0){
			setShowNoDeviceSelectedAlertMessage(true);
			return false;
		}

		return allValid;
	}

	const [showNoDeviceSelectedAlertMessage, setShowNoDeviceSelectedAlertMessage] = useState(false);

	useEffect(() => {
		if(props.incompatibleDevice !== null){
			setShowIncompatibleDeviceAlertMessage(true);
		} else {
			setShowIncompatibleDeviceAlertMessage(false);
		}
	}, [props.incompatibleDevice]);

	const [showIncompatibleDeviceAlertMessage, setShowIncompatibleDeviceAlertMessage] = useState(false);

	return <div className={`bg-white p-5 rounded`}>

		{/*<div className="container p-0">*/}
		{/*	<div className="row">*/}
				{/*<div className="col-auto">*/}
				{/*	<Button onClick={() => saveDeviceGroup()} variant='contained' className='w-lg-150px'>Create</Button>*/}
				{/*</div>*/}
				{/*<div className="col">*/}
		<h1 className='text-center'>
			{props.type === 'update' ?
			intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.FORM.TITLE.UPDATE", defaultMessage: 'Update Device Group' }) :
			intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.FORM.TITLE.NEW", defaultMessage: 'New Device Group' })}
		</h1>
				{/*</div>*/}
		{/*	</div>*/}
		{/*</div>*/}
		<TextField
		id = "name"
		name = "name"
		label = {intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.LABEL.NAME", defaultMessage: 'Device Group Name'})}
		// className = {`${classes.textField}`}
		value = {name.value}
		onChange = {nameChangeHandler}
		// onBlur={() => props.syncValueWithParentContainer('label')}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.PLACEHOLDER.NAME", defaultMessage: 'Galaxies or Android Android 13 Phones'})}
		error = {!name.valid}
		helperText = {name.validationMessage}
		// disabled={props.creationLoading}
	/>
		<TextField
			id = "description"
			name = "description"
			label = {intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.LABEL.DESCRIPTION", defaultMessage: 'Description'})}
			// className = {`${classes.textField}`}
			value = {description.value}
			onChange = {descriptionChangeHandler}
			// onBlur={() => props.syncValueWithParentContainer('label')}
			margin = "normal"
			variant = "outlined"
			placeholder = {intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.PLACEHOLDER.DESCRIPTION", defaultMessage: 'Description'})}
			error = {!description.valid}
			helperText = {description.validationMessage}
			// disabled={props.creationLoading}
		/>
		{showNoDeviceSelectedAlertMessage && <Alert severity="error" onClose={() => setShowNoDeviceSelectedAlertMessage(false)}>
			<FormattedMessage id="DEVICE.GROUP.DIALOG.FORM.ERROR.NO.SELECTION" defaultMessage='No device is selected'/>
		</Alert>}

		{(showIncompatibleDeviceAlertMessage && props.incompatibleDevice) && <div>
			<Alert severity="error" className="mb-2 h6" variant="filled"
				   onClose={() => setShowIncompatibleDeviceAlertMessage(false)}>
				{intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.ERROR.1", defaultMessage: 'Device Group was not'})}
				{props.type === 'update' ?
					intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.ERROR.3", defaultMessage: 'Updated'}) :
					intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.ERROR.2", defaultMessage: 'Created'})}
				{intl.formatMessage({id: "DEVICE.GROUP.DIALOG.FORM.ERROR.4", defaultMessage: ', the bellow devices are not compatible'})}
			</Alert>
			{props.incompatibleDevice.devices.map(device => device.reason ? <Alert severity="error" className="mb-2" key={device.id}>
				{props.devices.find(deviceInfo => deviceInfo.id === device.id).name + ' : ' + device.reason}
		</Alert> : null)}
		</div>}

		<DataTable value={props.devices} ref={dt} selection={selectedDevices} className='mt-3'
				   onSelectionChange={(e) => selectDevices(e.value)}>
			{/*<Column field="name" columnKey='name'*/}
			{/*		header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.NAMEEEE", defaultMessage: 'Name'})}*/}
			{/*		sortable filter*/}
			{/*		filterPlaceholder={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.NAME.FILTER.PLACEHOLDEREEE", defaultMessage: 'Search by name'})}*/}
			{/*		filterMatchMode='contains'*/}
			{/*/>*/}

			<Column selectionMode="multiple" headerStyle={{width: '3em'}}/>

			<Column field="model" columnKey='model'
					header={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.TABLE.COLUMN.MODEL", defaultMessage: 'Model'})}
					sortable filter
					filterPlaceholder={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.TABLE.COLUMN.MODEL.FILTER.PLACEHOLDER", defaultMessage: 'Search by model'})}
					filterMatchMode='contains'
			/>
			<Column field="manufacturer" columnKey='manufacturer'
					header={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.TABLE.COLUMN.MANUFACTURER", defaultMessage: 'Manufacturer'})}
					sortable
					filter filterElement={manufacturersFilter}
			/>
			<Column field="formFactor" columnKey='formFactor'
					body={formFactorBodyTemplate}
					header={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.TABLE.COLUMN.FORM.FACTOR", defaultMessage: 'Form Factor'})}
					sortable
					filter filterElement={formFactorFilter}
			/>
			<Column field='os' columnKey='os'
					header={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.TABLE.COLUMN.OS.VERSION", defaultMessage: 'OS Version'})}
					sortable
					filter filterElement={osVersionFilter}
			/>

			<Column field="resolution" columnKey='resolution'
					body={resolutionBodyTemplate}
					header={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.TABLE.COLUMN.RESOLUTION", defaultMessage: 'Resolution'})}
					sortable
					filter filterElement={resolutionsFilter} filterFunction={resolutionFilterFunction}
			/>

		</DataTable>

		<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center',}}
				  open={showDeviceLimitMessage}  onClose={hideDeviceLimitMessageSnackbar}
				  message={intl.formatMessage({ id: "DEVICE.GROUP.DIALOG.DEVICE.LIMIT.SNACKBAR.MESSAGE",
					  defaultMessage: 'The maximum devices you can select is 5 devices, you cannot select more.'})}>
			<Alert
				// onClose={hideTransactionSuccessfullSnackbar}
				severity="warning" className={`h2  mt-20 `}
				action={
					<IconButton color="inherit" onClick={hideDeviceLimitMessageSnackbar} >
						<CloseIcon/>
					</IconButton>
				}>
				<FormattedMessage id="DEVICE.GROUP.DIALOG.DEVICE.LIMIT.SNACKBAR.MESSAGE"
								  defaultMessage='The maximum devices you can select is 5 devices, you cannot select more.'/>
			</Alert>
		</Snackbar>
	</div>
}

export default DeviceGroupJSX;