import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Snackbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Alert} from "@material-ui/lab";

import {validateParameters} from "../Rest/Validator";
import RestRequestTabParameters from "../../components/Rest/RestRequestTabParameters";
import * as actions from "../../store/actions";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const AddVariablesToSuiteForm = props => {
	const intl = useIntl();
	const classes = useStyles();

	// Parameters
	const [parameters, setParameters] = useState([{key: '', value: ''}]);

	const [errorMessages, setErrorMessages] = useState([]);

	// Handle updating/updated here
	const [suiteUpdateSnackbarOpen, setSuiteUpdateSnackbarOpen] = useState(false);
	const [suiteUpdateSnackbarMessage, setSuiteUpdateSnackbarMessage] = useState('');

	useEffect(() => {
		if(props.updatingLoading === true){
			showSuiteSnackbar(intl.formatMessage({ id: "SUITE.SNACKBAR.UPDATING", defaultMessage: 'Updating Suite'}));
		}
		if (!props.updatingLoading && suiteUpdateSnackbarOpen) {
			showSuiteSnackbar(intl.formatMessage({ id: "SUITE.SNACKBAR.UPDATED", defaultMessage: 'Suite Updated'}));
		}
	}, [props.updatingLoading]);

	const showSuiteSnackbar = (message) => {
		setSuiteUpdateSnackbarOpen(true);
		setSuiteUpdateSnackbarMessage(message);
	}

	const hideSuiteSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSuiteUpdateSnackbarOpen(false);
	}

	useEffect(() => {
		if(props.suite){
			loadParameters();
		}
	}, [props.suite]);

	const loadParameters = () => {
		// key, value => [{'key': key, 'value': value}]
		const parametersList = [];
		// console.log('loadParameters', props.suite.variables);
		if(props.suite.variables){
			Object.keys(props.suite.variables).forEach(parameter => {
				parametersList.push({key: parameter, value: props.suite.variables[parameter]});
			});
			if(parametersList.length !== 0){
				// console.log('parametersList', parametersList);
				setParameters(parametersList);
			}
		}
	}

	const buildParameters = (parametersRes) => {
		const parametersAsObject = {};
		for(let param of parametersRes){
			parametersAsObject[param.key] = param.value;
		}
		return parametersAsObject;
	}

	const cleanAndBuildParameters = () => {
		const parametersResult = [...parameters];
		// Clean headers and parameters
		// make sure that parameters & headers: if length is 1 and key is empty it means, empty object
		if (parametersResult.length === 1 && parametersResult[0].key.trim() === '') {
			parametersResult.splice(0, 1);
		}

		const parametersObject = buildParameters(parametersResult);
		return parametersObject;
	}

	// save: parameters = cleanAndBuildParameters();

	const saveVariables = () => {
		const updatedGlobalMessage = [];

		if(!validateParameters(updatedGlobalMessage, parameters, intl, setErrorMessages, () => {})){
			return null;
		}
		// console.log('Save Variables');
		const parametersBuilt = cleanAndBuildParameters();
		// console.log('variables', parametersBuilt);
		const suitePatched = {
			variables: {value: parametersBuilt},
		};
		const stateSuite = {
			variables: parametersBuilt
		};
		const updatedSuite = {...props.suite};
		updatedSuite.variables = parametersBuilt;
		props.updateSuite(updatedSuite);
		props.onUpdateSuite(props.suite.id, suitePatched, stateSuite);

	}

	return <div>
		<div className='min-h-lg-200px'>
			{props.updatingError && <Alert elevation={6} variant="standard" severity="error" className='mb-3'>
				{props.updatingError}
			</Alert>}
			<RestRequestTabParameters parameters={parameters} setParameters={setParameters}/>
		</div>
		<div className={classes.buttons + ' mt-10'}>
			<Button variant="contained" onClick={saveVariables} className='' disabled={props.updatingLoading}>
				<FormattedMessage id="BUTTON.SAVE" defaultMessage='Save'/>
			</Button>
		</div>
		<Snackbar
			anchorOrigin = {{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open = {suiteUpdateSnackbarOpen}
			autoHideDuration = {2000}
			onClose = {hideSuiteSnackbar}
			message = {suiteUpdateSnackbarMessage}
		>
			<Alert onClose = {hideSuiteSnackbar} severity = "success">
				{suiteUpdateSnackbarMessage}
			</Alert>
		</Snackbar>
	</div>;

}
const mapStateToProps = state => {
	return {
		updatingLoading: state.suite.updatingLoading,
		updatingError: state.suite.updatingError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateSuite: (id, suite, stateSuite) => dispatch(actions.updateSuite(id, suite, stateSuite)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVariablesToSuiteForm);