import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import SprintsDisplay from "../../components/Sprint/SprintsDisplay";

const SprintsSection = (props) => {
	const intl = useIntl();

	// const [emptyProjects, setEmptyProjects] = useState(false);
	const [sprints, setSprints] = useState(props.sprints);
	const [totalSprints, setTotalSprints] = useState(props.totalSprints);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 50,
		page: 0,
		pageCount: 1,
		sortField: 'label',
		sortOrder: 1
	});
	const dt = useRef(null);

	const filterTimeout = useRef(0);
	useEffect(() => {
		clearTimeout(filterTimeout.current);
		filterTimeout.current = setTimeout(() => {
			if (props.project && props.sprints !== null) {
				props.onLoadSprints(props.project.id, createLazyParamsForBackend());
			}
		}, 500);
	}, [lazyParams]);

	// TODO optimize me: two call for onLoadSprints
	// when visiting bookmark url /sprints directly
	useEffect(() => {
		if (props.project && props.sprints === null && props.loading === false) {
			props.onLoadSprints(props.project.id,
				createLazyParamsForBackend()
			);
		}
	}, [props.project, props.sprints]);

	const createLazyParamsForBackend = () => {
		const params = {};
		params.size = lazyParams.rows;
		// convert first to page => 0 => 0, 15 => 1, 30 => 2
		params.page = lazyParams.first / lazyParams.rows;
		// console.log('lazyParams.filters', lazyParams.filters);
		if(lazyParams.filters){
			Object.keys(lazyParams.filters).forEach(filter => {
				params[filter] = lazyParams.filters[filter].value;
				// }
			});
		}

		if(lazyParams.hasOwnProperty('sortField')){
			params.sortField = lazyParams.sortField;
			if (lazyParams.sortOrder === 1) {
				params.sortOrder = 'ASC';
			} else {
				params.sortOrder = 'DESC';
			}
		}
		return params;
	}

	useEffect(() => {
		setSprints(props.sprints);
	}, [props.sprints]);

	useEffect(() => {
		setTotalSprints(props.totalSprints);
	}, [props.totalSprints]);

	useEffect(() => {
		document.title = intl.formatMessage({ id: "PAGE.TITLE.SPRINTS",
			defaultMessage: 'Sprints' });
	}, []);

	// setTimeout(() => {
	// 	// optimization ignore if project is true
	// 	if(!props.project){
	// 		setEmptyProjects(true);
	// 	}
	// }, 15000);

	const deleteSprint = (id) => {
		const redirect = '/sprints';
		props.onDeleteSprint(id, redirect);
	}

	useEffect(() => {
		if(props.refreshList === true){
			// console.log('Calling onLoadSprints 3');
			props.onLoadSprints(props.project.id,
				createLazyParamsForBackend()
			);
		}
	}, [props.refreshList]);

	const onPage = (event) => {
		// console.log('onPage event:', event)
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		// console.log('onSort event:', event)
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onFilter = (event) => {
		// console.log('onFilter event:', event);
		let _lazyParams = { ...lazyParams, ...event };
		_lazyParams['first'] = 0;
		setLazyParams(_lazyParams);
	}

	const projectName = props.project ? props.project.label : null;

	let sprintID = props.match.params.sprintID;
	if(!sprintID && props.sprint){
		sprintID = props.sprint.id;
	}

	return (
		<SprintsDisplay
			projectName = {projectName}
			error = {props.error}
			delete = {deleteSprint}
			sprints = {sprints}
			sprintID = {sprintID}
			// emptyProjects={emptyProjects}
			onFilter={onFilter}
			onSort={onSort}
			onPage={onPage}
			totalSprints={totalSprints}
			lazyParams={lazyParams}
			dt={dt}
			loading={props.loading}
		/>
	);
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		sprints: state.sprint.sprints,
		sprint: state.sprint.sprint,
		error: state.sprint.listAllError,
		loading: state.sprint.listAllLoading,
		totalSprints: state.sprint.totalRecords,
		refreshList: state.sprint.refreshList,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadSprints: (projectID, config) => dispatch(actions.loadSprints(projectID, config)),
		onDeleteSprint: (id, redirect) => dispatch(actions.deleteSprint(id, redirect))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SprintsSection);