import React, {useEffect, useRef, useState} from "react";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import CloudUpload from "@material-ui/icons/CloudUpload";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Input} from "@material-ui/core";
import {apkUploadHandler} from "../../components/Project/Shared";
import {loadTestFiles, uploadTestFile} from "../../store/actions";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Alert} from "@material-ui/lab";
import CircularProgress from '@material-ui/core/CircularProgress';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {makeStyles} from "@material-ui/styles";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const FilesForTest = props => {
	const intl = useIntl();
	const classes = useStyles();

	// const [file, setFile] = useState({
	// 	binary: new Blob(), // always an empty blob initially not props.apk
	// 	label: '',
	// });

	const [startedUploading, setStartedUploading] =useState(false);
	const [doneUploadingMessageVisible, setDoneUploadingMessageVisible] =useState(false);

	useEffect(() => {
		if(props.uploadTestFileLoading === true){
			setStartedUploading(true);
		}
	}, [props.uploadTestFileLoading]);

	useEffect(() => {
		if(startedUploading === true && props.uploadTestFileLoading === false){
			setDoneUploadingMessageVisible(true);
		}
	}, [startedUploading, props.uploadTestFileLoading]);

	const hideDoneUploadingMessage = () => {
		setDoneUploadingMessageVisible(false);
		setStartedUploading(false);
	}

	useEffect(() => {
		if(props.project){
			props.onLoadTestFiles(props.project.id);
		}
	}, [props.project]);


	const uploadFile = (event) => {
		console.log('is it even called', event.target);
		console.log('event.target.files[0]', event.target.files[0]);
		console.log('event.target.files[0].name', event.target.files[0].name);

		// const updatedfile = {...file};
		// updatedfile.binary = event.target.files[0];
		// updatedfile.label = event.target.files[0].name;
		// setFile(updatedfile);


		const binary = event.target.files[0];
		const name = event.target.files[0].name;

		props.onUploadTestFile(props.project.id, binary, name);
	}

	const [deletionCandidate, setDeletionCandidate] = useState(null);
	const [deletionConfirmationDialogVisible, setDeletionConfirmationDialogVisible] = useState(false);

	const displayDeletionConfirmationDialog = () => {
		setDeletionConfirmationDialogVisible(true);
	}

	const hideDeletionConfirmationDialog = () => {
		setDeletionConfirmationDialogVisible(false);
		setDeletionCandidate(null);
	}

	const fileActionsBodyTemplate = (rowData) => {
		return (
			<div className = {` ${classes.root}`}>
				<a title = {intl.formatMessage({ id: "DOMAINS.TESTS.TABLE.ACTIONS.REMOVE.TEST??", defaultMessage: 'Delete File'})}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm "
				   onClick={(event) => {
					   event.preventDefault();
					   setDeletionCandidate(rowData);
					   displayDeletionConfirmationDialog();
				   }}>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
						<SVG title = {intl.formatMessage({ id: "DOMAINS.TESTS.TABLE.ACTIONS.REMOVE.TEST", defaultMessage: 'Delete File'})}
							 src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</div>);
	}

	const deleteFile = (id) => {
		props.onDeleteTestFile(id, props.project.id);
	}

	return <div className='bg-white'>
		<Container>
			<Row>
				<Col md='auto'>
					<Button component="label" variant="contained" size='large' startIcon={<CloudUpload/>} className='my-3'
							disabled={props.uploadTestFileLoading}>
						<FormattedMessage id = "PROJECT.LABEL.APK??" defaultMessage = 'Upload file'/>
						<Input type = 'file' id = 'upload' hidden = {true} onChange = { event => uploadFile(event)} onClick={event => event.target.value = null} />
					</Button>
				</Col>
				<Col>
					{props.uploadTestFileLoading && <Alert severity="info" className="my-3 py-1 h-38px"
						   // onClose={() => props.setMoveErrorVisible(false)}
						   icon={<CircularProgress size={20}/> }>
						<FormattedMessage id="MOVE.TEST.ERROR.NO.DOMAIN.SELECTED??" defaultMessage='Uploading file'/>
					</Alert>}
					{(doneUploadingMessageVisible && props.uploadTestFileError) && <Alert severity="error" className="my-3 py-1 "
						onClose={() => hideDoneUploadingMessage()}>
						{props.uploadTestFileError}
					</Alert>}
					{(doneUploadingMessageVisible && !props.uploadTestFileError) && <Alert severity="success" className="my-3 py-1 "
																						   onClose={() => hideDoneUploadingMessage()}>
						<FormattedMessage id="MOVE.TEST.ERROR.NO.DOMAIN.SELECTED??" defaultMessage='Upload completed successfull'/>
					</Alert>}
				</Col>
			</Row>
			<Row>
				<Col>
					<DataTable value={props.testFiles} paginator rows={50} rowsPerPageOptions = {[10, 20, 50, 100]}
							   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							   paginatorTemplate = "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
							   emptyMessage={intl.formatMessage({ id: "MOVE.TEST.EMPTY.DOMAINS.LIST??", defaultMessage: 'No Files Uploaded yet'})}>
						<Column field="name" columnKey='name'
								header={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME??", defaultMessage: 'Name'})} sortable filter
								filterPlaceholder={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER??", defaultMessage: 'Search by name'})}
								filterMatchMode='contains'
						/>
						<Column field="type" columnKey='type'
								header={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME??", defaultMessage: 'Type'})} sortable filter
								filterPlaceholder={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER??", defaultMessage: 'Search by type'})}
								filterMatchMode='contains'
						/>
						<Column field="size" columnKey='size'
								header={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME??", defaultMessage: 'Size'})} sortable
								// filter
								// filterPlaceholder={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER??", defaultMessage: 'Search by size'})}
								// filterMatchMode='contains'
						/>
						<Column header={intl.formatMessage({id: 'DEVICE.GROUP.MANAGEMENT.TABLE.COLUMN.ACTIONS??', defaultMessage: 'Actions'})}
								body={fileActionsBodyTemplate}
								className='p-text-right'
								headerStyle={{width: '10rem'}}
						/>
					</DataTable>
				</Col>
			</Row>
		</Container>
		<DeletionConfirmationDialog open={deletionConfirmationDialogVisible} closeDialog={hideDeletionConfirmationDialog}
									message={<FormattedMessage id="DEVICE.GROUP.MANAGEMENT.REMOVE.MESSAGE"
															   defaultMessage='Remove Device Group'/>}
									name={deletionCandidate ? `${deletionCandidate.name}` : ''}
									deleteAction={() => {
										hideDeletionConfirmationDialog();
										deleteFile(deletionCandidate.id);
									}}
		/>
	</div>
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		testFiles: state.project.testFiles,
		uploadTestFileLoading: state.project.uploadTestFileLoading,
		uploadTestFileError: state.project.uploadTestFileError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadTestFiles: (id) => dispatch(actions.loadTestFiles(id)),
		onUploadTestFile: (id, file, name) => dispatch(actions.uploadTestFile(id, file, name)),
		onDeleteTestFile: (id, projectId) => dispatch(actions.deleteTestFile(id, projectId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesForTest);