import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {AppBar, Button, Dialog, Slide, Toolbar, Typography} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Col, Container, Row} from "react-bootstrap";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {MultiSelect} from "primereact/multiselect";

import {HttpMethodObjects} from "../Rest/enum/HttpMethod";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SuiteRequisitesAdditionForm = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [formOpen, setFormOpen] = useState(false);

	const showForm = () => {
		setFormOpen(true);
		// props.clear();
	};

	const hideForm = () => {
		setFormOpen(false);
	};

	const save = () => {
		if (props.submitAddRequestsHandler()) {
			hideForm();
		}
	}

	const methodFilter = <MultiSelect value={props.selectedMethods}
									  options={HttpMethodObjects}
									  onChange={props.onMethodChange} optionLabel="label"
									  optionValue="value"
									  placeholder={intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
									  className="p-column-filter"/>;

	const addRequisitesButtonText = props.type === 'pre' ?
		<FormattedMessage id="SUITE.PREREQUISITE.BUTTON.ADD" defaultMessage='Prerequisites'/> :
		<FormattedMessage id="SUITE.POSTREQUISITE.BUTTON.ADD" defaultMessage='Postrequisites'/>;

	const addRequisitesHeaderText = props.type === 'pre' ?
		<FormattedMessage id="SUITE.PREREQUISITE.TITLE.ADD" defaultMessage='Prerequisites'/> :
		<FormattedMessage id="SUITE.POSTREQUISITE.TITLE.ADD" defaultMessage='Postrequisites'/>

	return <>
		<Button variant="contained"
				size='small'
				startIcon={<AddIcon/>}
				onClick={showForm}
				className=''>
			{addRequisitesButtonText}
		</Button>
		<Dialog fullScreen open={formOpen} onClose={hideForm} TransitionComponent={Transition} style={{zIndex: 900}}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={hideForm} aria-label="close">
						<CloseIcon/>
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{addRequisitesHeaderText}
					</Typography>
					<Button autoFocus color="inherit" onClick={save}>
						<FormattedMessage id="BUTTON.SAVE"
										  defaultMessage='Save'/>
					</Button>
				</Toolbar>
			</AppBar>
			<div className="bg-white">
				<Container>
					<Row>
						<Col>
							<DataTable value={props.addRequests.requests} autoLayout={true} ref={props.dt}
									   selection={props.addRequests.selectedNoIndex}
									   onSelectionChange={(event) => props.addRequestsSelectHandler(event.value)}>
								<Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
								<Column field="label" columnKey='label'
										header={intl.formatMessage({id: "REST.REQUESTS.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
										sortable filter
										filterPlaceholder={intl.formatMessage({
											id: "REST.REQUESTS.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER", defaultMessage: 'Search by name'})}
										filterMatchMode='contains'/>
								<Column field="method" columnKey='method'
										header={intl.formatMessage({id: "REST.REQUESTS.TABLE.COLUMN.METHOD", defaultMessage: 'Method'})}
										sortable filter filterPlaceholder="Search by Method" filterElement={methodFilter}/>
								<Column field="uri" columnKey='uri'
										header={intl.formatMessage({id: "REST.REQUESTS.TABLE.COLUMN.URL", defaultMessage: 'URL'})}
										sortable filter
										filterPlaceholder={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.URL.FILTER.PLACEHOLDER",
											defaultMessage: 'Search by URL'})}
										filterMatchMode='contains'/>
							</DataTable>
						</Col>
					</Row>
				</Container>
			</div>
		</Dialog>
	</>;

}

export default SuiteRequisitesAdditionForm;