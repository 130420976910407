import React, {useState} from "react";
import {AppBar, Button, Dialog, Slide, Toolbar, Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button as PrimeButton} from 'primereact/button';
import {Alert} from "@material-ui/lab";

import tableStyle from './SuiteAddRequestsForm.module.css'

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const SuiteAddRequestsForm = props => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		// props.clear();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const save = () => {
		if(props.submitAddRequestsHandler()){
			handleClose();
		}
	}

	const [expandedRows, setExpandedRows] = useState({});

	const domainLabelBodyTemplate = (rowData) => {
		return <h3 className="">
			{rowData.label}
		</h3>;
	}

	const requestsRowExpansionTemplate = (data) => {
		return (
			<div className="">
				{/*<h5>Requests for {data.label}</h5>*/}
				<DataTable value={data.restApis} autoLayout={true}
						   selection={props.addRequests.selectedRequestsNoIndex}
						   onSelectionChange={(event) => props.addRequestsSelectHandler(event.value)}
				>
					<Column selectionMode="multiple" className={tableStyle.PSelectionColumn}
							headerClassName={tableStyle.PDatatableThead}
					/>
					<Column field="label" columnKey='label' headerClassName={tableStyle.PDatatableThead}/>
					<Column field="method" columnKey='method' headerClassName={tableStyle.PDatatableThead}/>
					<Column field="uri" columnKey='uri' headerClassName={tableStyle.PDatatableThead}/>
				</DataTable>
			</div>
		);
	}

	const expandAll = () => {
		let expandedRows = {};
		props.addRequests.requests.forEach(domain => expandedRows[`${domain.id}`] = true);
		setExpandedRows(expandedRows);
	}

	const collapseAll = () => {
		setExpandedRows({});
	}

	const header = (
		<div className="table-header-container">
			<PrimeButton icon="pi pi-plus" label={intl.formatMessage({id: "BUTTON.EXPAND.ALL", defaultMessage: 'Expand All'})}
						 onClick={expandAll} className="p-mr-2" />
			<PrimeButton icon="pi pi-minus" label={intl.formatMessage({id: "BUTTON.COLLAPSE.ALL", defaultMessage: 'Collapse All'})}
						 onClick={collapseAll} />
		</div>
	);

	return (
		<>
			<Button variant = "contained"
			        size = 'small'
			        startIcon = {<AddIcon/>}
			        onClick = {handleClickOpen}
			        className = ''>
				<FormattedMessage id = "SUITE.REQUESTS.ADDITION.ADD.REQUESTS.BUTTON" defaultMessage = 'Add Requests'/>
			</Button>
			<Dialog fullScreen open = {open} onClose = {handleClose} TransitionComponent = {Transition} style={{zIndex: 900}}>
				<AppBar className = {classes.appBar}>
					<Toolbar>
						<IconButton edge = "start" color = "inherit" onClick = {handleClose} aria-label = "close">
							<CloseIcon/>
						</IconButton>
						<Typography variant = "h6" className = {classes.title}>
							<FormattedMessage id = "SUITE.REQUESTS.ADDITION.ADD.REQUESTS.TITLE" defaultMessage = 'Add Requests'/>
						</Typography>
						<Button autoFocus color = "inherit" onClick = {save}>
							<FormattedMessage id="BUTTON.SAVE" defaultMessage='Save'/>
						</Button>
					</Toolbar>
				</AppBar>
				<div className = "bg-white">
					<Container>
						<Row>
							<Col>
								{props.addRequests.validationMessage && <Alert elevation = {3} variant = "standard"
																			   severity = "error" className = 'my-3'>
									{props.addRequests.validationMessage}
								</Alert>}
								<DataTable value={props.addRequests.requests} expandedRows={expandedRows}
										   onRowToggle={(e) => setExpandedRows(e.data)}
										   // onRowExpand={this.onRowExpand} onRowCollapse={this.onRowCollapse}
										   rowExpansionTemplate={requestsRowExpansionTemplate} dataKey="id"
										   header={header}
										   emptyMessage = {
											   intl.formatMessage({id: "SUITE.REQUESTS.ADDITION.NO.REQUESTS",
												   defaultMessage: 'No Requests To Select From'})
										   }
								>
									<Column expander style={{ width: '3em' }} />
									<Column field="label" columnKey='label'
											header={intl.formatMessage({
												id: "SUITE.REQUESTS.ADDITION.TABLE.COLUMN.DOMAIN.NAME", defaultMessage: 'Name'})}
											body={domainLabelBodyTemplate}/>
								</DataTable>
							</Col>
						</Row>
					</Container>
				</div>
			</Dialog>
		</>
	);
}

export default SuiteAddRequestsForm;