import * as actionTypes from "./actionTypes";

export const createTest = (test, domainID) => {
	return {
		type: actionTypes.CREATE_TEST,
		test: test,
		domainID: domainID
	}
};

export const createTestStart = () => {
	return {
		type: actionTypes.CREATE_TEST_START
	}
};

export const createTestSuccess = (test) => {
	return {
		type: actionTypes.CREATE_TEST_SUCCESS,
		test: test
	}
};

export const createTestFail = (error) => {
	return {
		type: actionTypes.CREATE_TEST_FAIL,
		error: error
	}
};

export const loadTest = (id) => {
	return {
		type: actionTypes.LOAD_TEST,
		id: id
	}
};

export const loadTestStart = () => {
	return {
		type: actionTypes.LOAD_TEST_START
	}
};

export const loadTestSuccess = (test) => {
	return {
		type: actionTypes.LOAD_TEST_SUCCESS,
		test: test
	}
};

export const loadTestFail = (error) => {
	return {
		type: actionTypes.LOAD_TEST_FAIL,
		error: error
	}
};

export const updateTest = (id, test) => {
	return {
		type: actionTypes.UPDATE_TEST,
		id: id,
		test: test
	}
};

export const updateTestStart = () => {
	return {
		type: actionTypes.UPDATE_TEST_START
	}
};

export const updateTestSuccess = () => {
	return {
		type: actionTypes.UPDATE_TEST_SUCCESS,
		test: null
	}
};

export const updateTestFail = (error) => {
	return {
		type: actionTypes.UPDATE_TEST_FAIL,
		error: error
	}
};

export const deleteTest = (id, redirect) => {
	return {
		type: actionTypes.DELETE_TEST,
		id: id,
		redirect: redirect
	}
};

export const deleteTestStart = () => {
	return {
		type: actionTypes.DELETE_TEST_START
	}
};

export const deleteTestSuccess = (id) => { // for removal from red state
	return {
		type: actionTypes.DELETE_TEST_SUCCESS,
		id: id
	}
};

export const deleteTestFromLoadedDomain = (id) => {
	return {
		type: actionTypes.DELETE_TEST_FROM_LOADED_DOMAIN,
		id: id
	}
};

export const deleteTestFail = (error) => {
	return {
		type: actionTypes.DELETE_TEST_FAIL,
		error: error
	}
};

export const loadProjectTests = (id) => {
	return {
		type: actionTypes.LOAD_PROJECT_TESTS,
		id: id
	}
};

export const loadProjectTestsStart = () => {
	return {
		type: actionTypes.LOAD_PROJECT_TESTS_START
	}
};

export const loadProjectTestsSuccess = (tests) => {
	return {
		type: actionTypes.LOAD_PROJECT_TESTS_SUCCESS,
		projectTests: tests
	}
};

export const loadProjectTestsFail = (error) => {
	return {
		type: actionTypes.LOAD_PROJECT_TESTS_FAIL,
		error: error
	}
};

export const nullifyTestError = () => {
	return {
		type: actionTypes.NULLIFY_TEST_ERROR
	}
};

export const nullifyUpdatingTestError = () => {
	return {
		type: actionTypes.NULLIFY_UPDATING_TEST_ERROR
	}
};

export const moveTestToDomain = (testID, sourceDomainId, targetDomainId) => {
	return {
		type: actionTypes.MOVE_TEST_TO_DOMAIN,
		testID: testID,
		sourceDomainId: sourceDomainId,
		targetDomainId: targetDomainId
	}
};

export const moveTestToDomainStart = () => {
	return {
		type: actionTypes.MOVE_TEST_TO_DOMAIN_START
	}
};

export const moveTestToDomainSuccess = () => {
	return {
		type: actionTypes.MOVE_TEST_TO_DOMAIN_SUCCESS,
	}
};

export const moveTestToDomainFail = (error) => {
	return {
		type: actionTypes.MOVE_TEST_TO_DOMAIN_FAIL,
		error: error
	}
};