import {checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import * as Action from '../../containers/Test/Actions';

export const changeText = (name, event, id, intl) => {
	const updatedName = {...name};
	updatedName.value = event.target.value;
	const [valid, message] = checkValidity(updatedName, UINames(id, intl), intl);
	updatedName.valid = valid;
	updatedName.validationMessage = message;
	return updatedName;
}

export const selectElement = (component, event, id, valueHolder, intl) => {
	const updatedComponent = {...component};
	updatedComponent[id] = event.target.value;
	[updatedComponent.valid, updatedComponent.validationMessage] =
		checkSelectionValidity(updatedComponent, valueHolder, UINames(id, intl), intl);
	return updatedComponent;
};

export const inputShouldBeVisible = (action) => {
	let component = true, identifier = true, value = true, toComponent = false;
	switch (action) {
		case Action.TYPE:
		case Action.SELECT:
		case Action.SEND_KEYS:
		case Action.ADD_SELECTION:
		case Action.REMOVE_SELECTION:
		case Action.WAIT_FOR_ELEMENT_PRESENT:
		case Action.STORE_VALUE:
		case Action.STORE_TEXT:
			return [component, identifier, value, false];
		case Action.DRAG_AND_DROP:
			toComponent = true;
			return [component, identifier, value, toComponent];
		case Action.CLICK:
		case Action.DOUBLE_CLICK:
		case Action.CHECK:
		case Action.UNCHECK:
		case Action.MOUSE_OUVER:
			value = false;
			return [component, identifier, value, false];
		case Action.SELECT_WINDOW:
		case Action.CHOOSE_OK_ON_NEXT_CONFIRMATION:
		case Action.WEBDRIVER_CHOOSE_OK_ON_VISIBLE_CONFIRMATION:
		case Action.WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_CONFIRMATION:
		case Action.WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_PROMPT:
		case Action.MOUSE_OUT:
			component = identifier = value = false;
			return [component, identifier, value, false];
		case Action.RUN_SCRIPT:
		case Action.WAIT_SECONDS:
		case Action.WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT:
			component = identifier = false;
			return [component, identifier, value, false];
		case Action.SELECT_FRAME:
			component = value = false;
			return [component, identifier, value, false];
		default:
			return [component, identifier, value, false];
	}
};