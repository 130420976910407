import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert, AlertTitle} from "@material-ui/lab";

import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";
import {AuthenticationTypeObjects} from "./enum/Authorities";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {StatusTypeObjects} from "./enum/Status";
import {getTopRoleObjectForDisplay} from "./Shared";
import * as Role from "../../containers/Users/enum/Roles";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const UsersDisplay = props => {
	const intl = useIntl();

	const [open, setOpen] = useState(false);
	const [user, setUser] = useState(null);

	const [showStatus, setShowStatus] = useState(false);

	const [canAddSagaInitiated, setCanAddSagaInitiated] = useState(false);


	useEffect(() => {
		if(props.canAddLoading === true){
			setCanAddSagaInitiated(true);
		}
	}, [props.canAddLoading]);

	useEffect(() => {
		if(localStorage.getItem('Top-Role') === Role.ROLE_SUPER){
			setShowStatus(true);
		}
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const userNameBodyTemplate = (rowData) => {
		return <Link to = {`/user/${rowData.login}`}
		             title = {intl.formatMessage({
			             id: 'USERS.TABLE.ROW.USERNAME.HOVER.INFO',
			             defaultMessage: 'View user'
		             })} className = 'text-secondary'>
			{rowData.login}
		</Link>;
	}

	const userRolesBodyTemplate = (rowData) => {
		const topRole = getTopRoleObjectForDisplay(rowData.authorities).label;

		return <div style={{whiteSpace: 'nowrap'}}>
			<span className={`label label-dot label-success mr-2`}/>
			<span className={`font-bold font-success`}>
				{topRole}
			</span>
		</div>;
	}

	const userStatusBodyTemplate = (rowData) => {
		let activeStyle;
		let activationStatusTitle;
		if (rowData.activated) {
			activeStyle = `label label-lg label-light-success label-inline`;
			activationStatusTitle = 'Active';
		} else {
			activeStyle = `label label-lg label-light-danger label-inline`;
			activationStatusTitle = 'Suspended';
		}

		const onClick = onClickFunction(rowData, props.disableToggle, props.mainType);

		let cursor = 'pointer';
		if (onClick === null) {
			cursor = 'default';
		}

		return (
			<span className = {activeStyle} style = {{cursor: cursor}}
				// onClick={() => disableToggleHanlder(row)}
				  onClick = {onClick}
			>
			{activationStatusTitle}
	    </span>
		);
	}

	const onClickFunction = (row, disableToggleHanlder, mainType) => {
		let onClick = () => disableToggleHanlder(row);
		if (row.login === props.loggedInUser.login) {
			onClick = null;
		}
		return onClick;
	}

	const userCreationDateBodyTemplate = (rowData) => {
		const htmll =
			<span>
				{new Date(rowData.createdDate).toLocaleDateString()}
				<br/>
				{new Date(rowData.createdDate).toLocaleTimeString()}
		</span>
		return htmll;
	}

	const userActionsBodyTemplate = (rowData) => {
		let deleteUser = <a
			title = {intl.formatMessage({
				id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.DELETE',
				defaultMessage: 'Delete User'
			})}
			className = "btn btn-icon btn-light btn-hover-danger btn-sm m-2"
			// onClick = {() => this.delete(rowData.login)}
			onClick = {(event) => {
				event.preventDefault();
				setUser(rowData);
				setOpen(true);
			}}
		>
	        <span className = "svg-icon svg-icon-md svg-icon-danger">
	          <SVG src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
	               title = {intl.formatMessage({
		               id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.DELETE',
		               defaultMessage: 'Delete User'
	               })}/>
	        </span>
		</a>;
		if (rowData.login === localStorage.getItem('username') || (localStorage.getItem('Top-Role') === Role.ROLE_SUPER && rowData.activated === true)) {
			deleteUser = null;
		}

		return (
			<>
				<Link to = {`/user/${rowData.login}`}
				      title = {intl.formatMessage({
					      id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.VIEW',
					      defaultMessage: 'View User'
				      })}
				      className = "btn btn-icon btn-light btn-hover-success btn-sm m-2"
				>
			        <span className = "svg-icon svg-icon-md svg-icon-success">
			          <SVG
				          src = {toAbsoluteUrl("/media/svg/icons/Map/Location-arrow.svg")}
				          title = {intl.formatMessage({
					          id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.VIEW',
					          defaultMessage: 'View User'
				          })}
			          />
			        </span>
				</Link>
				<Link to = {`/user/${rowData.login}/edit`}
				      title = {intl.formatMessage({
					      id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.EDIT',
					      defaultMessage: 'Edit User'
				      })}
				      className = "btn btn-icon btn-light btn-hover-primary btn-sm m-2"
				>
			        <span className = "svg-icon svg-icon-md svg-icon-primary">
			          <SVG
				          src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
				          title = {intl.formatMessage({
					          id: 'USERS.TABLE.ROW.ACTIONS.BUTTON.HOVER.EDIT',
					          defaultMessage: 'Edit User'
				          })}
			          />
			        </span>
				</Link>
				{deleteUser}
			</>
		);
	}

	const authoritiesFilter = <Dropdown value = {props.filterRole}
	                                    options = {AuthenticationTypeObjects}
	                                    onChange = {props.onRoleChange} optionLabel = "label"
	                                    optionValue = "value"
	                                    placeholder = {intl.formatMessage({
		                                    id: "ALL",
		                                    defaultMessage: 'All'
	                                    })}
	                                    className = "p-column-filter"
	                                    showClear />;

	const statusFilter = <Dropdown value = {props.filterStatus}
	                               options = {StatusTypeObjects}
	                               onChange = {props.onStatusChange} optionLabel = "label"
	                               optionValue = "value"
	                               placeholder = {intl.formatMessage({
		                               id: "ALL",
		                               defaultMessage: 'All'
	                               })}
	                               className = "p-column-filter"
	                               showClear/>;

	let statusColumn = null;
	if (showStatus === true) {
		statusColumn = <Column columnKey='activated' field='activated'
							   header={intl.formatMessage({id: 'USERS.TABLE.COLUMN.HEADER.STATUS', defaultMessage: 'Status'})}
							   body={userStatusBodyTemplate} sortable
							   filter filterElement={statusFilter}/>;
	}

	let newButton = null;
	if(canAddSagaInitiated === true){
		newButton = <Link type = "button" className = "btn btn-primary" to = '/users/new'>
			<FormattedMessage id = "USERS.BUTTON.NEW.USER"
							  defaultMessage = 'New User'/>
		</Link>;
	}

	let canAddUserAlert = null;
	if(canAddSagaInitiated === true && props.canAddLoading === false && props.canAdd === false){
		newButton = null;
		canAddUserAlert = <Alert elevation={2} variant="standard" severity="warning" >
			<AlertTitle>
				<h6>
					<FormattedMessage id="USERS.LIMIT.REACHED.1"
									  defaultMessage="You've Reached the maxixmum amount of Users you can create."/>
				</h6>
			</AlertTitle>

			<h6>
				<FormattedMessage id="USERS.LIMIT.REACHED.2"
								  defaultMessage="Click 'Upgrade To Premium' on the top of the page to lift the restrictions."/>
			</h6>

			{props.canAddError}
		</Alert>;
	}

	if(props.canAddLoading === true){
		newButton = null;
		canAddUserAlert = null;
	}

	if(localStorage.getItem('Top-Role') === Role.ROLE_SUPER){
		newButton = null;
	}

	return (
		<Card>

			{canAddUserAlert}

			<CardHeader title = {intl.formatMessage({
				id: 'USERS.TABLE.TITLE',
				defaultMessage: 'Users'
			})}>
				<CardHeaderToolbar>
					{newButton}
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				{props.deletionError && <Alert elevation={2} variant="standard" severity="error" className='my-3'
											   onClose={props.onHideDeletionError}>
					{props.deletionError}
				</Alert>}
				<DataTable value = {props.users} lazy ref = {props.dt}
				           paginator first = {props.lazyParams.first} rows = {props.lazyParams.rows}
				           className = "p-datatable-striped"
				           totalRecords = {props.totalUsers}
				           onPage = {props.onPage} onSort = {props.onSort}
				           sortField = {props.lazyParams.sortField} sortOrder = {props.lazyParams.sortOrder}
				           onFilter = {props.onFilter}
				           filters = {props.lazyParams.filters}
				           paginatorTemplate =
					           "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
				           currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
				           rowsPerPageOptions = {[10, 20, 50, 100]}
				           autoLayout = {true}
				           emptyMessage = {intl.formatMessage({
					           id: "USERS.EMPTY.LIST",
					           defaultMessage: 'No Users'
				           })}
				>
					<Column columnKey = 'login' field = 'login'
					        header = {intl.formatMessage({
						        id: 'USERS.TABLE.COLUMN.HEADER.USERNAME',
						        defaultMessage: 'User Name'
					        })}
					        body = {userNameBodyTemplate} sortable
					        filter filterPlaceholder = {intl.formatMessage({
						id: 'USERS.TABLE.COLUMN.SEARCH.BY.USERNAME',
						defaultMessage: 'Search by name'
					})}
					        filterMatchMode = 'contains'/>
					<Column columnKey = 'email' field = 'email'
					        header = {intl.formatMessage({
						        id: 'USERS.TABLE.COLUMN.HEADER.EMAIL',
						        defaultMessage: 'Email'
					        })}
					        sortable
					        filter filterPlaceholder = {intl.formatMessage({
						id: 'USERS.TABLE.COLUMN.SEARCH.BY.EMAIL',
						defaultMessage: 'Search by email'
					})}
					        filterMatchMode = 'contains'/>
					<Column columnKey = 'firstName' field = 'firstName'
					        header = {intl.formatMessage({
						        id: 'USERS.TABLE.COLUMN.HEADER.FIRSTNAME',
						        defaultMessage: 'First Name'
					        })}
					        sortable
					        filter filterPlaceholder = {intl.formatMessage({
						id: 'USERS.TABLE.COLUMN.SEARCH.BY.FIRSTNAME',
						defaultMessage: 'Search by first name'
					})}
					        filterMatchMode = 'contains'/>
					<Column columnKey = 'lastName' field = 'lastName'
					        header = {intl.formatMessage({
						        id: 'USERS.TABLE.COLUMN.HEADER.LASTNAME',
						        defaultMessage: 'Last Name'
					        })}
					        sortable
					        filter filterPlaceholder = {intl.formatMessage({
						id: 'USERS.TABLE.COLUMN.SEARCH.BY.LASTNAME',
						defaultMessage: 'Search by last name'
					})}
					        filterMatchMode = 'contains'/>
					<Column columnKey = 'authorities' field = 'authorities'
					        header = {intl.formatMessage({
						        id: 'USERS.TABLE.COLUMN.HEADER.AUTHORITIES',
						        defaultMessage: 'Authorities'
					        })}
					        body = {userRolesBodyTemplate}
							headerStyle={{ width: '10rem'}}
					        filter filterPlaceholder = "Filter by Authorities"
					        filterElement = {authoritiesFilter}/>

					{statusColumn}

					<Column columnKey = 'createdDate' field = 'createdDate'
					        header = {intl.formatMessage({
						        id: 'USERS.TABLE.COLUMN.HEADER.CREATED',
						        defaultMessage: 'Created'
					        })}
					        body = {userCreationDateBodyTemplate} sortable/>
					<Column header = {intl.formatMessage({
						id: 'USERS.TABLE.COLUMN.HEADER.ACTIONS',
						defaultMessage: 'Actions'
					})}
							body = {userActionsBodyTemplate}
							className = 'p-text-right'
							headerStyle={{ width: '13rem'}}
					/>
				</DataTable>

				<DeletionConfirmationDialog open={open} closeDialog={handleClose}
											message={<FormattedMessage id="USERS.DIALOG.REMOVE.USER" defaultMessage='Remove User '/>}
											name={user ? `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}` : ''}
											deleteAction={() => {
												handleClose();
												props.onDeleteUser(user.id);
											}}
				/>

			</CardBody>
		</Card>
	);

}

export default UsersDisplay;