import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";

import * as actions from "../../store/actions";
import {changeText} from "../../components/Users/Shared";

// TODO This should have a limit in the backend and must use captcha
const ForgotPassword = props => {
	const intl = useIntl();

	const [email, setEmail] = useState({
		value: '',
		validation: {
			required: true,
			isEmail: true
		},
		valid: true,
		validationMessage: null
	});

	const emailChangeHandler = (event) => {
		const updatedEmail = changeText(email, event.target.value, 'email', intl);
		setEmail(updatedEmail);
	}

	const validateEmail = () => {
		const updatedEmail = changeText(email, email.value, 'email', intl);
		setEmail(updatedEmail);
		return updatedEmail.valid;
	}

	const getUrlForNewPassword = () => {
		const validEmail = validateEmail();
		if(validEmail === false){
			// show error message, happen automatically
			return;
		}
		props.onForgotPassword(email.value);
	}

	useEffect(() => {
		document.title = intl.formatMessage({id: "PAGE.TITLE.FORGOT.PASSWORD", defaultMessage: 'Forgotten Password'});
		// TODO test see if it fire the action creator that fires the reducer
		props.initiliazeReducerValues();
	},[])

	return <div className="login-form login-forgot" style={{ display: "block" }}>
		<div className="text-center mb-10 mb-lg-20">
			<h3 className="font-size-h1">
				<FormattedMessage id = "FORGOT.PASSWORD"
								  defaultMessage = 'Forgotten Password ?'/>
			</h3>
			<div className="text-muted font-weight-bold">
				<FormattedMessage id = "FORGOT.PASSWORD.SUBTITLE"
								  defaultMessage = 'Enter your email to reset your password'/>
			</div>
		</div>
		<form className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">

			{(props.done === true && props.error !== null) && <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
				<div className="alert-text font-weight-bold">
					{props.error}
				</div>
			</div>}

			{(props.done === true && props.error === null) && <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
				<div className="alert-text font-weight-bold">
					<FormattedMessage id = "FORGOT.PASSWORD.REQUEST.SUCCESS"
									  defaultMessage = 'Request Sent Successfully, check your email inbox.'/>
				</div>
			</div>}

			<div className="form-group fv-plugins-icon-container">
				<input
					id="email"
					name="email"
					placeholder ={intl.formatMessage({
						id: 'REGISTRATION.LABEL.EMAIL', defaultMessage: 'Email'
					})}
					type="email"
					className={`form-control form-control-solid h-auto py-5 px-6`}
					onChange = {emailChangeHandler}
				/>

				{email.valid === false && <div className="fv-plugins-message-container">
					<div className="fv-help-block">{email.validationMessage}</div>
				</div>}

			</div>
			<div className="form-group d-flex flex-wrap flex-center">
				<Button
					variant="outlined" color="primary" id="kt_login_forgot_submit"
					className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					onClick={getUrlForNewPassword}>
					<FormattedMessage id="BUTTON.SUBMIT"
									  defaultMessage='Submit'/>
				</Button>

				<Link to="/auth">
					<button type="button" id="kt_login_forgot_cancel"
							className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
						<FormattedMessage id="BUTTON.CANCEL"
										  defaultMessage='Cancel'/>

					</button>
				</Link>
			</div>
		</form>
	</div>
}

const mapStateToProps = state => {
	return {
		done: state.authentication.passwordForgotDone,
		error: state.authentication.passwordForgotError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onForgotPassword: (email) => dispatch(actions.forgotPassword(email)),
		initiliazeReducerValues: () => dispatch(actions.forgotPasswordStart())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);