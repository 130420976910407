import axios from "axios";

export let backendUrl = '';
// File override command line argument
if(process.env.REACT_APP_API_URL){
	backendUrl = process.env.REACT_APP_API_URL;
}
const instance = axios.create({
	// baseURL: 'http://localhost:8186/api/',
	baseURL: backendUrl,
	timeout: 15000,
	// auth: {
	// 	username: 'user',
	// 	password: '3AAhRCJrbsc2mQno'
	// }
	// withCredentials: false,
	// headers: {
	// 	'Access-Control-Allow-Origin' : 'http://localhost:3000',
	// 	'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
	// }
});
instance.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');

export default instance;