// Candidate for Global.
import {checkArraySelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";

export const changeText = (name, event, id, intl) => {
	const updatedName = {...name};
	updatedName.value = event.target.value;
	const [valid, message] = checkValidity(updatedName, UINames(id, intl), intl);
	updatedName.valid = valid;
	updatedName.validationMessage = message;
	return updatedName;
}

export const changeDescription = (description, value, id, intl) => {
	const updatedDescription = {...description};
	updatedDescription.value = value;
	const [valid, message] = checkValidity(updatedDescription, UINames(id, intl), intl);
	updatedDescription.valid = valid;
	updatedDescription.validationMessage = message;
	return updatedDescription;
}

export const selectAddSuitesAction = (addSuites, event, intl) => {
	const updatedAddSuites = {...addSuites};
	updatedAddSuites.selected = event.target.value;
	[updatedAddSuites.valid, updatedAddSuites.validationMessage] =
		checkArraySelectionValidity(updatedAddSuites, 'selected', UINames('suite', intl), intl);
	return updatedAddSuites;
};