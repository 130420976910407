import {checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "../../containers/Rest/Messages";

export const changeText = (inputTextState, value, idMessageTranslation, intl) => {
	const updatedInputTextState = {...inputTextState};
	updatedInputTextState.value = value;
	const [valid, message] = checkValidity(updatedInputTextState, UINames(idMessageTranslation, intl), intl);
	updatedInputTextState.valid = valid;
	updatedInputTextState.validationMessage = message;
	return updatedInputTextState;
}

export const selectElement = (component, value, idMessageTranslation, valueHolder, intl) => {
	const updatedComponent = {...component};
	updatedComponent[valueHolder] = value;
	[updatedComponent.valid, updatedComponent.validationMessage] =
		checkSelectionValidity(updatedComponent, valueHolder, UINames(idMessageTranslation, intl), intl);
	return updatedComponent;
};