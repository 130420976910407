export const actions = [
	{name: "CLICK", label: "click"},
	{name: "TYPE", label: "type"},
	{name: "SELECT", label: "select"},
	{name: "SEND_KEYS", label: "send key"},
	{name: "ADD_SELECTION", label: "add selection"},
	{name: "REMOVE_SELECTION", label: "remove selection"},
	{name: "DRAG_AND_DROP", label: "drag and drop"},
	{name: "RUN_SCRIPT", label: "run script"},
	{name: "SELECT_WINDOW", label: "select window"},
	{name: "SELECT_FRAME", label: "select frame"},
	{name: "WAIT_SECONDS", label: "wait (by seconds)"},
	{name: "DOUBLE_CLICK", label: "double click"},
	{name: "CHECK", label: "check"},
	{name: "UNCHECK", label: "uncheck"},
	{name: "CHOOSE_OK_ON_NEXT_CONFIRMATION", label: "choose ok on next confirmation"},
	{name: "WEBDRIVER_CHOOSE_OK_ON_VISIBLE_CONFIRMATION", label: "choose ok on visible confirmation"},
	{name: "WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_CONFIRMATION", label: "choose cancel on visible confirmation"},
	{name: "WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT", label: "answer on visible prompt"},
	{name: "WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_PROMPT", label: "choose cancel on visible prompt"},
	{name: "MOUSE_OUVER", label: "mouse over"},
	{name: "MOUSE_OUT", label: "mouse out"},
	{name: "WAIT_FOR_ELEMENT_PRESENT", label: "wait for element present"},
	{name: "STORE_VALUE", label: "store value"},
	{name: "STORE_TEXT", label: "store text"}
	// OPEN = "OPEN",
];

export const actionsValues = [
	"CLICK",
	"TYPE",
	"SELECT",
	"SEND_KEYS",
	"ADD_SELECTION",
	"REMOVE_SELECTION",
	"DRAG_AND_DROP",
	"RUN_SCRIPT",
	"SELECT_WINDOW",
	"SELECT_FRAME",
	"WAIT_SECONDS",
	"DOUBLE_CLICK",
	"CHECK",
	"UNCHECK",
	"CHOOSE_OK_ON_NEXT_CONFIRMATION",
	"WEBDRIVER_CHOOSE_OK_ON_VISIBLE_CONFIRMATION",
	"WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_CONFIRMATION",
	"WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT",
	"WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_PROMPT",
	"MOUSE_OUVER",
	"MOUSE_OUT",
	"WAIT_FOR_ELEMENT_PRESENT",
	"STORE_VALUE",
	"STORE_TEXT"
	// OPEN = "OPEN",
];

export const getActionLabelForName = (name) => {
	for(let action of actions){
		if(action.name === name){
			return action.label;
		}
	}
}

export const getComponentLabelForName = (name) => {
	for(let component of components){
		if(component.name === name){
			return component.label;
		}
	}
}
export const getOperationLabelForName = (name) => {
	for(let operation of operations){
		if(operation.name === name){
			return operation.label;
		}
	}
}

export const components = [
	{name: "ID", label: "id"},
	{name: "NAME", label: "name"},
	{name: "XPATH", label: "xpath"},
	{name: "LINKTEXT", label: "link text"},
	{name: "PARTIALLINK", label: "partial link"},
	{name: "CLASSNAME", label: "classname"},
	{name: "CSSSELECTOR", label: "css selector"},
	{name: "ACCESSIBILITY", label: "accessibility"},
	{name: "IMAGE", label: "image"},
	{name: "CUSTOM", label: "custom"}
];

export const componentsValues = [
	"ID",
	"NAME",
	"XPATH",
	"LINKTEXT",
	"PARTIALLINK",
	"CLASSNAME",
	"CSSSELECTOR",
	"ACCESSIBILITY",
	"IMAGE",
	"CUSTOM"
];

export const operations = [
	{name: "TEXT_PRESENCE", label: "text presence"},
	{name: "TEXT_NOT_PRESENTE", label: "text not presente"},
	{name: "ASSERT_CHECKED", label: "assert checked"},
	{name: "ASSERT_NOT_CHECKED", label: "assert not checked"},
	{name: "ASSERT_ALERT", label: "assert alert"},
	{name: "ASSERT_CONFIRMATION", label: "assert confirmation"},
	{name: "ASSERT_PROMPT", label: "assert prompt"},
	{name: "ELEMENT_PRESENCE", label: "element presence"},
	{name: "EQUALS", label: "equals"},
	{name: "ASSERT_EDITABLE", label: "assert editable"},
	{name: "ASSERT_NOT_EDITABLE", label: "assert not editable"},
	{name: "ASSERT_SELECTED_VALUE", label: "assert selected value"},
	{name: "ASSERT_NOT_SELECTED_VALUE", label: "assert not selected value"},
	{name: "ASSERT_SELECTED_LABEL", label: "assert selected label"},
	{name: "ASSERT_TITLE", label: "assert title"},
	{name: "ASSERT_VALUE", label: "assert value"}
];

export const operationsValues = [
	"TEXT_PRESENCE",
	"TEXT_NOT_PRESENTE",
	"ASSERT_CHECKED",
	"ASSERT_NOT_CHECKED",
	"ASSERT_ALERT",
	"ASSERT_CONFIRMATION",
	"ASSERT_PROMPT",
	"ELEMENT_PRESENCE",
	"EQUALS",
	"ASSERT_EDITABLE",
	"ASSERT_NOT_EDITABLE",
	"ASSERT_SELECTED_VALUE",
	"ASSERT_NOT_SELECTED_VALUE",
	"ASSERT_SELECTED_LABEL",
	"ASSERT_TITLE",
	"ASSERT_VALUE"
]