import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, makeStyles, TextField} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import SVG from "react-inlinesvg";
import {Alert} from "@material-ui/lab";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	}
}));
const RestRequestTabParameters = props => {
	const intl = useIntl();
	const classes = useStyles();
	const [values, setValues] = useState(props.parameters);

	useEffect(() => {
			setValues(props.parameters);
	}, [props.parameters]);

	const [errorMessage, setErrorMessage] = useState('');

	const addParameter = () => {
		const updatedValues = [...values];
		updatedValues.push({key: '', value: ''});
		setValues(updatedValues);
		props.setParameters(updatedValues);
	}

	const deleteParameter = (index) => {
		const updatedValues = [...values];
		updatedValues.splice(index, 1);
		setValues(updatedValues);
		props.setParameters(updatedValues);
	}

	const updateKeyValue = (value, index, key = true) => {
		// Validation < 2000
		if(value.length > 2000){
			setErrorMessage(intl.formatMessage({
				id: "REST.REQUEST.FORM.PARAMETERS.KEYVALUE.LENGTH.VALIDATION.MESSAGE",
				defaultMessage: 'Last Entry is more than 2000 in length'
			}));
		} else {
			const updatedValues = [...values];
			const currentKeyValue = {...updatedValues[index]};
			if(key){
				currentKeyValue.key = value;
			} else {
				currentKeyValue.value = value;
			}
			updatedValues[index] = currentKeyValue;
			setValues(updatedValues);
			setErrorMessage('');
		}
	}

	const onBlur = () => {
		props.setParameters(values);
	}

	let keyValueInputSection = values.map((keyValue, index) => {
		return <Row key = {index} className='align-items-center'>
			<Col md = {5} xs = {12}>
				<TextField
					id = {'key' + index} name = {'key' + index}
					label = {intl.formatMessage({
						id: "REST.REQUEST.FORM.PARAMETERS.KEY.LABEL",
						defaultMessage: 'Key'
					}) + ' ' + (index + 1)}
					className = {`${classes.textField}`}
					value = {keyValue.key}
					onChange = {event => updateKeyValue(event.target.value, index, true)}
					onBlur = {onBlur}
					// onKeyDown={nameOnEnterKey}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({
						id: "REST.REQUEST.FORM.PARAMETERS.KEY.PLACEHOLDER",
						defaultMessage: 'Key'
					}) + ' ' + (index + 1)}
					// error = {!name.valid}
					// helperText = {name.validationMessage}
				/>
			</Col>
			<Col md = {6} xs = {11}>
				<TextField
					id = {'value' + index} name = {'value' + index}
					label = {intl.formatMessage({
						id: "REST.REQUEST.FORM.PARAMETERS.VALUE.LABEL",
						defaultMessage: 'Value'
					}) + ' ' + (index + 1)}
					className = {`${classes.textField}`}
					value = {keyValue.value}
					onChange = {event => updateKeyValue(event.target.value, index, false)}
					onBlur = {onBlur}
					// onKeyDown={nameOnEnterKey}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({
						id: "REST.REQUEST.FORM.PARAMETERS.VALUE.PLACEHOLDER",
						defaultMessage: 'Value'
					}) + ' ' + (index + 1)}
					// error = {!name.valid}
					// helperText = {name.validationMessage}
				/>
			</Col>
			<Col className='mt-3'>
				<a title = {intl.formatMessage({
					id: "REST.REQUEST.FORM.PARAMETERS.BUTTON.HOVER.DELETE.PARAMETER",
					defaultMessage: 'Delete Parameter '}) + (index +1)}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				   onClick = {() => deleteParameter(index)}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
			          <SVG
				          title = {intl.formatMessage({
					          id: "REST.REQUEST.FORM.PARAMETERS.BUTTON.HOVER.DELETE.PARAMETER",
					          defaultMessage: 'Delete Parameter '
				          }) + (index +1)}
				          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</Col>
		</Row>
	});


	let view = <Container className='px-0'>
		<Row>
			<Col>
				<Button variant = "outlined" className='mr-6' startIcon={<AddIcon/>}
				        onClick={addParameter} color='secondary'>
					<FormattedMessage id="REST.REQUEST.FORM.PARAMETERS.BUTTON.ADD.PARAMETER"
					                  defaultMessage='Parameter'/>
				</Button>
			</Col>
		</Row>
		{errorMessage !== '' && <Alert elevation = {3} variant = "standard"
		                              severity = "error" className = 'my-3'>
			{errorMessage}
		</Alert>}
		{keyValueInputSection}
	</Container>

	return view;
}

export default RestRequestTabParameters;