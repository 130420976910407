import React from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import SprintsSection from "./SprintsSection";
import SprintDisplaySection from "./SprintDisplaySection";

const Sprint = (props) => {
	let tab = 0;
	if(props.location.state && props.location.state.tab){
		tab = props.location.state.tab;
	}

	let sprintColumn = null;
	if (props.match.params.sprintID) {
		sprintColumn = <SprintDisplaySection {...props} tab={tab}/>
	}

	return (
		<Container className = 'p-0'>
			<Row>
				<Col sm = {12} md = {3}>
					<SprintsSection {...props} />
				</Col>
				<Col>
					{sprintColumn}
				</Col>
			</Row>
		</Container>
	);
}

const mapStateToProps = state => {
	return {

	};
};

const mapDispatchToProps = dispatch => {
	return {

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sprint);