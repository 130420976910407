import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Alert} from "@material-ui/lab";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import SVG from "react-inlinesvg";
import {FormattedMessage, useIntl} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {SuiteLoadingDialog} from "../../UI/LoadingDialog";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const SuitesDisplay = (props) => {
	const intl = useIntl();
	const [open, setOpen] = useState(false);

	const [suite, setSuite] = useState(null); // for removing suite

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const suiteNameBodyTemplate = (rowData) => {
		let select = 'text-secondary';
		if (props.suiteID === rowData.id) {
			select = 'text-success';
		}
		return <Link to = {`/suite/${rowData.id}`}
		             title = {intl.formatMessage({
			             id: "SUITES.TABLE.HOVER.LINK.VIEW.SUITE",
			             defaultMessage: 'View Suite'
		             })}
		             className = {`h4 ${select}`}>
			{rowData.label}
		</Link>;
	}

	const suiteActionsBodyTemplate = (rowData) => {

		return (
			<a title = {intl.formatMessage({
				id: "SUITES.TABLE.HOVER.LINK.DELETE.SUITE",
				defaultMessage: 'Delete Suite'
			})}
			   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				// onClick = {() => props.delete(rowData.id)}
               onClick = {() => {
	               setSuite(rowData);
	               handleClickOpen();
               }}
			>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
			          <SVG title = {intl.formatMessage({
				          id: "SUITES.TABLE.HOVER.LINK.DELETE.SUITE",
				          defaultMessage: 'Delete Suite'
			          })}
			               src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
			</a>
		);
	}


	const columns2 = [
		<Column key = 'label' columnKey = 'label' field = 'label'
		        header = {intl.formatMessage({
			        id: "SUITES.TABLE.COLUMN.NAME",
			        defaultMessage: 'Name'
		        })}
		        body = {suiteNameBodyTemplate}
		        filter filterPlaceholder = {intl.formatMessage({
			id: 'SUITES.TABLE.COLUMN.SEARCH.BY.NAME',
			defaultMessage: 'Search by name'
		})}
		        filterMatchMode = 'contains'/>

	];
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		columns2.push(<Column key = 'actions'
		                      header = {intl.formatMessage({
			                      id: "SUITES.TABLE.COLUMN.ACTIONS",
			                      defaultMessage: 'Actions'
		                      })}
		                      body = {suiteActionsBodyTemplate}
		                      className = 'p-text-right'/>);
	}

	let actions =
		<div className = 'text-center'>
			<Link className = "btn btn-primary mb-2 mt-2"
			      to = '/suite/new'>
				<FormattedMessage id = "SUITES.BUTTON.NEW"
				                  defaultMessage = "New Suite"/>
			</Link>
		</div>
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		actions = null;
	}

	let errorMessage = null;
	if (props.error) {
		errorMessage = <Alert elevation = {4} variant = "standard" severity = "error" className = 'my-3'>
			{props.error}
		</Alert>
	}
	let genralErrorMessage = null;
	if (props.generalError) {
		genralErrorMessage = <Alert elevation = {4} variant = "standard" severity = "error" className = 'my-3'>
			{props.generalError}
		</Alert>
	}
	let display = <div className = 'bg-white'>
		<SuiteLoadingDialog/>
		{actions}
		{errorMessage}
		{genralErrorMessage}
		<DataTable value = {props.suites} lazy ref = {props.dt}
		           paginator first = {props.lazyParams.first} rows = {props.lazyParams.rows}
		           totalRecords = {props.totalSuites}
		           onPage = {props.onPage} onSort = {props.onSort}
		           sortField = {props.lazyParams.sortField} sortOrder = {props.lazyParams.sortOrder}
		           onFilter = {props.onFilter}
		           filters = {props.lazyParams.filters}

		           currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
		           rowsPerPageOptions = {[10, 20, 50, 100]}
		           paginatorTemplate =
			           "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
		           emptyMessage = {intl.formatMessage({
			           id: "SUITES.EMPTY.LIST",
			           defaultMessage: 'No Suites'
		           })}
		>
			{columns2}
		</DataTable>
		<DeletionConfirmationDialog open={open} closeDialog={handleClose}
									message={<FormattedMessage id = "SUITES.TABLE.REMOVE.SUITE"
															   defaultMessage = 'Remove Suite'/> }
									name={suite ? `${suite.label}` : ''}
									deleteAction={() => {
										handleClose();
										props.delete(suite.id);
									}}
		/>
	</div>;

	return display;
}

export default SuitesDisplay;