import React, {useEffect, useRef, useState} from "react";
import {FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Container, Row} from "react-bootstrap";

import * as APIKeyType from "../enum/APIKeyType";
import {changeText, selectElement} from "../Shared";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(4),
		minWidth: 120
	}
}));

const AuthAPIKeyForm = props => {
	const intl = useIntl();
	const classes = useStyles();

	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;
		if(props.validate.APIKey === true){
			checkAllInputsValidity();
		}
		return () => mounted.current = false;
	}, []);

	const [key, setKey] = useState({
		value: props.APIKey.key,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [value, setValue] = useState({
		value: props.APIKey.value,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [type, setType] = useState({
		type: props.APIKey.type,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: APIKeyType.AuthenticationTypeObjects
	});

	const keyChangeHandler = (event) => {
		const updatedKey = changeText(key, event.target.value, 'apikey-key', intl);
		setKey(updatedKey);
	}

	const valueChangeHandler = (event) => {
		const updatedValue = changeText(value, event.target.value, 'apikey-value', intl);
		setValue(updatedValue);
	}

	const typeSelectHandler = (event) => {
		const updatedComponent = selectElement(type, event.target.value, 'apikey-type', 'type', intl);
		setType(updatedComponent);
		const APIKey = {
			key: key.value,
			value: value.value,
			type: updatedComponent.type
		}
		props.setAPIKey(APIKey);
		props.createAuthObject( '', '', '', APIKey);
	};

	const onBlur = () => {
		const APIKey = {
			key: key.value,
			value: value.value,
			type: type.type
		}
		// console.log('blur', core);
		props.setAPIKey(APIKey);
		props.createAuthObject( '', '', '', APIKey);
	}

	const checkAllInputsValidity = () => {
		let allValid = true;

		const updatedKey = changeText(key, key.value, 'apikey-key', intl);
		if (mounted.current === true) {
			setKey(updatedKey);
		}
		allValid = allValid && updatedKey.valid;

		const updatedValue = changeText(value, value.value, 'apikey-value', intl);
		if (mounted.current === true) {
			setValue(updatedValue);
		}
		allValid = allValid && updatedValue.valid;

		const updatedType = selectElement(type, type.type, 'apikey-type', 'type', intl);
		if (mounted.current === true) {
			setType(updatedType);
		}
		allValid = allValid && updatedType.valid;

		return allValid;
	}
	props.registerValidateAuthAPIKeyFormCallback(checkAllInputsValidity);

	return <Container className='px-0'>
		<Row>
			<Col>
				<TextField
					id = "apikey-key"  name = "apikey-key"
					label = {intl.formatMessage({
						id: "REST.REQUEST.FORM.AUTH.APIKEY.KEY.LABEL",
						defaultMessage: 'Key'})}
					className = {`${classes.textField}`}
					value = {key.value}
					onChange = {keyChangeHandler}
					onBlur={onBlur}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({
						id: "REST.REQUEST.FORM.AUTH.APIKEY.KEY.PLACEHOLDER",
						defaultMessage: 'Key'})}
					error = {!key.valid}
					helperText = {key.validationMessage}
				/>
			</Col>
		</Row>
		<Row>
			<Col>
				<TextField
					id = "apikey-value"  name = "apikey-value"
					label = {intl.formatMessage({
						id: "REST.REQUEST.FORM.AUTH.APIKEY.VALUE.LABEL",
						defaultMessage: 'Value' })}
					className = {`${classes.textField}`}
					value = {value.value}
					onChange = {valueChangeHandler}
					onBlur={onBlur}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({
						id: "REST.REQUEST.FORM.AUTH.APIKEY.VALUE.PLACEHOLDER",
						defaultMessage: 'Value' })}
					error = {!value.valid}
					helperText = {value.validationMessage}
				/>
			</Col>
		</Row>
		<Row>
			<Col>
				<FormControl className = {`${classes.formControl}`} fullWidth={true}
				             error = {!type.valid}>
					<InputLabel htmlFor = "apikey-type">
						<FormattedMessage id="REST.REQUEST.FORM.AUTH.APIKEY.TYPE.LABEL"
						                  defaultMessage='Type'/>
					</InputLabel>
					<Select
						value = {type.type}
						onChange = {(event) => typeSelectHandler(event)}
						input = {<Input id = "apikey-type"/>}
					>

						{type.types.map(type => {
							return <MenuItem key = {type.value}
							                 value = {type.value}>{type.label}</MenuItem>
						})}
					</Select>
					<FormHelperText>{type.validationMessage}</FormHelperText>
				</FormControl>
			</Col>
		</Row>
	</Container>


}

export default AuthAPIKeyForm;