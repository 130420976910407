export const UINames = (name, intl) => {
	switch (name) {
		case 'label':
			return intl.formatMessage({ id: "PROJECT.FORM.LABEL", defaultMessage: 'Project Name' });
		case 'type':
			return intl.formatMessage({ id: "PROJECT.FORM.TYPE", defaultMessage: 'Project Type' });
		case 'url':
			return intl.formatMessage({ id: "PROJECT.FORM.URL", defaultMessage: 'Website' });
		case 'live':
			return intl.formatMessage({ id: "PROJECTS.LABEL.LIVE", defaultMessage: 'Live Mode' });
		case 'password':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.PASSWORD", defaultMessage: 'Password' })
		case 'autoExec':
			return intl.formatMessage({ id: "PROJECTS.LABEL.AUTOMATIC.EXECUTION", defaultMessage: 'Automatic Execution' });
		case 'browser':
			return intl.formatMessage({ id: "PROJECTS.LABEL.BROWSER", defaultMessage: 'Browser' });
		case 'startTime':
			return intl.formatMessage({ id: "PROJECTS.LABEL.START.TIME", defaultMessage: 'Start Time' });
		case 'device':
			return intl.formatMessage({ id: "PROJECTS.LABEL.DEVICE", defaultMessage: 'Device' });
		case 'description':
			return intl.formatMessage({ id: "DESCRIPTION", defaultMessage: 'Description' });
		case 'apk':
			return intl.formatMessage({ id: "PROJECTS.LABEL.APK.FILE", defaultMessage: 'Apk File' });
		case 'operatingSystem':
			return intl.formatMessage({ id: "PROJECTS.LABEL.OPERATING.SYSTEM", defaultMessage: 'Operating System'});
		case 'browserVersion':
			return intl.formatMessage({ id: "PROJECTS.LABEL.BROWSER.VERSION", defaultMessage: 'Browser Version'});
		case 'deviceGroupName':
			return intl.formatMessage({ id: "PROJECTS.LABEL.BROWSER.VERSIONNNN", defaultMessage: 'Device Group Name'});
		default:
			return '';
	}
}