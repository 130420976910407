import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {apkUploadHandler, changeText, selectElement, updateStartTimeState} from "./Shared";
import CreationProjectFormJSX from "./CreationProjectFormJSX";
import {ProjectTypeObjects} from "./enum/ProjectType";
import {BrowserTypeObjects} from "./enum/BrowserType";
import {DeviceTypesObjects} from "./enum/DeviceType";
import {OperatingSystemObjects} from "./enum/OperatingSystem";
import {BrowserVersionObjects} from "./enum/BrowserVersion";
import * as OS from './enum/OperatingSystem'
import * as Type from "./enum/ProjectType";

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const CreationProjectForm = props => {
	const intl = useIntl();

	const [label, setLabel] = useState({
		value: props.label,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 250
		},
		valid: true,
		validationMessage: null
	});
	const [url, setUrl] = useState({
		value: props.url,
		validation: {
			required: true,
			minLength: 2,
			maxLength: 2000,
			isURL: true
		},
		valid: true,
		validationMessage: null
	});

	// const [live, setLive] = useState(props.live);
	//
	// const [password, setPassword] = useState({
	// 	value: props.password,
	// 	validation: {
	// 		maxLength: 30
	// 	},
	// 	valid: true,
	// 	validationMessage: null
	// });

	const [autoExec, setAutoExec] = useState(props.autoExec);
	const [timeZone, setTimeZone] = useState(props.timeZone);
	const [timeZoneError, setTimeZoneError] = useState(null);

	const [startTime, setStartTime] = useState({
		value: props.startTime,
		validation: {
			required: true,
		},
		valid: true,
		validationMessage: null
	});

	const [type, setType] = useState({
		selected: props.projectType,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: ProjectTypeObjects
		// 	[
		// 	{id: 1, label: 'WEB', value: 'WEB'},
		// 	{id: 2, label: 'ANDROID WEB', value: 'ANDROID_WEB'},
		// 	{id: 3, label: 'ANDROID', value: 'ANDROID'}
		// 	// {id: 4, label: 'iOS WEB', value: 'IOS_WEB'},
		// 	// {id: 5, label: 'iOS', value: 'IOS'}
		// ]
	});
	const [browser, setBrowser] = useState({
		selected: props.browser,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: BrowserTypeObjects
	});

	const [device, setDevice] = useState({
		selected: props.device,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,

		types: DeviceTypesObjects
	});
	const [description, setDescription] = useState({
		value: props.description,
		validation: {
			maxLength: 200_000,
		},
		valid: true,
		validationMessage: null
	});

	const [apk, setApk] = useState({
		value: new Blob(), // always an empty blob initially not props.apk
		label: props.apkLabel,
		validation: {
			required: true,
			apk: 'apk'
		},
		valid: true,
		validationMessage: null,
	});

	// Transfer List
	const [usersLeft, setUsersLeft] = useState(props.usersLeft);
	const [usersRight, setUsersRight] = useState(props.usersRight);
	const [usersSelected, setUsersSelected] = useState([]);

	const [operatingSystem, setOperatingSystem] = useState({
		selected: props.operatingSystem,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: OperatingSystemObjects
	});

	const [browserVersion, setBrowserVersion] = useState({
		selected: props.browserVersion,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: BrowserVersionObjects
	});

	const [suggestDefaultDialogOpen, setSuggestDefaultDialogOpen] = useState(props.suggestDefault);

	const [deviceGroup, setDeviceGroup] = useState({
		selected: props.deviceGroup,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: []
	});

	const handleCloseSuggestDefaultDialog = () => {
		setSuggestDefaultDialogOpen(false);
	};

	useEffect(() => {
		if(props.usersLeft !== usersLeft){
			setUsersLeft(props.usersLeft);
		}
	}, [props.usersLeft]);

	useEffect(() => {
		if(props.usersRight !== usersRight){
			setUsersRight(props.usersRight);
		}
	}, [props.usersRight]);

	const usersLeftChecked = intersection(usersSelected, usersLeft);
	const usersRightChecked = intersection(usersSelected, usersRight);

	useEffect(() => {
		if (label.value !== props.label) {
			let updatedElement = {...label};
			updatedElement.value = props.label;
			setLabel(updatedElement);

			updatedElement = {...url};
			updatedElement.value = props.url;
			setUrl(updatedElement);

			// setLive(props.live);
			//
			// updatedElement = {...password};
			// updatedElement.value = props.password;
			// setPassword(updatedElement);

			setAutoExec(props.autoExec);
			setTimeZone(props.timeZone);

			updatedElement = {...startTime};
			updatedElement.value = props.startTime;
			setStartTime(updatedElement);

			updatedElement = {...type};
			updatedElement.selected = props.projectType;
			setType(updatedElement);

			updatedElement = {...browser};
			updatedElement.selected = props.browser;
			setBrowser(updatedElement);

			updatedElement = {...device};
			updatedElement.selected = props.device;
			setDevice(updatedElement);

			updatedElement = {...description};
			updatedElement.value = props.description;
			setDescription(updatedElement);

			updatedElement = {...apk};
			updatedElement.label = props.apkLabel;
			setApk(updatedElement);

			updatedElement = {...operatingSystem};
			updatedElement.selected = props.operatingSystem;
			setOperatingSystem(updatedElement);

			updatedElement = {...browserVersion};
			updatedElement.selected = props.browserVersion;
			setBrowserVersion(updatedElement);

			// sets the list of existing device groups
			updatedElement = {...deviceGroup};
			updatedElement.types = props.deviceGroups;
			updatedElement.selected = Object.keys(props.deviceGroup).length === 0 ? updatedElement.selected :
				updatedElement.types.find(grp => grp.id === props.deviceGroup.id);
			setDeviceGroup(updatedElement);
		}
	}, [props.label]);

	// explanation: own effect because user can add a group in update form
	useEffect(() => {
		if (props.deviceGroup) {
			// sets the list of existing device groups
			const updatedElement = {...deviceGroup};
			updatedElement.types = props.deviceGroups;
			// updatedElement.selected = updatedElement.types.find(grp => grp.id === props.deviceGroup.id);
			updatedElement.selected = Object.keys(props.deviceGroup).length === 0 ? updatedElement.selected :
				updatedElement.types.find(grp => grp.id === props.deviceGroup.id);
			setDeviceGroup(updatedElement);
		}
	}, [props.deviceGroup, props.deviceGroups]);

	const labelChangeHandler = (event) => {
		const updatedLabel = changeText(label, event.target.value, 'label', intl);
		setLabel(updatedLabel);
	}

	const urlChangeHandler = (event) => {
		const updatedUrl = changeText(url, event.target.value, 'url', intl);
		setUrl(updatedUrl);
	}

	// const passwordChangeHandler = (event) => {
	// 	const updatedPassword = changeText(password, event.target.value, 'password', intl);
	// 	setPassword(updatedPassword);
	// }
	//
	// const liveChangeHandler = (checked) => {
	// 	setLive(checked);
	// 	props.setLive(checked);
	// }

	const autoExecChangeHandler = (checked) => {
		setAutoExec(checked);
		props.setAutoExec(checked);
	}

	const timeZoneChangeHandler = (value) => {
		setTimeZone(value.value);
		props.setTimeZone(value.value);
		if(value !== ''){
			setTimeZoneError(null);
		}
	}

	const startTimeChangeHandler = (time) => {
		const updatedStartTime = updateStartTimeState(startTime, time,'value', 'startTime', intl);
		setStartTime(updatedStartTime);
		props.setStartTime(time);
	}

	const typeSelectHandler = (event) => {
		const updatedType = selectElement(type, event.target.value, 'type', 'selected', intl);
		setType(updatedType);
		props.setProjectType(event.target.value);
	}

	const browserSelectHandler = (event) => {
		const updatedBrowser = selectElement(browser, event.target.value, 'browser', 'selected', intl);
		setBrowser(updatedBrowser);
		props.setBrowser(event.target.value);
	}

	const deviceSelectHandler = (event) => {
		const updatedDevice = selectElement(device, event.target.value, 'device', 'selected', intl);
		setDevice(updatedDevice);
		props.setDevice(event.target.value);
	}

	const descriptionChangeHandler = (value) => {
		const updatedDescription = changeText(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	// const [callCreateAndroid, setCallCreateAndroid] = useState(false);
	const apkChangeHandler = (event) => {
		// console.log('is it even called', event.target);
		const [updatedApk, touched] = apkUploadHandler(apk, event.target.files[0], event.target.files[0].name, 'label', intl);
		setApk(updatedApk);

		props.setApkLabel(updatedApk.label);
		props.setApk(updatedApk.value);
		if(props.type === 'update' && props.apkTouched === false){
			props.setApkTouched(touched);
		}

		// if(props.type === 'create'){
		// 	setCallCreateAndroid(true);
		// }
	}

	// useEffect(() => {
	// 	if(callCreateAndroid === true){
	// 		props.createProjectToBeCalledByAPKChangeHandler();
	// 		setCallCreateAndroid(false);
	// 	}
	// }, [callCreateAndroid])

	const selectOperatingSystem = (event) => {
		const osValue = event.target.value;
		const updatedOperatingSystem = selectElement(operatingSystem, osValue, 'operatingSystem', 'selected', intl);
		setOperatingSystem(updatedOperatingSystem);
		props.setOperatingSystem(osValue);

		// change list of browser versions
		updateBrowserVersionsSelectionList(osValue.value);
	}

	const selectBrowserVersion = (event) => {
		const updatedBrowserVersion = selectElement(browserVersion, event.target.value, 'browserVersion', 'selected', intl);
		setBrowserVersion(updatedBrowserVersion);
		props.setBrowserVersion(event.target.value);
	}

	const updateBrowserVersionsSelectionList = (os) => {
		const updatedBrowserVersion = {...browserVersion};
		if(os === OS.WINDOWS){
			updatedBrowserVersion.types = BrowserVersionObjects;
			updatedBrowserVersion.selected = BrowserVersionObjects[0];
		} else if(os === OS.LINUX){
			const linuxTypes = [BrowserVersionObjects[0]];
			updatedBrowserVersion.types = linuxTypes;
			updatedBrowserVersion.selected = linuxTypes[0];
		}
		setBrowserVersion(updatedBrowserVersion);
		props.setBrowserVersion(updatedBrowserVersion.selected);
	}

	const checkAllInputsValidity = () => {
		let allValid = true;
		const updatedLabel = changeText(label, label.value, 'label', intl);
		setLabel(updatedLabel);
		allValid = allValid && updatedLabel.valid;

		const updatedType = selectElement(type, type.selected, 'type', 'selected', intl);
		setType(updatedType);
		allValid = allValid && updatedType.valid;
		if(type.selected.value === 'WEB' || type.selected.value === 'ANDROID_WEB'){
			const updatedUrl = changeText(url, url.value, 'url', intl);
			setUrl(updatedUrl);
			allValid = allValid && updatedUrl.valid;
		}

		if(type.selected.value === Type.ANDROID){
			if(props.type === 'create'){
				//same
				const [updatedApk, touched] = apkUploadHandler(apk, apk.value, apk.label, 'label', intl);
				setApk(updatedApk);
				allValid = allValid && updatedApk.valid;
			} else if(props.type === 'update'){
				if(props.apkTouched === false){
					// nothing
				} else {
					const [updatedApk, touched] = apkUploadHandler(apk, apk.value, apk.label, 'label', intl);
					setApk(updatedApk);
					allValid = allValid && updatedApk.valid;
				}
			}
		}
		// if(live){
		// 	const updatedPassword = changeText(password, password.value, 'password', intl);
		// 	setPassword(updatedPassword);
		// 	allValid = allValid && updatedPassword.valid;
		// }

		if(autoExec){
			if(timeZone === ''){
				setTimeZoneError('You Must Select A Timezone');
				allValid = false;
			}
			const updatedStartTime = changeText(startTime, startTime.value, 'startTime', intl);
			setStartTime(updatedStartTime);
			allValid = allValid && updatedStartTime.valid;
		}
		if(type.selected.value === 'ANDROID_WEB' || type.selected.value === 'ANDROID'){
			const updatedDevice = selectElement(device, device.selected, 'device', 'selected', intl);
			setDevice(updatedDevice);
			allValid = allValid && updatedDevice.valid;
		}

		if(type.selected.value === 'WEB'){
			const updatedBrowser = selectElement(browser, browser.selected, 'browser', 'selected', intl);
			setBrowser(updatedBrowser);
			allValid = allValid && updatedBrowser.valid;

			const updatedOperatingSystem = selectElement(operatingSystem, operatingSystem.selected, 'operatingSystem', 'selected', intl);
			setOperatingSystem(updatedOperatingSystem);
			allValid = allValid && updatedOperatingSystem.valid;

			const updatedBrowserVersion = selectElement(browserVersion, browserVersion.selected, 'browserVersion', 'selected', intl);
			setBrowserVersion(updatedBrowserVersion);
			allValid = allValid && updatedBrowserVersion.valid;
		}

		const updatedDescription = changeText(description, description.value, 'description', intl);
		setDescription(updatedDescription);
		allValid = allValid && updatedDescription.valid;

		// APK skipped
		return allValid;
	}
	props.registerValidateFormCallback(checkAllInputsValidity);

	const handleToggle = (value) => {
		const currentIndex = usersSelected.indexOf(value);
		const newUsersSelected = [...usersSelected];

		if (currentIndex === -1) {
			newUsersSelected.push(value);
		} else {
			newUsersSelected.splice(currentIndex, 1);
		}
		setUsersSelected(newUsersSelected);
	};

	const handleAllRight = () => {
		setUsersRight(usersRight.concat(usersLeft));
		setUsersLeft([]);
		props.setUsersRight(usersRight.concat(usersLeft));
		props.setUsersLeft([]);
	};

	const handleCheckedRight = () => {
		const right = usersRight.concat(usersLeftChecked);
		const left = not(usersLeft, usersLeftChecked);
		setUsersRight(right);
		setUsersLeft(left);
		setUsersSelected(not(usersSelected, usersLeftChecked));
		props.setUsersRight(right);
		props.setUsersLeft(left);
	};

	const handleCheckedLeft = () => {
		const left = usersLeft.concat(usersRightChecked);
		const right = not(usersRight, usersRightChecked);
		setUsersLeft(left);
		setUsersRight(right);
		setUsersSelected(not(usersSelected, usersRightChecked));
		props.setUsersLeft(left);
		props.setUsersRight(right);
	};

	const handleAllLeft = () => {
		setUsersLeft(usersLeft.concat(usersRight));
		setUsersRight([]);
		props.setUsersLeft(usersLeft.concat(usersRight));
		props.setUsersRight([]);
	};

	const syncValueWithParentContainer = (element) => {
		switch (element) {
			case 'label':
				props.setLabel(label.value);
				break;
			// case 'type':
			// 	props.setProjectType(type.selected);
			// 	break;
			case 'url':
				props.setUrl(url.value);
				break;
			// case 'browser':
			// 	props.setBrowser(browser.selected);
			// 	break;
			// case 'device':
			// 	props.setDevice(device.selected);
			// 	break;
			// case 'apk':
			// 	props.setApk(apk.value);
			// 	props.setApkLabel(apk.label);
			// 	break;
			// case 'live':
			// 	props.setLive(live);
			// 	break;
			// case 'autoExec':
			// 	props.setAutoExec(autoExec);
			// 	break;
			// case 'password':
			// 	props.setPassword(password.value);
			// 	break;
			// case 'startTime':
			// 	props.setStartTime(startTime.value);
			// 	break;
			case 'description':
				props.setDescription(description.value);
				break;
		}
	}

	const selectDeviceGroup = (event) => {
		const group = event.target.value;
		const updatedDeviceGroup = selectElement(deviceGroup, group, 'deviceGroup', 'selected', intl);
		setDeviceGroup(updatedDeviceGroup);
		props.setDeviceGroup(group);
	}

	return <CreationProjectFormJSX type={props.type}
	                               usersLeft={usersLeft}
	                               usersRight={usersRight}
	                               usersSelected={usersSelected}

	                               label={label} labelChangeHandler={labelChangeHandler}
	                               projectType={type} typeSelectHandler={typeSelectHandler}
	                               url={url} urlChangeHandler={urlChangeHandler}
	                               browser={browser} browserSelectHandler={browserSelectHandler}
	                               device={device} deviceSelectHandler={deviceSelectHandler}
	                               apk={apk} apkChangeHandler={apkChangeHandler}
	                               autoExec={autoExec} autoExecChangeHandler={autoExecChangeHandler}
								   timeZone={timeZone} timeZoneChangeHandler={timeZoneChangeHandler}
								   timeZoneError={timeZoneError}
								   // timeZones={props.timeZones}
	                               startTime={startTime} startTimeChangeHandler={startTimeChangeHandler}
	                               // live={live} liveChangeHandler={liveChangeHandler}
	                               // password={password} passwordChangeHandler={passwordChangeHandler}

	                               description={description}
	                               descriptionChangeHandler={descriptionChangeHandler}
	                               usersLeftChecked={usersLeftChecked}
	                               usersRightChecked={usersRightChecked}
	                               handleToggle={handleToggle}
	                               handleAllRight={handleAllRight}
	                               handleCheckedRight={handleCheckedRight}
	                               handleCheckedLeft={handleCheckedLeft}
	                               handleAllLeft={handleAllLeft}

	                               syncValueWithParentContainer={syncValueWithParentContainer}

	                               allUsers={props.allUsers}

								   operatingSystem={operatingSystem} selectOperatingSystem={selectOperatingSystem}
								   browserVersion={browserVersion} selectBrowserVersion={selectBrowserVersion}

								   creationLoading={props.creationLoading}

								   suggestDefault={props.suggestDefault}
								   suggestDefaultDialogOpen={suggestDefaultDialogOpen}
								   handleCloseSuggestDefaultDialog={handleCloseSuggestDefaultDialog}
								   onUpdatePreferred={props.onUpdatePreferred}

								   deviceGroup={deviceGroup} selectDeviceGroup={selectDeviceGroup}
	/>;

}

export default CreationProjectForm;