import React from "react";

import DomainDisplayTabs from "./DomainDisplayTabs";
import {DomainLoadingDialog} from "../../UI/LoadingDialog";

const DomainDisplay = props => {

	return (
		<>
			{/*<DomainActions {...props}/>*/}
			<DomainLoadingDialog/>
			<DomainDisplayTabs {...props}/>
		</>
	);
}

export default DomainDisplay;