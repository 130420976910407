import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import RestRequestsDisplay from "../../components/Rest/RestRequestsDisplay";

const ListRestRequests = props => {
	const intl = useIntl();

	let [restRequestCreatedSuccessfullyAlertMessage, setRestRequestCreatedSuccessfullyAlertMessage] = useState(null); // state, effect

	const [restRequests, setRestRequests] = useState(props.restRequests);

	const [totalRecords, setTotalRecords] = useState(props.totalRecords);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 10,
		page: 0,
		pageCount: 1,
		sortField: 'label',
		sortOrder: 1
	});

	const [selectedMethods, setSelectedMethods] = useState(null);

	const dt = useRef(null);

	useEffect(() => {
		document.title = intl.formatMessage({
			id: "PAGE.TITLE.REST.REQUESTS",
			defaultMessage: 'Rest Requests'
		});
		// Request Created, show success message
		if (props.location.state && props.location.state.label) {
			setRestRequestCreatedSuccessfullyAlertMessage(props.location.state.label);
			setTimeout(() => {
				window.history.replaceState({}, document.title);
				setRestRequestCreatedSuccessfullyAlertMessage(null);
			}, 2000);
		}
	}, []);

	useEffect(() => {
		if (props.project) {
			props.onLoadRestRequests(props.project.id, createLazyParamsForBackend());
		}
	}, [props.project, lazyParams]);

	useEffect(() => {
		setRestRequests(props.restRequests);
	}, [props.restRequests]);

	useEffect(() => {
		setTotalRecords(props.totalRecords);
	}, [props.totalRecords]);

	useEffect(() => {
		if (props.refreshList === true) {
			props.onLoadRestRequests(props.project.id, createLazyParamsForBackend());
		}
	}, [props.refreshList]);

	const createLazyParamsForBackend = () => {
		const params = {};
		params.size = lazyParams.rows;
		// convert first to page => 0 => 0, 15 => 1, 30 => 2
		params.page = lazyParams.first / lazyParams.rows;
		if (lazyParams.filters) {
			Object.keys(lazyParams.filters).forEach(filter => {
				if (filter === 'methods') {
					let methodsAsString = '';
					lazyParams.filters[filter].value.forEach((value) => methodsAsString = methodsAsString + value + ',');
					params[filter] = methodsAsString;
				} else {
					params[filter] = lazyParams.filters[filter].value;
				}
			});
		}

		if (lazyParams.hasOwnProperty('sortField')) {
			params.sortField = lazyParams.sortField;
			if (lazyParams.sortOrder === 1) {
				params.sortOrder = 'ASC';
			} else {
				params.sortOrder = 'DESC';
			}
		}
		// console.log('createLazyParamsForBackend params', params);
		return params;
	}

	const onPage = (event) => {
		// console.log('onPage event:', event)
		let _lazyParams = {...lazyParams, ...event};
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		// console.log('onSort event:', event)
		let _lazyParams = {...lazyParams, ...event};
		setLazyParams(_lazyParams);
	}

	const onFilter = (event) => {
		// console.log('onFilter event:', event)
		let _lazyParams = {...lazyParams, ...event};
		_lazyParams['first'] = 0;
		setLazyParams(_lazyParams);
	}

	const onMethodChange = (e) => {
		// console.log('onMethodChange event:', e);
		dt.current.filter(e.value, 'methods', 'in');
		setSelectedMethods(e.value);
	}

	const deleteRequest = (id) => {
		props.onDeleteRequest(id, null);
	}

	const [errorMessages, setErrorMessages] = useState([]);

	useEffect(() => {
		if (props.error) {
			const updatedErrorMessages = [...errorMessages]
			updatedErrorMessages.push(props.error)
			setErrorMessages(updatedErrorMessages);
		}
	}, [props.error]);

	return <RestRequestsDisplay restRequests={restRequests}
								onFilter={onFilter}
								onSort={onSort}
								onPage={onPage}
								totalRecords={totalRecords}
								lazyParams={lazyParams}
								onLoadRestRequests={props.onLoadRestRequests}
								dt={dt}
								selectedMethods={selectedMethods}
								onMethodChange={onMethodChange}
								deleteRequest={deleteRequest}
								errorMessages={errorMessages}

								label={restRequestCreatedSuccessfullyAlertMessage}
		// label is for when creating a request => show message
	/>;
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		error: state.restRequest.listAllError,
		loading: state.restRequest.listAllLoading,
		restRequests: state.restRequest.restRequests,
		totalRecords: state.restRequest.totalRecords,
		refreshList: state.restRequest.refreshList
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadRestRequests: (projectID, config) => dispatch(actions.loadRestRequests(projectID, config)),
		onDeleteRequest: (id, redirect) => dispatch(actions.deleteRestRequest(id, redirect))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListRestRequests);