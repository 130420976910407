import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import SVG from "react-inlinesvg";
import {Snackbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {FormattedMessage, useIntl} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {IterationLoadingDialog} from "../../UI/LoadingDialog";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const IterationSuitesDisplay = (props) => {
	const intl = useIntl();
	const classes = useStyles();

	const [suite, setSuite] = useState(null);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const handleClickAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlertOpen(false);
	};

	// Remove
	const [openRemove, setOpenRemove] = React.useState(false);

	const handleClickOpenRemove = () => {
		setOpenRemove(true);
	};

	const handleCloseRemove = () => {
		setOpenRemove(false);
	};

	const suiteNameBodyTemplate = (rowData) => {
		return <h5>{rowData.label}</h5>;
	}

	const suiteActionsBodyTemplate = (rowData) => {

		return (
			<div className = {`${classes.root}`}>
				<a title = {intl.formatMessage({ id: "ITERATION.TABLE.HOVER.LINK.REMOVE.SUITE",
					defaultMessage: 'Remove Suite'})}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm "
					// onClick = {() => deleteIteration(rowData.id)}
                   onClick = {() => {
	                   setSuite(rowData); // to see this {index+iterationCase}
	                   handleClickOpenRemove();
                   }}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
				        <SVG title={intl.formatMessage({ id: "ITERATION.TABLE.HOVER.LINK.START.ITERATION",
					        defaultMessage: 'Start Iteration'})}
					        src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</div>
		);
	}

	const columns2 = [
		<Column key = 'label' columnKey = 'label' field = 'label'
		        header = {intl.formatMessage({ id: "ITERATION.TABLE.COLUMN.NAME",
			        defaultMessage: 'Name'})}
		        body = {suiteNameBodyTemplate} sortable/>
	];
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		columns2.push(<Column key = 'actions'
		                      header = {intl.formatMessage({ id: "ITERATION.TABLE.COLUMN.ACTIONS",
			                      defaultMessage: 'Actions'})}
		                      body = {suiteActionsBodyTemplate}
		                      style = {{width: '25%'}}
		                      className = 'p-text-right'
		/>);
	}


	let display = <div className = 'bg-white pt-1 mt-1'>
		<IterationLoadingDialog/>
		{props.error && <Alert elevation = {3} variant = "standard" severity = "error" className = 'm-2 mb-4'>
			{props.error}
		</Alert>}
		<DataTable value = {props.suites}
				   paginator className="p-datatable-striped"
				   paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				   currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
				   rows = {20} rowsPerPageOptions = {[20, 50, 100, 500, 1000]}
				   emptyMessage = {intl.formatMessage({id: "ITERATION.NO.SUITE", defaultMessage: 'No Suites Exists For Iteration'})}
		>
			{columns2}
		</DataTable>
		<DeletionConfirmationDialog open={openRemove} closeDialog={handleCloseRemove}
									message={<FormattedMessage id="ITERATION.TABLE.DIALOG.REMOVE.SUITE"
															   defaultMessage='Remove Suite'/> }
									name={suite ? `${suite.label}` : ''}
									deleteAction={() => {
										handleCloseRemove();
										props.delete(suite.id);
									}}
		/>

		<Snackbar
			anchorOrigin = {{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open = {alertOpen}
			autoHideDuration = {2000}
			onClose = {handleCloseAlert}
			message = {alertMessage}
		>
			<Alert onClose = {handleCloseAlert} severity = "success">
				{alertMessage}
			</Alert>
		</Snackbar>
	</div>;

	return display;
}

export default IterationSuitesDisplay;