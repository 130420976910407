import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";
import {prepareAndroidRecoringEnvironmentStart} from "../actions";
import * as actions from "../actions";
import {
	LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_ALREADY_IN_USE,
	LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_STOP_TO_CONTINUE,
	REMOVE_DOMAIN_ID_FROM_REQUESTED_ANDROID_ENVIRNOMENT_LIST
} from "../actions/actionTypes";

export const initialState = {
	creationLoading: false,
	creationError: null,

	updatingLoading: false,
	updatingError: null,

	listAllLoading: false,
	listAllError: null,

	loading: false,
	error: null,
	domains: null, // initial null: loading. done: [] empty array
	domain: null,

	totalRecords: 0,
	refreshList: false,

	listDomainsForSuiteAdditionLoading: false,
	listDomainsForSuiteAdditionError: null,
	listDomainsForSuiteAddition: null,

	// Android Recording
	requestedAndroidEnvironmentDomainsIdsList: [], // domainsIDs
	prepareAndroidRecoringEnvironmentLoading: false,
	prepareAndroidRecoringEnvironmentError: null,
	// androidRecoringEnvironment: {
	// 	server: {
	// 		host: '', port: '', path: ''
	// 	},
	// 	caps: {
	// 		app: 'apkPath',
	// 		automationName: 'UIAutomator2',
	// 		deviceName: 'Android Emulator',
	// 		ensureWebviewsHavePages: true,
	// 		nativeWebScreenshot: true,
	// 		connectHardwareKeyboard: true,
	// 		platformName: 'Android',
	// 	}
	// },

	showAndroidRecordingEnvironmentLoadingMessage: false,
	showAndroidRecordingEnvironmentStopToContinueMessage: false,
	androidRecordingEnvironmentStopToContinueDomainLabel: '',

	stopAndroidRecoringEnvironmentLoading: false,
	stopAndroidRecoringEnvironmentError: null,

	showAndroidRecordingEnvironmentAlreadyInUseMessage: false,
	androidRecordingEnvironmentAlreadyInUseUserFullname: '',

	// domainAndroidState: null,
	domainAndroidStateLoading: true,
	domainAndroidStateError: null

};

const buildURL = (parameters, doaminId, projectId) => {
	let token = localStorage.getItem('jwt').replace('Bearer ', '');
	const frontHostName = window.location.protocol + '//' + window.location.host;
	// console.log('frontHostName', frontHostName);
	let url = `${parameters.clientUrl}/?doaminId=${doaminId}&host=${parameters.serverHost}&port=${parameters.serverPort}&
	path=${parameters.path}&apkPath=${parameters.capabilities.app}&token=${token}&platform=${parameters.capabilities.platformName}&
	device=${parameters.capabilities.deviceName}&automationName=${parameters.capabilities.automationName}&frontHostname=${frontHostName}
	&projectId=${projectId}&time=${parameters.remainingTime}&browserName=${parameters.capabilities.browserName}`;
	if (parameters.https === true) {
		url = url + `&https=${parameters.https}`;
	}
	// console.log('url', url);
	return url;
}

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.CREATE_DOMAIN_START:
			return updateObject(state, {
				creationLoading: true,
				creationError: null
			});
		case actionTypes.CREATE_DOMAIN_SUCCESS:
			return updateObject(state, {
				creationLoading: false,
				domain: action.domain
			});
		case actionTypes.CREATE_DOMAIN_FAIL:
			return updateObject(state, {
				creationLoading: false,
				creationError: action.error,
				domain: null
			});
		case actionTypes.LOAD_DOMAINS_START:
			return updateObject(state, {
				listAllLoading: true,
				refreshList: false
			});
		case actionTypes.LOAD_DOMAINS_SUCCESS:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: null,
				domains: action.domains,
				totalRecords: action.totalRecords
			});
		case actionTypes.LOAD_DOMAINS_FAIL:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: action.error,
				domains: null
			});
		case actionTypes.DELETE_DOMAIN_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.DELETE_DOMAIN_SUCCESS:
			let domainsCopy = [...state.domains];
			domainsCopy = domainsCopy.filter((domain) => {
				if(domain.id !== action.id){
					return true;
				}
			});
			return updateObject(state, {
				loading: false,
				error: null,
				domains: domainsCopy,
				refreshList: true
			});
		case actionTypes.DELETE_DOMAIN_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		case actionTypes.DELETE_TEST_FROM_LOADED_DOMAIN:
			const updatedDomain = {...state.domain};
			let updatedTests = [...state.domain.cases];
			updatedTests = updatedTests.filter(test => {
				if(action.id !== test.id){
					return true;
				}
			});
			updatedDomain.cases = updatedTests;
			return updateObject(state, {
				domain: updatedDomain
			});
		case actionTypes.LOAD_DOMAIN_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_DOMAIN_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				domain: action.domain
			});
		case actionTypes.LOAD_DOMAIN_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				domain: null
			});
		case actionTypes.UPDATE_DOMAIN_START:
			return updateObject(state, {
				updatingLoading: true
			});
		case actionTypes.UPDATE_DOMAIN_SUCCESS:
			const mergeDomain = updateObject(state.domain, {
				...action.domain
			});
			const mergeDomains = state.domains.map((domain) => {
				if(domain.id === mergeDomain.id){
					const copyDomain = {...mergeDomain};
					delete copyDomain.cases
					return copyDomain;
				}
				return domain;
			});

			return updateObject(state, {
				domain: mergeDomain,
				domains: mergeDomains,
				updatingLoading: false,
				updatingError: null,
			});
		case actionTypes.UPDATE_DOMAIN_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error,
			});
		case actionTypes.IMPORT_TESTS_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.IMPORT_TESTS_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				domain: action.domain
			});
		case actionTypes.IMPORT_TESTS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_START:
			return updateObject(state, {
				listDomainsForSuiteAdditionLoading: true,
				listDomainsForSuiteAdditionError: null,
				listDomainsForSuiteAddition: null
			});
		case actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_SUCCESS:
			return updateObject(state, {
				listDomainsForSuiteAdditionLoading: false,
				listDomainsForSuiteAddition: action.domains
			});
		case actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_FAIL:
			return updateObject(state, {
				listDomainsForSuiteAdditionLoading: false,
				listDomainsForSuiteAdditionError: action.error,
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_START:
			return updateObject(state, {
				// requestedAndroidEnvironmentDomainsIdsList: false,
				showAndroidRecordingEnvironmentLoadingMessage: true,
				prepareAndroidRecoringEnvironmentLoading: true,
				prepareAndroidRecoringEnvironmentError: null,
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS_DONE:
			return updateObject(state, {
				requestedAndroidEnvironmentDomainsIdsList: [...state.requestedAndroidEnvironmentDomainsIdsList, action.domainID],
				showAndroidRecordingEnvironmentLoadingMessage: true,
				prepareAndroidRecoringEnvironmentLoading: false,
				prepareAndroidRecoringEnvironmentError: null
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_TOGGLE_LOADING_MESSAGE:
			return updateObject(state, {
				showAndroidRecordingEnvironmentLoadingMessage: action.show
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS:
			const url = buildURL(action.parameters, action.domainID, action.projectId);
			window.open(url, '_self');
			return updateObject(state, {
				prepareAndroidRecoringEnvironmentLoading: false,
				showAndroidRecordingEnvironmentLoadingMessage: false,
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_STOP_TO_CONTINUE:
			return updateObject(state, {
				prepareAndroidRecoringEnvironmentLoading: false,
				showAndroidRecordingEnvironmentLoadingMessage: false,
				showAndroidRecordingEnvironmentStopToContinueMessage: action.show,
				androidRecordingEnvironmentStopToContinueDomainLabel: action.show === true ? action.domainLabel : ''
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_ALREADY_IN_USE:
			return updateObject(state, {
				prepareAndroidRecoringEnvironmentLoading: false,
				showAndroidRecordingEnvironmentLoadingMessage: false,
				showAndroidRecordingEnvironmentAlreadyInUseMessage: action.show,
				androidRecordingEnvironmentAlreadyInUseUserFullname: action.fullname
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_FAIL:
			return updateObject(state, {
				prepareAndroidRecoringEnvironmentLoading: false,
				prepareAndroidRecoringEnvironmentError: action.error,
				showAndroidRecordingEnvironmentLoadingMessage: false
			});
		case actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_NULLIFY_ERROR:
			return updateObject(state, {
				prepareAndroidRecoringEnvironmentError: null,
			});
		case actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT_START:
			return updateObject(state, {
				stopAndroidRecoringEnvironmentLoading: true,
				stopAndroidRecoringEnvironmentError: null
			});
		case actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT_SUCCESS:
			return updateObject(state, {
				stopAndroidRecoringEnvironmentLoading: false,
			});
		case actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT_FAIL:
			return updateObject(state, {
				stopAndroidRecoringEnvironmentLoading: false,
				stopAndroidRecoringEnvironmentError: action.error
			});
		// case actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_START:
		// 	return updateObject(state, {
		// 		domainAndroidStateLoading: true,
		// 	});
		// case actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_CREATED:
		// 	return updateObject(state, {
		// 		// domainAndroidState: action.state,
		// 		// showAndroidRecordingEnvironmentLoadingMessage: false,   // name is copied from PREPARE_ANDROID_RECORDING_ENVIRONMENT
		// 		domainAndroidStateLoading: false,
		// 		domainAndroidStateError: null
		// 	});
		// case actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_SUCCESS:
		// 	window.open(buildURL(action.parameters, action.domainID, action.projectID), '_blank').focus();
		// 	return updateObject(state, {
		// 		// domainAndroidState: action.state,
		// 		showAndroidRecordingEnvironmentLoadingMessage: false,   // name is copied from PREPARE_ANDROID_RECORDING_ENVIRONMENT
		// 		domainAndroidStateLoading: false,
		// 		domainAndroidStateError: null
		// 	});
		// 	// For Case Toogle showAndroidRecordingEnvironmentLoadingMessage the old one is used from above
		// case actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_STOP_TO_CONTINUE:
		// 	return updateObject(state, {
		// 		domainAndroidStateLoading: false,
		// 		showAndroidRecordingEnvironmentLoadingMessage: false,
		// 		showAndroidRecordingEnvironmentStopToContinueMessage: action.show,			// Will keep old name
		// 		androidRecordingEnvironmentStopToContinueDomainLabel: action.show === true ? action.domainLabel : ''
		// 	});
		// case actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_ALREADY_IN_USE:
		// 	return updateObject(state, {
		// 		domainAndroidStateLoading: false,
		// 		showAndroidRecordingEnvironmentLoadingMessage: false,
		// 		showAndroidRecordingEnvironmentAlreadyInUseMessage: action.show,
		// 		androidRecordingEnvironmentAlreadyInUseUserFullname: action.fullname
		// 	});
		// case actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_FAIL:
		// 	return updateObject(state, {
		// 		// domainAndroidState: null,
		// 		showAndroidRecordingEnvironmentLoadingMessage: false,
		// 		domainAndroidStateLoading: false,
		// 		domainAndroidStateError: action.error,
		// 	});
		// case actionTypes.REMOVE_DOMAIN_ID_FROM_REQUESTED_ANDROID_ENVIRNOMENT_LIST:
		// 	const updatedDomainsIds = [...state.requestedAndroidEnvironmentDomainsIdsList];
		// 	updatedDomainsIds.splice(updatedDomainsIds.indexOf(action.domainID), 1)
		// 	return updateObject(state, {
		// 		requestedAndroidEnvironmentDomainsIdsList: updatedDomainsIds
		// 	});
		default: {
			return state;
		}
	}
}

export default reducer;