import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {FormattedMessage, useIntl} from "react-intl";
import {Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

import {useHtmlClassService} from "../../../_metronic/layout";

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#c9f7f5',
		color: '#1bc5bd',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

function getChartOption(layoutProps, testsExecuted, intl) {

	// const monthsNumbers = [1,2,3,4,5,6];
	const months = [];
	for(let month of testsExecuted.months){
		months.push(Intl.DateTimeFormat(navigator.language, { month: 'long' }).format(new Date(0, month -1)));
	}
	let maxValue = Math.max(...testsExecuted.testsExecuted);
	// maxValue = maxValue > 10 ? maxValue + (maxValue / 10) : maxValue + 1;
	maxValue = maxValue + (maxValue / 10);
	const options = {
		series: [{
			// name: "", data: [300, 450, 320, 700, 400, 555]
			name: "", data: testsExecuted.testsExecuted
		}], chart: {
			type: "area", height: 150, toolbar: {
				show: false
			}, zoom: {
				enabled: false
			}, sparkline: {
				enabled: true
			}
		}, plotOptions: {}, legend: {
			show: false
		}, dataLabels: {
			enabled: false
		}, fill: {
			type: "solid", opacity: 1
		}, stroke: {
			curve: "smooth", show: true, width: 3, colors: [layoutProps.colorsThemeBaseSuccess]
		}, xaxis: {
			categories: months, axisBorder: {
				show: false
			}, axisTicks: {
				show: false
			}, labels: {
				show: false, style: {
					colors: layoutProps.colorsGrayGray500, fontSize: "12px", fontFamily: layoutProps.fontFamily
				}
			}, crosshairs: {
				show: false, position: "front", stroke: {
					color: layoutProps.colorsGrayGray300, width: 1, dashArray: 3
				}
			}, tooltip: {
				enabled: true, formatter: undefined, offsetY: 0, style: {
					fontSize: "12px", fontFamily: layoutProps.fontFamily
				}
			}
		}, yaxis: {
			max: maxValue,
			labels: {
				show: false, style: {
					colors: layoutProps.colorsGrayGray500, fontSize: "12px", fontFamily: layoutProps.fontFamily
				}
			}
		}, states: {
			normal: {
				filter: {
					type: "none", value: 0
				}
			}, hover: {
				filter: {
					type: "none", value: 0
				}
			}, active: {
				allowMultipleDataPointsSelection: false, filter: {
					type: "none", value: 0
				}
			}
		}, tooltip: {
			style: {
				fontSize: "12px", fontFamily: layoutProps.fontFamily
			}, y: {
				formatter: function (val) {
					return val + intl.formatMessage({id: "DASHBOARD.TESTS.EXECUTED.FRAGEMENT.TEXT", defaultMessage: ' executed'});
				}
			}
		}, colors: [layoutProps.colorsThemeLightSuccess], markers: {
			colors: [layoutProps.colorsThemeLightSuccess], strokeColor: [layoutProps.colorsThemeBaseSuccess], strokeWidth: 3
		}
	};
	return options;
}

const TestsExecutionWidget = props => {
	const intl = useIntl();

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
			colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
			colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
			colorsThemeBaseSuccess: objectPath.get(uiService.config, "js.colors.theme.base.success"),
			colorsThemeLightSuccess: objectPath.get(uiService.config, "js.colors.theme.light.success"),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily")
		};
	}, [uiService]);

	useEffect(() => {
		const element = document.getElementById("tests_executed_chart");

		if (!element) {
			return;
		}

		const options = getChartOption(layoutProps, props.testsExecuted, intl);
		const chart = new ApexCharts(element, options);
		chart.render();
		return function cleanUp() {
			chart.destroy();
		};
	}, [layoutProps]);

	// const average = 570;

	return (<div className={`card card-custom ${props.className}`}>
			<div className="card-body d-flex flex-column p-0">
				<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
					<div className="d-flex flex-column mr-2">
						<span className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">
							<FormattedMessage id="DASHBOARD.TESTS.EXECUTED.HEADER" defaultMessage='Tests Executed In The Last 6 Months'/>
						</span>
						<span className="text-muted font-weight-bold mt-2">
							<FormattedMessage id="DASHBOARD.TESTS.EXECUTED.SUB.HEADER" defaultMessage='For All Projects'/>
						</span>
					</div>
					<LightTooltip title={intl.formatMessage({id: "DASHBOARD.TESTS.EXECUTED.AVERAGE", defaultMessage: 'Avergae Per Month'})}>
						<span className="symbol symbol-light-success symbol-55">
							<span className="symbol-label font-weight-bolder font-size-h6">
								{props.testsExecuted.average}
							</span>
						</span>
					</LightTooltip>
				</div>
				<div id="tests_executed_chart" className="card-rounded-bottom" style={{height: "150px"}}/>
			</div>
		</div>);
}

export default TestsExecutionWidget;