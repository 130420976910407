import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const RecentUsersWidget = props => {

	// const recentUsers = [{fullName: 'Mohamed Hafid', role: 'MANAGER', username: 'mohamed'},
	// 	{fullName: 'Imran Hafid', role: 'MANAGER', username: 'mohamed'}, {fullName: 'Mayssa Hafid', role: 'MANAGER', username: 'mohamed'},
	// 	{fullName: 'Maysam Hafid', role: 'MANAGER', username: 'mohamed'}, {fullName: 'Hasna Hafid', role: 'MANAGER', username: 'mohamed'},];

	const avatars = ['016-boy-7.svg', '001-boy.svg', '007-boy-2.svg', '008-boy-3.svg', '009-boy-4.svg',]

	return <div className="card card-custom bg-light-success card-stretch gutter-b">
		<div className="card-header border-0">
			<h3 className="card-title font-weight-bolder text-success">
				<FormattedMessage id="DASHBOARD.RECENT.USERS" defaultMessage='Recent Users'/>
			</h3>
			{/*<div className="card-toolbar">*/}
			{/*	<Dropdown className="dropdown-inline" drop="down" alignRight>*/}
			{/*		<Dropdown.Toggle*/}
			{/*			as={DropdownCustomToggler} >*/}
			{/*			<i className="ki ki-bold-more-ver text-success"/>*/}
			{/*		</Dropdown.Toggle>*/}
			{/*		<Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">*/}
			{/*			<DropdownMenu2/>*/}
			{/*		</Dropdown.Menu>*/}
			{/*	</Dropdown>*/}
			{/*</div>*/}
		</div>
		<div className="card-body pt-2">
			{props.recentUsers.map((user, index) => {
				return <div className="d-flex align-items-center mb-10" key={index}>
					<div className="symbol symbol-40 symbol-light-white mr-5">
						<div className="symbol-label">
							<img src={toAbsoluteUrl(`/media/svg/avatars/${avatars[index]}`)} className="h-75 align-self-end" alt=""/>
						</div>
					</div>
					<div className="d-flex flex-column font-weight-bold">
						<Link to={`/user/${user.username}`}
							  className="text-dark text-hover-primary mb-1 font-size-lg">{user.fullName}</Link>
						<span className="text-muted">{user.role}</span>
					</div>
				</div>
			})}
			<Link to="/users" className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3">
				<FormattedMessage id="DASHBOARD.RECENT.USERS.VIEW.ALL" defaultMessage='View All'/>
			</Link>
		</div>

	</div>;
}

export default RecentUsersWidget;
