import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	creationLoading: false,
	listAllLoading: false,
	deletionLoading: false,
	listOneLoading: false,
	updatingLoading: false,
	listNamesLoading: false,

	creationError: null,
	listAllError: null,
	deletionError: null,
	listOneError: null,
	updatingError: null,
	listNamesError: false,

	restRequests: null,
	totalRecords: 0,

	restRequest: null,

	refreshList: false, // utility so after deletion the table gets fresh List. else the table will
	// contain rows MINUS deleted. which means onPaging, things will be 1 Item off

	restRequestsNames: null,

	creationOfDomainLoading: false,
	creationOfDomainError: null,

	listProjectRestRequestsLoading: false,
	listProjectRestRequestsError: true

};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CREATE_REST_REQUEST_START:
			return updateObject(state, {
				creationLoading: true
			});
		case actionTypes.CREATE_REST_REQUEST_SUCCESS:
			return updateObject(state, {
				creationLoading: false,
				creationError: null,
				restRequest: action.restRequest
			});
		case actionTypes.CREATE_REST_REQUEST_FAIL:
			return updateObject(state, {
				creationError: action.error,
				creationLoading: false,
				restRequest: null
			});
		case actionTypes.LOAD_REST_REQUESTS_START:
			return updateObject(state, {
				listAllLoading: true,
				refreshList: false
			});
		case actionTypes.LOAD_REST_REQUESTS_SUCCESS:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: null,
				restRequests: action.restRequests,
				totalRecords: action.totalRecords
			});
		case actionTypes.LOAD_REST_REQUESTS_FAIL:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: action.error,
				restRequests: [],
				totalRecords: 0
			});
		case actionTypes.DELETE_REST_REQUEST_START:
			return updateObject(state, {
				deletionLoading: true,
				deletionError: null,
				refreshList: false
			});
		case actionTypes.DELETE_REST_REQUEST_SUCCESS:
			// let sprintsCopy = [...state.sprints];
			// sprintsCopy = sprintsCopy.filter((sprint) => {
			// 	if(sprint.id !== action.id){
			// 		return true;
			// 	}
			// });
			return updateObject(state, {
				deletionLoading: false,
				refreshList: true,
				// sprints: sprintsCopy
			});
		case actionTypes.DELETE_REST_REQUEST_FAIL:
			return updateObject(state, {
				deletionLoading: false,
				deletionError: action.error
			});
		case actionTypes.LOAD_REST_REQUEST_START:
			return updateObject(state, {
				listOneLoading: true
			});
		case actionTypes.LOAD_REST_REQUEST_SUCCESS:
			return updateObject(state, {
				listOneLoading: false,
				listOneError: null,
				restRequest: action.restRequest
			});
		case actionTypes.LOAD_REST_REQUEST_FAIL:
			return updateObject(state, {
				listOneLoading: false,
				listOneError: action.error,
				restRequest: null
			});
		case actionTypes.UPDATE_REST_REQUEST_START:
			return updateObject(state, {
				updatingLoading: true,
				updatingError: null
			});
		case actionTypes.UPDATE_REST_REQUEST_SUCCESS:
			return updateObject(state, {
				updatingLoading: false
			});
		case actionTypes.UPDATE_REST_REQUEST_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error,
			});
		case actionTypes.LOAD_REST_REQUESTS_NAMES_START:
			return updateObject(state, {
				listNamesLoading: true
			});
		case actionTypes.LOAD_REST_REQUESTS_NAMES_SUCCESS:
			return updateObject(state, {
				listNamesLoading: false,
				listNamesError: null,
				restRequestsNames: action.restRequests
			});
		case actionTypes.LOAD_REST_REQUESTS_NAMES_FAIL:
			return updateObject(state, {
				listNamesLoading: false,
				listNamesError: action.error,
				restRequestsNames: []
			});
		case actionTypes.CREATE_REST_REQUEST_OF_DOMAIN_START:
			return updateObject(state, {
				creationOfDomainLoading: true,
				creationOfDomainError: null
			});
		case actionTypes.CREATE_REST_REQUEST_OF_DOMAIN_SUCCESS:
			return updateObject(state, {
				creationOfDomainLoading: false,
				// restRequest: action.restRequest
			});
		case actionTypes.CREATE_REST_REQUEST_OF_DOMAIN_FAIL:
			return updateObject(state, {
				creationOfDomainLoading: false,
				creationOfDomainError: action.error
				// restRequest: null
			});
		case actionTypes.LOAD_PROJECT_REST_REQUESTS_START:
			return updateObject(state, {
				listProjectRestRequestsLoading: true,
				listProjectRestRequestsError: null,
				listProjectRestRequests: null
			});
		case actionTypes.LOAD_PROJECT_REST_REQUESTS_SUCCESS:
			return updateObject(state, {
				listProjectRestRequestsLoading: false,
				listProjectRestRequests: action.projectRestRequests,
			});
		case actionTypes.LOAD_PROJECT_REST_REQUESTS_FAIL:
			return updateObject(state, {
				listProjectRestRequestsLoading: false,
				listProjectRestRequestsError: action.error
			});
		case actionTypes.DELETE_REST_REQUEST_OF_DOMAIN_START:
			return updateObject(state, {
				deletionLoading: true,
				deletionError: null,
				refreshList: false
			});
		case actionTypes.DELETE_REST_REQUEST_OF_DOMAIN_SUCCESS:
			return updateObject(state, {
				deletionLoading: false,
				refreshList: true,
			});
		case actionTypes.DELETE_REST_REQUEST_OF_DOMAIN_FAIL:
			return updateObject(state, {
				deletionLoading: false,
				deletionError: action.error
			});
		default: {
			return state;
		}
	}
}

export default reducer;