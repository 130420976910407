export const RESPONSE_BODY = "RESPONSE_BODY";
export const STATUS_CODE = "STATUS_CODE";
export const TIME = "TIME";
export const SIZE = "SIZE";

export const AssertionTypeObjects = [
	{label: 'Status Code', value: STATUS_CODE},
	{label: 'Response Body', value: RESPONSE_BODY}
];

export const AssertionTypeValues = [
	"RESPONSE_BODY",
	"STATUS_CODE"
];