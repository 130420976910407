import React from "react";
import {Spinner} from "react-bootstrap";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	splash: {
		// position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	splashInline: {
		// position: 'absolute',
		// width: '100%',
		// height: '100%',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// flexDirection: 'column',
	}
}));

const Splash = props => {
	const classes = useStyles();

	return <div className = {classes.splash}>
		<Spinner animation = "border" variant = "danger">
			<span className = "sr-only">Loading...</span>
		</Spinner>
	</div>

}

export const SplashInline = props => {
	// const classes = useStyles();

	return  <span className = 'text-center align-middle'>
				<Spinner animation = "border" variant = "danger">
					<span className = "sr-only">Loading...</span>
				</Spinner>
			</span>;
}

export default Splash;