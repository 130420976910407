// Candidate for Global.
import {checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";

export const changeText = (name, event, id, intl) => {
	const updatedName = {...name};
	updatedName.value = event.target.value;
	const [valid, message] = checkValidity(updatedName, UINames(id, intl), intl);
	updatedName.valid = valid;
	updatedName.validationMessage = message;
	return updatedName;
}

export const changeDescription = (description, value, id, intl) => {
	const updatedDescription = {...description};
	updatedDescription.value = value;
	const [valid, message] = checkValidity(updatedDescription, UINames(id, intl), intl);
	updatedDescription.valid = valid;
	updatedDescription.validationMessage = message;
	return updatedDescription;
}

export const selectProject = (project, event, intl) => {
	const updatedProject = {...project};
	let selectedProject;
	for (let project of updatedProject.projects) {
		if (project.id === event.target.value.id) {
			selectedProject = project;
		}
	}
	updatedProject.project = selectedProject;
	[updatedProject.valid, updatedProject.validationMessage] =
		checkSelectionValidity(updatedProject, 'project', UINames('project', intl), intl);
	return updatedProject;
};