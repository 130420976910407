import {put} from "redux-saga/effects";
import {push} from "connected-react-router";

import * as actions from "../actions";
import axios from "../../axios/axios";

export function* createTest(action) {
	yield put(actions.createTestStart());
	try {
		// weird using domain, but that what abderrahim used
		const response = yield axios.post(`/tests/domain/${action.domainID}`, action.test);
		if (response.status === 201) {
			yield put(actions.createTestSuccess(response.data));
			yield put(push(`/test/${response.data.id}`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createTestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createTestFail('Error Creating The Test, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createTestFail(error.message));
		}
	}
}

export function* loadTest(action) {
	yield put(actions.loadTestStart());
	try {
		const response = yield axios.get(`/tests/${action.id}`);

		yield put(actions.loadTestSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadTestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadTestFail('Error Loading Test, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadTestFail(error.message));
		}
	}
}

export function* updateTest(action) {
	yield put(actions.updateTestStart());
	try {
		const response = yield axios.patch(`/tests/${action.id}`, action.test);
		yield put(actions.updateTestSuccess());

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateTestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateTestFail('Error Updating Test, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateTestFail(error.message));
		}
	}
}

export function* deleteTest(action) {
	yield put(actions.deleteTestStart());
	try {
		const response = yield axios.delete(`/tests/${action.id}`);
		yield put(actions.deleteTestSuccess(action.id));
		yield put(actions.deleteTestFromLoadedDomain(action.id));
		if(action.redirect){
			yield put(push(action.redirect));
		}

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteTestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteTestFail('Error Deleting Test, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteTestFail(error.message));
		}
	}
}

export function* loadProjectTests(action) {
	yield put(actions.loadProjectTestsStart());
	try {
		const response = yield axios.get(`/tests/project/${action.id}/part`);
		yield put(actions.loadProjectTestsSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadProjectTestsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadProjectTestsFail('Error Loading Project Tests, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadProjectTestsFail(error.message));
		}
	}
}

export function* moveTestToDomain(action) {
	yield put(actions.moveTestToDomainStart());
	try {
		let response = yield axios.put(`/tests/${action.testID}/${action.sourceDomainId}/${action.targetDomainId}`);
		const sourceDomain = response.data;
		yield put(actions.updateDomainSuccess(sourceDomain));

		// response = yield axios.get(`/domains/${action.sourceDomainId}`);
		// let sourceDomain = response.data;
		// console.log('sourceDomain before', sourceDomain);
		// sourceDomain.cases = sourceDomain.cases.filter((testCase) => testCase.id !== test.id);
		// console.log('sourceDomain after', sourceDomain);
		// 	// patch
		// response = yield axios.patch(`/domains/${action.sourceDomainId}`, {cases: {value: sourceDomain.cases}});
		// sourceDomain = response.data;

		//
		// // add test to new test
		// response = yield axios.get(`/domains/${action.targetDomainId}`);
		// let targetDomain = response.data;
		// console.log('targetDomain before', targetDomain);
		// targetDomain.cases.push(test);
		// console.log('targetDomain after', targetDomain);
		// 	// patch
		// yield axios.patch(`/domains/${action.targetDomainId}`, {cases: {value: targetDomain.cases}});

		yield put(actions.moveTestToDomainSuccess());

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.moveTestToDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.moveTestToDomainFail('Error Moving Test To Domain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.moveTestToDomainFail(error.message));
		}
	}
}