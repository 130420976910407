import React, {forwardRef, useState} from "react";
import SVG from "react-inlinesvg";
import {AppBar, Button, Dialog, Slide, Toolbar, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {makeStyles} from "@material-ui/core/styles";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const Transition = forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const MoveTestToDomainJSX = props => {
	const intl = useIntl();
	const classes = useStyles();



	return <>
		<a title = {intl.formatMessage({ id: "MOVE.TEST.TABLE.ACTION.MOVE", defaultMessage: 'Move Test' })}
		   className = "btn btn-icon btn-light btn-hover-primary btn-sm "
		   onClick = {props.showTestDialog}
		>
			<span className = "svg-icon svg-icon-md svg-icon-primary">
				<SVG title = {intl.formatMessage({ id: "MOVE.TEST.TABLE.ACTION.MOVE", defaultMessage: 'Move Test' })}
					 src = {toAbsoluteUrl("/media/svg/icons/Text/Redo.svg")}/>
			</span>
		</a>
		<Dialog fullScreen open = {props.moveTestDialogVisible} onClose = {props.hideTestDialog} TransitionComponent = {Transition}  style={{zIndex: 900}}>
			<AppBar className = {classes.appBar}>
				<Toolbar>
					<IconButton edge = "start" color = "inherit" onClick = {props.hideTestDialog} aria-label = "close">
						<CloseIcon/>
					</IconButton>
					<Typography variant = "h6" className = {classes.title}>
						<FormattedMessage id = "MOVE.TEST.DIALOG.TITLE" defaultMessage = 'Move Test To This Domain'/>
					</Typography>
					<Button autoFocus color = "inherit" onClick = {props.move}>
						<FormattedMessage id="BUTTON.MOVE" defaultMessage='Move'/>
					</Button>
				</Toolbar>
			</AppBar>

			{props.moveErrorVisible && <Alert severity="error" className="my-3" onClose={() => props.setMoveErrorVisible(false)}>
				<FormattedMessage id="MOVE.TEST.ERROR.NO.DOMAIN.SELECTED" defaultMessage='Must select a target domain for the test'/>
			</Alert>}

			<DataTable value={props.targetDomains}
					   paginator
					   rows={50}
					   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					   paginatorTemplate = "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
					   rowsPerPageOptions = {[10, 20, 50, 100]}
					   emptyMessage={intl.formatMessage({ id: "MOVE.TEST.EMPTY.DOMAINS.LIST", defaultMessage: 'No Domains'})}
					   selection={props.targetDomain} onSelectionChange={e => {
						   props.setTargetDomain(e.value)
					   }}>
				<Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
				<Column field="label" columnKey='label'
						header={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
						sortable
						filter
						filterPlaceholder={intl.formatMessage({ id: "MOVE.TEST.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER", defaultMessage: 'Search by name'})}
						filterMatchMode='contains'
				/>
			</DataTable>
		</Dialog>
	</>
}

export default MoveTestToDomainJSX;