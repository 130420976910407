import React, {useEffect} from "react";
import {connect} from "react-redux";

import * as actions from "../../../store/actions";
import DeviceGroupJSX from "../../../components/Project/Device/DeviceGroupJSX";

const DeviceGroup = props => {

	useEffect(() => {
		props.onLoadDevices();
	}, []);

	const saveDeviceGroup = (group) => {
		// if DeviceGroup used from a parent
		props.save(group);
	}

	return <DeviceGroupJSX devices={props.devices}
						   saveDeviceGroup={saveDeviceGroup}
						   incompatibleDevice={props.incompatibleDeviceGroup}
						   registerSaveGroupCallback={props.registerSaveGroupCallback}
	/>

}

const mapStateToProps = state => {
	return {
		devices: state.project.devices,
		createdDeviceGroup: state.project.createdDeviceGroup,
		// project: state.project.projectView,
		incompatibleDeviceGroup: state.project.incompatibleDeviceGroup,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadDevices: () => dispatch(actions.loadDevices()),
		onSaveDeviceGroup: (projectId, group) => dispatch(actions.createDeviceGroup(projectId, group)),

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroup);