export const checkValidity = (element, name, intl) => {
	const rules = element.validation;
	let value = element.value;
	let isValid = true;
	let validationMessage = null;

	if (!rules) {
		return true;
	}

	if (rules.required) {
		value = typeof value === 'string' ? value.trim(): value;
		isValid = value !== '' && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.REQUIRED",
			defaultMessage: 'is required!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	// prohibitedValues
	if (rules.prohibitedValues) {
		let accept = true;
		for(let prohibitedValue of rules.prohibitedValues){
			if(value.toLowerCase() === prohibitedValue){
				accept = false;
				break;
			}
		}
		isValid = accept && isValid;
		validationMessage = !isValid ? `${value} ${intl.formatMessage({ id: "VALIDATION.PROHIBITED", 
			defaultMessage: 'not allowed as a valid value!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.noSpace) {
		isValid = !value.includes(' ') && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.NO.SPACE",
			defaultMessage: 'cannot contains SPACE as a Character!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.minLength) {
		isValid = (value.length >= rules.minLength) && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.MIN.LENGTH",
			defaultMessage: 'length should be at least'})} ${rules.minLength} !` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.maxLength) {
		isValid = (value.length <= rules.maxLength) && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.MAX.LENGTH",
			defaultMessage: 'length should be less than'})} ${rules.maxLength} !` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.isEmail) {
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		isValid = pattern.test(value) && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.VALID.EMAIL",
			defaultMessage: 'is not a valid email!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if(rules.isURL){
		// function ValidateIPaddress(ipaddress) {
		//   if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
		//     return (true)
		//   }
		//   alert("You have entered an invalid IP address!")
		//   return (false)
		// }

		// check if website OR if ip address

		const isWebsite = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) != null;

		const isIP = value.match(/(localhost|\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{0,4})?\b)/) != null;

		// const isIP = (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value));

		isValid = (isWebsite || isIP) && isValid;

		// isValid = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) != null && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.NOT.URL",
			defaultMessage: 'is not a valid URL!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.isNumeric && value.length > 0) {
		const pattern = /^\d+$/;
		isValid = pattern.test(value) && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.NOT.NUMBER",
			defaultMessage: 'is not a number!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.minNumber && value.length > 0) {
		isValid = (value >= rules.minNumber) && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.LESS.THAN",
			defaultMessage: 'is less than'})} ${rules.minNumber}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.maxNumber && value.length > 0) {
		isValid = (value >= rules.maxNumber) && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.BIGGER.THAN",
			defaultMessage: 'is bigger than'})} ${rules.maxNumber}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	if (rules.isImageLink) {
		isValid = value.match(/\.(jpeg|jpg|gif|png)$/) != null && isValid;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.NOT.IMAGE",
			defaultMessage: 'is not an image!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}
	if (rules.apk) {
		// problem: null should fail because nothing is selected. and in updating null is a valid
		// value that means no change
		// solution: label is alwasy a String when updating. NONO
		// try skip if label exist in update
		// if(element.label !== ''){
			isValid = (value.type === "application/vnd.android.package-archive" || value.name?.endsWith('.apk')) && isValid;
			validationMessage = !isValid ? `${intl.formatMessage({ id: "VALIDATION.APK.FILE",
				defaultMessage: 'File is not an APK File!'})}` : null;
			if(!isValid){
				return [isValid, validationMessage];
			}
		// }

	}
	return [isValid, validationMessage];
};

export const checkDateValidity = (element, name, intl) => {
	const rules = element.validation;
	let value = element.value;
	let isValid = true;
	let validationMessage = null;

	if (!rules) {
		return true;
	}
	if(rules.required){
		isValid = value ? true : false;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.REQUIRED",
			defaultMessage: 'is required!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	const date = new Date(value);
	if(date == 'Invalid Date'){
		isValid = false;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.DATE",
			defaultMessage: 'is not a valid Date!'})}` : null;
	}
	return [isValid, validationMessage];
}

export const checkTimeValidity = (element, name, intl) => {
	const rules = element.validation;
	let value = element.value;
	let isValid = true;
	let validationMessage = null;

	if (!rules) {
		return true;
	}
	if(rules.required){
		isValid = value ? true : false;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.REQUIRED",
			defaultMessage: 'is required!'})}` : null;
		if(!isValid){
			return [isValid, validationMessage];
		}
	}

	const time = new Date(value);
	// console.log('time', time); // TODO check if Date is correct

	if(time == 'Invalid Date'){
		isValid = false;
		validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.TIME",
			defaultMessage: 'is not a valid Time!'})}` : null;
	}
	return [isValid, validationMessage];
}

export const checkSelectionValidity = (element, valueHolder, name, intl) => {
	const rules = element.validation;
	let value = element[valueHolder];
	let isValid = true;
	let validationMessage = null;
	if (!rules) {
		return true;
	}
	if(rules.required){
		if( !value || value.id === 0){ // TODO check if must change to .vlaue instead of .id
			isValid = false;
			validationMessage = !isValid ? `${name} ${intl.formatMessage({ id: "VALIDATION.REQUIRED",
				defaultMessage: 'is required!'})}` : null;
		}

	}
	return [isValid, validationMessage];
}

export const checkArraySelectionValidity = (element, id,  name, intl) => {
	const rules = element.validation;
	let value = element[id];
	let isValid = true;
	let validationMessage = null;

	if (!rules) {
		return true;
	}

	if(rules.required){
		if(!value || !value.length){
			isValid = false;
			validationMessage = `${intl.formatMessage({ id: "VALIDATION.SELECT.ONE",
				defaultMessage: 'Select atleast one'})} ${name}`;
		}
	}
	return [isValid, validationMessage];
}
