import * as actionTypes from "./actionTypes";
import {
	LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_ALREADY_IN_USE,
	LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_STOP_TO_CONTINUE, PREPARE_ANDROID_RECORDING_ENVIRONMENT_NULLIFY_ERROR,
	PREPARE_ANDROID_RECORDING_ENVIRONMENT_TOGGLE_LOADING_MESSAGE,
	REMOVE_DOMAIN_ID_FROM_REQUESTED_ANDROID_ENVIRNOMENT_LIST
} from "./actionTypes";

export const createDomain = (domain, projectID) => {
	return {
		type: actionTypes.CREATE_DOMAIN,
		domain: domain,
		projectID: projectID
	}
};

export const createDomainStart = () => {
	return {
		type: actionTypes.CREATE_DOMAIN_START
	}
};

export const createDomainSuccess = () => {
	return {
		type: actionTypes.CREATE_DOMAIN_SUCCESS
	}
};

export const createDomainFail = (error) => {
	return {
		type: actionTypes.CREATE_DOMAIN_FAIL,
		error: error
	}
};

export const loadDomains = (projectID, config) => {
	return {
		type: actionTypes.LOAD_DOMAINS,
		projectID: projectID,
		config: config
	}
};

export const loadDomainsStart = () => {
	return {
		type: actionTypes.LOAD_DOMAINS_START
	}
};

export const loadDomainsSuccess = (domains, totalRecords) => {
	return {
		type: actionTypes.LOAD_DOMAINS_SUCCESS,
		domains: domains,
		totalRecords: totalRecords
	}
};

export const loadDomainsFail = (error) => {
	return {
		type: actionTypes.LOAD_DOMAINS_FAIL,
		error: error
	}
};

export const deleteDomain = (id, redirect) => {
	return {
		type: actionTypes.DELETE_DOMAIN,
		id: id,
		redirect: redirect
	}
};

export const deleteDomainStart = () => {
	return {
		type: actionTypes.DELETE_DOMAIN_START
	}
};

export const deleteDomainSuccess = (id) => { // for removal from red state
	return {
		type: actionTypes.DELETE_DOMAIN_SUCCESS,
		id: id
	}
};

export const deleteDomainFail = (error) => {
	return {
		type: actionTypes.DELETE_DOMAIN_FAIL,
		error: error
	}
};

export const loadDomain = (id) => {
	return {
		type: actionTypes.LOAD_DOMAIN,
		id: id
	}
};

export const loadDomainStart = () => {
	return {
		type: actionTypes.LOAD_DOMAIN_START
	}
};

export const loadDomainSuccess = (domain) => {
	return {
		type: actionTypes.LOAD_DOMAIN_SUCCESS,
		domain: domain
	}
};

export const loadDomainFail = (error) => {
	return {
		type: actionTypes.LOAD_DOMAIN_FAIL,
		error: error
	}
};

export const updateDomain = (domain, id, stateDomain) => {
	return {
		type: actionTypes.UPDATE_DOMAIN,
		id: id,
		domain: domain,
		stateDomain: stateDomain
	}
};

export const updateDomainStart = () => {
	return {
		type: actionTypes.UPDATE_DOMAIN_START
	}
};

export const updateDomainSuccess = (domain) => {
	return {
		type: actionTypes.UPDATE_DOMAIN_SUCCESS,
		domain: domain
	}
};

export const updateDomainFail = (error) => {
	return {
		type: actionTypes.UPDATE_DOMAIN_FAIL,
		error: error
	}
};

export const importTests = (id, cases) => {
	return {
		type: actionTypes.IMPORT_TESTS,
		id: id,
		cases: cases
	}
};

export const importTestsStart = () => {
	return {
		type: actionTypes.IMPORT_TESTS_START
	}
};

export const importTestsSuccess = (domain) => {
	return {
		type: actionTypes.IMPORT_TESTS_SUCCESS,
		domain: domain
	}
};

export const importTestsFail = (error) => {
	return {
		type: actionTypes.IMPORT_TESTS_FAIL,
		error: error
	}
};

export const loadDomainsWithTestsOrRequests = (projectID) => {
	return {
		type: actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS,
		projectID: projectID
	}
};

export const loadDomainsWithTestsOrRequestsStart = () => {
	return {
		type: actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_START
	}
};

export const loadDomainsWithTestsOrRequestsSuccess = (domains) => {
	return {
		type: actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_SUCCESS,
		domains: domains
	}
};

export const loadDomainsWithTestsOrRequestsFail = (error) => {
	return {
		type: actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_FAIL,
		error: error
	}
};

export const prepareAndroidRecoringEnvironment = (domainID, projectId) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT,
		domainID: domainID,
		projectId: projectId
	}
};

export const prepareAndroidRecoringEnvironmentStart = () => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_START
	}
};

export const prepareAndroidRecoringEnvironmentSuccesssDone = (domainID) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS_DONE,
		domainID: domainID
	}
};

export const prepareAndroidRecoringEnvironmentToggleLoadingMessage = (show) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_TOGGLE_LOADING_MESSAGE,
		show: show
	}
};


export const prepareAndroidRecoringEnvironmentSuccess = (parameters, domainID, projectId) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS,
		parameters: parameters,
		domainID: domainID,
		projectId: projectId
	}
};

export const prepareAndroidRecoringEnvironmentStopToContinue = (show, domainLabel) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_STOP_TO_CONTINUE,
		show: show,
		domainLabel: domainLabel
	}
};

export const prepareAndroidRecoringEnvironmentAlreadyInUse = (show, fullname) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_ALREADY_IN_USE,
		show: show,
		fullname: fullname
	}
};

export const prepareAndroidRecoringEnvironmentFail = (error) => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_FAIL,
		error: error
	}
};

export const prepareAndroidRecoringEnvironmentNullifyError = () => {
	return {
		type: actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT_NULLIFY_ERROR,
	}
};

export const stopAndroidRecoringEnvironment = (projectID) => {
	return {
		type: actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT,
		projectID: projectID
	}
};

export const stopAndroidRecoringEnvironmentStart = () => {
	return {
		type: actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT_START
	}
};

export const stopAndroidRecoringEnvironmentSuccess = () => {
	return {
		type: actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT_SUCCESS,
	}
};

export const stopAndroidRecoringEnvironmentFail = (error) => {
	return {
		type: actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT_FAIL,
		error: error
	}
};

// export const loadCurrentAndroidRecoringEnvironmentStateOfDomain = (domainID, projectID) => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN,
// 		domainID: domainID,
// 		projectID: projectID
// 	}
// };
//
// export const loadCurrentAndroidRecoringEnvironmentStateOfDomainStart = () => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_START
// 	}
// };
//
// export const loadCurrentAndroidRecoringEnvironmentStateOfDomainCreated = () => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_CREATED,
// 	}
// };
//
// export const loadCurrentAndroidRecoringEnvironmentStateOfDomainSuccess = (parameters, domainID, projectID) => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_SUCCESS,
// 		parameters: parameters,
// 		domainID: domainID,
// 		projectID: projectID
// 	}
// };
//
// export const loadCurrentAndroidRecoringEnvironmentStateOfDomainStopToContinue = (show, domainLabel) => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_STOP_TO_CONTINUE,
// 		show: show,
// 		domainLabel: domainLabel
// 	}
// };
//
// export const loadCurrentAndroidRecoringEnvironmentStateOfDomainAlreadyInUse = (show, fullname) => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_ALREADY_IN_USE,
// 		show: show,
// 		fullname: fullname
// 	}
// };
//
// export const loadCurrentAndroidRecoringEnvironmentStateOfDomainFail = (error) => {
// 	return {
// 		type: actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_FAIL,
// 		error: error
// 	}
// };
//
// export const removeDomainIDFromRequestedAndroidEnvironmentList = (domainID) => {
// 	return {
// 		type: actionTypes.REMOVE_DOMAIN_ID_FROM_REQUESTED_ANDROID_ENVIRNOMENT_LIST,
// 		domainID: domainID
// 	}
// };