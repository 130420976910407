import {useIntl} from "react-intl";
import React from "react";
import {AppBar, Tab, Tabs} from "@material-ui/core";

import TabPanel from "../../UI/TabPanel";
import RestRequestTabDescription from "./RestRequestTabDescription";
import RestRequestTabParameters from "./RestRequestTabParameters";
import RestRequestTabHeaders from "./RestRequestTabHeaders";
import RestRequestTabBody from "./RestRequestTabBody";
import RestRequestTabAuthentication from "./RestRequestTabAuthentication";
import RestRequestTabAssertions from "./RestRequestTabAssertions";
import RestRequestTabResponseCollection from "./RestRequestTabResponseCollection";

const RestRequestTabs = props => {
	const intl = useIntl();

	const tabChangeHandler = (event, newValue) => {
		props.setSelectTab(newValue);
	};

	return (
		// <div className = "bg-gray-600">
		 <>
			<AppBar position = "static" color='transparent' elevation={0}>
				<Tabs value = {props.selectTab} onChange = {tabChangeHandler}
				      variant="scrollable" scrollButtons="auto">
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.DESCRIPTION", defaultMessage: 'Description'
					})}/>
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.PARAMETERS", defaultMessage: 'Parameters'
					})}/>
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.HEADERS", defaultMessage: 'Headers'
					})}/>
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.BODY", defaultMessage: 'Body (JSON)'
					})}/>
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.AUTHENTICATION", defaultMessage: 'Authentication'
					})}/>
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.ASSERTIONS", defaultMessage: 'Assertions'
					})}/>
					<Tab label = {intl.formatMessage({
						id: "REST.REQUEST.TABS.TITLE.RESPONSE.COLLECTION", defaultMessage: 'Response Collection'
					})}/>
				</Tabs>
			</AppBar>

			<TabPanel value={props.selectTab} index={0}>
				<RestRequestTabDescription description={props.description}
				                           setDescription={props.setDescription}
				                           registerValidateDescriptionFormCallback={props.registerValidateDescriptionFormCallback}/>
			</TabPanel>

			<TabPanel value={props.selectTab} index={1}>
				<RestRequestTabParameters parameters={props.parameters}
				                          setParameters={props.setParameters}/>
			</TabPanel>

			 <TabPanel value={props.selectTab} index = {2}>
				 <RestRequestTabHeaders headers = {props.headers}
				                        setHeaders = {props.setHeaders}/>
			 </TabPanel>
			 <TabPanel value={props.selectTab} index = {3}>
				 <RestRequestTabBody body = {props.body}
				                     setBody = {props.setBody}/>
			 </TabPanel>
			 <TabPanel value={props.selectTab} index={4}>
				 <RestRequestTabAuthentication
					 authentication={props.authentication}
					 setAuthentication={props.setAuthentication}
					 registerValidateAuthBasicFormCallback={props.registerValidateAuthBasicFormCallback}
					 validate={props.validate}
					 registerValidateAuthTokenFormCallback={props.registerValidateAuthTokenFormCallback}
					 registerValidateAuthAPIKeyFormCallback={props.registerValidateAuthAPIKeyFormCallback}
				 />
			 </TabPanel>
			 <TabPanel value={props.selectTab} index={5}>
				 <RestRequestTabAssertions assertions={props.assertions}
										   setAssertions={props.setAssertions}/>
			 </TabPanel>
			 <TabPanel value={props.selectTab} index={6}>
				 <RestRequestTabResponseCollection
					 responseCollection={props.responseCollection}
					 setResponseCollection={props.setResponseCollection}
					 registerValidateResponseCollectionFormCallback={props.registerValidateResponseCollectionFormCallback}/>
			 </TabPanel>

		</>
		// </div>
	);
}

export default RestRequestTabs;