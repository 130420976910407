import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";
import {NULLIFY_CREATED_DEVICE_GROUP} from "../actions/actionTypes";

export const initialState = {
	creationLoading: false,
	creationError: null,

	updatingLoading: false,
	updatingError: null,

	loading: false,
	error: null,
	projects: [],
	project: null,      // This is for the default: for Domains, Suites, Sprints
	numberOfCalls: 0,

	viewPort: 0,

	// Cancel subscriptioon
	cancelSubscriptionLoading: false,
	cancelSubscriptionError: null,
	cancelSubscriptionSuccess: false,

	// Load View Project
	projectView: null,
	projectViewLoading: false,
	projectViewError: null,

	// Load View Projects
	projectsLoading: false,
	projectsError: null,

	// can add
	canAdd: false,
	canAddLoading: false,
	canAddError: null,

	timeZones: [],
	timeZonesLoading: false,
	timeZonesError: null,

	androidEnvironmentStatus: null,
	androidEnvironmentStatusLoading: false,
	androidEnvironmentStatusError: null,

	devices: [],
	devicesLoading: false,
	devicesError: null,

	createdDeviceGroup: null,
	createDeviceGroupLoading: false,
	createDeviceGroupError: null,
	incompatibleDeviceGroup: null,
// {
// 		"id": "63c198560748711f40d4fc5f",
// 		"name": "qjdpam pand",
// 		"description": "qolsdj",
// 		"compatible": true,
// 		"devices": [
// 			{
// 				"id": "63beb11bf3cf1761be3419b5", reason : "The Device 1 is not compatible",
// 			},
// 			{
// 				"id": "63beb11bf3cf1761be3419b9", reason : "The Device 2 is not compatible",
// 			},
// 			{
// 				"id": "63beb11bf3cf1761be3419bc", reason : "The Device 3 is not compatible",
// 			},
// 			{
// 				"id": "63beb11bf3cf1761be3419ca", reason : "The Device 4 is not compatible",
// 			}
// 		]
// 	}
	deletionDeviceGroupLoading: false,
	deletionDeviceGroupError: null,

	updatedDeviceGroup: null,
	updateDeviceGroupLoading: false,
	updateDeviceGroupError: null,

	// Exclusive for projectView
	deviceGroups: [],

	testFiles: [],
	testFilesLoading: false,
	testFilesError: null,

	uploadTestFileLoading: false,
	uploadTestFileError: null,

	deleteTestFileLoading: false,
	deleteTestFileError: null
};

const reducer = (state = initialState, action) => {
	let loading = false;
	let numberOfCalls = 0;
	switch (action.type) {
		case actionTypes.CREATE_PROJECT_START:
			return updateObject(state, {
				creationLoading: true
			});
		case actionTypes.CREATE_PROJECT_SUCCESS:
			return updateObject(state, {
				creationLoading: false,
				creationError: null,
				projectView: action.project,
			});
		case actionTypes.CREATE_PROJECT_FAIL:
			return updateObject(state, {
				creationLoading: false,
				creationError: action.error,
				projectView: null
			});
		case actionTypes.CREATE_PROJECT_ANDROID_START:
			return updateObject(state, {
				creationLoading: true
			});
		case actionTypes.CREATE_PROJECT_ANDROID_SUCCESS:
			return updateObject(state, {
				creationLoading: false,
				creationError: null,
				projectView: action.project,
			});
		case actionTypes.CREATE_PROJECT_ANDROID_FAIL:
			return updateObject(state, {
				creationLoading: false,
				creationError: action.error,
				projectView: null
			});
		case actionTypes.LOAD_PROJECT_START:
			if(action.view){
				return updateObject(state, {
					projectViewLoading: true
				});
			}
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_PROJECT_SUCCESS:
			if(action.view){
				return updateObject(state, {
					projectView: action.project,
					deviceGroups: Array.isArray(action.project.deviceGroups) ? action.project.deviceGroups : [],
					projectViewLoading: false,
					projectViewError: null
				});
			}
			return updateObject(state, {
				project: action.project,
				loading: false,
				error: null,
			});
		case actionTypes.LOAD_PROJECT_FAIL:
			if(action.view){
				return updateObject(state, {
					projectView: null,
					projectViewLoading: false,
					projectViewError: action.error
				});
			}
			return updateObject(state, {
				project: null,
				loading: false,
				error: action.error
			});
		case actionTypes.LOAD_PROJECT_VIEW_PORT_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_PROJECT_VIEW_PORT_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				viewPort: action.viewPort,
			});
		case actionTypes.LOAD_PROJECT_VIEW_PORT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				viewPort: 0
			});
		case actionTypes.LOAD_PROJECTS_START:
			return updateObject(state, {
				projectsLoading: true,
				projectsError: null,
				projects: []
			});
		case actionTypes.LOAD_PROJECTS_SUCCESS:
			return updateObject(state, {
				projects: action.projects,
				projectsLoading: false,
				projectsError: null
			});
		case actionTypes.LOAD_PROJECTS_FAIL:
			return updateObject(state, {
				projects: null,
				projectsLoading: false,
				projectsError: action.error
			});
		case actionTypes.DELETE_PROJECT_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.DELETE_PROJECT_SUCCESS:
			let projectsCopy = [...state.projects];
			projectsCopy = projectsCopy.filter((project) => {
				if(project.id !== action.id){
					return true;
				}
			});
			return updateObject(state, {
				loading: false,
				error: null,
				projects: projectsCopy
			});
		case actionTypes.DELETE_PROJECT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		case actionTypes.UPDATE_PROJECT_START:
			return updateObject(state, {
				updatingLoading: true,
				numberOfCalls: state.numberOfCalls + 1
			});
		case actionTypes.UPDATE_PROJECT_SUCCESS:
			// action.project is empty because patch does not return anything.
			// so after updating a project load it after success if project === the one being updatd
			loading = false;
			numberOfCalls = state.numberOfCalls - 1;
			if(numberOfCalls > 0){
				loading = true;
			}
			// let preferredProject = state.project;
			// if(action.project.id === state.project.id){
			// 	preferredProject = action.project;
			// }
			return updateObject(state, {
				updatingLoading: loading,
				// projectView: action.project,
				updatingError: null,
				numberOfCalls: numberOfCalls,
				// project: preferredProject
			});
		case actionTypes.UPDATE_PROJECT_FAIL:
			loading = false;
			numberOfCalls = state.numberOfCalls - 1;
			if(numberOfCalls > 0){
				loading = true;
			}
			return updateObject(state, {
				updatingLoading: loading,
				updatingError: action.error,
				numberOfCalls: numberOfCalls
			});

		case actionTypes.TOGGLE_EXEMPT_PROJECT_START:
			return updateObject(state, {
				updatingLoading: true
			});
		case actionTypes.TOGGLE_EXEMPT_PROJECT_SUCCESS:
			let projectsCopy2 = [...state.projects];
			let updatedProject;
			for(let index in state.projects){
				if(state.projects[index].id === action.id){
					projectsCopy2[index].exempt = !state.projects[index].exempt;
					updatedProject = {...projectsCopy2[index]};
				}
			}
			return updateObject(state, {
				updatingLoading: false,
				updatingError: null,
				projects: projectsCopy2,
				projectView: updatedProject
			});
		case actionTypes.TOGGLE_EXEMPT_PROJECT_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error
			});
		case actionTypes.UPDATE_PROJECT_ANDROID_START:
			return updateObject(state, {
				updatingLoading: true,
				numberOfCalls: state.numberOfCalls + 1
			});
		case actionTypes.UPDATE_PROJECT_ANDROID_SUCCESS:
			loading = false;
			numberOfCalls = state.numberOfCalls - 1;
			if(numberOfCalls > 0){
				loading = true;
			}
			return updateObject(state, {
				updatingLoading: loading,
				updatingError: null,
				// projectView: action.project,
				numberOfCalls: numberOfCalls
			});
		case actionTypes.UPDATE_PROJECT_ANDROID_FAIL:
			loading = false;
			numberOfCalls = state.numberOfCalls - 1;
			if(numberOfCalls > 0){
				loading = true;
			}
			return updateObject(state, {
				updatingLoading: loading,
				updatingError: action.error,
				numberOfCalls: numberOfCalls
			});
		case actionTypes.CANCEL_SUBSCRIPTION_START:
			return updateObject(state, {
				cancelSubscriptionLoading: true,
				cancelSubscriptionError: null,
				cancelSubscriptionSuccess: false
			});
		case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
			return updateObject(state, {
				cancelSubscriptionLoading: false,
				cancelSubscriptionError: null,
				cancelSubscriptionSuccess: true
			});
		case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
			return updateObject(state, {
				cancelSubscriptionLoading: false,
				cancelSubscriptionError: action.error,
				cancelSubscriptionSuccess: false
			});
		case actionTypes.CAN_CREATE_PROJECT_START:
			return updateObject(state, {
				canAdd: false,
				canAddLoading: true,
				canAddError: null
			});
		case actionTypes.CAN_CREATE_PROJECT_SUCCESS:
			return updateObject(state, {
				canAdd: action.canAdd,
				canAddLoading: false,
				canAddError: null
			});
		case actionTypes.CAN_CREATE_PROJECT_FAIL:
			return updateObject(state, {
				canAddLoading: false,
				canAddError: action.error
			});
		case actionTypes.LOAD_TIMEZONES_START:
			return updateObject(state, {
				timeZones: [],
				timeZonesLoading: true,
				timeZonesError: null
			});
		case actionTypes.LOAD_TIMEZONES_SUCCESS:
			return updateObject(state, {
				timeZones: action.timeZones,
				timeZonesLoading: false,
			});
		case actionTypes.LOAD_TIMEZONES_FAIL:
			return updateObject(state, {
				timeZonesLoading: false,
				timeZonesError: action.error
			});
		case actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS_START:
			return updateObject(state, {
				androidEnvironmentStatusLoading: true,
				androidEnvironmentStatus: null,
				androidEnvironmentStatusError: null
			});
		case actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS_SUCCESS:
			return updateObject(state, {
				androidEnvironmentStatus: action.status,
				androidEnvironmentStatusLoading: false,
			});
		case actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS_FAIL:
			return updateObject(state, {
				// androidEnvironmentStatus: null,
				androidEnvironmentStatusLoading: false,
				androidEnvironmentStatusError: action.error
			});
		case actionTypes.NULLIFY_ANDROID_ENVIRONMENT_STATUS_ERROR:
			return updateObject(state, {
				androidEnvironmentStatusError: null
			});
		case actionTypes.LOAD_DEVICES_START:
			return updateObject(state, {
				devices: [],
				devicesLoading: true,
				devicesError: null
			});
		case actionTypes.LOAD_DEVICES_SUCCESS:
			return updateObject(state, {
				devices: action.devices,
				devicesLoading: false,
			});
		case actionTypes.LOAD_DEVICES_FAIL:
			return updateObject(state, {
				devicesLoading: false,
				devicesError: action.error
			});
		case actionTypes.CREATE_DEVICE_GROUP_START:
			return updateObject(state, {
				createdDeviceGroup: null,
				createDeviceGroupLoading: true,
				createDeviceGroupError: null,
				incompatibleDeviceGroup: null
			});
		case actionTypes.CREATE_DEVICE_GROUP_SUCCESS:
			// const foundGroup = state.deviceGroups.find(group => group.id === action.group.id);
			// if(state.project.id === state.projectView){
			//
			// }

			// console.log('foundGroup', foundGroup);
			// add to project.deviceGroups
			// let deviceGroupsCopy3 = [...state.projectView.deviceGroups];
			// deviceGroupsCopy3.push(action.group);
			// const projectCopy3 = {...state.projectView};
			// projectCopy3.deviceGroups = deviceGroupsCopy3;
			// let prefProject3 = state.projectView.id === state.project.id ? projectCopy3 : state.project;
			return updateObject(state, {
				// createdDeviceGroup: foundGroup,
				// projectView: projectCopy3,
				// project: prefProject3,
				// deviceGroups: deviceGroupsCopy3,
				createDeviceGroupLoading: false,
			});
		case actionTypes.NULLIFY_CREATED_DEVICE_GROUP:
			return updateObject(state, {
				createdDeviceGroup: null,
			});
		case actionTypes.CREATE_DEVICE_GROUP_FAIL_INCOMPATIBLE:
			return updateObject(state, {
				incompatibleDeviceGroup: action.group,
				createDeviceGroupLoading: false,
			});
		case actionTypes.CREATE_DEVICE_GROUP_FAIL:
			return updateObject(state, {
				createDeviceGroupLoading: false,
				createDeviceGroupError: action.error
			});
		case actionTypes.DELETE_DEVICE_GROUP_START:
			return updateObject(state, {
				deletionDeviceGroupLoading: true,
				deletionDeviceGroupError: null,
			});
		case actionTypes.DELETE_DEVICE_GROUP_SUCCESS:
			if(action.project){
				return updateObject(state, {
					deletionDeviceGroupLoading: false,
					project: action.project,
				});
			} else {
				let deviceGroupsCopy = [...state.projectView.deviceGroups];
				deviceGroupsCopy = deviceGroupsCopy.filter((group) => {
					if(group.id !== action.id){
						return true;
					}
				});
				const projectCopy = {...state.projectView};
				// delete deviceGroup if selected
				if(projectCopy.deviceGroup.id === action.id){
					projectCopy.deviceGroup = deviceGroupsCopy.length > 0 ? deviceGroupsCopy[0] : {}
				}
				projectCopy.deviceGroups = deviceGroupsCopy;
				let prefProject = state.projectView.id === state.project.id ? projectCopy : state.project;

				return updateObject(state, {
					deletionDeviceGroupLoading: false,
					// deletionDeviceGroupError: null,
					projectView: projectCopy,
					deviceGroups: deviceGroupsCopy,
					project: prefProject,
				});
			}

		case actionTypes.DELETE_DEVICE_GROUP_FAIL:
			return updateObject(state, {
				deletionDeviceGroupLoading: false,
				deletionDeviceGroupError: action.error,
			});
		case actionTypes.NULLIFY_DELETION_DEVICE_GROUP_ERROR:
			return updateObject(state, {
				deletionDeviceGroupError: null,
			});
		case actionTypes.UPDATE_DEVICE_GROUP_START:
			return updateObject(state, {
				// updatedDeviceGroup: null,
				updateDeviceGroupLoading: true,
				updateDeviceGroupError: null,
				incompatibleDeviceGroup: null
			});
		case actionTypes.UPDATE_DEVICE_GROUP_SUCCESS:
			// update device group
			// let deviceGroupsCopy2 = [...state.projectView.deviceGroups];
			// deviceGroupsCopy2 = deviceGroupsCopy2.filter((group) => {
			// 	if(group.id !== action.group.id){
			// 		return true;
			// 	}
			// });
			// deviceGroupsCopy2.push(action.group);
			// const projectCopy2 = {...state.projectView};
			// projectCopy2.deviceGroups = [...deviceGroupsCopy2];
			// let prefProject2 = state.projectView.id === state.project.id ? projectCopy2 : state.project;
			return updateObject(state, {
				// projectView: projectCopy2,
				// updatedDeviceGroup: action.group,
				updateDeviceGroupLoading: false,
				// refreshUpdateProject : true,
				// deviceGroups: deviceGroupsCopy2,
				// project: prefProject2
			});
		case actionTypes.UPDATE_DEVICE_GROUP_FAIL_INCOMPATIBLE:
			return updateObject(state, {
				incompatibleDeviceGroup: action.group,
				updateDeviceGroupLoading: false,
			});
		case actionTypes.UPDATE_DEVICE_GROUP_FAIL:
			return updateObject(state, {
				updateDeviceGroupLoading: false,
				updateDeviceGroupError: action.error
			});
		case actionTypes.LOAD_TEST_FILES_START:
			return updateObject(state, {
				testFiles: [],
				testFilesLoading: true,
				testFilesError: null
			});
		case actionTypes.LOAD_TEST_FILES_SUCCESS:
			return updateObject(state, {
				testFiles: action.files,
				testFilesLoading: false,
			});
		case actionTypes.LOAD_TEST_FILES_FAIL:
			return updateObject(state, {
				testFilesLoading: false,
				testFilesError: action.error
			});
		case actionTypes.UPLOAD_TEST_FILE_START:
			return updateObject(state, {
				uploadTestFileLoading: true,
				uploadTestFileError: null
			});
		case actionTypes.UPLOAD_TEST_FILE_SUCCESS:
			return updateObject(state, {
				uploadTestFileLoading: false,
			});
		case actionTypes.UPLOAD_TEST_FILE_FAIL:
			return updateObject(state, {
				uploadTestFileLoading: false,
				uploadTestFileError: action.error
			});
		case actionTypes.DELETE_TEST_FILE_START:
			return updateObject(state, {
				deleteTestFileLoading: true,
				deleteTestFileError: null
			});
		case actionTypes.DELETE_TEST_FILE_SUCCESS:
			return updateObject(state, {
				deleteTestFileLoading: false,
			});
		case actionTypes.DELETE_TEST_FILE_FAIL:
			return updateObject(state, {
				deleteTestFileLoading: false,
				deleteTestFileError: action.error
			});
		default: {
			return state;
		}
	}
}

export default reducer;