export const validateNormal = validateNormalUserFormRef => {
	if (typeof validateNormalUserFormRef.current === 'function' && !validateNormalUserFormRef.current()) {
		console.log('Normal User Form invalid');
		return false;
	}
	return true;
};

export const validateNormalAddress = validateNormalUserAddressFormRef => {
	if (typeof validateNormalUserAddressFormRef.current === 'function' && !validateNormalUserAddressFormRef.current()) {
		console.log('Normal User Address Form invalid');
		return false;
	}
	return true;
};

export const validateCICD = validateCICDUserFormRef => {
	if (typeof validateCICDUserFormRef.current === 'function' && !validateCICDUserFormRef.current()) {
		console.log('CICD User Form invalid');
		return false;
	}
	return true;
};