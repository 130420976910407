import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import SuitesDisplay from "../../components/Suite/SuitesDisplay";

const SuitesSection = (props) => {
	const intl = useIntl();
	// const [emptyProjects, setEmptyProjects] = useState(false);
	const [suites, setSuites] = useState(props.suites);
	const [totalSuites, setTotalSuites] = useState(props.totalSuites);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 50,
		page: 0,
		pageCount: 1,
		sortField: 'label',
		sortOrder: 1
	});
	const dt = useRef(null);

	const filterTimeout = useRef(0);
	useEffect(() => {
		clearTimeout(filterTimeout.current);
		filterTimeout.current = setTimeout(() => {
			if (props.project && props.suites !== null) {
					props.onLoadSuites(props.project.id, createLazyParamsForBackend());
			}
		}, 500);
	}, [lazyParams]);

	// when visiting bookmark url /suites directly
	useEffect(() => {
		if (props.project && props.suites === null) {
			//
			props.onLoadSuites(props.project.id,
				createLazyParamsForBackend()
			);
		}
	}, [props.project, props.suites]);

	const createLazyParamsForBackend = () => {
		const params = {};
		params.size = lazyParams.rows;
		// convert first to page => 0 => 0, 15 => 1, 30 => 2
		params.page = lazyParams.first / lazyParams.rows;
		// console.log('lazyParams.filters', lazyParams.filters);
		if(lazyParams.filters){
			Object.keys(lazyParams.filters).forEach(filter => {
				params[filter] = lazyParams.filters[filter].value;
				// }
			});
		}

		if(lazyParams.hasOwnProperty('sortField')){
			params.sortField = lazyParams.sortField;
			if (lazyParams.sortOrder === 1) {
				params.sortOrder = 'ASC';
			} else {
				params.sortOrder = 'DESC';
			}
		}

		return params;
	}

	useEffect(() => {
		setSuites(props.suites);
	}, [props.suites]);

	useEffect(() => {
		setTotalSuites(props.totalSuites);
	}, [props.totalSuites]);

	useEffect(() => {
		document.title = intl.formatMessage({ id: "PAGE.TITLE.SUITES",
			defaultMessage: 'Suites' });
		// Google Analytics
		// window.gtag('event', 'page_view', {
		// 	page_title: 'Suites, Manually from Code',
		// 	page_path: '/suites'
		// });
	}, []);

	// if user has no access to any project: this works becuase in SuitesDisplay display is set
	// to timeout msg after timeout.
	// setTimeout(() => {
	// 	// optimization ignore if project is true
	// 	if(!props.project){
	// 		setEmptyProjects(true);
	// 	}
	// }, 15000);

	const deleteSuite = (id) => {
		const redirect = '/suites';
		props.onDeleteSuite(id, redirect);
	}

	useEffect(() => {
		if(props.refreshList === true){
			props.onLoadSuites(props.project.id,
				createLazyParamsForBackend()
			);
		}
	}, [props.refreshList]);

	const onPage = (event) => {
		// console.log('onPage event:', event);
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		// console.log('onSort event:', event);
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onFilter = (event) => {
		// console.log('onFilter event:', event);
		let _lazyParams = { ...lazyParams, ...event };
		_lazyParams['first'] = 0;
		setLazyParams(_lazyParams);
	}

	const projectName = props.project ? props.project.label : null;

	let suiteID = null;
	if(props.match && props.match.params && props.match.params && props.match.params.suiteID){
		suiteID = props.match.params.suiteID;
	}
	return (
		<SuitesDisplay
			projectName = {projectName}
			error = {props.error}
			generalError = {props.generalError}
			delete = {deleteSuite}
			suites = {suites}
			suiteID = {suiteID}

			onFilter={onFilter}
			onSort={onSort}
			onPage={onPage}
			totalSuites={totalSuites}
			lazyParams={lazyParams}
			dt={dt}
			loading={props.loading}
		/>
	);
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		suites: state.suite.suites,
		loading: state.suite.listAllLoading,
		error: state.suite.listAllError,
		generalError: state.suite.error,
		totalSuites: state.suite.totalRecords,
		refreshList: state.suite.refreshList,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadSuites: (projectID, config) => dispatch(actions.loadSuites(projectID, config)),
		onDeleteSuite: (id, redirect) => dispatch(actions.deleteSuite(id, redirect))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SuitesSection);