/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect} from "react";
import {connect, Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {ConnectedRouter} from 'connected-react-router';

import {Routes} from "./Routes";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import {I18nProvider} from "../_metronic/i18n";
import {history} from '../redux/store';
import * as actions from "../tigmat/store/actions";
// import "../../public/css/splash-screen";
import "./spinner-css/spin.css";

function App(props) {
	const store = props.store;
	const persistor = props.persistor;
	// const basename = props.basename;

	useEffect(() => {
		// console.log("useEffect onTryAutoSignUp");
		props.onTryAutoSignUp();
	}, []);


	return (
		/* Provide Redux store */
		<Provider store = {store}>
			<ConnectedRouter history = {history}> { /* place ConnectedRouter under Provider */}
				{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
				<PersistGate persistor = {persistor} loading = {<LayoutSplashScreen/>}>
					{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
					<React.Suspense fallback = {<LayoutSplashScreen/>}>
						{/* Override `basename` (e.g: `homepage` in `package.json`) */}
						{/**/}
						{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
						<MaterialThemeProvider>
							{/* Provide `react-intl` context synchronized with Redux state.  */}
							<I18nProvider>
								{/* Render routes with provided `Layout`. */}
								<Routes isAuthenticated={props.isAuthenticated} authCheckFinished={props.authCheckFinished}/>
							</I18nProvider>
						</MaterialThemeProvider>
					</React.Suspense>
				</PersistGate>
			</ConnectedRouter>
		</Provider>
	);
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.authentication.authenticated,
		authCheckFinished: state.authentication.authCheckFinished
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onTryAutoSignUp: () => dispatch(actions.authCheckState())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default App;
