import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import DomainsDisplay from "../../components/Domain/DomainsDisplay";

const DomainsView = (props) => {
	const intl = useIntl();
	const [domains, setDomains] = useState(props.domains);
	const [totalDomains, setTotalDomains] = useState(props.totalDomains);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 50,
		page: 0,
		pageCount: 1,
		sortField: 'label',
		sortOrder: 1
	});
	const dt = useRef(null);

	const filterTimeout = useRef(0);
	useEffect(() => {
		clearTimeout(filterTimeout.current);
		filterTimeout.current = setTimeout(() => {
			if (props.project && props.domains !== null) {
				// console.log('lazyParams, props.domains', lazyParams, props.domains);
				props.onLoadDomains(props.project.id,
					createLazyParamsForBackend()
				);
			}
		}, 500);
	}, [lazyParams]);

	// when visiting bookmark url /domains directly
	useEffect(() => {
		// This is NOT an infinite loop because initially domains = null and if empty list [] then domains is !== than null
		// in case of a fail domains = null than no change occured and the useEffect is not called.
		if (props.project && props.domains === null) {
			// console.log('[props.project, props.domains]');
			props.onLoadDomains(props.project.id,
				createLazyParamsForBackend()
			);
		}
	}, [props.project, props.domains]);

	const createLazyParamsForBackend = () => {
		const params = {};
		params.size = lazyParams.rows;
		// convert first to page => 0 => 0, 15 => 1, 30 => 2
		params.page = lazyParams.first / lazyParams.rows;
		// console.log('lazyParams.filters', lazyParams.filters);
		if (lazyParams.filters) {
			Object.keys(lazyParams.filters).forEach(filter => {
				params[filter] = lazyParams.filters[filter].value;
			});
		}

		if (lazyParams.hasOwnProperty('sortField')) {
			params.sortField = lazyParams.sortField;
			if (lazyParams.sortOrder === 1) {
				params.sortOrder = 'ASC';
			} else {
				params.sortOrder = 'DESC';
			}
		}
		// console.log('createLazyParamsForBackend params', params);
		return params;
	}

	useEffect(() => {
		setDomains(props.domains);
	}, [props.domains]);

	useEffect(() => {
		setTotalDomains(props.totalDomains);
	}, [props.totalDomains]);

	useEffect(() => {
		document.title = intl.formatMessage({
			id: "PAGE.TITLE.DOMAINS",
			defaultMessage: 'Domains'
		});
	}, []);

	useEffect(() => {
		if (props.refreshList === true) {
			// console.log('[props.refreshList]');
			props.onLoadDomains(props.project.id,
				createLazyParamsForBackend()
			);
		}
	}, [props.refreshList]);

	const onPage = (event) => {
		// console.log('onPage event:', event)
		let _lazyParams = {...lazyParams, ...event};
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		// console.log('onSort event:', event)
		let _lazyParams = {...lazyParams, ...event};
		setLazyParams(_lazyParams);
	}

	const onFilter = (event) => {
		// console.log('onFilter event:', event);
		let _lazyParams = {...lazyParams, ...event};
		_lazyParams['first'] = 0;
		setLazyParams(_lazyParams);
	}

	const deleteDomain = (id) => {
		const redirect = '/domains';
		props.onDeleteDomain(id, redirect)
	}

	return (
		<DomainsDisplay
			project = {props.project}
			error = {props.error}
			generalError = {props.generalError}
			delete = {deleteDomain}
			domains = {domains}
			domainID = {props.match.params.domainID}
			onFilter = {onFilter}
			onSort = {onSort}
			onPage = {onPage}
			totalDomains = {totalDomains}
			lazyParams = {lazyParams}
			dt = {dt}
			loading = {props.loading}
		/>
	);
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		projects: state.project.projects,
		domains: state.domain.domains,
		totalDomains: state.domain.totalRecords,
		refreshList: state.domain.refreshList,
		error: state.domain.listAllError,
		loading: state.domain.listAllLoading,
		generalError: state.domain.error,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadProjects: (username) => dispatch(actions.loadProjects(username)),
		onSetProject: (project) => dispatch(actions.loadProjectSuccess(project)),
		onLoadDomains: (projectID, config) => dispatch(actions.loadDomains(projectID, config)),
		onDeleteDomain: (id, redirect) => dispatch(actions.deleteDomain(id, redirect))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsView);