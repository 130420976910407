import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import {validateProjectForm} from "./Validator";
import FormProjectDisplay from "../../components/Project/FormProjectDisplay";
import * as Type from "../../components/Project/enum/ProjectType";
import {ProjectTypeObjects} from "../../components/Project/enum/ProjectType";
import {BrowserTypeObjects} from "../../components/Project/enum/BrowserType";
import {DeviceTypesObjects} from "../../components/Project/enum/DeviceType";
import {OperatingSystemObjects} from "../../components/Project/enum/OperatingSystem";
import {BrowserVersionObjects} from "../../components/Project/enum/BrowserVersion";

const NewProject = props => {

	const intl = useIntl();

	const [users, setUsers] = useState(false);

	const [label, setLabel] = useState('');
	const [url, setUrl] = useState('');
	// const [password, setPassword] = useState('');
	// const [live, setLive] = useState(false);
	const [autoExec, setAutoExec] = useState(false);
	const [timeZone, setTimeZone] = useState('');

	const [startTime, setStartTime] = useState(new Date());
	const [projectType, setProjectType] = useState(ProjectTypeObjects[0]);
	const [browser, setBrowser] = useState(BrowserTypeObjects[1]);
	const [device, setDevice] = useState(DeviceTypesObjects[0]);
	const [description, setDescription] = useState('');
	const [apk, setApk] = useState(new Blob());
	const [apkLabel, setApkLabel] = useState('');
	const [usersLeft, setUsersLeft] = useState([]);
	const [usersRight, setUsersRight] = useState([]);

	const [operatingSystem, setOperatingSystem] = useState(OperatingSystemObjects[0]);
	const [browserVersion, setBrowserVersion] = useState(BrowserVersionObjects[0]);

	useEffect(() => {
		props.onLoadAllUsers(localStorage.getItem("username"));
		document.title = intl.formatMessage({id: "PAGE.TITLE.NEW.PROJECT", defaultMessage: 'New Project'});
	}, []);

	useEffect(() => {
		if(Array.isArray(props.users)){
			if (props.users.length > 0 && !users) {
				setUsersRight([]);
				const updatedUsersLeft = [];
				props.users.map(user => {
					if (user.login !== localStorage.getItem("username")) {
						updatedUsersLeft.push(user.login);
					}
				});
				setUsersLeft(updatedUsersLeft);
				if (usersLeft.length !== 0) {
					setUsers(true);
				}
			}
		}
	}, [props.users]);

	const [errorMessages, setErrorMessages] = useState([]);

	useEffect(() => {
		const updatedGlobalMessages = [...errorMessages];
		if (props.creationError) {
			updatedGlobalMessages.push(props.creationError);
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.creationError]);

	const validateFormRef = useRef();
	const registerValidateFormCallback = callback => {
		validateFormRef.current = callback;
	};

	const createProject = (event) => {
		if(event) {event.preventDefault();}

		// validate all
		if (!validateProjectForm(validateFormRef)) {
			return null;
		}

		const suggestDefault = props.projects.length !== 0;

		let autoExecObject = {autoExec: autoExec};
		if (autoExec) {
			autoExecObject = {
				autoExec: autoExec,
				timeZone: timeZone,
				hourSequence: new Date(startTime).getHours(),
				minuteSequence: new Date(startTime).getMinutes(),
			}
		}
		if (projectType.value === Type.WEB ) {
			const project = {
				label: label,
				type: projectType.value,
				url: url,
				browser: browser.value,
				platform: operatingSystem.value,
				browserVersion: browserVersion.value,
				...autoExecObject,
				description: description,
				users: usersRight
			}
			props.onCreateProject(project, localStorage.getItem('username'), suggestDefault, props.loggedInUser);
		}  else if (projectType.value === Type.ANDROID_WEB) {
			const project = {
				label: label,
				type: projectType.value,
				url: url,
				browser: browser.value,
				androidDevice: device.value,
				// enableLive: live,
				// passLive: password,
				...autoExecObject,
				description: description,
				users: usersRight
			}
			props.onCreateProject(project, localStorage.getItem('username'), suggestDefault, props.loggedInUser);
		} else if (projectType.value === Type.ANDROID) {
			const project = {
				label: label,
				type: projectType.value,
				// androidDevice: device.value,
				// ...autoExecObject,
				// description: description,
				// users: usersRight
			}

			// project.append("file", apk, apkLabel);

			// if (live) {
			// 	project.append("enableLive", JSON.stringify(live));
			// 	project.append("passLive", JSON.stringify(password));
			// }
			props.onCreateAndroidProject(project, suggestDefault, props.loggedInUser, apkLabel, apk);
		}  else if (projectType.value === Type.API) {
			const project = {
				label: label,
				type: projectType.value,
				url: url,
				...autoExecObject,
				description: description,
				users: usersRight
			}
			props.onCreateProject(project, localStorage.getItem('username'), suggestDefault, props.loggedInUser);
		} else {
			// ios do nothing
		}
	}

	if (props.limitReached) {
		return <Redirect to='/projects'/>;
	}

	// console.log('time', Intl.DateTimeFormat().resolvedOptions().timeZone);
	// var offset = new Date().getTimezoneOffset();
	// console.log('offset', offset);

	return (
		<FormProjectDisplay type='create'
							createProject={createProject}
							errorMessages={errorMessages}
							label={label} setLabel={setLabel}
							url={url} setUrl={setUrl}
							// password={password} setPassword={setPassword}
							// live={live} setLive={setLive}
							autoExec={autoExec} setAutoExec={setAutoExec}
							timeZone={timeZone} setTimeZone={setTimeZone}
							// timeZones={props.timeZones}
							startTime={startTime} setStartTime={setStartTime}
							projectType={projectType} setProjectType={setProjectType}
							browser={browser} setBrowser={setBrowser}
							device={device} setDevice={setDevice}
							description={description} setDescription={setDescription}
							apk={apk} setApk={setApk}
							apkLabel={apkLabel} setApkLabel={setApkLabel}
							apkTouched = {false} setApkTouched = {null}
							registerValidateFormCallback={registerValidateFormCallback}

							usersLeft={usersLeft} setUsersLeft={setUsersLeft}
							usersRight={usersRight} setUsersRight={setUsersRight}

							creationLoading={props.creationLoading}
							updatingLoading={props.updatingLoading}
							allUsers={props.users}

							operatingSystem={operatingSystem} setOperatingSystem={setOperatingSystem}
							browserVersion={browserVersion} setBrowserVersion={setBrowserVersion}
		/>
	)
}

const mapStateToProps = state => {
	return {
		users: state.users.allUsers,
		projects: state.project.projects,
		creationError: state.project.creationError,
		creationLoading: state.project.creationLoading,
		updatingError: state.project.updatingError,
		updatingLoading: state.project.updatingLoading,
		loggedInUser: state.authentication.loggedInUser,
		// timeZones: state.project.timeZones,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateProject: (project, username, suggestDefault, loggedInUser) => dispatch(
			actions.createProject(project, username, suggestDefault, loggedInUser)),
		onLoadAllUsers: (username) => dispatch(actions.loadAllUsers(username)),
		onCreateAndroidProject: (project, suggestDefault, loggedInUser, apkLabel, apk) =>
			dispatch(actions.createProjectAndroid(project, suggestDefault, loggedInUser, apkLabel, apk)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
