import {AppBar, Tab, Tabs} from "@material-ui/core";
import React, {useState} from "react";
import {useIntl} from "react-intl";

import TestActions from "../Test/TestActions";
import TestsDisplay from "../Test/TestsDisplay";
import UpdateDomain from "../../containers/Domain/UpdateDomain";
import TabPanel from "../../UI/TabPanel";
import * as Type from "../Project/enum/ProjectType";
import APITestActions from "../Test/APITestActions";
import ListDomainRestRequests from "../../containers/Domain/ListDomainRestRequests";

const DomainDisplayTabs = props => {
	const intl = useIntl();

	const [tab, setTab] = useState(props.tab); // default is 0

	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	let testActions;


	// Web, Android Web, Android: info + tests
	// Api: info + Api test
	let tabHeader, tabContent;
	if(props.type === Type.API){
		testActions = <APITestActions domainId={props.domainId}/>
		tabHeader = <Tab label = {intl.formatMessage({ id: "DOMAINS.TAB.TITLE.API.TESTS..", defaultMessage: 'API Tests' })}/>;
		tabContent = <TabPanel value={tab} index={1} py={0}>
			{testActions}
			<ListDomainRestRequests restRequests={props.domain.restApis}
						  restRequestLabel={props.restRequestLabel}
						  onLoadDomain={props.onLoadDomain}

			/>
		</TabPanel>;
	} else {
		testActions = <TestActions domainID ={props.domain.id}
								   importTests={props.importTests}
								   domain={props.domain}
								   prepareAndroidRecoringEnvironment={props.prepareAndroidRecoringEnvironment}
								   stopAndroidRecoringEnvironment={props.stopAndroidRecoringEnvironment}
								   showStopAndroidRecoringEnvironmentButton={props.showStopAndroidRecoringEnvironmentButton}
								   androidRecoringEnvironment={props.androidRecoringEnvironment}
								   prepareAndroidRecoringEnvironmentLoading={props.prepareAndroidRecoringEnvironmentLoading}
								   stopAndroidRecoringEnvironmentLoading={props.stopAndroidRecoringEnvironmentLoading}
								   showAndroidRecordingEnvironmentLoadingMessage={props.showAndroidRecordingEnvironmentLoadingMessage}
								   hideAndroidRecordingEnvironmentLoadingMessage={props.hideAndroidRecordingEnvironmentLoadingMessage}
								   onShowAndroidRecordingEnvironmentLoadingMessage={props.onShowAndroidRecordingEnvironmentLoadingMessage}
								   toggleAndroidRecordingEnvironmentStopToContinueMessage={props.toggleAndroidRecordingEnvironmentStopToContinueMessage}
								   androidRecordingEnvironmentStopToContinueDomainLabel={props.androidRecordingEnvironmentStopToContinueDomainLabel}
								   showAndroidRecordingEnvironmentStopToContinueMessage={props.showAndroidRecordingEnvironmentStopToContinueMessage}
								   showAndroidRecordingEnvironmentAlreadyInUseMessage={props.showAndroidRecordingEnvironmentAlreadyInUseMessage}
								   androidRecordingEnvironmentAlreadyInUseUserFullname={props.androidRecordingEnvironmentAlreadyInUseUserFullname}
								   hideAndroidRecordingEnvironmentAlreadyInUseMessage={props.hideAndroidRecordingEnvironmentAlreadyInUseMessage}
								   androidEnvironmentStatus={props.androidEnvironmentStatus}
								   androidEnvironmentStatusError={props.androidEnvironmentStatusError}
								   prepareAndroidRecoringEnvironmentError={props.prepareAndroidRecoringEnvironmentError}
								   hidePrepareAndroidRecoringEnvironmentError={props.hidePrepareAndroidRecoringEnvironmentError}
								   showAndroidLimitMessageAlert={props.showAndroidLimitMessageAlert}
								   setShowAndroidLimitMessageAlert={props.setShowAndroidLimitMessageAlert}
								   stoppedByRecorder={props.stoppedByRecorder}
		/>;
		tabHeader = <Tab label = {intl.formatMessage({ id: "DOMAINS.TAB.TITLE.TESTS", defaultMessage: 'Tests' })}/>;
		tabContent = <TabPanel value={tab} index={1} py={0}>
			{testActions}
			<TestsDisplay tests={props.domain.cases}
						  domainName={props.domain.label}
						  deleteTest={props.deleteTest}
						  testError={props.testError}
						  nullifyError={props.nullifyError}
			/>
		</TabPanel>;

	}

	// TODO test if this null assignment actually works.
	if(localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD'){
		testActions = null;

	}

	return(
		<>
			<AppBar position = "static" color='transparent' elevation={0}>
				<Tabs value = {tab} onChange = {tabChangeHandler}
				      variant="scrollable" scrollButtons="auto">
					<Tab label = {intl.formatMessage({ id: "DOMAINS.TAB.TITLE.INFORMATIONS",
						defaultMessage: 'Informations' })}
					     // icon = {
						 //     <SVG
							//      src = {toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}
						 //     />
					     // }
					/>
					{tabHeader}
				</Tabs>
			</AppBar>

			<TabPanel value={tab} index={0} py={0}>
				<UpdateDomain {...props}/>
			</TabPanel>

			{tabContent}

			{/*{tab === 1 && <>*/}
			{/*	{testActions}*/}
			{/*	<TestsDisplay tests={props.domain.cases}*/}
			{/*	              domainName={props.domain.label}*/}
			{/*	              deleteTest={props.deleteTest}*/}
			{/*	              testError={props.testError}*/}
			{/*	              nullifyError={props.nullifyError}*/}
			{/*	/>*/}
			{/*</>}*/}
			{/*{tab === 0 && <UpdateDomain {...props}/>}*/}
		</>
	)
}

export default DomainDisplayTabs;