export const CONTAINS = "CONTAINS";
export const NOT_CONTAINS = "NOT_CONTAINS";
export const MATCHES = "MATCHES";
export const NOT_MATCHES = "NOT_MATCHES";
export const IS_JSON = "IS_JSON";
export const LESS_THAN = "LESS_THAN";
export const GREATER_THAN = "GREATER_THAN";

export const AssertionResponseBodyConditionObjects = [
	{label: 'Contains', value: CONTAINS},
	{label: 'Does Not Contains', value: NOT_CONTAINS},
	{label: 'Matches', value: MATCHES},
	{label: 'Does Not Matches', value: NOT_MATCHES},
	{label: 'Is JSON', value: IS_JSON},
	{label: 'Less Than', value: LESS_THAN},
	{label: 'Greater Than', value: GREATER_THAN}
];

export const AssertionResponseBodyConditionValues = [
	"CONTAINS",
	"NOT_CONTAINS",
	"MATCHES",
	"NOT_MATCHES",
	"IS_JSON",
	"LESS_THAN",
	"GREATER_THAN"
];