import React, {useEffect} from "react";
import ApexCharts from "apexcharts";
import {FormattedMessage, useIntl} from "react-intl";

const getChartOptions = (timeUsage, intl) => {

	const options = {
		series: [{name: 'Used', data: [timeUsage.used]}, {name: 'Remaining', data: [timeUsage.remaining]}],
		// series: [{name: intl.formatMessage({ id: "DASHBOARD.USED", defaultMessage: 'Used' }), data: [100]},
		// 	{name: intl.formatMessage({ id: "DASHBOARD.REMAINING", defaultMessage: 'Remaining' }), data: [80]}],
		chart: {
			type: 'bar', height: 400, stacked: true,
		},
		// labels: timeUsage.projects,
		xaxis: {
			categories: [intl.formatMessage({ id: "DASHBOARD.MOBILE.RECORDER", defaultMessage: 'Mobile Recorder' })]
		},
		// labels: ['Web', 'Mobile'],
		legend: {
			position: 'bottom'
		},
		fill: {
			type: 'gradient',
			// opacity: 1
		}
	};
	return options;
}

const MobileRecorderTimeUsage = props => {
	const intl = useIntl();

	useEffect(() => {
		if(props.mobileRecorderTimeUsage){
			const element = document.getElementById("mobile_recorder_time_usage_chart");
			if (!element) {
				return;
			}

			const options = getChartOptions(props.mobileRecorderTimeUsage, intl);

			const chart = new ApexCharts(element, options);
			chart.render();
			return () => {
				chart.destroy();
			};
		}

	}, [props.mobileRecorderTimeUsage]);

	return <div className="card card-custom gutter-b card-stretch">
		<div className="card-header border-0 pt-5">
			<div className="card-title">
				<div className="card-label">
					<div className="font-weight-bolder">
						<FormattedMessage id="DASHBOARD.MOBILE.RECORDER.TIME.USAGE" defaultMessage='Mobile Recorder Time Usage'/>
					</div>
					<div className="font-size-sm text-muted mt-2">
						<FormattedMessage id="DASHBOARD.MOBILE.RECORDER.TIME.USAGE.SUBHEADER" defaultMessage='Usage in hours'/>
					</div>
				</div>
			</div>
		</div>
		<div className="card-body d-flex flex-column">
			<div className="flex-grow-1">
				<div id="mobile_recorder_time_usage_chart" style={{height: "400px"}}/>
			</div>
			<p>
				<FormattedMessage id="DASHBOARD.MOBILE.RECORDER.TIME.USAGE.P1" defaultMessage='Mobile recorder time is not related to Execution Time.'/>
			</p>
		</div>
	</div>;
}

export default MobileRecorderTimeUsage;