import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormControl, FormHelperText, Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";

import * as actions from "../../store/actions";
import {selectProject} from "../Domain/Shared";

const ProjectSelector = props => {
	const intl = useIntl();
	const [project, setProject] = useState({
		project: props.project ? props.project : null,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		projects: props.projects
	});

	// Find out Prefered.
	const getPreferredProject = () => {
		const loggedInUser = props.loggedInUser;
		let preferredProject = null;
		if (loggedInUser) {
			if (loggedInUser.preferedProjectId && props.projects.length > 0) {
				for (let project of props.projects) {
					if (project.id === loggedInUser.preferedProjectId) {
						preferredProject = project;
					}
				}
			}
		}
		// console.log('preferredProject is:', preferredProject);
		return preferredProject;
	}

	useEffect(() => {
		if (!props.project) {
			if (props.projects.length === 0) {
				props.onLoadProjects(localStorage.getItem('username'));
			} else {
				const preferredProject = getPreferredProject();
				props.onSetProject(preferredProject);
				props.onLoadProjectViewPort(preferredProject.id);
			}
		}
		// else {
		// 	console.log('onMount onLoadDomains');
		// 	props.onLoadDomains(props.project.id);
		// }
	}, []);

	useEffect(() => {
		if (props.projects) {
			if (props.projects.length > 0) {
				const preferredProject = getPreferredProject();
				if (preferredProject) {
					props.onSetProject(preferredProject);
					props.onLoadProjectViewPort(preferredProject.id);
				} else{
					props.onSetProject(props.projects[0]);
					// props.onLoadProjectViewPort(props.projects[0].id); I don't remember if this should be called or not
				}
			}
			// both cases: a project exists or more. and [] because of reducer.
			const updatedProject = {...project};
			updatedProject.projects = props.projects;

			// Explanation: Problem with ProjectSelector: projects no longer contains removed prefered project
			// so the select value does not exist in the list which means uncontrolled.
			// fix: projects change, if select project is not in the list. choose someone in the list
			// New problem solved automatically: when the project is loaded. useEffect of
			// ProjectSelector updates the Select State.
			if(props.projects === null || (Array.isArray(props.projects) && props.projects.length === 0)){
				// console.log('updatedProject.project = null;');
				updatedProject.project = null;
			} else {
				// console.log('updatedProject.project = props.projects[0];', props.projects[0]);
				updatedProject.project = props.projects[0];
			}

			setProject(updatedProject);
		}
	}, [props.projects]);

	useEffect(() => {
		if (props.project) {
			const updatedProject = {...project};
			updatedProject.project = props.project;
			if (updatedProject.projects.length !== props.projects.length) {
				updatedProject.projects = props.projects;
			}
			setProject(updatedProject);
		} else {
			const updatedProject = {...project};
			updatedProject.project = null;
			setProject(updatedProject);
		}
	}, [props.project]);

	const projectSelectHandler = (event) => {
		const updatedProject = selectProject(project, event, intl);
		setProject(updatedProject);

		props.onUpdatePreferred(props.loggedInUser.id, updatedProject.project.id);
	};

	let chooseProject = null;

	if (project.project && project.projects.length > 0) {
		let selectMe;
		for (let current of project.projects) {
			if (current.id === project.project.id) {
				selectMe = current;
				break;
			}
		}

		chooseProject = <FormControl className = {`mr-3 mt-3 min-w-200px `}
		                             error = {!project.valid}>
			<InputLabel htmlFor = "select-project" >
				<FormattedMessage id = "HEADER.SELECT.PROJECT"
				                  defaultMessage = 'Project'/></InputLabel>
			<Select value = {selectMe}
			        onChange = {(event) => projectSelectHandler(event)}
			        input = {<Input id = "select-project"/>}>
				{project.projects.map(currentProject => {
					return <MenuItem key = {currentProject.id}
					                 value = {currentProject}>{currentProject.label}</MenuItem>
				})}
			</Select>
			<FormHelperText>{project.validationMessage}</FormHelperText>
		</FormControl>
	}
	return chooseProject;
}
const mapStateToProps = state => {
	return {
		project: state.project.project,
		projects: state.project.projects,
		loggedInUser: state.authentication.loggedInUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadProjects: (username) => dispatch(actions.loadProjects(username)),
		onLoadProject: (id) => dispatch(actions.loadProject(id)),
		onSetProject: (project) => dispatch(actions.loadProjectSuccess(project, false)),
		onUpdatePreferred: (userId, preferredId) => dispatch(actions.updatePreferredProject(userId, preferredId)),
		onLoadProjectViewPort: (id) => dispatch(actions.loadProjectViewPort(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelector);
