import React from "react";

import {SuiteLoadingDialog} from "../../UI/LoadingDialog";
import SuiteDisplayTabs from "./SuiteDisplayTabs";

const SuiteDisplay = props => {

	return (
		<>
			{/*<DomainActions {...props}/>*/}
			<SuiteLoadingDialog/>
			<SuiteDisplayTabs {...props}/>
		</>
	);
}

export default SuiteDisplay;