import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import AddForm from "../../components/Domain/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";

const UpdateDomain = (props) => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage:
			null
	});

	const [previousNameAndDescription, setPreviousNameAndDescription] = useState({name: '', description: ''});

	useEffect(() => {
		if (props.domain) {
			const updatedName = {...name};
			updatedName.value = props.domain.label;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = props.domain.description ? props.domain.description : '';
			setDescription(updatedDescription);

			// Save Original Values in case there is an error on update
			setPreviousNameAndDescription({name: props.domain.label, description: props.domain.description ? props.domain.description : ''});

			document.title = intl.formatMessage({
				id: "PAGE.TITLE.UPDATE.DOMAIN",
				defaultMessage: 'Update Domain: '
			}) + props.domain.label;
		}
	}, [props.domain])

	useEffect(() => {
		if (props.error) {
			const updatedName = {...name};
			updatedName.value = previousNameAndDescription.name;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = previousNameAndDescription.description;
			setDescription(updatedDescription);
		}
	}, [props.error]);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitDomainHandler = (event) => {
		event.preventDefault();

		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const domain = {
			label: {value: name.value},
			description: {value: description.value}
		};
		const stateDomain = {
			label: name.value,
			description: description.value
		};
		props.onUpdateDomain(domain, props.domain.id, stateDomain);
	}

	return (
		<AddForm name = {name}
		         description = {description}
		         nameChangeHandler = {nameChangeHandler}
		         descriptionChangeHandler = {descriptionChangeHandler}
		         submitDomainHandler = {submitDomainHandler}
		         {...props}
		         error = {props.error}
		         type = 'update'/>
	);
}

const mapStateToProps = state => {
	return {
		domain: state.domain.domain,
		loading: state.domain.updatingLoading,
		error: state.domain.updatingError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateDomain: (domain, id, stateDomain) => dispatch(actions.updateDomain(domain, id, stateDomain)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDomain);
