import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

import * as Plan from "../../containers/Payment/enum/PlanType";

const formatTime = mintutes => {
	if(mintutes > 60){
		let hours = Math.trunc(mintutes / 60);
		let mins = String(mintutes % 60).padStart(2, '0');
		return hours + ':' + mins;
	} else {
		return '00:' + mintutes;
	}

}

const CurrentPlanInformationsJSX = props => {

	// let
	let currentPlanName = 'Free';
	// let currentPlanMaxAllowedExecutions =  60;
	let currentTotalTimePercent = 0;
	let currentTotalWebTime = '00:00';
	let webExecutionTime = '00:30';
	let currentTotalMobileTime = '00:00';
	let mobileExecutionTime = '00:15';
	let currentPlanMaxAndroidRecorderTime =  60; 		// minutes
	let currentPlanMaxAndroidRecorderTimeHours =  1; 		// hours
	// let currentPlanMaxAllowedProjects = 1;
	// let currentPlanMaxAllowedUsers = 5;
	let currentPlanExecutionsCount =  0;
	let currentPlanProjectsCount =  0;
	let currentPlanAndroidRecorderCount =  0;
	let currentPlanAndroidRecorderCountHours =  0;
	let currentPlanAndroidRecorderCountModulusMinutes =  0;
	let currentPlanUsersCount = 0;
	let currentPlanRenewalDate = null;
	let currentPlanCancellationDate = null;
	let currentPlanBalanceAmount = 0;
	// Display
	let usedTimeAndroidRecorder = '00:00';
	let availableTimeAndroidRecorder = '00:15'; //currentPlanMaxAndroidRecorderTime

	let downgradePlanName = '';
	let downgradeToPlan = null;
	if(props.currentPlan !== null){
		currentPlanName = props.currentPlan.type;
		currentTotalTimePercent = props.currentPlan.currentTotalTimePercent;
		currentTotalWebTime = formatTime(props.currentPlan.currentTotalWebTime);
		webExecutionTime = formatTime(props.currentPlan.webExecutionTime);
		currentTotalMobileTime = formatTime(props.currentPlan.currentTotalMobileTime);
		mobileExecutionTime = formatTime(props.currentPlan.mobileExecutionTime);
		currentPlanMaxAndroidRecorderTime = props.currentPlan.mobileInspectTime;
		currentPlanMaxAndroidRecorderTimeHours = currentPlanMaxAndroidRecorderTime / 60;
		// currentPlanMaxAllowedProjects = props.currentPlan.projectsNumber;
		// currentPlanMaxAllowedUsers = props.currentPlan.usersNumber;
		currentPlanExecutionsCount = props.currentPlan.currentTotalExecutions;
		currentPlanProjectsCount = props.currentPlan.currentTotalProjects;
		currentPlanAndroidRecorderCount = props.currentPlan.currentTotalInspectTime;
		currentPlanAndroidRecorderCountModulusMinutes = String(currentPlanAndroidRecorderCount % 60).padStart(2, '0');
		currentPlanAndroidRecorderCountHours = Math.trunc(currentPlanAndroidRecorderCount / 60);
		currentPlanUsersCount = props.currentPlan.currentTotalUsers;
		if(props.currentPlan.type === Plan.BASIC || props.currentPlan.type === Plan.PROFESSIONAL || props.currentPlan.type === Plan.PREMIUM ){
			currentPlanRenewalDate = new Date(props.currentPlan.currentPeriodEndAt).toDateString();
			currentPlanCancellationDate = props.currentPlan.cancelAt ? props.currentPlan.cancelAt : null;
		}
		currentPlanBalanceAmount = props.currentPlan.balance && typeof props.currentPlan.balance === 'number' ?
			props.currentPlan.balance : currentPlanBalanceAmount;
		if(currentPlanMaxAndroidRecorderTime >= 60){
			availableTimeAndroidRecorder = `${currentPlanMaxAndroidRecorderTimeHours}:00`;
		} else{
			availableTimeAndroidRecorder = `00:${currentPlanMaxAndroidRecorderTime}`;
		}
		if(currentPlanAndroidRecorderCount >= 60){
			usedTimeAndroidRecorder = `${currentPlanAndroidRecorderCountHours}:${currentPlanAndroidRecorderCountModulusMinutes}`
		} else {
			usedTimeAndroidRecorder = `00:${currentPlanAndroidRecorderCount}`;
		}

		if(props.currentPlan.nextPlan){
			downgradePlanName = props.currentPlan.nextPlan.price.name
		}

		if (props.currentPlan.status === 'TRIALING' && props.currentPlan.nextPlan) {
			downgradeToPlan = <span className="text-dark-75 font-weight-bolder font-size-sm">
				<FormattedMessage id="PAYMENT.CURRENT.PLAN.DOWNGRADE.MESSAGE.1" defaultMessage='Downgrading To Plan '/> {downgradePlanName}
				<FormattedMessage id="PAYMENT.CURRENT.PLAN.DOWNGRADE.MESSAGE.2" defaultMessage=', starting from renewal date'/>
			</span>;
		}
	}

	return <div className='bg-white p-4 mb-3'>
		<div className="d-flex flex-wrap mt-2"> {/*flex-xxl-center*/}
			{/*// <!-- begin::Item-->*/}
			<div className="d-flex align-items-center flex-sm-fill mr-5 mb-2 min-w-200px">
						<span className="mr-4">
							<i className="flaticon-piggy-bank display-4 font-weight-bold text-info"/>
						</span>
				<div className="d-flex flex-column text-dark-75">
							<span className="font-weight-bolder font-size-sm">
								<FormattedMessage id = "PAYMENT.CURRENT.PLAN.TITLE" defaultMessage = 'Current Plan'/>
							</span>
					<span className="font-weight-bolder font-size-h5">
								{currentPlanName}
							</span>
				</div>
			</div>

			<div className='flex-sm-fill min-w-200px mr-5 mb-2'>
				<div className="d-flex align-items-center flex-sm-fill">
							<span className="mr-4">
								<i className="flaticon2-layers display-4 font-weight-bold text-danger"/>
							</span>
					<Container className = 'p-0'>
						{/*<Row>*/}
						{/*	<Col>*/}
						{/*		<span className="font-weight-bolder font-size-sm">*/}
						{/*			<FormattedMessage id = "PAYMENT.CURRENT.PLAN.TEST.RUNS" defaultMessage = 'Tests Runs'/>*/}
						{/*		</span>*/}
						{/*	</Col>*/}
						{/*</Row>*/}
						<Row>
							<Col xs={9}>
								<div className='d-flex justify-content-between'>
									<span className="font-weight-bolder font-size-sm">Web </span>
									<span className="font-weight-bolder font-size-h5 ">
										{currentTotalWebTime}/{webExecutionTime}
									</span>
								</div>
								<div className='d-flex justify-content-between'>
									<span className="font-weight-bolder font-size-sm">Mobile</span>
									<span className="font-weight-bolder font-size-h5 ">
										{currentTotalMobileTime}/{mobileExecutionTime}
									</span>
								</div>
							</Col>
							<Col xs={3} className='align-self-center'>
								<span className="font-weight-bolder text-dark">
									{Math.trunc(currentTotalTimePercent)}%
								</span>
							</Col>
						</Row>
						{/*<Row>*/}
						{/*	<Col xs={9}>*/}
						{/*				*/}
						{/*	</Col>*/}
						{/*	<Col xs={3} >*/}
						{/*				<span className="font-weight-bolder text-dark">*/}
						{/*					{Math.trunc(currentTotalTimePercent)}%*/}
						{/*				</span>*/}
						{/*	</Col>*/}
						{/*</Row>*/}
					</Container>

					{/*<div className="d-flex flex-column text-dark-75 min-w-150px">*/}
					{/*	<span className="font-weight-bolder font-size-sm">Tests Runs</span>*/}
					{/*	<div className='d-flex align-items-center w-25 flex-fill float-right'>*/}
					{/*		<span className="font-weight-bolder font-size-h5">*/}
					{/*			16 / 60*/}
					{/*		</span>*/}
					{/*		<span className="font-weight-bolder text-dark">78%</span>*/}
					{/*	</div>*/}

					{/*</div>*/}
				</div>
				<div>
					<div className="progress progress-xs mx-3 w-80">  {/* 80 so that bar does not extend to the next div*/}
						<div className="progress-bar bg-success" role="progressbar"
							 style={{width: `${currentTotalTimePercent}%`}}
							 aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"/>
					</div>
				</div>
			</div>



			<div className='flex-sm-fill min-w-200px mr-5 mb-2'>
				<div className="d-flex align-items-center flex-sm-fill">
							<span className="mr-4">
								<i className="flaticon2-ui display-4 font-weight-bold text-success"/>
							</span>
					<Container className = 'p-0'>
						<Row>
							<Col>
								<span className="font-weight-bolder font-size-sm">
									<FormattedMessage id = "PAYMENT.CURRENT.PLAN.ANDROID.RECORDER" defaultMessage = 'Mobile Recorder'/>
								</span>
							</Col>
						</Row>
						<Row>
							<Col xs={9}>
										<span className="font-weight-bolder font-size-h5">
											{/* currentPlanAndroidRecorderCount */}

											{`${usedTimeAndroidRecorder}/${availableTimeAndroidRecorder}`}
											{/*{currentPlanAndroidRecorderCountHours >= 1 ?*/}
											{/*	(`${currentPlanAndroidRecorderCountHours} : ${currentPlanAndroidRecorderCountModulusMinutes}`) :*/}
											{/*	currentPlanAndroidRecorderCount*/}
											{/*} / {currentPlanMaxAndroidRecorderTimeHours} : 00*/}
										</span>
							</Col>
							<Col xs={3} >
										<span className="font-weight-bolder text-dark">
											{Math.trunc(currentPlanAndroidRecorderCount/currentPlanMaxAndroidRecorderTime * 100)}%
										</span>
							</Col>
						</Row>
					</Container>

				</div>
				<div>
					<div className="progress progress-xs mx-3 w-80">  {/* 80 so that bar does not extend to the next div*/}
						<div className="progress-bar bg-success" role="progressbar"
							 style={{width: `${currentPlanAndroidRecorderCount/currentPlanMaxAndroidRecorderTime * 100}%`}}
							 aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"/>
					</div>
				</div>
			</div>



			<div className='flex-sm-fill min-w-200px mr-5 mb-2'>
				<div className="d-flex align-items-center flex-lg-fill">
							<span className="mr-4">
								<i className="flaticon2-group display-4 font-weight-bold text-warning"/>
							</span>
					<Container className = 'p-0'>
						<Row>
							<Col>
										<span className="font-weight-bolder font-size-sm">
											<FormattedMessage id = "PAYMENT.CURRENT.PLAN.USERS" defaultMessage = 'Users'/>
										</span>
							</Col>
						</Row>
						<Row>
							<Col xs={9}>
										<span className="font-weight-bolder font-size-h5">
											{currentPlanUsersCount}
											{/*/ { currentPlanMaxAllowedUsers}*/}
										</span>
							</Col>
							{/*<Col xs={3} >*/}
							{/*	<span className="font-weight-bolder text-dark">*/}
							{/*		{Math.trunc(currentPlanUsersCount/currentPlanMaxAllowedUsers * 100)}%*/}
							{/*	</span>*/}
							{/*</Col>*/}
						</Row>
					</Container>
				</div>
				{/*<div>*/}
				{/*	<div className="progress progress-xs mx-3 w-80">*/}
				{/*		<div className="progress-bar bg-success" role="progressbar"*/}
				{/*			 style={{width: `${currentPlanUsersCount/currentPlanMaxAllowedUsers * 100}%`}} aria-valuenow="50"*/}
				{/*			 aria-valuemin="0" aria-valuemax="100"/>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>

			<div className='flex-sm-fill min-w-200px mr-5 mb-2'>
				<div className="d-flex align-items-center flex-lg-fill">
							<span className="mr-4">
								<i className="flaticon2-box-1 display-4 font-weight-bold text-info"/>
							</span>
					<Container className = 'p-0'>
						<Row>
							<Col>
										<span className="font-weight-bolder font-size-sm">
											<FormattedMessage id = "PAYMENT.CURRENT.PLAN.PROJECTS" defaultMessage = 'Projects'/>
										</span>
							</Col>
						</Row>
						<Row>
							<Col xs={9}>
										<span className="font-weight-bolder font-size-h5">
											{currentPlanProjectsCount}
											{/*/ { currentPlanMaxAllowedProjects}*/}
										</span>
							</Col>
							{/*<Col xs={3} >*/}
							{/*	<span className="font-weight-bolder text-dark">*/}
							{/*		{Math.trunc(currentPlanProjectsCount/currentPlanMaxAllowedProjects * 100)}%*/}
							{/*	</span>*/}
							{/*</Col>*/}
						</Row>
					</Container>
				</div>
				{/*<div>*/}
				{/*	<div className="progress progress-xs mx-3 w-80">*/}
				{/*		<div className="progress-bar bg-success" role="progressbar"*/}
				{/*			 style={{width: `${currentPlanProjectsCount/currentPlanMaxAllowedProjects * 100}%`}} aria-valuenow="50"*/}
				{/*			 aria-valuemin="0" aria-valuemax="100"/>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>

			{/*<div className='flex-sm-fill min-w-200px mr-5 mb-2'>*/}
			{/*	<div className="d-flex align-items-center flex-lg-fill">*/}
			{/*				<span className="mr-4">*/}
			{/*					<i className="flaticon2-ui display-4 font-weight-bold text-success"/>*/}
			{/*				</span>*/}
			{/*		<Container className = 'p-0'>*/}
			{/*			<Row>*/}
			{/*				<Col>*/}
			{/*					<span className="font-weight-bolder font-size-sm">*/}
			{/*						<FormattedMessage id = "PAYMENT.CURRENT.PLAN.ANDROID.RECORDER" defaultMessage = 'Android Recorder'/>*/}
			{/*					</span>*/}
			{/*				</Col>*/}
			{/*			</Row>*/}
			{/*			<Row>*/}
			{/*				<Col xs={9}>*/}
			{/*					<span className="font-weight-bolder font-size-h5">*/}
			{/*						{currentPlanAndroidRecorderCount} <FormattedMessage id = "PAYMENT.CURRENT.PLAN.ANDROID.RECORDER.MINUTES" defaultMessage = 'minutes'/>*/}
			{/*						 = {(currentPlanAndroidRecorderCount / 60).toFixed(2)} <FormattedMessage id = "PAYMENT.CURRENT.PLAN.ANDROID.RECORDER.HOURS" defaultMessage = 'hours'/>*/}
			{/*					</span>*/}
			{/*				</Col>*/}
			{/*			</Row>*/}
			{/*		</Container>*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*// <!-- end::Item-->*/}
			{/*// <!-- begin::Item-->*/}
			{currentPlanRenewalDate || currentPlanCancellationDate ? <div className="d-flex align-items-center mr-5 mb-2 min-w-200px">
				<span className="mr-4">
					<i className="flaticon2-calendar-9 display-4 font-weight-bold text-primary"/>
				</span>
				<div className="d-flex flex-column flex-sm-fill">
					{downgradeToPlan}
					{currentPlanRenewalDate ? <>
						<span className="text-dark-75 font-weight-bolder font-size-sm">
							<FormattedMessage id="PAYMENT.CURRENT.PLAN.RENEWAL.DATE" defaultMessage='Renewal Date'/>
						</span>
						<span className="text-primary font-weight-bolder">
							{currentPlanRenewalDate}
						</span>
					</> : null}
					{currentPlanCancellationDate && <>
								<span className="text-dark-75 font-weight-bolder font-size-sm">
									<FormattedMessage id="PAYMENT.CURRENT.PLAN.BACK.TO.FREE.PLAN" defaultMessage='Back To Free Plan On'/>
								</span>
						<span className="text-primary font-weight-bolder">{new Date(currentPlanCancellationDate).toDateString()}</span>
					</>
					}
				</div>
			</div> : null}
			{/*// <!--end::Item-->*/}
			{currentPlanBalanceAmount !== 0 && <div className="d-flex align-items-center mr-5 mb-2 min-w-200px">
						<span className="mr-4">
							<i className="flaticon2-calendar-9 display-4 font-weight-bold text-primary"/>
						</span>
				<div className="d-flex flex-column flex-sm-fill">
							<span className="text-dark-75 font-weight-bolder font-size-sm">
								<FormattedMessage id = "PAYMENT.CURRENT.PLAN.CURRENT.BALANCE" defaultMessage = 'Current Balance'/>
							</span>
					<span className="text-dark-75 font-size-xs">
								<FormattedMessage id = "PAYMENT.CURRENT.PLAN.CURRENT.BALANCE.HELP"
												  defaultMessage = 'Balance will decrease the amount due on your next invoice.'/>

							</span>
					<span className="text-primary font-weight-bolder">
								{new Number(Math.abs(currentPlanBalanceAmount)).toLocaleString()}
						<sup className="font-weight-normal pl-1">€</sup>
							</span>
				</div>

			</div>}

		</div>
	</div>

}

export default CurrentPlanInformationsJSX;