export const SAMSUNG_GALAXY_S10 = "SAMSUNG_GALAXY_S10";
export const SAMSUNG_GALAXY_S9 = "SAMSUNG_GALAXY_S9";
export const SAMSUNG_GALAXY_S8 = "SAMSUNG_GALAXY_S8";
export const SAMSUNG_GALAXY_S7_EDGE = "SAMSUNG_GALAXY_S7_EDGE";
export const SAMSUNG_GALAXY_S7 = "SAMSUNG_GALAXY_S7";
export const SAMSUNG_GALAXY_S6 = "SAMSUNG_GALAXY_S6";
export const NEXUS_7 = "NEXUS_7";
export const NEXUS_S = "NEXUS_S";
export const NEXUS_ONE = "NEXUS_ONE";
export const NEXUS_5 = "NEXUS_5";
export const NEXUS_4 = "NEXUS_4";

export const DeviceTypesObjects = [
	{id: 1, label: 'Samsung Galaxy S 10', value: SAMSUNG_GALAXY_S10},
	{id: 2, label: 'Samsung Galaxy S 9', value: SAMSUNG_GALAXY_S9},
	{id: 3, label: 'Samsung Galaxy S 8', value: SAMSUNG_GALAXY_S8},
	{id: 4, label: 'Samsung Galaxy S 7 EDGE', value: SAMSUNG_GALAXY_S7_EDGE},
	{id: 5, label: 'Samsung Galaxy S 7', value: SAMSUNG_GALAXY_S7},
	{id: 6, label: 'Samsung Galaxy S 6', value: SAMSUNG_GALAXY_S6},
	{id: 7, label: 'NEXUS 7', value: NEXUS_7},
	{id: 8, label: 'NEXUS S', value: NEXUS_S},
	{id: 9, label: 'NEXUS ONE', value: NEXUS_ONE},
	{id: 10, label: 'NEXUS 5', value: NEXUS_5},
	{id: 11, label: 'NEXUS 4', value: NEXUS_4},
];

export const DeviceTypesValues = [
	SAMSUNG_GALAXY_S10,
	SAMSUNG_GALAXY_S9,
	SAMSUNG_GALAXY_S8,
	SAMSUNG_GALAXY_S7_EDGE,
	SAMSUNG_GALAXY_S7,
	SAMSUNG_GALAXY_S6,
	NEXUS_7,
	NEXUS_S,
	NEXUS_ONE,
	NEXUS_5,
	NEXUS_4,
];