import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import DomainDisplay from "../../components/Domain/DomainDisplay";
import * as Type from "../../components/Project/enum/ProjectType";

const Domain = props => {
	const intl = useIntl();

	let tab = 0;
	if(props.location.state && props.location.state.tab){
		tab = props.location.state.tab;
	}

	const [domain, setDomain] = useState(
		props.domain ? props.domain : {id: 0}
	);

	// this is for android recorder limit reached: when user closes Alert, don't show it again until a new domain is visited
	const [showAndroidLimitMessageAlert, setShowAndroidLimitMessageAlert] = useState(false);

	useEffect(() => {
		if(props.androidEnvironmentStatusError !== null){
			setShowAndroidLimitMessageAlert(true);
		}
	}, [props.androidEnvironmentStatusError])

	// useEffect(() => {
	// 	if(props.domainLoading === false && props.domain && props.prepareAndroidRecoringEnvironmentLoading === false
	// 		&& props.stopAndroidRecoringEnvironmentLoading === false && props.project){
	// 		const fetchingAndroidEnvironmentStatus = setInterval(() => {
	// 			props.onCheckAndroidEnvironmentStatus(props.project.id);
	// 		}, 60_000);
	// 	}
	// }, [props.domainLoading, props.domain, props.prepareAndroidRecoringEnvironmentLoading, props.stopAndroidRecoringEnvironmentLoading, props.project])

	useEffect(() => {
		props.onLoadDomain(props.match.params.domainID);
		// checkDomainAndroidStateIntervalRef.current = 0;
		checkDomainAndroidStatusIntervalRef.current = 0;
		setShowAndroidLimitMessageAlert(false);
	}, [props.match.params.domainID])

	// useEffect(() => {
	// 	console.log('useEffect[] called');
	// 	props.onLoadDomain(props.match.params.domainID);
	// 	// Domains Section takes care of loading domains
	// 	// if(props.project){          // this is null when user visit a bookmark to domain directly
	// 	// 	props.onLoadDomains(props.project.id, {size: 50, page: 0, sortOrder: 'ASC', sortField: 'label'});
	// 	// }
	// }, []);

	useEffect(() => {
		props.onNullifyAndroidEnvironmentStatusError();
		const stoppedByRecorder = new URLSearchParams(props.location.search).get('sBR') === "true";
		setStoppedByRecorder(stoppedByRecorder);
	}, []);

	useEffect(() => {
		if(props.project){
			props.onLoadDomainsWithTestsOrRequests(props.project.id);
		}
	}, [props.project])

	useEffect(() => {
		if(props.domain && props.project && props.domain.id === props.match.params.domainID){
			// third condition to stop checkStatus for already loaded domain: duplicate call
			document.title = intl.formatMessage({ id: "PAGE.TITLE.DOMAIN", defaultMessage: 'Domain: ' }) + props.domain.label;
			setDomain(props.domain);
			if(props.project.type === Type.ANDROID){
				props.onCheckAndroidEnvironmentStatus(props.project.id, props.domain.id);
			}
		}
	}, [props.domain, props.project, props.match.params.domainID]);

	const checkDomainAndroidStatusIntervalRef = useRef(0);
	// this case is for when: click record, close window open window the status is created but no state setinterval is called
	// problem is that how to know which domain id
	useEffect(() => {
		if(props.domain){
			// && !props.requestedAndroidEnvironmentDomainsIdsList.includes(props.domain.id)

			// then simply just add domainID to the list so that the existing useEffect takes place
			// actually just loop check Status

			if(props.androidEnvironmentStatus && props.androidEnvironmentStatus.status === 'CREATED'){
				const checkDomainAndroidStatus = setInterval(() => {
					props.onCheckAndroidEnvironmentStatus(props.project.id, props.domain.id);
				}, 5_000);
				checkDomainAndroidStatusIntervalRef.current = checkDomainAndroidStatus;
			} else {
				clearInterval(checkDomainAndroidStatusIntervalRef.current);
				checkDomainAndroidStatusIntervalRef.current = 0;
			}
		}
	}, [props.domain, props.androidEnvironmentStatus, props.project]);

	const [stoppedByRecorder, setStoppedByRecorder] = useState(false);
	useEffect(() => {
		if(props.domain && props.project && stoppedByRecorder === true){
			if(props.androidEnvironmentStatus?.status !== 'STOPED'){
				const checkDomainAndroidStatus = setInterval(() => {
					props.onCheckAndroidEnvironmentStatus(props.project.id, props.domain.id);
				}, 5_000);
				checkDomainAndroidStatusIntervalRef.current = checkDomainAndroidStatus;
			} else {
				window.history.pushState('', '', `/domain/${props.domain.id}`);
				clearInterval(checkDomainAndroidStatusIntervalRef.current);
				checkDomainAndroidStatusIntervalRef.current = 0;
				setStoppedByRecorder(false);
			}
		}

	}, [props.domain, props.location, props.project, props.androidEnvironmentStatus]);

	// const checkDomainAndroidStateIntervalRef = useRef(0);

	// useEffect(() => {
	// 	if(props.domain){
	// 		if(props.requestedAndroidEnvironmentDomainsIdsList.includes(props.domain.id)){
	// 			if(checkDomainAndroidStateIntervalRef.current === 0){
	// 				props.onLoadCurrentAndroidRecoringEnvironmentStateOfDomain(props.domain.id, props.project.id);
	// 			}
	// 			const checkDomainAndroidState = setInterval(() => {
	// 				props.onLoadCurrentAndroidRecoringEnvironmentStateOfDomain(props.domain.id, props.project.id);
	// 			}, 5_000);
	// 			checkDomainAndroidStateIntervalRef.current = checkDomainAndroidState;
	// 		} else {
	// 			clearInterval(checkDomainAndroidStateIntervalRef.current);
	// 			checkDomainAndroidStateIntervalRef.current = 0
	// 		}
	// 	}
	// }, [props.requestedAndroidEnvironmentDomainsIdsList, props.domain, props.project]);

	const [stopRecorderFired, setStopRecorderFired] = useState(false);

	useEffect(() => {
		if(stopRecorderFired === true && props.stopAndroidRecoringEnvironmentLoading === false){
			setStopRecorderFired(false);
			props.onCheckAndroidEnvironmentStatus(props.project.id, props.domain.id);
		}
	}, [stopRecorderFired, props.stopAndroidRecoringEnvironmentLoading, props.project, props.domain])

	const [startRecorderFired, setStartRecorderFired] = useState(false);

	useEffect(() => {
		if(startRecorderFired === true && props.prepareAndroidRecoringEnvironmentLoading === false && props.prepareAndroidRecoringEnvironmentError === null){
			setStartRecorderFired(false);
			props.onCheckAndroidEnvironmentStatus(props.project.id, props.domain.id);
		}
	}, [startRecorderFired, props.prepareAndroidRecoringEnvironmentLoading, props.project, props.domain])

	const deleteTest = id => {
		props.onDeleteTest(id, null);
		// remove test from cases
	}

	const importTests = (id, cases) => {
		props.onImportTests(id, cases);
	}

	const nullifyError = () => {
		props.onNullifyTestError();
	}

	const prepareAndroidRecoringEnvironment = () => {
		setStartRecorderFired(true);
		props.onPrepareAndroidRecoringEnvironment(props.domain.id, props.project.id);
	}

	const stopAndroidRecoringEnvironment = () => {
		setStopRecorderFired(true);
		props.onStopAndroidRecoringEnvironment(props.project.id);
	}

	const hideAndroidRecordingEnvironmentLoadingMessage = () => {
		props.onHideAndroidRecordingEnvironmentLoadingMessage();
	}

	const toggleAndroidRecordingEnvironmentStopToContinueMessage = (show) => {
		props.onToggleAndroidRecordingEnvironmentStopToContinueMessage(show);
	}

	const hideAndroidRecordingEnvironmentAlreadyInUseMessage = () => {
		props.onToggleAndroidRecordingEnvironmentAlreadyInUseMessage(false);
	}

	const hidePrepareAndroidRecoringEnvironmentError = () => {
		props.onPrepareAndroidRecoringEnvironmentNullifyError();
	}

	const type = props.project ? props.project.type : Type.WEB;
	const restRequestLabel = props.location.state && props.location.state.restRequestLabel ? props.location.state.restRequestLabel : null;
	// const type = Type.API;
	return <DomainDisplay domain={domain}
	                      deleteTest={deleteTest}
	                      domainID={props.match.params.domainID}
	                      importTests={importTests}
	                      testError={props.testError}
	                      tab={tab}
	                      nullifyError={nullifyError}
						  type={type}
						  domainId={props.match.params.domainID}
						  restRequestLabel={restRequestLabel}
						  onLoadDomain={props.onLoadDomain}
						  prepareAndroidRecoringEnvironment={prepareAndroidRecoringEnvironment}
						  stopAndroidRecoringEnvironment={stopAndroidRecoringEnvironment}
						  showStopAndroidRecoringEnvironmentButton={props.showStopAndroidRecoringEnvironmentButton}
						  androidRecoringEnvironment={props.androidRecoringEnvironment}
						  prepareAndroidRecoringEnvironmentLoading={props.prepareAndroidRecoringEnvironmentLoading}
						  prepareAndroidRecoringEnvironmentError={props.prepareAndroidRecoringEnvironmentError}
						  stopAndroidRecoringEnvironmentLoading={props.stopAndroidRecoringEnvironmentLoading}
						  showAndroidRecordingEnvironmentLoadingMessage={props.showAndroidRecordingEnvironmentLoadingMessage}
						  hideAndroidRecordingEnvironmentLoadingMessage={hideAndroidRecordingEnvironmentLoadingMessage}
						  onShowAndroidRecordingEnvironmentLoadingMessage={props.onShowAndroidRecordingEnvironmentLoadingMessage}
						  toggleAndroidRecordingEnvironmentStopToContinueMessage={toggleAndroidRecordingEnvironmentStopToContinueMessage}
						  androidRecordingEnvironmentStopToContinueDomainLabel={props.androidRecordingEnvironmentStopToContinueDomainLabel}
						  showAndroidRecordingEnvironmentStopToContinueMessage={props.showAndroidRecordingEnvironmentStopToContinueMessage}
						  hideAndroidRecordingEnvironmentAlreadyInUseMessage={hideAndroidRecordingEnvironmentAlreadyInUseMessage}
						  showAndroidRecordingEnvironmentAlreadyInUseMessage={props.showAndroidRecordingEnvironmentAlreadyInUseMessage}
						  androidRecordingEnvironmentAlreadyInUseUserFullname={props.androidRecordingEnvironmentAlreadyInUseUserFullname}
						  androidEnvironmentStatus={props.androidEnvironmentStatus}
						  androidEnvironmentStatusError={props.androidEnvironmentStatusError}
						  hidePrepareAndroidRecoringEnvironmentError={hidePrepareAndroidRecoringEnvironmentError}
						  showAndroidLimitMessageAlert={showAndroidLimitMessageAlert} setShowAndroidLimitMessageAlert={setShowAndroidLimitMessageAlert}
						  stoppedByRecorder={stoppedByRecorder}
	/>;

}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		domain: state.domain.domain,
		domainLoading: state.domain.loading,
		// domains: state.domain.domains,
		testError: state.test.error,
		showStopAndroidRecoringEnvironmentButton: state.domain.showStopAndroidRecoringEnvironmentButton,
		androidRecoringEnvironment: state.domain.androidRecoringEnvironment,
		prepareAndroidRecoringEnvironmentLoading: state.domain.prepareAndroidRecoringEnvironmentLoading,
		prepareAndroidRecoringEnvironmentError: state.domain.prepareAndroidRecoringEnvironmentError,
		stopAndroidRecoringEnvironmentLoading: state.domain.stopAndroidRecoringEnvironmentLoading,
		showAndroidRecordingEnvironmentLoadingMessage: state.domain.showAndroidRecordingEnvironmentLoadingMessage,
		showAndroidRecordingEnvironmentStopToContinueMessage: state.domain.showAndroidRecordingEnvironmentStopToContinueMessage,
		androidRecordingEnvironmentStopToContinueDomainLabel: state.domain.androidRecordingEnvironmentStopToContinueDomainLabel,
		showAndroidRecordingEnvironmentAlreadyInUseMessage: state.domain.showAndroidRecordingEnvironmentAlreadyInUseMessage,
		androidRecordingEnvironmentAlreadyInUseUserFullname: state.domain.androidRecordingEnvironmentAlreadyInUseUserFullname,
		androidEnvironmentStatus: state.project.androidEnvironmentStatus,
		requestedAndroidEnvironmentDomainsIdsList: state.domain.requestedAndroidEnvironmentDomainsIdsList,
		androidEnvironmentStatusError: state.project.androidEnvironmentStatusError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadDomain: (id) => dispatch(actions.loadDomain(id)),
		onDeleteTest: (id, redirect) => dispatch(actions.deleteTest(id, redirect)),
		onImportTests: (id, cases) => dispatch(actions.importTests(id, cases)),
		onNullifyTestError: () => dispatch(actions.nullifyTestError()),
		// onLoadDomains: (projectID, config) => dispatch(actions.loadDomains(projectID, config)),
		onPrepareAndroidRecoringEnvironment: (doaminID, projectId) => dispatch(actions.prepareAndroidRecoringEnvironment(doaminID, projectId)),
		onStopAndroidRecoringEnvironment: (projectID) => dispatch(actions.stopAndroidRecoringEnvironment(projectID)),
		onHideAndroidRecordingEnvironmentLoadingMessage: () => dispatch(actions.prepareAndroidRecoringEnvironmentToggleLoadingMessage(false)),
		onShowAndroidRecordingEnvironmentLoadingMessage: () => dispatch(actions.prepareAndroidRecoringEnvironmentToggleLoadingMessage(true)),
		onToggleAndroidRecordingEnvironmentStopToContinueMessage: (show) => dispatch(actions.prepareAndroidRecoringEnvironmentStopToContinue(show)),
		onToggleAndroidRecordingEnvironmentAlreadyInUseMessage: (show) => dispatch(actions.prepareAndroidRecoringEnvironmentAlreadyInUse(show)),
		onCheckAndroidEnvironmentStatus: (projectID, domainID) => dispatch(actions.checkAndroidEnvironmentStatus(projectID, domainID)),
		// onLoadCurrentAndroidRecoringEnvironmentStateOfDomain: (domainID, projectId) =>
		// 	dispatch(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomain(domainID, projectId)),
		onPrepareAndroidRecoringEnvironmentNullifyError: () => dispatch(actions.prepareAndroidRecoringEnvironmentNullifyError()),
		onNullifyAndroidEnvironmentStatusError: () => dispatch(actions.nullifyAndroidEnvironmentStatusError()),
		onLoadDomainsWithTestsOrRequests: (id) => dispatch(actions.loadDomainsWithTestsOrRequests(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Domain);
