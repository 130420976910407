import * as Role from "../../../containers/Users/enum/Roles";

export const AuthenticationTypeObjects = [
	{id: 1, label: 'ADMIN', value: Role.ROLE_ADMIN},
	{id: 2, label: 'MANAGER', value: Role.ROLE_MANAGER},
	{id: 3, label: 'TESTER', value: Role.ROLE_TESTER},
	{id: 4, label: 'USER', value: Role.ROLE_USER}
]

export const AuthenticationTypeValues = [
	Role.ROLE_ADMIN,
	Role.ROLE_MANAGER,
	Role.ROLE_TESTER,
	Role.ROLE_USER
]