import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {connect} from "react-redux";

import * as actions from "../../store/actions";
import AddForm from "../../components/Suite/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";


const UpdateSuite = (props) => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage: null
	});

	const [previousNameAndDescription, setPreviousNameAndDescription] = useState({name: '', description: ''});

	useEffect(() => {
		if (props.suite) {
			const updatedName = {...name};
			updatedName.value = props.suite.label;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = props.suite.description ? props.suite.description : '';
			setDescription(updatedDescription);

			// Save Original Values in case there is an error on update
			setPreviousNameAndDescription({name: props.suite.label, description: props.suite.description ? props.suite.description : ''});

			document.title = intl.formatMessage({
				id: "PAGE.TITLE.UPDATE.SUITE",
				defaultMessage: 'Update Suite: '
			}) + props.suite.label;
		}
	}, [props.suite])

	useEffect(() => {
		if (props.error) {
			const updatedName = {...name};
			updatedName.value = previousNameAndDescription.name;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = previousNameAndDescription.description;
			setDescription(updatedDescription);
		}
	}, [props.error]);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}

		return allValid;
	}

	const submitSuiteHandler = (event) => {
		event.preventDefault();

		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const suite = {
			label: {value: name.value},
			description: {value: description.value}
		};
		const stateSuite = {
			label: name.value,
			description: description.value
		};
		const updatedSuite = {...props.suite};
		updatedSuite.label = name.value;
		updatedSuite.description = description.value;
		props.updateSuite(updatedSuite);
		props.onUpdateSuite(props.suite.id, suite, stateSuite);
	}

	return (
		<AddForm name={name}
				 description={description}
				 nameChangeHandler={nameChangeHandler}
				 descriptionChangeHandler={descriptionChangeHandler}
				 submitSuiteHandler={submitSuiteHandler}
				 error={props.error}
				 {...props}
				 type='update'/>
	);
}

const mapStateToProps = state => {
	return {
		loading: state.suite.updatingLoading,
		error: state.suite.updatingError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateSuite: (id, suite, stateSuite) => dispatch(actions.updateSuite(id, suite, stateSuite)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSuite);
