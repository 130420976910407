import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
import {Button, FormControl, Input, InputLabel, MenuItem, Select, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/styles";

import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";
import * as actions from "../../store/actions";
import DeviceGroupDialogContainerForProject from "../../components/Project/DeviceGroupDialogContainerForProject";
import {history} from "../../../redux/store";

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		// minWidth: 120,
	},
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const IterationDeviceGroupTab = props => {
	const classes = useStyles();
	const intl = useIntl();

	const [group, setGroup] = useState({});

	useEffect(() => {
		if(props.iteration && props.iteration.deviceGroup && props.project?.deviceGroups.length > 0){
			const found = props.project.deviceGroups.find(grp => grp.id === props.iteration.deviceGroup.id);
			if(found !== undefined){
				setGroup(found);
			}
		}
	}, [props.iteration, props.project]);

	// useEffect(() => {
	// 	if(props.iteration && props.iteration.deviceGroup && props.project?.deviceGroups.length > 0){
	// 		const found = props.project.deviceGroups.find(grp => grp.id === props.iteration.deviceGroup.id);
	// 		if(found !== undefined){
	// 			setGroup(found);
	// 		}
	// 	}
	// }, [props.iteration]);

	const selectDeviceGroup = (event) => {
		const group = event.target.value;
		setGroup(group);
		// console.log('selectDeviceGroup', group);

		const iterationPatch = {deviceGroup: {value: group.id}};
		props.onUpdateIteration(props.iteration.id, iterationPatch);
	}

	const [updateIterationSagaCalled, setUpdateIterationSagaCalled] = useState(false);
	useEffect(() => {
		if(props.iteration ){
			if(updateIterationSagaCalled === false && props.updatingLoading === true){
				setUpdateIterationSagaCalled(true);
			}
			if(updateIterationSagaCalled === true && props.updatingLoading === false){
				displayGroupChangedSnackbar();
			}
		}
	}, [props.iteration, updateIterationSagaCalled, props.updatingLoading])

	const deleteDeviceGroup = () => {
		// console.log('Deleting', group);
		props.onDeleteDeviceGroup(group.id, props.iteration.id);
	}

	const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] = useState(false);
	// const [deletionCandidat, setDeletionCandidat] = useState(null);

	const displayDeletionConfirmationDialog = () => {
		setShowDeletionConfirmationDialog(true);
	}

	const hideDeletionConfirmationDialog = () => {
		setShowDeletionConfirmationDialog(false);
		// setDeletionCandidat(null);
	}

	const hideDeletionError = () => {
		props.onNullifyDeletionDeviceGroupError();
	}

	const [showGroupChangedSnackbar, setShowGroupChangedSnackbar] = useState(false);

	const hideGroupChangedSnackbar = () => {
		setShowGroupChangedSnackbar(false);
	}

	const displayGroupChangedSnackbar = () => {
		setShowGroupChangedSnackbar(true);
	}

	return <div className={`bg-white p-2 rounded`} >
		{props.deletionDeviceGroupError && <Alert severity="error" className="mb-3" onClose={() => hideDeletionError()}>
			{props.deletionDeviceGroupError}
		</Alert>}
		<Container>
			<Row>
				<Col className='d-flex justify-content-center'>
					<Button variant = "contained"
							size = 'small'
							startIcon={<KeyboardReturn/>}
							onClick = {(event) => {
								event.preventDefault();
								if(props.sprint){
									history.push('/sprint/' + props.sprint.id, {tab: 1});
								} else {
									history.goBack();
								}
							}}
							className = ''>
						<FormattedMessage id = "BUTTON.BACK" defaultMessage = 'Back'/>
					</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormControl className={`${classes.formControl} w-100 mt-6`} >
						<InputLabel htmlFor="select-device-group">
							<FormattedMessage id="ITERATION.DEVICE.GROUP.SELECT.LABEL" defaultMessage='Device Group'/>
						</InputLabel>
						<Select
							value={group} label={group.name}
							onChange={selectDeviceGroup}
							input={<Input id="select-device-group"/>}>
							{props.project?.deviceGroups.map(device => {
								return <MenuItem key={device.id} value={device}>{device.name}</MenuItem>
							})}
						</Select>
						{/*<FormHelperText>{props.deviceGroup.validationMessage}</FormHelperText>*/}
					</FormControl>
					{Object.keys(group).length > 0 ? group.devices.map(dev =>
						<span key={dev.id} className="label label-lg label-light-primary label-inline m-2 font-weight-bold">{dev.name}</span>) : null}
				</Col>
				<Col className={`align-self-center d-flex justify-content-center ${classes.buttons}`}>
					{/*<DeviceGroupDialogContainerForProject/>*/}
					{/*<DeviceGroupManagementDialog/>*/}
					<DeviceGroupDialogContainerForProject forIteration={true} iterationId={props.iteration.id}/>
					<Button disabled={Object.keys(group).length === 0} variant='contained' color='primary' size='large' onClick={event => {
						event.preventDefault();
						displayDeletionConfirmationDialog();
					}}>
						{intl.formatMessage({ id: 'ITERATION.DEVICE.GROUP.BUTTON.DELETE', defaultMessage: 'Delete Selected Group'})}
					</Button>
				</Col>
			</Row>
		</Container>
		<DeletionConfirmationDialog open={showDeletionConfirmationDialog} closeDialog={hideDeletionConfirmationDialog}
									message={<FormattedMessage id="ITERATION.DEVICE.GROUP.DELETE.MESSAGE"
															   defaultMessage='Remove Device Group'/>}
									name={group ? `${group.name}` : ''}
									deleteAction={() => {
										hideDeletionConfirmationDialog();
										deleteDeviceGroup();
									}}
		/>
		<Snackbar
			anchorOrigin = {{
				vertical: 'top',
				horizontal: 'right',
			}}
			open = {showGroupChangedSnackbar}
			autoHideDuration = {5000}
			onClose = {hideGroupChangedSnackbar}
			message = {intl.formatMessage({ id: "ITERATION.DEVICE.GROUP.CHANGE.SUCCESS", defaultMessage: 'Group device changed successfully' })}
		>
			<Alert onClose = {hideGroupChangedSnackbar} severity = "success">
				{intl.formatMessage({ id: "ITERATION.DEVICE.GROUP.CHANGE.SUCCESS", defaultMessage: 'Group device changed successfully' })}
			</Alert>
		</Snackbar>
	</div>;

}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		deletionDeviceGroupError: state.project.deletionDeviceGroupError,
		updatingLoading: state.iteration.updatingLoading,
		sprint: state.sprint.sprint,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateIteration: (id, iteration) => dispatch(actions.updateIteration(id, iteration)),
		onDeleteDeviceGroup: (id, iterationId) => dispatch(actions.deleteDeviceGroup(id, true, iterationId)),
		onNullifyDeletionDeviceGroupError: () => dispatch(actions.nullifyDeletionDeviceGroupError()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IterationDeviceGroupTab);
