export const FREE = "FREE";
export const BASIC = "BASIC";
export const PROFESSIONAL = "PROFESSIONAL";
export const PREMIUM = "PREMIUM";

export const PlanTypeObjects = [
	{label: 'FREE', value: FREE},
	{label: 'BASIC', value: BASIC},
	{label: 'PROFESSIONAL', value: PROFESSIONAL},
	{label: 'PREMIUM', value: PREMIUM}
];

export const PlanTypeValues = [
	FREE, BASIC, PROFESSIONAL, PREMIUM
];