// Candidate for Global.
import {checkArraySelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";

export const changeText = (name, event, id, intl) => {
	const updatedName = {...name};
	updatedName.value = event.target.value;
	const [valid, message] = checkValidity(updatedName, UINames(id, intl), intl);
	updatedName.valid = valid;
	updatedName.validationMessage = message;
	return updatedName;
}

export const changeDescription = (description, value, id, intl) => {
	const updatedDescription = {...description};
	updatedDescription.value = value;
	const [valid, message] = checkValidity(updatedDescription, UINames(id, intl), intl);
	updatedDescription.valid = valid;
	updatedDescription.validationMessage = message;
	return updatedDescription;
}

export const selectAddTestsAction = (addTests, event, intl) => {
	const updatedAddTests = {...addTests};
	updatedAddTests.selected = event.target.value;
	[updatedAddTests.valid, updatedAddTests.validationMessage] =
		checkArraySelectionValidity(updatedAddTests, 'selected', UINames('test', intl), intl);
	return updatedAddTests;
};