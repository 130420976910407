export const UINames = (name, intl) => {
	switch (name) {
		case 'name':
			return intl.formatMessage({ id: "ITERATION.MESSAGE.NAME",
				defaultMessage: 'Iteration Name'});
		case 'description':
			return intl.formatMessage({ id: "ITERATION.MESSAGE.DESCRIPTION",
				defaultMessage: 'Description'});
		case 'addTests':
			return intl.formatMessage({ id: "ITERATION.MESSAGE.TESTS",
				defaultMessage: 'Tests'});
		case 'suite':
			return intl.formatMessage({ id: "ITERATION.MESSAGE.SUITE",
				defaultMessage: 'Suite'});
		default:
			return '';
	}
}