import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import {selectAddRestRequestsProjectAPIAction} from "./Shared";
import {checkArraySelectionValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import * as actions from "../../store/actions";
import SuiteAddRequestsForm from "../../components/Suite/SuiteAddRequestsForm";

const AddRestRequestsToSuiteForm = props => {
	const intl = useIntl();

	const [addRequests, setAddRequests] = useState({
		selectedRequests: [],		// THIS DIRECTLY REQUESTS, NO DOMAINS INFO
		selectedRequestsNoIndex: [],		// THIS DIRECTLY REQUESTS, NO DOMAINS INFO
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		requests: []   // THIS IS DOMAINS EACH WITH LIST OF REQUESTS. FILTERED
	});

	useEffect(() => {
		if(props.project){
			props.onLoadProjectRestRequests(props.project.id); // TODO ?Remove?
			props.onLoadDomainsWithTestsOrRequests(props.project.id);
		}
	}, [props.project]);

	useEffect(() => {
		if(Array.isArray(props.listDomainsForSuiteAddition)){
			// [{Dname, requests: [ R1, R2, R3, R4]}, {Dname, requests: [ R5, R6, R7, R8]} ]
			// filter
			// [{Dname, requests: [ R1, R2]}, {Dname, requests: [ R5, R6, R8]}]
			// if a domain.requests is empty => remove the domain itself from the list.

			const updatedAddRequests = {...addRequests};
			let allDomainsWithRequests = [...props.listDomainsForSuiteAddition];  // props.restRequests is All Requests from the backend.
			let filteredDomains = [];
			for(let domainWithRequest of allDomainsWithRequests){
				const domainWithRequestCopy = {...domainWithRequest};
				domainWithRequestCopy.restApis = [];
				if(Array.isArray(domainWithRequest.restApis)){
					domainWithRequest.restApis.forEach((request, index) => {
						// for(const toRemoveRequest of props.requests){   // props.requests is the list of addedRequests in the suite.
						// 	if(request.id === toRemoveRequest.id){
						// 		return;
						// 	}
						// }
						domainWithRequestCopy.restApis.push(request);
					});
					if(domainWithRequestCopy.restApis.length > 0){
						filteredDomains.push(domainWithRequestCopy);
					}
				}
			}
			updatedAddRequests.requests = filteredDomains;
			setAddRequests(updatedAddRequests);
		}
	}, [props.listDomainsForSuiteAddition]);

	useEffect(() => {
		if(Array.isArray(props.requests)){
			const selectedRequestsByDefault = [];
			const selectedRequestsByDefaultNoIndex = [];
			for(const selectRequest of props.requests){   // props.requests is the list of addedRequests in the suite.
				// selectedTestsByDefault[selectRequest.testCase.id] = {checked: true, partialChecked: false};
				selectedRequestsByDefault.push(selectRequest);
				const selectRequestCopy = {...selectRequest}
				delete selectRequestCopy.index;
				selectedRequestsByDefaultNoIndex.push(selectRequestCopy);

			}
			const updatedAddRequests = {...addRequests};
			updatedAddRequests.selectedRequests = selectedRequestsByDefault;
			updatedAddRequests.selectedRequestsNoIndex = selectedRequestsByDefaultNoIndex;
			setAddRequests(updatedAddRequests);
		}
	}, [props.requests]);

	const addRequestsSelectHandler = (value) => {
		const updatedAddRequests = selectAddRestRequestsProjectAPIAction(addRequests, value, intl);
		setAddRequests(updatedAddRequests);
	};

	const checkValidityForAll = () => {
		let allValid = true;

		// Tests
		const updatedAddRequests = {...addRequests};
		let [valid, message] = checkArraySelectionValidity(updatedAddRequests, 'selectedRequestsNoIndex',
			UINames('rest-request', intl), intl);
		updatedAddRequests.valid = valid;
		updatedAddRequests.validationMessage = message;
		setAddRequests(updatedAddRequests);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitAddRequestsHandler = () => {

		// validate all
		if (!checkValidityForAll()) {
			return false;
		}

		// let existingRequests = [...props.requests];
		let existingRequests = [];
		let newRequests = [...addRequests.selectedRequestsNoIndex];
		let indexStart = existingRequests.length;
		newRequests = newRequests.map(request => {
			return {...request, index: indexStart++}
		});
		existingRequests.push(...newRequests);
		props.setRequests(existingRequests);
		props.updateRequests(existingRequests);
		clear();
		return true;
	}

	const clear = () => {
		// const updatedAddRequests = {...addRequests};
		// updatedAddRequests.selectedRequests = [];
		// setAddRequests(updatedAddRequests);
		props.onLoadProjectRestRequests(props.project.id); // TODO check this??
	}

	return <SuiteAddRequestsForm addRequests={addRequests}
								 addRequestsSelectHandler={addRequestsSelectHandler}
								 submitAddRequestsHandler={submitAddRequestsHandler}
								 clear={clear}
								 listDomainsForSuiteAddition={props.listDomainsForSuiteAddition}
	/>
}

const mapStateToProps = state => {
	return {
		restRequests: state.restRequest.listProjectRestRequests,
		listDomainsForSuiteAddition: state.domain.listDomainsForSuiteAddition,
		project: state.project.project,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadProjectRestRequests: (id) => dispatch(actions.loadProjectRestRequests(id)),
		onLoadDomainsWithTestsOrRequests: (id) => dispatch(actions.loadDomainsWithTestsOrRequests(id)),

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRestRequestsToSuiteForm);