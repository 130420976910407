import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Alert, AlertTitle} from "@material-ui/lab";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Container, Row} from "react-bootstrap";

import * as actions from "../../store/actions";
import Display from "../../components/Project/Display";
import * as Roles from "../Users/enum/Roles";

const Projects = props => {
	const intl = useIntl();

	useEffect(() => {
		props.onLoadProjects(localStorage.getItem('username'));
		if (localStorage.getItem('Top-Role') === Roles.ROLE_ADMIN ||
			localStorage.getItem('Top-Role') === Roles.ROLE_SUPER ||
			localStorage.getItem('Top-Role') === Roles.ROLE_MANAGER ||
			localStorage.getItem('Top-Role') === Roles.ROLE_TESTER) {
			props.onCanCreateProject();
		}
		document.title = intl.formatMessage({id: "PAGE.TITLE.PROJECTS", defaultMessage: 'Projects'});
	}, []);

	const [deleteRefreshFlag, setDeleteRefreshFlag] = useState(false);

	const deleteProject = (id) => {
		const redirect = null;
		const isPreferredProject = props.loggedInUser.preferedProjectId === id;

		// here this qualifie as null
		// if preferred then: pref must be updated. to:
		// 1: if projects is empty, then null.
		// 2: if projects is not empty, then select first that is not prefe, redirect to /projects
		// not preferred: select first when done
		let newPreferred = null;
		if (props.projects.length > 1) {
			for (let project of props.projects) {
				if (project.id !== id) {
					newPreferred = project;
					break;
				}
			}
		}

		// setting loggedInUser pref
		const loggedInUser = {...props.loggedInUser};
		if (isPreferredProject) {
			if (newPreferred === null) {
				loggedInUser.preferedProjectId = null;
				loggedInUser.preferedProjectLabel = null;
				loggedInUser.preferedProjectType = null;
			} else {
				loggedInUser.preferedProjectId = newPreferred.id;
				loggedInUser.preferedProjectLabel = newPreferred.label;
				loggedInUser.preferedProjectType = newPreferred.type;
			}
		}
		setDeleteRefreshFlag(true);
		props.onDeleteProject(id, redirect, isPreferredProject, newPreferred, loggedInUser);
	}

	useEffect(() => {
		if(deleteRefreshFlag === true && props.loadingOld === false){
			setDeleteRefreshFlag(false);
			if (localStorage.getItem('Top-Role') === Roles.ROLE_ADMIN ||
				localStorage.getItem('Top-Role') === Roles.ROLE_SUPER ||
				localStorage.getItem('Top-Role') === Roles.ROLE_MANAGER ||
				localStorage.getItem('Top-Role') === Roles.ROLE_TESTER) {
				props.onCanCreateProject();
			}
		}
	}, [props.loadingOld])

	const toggleExempt = (project) => {
		props.onToggleExemptProject(project.id, project.exempt);
	}

	let structure = null;
	let newButton = null;
	// Button
	if (localStorage.getItem('Top-Role') !== Roles.ROLE_USER && localStorage.getItem('Top-Role') !== Roles.ROLE_CICD
	&& localStorage.getItem('Top-Role') !== Roles.ROLE_TESTER) {
		newButton = <div className='text-center mx-auto'>
			<Link className="btn btn-primary mb-6" to='/project/new'>
				<FormattedMessage id="PROJECTS.BUTTON.NEW.PROJECT" defaultMessage='New Project'/>
			</Link>
		</div>;

		if (props.canAddLoading === false && props.canAdd === false) {
			newButton = <Alert elevation={2} variant="standard" severity="warning" className='mt-6 mb-5' >
				<AlertTitle>
					<h5>
						<FormattedMessage id="PROJECTS.LIMIT.REACHED.1"
										  defaultMessage="You've Reached the maxixmum amount of projects you can create."/>
					</h5>
				</AlertTitle>

				<h5>
					<FormattedMessage id="PROJECTS.LIMIT.REACHED.2"
									  defaultMessage="Click 'Upgrade To Premium' on the top of the page to lift the restrictions."/>
				</h5>
				{props.canAddError}
			</Alert>;
		}


	}


	if (localStorage.getItem('Top-Role') === Roles.ROLE_SUPER) {
		newButton = <div className='text-center mx-auto'>
			<Link className="btn btn-primary mb-6" to='/project/new'>
				<FormattedMessage id="PROJECTS.BUTTON.NEW.PROJECT" defaultMessage='New Project'/>
			</Link>
		</div>;
	}
	if(props.canAddLoading === true){
		newButton = null;
	}
	// Projects

	let returnPage = false;
	if(props.loading === true){
		structure = null;
		returnPage = true;
	}
	if(!returnPage && props.error !== null){
		structure = <Alert elevation={2} variant="standard" severity="error" className='my-6'>
			{props.error}
			</Alert>;
		returnPage = true;
	}

	// <Alert elevation={2} variant="standard"
	// 	   severity="info" className='my-6'>
	// 	<FormattedMessage id="PROJECTS.LIMIT.REACHED.1121212"
	// 					  defaultMessage="You have No Projects yet, Use the Button Above To Create "/>
	// </Alert>
	if (!returnPage && props.projects.length === 0) {
		if (localStorage.getItem('Top-Role') === Roles.ROLE_ADMIN ||
			localStorage.getItem('Top-Role') === Roles.ROLE_SUPER ||
			localStorage.getItem('Top-Role') === Roles.ROLE_MANAGER) {
			structure = <Alert elevation={2} variant="standard" severity="warning" className='my-6'>
				<FormattedMessage id="PROJECTS.EMPTY.LIST.ADMIN"
								  defaultMessage="You Have Not Created Any Projects Yet, Use The Button Above To Create One."/>
			</Alert>;
		} else {
			structure = <Alert elevation={2} variant="standard" severity="warning" className='my-6'>
				<FormattedMessage id="PROJECTS.EMPTY.LIST.NOT.ADMIN"
								  defaultMessage="Your Admin Have Not Assigned You To A Project Yet, Ask Them To Grant You Access."/>
			</Alert>;
		}
		returnPage = true;
	}
	if(!returnPage && props.projects.length > 0) {

		const projectsDisplay = props.projects.map(project => {
			return <Col key={project.id} xl={6} lg={12} className='mb-6'>
				<Display project={project}
						 delete={deleteProject}
						 disableToggle={toggleExempt}
						 showDescription={false}
					//error={null}
				/>
			</Col>
		});

		structure = <Container className='px-0'>
			<Row >
				{projectsDisplay}
			</Row>
		</Container>;
	}

	// Limit Reached
	// let limitReachedErrorMessage = null;
	// if(props.canAddError){
	// 	limitReachedErrorMessage = <Alert elevation={2} variant="standard" severity="warning" className='my-6'>
	// 		{props.canAddError}
	// 	</Alert>;
	// }
	// console.log('2 structure', structure);
	return <>

		{newButton}

		{/*{limitReachedErrorMessage}*/}
		{structure}
	</>;
}

const mapStateToProps = state => {
	return {
		projects: state.project.projects,
		loading: state.project.projectsLoading,
		loadingOld: state.project.loading,
		// limitReachedLoading: state.project.limitReachedLoading,
		error: state.project.projectsError,
		// limitReachedError: state.project.limitReachedError,
		// limitReached: state.project.limitReached,
		loggedInUser: state.authentication.loggedInUser,
		canAdd: state.project.canAdd,
		canAddLoading: state.project.canAddLoading,
		canAddError: state.project.canAddError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadProjects: (username) => dispatch(actions.loadProjects(username)),
		onCanCreateProject: () => dispatch(actions.canCreateProject()),
		onDeleteProject: (id, redirect, isPreferredProject, newPreferred, loggedInUser) =>
			dispatch(actions.deleteProject(id, redirect, isPreferredProject, newPreferred, loggedInUser)),
		onToggleExemptProject: (id, exempt) => dispatch(actions.toggleExemptProject(id, exempt))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);