import React, {useEffect} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Button, Divider, makeStyles, Snackbar, TextField} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Editor as TinyEditor} from '@tinymce/tinymce-react';
import {Alert} from "@material-ui/lab";
import ReactHtmlParser from "react-html-parser";

import {history} from '../../../redux/store';
import {TestUpdatingLoadingDialog} from "../../UI/LoadingDialog";
import Editor from "for-editor";

const useStyles = () => makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}
}));

const AddForm = props => {
	const intl = useIntl();
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');
	const [severity, setSeverity] = React.useState('');


	const handleUpdate = (message) => {
		setOpen(true);
		setAlertMessage(message);
		if (message === 'Updating Test') {
			setSeverity('info');
		} else {
			setSeverity('success');
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		if (!props.loading && open) {
			handleUpdate(intl.formatMessage({ id: "TEST.SNACKBAR.UPDATED",
				defaultMessage: 'Test Updated'}));
		}
	}, [props.loading]);

	let descriptionValidationMessage = null;
	if (!props.description.valid) {
		descriptionValidationMessage = <Alert elevation = {6} variant = "filled"
		                                      severity = "error" className = 'mt-6 mb-5'>
			{props.description.validationMessage}
		</Alert>
	}
	let headerTitle = intl.formatMessage({ id: "TEST.TITLE.NEW",
		defaultMessage: 'Create New Test'});
	if (props.type === 'update') {
		headerTitle = intl.formatMessage({ id: "TEST.TITLE.UPDATE",
			defaultMessage: 'Update Test'});

	}
	let actions = <Card.Footer>
		{props.type === 'create' && <Button variant = "contained" type = "submit"
		                                    className = 'mr-6'>
			<FormattedMessage id="BUTTON.CREATE"
			                  defaultMessage='Create'/>
		</Button>}
		{props.type === 'update' && <Button variant = "contained" type = "submit"
		                                    className = 'mr-6' id='submit' hidden={true}>
			<FormattedMessage id="BUTTON.UPDATE"
			                  defaultMessage='Update'/>
		</Button>}
		<Button variant = "contained"
		        onClick = {(event) => {
			        event.preventDefault();
			        // means domain/id + state tab = 1
			        if(props.id){
				        history.push('/domain/' + props.id, {tab: 1});
			        } else {
				        history.goBack();
			        }
		        }}>
			{props.type === 'create' && <FormattedMessage id = "BUTTON.CANCEL"
			                                              defaultMessage = 'Cancel'/>
			}
			{props.type === 'update' && <FormattedMessage id = "BUTTON.Back"
			                                              defaultMessage = 'Back'/>
			}
		</Button>
	</Card.Footer>;

	let nameOnBlur = null;
	let descriptionOnBlur = null;
	let nameOnEnterKey = null;

	if(props.type === 'update'){
		nameOnBlur = event => document.getElementById('submit').click();
		descriptionOnBlur = event => document.getElementById('submit').click();
		nameOnEnterKey= (event) => {
			if (event.key === 'Enter' || event.keyCode === 13){
				document.getElementById('submit').click();
			}
		}
	}

	let name = <TextField
		id = "name"
		name = "name"
		label = {intl.formatMessage({ id: "TEST.LABEL.NAME", defaultMessage: 'Test Name' })}
		className = {`${classes.textField}`}
		value = {props.name.value}
		onChange = {event => props.nameChangeHandler(event, 'name')}
		onBlur={nameOnBlur}
		onKeyDown={nameOnEnterKey}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({ id: "TEST.PLACEHOLDER.NAME", defaultMessage: 'Elements' })}
		error = {!props.name.valid}
		helperText = {props.name.validationMessage}
	/>;
	// let description = <><TinyEditor
	// 	value = {props.description.value}
	// 	init = {{
	// 		height: 500,
	// 		menubar: false,
	// 		plugins: [
	// 			'print preview paste autolink autosave save directionality code visualblocks visualchars ' +
	// 			'fullscreen link codesample charmap hr pagebreak nonbreaking toc advlist lists textpattern ' +
	// 			'noneditable charmap quickbars emoticons',
	// 		],
	// 		toolbar:
	// 			'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
	// 			'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
	// 			'forecolor backcolor removeformat | pagebreak | charmap emoticons | ' +
	// 			'fullscreen  preview save print | insertfile image media template link anchor codesample | ' +
	// 			'ltr rtl',
	// 		autosave_interval: '15s',
	// 		autosave_retention: '2m',
	// 		toolbar_mode: 'wrap',
	// 		branding: false,
	// 		statusbar: false,
	// 		setup: ed => {
	// 			ed.on('blur', descriptionOnBlur);
	// 		}
	// 	}}
	// 	onEditorChange = {value => props.descriptionChangeHandler(value)}
	// />
	// 	{descriptionValidationMessage}</>;
	let description = <><Editor value={props.description.value}
								onChange={(value) => {
									props.descriptionChangeHandler(value);

								}}
								onSave={() => {
									if (typeof descriptionOnBlur === 'function') {
										descriptionOnBlur();
									}
								}} language="en" subfield={true} preview={true}
								toolbar={{
									h1: true, // h1
									h2: true, // h2
									h3: true, // h3
									h4: true, // h4
									link: true, // 链接
									code: true, // 代码块
									preview: true,
									expand: true, // 全屏
									undo: true, // 撤销
									redo: true, // 重做
									save: true,
									subfield: true, // 单双栏模式
								}}/>
		{descriptionValidationMessage}</>;
	let cardHeader = <Card.Header className='pt-4 pb-0'> <h3>{headerTitle}</h3> </Card.Header>
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		actions = null;
		name = <h1>{props.name.value}</h1>;
		description = <div className = "mb-6">
			{ ReactHtmlParser(props.description.value) }
		</div>
		cardHeader = null;
	}

	let error = null;
	if (props.error) {
		error = <Row>
			<Col>
				<Alert elevation = {6} variant = "standard"
				       severity = "error" className = 'mt-6 mb-5'>
					{props.error}
				</Alert>
			</Col>
		</Row>
	}

	const submitForm = (event) => {
		props.submitTestHandler(event);
		if(props.type === 'update'){
			handleUpdate(intl.formatMessage({ id: "TEST.SNACKBAR.UPDATING",
				defaultMessage: 'Updating Test' }));
		}
	}

	let view = <>
			{/*updating only, no creation*/}
			<TestUpdatingLoadingDialog/>
			<Divider />
			<Card className = "border-0 rounded-0">
				{cardHeader}
				<form className = {classes.container} noValidate autoComplete = "off"
				      onSubmit = {submitForm}>
					<Card.Body className='py-0'>
						<Container className='px-0'>
							{error}
							<Row>
								<Col>
									{name}
								</Col>
							</Row>
							<Row>
								<Col>
									{description}
								</Col>
							</Row>

						</Container>

					</Card.Body>
					{actions}
				</form>
				<Snackbar
					anchorOrigin = {{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open = {open}
					autoHideDuration = {2000}
					onClose = {handleClose}
					message = {alertMessage}
				>
					<Alert onClose = {handleClose} severity = "success">
						{alertMessage}
					</Alert>
				</Snackbar>
			</Card>
		</>;
	if(props.type === 'create' && !props.id){
		view = <Link to = '/domains'>
			<h3>
				<Alert elevation = {6} variant = "filled"
				       severity = "info" className = 'mt-6 mb-5'>
					<FormattedMessage id="TEST.DOMAIN.NOT.SELECTED"
					                  defaultMessage='Select A Domain First'/>
				</Alert>
			</h3>
		</Link>;
	}

	return view;
}

export default AddForm;