import React from "react";
import {
	AppBar,
	Button,
	Dialog,
	FormControl,
	FormHelperText,
	Input,
	InputLabel,
	MenuItem,
	Select,
	Slide,
	Toolbar,
	Typography
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});


const IterationAddSuitesForm = props => {

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const save = () => {
		if (props.submitAddSuitesHandler()) {
			handleClose();
		}
	}

	return (
		<>
			<Button variant = "contained"
			        size = 'small'
			        startIcon = {<AddIcon/>}
			        onClick = {handleClickOpen}
			        className = ''>
				<FormattedMessage id = "ITERATION.SUITE.FORM.BUTTON.ADD" defaultMessage = 'Suites'/>
			</Button>
			<Dialog fullScreen open = {open} onClose = {handleClose} TransitionComponent = {Transition}>
				<AppBar className = {classes.appBar}>
					<Toolbar>
						<IconButton edge = "start" color = "inherit" onClick = {handleClose} aria-label = "close">
							<CloseIcon/>
						</IconButton>
						<Typography variant = "h6" className = {classes.title}>
							<FormattedMessage id = "ITERATION.SUITE.FORM.TITLE.ADD" defaultMessage = 'Add Suites'/>
						</Typography>
						<Button autoFocus color = "inherit" onClick = {save}>
							<FormattedMessage id="BUTTON.SAVE"
							                  defaultMessage='Save'/>
						</Button>
					</Toolbar>
				</AppBar>
				<div className = "bg-white">
					<Container>
						<Row>
							<Col>
								<FormControl className = {`${classes.formControl} row d-flex mb-6`}
								             error = {!props.addSuites.valid}>
									<InputLabel htmlFor = "select-suites">
										<FormattedMessage id="ITERATION.SUITE.FORM.LABEL.SUITES"
										                  defaultMessage='Suites'/>
									</InputLabel>
									<Select
										value = {props.addSuites.selected}
										multiple
										onChange = {(event) => props.addSuitesSelectHandler(event)}
										input = {<Input id = "select-suites"/>}
									>
										{props.addSuites.suites.map(suite => {
											return <MenuItem key = {suite.id}
											                 value = {suite}>{suite.label}</MenuItem>
										})}
									</Select>
									<FormHelperText>{props.addSuites.validationMessage}</FormHelperText>
								</FormControl>
							</Col>
						</Row>
					</Container>
				</div>
			</Dialog>
		</>
	);
}

export default IterationAddSuitesForm;