import React from "react";
import {AppBar, Button, Dialog, FormControlLabel, Slide, Switch, TextField, Toolbar, Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const SprintAddIterationForm = props => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const save = (event) => {
		event.preventDefault();
		if(props.submitAddIterationHandler()){
			handleClose();
		}
	}

	return (
		<>
			<Button variant = "contained"
			        size = 'small'
			        startIcon = {<AddIcon/>}
			        onClick = {handleClickOpen}
			        className = ''>
				<FormattedMessage id = "SPRINT.ITERATION.FORM.BUTTON.ADD" defaultMessage = 'Custom Iteration'/>
			</Button>
			<Dialog fullScreen open = {open} onClose = {handleClose} TransitionComponent = {Transition}>
				<AppBar className = {classes.appBar}>
					<Toolbar>
						<IconButton edge = "start" color = "inherit" onClick = {handleClose} aria-label = "close">
							<CloseIcon/>
						</IconButton>
						<Typography variant = "h6" className = {classes.title}>
							<FormattedMessage id = "SPRINT.ITERATION.FORM.TITLE.ADD" defaultMessage = 'Add Iteration'/>
						</Typography>
						<Button autoFocus color = "inherit" onClick = {save}>
							<FormattedMessage id="BUTTON.SAVE"
							                  defaultMessage='Save'/>
						</Button>
					</Toolbar>
				</AppBar>
				<div className = "row">
					<div className = "col-md-12 gutter-b">
						<Card>
							<Card.Body>
									<Container>
										<Row>
											<Col>
												<TextField
													id = "label"
													name = "label"
													label = {intl.formatMessage({ id: "SPRINT.ITERATION.FORM.LABEL.NAME",
														defaultMessage: 'Iteration Name'})}
													className = {`${classes.textField}`}
													value = {props.name.value}
													onChange = {event => props.nameChangeHandler(event)}
													margin = "normal"
													variant = "outlined"
													placeholder = {intl.formatMessage({ id: "SPRINT.ITERATION.FORM.PLACEHOLDER.NAME",
														defaultMessage: 'Iteration Name'})}
													error = {!props.name.valid}
													helperText = {props.name.validationMessage}
												/>
											</Col>
										</Row>
										<Row>
											<FormControlLabel
												control = {
													<Switch
														checked = {props.allSuite}
														onChange = {(event, checked) => props.toggleAllSuiteHandler(event, checked)}
														value = "allSuite"
														inputProps = {{'aria-label': 'secondary checkbox'}}
													/>
												}
												label = {intl.formatMessage({ id: "SPRINT.ITERATION.FORM.LABEL.ADD.ALL.SUITES",
													defaultMessage: 'Add All Suites'})}
												className = {`${classes.textField} row d-flex mt-6`}
											/>
										</Row>
									</Container>
							</Card.Body>
						</Card>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default SprintAddIterationForm;