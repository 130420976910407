import React from "react";
import {Card} from "react-bootstrap";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import SVG from "react-inlinesvg";
import {Snackbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {FormattedMessage, useIntl} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const SuiteTestsDisplay = (props) => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const [test, setTest] = React.useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Handling Alert
	const [alertOpen, setAlertOpen] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');

	const handleClickAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlertOpen(false);
	};

	const testNameBodyTemplate = (rowData) => {
		return <h5>{rowData.testCase.label}</h5>;
	}

	const onRowReorder = (e) => {
		let tests = e.value;
		tests = tests.map((test, index) => {
			test.index = index;
			return test;
		});
		props.setTests(tests);
		handleClickAlert(intl.formatMessage({ id: "SUITE.SNACKBAR.TESTS.REORDERED",
			defaultMessage: 'Tests Reordered'}));
		props.updateTests(tests);
	}

	const testActionsBodyTemplate = (rowData) => {

		return (
			<div className = {` ${classes.root}`}>
				<a title = {intl.formatMessage({ id: "SUITE.TESTS.TABLE.DELETE.TEST",
					defaultMessage: 'Delete Test'})}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm "
					// onClick = {() => deleteTest(rowData.id)}
                   onClick = {() => {
	                   setTest(rowData); // to see this {index+testCase}
	                   handleClickOpen();
                   }}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
				        <SVG title={intl.formatMessage({ id: "SUITE.TESTS.TABLE.DELETE.TEST",
					        defaultMessage: 'Delete Test'})}
					        src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</div>
		);
	}

	const columns2 = [
		<Column key = 'label' columnKey = 'label' field = 'label'
		        header = {intl.formatMessage({ id: "SUITE.TESTS.TABLE.COLUMN.NAME",
			        defaultMessage: 'Name'})}
		        body = {testNameBodyTemplate} sortable/>

	];
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		columns2.push(<Column key = 'actions'
		                      header = {intl.formatMessage({ id: "SUITE.TESTS.TABLE.COLUMN.ACTIONS",
			                      defaultMessage: 'Actions'})}
		                      body = {testActionsBodyTemplate}
		                      style = {{width: '25%'}}
		                      className = 'p-text-right'
		/>);
	}

	let display = null;
	display = <Card className="border-0 rounded-0">
		<Card.Body className='p-0'>
			{props.updatingError && <Alert elevation={6} variant="standard" severity="error" className='m-3'>
				{props.updatingError}
			</Alert>}
			<DataTable value={props.tests}
					   paginator className="p-datatable-striped"
					   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					   rows={20} rowsPerPageOptions={[20, 50, 100, 500, 1000]}
					   onRowReorder={onRowReorder}
					   emptyMessage={intl.formatMessage({id: "SUITE.NO.TEST", defaultMessage: 'No Tests Exists For Suite'}) + ' ' +
						   props.suiteName}
			>
				<Column rowReorder style={{width: '3em'}}/>
				{columns2}
			</DataTable>
			<DeletionConfirmationDialog open={open} closeDialog={handleClose}
										message={<FormattedMessage id="SUITE.TESTS.TABLE.DIALOG.REMOVE.TEST"
																   defaultMessage='Remove Test'/>}
										name={test && test.testCase ? `${test.testCase.label}` : ''}
										deleteAction={() => {
											handleClose();
											props.deleteTest(test.index);
										}}
			/>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={alertOpen}
				autoHideDuration={2000}
				onClose={handleCloseAlert}
				message={alertMessage}
			>
				<Alert onClose={handleCloseAlert} severity="success">
					{alertMessage}
				</Alert>
			</Snackbar>
		</Card.Body>
	</Card>;
	return display;
}

export default SuiteTestsDisplay;