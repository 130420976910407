import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	loading: false,
	error: null,
	iterations: null,
	iteration: null,

	// can add
	canAdd: false,
	canAddLoading: false,
	canAddError: null,

	updatingLoading: false,
	updatingError: null,

	launchedIterations: {
		// projID: [id1, id2], projID: [idZ, idK]
	}
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.CREATE_ITERATION_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.CREATE_ITERATION_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				iteration: action.iteration
			});
		case actionTypes.CREATE_ITERATION_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				iteration: null
			});
		case actionTypes.LOAD_ITERATION_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_ITERATION_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				iteration: action.iteration
			});
		case actionTypes.LOAD_ITERATION_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				iteration: null
			});
		case actionTypes.UPDATE_ITERATION_START:
			return updateObject(state, {
				updatingLoading: true,
				updatingError: null,
			});
		case actionTypes.UPDATE_ITERATION_SUCCESS:
			return updateObject(state, {
				updatingLoading: false,
			});
		case actionTypes.UPDATE_ITERATION_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error
			});
		case actionTypes.EXECUTE_ITERATION_START:
			const updatedLaunchedIterations = {...state.launchedIterations}
			if(action.projectId in state.launchedIterations){
				// project already exists
				const ids = [...state.launchedIterations[action.projectId]];
				ids.push(action.id);
				updatedLaunchedIterations[action.projectId] = ids;
			} else {
				// new
				updatedLaunchedIterations[action.projectId] = [action.id];
			}
			return updateObject(state, {
				loading: true,
				launchedIterations: updatedLaunchedIterations
			});
		case actionTypes.EXECUTE_ITERATION_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null
			});
		case actionTypes.EXECUTE_ITERATION_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actionTypes.CAN_CREATE_ITERATION_START:
			return updateObject(state, {
				canAdd: false,
				canAddLoading: true,
				canAddError: null
			});
		case actionTypes.CAN_CREATE_ITERATION_SUCCESS:
			return updateObject(state, {
				canAdd: action.canAdd,
				canAddLoading: false,
				canAddError: null
			});
		case actionTypes.CAN_CREATE_ITERATION_FAIL:
			return updateObject(state, {
				canAddLoading: false,
				canAddError: action.error
			});
		default: {
			return state;
		}
	}
}

export default reducer;