import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	updatingLoading: false,
	updatingError: null,

	loading: false,
	error: null,
	tests: null, // initial null: loading. done: [] empty array
	test: null,
	projectTests: null
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.CREATE_TEST_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.CREATE_TEST_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				test: action.test
			});
		case actionTypes.CREATE_TEST_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				test: null
			});
		case actionTypes.LOAD_TEST_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_TEST_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				test: action.test
			});
		case actionTypes.LOAD_TEST_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				test: null
			});
		case actionTypes.UPDATE_TEST_START:
			return updateObject(state, {
				updatingLoading: true,
				updatingError: null
			});
		case actionTypes.UPDATE_TEST_SUCCESS:
			return updateObject(state, {
				updatingLoading: false
			});
		case actionTypes.UPDATE_TEST_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error,
			});
		case actionTypes.DELETE_TEST_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.DELETE_TEST_SUCCESS:
			if (state.tests && state.tests.length > 0) {
				let testsCopy = [...state.tests];
				testsCopy = testsCopy.filter((test) => {
					if (test.id !== action.id) {
						return true;
					}
				});
				return updateObject(state, {
					loading: false,
					error: null,
					tests: testsCopy
				});
			}
			return updateObject(state, {
				loading: false,
				error: null,
				tests: state.tests
			});

		case actionTypes.DELETE_TEST_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		case actionTypes.LOAD_PROJECT_TESTS_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_PROJECT_TESTS_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				projectTests: action.projectTests
			});
		case actionTypes.LOAD_PROJECT_TESTS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				projectTests: []
			});
		case actionTypes.NULLIFY_TEST_ERROR:
			return updateObject(state, {
				error: null
			});
		case actionTypes.NULLIFY_UPDATING_TEST_ERROR:
			return updateObject(state, {
				updatingError: null
			});
		case actionTypes.MOVE_TEST_TO_DOMAIN_START:
			return updateObject(state, {
				moveTestLoading: true,
				moveTestError: null
			});
		case actionTypes.MOVE_TEST_TO_DOMAIN_SUCCESS:
			return updateObject(state, {
				moveTestLoading: false,
			});
		case actionTypes.MOVE_TEST_TO_DOMAIN_FAIL:
			return updateObject(state, {
				moveTestLoading: false,
				moveTestError: action.error,
			});
		default: {
			return state;
		}
	}
}

export default reducer;