import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {FormattedMessage, useIntl} from "react-intl";
import {Column} from "primereact/column";
import {Avatar, Button, Chip} from "@material-ui/core";
import SVG from "react-inlinesvg";
import {Alert} from "@material-ui/lab";
import ArrowBack from "@material-ui/icons/ArrowBack";

import * as actions from "../../store/actions";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {history} from "../../../redux/store";

const Invoices = props => {

	const intl = useIntl();

	useEffect(() => {
		props.onLoadInvoices(createConfigParametersForBackend());
		document.title = intl.formatMessage({id: "PAGE.TITLE.INVOICES", defaultMessage: 'Invoices'});
	}, []);

	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 50,
	});

	const createConfigParametersForBackend = () => {
		const params = {};
		params.size = lazyParams.rows;
		params.page = lazyParams.first / lazyParams.rows;
		return params;
	}

	const filterTimeout = useRef(0);

	useEffect(() => {
		clearTimeout(filterTimeout.current);
		filterTimeout.current = setTimeout(() => {
			props.onLoadInvoices(createConfigParametersForBackend());
		}, 500);
	}, [lazyParams]);

	const onPage = (event) => {
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const dateBodyTemplate = (rowData) => {
		return <span className="font-size-h5 font-weight-bolder">
			{new Date(rowData.createdDate).toLocaleDateString(navigator.language)}
		</span>;
	}

	const dateColumn = <Column columnKey = 'createdDate' field = 'createdDate'
								header = {intl.formatMessage({id: "INVOICES.TABLE.COLUMN.DATE", defaultMessage: 'Date'})}
								body = {dateBodyTemplate} />;

	const numberBodyTemplate = (rowData) => {
		return <span className="font-size-h5 font-weight-bolder">
			{rowData.number}
		</span>;
	}

	const numberColumn = <Column columnKey='number' field='number'
								 header={intl.formatMessage({id: "INVOICES.TABLE.COLUMN.NUMBER", defaultMessage: 'N°'})}
								 body={numberBodyTemplate}/>;

	const statusBodyTemplate = (rowData) => {

		let color = 'primary';
		if (rowData.payemntFailed === true) {
			color = 'secondary';
		}
		return <Chip label={rowData.status} color={color} avatar={<Avatar>{rowData.status.charAt(0).toUpperCase()}</Avatar>}
					 className='font-size-h6 text-uppercase'/>;
	}

	const statusColumn = <Column columnKey='status' field='status'
								 header={intl.formatMessage({id: "INVOICES.TABLE.COLUMN.STATUS", defaultMessage: 'Status'})}
								 body={statusBodyTemplate}
	/>;

	const amountBodyTemplate = (rowData) => {
		return <span className="text-primary font-size-h5 font-weight-bolder">
			{Number(Math.abs(rowData.amount)).toLocaleString()}
			<sup className="font-weight-normal pl-1">€</sup>
		</span>;
	}

	const amountColumn = <Column columnKey='amount' field='amount'
								 header={intl.formatMessage({id: "INVOICES.TABLE.COLUMN.AMOUNT", defaultMessage: 'Amount'})}
								 body={amountBodyTemplate}
	/>;

	const amountRemainingBodyTemplate = (rowData) => {
		return <span className="text-primary font-size-h5 font-weight-bolder">
			{Number(Math.abs(rowData.amountRemaining)).toLocaleString()}
			<sup className="font-weight-normal pl-1">€</sup>
		</span>;
	}

	const amountRemainingColumn = <Column columnKey='amountRemaining' field='amountRemaining'
										  header={intl.formatMessage({id: "INVOICES.TABLE.COLUMN.REMAINING.AMOUNT",
											  defaultMessage: 'Remaining Amount'})}
										  body={amountRemainingBodyTemplate}
	/>;

	const taxBodyTemplate = (rowData) => {
		return <span className="text-primary font-size-h5 font-weight-bolder">
			{Number(Math.abs(rowData.amountTax)).toLocaleString()}
			<sup className="font-weight-normal pl-1">€</sup>
		</span>;
	}

	const taxColumn = <Column columnKey = 'amountTax' field = 'amountTax'
							  header = {intl.formatMessage({id: "INVOICES.TABLE.COLUMN.TAX", defaultMessage: 'Tax'})}
							  body = {taxBodyTemplate}/>;

	const actionsBodyTemplate = (rowData) => {

		let pay = null;

		if(rowData.payemntFailed === true){

			pay = <a
				title={intl.formatMessage({id: 'INVOICES.TABLE.COLUMN.ACTION.BUTTON.PAY', defaultMessage: 'Pay Invoice'})}
				className="btn btn-light btn-hover-warning btn-md m-2" target='_blank'
				href={rowData.hostedInvoiceUrl}>
				<span className="svg-icon svg-icon-md svg-icon-warning">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}
						 title={intl.formatMessage({id: 'INVOICES.TABLE.COLUMN.ACTION.BUTTON.PAY', defaultMessage: 'Pay Invoice'})}/>
				</span>
				Pay
			</a>;
		}

		const donwload = <a
			title={intl.formatMessage({id: 'INVOICES.TABLE.COLUMN.ACTION.BUTTON.DOWNLOAD', defaultMessage: 'Download Invoice'})}
			className="btn btn-icon btn-light btn-hover-dark btn-md m-2"
			href={rowData.invoicePdf}>
	        <span className="svg-icon svg-icon-md svg-icon-dark">
	          <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
				   title={intl.formatMessage({id: 'INVOICES.TABLE.COLUMN.ACTION.BUTTON.DOWNLOAD', defaultMessage: 'Download Invoice'})}/>
	        </span>
		</a>;

		return <div>
			{donwload}
			{pay}
		</div>;
	}

	const actionsColumn = <Column header = {intl.formatMessage({id: "INVOICES.TABLE.COLUMN.ACTIONS", defaultMessage: 'Actions'})}
		body = {actionsBodyTemplate} className = 'text-right'/>;

	let errorMessage = null;
	if (props.invoicesError) {
		errorMessage = <Alert elevation = {3} variant = "standard" severity = "error" className = 'm-5'>
			{props.invoicesError}
		</Alert>
	}

	return <div className = 'bg-white '>
		<Button variant="contained" className='ml-3 mt-3' startIcon={<ArrowBack/>}
				onClick={(event) => {
					event.preventDefault();
					history.push('/billing');
				}}>
			<FormattedMessage id="BUTTON.BACK" defaultMessage='Back'/>
		</Button>
		<h1 className='text-center'>
			<FormattedMessage id="INVOICES.PAGE.HEADER" defaultMessage='Invoices'/>
		</h1>
		{errorMessage}
		<DataTable value={props.invoices} lazy
			// ref = {props.dt}
				   paginator first={lazyParams.first} rows={lazyParams.rows}
				   totalRecords={props.totalInvoices}
				   autoLayout={true}
				   onPage={onPage}
				   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				   rowsPerPageOptions={[10, 20, 50, 100]}
				   paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
				   emptyMessage={intl.formatMessage({id: "INVOICES.EMPTY.LIST", defaultMessage: 'No Invoice'})}
		>
			{dateColumn}
			{numberColumn}
			{statusColumn}
			{amountColumn}
			{amountRemainingColumn}
			{taxColumn}
			{actionsColumn}
	</DataTable>
	</div>;
}


const mapStateToProps = state => {
	return {
		invoices: state.payment.invoices,
		totalInvoices: state.payment.totalInvoices,
		invoicesLoading: state.payment.invoicesLoading,
		invoicesError: state.payment.invoicesError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadInvoices: (config) => dispatch(actions.loadInvoices(config)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);