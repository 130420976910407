export const UINames = (name, intl) => {
	switch (name) {
		case 'name':
			return intl.formatMessage({ id: "SPRINT.MESSAGE.NAME",
				defaultMessage: 'Sprint Name' });
		case 'description':
			return intl.formatMessage({ id: "SPRINT.MESSAGE.DESCRIPTION",
				defaultMessage: 'Description' });
		case 'iteration':
			return intl.formatMessage({ id: "SPRINT.MESSAGE.ITERATION",
				defaultMessage: 'Iteration Name' });
		default:
			return '';
	}
}