import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, makeStyles, TextField} from "@material-ui/core";
import SVG from "react-inlinesvg";
import AddIcon from '@material-ui/icons/Add';
import ReactTagInput from "@pathofdev/react-tag-input";
import {Alert} from "@material-ui/lab";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
// Import the SCSS file
import "../../scss/react-tag-input/index.scss";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	}
}));
const RestRequestTabHeaders = props => {
	const intl = useIntl();
	const classes = useStyles();
	const [values, setValues] = useState(props.headers);

	useEffect(() => {
		setValues(props.headers);
	}, [props.headers]);

	const [errorMessage, setErrorMessage] = useState('');

	const addHeader = () => {
		const updatedValues = [...values];
		updatedValues.push({key: '', value: []});
		setValues(updatedValues);
		props.setHeaders(updatedValues);
	}

	const deleteHeader = (index) => {
		const updatedValues = [...values];
		updatedValues.splice(index, 1);
		setValues(updatedValues);
		props.setHeaders(updatedValues);
	}

	const updateKeyValue = (value, index, key = true) => {
		// Validation < 2000

		let valueExcedLength = false
		if(!key){
			for(let valueItem of value){
				if(valueItem.length > 2000){
					valueExcedLength = true;
				}
			}
		}

		if((key && value.length > 2000) || (!key && valueExcedLength)){
			setErrorMessage(intl.formatMessage({
				id: "REST.REQUEST.FORM.PARAMETERS.KEYVALUE.LENGTH.VALIDATION.MESSAGE",
				defaultMessage: 'Last Entry is more than 2000 in length'
			}));
		} else {
			const updatedValues = [...values];
			const currentKeyValue = {...updatedValues[index]};
			if (key) {
				currentKeyValue.key = value;
			} else {
				currentKeyValue.value = value;
			}
			updatedValues[index] = currentKeyValue;
			setValues(updatedValues);
			if (!key) {
				props.setHeaders(updatedValues);
			}
			setErrorMessage('');
		}
	}

	const onBlur = () => {
		props.setHeaders(values);
	}

	let keyValueInputSection = values.map((keyValue, index) => {
		return <Row key = {index} className='align-items-center'>
			<Col md = {5} xs = {12}>
				<TextField
					id = {'key' + index} name = {'key' + index}
					label = {intl.formatMessage({
						id: "REST.REQUEST.FORM.HEADERS.KEY.LABEL",
						defaultMessage: 'Key'
					}) + ' ' + (index + 1)}
					className = {`${classes.textField}`}
					value = {keyValue.key}
					onChange = {event => updateKeyValue(event.target.value, index, true)}
					onBlur = {onBlur}
					// onKeyDown={nameOnEnterKey}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({
						id: "REST.REQUEST.FORM.HEADERS.KEY.PLACEHOLDER",
						defaultMessage: 'Key'
					}) + ' ' + (index + 1)}
					// error = {!name.valid}
					// helperText = {name.validationMessage}
				/>
			</Col>
			<Col md = {6} xs = {11}>

				{/*<Tags*/}
				{/*	// tagifyRef={tagifyRef}*/}
				{/*	className = {`${classes.textField} mt-2 p-2`}*/}
				{/*	settings={{*/}
				{/*		maxTags: 20,*/}
				{/*		editTags: {clicks: 1}*/}
				{/*	}}*/}
				{/*	value = {keyValue.value}*/}
				{/*	onChange={event => updateKeyValue(event.target.value, index, false)}*/}
				{/*	// onChange = {event => updateKeyValue2(event)}*/}
				{/*	onBlur = {onBlur}*/}
				{/*/>*/}
				<ReactTagInput
					tags={keyValue.value}
					onChange={newTags => updateKeyValue(newTags, index, false)}
					maxTags={20} editable={true} removeOnBackspace={true}
					placeholder = {intl.formatMessage({
						id: "REST.REQUEST.FORM.HEADERS.VALUE.PLACEHOLDER",
						defaultMessage: 'Value'
					}) + ' ' + (index + 1)}
					className = {`${classes.textField} mt-2 p-2`}
				/>
				{/*<TextField*/}
				{/*	id = {'value' + index} name = {'value' + index}*/}
				{/*	label = {intl.formatMessage({*/}
				{/*		id: "REST.REQUEST.FORM.HEADERS.VALUE.LABEL",*/}
				{/*		defaultMessage: 'Value'*/}
				{/*	}) + ' ' + (index + 1)}*/}
				{/*	className = {`${classes.textField}`}*/}
				{/*	value = {keyValue.value}*/}
				{/*	onChange = {event => updateKeyValue(event.target.value, index, false)}*/}
				{/*	onBlur = {onBlur}*/}
				{/*	// onKeyDown={nameOnEnterKey}*/}
				{/*	margin = "normal"*/}
				{/*	variant = "outlined"*/}
				{/*	placeholder = {intl.formatMessage({*/}
				{/*		id: "REST.REQUEST.FORM.HEADERS.VALUE.PLACEHOLDER",*/}
				{/*		defaultMessage: 'Value'*/}
				{/*	}) + ' ' + (index + 1)}*/}
				{/*	// error = {!name.valid}*/}
				{/*	// helperText = {name.validationMessage}*/}
				{/*/>*/}
			</Col>
			<Col className='mt-3'>
				<a title = {intl.formatMessage({
					id: "REST.REQUEST.FORM.HEADERS.BUTTON.HOVER.DELETE.HEADER",
					defaultMessage: 'Delete Header '}) + (index +1)}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				   onClick = {() => deleteHeader(index)}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
			          <SVG
				          title = {intl.formatMessage({
					          id: "REST.REQUEST.FORM.HEADERS.BUTTON.HOVER.DELETE.HEADER",
					          defaultMessage: 'Delete Header '
				          }) + (index +1)}
				          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</Col>
		</Row>
	});

	let view = <Container className='px-0'>
		<Row className='align-items-center'>
			<Col md='auto'>
				<Button variant = "outlined" className='' startIcon={<AddIcon/>}
				        onClick={addHeader} color='secondary'>
					<FormattedMessage id="REST.REQUEST.FORM.HEADERS.BUTTON.ADD.HEADER"
					                  defaultMessage='Header'/>
				</Button>
			</Col>
			<Col >
				<Alert elevation = {0} variant = "filled" severity = "info" className = 'font-weight-bold'>
					<FormattedMessage id="REST.REQUEST.FORM.HEADERS.ALERT.ALWAYS.PRESS.ENTER"
					                  defaultMessage="When you're done typing a header value you must ALWAYS press enter"/>

				</Alert>
			</Col>
		</Row>
		{errorMessage !== '' && <Alert elevation = {3} variant = "standard"
		                               severity = "error" className = 'my-3'>
			{errorMessage}
		</Alert>}
		{keyValueInputSection}
	</Container>

	return view;
}

export default RestRequestTabHeaders;