import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Col, Container, Dropdown, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
	Button,
	Button as MUIButton,
	Chip,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip, Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Alert} from "@material-ui/lab";
import {FormattedMessage, useIntl} from "react-intl";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {allTimezones} from "react-timezone-select";

import {ProjectLoadingDialog} from "../../UI/LoadingDialog";
import {DropdownCustomToggler} from "../../../_metronic/_partials/dropdowns";
import {getTopRoleObjectForDisplay} from "../Users/Shared";
import * as Role from "../../containers/Users/enum/Roles";
import * as Roles from "../../containers/Users/enum/Roles";
import * as Type from "./enum/ProjectType";
import * as Browser from "./enum/BrowserType";
import {history} from "../../../redux/store";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";
import * as OS from "./enum/OperatingSystem";
import {getLabel} from "./enum/BrowserVersion";
import Editor from "for-editor";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const getUserInitialsColor = role => {
	switch (role) {
		case Role.ROLE_MANAGER:
			return 'success';
		case Role.ROLE_TESTER:
			return 'info';
		case Role.ROLE_USER:
			return 'primary';
		default: return 'success'
	}
}


const getAbsoluteURL = (url) => {
	if (url.startsWith('http') || url.startsWith('https')) {
		return url;
	} else {
		return 'http://' + url;
	}
}

const Display = (props) => {
	const intl = useIntl();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [suggestDefaultDialogOpen, setSuggestDefaultDialogOpen] = useState(props.suggestDefault);

	const handleCloseSuggestDefaultDialog = () => {
		setSuggestDefaultDialogOpen(false);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [openCopiedTooltip, setOpenCopiedTooltip] = useState(false);

	const showCopiedTooltip = () => {
		setOpenCopiedTooltip(true);
	}

	const hideCopiedTooltip = () => {
		setOpenCopiedTooltip(false);
	}

	if(props.error){
		return <Alert elevation={2} variant="standard" severity="error" className='my-6'>
			{props.error}
		</Alert>;
	}

	if (!props.project) {
		return <ProjectLoadingDialog/>;
	}

	let urlBrowserView = null;
	let urlDeviceView = null;
	let apkDeviceView = null;
	let apiView = null;

	const chromeIcon = <i className="fab fa-chrome text-success icon-2x "/>;
	const firefoxIcon = <i className="fab fa-firefox text-warning icon-2x"/>;
	const edgeIcon = <i className="fab fa-edge text-primary icon-2x"/>;
	const windowsIcon = <i className="fab fa-windows text-primary icon-2x "/>;
	const linuxIcon = <i className="fab fa-linux text-warning icon-2x "/>;

	switch (props.project.type) {
		case Type.WEB:
			let browserIcon;
			if(props.project.browser === Browser.CHROME){
				browserIcon = chromeIcon;
			} else if(props.project.browser === Browser.FIREFOX){
				browserIcon = firefoxIcon;
			} else if(props.project.browser === Browser.EDGE){
				browserIcon = edgeIcon;
			}
			let osIcon = linuxIcon;
			if(props.project.platform === OS.WINDOWS){
				osIcon = windowsIcon;
			} else if(props.project.platform === OS.WINDOWS){
				osIcon = linuxIcon;
			}
			urlBrowserView = <Col className='text-center'>
				<a href={getAbsoluteURL(props.project.url)} className="btn btn-light text-hover-dark font-size-lg mr-3" target='_blank'>
					{osIcon}
					{browserIcon}
					{props.project.url}
				</a>
				<Typography variant='h5' >{getLabel(props.project.browserVersion)}</Typography>
			</Col>;

			break;
		case Type.ANDROID_WEB:
			urlDeviceView = <Col className='text-center'>
				<a href={getAbsoluteURL(props.project.url)} className="btn btn-light text-hover-dark font-size-lg mr-3 mb-3" target='_blank'>
					{props.project.url}
				</a>
				<div className="badge badge-light text-hover-dark font-size-lg">
					<i className="fas fa-mobile-alt text-danger icon-2x mr-4"/>
					<span className='d-inline align-text-top'>{props.project.androidDevice}</span>
				</div>
			</Col>;
			break;
		case Type.ANDROID:
			// apkDeviceView = <Col className='text-center'>
			// 	{/*<div className="badge badge-light text-hover-dark font-size-lg mx-3 py-4 px-8 align-top h-100">*/}
			// 	{/*	<div className="d-flex flex-grow-1 align-items-center bg-hover-light p-2 rounded">*/}
			// 	{/*		<div className='mr-4 flex-shrink-0 text-center'>*/}
			// 	{/*			*/}
			// 	{/*		</div>*/}
			// 	{/*	</div>*/}
			// 	{/*</div>*/}
			// 	{/*<div className="badge badge-light text-hover-dark font-size-lg align-top h-100">*/}
			// 	{/*	<div className='d-flex flex-grow-1 align-items-center bg-hover-light p-2 rounded '>*/}
			// 	{/*		<div className='flex-shrink-0 text-center h-100 m-3'>*/}
			// 	{/*			{props.project.apkFileName}*/}
			// 	{/*		</div>*/}
			// 	{/*	</div>*/}
			// 	{/*</div>*/}
			// 	<div className="badge badge-light text-hover-dark font-size-lg">
			// 		<div className="d-flex flex-grow-1 align-items-center bg-hover-light p-2 rounded">
			// 			<div className='mr-4 flex-shrink-0 text-center'>
			// 				<i className="fas fa-mobile-alt text-danger icon-2x "/>
			// 			</div>
			// 			<div className="mr-4">
			// 				<div className="font-weight-bold mb-2">File Name</div>
			// 				<span className="btn btn-sm btn-text btn-light-success text-uppercase font-weight-bold">{props.project.apkFileName}</span>
			// 			</div>
			// 			{/*<span className='mr-4'>{props.project.apkFileName}</span>*/}
			// 			<div className="mr-4">
			// 				<div className="font-weight-bold mb-2">Group Name</div>
			// 				<span className="btn btn-sm btn-text btn-light-success text-uppercase font-weight-bold">{props.project.deviceGroup.name}</span>
			// 			</div>
			// 			{/*<span className='mr-4'>{props.project.deviceGroup.name}</span>*/}
			// 			{props.project.deviceGroup?.devices.map(device => <span key={device.id}
			// 																	className="label label-lg label-light-primary label-inline m-2 font-weight-bold">
			// 				{device.name}
			// 			</span>)}
			//
			// 		</div>
			// 	</div>
			// </Col>
			if(props.showDescription === true){
				apkDeviceView = <Row className='mb-4 text-md-center justify-content-center align-items-center ' >
					<Col md='auto'>
						<div className='bg-gray-100 px-4 py-2 rounded'>

							<i className="fas fa-mobile-alt text-danger icon-2x d-md-inline-block m-2 align-text-bottom min-w-80px"/>

							<div className="mr-4 d-md-inline-block m-2">
								<div className="font-weight-bold mb-2">File Name</div>
								<div className="label label-xl label-inline label-light-success text-uppercase font-weight-bold p-6">
									{props.project.apkFileName}
								</div>
							</div>
							{props.project.deviceGroup && <><div className="mr-4 d-md-inline-block m-2">
								<div className="font-weight-bold mb-2">Group Name</div>
								<div className="label label-xl label-inline label-light-success text-uppercase font-weight-bold p-6">
									{props.project.deviceGroup?.name}
								</div>
							</div>
							<div className='d-md-inline-block m-2 '>
								{props.project.deviceGroup?.devices.map(device => <span key={device.id}
																						className="label label-lg label-light-primary label-inline m-2 font-weight-bold">
								{device.name}
							</span>)}
							</div></>}
						</div>
					</Col>
				</Row>;
			} else {
				apkDeviceView = <Row className='mb-4 text-md-center justify-content-center align-items-center ' >
					<Col md='auto'>
						<div className='bg-gray-100 px-4 py-2 rounded'>
							<i className="fas fa-mobile-alt text-danger icon-2x d-md-inline-block m-2 align-text-bottom min-w-80px"/>
							<div className="mr-4 d-md-inline-block m-2">
								<div className="font-weight-bold mb-2">File Name</div>
								<div className="label label-xl label-inline label-outline-success text-uppercase font-weight-bold p-6">
									{props.project.apkFileName}
								</div>
							</div>
						</div>
					</Col>
				</Row>;
			}


				{/*<Col className='' md='auto'>*/}
				{/*	<i className="fas fa-mobile-alt text-danger icon-2x "/>*/}
				{/*</Col>*/}
				{/*<Col className='' md='auto'>*/}
				{/*	<div className="mr-4">*/}
				{/*		<div className="font-weight-bold mb-2">File Name</div>*/}
				{/*		<span className="btn btn-sm btn-text btn-light-success text-uppercase font-weight-bold">{props.project.apkFileName}</span>*/}
				{/*	</div>*/}
				{/*</Col>*/}
				{/*<Col className='' md='auto'>*/}
				{/*	<div className="mr-4">*/}
				{/*		<div className="font-weight-bold mb-2">Group Name</div>*/}
				{/*		<span className="btn btn-sm btn-text btn-light-success text-uppercase font-weight-bold">{props.project.deviceGroup.name}</span>*/}
				{/*	</div>*/}
				{/*</Col>*/}
				{/*<Col className='' md='auto'>*/}
				{/*	{props.project.deviceGroup?.devices.map(device => <span key={device.id}*/}
				{/*															className="label label-lg label-light-primary label-inline m-2 font-weight-bold">*/}
				{/*		{device.name}*/}
				{/*	</span>)}*/}
				{/*</Col>*/}

			break;
		case Type.API:
			apiView = <Col className='text-center'>
				<a href={getAbsoluteURL(props.project.url)} className = "btn btn-light text-hover-dark font-size-lg" target='_blank'>
					{props.project.url}
				</a>
			</Col>;
			break;
	}

	let suggestDefaultDialog = null;
	if(props.suggestDefault){
		suggestDefaultDialog = <Dialog
			open = {suggestDefaultDialogOpen}
			onClose = {handleCloseSuggestDefaultDialog}
			aria-labelledby = "alert-dialog-title"
			aria-describedby = "alert-dialog-description"
		>
			<DialogTitle id = "alert-dialog-title">
				<FormattedMessage id="PROJECTS.DIALOG.MAKE.PROJECT.DEFAULT"
				                  defaultMessage='Make this the default project'/> : {props.project.label} ?
			</DialogTitle>
			<DialogActions>
				<MUIButton onClick = {handleCloseSuggestDefaultDialog} color = "primary">
					<FormattedMessage id="BUTTON.NO" defaultMessage='No'/>
				</MUIButton>
				<MUIButton onClick = {() => {
					handleCloseSuggestDefaultDialog();
					// props.delete(props.project.id);
					// Prefered
					// const loggedInUser = {...props.loggedInUser};
					// loggedInUser.preferedProjectId = props.project.id;
					// loggedInUser.preferedProjectLabel = props.project.label;
					// loggedInUser.preferedProjectSubscribed = props.project.subscripted;
					// loggedInUser.preferedProjectType = props.project.type;
					// props.onUpdateUser(loggedInUser);
					props.onUpdatePreferred(props.loggedInUser.id, props.project.id);
					// props.onSetProject(props.project);
				}} color = "primary">
					<FormattedMessage id="BUTTON.YES" defaultMessage='Yes'/>
				</MUIButton>
			</DialogActions>
		</Dialog>;
	}

	let removeMenuItem = null;
	if(localStorage.getItem('Top-Role') !== Roles.ROLE_USER && localStorage.getItem('Top-Role') !== Roles.ROLE_CICD
		&& localStorage.getItem('Top-Role') !== Roles.ROLE_TESTER){
		removeMenuItem = <li className = "navi-item">
			<a
				// onClick = {() => props.delete(props.project.id)}
				onClick = {handleClickOpen}
				className = "navi-link">
				<span className = "navi-icon"><i className = "flaticon2-delete"/></span>
				<span className = "navi-text">
					<FormattedMessage id="BUTTON.REMOVE" defaultMessage='Remove'/>
				</span>
			</a>
		</li>
	}

	let viewMenuItem = null;
	if(!props.showDescription){
		viewMenuItem = <li className = "navi-item">
			<Link to = {`/project/${props.project.id}`}
			      className = "navi-link">
				<span className = "navi-icon"><i className = "flaticon2-paperplane"/></span>
				<span className = "navi-text">
					<FormattedMessage id="BUTTON.VIEW" defaultMessage='View'/>
				</span>
			</Link>
		</li>;
	}

	let users = null;

	// props.users contains all users including admin. admin must be removed in the container
	if(props.showDescription === true && Array.isArray(props.users) && Array.isArray(props.project.users)){
		users = props.users.map(user => {
			for(let username of props.project.users){
				if(user.login === username){
					const topRole = getTopRoleObjectForDisplay(user.authorities);
					let firstName = '', lastName = '';
					if(topRole.value === Role.ROLE_CICD){
						firstName = user.login;
						lastName = ' ';
					} else {
						firstName = user.firstName;
						lastName = user.lastName;
					}
					return <Col key={user.login} xl={3} lg={4} md={6} sm={6} className='text-break'>
						<div className={`card card-custom gutter-b card-stretch shadow-none border border-1 border-light-dark`}>
							<div className="card-body p-4">
								<div className="d-flex align-items-center align-middle">
									<div className="flex-shrink-0 mr-4 mt-lg-0">
										<div className={`symbol symbol-lg-50 symbol-circle symbol-light-${getUserInitialsColor(topRole.value)}`}>
											<span className="symbol-label font-size-h4 font-weight-boldest">
												{firstName.charAt(0).toUpperCase() + ' ' + lastName.charAt(0).toUpperCase()}
											</span>
										</div>
									</div>

									<div className="d-flex flex-column">
										<Link to={`/user/${user.login}`} className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
											{firstName + ' ' + lastName}
										</Link>
										<span className={`font-weight-bold text-${getUserInitialsColor(topRole.value)}`}>{topRole.label}</span>

									</div>
								</div>
								{/*<div className="mb-3">*/}
								{/*	<div className="d-flex justify-content-center align-items-center">*/}
								{/*		<span className="text-dark-75 font-weight-bolder mr-2">{user.email}</span>*/}
								{/*		/!*<a href="#" className="text-muted text-hover-primary"></a>*!/*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
						</div>
					</Col>
				}
			}
		});
	}

	const autoExecutionColors = props.project.autoExec ? 'bg-light-success text-success' : 'bg-light-danger text-danger';

	return (
		<div className='bg-white p-4'>
			<Container className=''>
				<Row>
					<Col className='px-1'>
						<div className = "d-flex flex-center ">
							<div className={classes.buttons}>
								<Link to = {`/project/${props.project.id}`}
									  title = {intl.formatMessage({ id: "PROJECTS.DISPLAY.HOVER.VIEW.PROJECT",
										  defaultMessage: 'View Project' })}
									  className = 'font-weight-bolder text-dark-75 text-hover-primary d-inline align-middle'>
									<h1 className='d-inline mr-5'>{props.project.label}</h1>
								</Link>
								<Chip label={props.project.type} color='secondary' />
								<Link to = {`/project/${props.project.id}/edit`}
									  className = "btn btn-sm border-light-dark btn-hover-light-info font-weight-bold py-2 px-3 px-xxl-5 align-items-baseline">
									<i className = "flaticon2-edit"/>
									<FormattedMessage id="BUTTON.EDIT" defaultMessage='Edit'/>
								</Link>
							</div>
						</div>
					</Col>
					{(viewMenuItem !== null || removeMenuItem !== null) && <Col xs='auto'>
						<div className = "d-flex justify-content-end">
							<Dropdown className = "dropdown-inline">
								<Dropdown.Toggle
									id = "dropdown-toggle-top"
									as = {DropdownCustomToggler}
								>
									<i className = "ki ki-bold-more-hor"/>
								</Dropdown.Toggle>
								<Dropdown.Menu className = "dropdown-menu dropdown-menu-sm dropdown-menu-right">
									<ul className = "navi navi-hover">

										{viewMenuItem}

										{removeMenuItem}

									</ul>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</Col> }
				</Row>
				<br/>
				<br/>

				<Row className='mb-4'>
					{urlBrowserView}
					{urlDeviceView}
					{apiView}
				</Row>

				{apkDeviceView}

				<Row className='mb-4'>
					<Col className='text-center'>
						<span className = "font-weight-bold mr-3 mb-3 font-size-h3">
							<FormattedMessage id="PROJECTS.LABEL.AUTOMATIC.EXECUTION" defaultMessage='Automatic Execution'/>
						</span>
						<span className={`${autoExecutionColors} font-weight-bold px-12 py-2 rounded mr-3 mb-3`}>
							{props.project.autoExec ?
								<FormattedMessage id="ON" defaultMessage='ON'/> :
								<FormattedMessage id="OFF" defaultMessage='OFF'/>
							}
						</span>
						{props.project.autoExec && <span
							className={`bg-light-secondary text-secondary font-weight-bold px-12 py-2 rounded`}>
							{
								String(props.project.hourSequence).padStart(2, '0') +
								':' +
								String(props.project.minuteSequence).padStart(2, '0') +
								' | ' +
								(props.project.timeZone === 'Etc/GMT' ? 'UTC' : allTimezones[props.project.timeZone])
							}
						</span>}

					</Col>

				</Row>

				<Row>
					<Col className='text-center'>
						<TextField
							InputProps={{
								startAdornment: <InputAdornment position="start">
									<FormattedMessage id="PROJECTS.LABEL.IDENTIFIER" defaultMessage='Identifier'/>
								</InputAdornment>,
								endAdornment: <Tooltip
									onClose={hideCopiedTooltip}
									open={openCopiedTooltip}
									disableFocusListener
									disableHoverListener
									disableTouchListener
									title="Copied!"
								>
								<InputAdornment position="end">
									<IconButton aria-label="copy Identifier"
												onClick={() => {
													navigator.clipboard.writeText(props.project.identifier)
													showCopiedTooltip();
													setTimeout(() => {
														hideCopiedTooltip();
													}, 2000);
												}}>
										<FileCopyIcon/>
									</IconButton>
								</InputAdornment></Tooltip>,
								readOnly:true,
								className: 'font-size-h3'
							}}
							value={props.project.identifier}
						/>
					</Col>
				</Row>

				{props.showDescription === true &&
					<Row className='my-6'>
						{users}
					</Row>
				}

				{props.showDescription && props.project.description && <Row>
					<Col>
						<div className = "card-header">
							<FormattedMessage id="DESCRIPTION"
											  defaultMessage='Description'/>
						</div>
						<div className = "card-body pt-4" suppressContentEditableWarning={true}>
							<Editor value={props.project.description} language="en" preview={true}/>

						</div>
					</Col>
				</Row>}
			</Container>

			{props.return && <div className='text-center'>
				<Button variant="contained" className='mr-6' startIcon={<ArrowBack/>}
						onClick={(event) => {
							event.preventDefault();
							history.push('/projects');
						}}>
					<FormattedMessage id="BUTTON.BACK" defaultMessage='Back'/>
				</Button>
			</div>}

			<DeletionConfirmationDialog open={open} closeDialog={handleClose}
										message={<FormattedMessage id="PROJECTS.DIALOG.REMOVE.PROJECT" defaultMessage='Remove Project'/>}
										name={props.project ? `${props.project.label}` : ''}
										deleteAction={() => {
											handleClose();
											props.delete(props.project.id);
										}}
			/>
			{suggestDefaultDialog}
		</div>

	);
}

export default Display;