import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {makeStyles, TextField} from "@material-ui/core";
import {useIntl} from "react-intl";

import {changeText} from "./Shared";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	}
}));
const RestRequestTabResponseCollection = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [responsePath, setResponsePath] = useState({
		value: props.responseCollection.responsePath,
		validation: {
			required: false,
			maxLength: 2000
		},
		valid: true,
		validationMessage: null
	});

	const [suiteVariable, setSuiteVariable] = useState({
		value: props.responseCollection.suiteVariable,
		validation: {
			required: false,
			maxLength: 250
		},
		valid: true,
		validationMessage: null
	});

	const mounted = useRef(false);

	useEffect(() => {
		// mounted:
		mounted.current = true;
		// Always check validity: 1) switch from tab to Desc tab. 2) submit
		checkAllInputsValidity();
		return () => mounted.current = false;
	}, []);

	useEffect(() => {
		// console.log('useEffect props.core');
		// Instead of making this check for each one individually. if name is different then
		// the rest is also different
		if(props.responseCollection.responsePath !== responsePath.value ){
			const updatedResponsePath = {...responsePath};
			updatedResponsePath.value = props.responseCollection.responsePath;
			setResponsePath(updatedResponsePath);
		}
		if(props.responseCollection.suiteVariable !== suiteVariable.value ){
			const updatedSuiteVariable = {...suiteVariable};
			updatedSuiteVariable.value = props.responseCollection.suiteVariable;
			setResponsePath(updatedSuiteVariable);
		}
	}, [props.responseCollection]);

	const responsePathChangeHandler = (event) => {
		const updatedResponsePath = changeText(responsePath, event.target.value, 'responsePath', intl);
		setResponsePath(updatedResponsePath);
	}

	const suiteVariableChangeHandler = (event) => {
		const updatedSuiteVariable = changeText(suiteVariable, event.target.value, 'suiteVariable', intl);
		setSuiteVariable(updatedSuiteVariable);
	}

	const saveResponseCollection = () => {
		const responseCollection = {
			responsePath: responsePath.value,
			suiteVariable: suiteVariable.value
		}
		props.setResponseCollection(responseCollection);
	}

	const checkAllInputsValidity = () => {
		let allValid = true;

		const updatedResponsePath = changeText(responsePath, responsePath.value, 'responsePath', intl);
		if(mounted.current === true ) {
			setResponsePath(updatedResponsePath);
		}

		allValid = allValid && updatedResponsePath.valid;

		const updatedSuiteVariable = changeText(suiteVariable, suiteVariable.value, 'suiteVariable', intl);
		if(mounted.current === true ) {
			setSuiteVariable(updatedSuiteVariable);
		}

		allValid = allValid && updatedSuiteVariable.valid;

		return allValid;
	}
	props.registerValidateResponseCollectionFormCallback(checkAllInputsValidity);

	return <Container className='px-0'>
		<Row>
			<Col>
				<TextField
					id = "responsePath"  name = "responsePath"
					label = {intl.formatMessage(
						{ id: "REST.REQUEST.FORM.RESPONSE.COLLECTION.RESPONSE.PATH.LABEL", defaultMessage: 'Response Path'})}
					className = {`${classes.textField}`}
					value = {responsePath.value}
					onChange = {responsePathChangeHandler}
					onBlur={saveResponseCollection}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.RESPONSE.COLLECTION.RESPONSE.PATH.PLACEHOLDER",
						defaultMessage: 'id_token'})}
					error = {!responsePath.valid}
					helperText = {responsePath.validationMessage}
				/>
			</Col>
		</Row>
		<Row>
			<Col>
				<TextField
					id = "suiteVariable"  name = "suiteVariable"
					label = {intl.formatMessage(
						{ id: "REST.REQUEST.FORM.RESPONSE.COLLECTION.SUITE.VARIABLE.LABEL", defaultMessage: 'Suite Variable'})}
					className = {classes.textField}
					value = {suiteVariable.value}
					onChange = {suiteVariableChangeHandler}
					onBlur={saveResponseCollection}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.RESPONSE.COLLECTION.SUITE.VARIABLE.LABEL.PLACEHOLDER",
						defaultMessage: 'token'})}
					error = {!suiteVariable.valid}
					helperText = {suiteVariable.validationMessage}
				/>
			</Col>
		</Row>
	</Container>;


}

export default RestRequestTabResponseCollection;