import React, {useEffect, useState} from "react";
import {highlight, languages} from "prismjs/components/prism-core";
import Editor from "react-simple-code-editor";
import "prismjs/components/prism-json.min";
import "prismjs/themes/prism.css";
import {useIntl} from "react-intl";

const RestRequestTabBody = props => {
	const intl = useIntl();

	const [body, setBody] = useState(props.body);

	useEffect(() => {
		setBody(props.body);
	}, [props.body]);

	// ignore characters after 5000
	const bodyChangeHandler = code => {
		if(code.length < 200_000){
			setBody(code);
		}
	}

	const onBlur = () => {
		props.setBody(body);
	}

	return (
		<Editor
			value={body}
			placeholder={intl.formatMessage({
					id: "REST.REQUEST.FORM.BODY.VALUE.PLACEHOLDER",
					defaultMessage: 'Insert JSON Code Here'
				})}
			onValueChange={(code) => bodyChangeHandler(code)}
			highlight={(code) => highlight(code, languages.json, 'json')}
			padding={10}
			style={{
				fontFamily: '"Fira code", "Fira Mono", monospace',
				fontSize: 12,
			}}
			className='bg-light-o-100'
			onBlur={() => onBlur()}
		/>
	);
}

export default RestRequestTabBody;