import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import {Button, Snackbar} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert} from "@material-ui/lab";

import RestRequestTabAuthentication from "../../components/Rest/RestRequestTabAuthentication";
import {HEADER} from "../../components/Rest/enum/APIKeyType";
import {validateAuthentication} from "../Rest/Validator";
import * as AuthenticationType from "../../components/Rest/enum/AuthenticationType";
import * as actions from "../../store/actions";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const NoAuthWithEmptyValues = {
	authType: AuthenticationType.NOAUTH,
	username: '',
	password: '',
	token: '',
	apiKey: {
		key: '',
		value: '',
		type: HEADER
	}
};

const AddAuthenticationToSuiteForm = props => {
	const intl = useIntl();
	const classes = useStyles();
	const [authentication, setAuthentication] = useState({
		authType: AuthenticationType.NOAUTH,
		username: '',
		password: '',
		token: '',
		tokenVariable: '',
		apiKey: {
			key: '',
			value: '',
			type: HEADER
		}
	});

	const [validate, setValidate] = useState({});

	const validateAuthBasicFormRef = React.useRef();
	const registerValidateAuthBasicFormCallback = callback => {
		validateAuthBasicFormRef.current = callback;
	};

	const validateAuthTokenFormRef = React.useRef();
	const registerValidateAuthTokenFormCallback = callback => {
		validateAuthTokenFormRef.current = callback;
	};

	const validateAuthAPIKeyFormRef = React.useRef();
	const registerValidateAuthAPIKeyFormCallback = callback => {
		validateAuthAPIKeyFormRef.current = callback;
	};

	// Variables List
	const [isTokenVariable, setIsTokenVariable] = useState(false);
	const [restVariables, setRestVariables] = useState([]);
	useEffect(() => {
		if(Array.isArray(props.variables)){
			setRestVariables(props.variables);
		}
	}, [props.variables]);

	useEffect(() => {
		if(props.suite){
			loadAuthentication();
			// load variable list
			props.onLoadVariables(props.suite.id);
		}
	}, [props.suite]);

	// Handle updating/updated here
	const [suiteUpdateSnackbarOpen, setSuiteUpdateSnackbarOpen] = useState(false);
	const [suiteUpdateSnackbarMessage, setSuiteUpdateSnackbarMessage] = useState('');

	useEffect(() => {
		if(props.updatingLoading === true){
			showSuiteSnackbar(intl.formatMessage({ id: "SUITE.SNACKBAR.UPDATING", defaultMessage: 'Updating Suite'}));
		}
		if (!props.updatingLoading && suiteUpdateSnackbarOpen) {
			showSuiteSnackbar(intl.formatMessage({ id: "SUITE.SNACKBAR.UPDATED", defaultMessage: 'Suite Updated'}));
		}
	}, [props.updatingLoading]);

	const showSuiteSnackbar = (message) => {
		setSuiteUpdateSnackbarOpen(true);
		setSuiteUpdateSnackbarMessage(message);
	}

	const hideSuiteSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSuiteUpdateSnackbarOpen(false);
	}

	const loadAuthentication = () => {
		if(props.suite.authorization){
			const authenticationBackendData = props.suite.authorization;
			const authenticationUpdated = {...authentication};
			if(authenticationBackendData.authType){
				authenticationUpdated.authType = authenticationBackendData.authType;
				switch (authenticationBackendData.authType) {
					case AuthenticationType.NOAUTH:
						// do nothing
						break;
					case AuthenticationType.BASIC_AUTH:
						authenticationUpdated.username = authenticationBackendData.username;
						authenticationUpdated.password = authenticationBackendData.password;
						break;
					case AuthenticationType.BEARER_TOKEN:
						let strripedToken = '';
						const backenToken = authenticationBackendData.token;
						if(backenToken && backenToken.startsWith('{{')){
							strripedToken = backenToken.slice(2, backenToken.length - 2);
							authenticationUpdated.tokenVariable = strripedToken;
							setIsTokenVariable(true);
						} else {
							authenticationUpdated.token = backenToken;
						}
						break;
					case AuthenticationType.API_KEY:
						authenticationUpdated.apiKey = authenticationBackendData.apiKey;
						break;
				}
			}
			setAuthentication(authenticationUpdated);
		}
	}

	const saveAuthenticationInfo = () => {
		if(!validateAuthentication(authentication, validateAuthBasicFormRef, () => {},
			setValidate, validateAuthTokenFormRef, validateAuthAPIKeyFormRef)){
			return null;
		}

		let authenticationMechanism = {...NoAuthWithEmptyValues, authType: AuthenticationType.NOAUTH};
		switch (authentication.authType) {
			case AuthenticationType.NOAUTH:
				break;
			case AuthenticationType.INHERIT:
				authenticationMechanism = {...NoAuthWithEmptyValues, authType: AuthenticationType.INHERIT};
				break;
			case AuthenticationType.BASIC_AUTH:
				authenticationMechanism = {
					...NoAuthWithEmptyValues,
					authType: AuthenticationType.BASIC_AUTH,
					username: authentication.username,
					password: authentication.password,
				};
				break;
			case AuthenticationType.BEARER_TOKEN:
				let formattedToken = '';
				if(isTokenVariable === true){
					formattedToken = '{{' + authentication.tokenVariable + '}}';
				} else {
					formattedToken = authentication.token;
				}
				authenticationMechanism = {
					...NoAuthWithEmptyValues,
					authType: AuthenticationType.BEARER_TOKEN,
					token: formattedToken
				};
				break;
			case AuthenticationType.API_KEY:
				authenticationMechanism = {
					...NoAuthWithEmptyValues,
					authType: AuthenticationType.API_KEY,
					apiKey: {
						key: authentication.apiKey.key,
						value: authentication.apiKey.value,
						type: authentication.apiKey.type
					}
				};
				break;
		}
		const suitePatched = {
			authorization: {value: authenticationMechanism},
		};
		const stateSuite = {
			authorization: authenticationMechanism
		};
		const updatedSuite = {...props.suite};
		updatedSuite.authorization = authenticationMechanism;
		props.updateSuite(updatedSuite);
		props.onUpdateSuite(props.suite.id, suitePatched, stateSuite);

	}

	return <div>
		<div className='min-h-lg-200px'>
			{props.updatingError && <Alert elevation={6} variant="standard" severity="error" className='mb-3'>
				{props.updatingError}
			</Alert>}

			<RestRequestTabAuthentication
				authentication={authentication}
				setAuthentication={setAuthentication}
				isVariable={isTokenVariable}
				setIsVariable={setIsTokenVariable}
				registerValidateAuthBasicFormCallback={registerValidateAuthBasicFormCallback}
				validate={validate}
				registerValidateAuthTokenFormCallback={registerValidateAuthTokenFormCallback}
				registerValidateAuthAPIKeyFormCallback={registerValidateAuthAPIKeyFormCallback}
				forSuite={true}
				restVariables={restVariables}
			/>
		</div>
		<div className={classes.buttons + ' mt-10'}>
			<Button variant="contained" onClick={saveAuthenticationInfo} className='' disabled={props.updatingLoading}>
				<FormattedMessage id="BUTTON.SAVE" defaultMessage='Save'/>
			</Button>
		</div>
		<Snackbar
			anchorOrigin = {{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open = {suiteUpdateSnackbarOpen}
			autoHideDuration = {2000}
			onClose = {hideSuiteSnackbar}
			message = {suiteUpdateSnackbarMessage}
		>
			<Alert onClose = {hideSuiteSnackbar} severity = "success">
				{suiteUpdateSnackbarMessage}
			</Alert>
		</Snackbar>
	</div>;
}

const mapStateToProps = state => {
	return {
		updatingLoading: state.suite.updatingLoading,
		updatingError: state.suite.updatingError,
		variables: state.suite.variables
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateSuite: (id, suite, stateSuite) => dispatch(actions.updateSuite(id, suite, stateSuite)),
		onLoadVariables: (suiteID) => dispatch(actions.loadVariables(suiteID)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAuthenticationToSuiteForm);