import React, {useEffect} from "react";
import ApexCharts from "apexcharts";
import {FormattedMessage, useIntl} from "react-intl";

const getChartOptions = (timeUsage, intl) => {
	const options = {
		series: [{name: 'Used', data: timeUsage.used}, {name: 'Remaining', data: timeUsage.remaining}],
		// series: [{name: intl.formatMessage({ id: "DASHBOARD.USED", defaultMessage: 'Used' }), data: [16, 25]},
		// 	{name: intl.formatMessage({ id: "DASHBOARD.REMAINING", defaultMessage: 'Remaining' }), data: [100, 17]}],
		chart: {
			type: 'bar', height: 400, stacked: true,
		},
		// labels: timeUsage.projects,
		xaxis: {
			categories: [intl.formatMessage({ id: "DASHBOARD.WEB", defaultMessage: 'Web' }),
				intl.formatMessage({ id: "DASHBOARD.MOBILE", defaultMessage: 'Mobile' })]
		},
		// labels: ['Web', 'Mobile'],
		legend: {
			position: 'bottom'
		},
		fill: {
			// type: 'gradient',
			opacity: 1
		}
	};
	return options;
}

const ExecutionTimeUsage = props => {
	const intl = useIntl();

	useEffect(() => {
		if(props.executionTimeUsage){
			const element = document.getElementById("execution_time_usage_chart");
			if (!element) {
				return;
			}

			const options = getChartOptions(props.executionTimeUsage, intl);

			const chart = new ApexCharts(element, options);
			chart.render();
			return () => {
				chart.destroy();
			};
		}
	}, [props.executionTimeUsage]);

	return <div className="card card-custom gutter-b card-stretch">
		<div className="card-header border-0 pt-5">
			<div className="card-title">
				<div className="card-label">
					<div className="font-weight-bolder">
						<FormattedMessage id="DASHBOARD.EXEC.TIME.USAGE" defaultMessage='Execution Time Usage'/>
					</div>
					<div className="font-size-sm text-muted mt-2">
						<FormattedMessage id="DASHBOARD.EXEC.TIME.USAGE.SUBHEADER" defaultMessage='Usage in hours'/>
					</div>
				</div>
			</div>

		</div>
		<div className="card-body d-flex flex-column">
			<div className="flex-grow-1">
				<div id="execution_time_usage_chart" style={{height: "400px"}}/>
			</div>
			<p>
				<FormattedMessage id = "DASHBOARD.EXEC.TIME.USAGE.P1"
								  defaultMessage = 'Remaining time is exlusive, meaning you can either use the remaining web or mobile execution time.'/>
			</p>
			<p>
				<FormattedMessage id = "DASHBOARD.EXEC.TIME.USAGE.P2"
								  defaultMessage = 'You can also use both until you reach the limit.'/>
			</p>
		</div>
	</div>;
}

export default ExecutionTimeUsage;