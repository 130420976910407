import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import * as Type from "../../components/Project/enum/ProjectType";
import {ProjectTypeObjects} from "../../components/Project/enum/ProjectType";
import {BrowserTypeObjects} from "../../components/Project/enum/BrowserType";
import {DeviceTypesObjects} from "../../components/Project/enum/DeviceType";
import {validateProjectForm} from "./Validator";
import FormProjectDisplay from "../../components/Project/FormProjectDisplay";
import {OperatingSystemObjects} from "../../components/Project/enum/OperatingSystem";
import {BrowserVersionObjects} from "../../components/Project/enum/BrowserVersion";

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

const UpdateProject = props => {
	const intl = useIntl();

	const [users, setUsers] = useState(false);

	const [label, setLabel] = useState('');
	const [url, setUrl] = useState('');
	// const [password, setPassword] = useState('');
	// const [live, setLive] = useState(false);
	const [autoExec, setAutoExec] = useState(false);
	const [timeZone, setTimeZone] = useState('Etc/GMT');
	const [startTime, setStartTime] = useState(new Date());
	const [projectType, setProjectType] = useState(ProjectTypeObjects[0]);
	const [browser, setBrowser] = useState(BrowserTypeObjects[1]);
	const [device, setDevice] = useState(DeviceTypesObjects[0]);
	const [description, setDescription] = useState('');
	const [apk, setApk] = useState(new Blob());
	const [apkLabel, setApkLabel] = useState('');
	const [apkTouched, setApkTouched] = useState(false);
	const [usersLeft, setUsersLeft] = useState([]);
	const [usersRight, setUsersRight] = useState([]);

	const [id, setId] = useState('' + props.match.params.projectID);
	const [project, setProject] = useState(false);
	const [done, setDone] = useState(false);

	const [operatingSystem, setOperatingSystem] = useState(OperatingSystemObjects[0]);
	const [browserVersion, setBrowserVersion] = useState(BrowserVersionObjects[0]);

	const [deviceGroup, setDeviceGroup] = useState({});
	const [deviceGroups, setDeviceGroups] = useState([]);

	useEffect(() => {
		props.onLoadProject(props.match.params.projectID);
		props.onLoadAllUsers(localStorage.getItem("username"));
	}, []);

	useEffect(() => {
		// if props.project.id === id is for when proj 1 is already populated
		// when trying to load proj 2
		if (props.project && !project && props.project.id === id) {
			loadValues();
			setProject(true);

			document.title = intl.formatMessage({id: "PAGE.TITLE.UPDATE.PROJECT", defaultMessage: 'Update Project: '})
				+ props.project.label;
		}

		// Load Users
		if(Array.isArray(props.users)){
			if (props.users.length > 0 && props.project && props.project.id === id) {
				assignUsers();
			}
		}

	}, [props.project, props.users]);

	const assignUsers = () => {
		// console.log('assignUsers props.users props.project', props.users, props.project);
		const rightUsers = [];
		if(Array.isArray(props.project.users) && props.project.users.length > 0){
			props.project.users.forEach(user => {
				if (user === 'sup_tig') {
					return;
				}
				if (user !== localStorage.getItem("username")) {
					rightUsers.push(user);
				}
			});
		}

		setUsersRight(rightUsers);

		const usersCurrentremoved = [];
		if(Array.isArray(props.users)){
			props.users.map(user => {
				// no need to remove SUPER because it it's never in the list
				if (user.login !== localStorage.getItem("username")) {
					usersCurrentremoved.push(user.login);
				}
			});
		}
		let leftUsers = [];
		// console.log('usersCurrentremoved', usersCurrentremoved);
		leftUsers = not(usersCurrentremoved, Array.isArray(props.project.users) ? props.project.users : []);
		// console.log('leftUsers', leftUsers);
		// if loggin user is super => empty left
		if (localStorage.getItem('Top-Role') === 'ROLE_SUPER') {
			leftUsers = [];
		}
		setUsersLeft(leftUsers);
		setUsers(true);
	}

	const loadValues = () => {
		setLabel(props.project.label);
		// Type
		for (let type of ProjectTypeObjects) {
			if (props.project.type === type.value) {
				setProjectType(type);
				break;
			}
		}
		setUrl(props.project.url);
		// Browser
		for (let browser of BrowserTypeObjects) {
			if (props.project.browser === browser.value) {
				setBrowser(browser);
				break;
			}
		}
		// Devise
		for (let device of DeviceTypesObjects) {
			if (props.project.androidDevice === device.value) {
				setDevice(device);
				break;
			}
		}
		// setLive(props.project.enableLive);
		// setPassword(props.project.passLive ? props.project.passLive : '');
		setAutoExec(props.project.autoExec !== undefined ? props.project.autoExec : false);
		if(props.project.autoExec === true){
			setStartTime(new Date().setHours(props.project.hourSequence, props.project.minuteSequence));
			setTimeZone(props.project.timeZone);
		} else {
			setStartTime(new Date());
			// setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}

		setDescription(props.project.description ? props.project.description : '');
		setApkLabel(props.project.apkFileName);

		for (let os of OperatingSystemObjects) {
			if (props.project.platform === os.value) {
				setOperatingSystem(os);
				break;
			}
		}
		for (let version of BrowserVersionObjects) {
			if (props.project.browserVersion === version.value) {
				setBrowserVersion(version);
				break;
			}
		}
		if (props.project.deviceGroup && Array.isArray(props.deviceGroups) && props.deviceGroups.length > 0){
			for (let group of props.deviceGroups) {
				if (props.project.deviceGroup.id === group.id) {
					setDeviceGroup(group);
					break;
				}
			}
		}
		setDeviceGroups(props.deviceGroups);
	}

	const [errorMessages, setErrorMessages] = useState([]);

	useEffect(() => {
		const updatedGlobalMessages = [...errorMessages];
		if (props.updatingError) {
			updatedGlobalMessages.push(props.updatingError);
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.updatingError]);

	const validateFormRef = useRef();
	const registerValidateFormCallback = callback => {
		validateFormRef.current = callback;
	};

	const updateProject = (event) => {
		event.preventDefault();

		// validate all
		if (!validateProjectForm(validateFormRef)) {
			return null;
		}
		let autoExecObject = {autoExec: {value: autoExec}};
		if (autoExec) {
			autoExecObject = {
				autoExec: {value: autoExec},
				timeZone: {value: timeZone},
				hourSequence: {value: new Date(startTime).getHours()},
				minuteSequence: {value: new Date(startTime).getMinutes()},
			}
		}
		if (projectType.value === Type.WEB) {
			const project = {
				label: {value: label},
				type: {value: projectType.value},
				url: {value: url},
				browser: {value: browser.value},
				platform: {value: operatingSystem.value},
				browserVersion: {value: browserVersion.value},
				...autoExecObject,
				description: {value: description},
				users: {value: usersRight}
			}
			props.onUpdateProject(id, project, id === props.preferredProject.id);
		} else if (projectType.value === Type.ANDROID_WEB) {
			const project = {
				label: {value: label},
				type: {value: projectType.value},
				url: {value: url},
				deviceGroup: {value: deviceGroup.id},
				// enableLive: {value: live},
				// passLive: {value: password},
				...autoExecObject,
				description: {value: description},
				users: {value: usersRight}
			}
			props.onUpdateProject(id, project, id === props.preferredProject.id);
		}
		else if (projectType.value === Type.ANDROID) {
			// Must send two request: one for field patch, another for file. same saga
			const projectInfo = {
				label: {value: label},
				// androidDevice: {value: device.value},
				// enableLive: {value: live},
				// passLive: {value: password},
				...autoExecObject,
				description: {value: description},
				users: {value: usersRight},
				deviceGroup: {value: deviceGroup.id}
			}

			// let projectApkFile = null; // saga if null doesn't update
			// // console.log('APK Size is:', apk.size);
			// if (apkTouched) {
			// 	projectApkFile = new FormData();
			// 	// projectApkFile.append("file", apk, apkLabel);
			// 	projectApkFile.append("fileName", apkLabel);
			// 	projectApkFile.append("projectId", id);
			// }
			props.onUpdateAndroidProject(id, projectInfo, apkTouched, apkLabel, apk, id === props.preferredProject.id);
		} else if (projectType.value === Type.API) {
			const project = {
				label: {value: label},
				type: {value: projectType.value},
				url: {value: url},
				...autoExecObject,
				description: {value: description},
				users: {value: usersRight}
			}
			props.onUpdateProject(id, project, id === props.preferredProject.id);
		} else {
			// ios do nothing
		}
	}

	const updatePreferred = () => {
		props.onUpdatePreferred(props.loggedInUser.id, props.project.id);
	}

	const suggestDefault = new URLSearchParams(props.location.search).get('suggestDefault') === "true";

	useEffect(() => {
		// console.log('Effect 1', props.deviceGroups, props.createdDeviceGroup, deviceGroup);
		if((projectType.value === Type.ANDROID || projectType.value === Type.ANDROID_WEB ) && props.project?.deviceGroup){
			if(Array.isArray(props.deviceGroups) && project === true){
		// 		console.log('Effect 1 IF ONE',);
		// 		// creation
		// 		if(props.createdDeviceGroup !== null){
		// 			console.log('Effect 1 IF TWO',);
		// 			setDeviceGroup(props.createdDeviceGroup);
		// 			// set to null immediately
		// 			props.onNullifyCreatedDeviceGroup();
		// 		} else if (deviceGroup && props.project.deviceGroup.id !== deviceGroup.id) {
		// 			console.log('Effect 1 IF THREE',);
		// 			// delete & update
		// 			const found = props.deviceGroups.find(group => group.id === deviceGroup.id);
		// 			setDeviceGroup(found ? found : props.deviceGroups.length > 0 ? props.deviceGroups[0] : {});
		// 			// note after the first group is create, groups must have atleast one group.
		// 		}
				setDeviceGroups(props.deviceGroups);
				// dg
				setDeviceGroup(props.deviceGroups.find(group => props.project.deviceGroup.id === group.id));

			}
		}
	}, [ props.deviceGroups, project, projectType, props.project
		// , props.createdDeviceGroup, deviceGroup
	])

	return <FormProjectDisplay type = 'update'
	                           updateProject = {updateProject}
	                           errorMessages = {errorMessages}
	                           label = {label} setLabel = {setLabel}
	                           url = {url} setUrl = {setUrl}
	                           // password = {password} setPassword = {setPassword}
	                           // live = {live} setLive = {setLive}
	                           autoExec = {autoExec} setAutoExec = {setAutoExec}
							   timeZone={timeZone} setTimeZone={setTimeZone}
	                           startTime = {startTime} setStartTime = {setStartTime}
	                           projectType = {projectType} setProjectType = {setProjectType}
	                           browser = {browser} setBrowser = {setBrowser}
	                           device = {device} setDevice = {setDevice}
	                           description = {description} setDescription = {setDescription}
	                           apk = {apk} setApk = {setApk}
	                           apkTouched = {apkTouched} setApkTouched = {setApkTouched}
	                           apkLabel = {apkLabel} setApkLabel = {setApkLabel}
	                           registerValidateFormCallback = {registerValidateFormCallback}

	                           usersLeft = {usersLeft} setUsersLeft = {setUsersLeft}
	                           usersRight = {usersRight} setUsersRight = {setUsersRight}

	                           updatingLoading = {props.updatingLoading}

	                           allUsers = {props.users}

							   operatingSystem={operatingSystem} setOperatingSystem={setOperatingSystem}
							   browserVersion={browserVersion} setBrowserVersion={setBrowserVersion}

							   suggestDefault={suggestDefault}
							   onUpdatePreferred={updatePreferred}

							   deviceGroup={deviceGroup} setDeviceGroup={setDeviceGroup}
							   deviceGroups={deviceGroups} setDeviceGroups={setDeviceGroups}
	/>;
}

const mapStateToProps = state => {
	return {
		project: state.project.projectView,
		preferredProject: state.project.project,
		updatingError: state.project.updatingError,
		updatingLoading: state.project.updatingLoading,
		users: state.users.allUsers,
		loggedInUser: state.authentication.loggedInUser,
		// createdDeviceGroup: state.project.createdDeviceGroup,
		deviceGroups: state.project.deviceGroups,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadProject: (id) => dispatch(actions.loadProject(id, true)),
		onUpdateProject: (id, project, preferredProject) => dispatch(actions.updateProject(id, project, preferredProject)),
		onLoadAllUsers: (username) => dispatch(actions.loadAllUsers(username)),
		onUpdateAndroidProject: (id, projectInfo, isNewAPK, apkLabel, apk, isPreferredProject) =>
			dispatch(actions.updateProjectAndroid(id, projectInfo, isNewAPK, apkLabel, apk, isPreferredProject)),
		onUpdatePreferred: (userId, preferredId) => dispatch(actions.updatePreferredProject(userId, preferredId)),
		onNullifyCreatedDeviceGroup: () => dispatch(actions.nullifyCreatedDeviceGroup()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject);
