import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Alert} from "@material-ui/lab";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import SVG from "react-inlinesvg";
import {FormattedMessage, useIntl} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {DomainsLoadingDialog} from "../../UI/LoadingDialog";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const DomainsDisplay = (props) => {
	const [open, setOpen] = useState(false);
	const [domain, setDomain] = useState(null);

	const intl = useIntl();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const domainNameBodyTemplate = (rowData) => {
		let select = 'text-secondary';
		if (props.domainID === rowData.id) {
			select = 'text-success';
		}
		return <Link to = {`/domain/${rowData.id}`}
		             title = {intl.formatMessage({
			             id: "DOMAINS.TABLE.HOVER.LINK.VIEW.DOMAIN",
			             defaultMessage: 'View Domain'
		             })} className = {`h4 ${select}`}>
			{rowData.label}
		</Link>;
	}

	const domainActionsBodyTemplate = (rowData) => {

		return (
			<a title = {intl.formatMessage({
				id: "DOMAINS.TABLE.HOVER.LINK.DELETE.DOMAIN",
				defaultMessage: 'Delete Domain'
			})}
			   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				// onClick = {() => props.delete(rowData.id)}
               onClick = {() => {
	               setDomain(rowData);
	               handleClickOpen();
               }}
			>
		        <span className = "svg-icon svg-icon-md svg-icon-danger">
		          <SVG src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
		        </span>
			</a>
		);
	}


	const labelColumn = <Column key = 'label' columnKey = 'label' field = 'label'
		        header = {intl.formatMessage({
			        id: "DOMAINS.TABLE.COLUMN.HEADER.NAME", defaultMessage: 'Name'
		        })}
		        body = {domainNameBodyTemplate} sortable
		        filter filterPlaceholder = {intl.formatMessage({
					id: 'DOMAINS.TABLE.COLUMN.SEARCH.BY.NAME',
					defaultMessage: 'Search by name'
				})}
		        filterMatchMode = 'contains'/>;
	let actionsColumn = null
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		actionsColumn = <Column key = 'actions'
		                      header = {intl.formatMessage({
			                      id: "DOMAINS.TABLE.COLUMN.HEADER.ACTIONS", defaultMessage: 'Actions'
		                      })}
		                      body = {domainActionsBodyTemplate}
		                      className = 'p-text-right'/>;
	}

	// Assumption that props.project is always populated is always true, because
	// when domains is loaded, ProjectReducer project & projects are populated before domain

	let actions =
		<div className = 'text-center'>
			<Link className = "btn btn-primary mb-2 mt-2"
			      to = '/domain/new'>
				<FormattedMessage id = "DOMAINS.BUTTON.NEW"
				                  defaultMessage = "New Domain"/>
			</Link>
		</div>
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		actions = null;

	}

	let errorMessage = null;
	if (props.error) {
		errorMessage = <Alert elevation = {6} variant = "filled"
		                      severity = "error" className = 'mt-6 mb-5'>
			{props.error}
		</Alert>
	}

	let generalErrorMessage = null;
	const [hideGeneralErrorMessage, setHideGeneralErrorMessage] = useState(false);
	if (props.generalError) {
		generalErrorMessage = <Alert elevation = {6} variant = "standard" severity = "error" className = 'my-3'
									 onClose={() => setHideGeneralErrorMessage(true)}
									 hidden={hideGeneralErrorMessage}>
			{props.generalError}
		</Alert>
	}
	let display = <div className = 'bg-white'>
			<DomainsLoadingDialog/>
			{actions}
			{errorMessage}
			{generalErrorMessage}
			<DataTable value = {props.domains} lazy ref = {props.dt}
			           paginator first = {props.lazyParams.first} rows = {props.lazyParams.rows}
			           totalRecords = {props.totalDomains}
			           onPage = {props.onPage} onSort = {props.onSort}
			           sortField = {props.lazyParams.sortField} sortOrder = {props.lazyParams.sortOrder}
			           onFilter = {props.onFilter}
			           filters = {props.lazyParams.filters}

			           paginatorTemplate =
				           "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
			           currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
			           rowsPerPageOptions = {[10, 20, 50, 100]}
			           emptyMessage = {intl.formatMessage({
				           id: "DOMAINS.EMPTY.LIST",
				           defaultMessage: 'No Domains'
			           })}
			>
				{labelColumn}
				{actionsColumn}
			</DataTable>

		<DeletionConfirmationDialog open={open} closeDialog={handleClose}
									message={<FormattedMessage id = "DOMAINS.DIALOG.REMOVE.DOMAIN" defaultMessage = 'Remove Domain'/> }
									name={domain ? `${domain.label}` : ''}
									deleteAction={() => {
										handleClose();
										setHideGeneralErrorMessage(false);
										props.delete(domain.id);
									}}
		/>
		</div>;

	return display;
}

export default DomainsDisplay;