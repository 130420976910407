import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {reduxBatch} from "@manaflair/redux-batch";
import {persistStore} from "redux-persist";

import {rootReducer, rootSaga} from "./rootReducer";
import {
	watchAuthentication,
	watchDomain,
	watchProject, watchSprint,
	watchSuite,
	watchTest,
	watchUsers,
	watchIteration, watchReport, watchPayment, watchSummaryChart, watchRestRequest
} from "../tigmat/store/sagas/rootSaga";

export const history = createBrowserHistory(
	// { basename: '/metro' }
);

history.listen((location) => {
	// window.gtag('config', 'UA-1234567-89', {'page_path': '/' + location.pathname + location.search});
	// window.ga('set', 'page', location.pathname + location.search);
	// window.ga('send', 'pageview');
	// console.log('Saving Info for page:', location.pathname + location.search);
	// window.gtag('config', 'G-H767TZPDKL', {send_page_view: false});
	// console.log('location object:', location);
});

const sagaMiddleware = createSagaMiddleware();
const middleware = [
	...getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
		thunk: true
	}),
	sagaMiddleware,
	routerMiddleware(history)
];

const store = configureStore({
	reducer: rootReducer(history),
	middleware,
	devTools: process.env.NODE_ENV !== "production",
	enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(watchAuthentication);
sagaMiddleware.run(watchUsers);
sagaMiddleware.run(watchProject);
sagaMiddleware.run(watchDomain);
sagaMiddleware.run(watchTest);
sagaMiddleware.run(watchSuite);
sagaMiddleware.run(watchSprint);
sagaMiddleware.run(watchIteration);
sagaMiddleware.run(watchReport);
sagaMiddleware.run(watchPayment);
sagaMiddleware.run(watchSummaryChart);
sagaMiddleware.run(watchRestRequest);
sagaMiddleware.run(rootSaga);

export default store;
