import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import AddForm from "../../components/Test/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";

const NewTest = (props) => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage:
			null
	});

	useEffect(() => {
		document.title = intl.formatMessage({ id: "PAGE.TITLE.NEW.TEST",
			defaultMessage: 'New Test' });
	}, []);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}

		return allValid;
	}

	const submitTestHandler = (event) => {
		event.preventDefault();

		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const test = {
			label: name.value,
			description: description.value,
			domainId: props.domain.id
		};
		props.onCreateTest(test, props.domain.id);

	}

	const domainID = props.domain ? props.domain.id : null;

	return (
		<AddForm name = {name}
		         description = {description}
		         id ={domainID}
		         nameChangeHandler = {nameChangeHandler}
		         descriptionChangeHandler = {descriptionChangeHandler}
		         submitTestHandler = {submitTestHandler}
		         error={props.error}
		         type = 'create'/>
	)
}

const mapStateToProps = state => {
	return {
		domain: state.domain.domain,
		error: state.test.error,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateTest: (test, domainID) => dispatch(actions.createTest(test, domainID)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTest);
