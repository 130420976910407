import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
	Button as MUIButton,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Input,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Switch,
	TextField,
	Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ImportExport from "@material-ui/icons/ImportExport";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Col, Container, Row} from "react-bootstrap";
import {Editor as TinyEditor} from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import TimezoneSelect, {allTimezones} from 'react-timezone-select'
import {makeStyles} from "@material-ui/styles";
import Editor from 'for-editor';

import {getTopRoleObjectForDisplay} from "../Users/Shared";
import * as Type from "./enum/ProjectType";
import DeviceGroupDialogContainerForProject from "./DeviceGroupDialogContainerForProject";
import DeviceGroupManagementDialog from "./DeviceGroupManagementDialog";
import * as Role from "../../containers/Users/enum/Roles";

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	dense: {
		marginTop: theme.spacing(2),
	},
	menu: {
		width: 200,
	},
	root: {
		margin: 'auto',
	},
	paper: {
		width: 200,
		height: 230,
		overflow: 'auto',
	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const useStylesTransfer = makeStyles(theme => ({
	root: {
		marginBottom: 'auto',
	},
	paper: {
		width: 'auto',
		height: 230,
		overflow: 'auto',

	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
}));

const CreationProjectFormJSX = props => {
	const intl = useIntl();
	const classes = useStyles();
	const classesTransfer = useStylesTransfer();

	// const [showPassword, setShowPassword] = useState(false);

	let titleHeader = props.type === 'create' ?
		intl.formatMessage({ id: "PROJECT.TITLE.CREATE.NEW", defaultMessage: 'New Project' }) :
		intl.formatMessage({ id: "PROJECT.TITLE.UPDATE.PROJECT", defaultMessage: 'Update Project ' })
		+ props.label.value;

	const roleLabel = (username) => {
		for(let user of props.allUsers){
			if(user.login === username){
				return <Chip label= {getTopRoleObjectForDisplay(user.authorities).label} color='primary' className='float-right'/>;
			}
		}
	}

	const customList = (items) => {

		if(props.allUsers === null){
			return null;
		}

		if(items.length === 0){
			return <Alert elevation = {6} variant = "standard" style={{height: 230} }
			              severity = "info" className = ''>
				<FormattedMessage id = "PROJECT.ACCESS.NO.AVAILABLE.USERS" defaultMessage = 'No Available Users'/>
			</Alert>;
		}

		return (
			<Paper className = {classesTransfer.paper} elevation={14}>
				<List dense component = "div" role = "list" >
					{ items.map((value) => {
						const labelId = `transfer-list-item-${value}-label`;
						const foundUser = props.allUsers.find(user => user.login === value);
						const fullname = foundUser.firstName + ' ' + foundUser.lastName + ` ( ${value})`;
						return (
							<ListItem key = {value} role = "listitem" button
							          onClick = {() => props.handleToggle(value)}
							          >
								<ListItemIcon>
									<Checkbox
										checked = {props.usersSelected.indexOf(value) !== -1}
										tabIndex = {-1}
										disableRipple
										inputProps = {{'aria-labelledby': labelId}}
									/>
								</ListItemIcon>
								<ListItemText id = {labelId} className='align-middle'
								              primary = {<span
									              className='mr-6 d-inline-block mt-2'>{fullname}</span>}
								              secondary={roleLabel(value)}
								              disableTypography={true}
								/>
							</ListItem>
						);


					})}
					<ListItem/>
				</List>
			</Paper>
		);
	};

	let descriptionValidationMessage = null;
	if (!props.description.valid) {
		descriptionValidationMessage = <Alert elevation = {6} variant = "filled"
		                                      severity = "error" className = 'mt-6 mb-5'>
			{props.description.validationMessage}
		</Alert>
	}

	const labelInput = <TextField
		id = "label"
		name = "label"
		label = {intl.formatMessage({id: "PROJECT.LABEL.NAME", defaultMessage: 'Project Name'})}
		className = {`${classes.textField}`}
		value = {props.label.value}
		onChange = {props.labelChangeHandler}
		onBlur={() => props.syncValueWithParentContainer('label')}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({
			id: "PROJECT.PLACEHOLDER.NAME",
			defaultMessage: 'Quiz Application'
		})}
		error = {!props.label.valid}
		helperText = {props.label.validationMessage}
		disabled={props.creationLoading}
	/>;

	const projectTypeInput = <FormControl className = {`${classes.formControl} row d-flex mt-6`}
	                                      error = {!props.projectType.valid}
	                                      disabled = {props.type === 'update' || props.creationLoading}>
		<InputLabel htmlFor = "select-type">
			<FormattedMessage id = "PROJECT.LABEL.TYPE"
			                  defaultMessage = 'Type'/></InputLabel>
		<Select
			value = {props.projectType.selected}
			onChange = {props.typeSelectHandler}
			input = {<Input id = "select-type"/>}
		>

			{props.projectType.types.map(type => {
				return <MenuItem key = {type.id}
				                 value = {type}>{type.label}</MenuItem>
			})}
		</Select>
		<FormHelperText>{props.projectType.validationMessage}</FormHelperText>
	</FormControl>;

	const urlInput = <TextField
		id = "url"
		name = "url"
		label = {intl.formatMessage({
			id: "PROJECT.LABEL.URL.ADDRESS",
			defaultMessage: 'Website Address'
		})}
		className = {`${classes.textField}`}
		value = {props.url.value}
		onChange = {props.urlChangeHandler}
		onBlur={() => props.syncValueWithParentContainer('url')}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({
			id: "PROJECT.PLACEHOLDER.URL.ADDRESS",
			defaultMessage: 'https://bing.com'
		})}
		error = {!props.url.valid}
		helperText = {props.url.validationMessage}
	/>;

	const browserInput = <FormControl className = {`${classes.formControl} row d-flex mt-6`}
	                                  error = {!props.browser.valid}>
		<InputLabel htmlFor = "select-browser">
			<FormattedMessage id = "PROJECTS.LABEL.BROWSER"
			                  defaultMessage = 'Browser'/></InputLabel>
		<Select
			value = {props.browser.selected}
			onChange = {props.browserSelectHandler}
			input = {<Input id = "select-browser"/>}
		>

			{props.browser.types.map(browser => {
				return <MenuItem key = {browser.value}
				                 value = {browser}>{browser.label}</MenuItem>
			})}
		</Select>
		<FormHelperText>{props.browser.validationMessage}</FormHelperText>
	</FormControl>;

	// const deviceInput = <FormControl className = {`${classes.textField} row d-flex mt-6`}
	//                                  error = {!props.device.valid}>
	// 	<InputLabel htmlFor = "select-device">
	// 		<FormattedMessage id = "PROJECTS.LABEL.DEVICE"
	// 		                  defaultMessage = 'Device'/></InputLabel>
	// 	<Select
	// 		value = {props.device.selected}
	// 		onChange = {props.deviceSelectHandler}
	// 		input = {<Input id = "select-device"/>}
	// 	>
	//
	// 		{props.device.types.map(device => {
	// 			return <MenuItem key = {device.id}
	// 			                 value = {device}>{device.label}</MenuItem>
	// 		})}
	// 	</Select>
	// 	<FormHelperText>{props.device.validationMessage}</FormHelperText>
	// </FormControl>;

	const apkFile = <div>
		<Button component = "label" variant = "contained" size = 'large' startIcon = {<ImportExport/>} className = 'my-3' disabled={props.creationLoading}>

			<FormattedMessage id = "PROJECT.LABEL.APK"
			                  defaultMessage = 'Select Apk File'/>

			<Input type = 'file' id = 'select-apk' hidden = {true}
			       onChange = { event => props.apkChangeHandler(event)}
				   onClick={event => event.target.value = null}
			/>
		</Button>

		{props.apk.label !== '' && <Typography variant = "subtitle1" gutterBottom>
			<FormattedMessage id = "PROJECT.LABEL.APK.SELECTED"
			                  defaultMessage = 'Selected: '/> {props.apk.label}
		</Typography>}

		{!props.apk.valid && <Typography variant = "subtitle1" color = 'error' gutterBottom>
			{props.apk.validationMessage}
		</Typography>}
	</div>;

	// let liveInput = <FormControlLabel
	// 	control = {
	// 		<Switch
	// 			checked = {props.live}
	// 			onChange = {(event, checked) =>
	// 				props.liveChangeHandler(checked)}
	// 			value = "live"
	// 			inputProps = {{'aria-label': 'secondary checkbox'}}
	// 		/>
	// 	}
	// 	label = {intl.formatMessage({ id: "PROJECTS.LABEL.LIVE", defaultMessage: 'Live Mode' })}
	// 	className = {`${classes.textField} row mt-6`}
	// />;

	const operatingSystemInput = <FormControl className = {`${classes.formControl} row d-flex mt-6`}
									  error = {!props.operatingSystem.valid}>
		<InputLabel htmlFor = "select-operatingSystem">
			<FormattedMessage id = "PROJECTS.LABEL.OPERATING.SYSTEM" defaultMessage = 'Operating System'/>
		</InputLabel>
		<Select
			value = {props.operatingSystem.selected}
			onChange = {props.selectOperatingSystem}
			input = {<Input id = "select-operatingSystem"/>}>
			{props.operatingSystem.types.map(os => {
				return <MenuItem key = {os.value} value = {os}>{os.label}</MenuItem>
			})}
		</Select>
		<FormHelperText>{props.operatingSystem.validationMessage}</FormHelperText>
	</FormControl>;

	const browserVersionInput = <FormControl className = {`${classes.formControl} row d-flex mt-6`}
											  error = {!props.browserVersion.valid}>
		<InputLabel htmlFor = "select-browserVersion">
			<FormattedMessage id = "PROJECTS.LABEL.BROWSER.VERSION" defaultMessage = 'Browser Version'/>
		</InputLabel>
		<Select
			value = {props.browserVersion.selected}
			onChange = {props.selectBrowserVersion}
			input = {<Input id = "select-browserVersion"/>}>
			{props.browserVersion.types.map(version => {
				return <MenuItem key = {version.value + version.label} value = {version}>{version.label}</MenuItem>
			})}
		</Select>
		<FormHelperText>{props.browserVersion.validationMessage}</FormHelperText>
	</FormControl>;

	let typeAdditionalField1, typeAdditionalField2;
	if (props.projectType.selected) {
		const id = props.projectType.selected.id;
		switch (id) {
			case 1:
				typeAdditionalField1 = urlInput;
				typeAdditionalField2 = browserInput;
				break;
			case 2:
				typeAdditionalField1 = urlInput;
				typeAdditionalField2 = null; //deviceInput;
				break;
			case 3:
				typeAdditionalField1 = apkFile;
				typeAdditionalField2 = null; //deviceInput;
				break;
			case 4:
				typeAdditionalField1 = urlInput;
				typeAdditionalField2 = null;
				// liveInput = null;
				break;
			default:
				typeAdditionalField1 = typeAdditionalField2 = null;
		}
	}


	// const [customTimeZones, setCustomTimeZones] = useState({});
	// useEffect(() => {
	// 	if(Array.isArray(props.timeZones)){
	// 		const updatedCustomTimeZones = {};
	// 		props.timeZones.forEach(timeZone => updatedCustomTimeZones[timeZone.zoneId] = timeZone.label );
	// 		delete updatedCustomTimeZones.Asia;
	// 		// setCustomTimeZones(updatedCustomTimeZones);
	// 		setCustomTimeZones({'one': 'two', 'oo': 'pp'});
	// 		console.log('customTimeZones', customTimeZones);
	// 		console.log('allTimezones', allTimezones);
	//
	// 	}
	// }, [props.timeZones]);

	const [customTimeZones, setCustomTimeZones] = useState({});
	useEffect(() => {
		const updatedfAllTimezones = {...allTimezones};
		delete updatedfAllTimezones['Asia/Jerusalem'];
		setCustomTimeZones(updatedfAllTimezones);
	}, []);

	let timeInput = null, timeZone = null;
	if (props.autoExec) {
		timeInput = (
			<MuiPickersUtilsProvider utils = {DateFnsUtils}>
				<KeyboardTimePicker
					clearable ampm = {false} placeholder = "17:30" mask = "__:__"
					label = {intl.formatMessage({
						id: "PROJECTS.LABEL.START.TIME",
						defaultMessage: 'Start Time'
					})}
					value = {props.startTime.value}
					onChange = {props.startTimeChangeHandler}
					required = {true}
					error = {!props.startTime.valid}
					invalidDateMessage = {props.startTime.validationMessage}
					helperText = {props.startTime.validationMessage}
					className = {`${classes.textField} row d-flex w-100`}
				/>
			</MuiPickersUtilsProvider>
		);
		timeZone = <TimezoneSelect value={props.timeZone} onChange={props.timeZoneChangeHandler} className='w-100 mt-3'
								   timezones={customTimeZones}
		/>;
	}

	// let passwordInput = null;
	// if (props.live) {
	// 	passwordInput = <TextField
	// 		id = "password"
	// 		name = "password"
	// 		autoComplete = 'new-password'
	// 		// type='password'
	// 		type = {showPassword ? 'text' : 'password'}
	// 		label = {intl.formatMessage({
	// 			id: "REGISTRATION.LABEL.PASSWORD",
	// 			defaultMessage: 'Password'
	// 		})}
	// 		className = {`${classes.textField} `}
	// 		value = {props.password.value}
	// 		onChange = {props.passwordChangeHandler}
	// 		onBlur={() => props.syncValueWithParentContainer('password')}
	// 		margin = "normal"
	// 		variant = "outlined"
	// 		placeholder = {intl.formatMessage({
	// 			id: "REGISTRATION.PLACEHOLDER.PASSWORD",
	// 			defaultMessage: 'Password'
	// 		})}
	// 		error = {!props.password.valid}
	// 		helperText = {props.password.validationMessage}
	// 		InputProps = {{
	// 			endAdornment: (<InputAdornment position = "start">
	// 				<IconButton
	// 					aria-label = "toggle password visibility"
	// 					onClick = {(e) => {
	// 						e.preventDefault();
	// 						setShowPassword(!showPassword);
	// 					}}
	// 					// onMouseDown={handleMouseDownPassword}
	// 				>
	// 					{showPassword ? <Visibility/> : <VisibilityOff/>}
	// 				</IconButton>
	// 			</InputAdornment>)
	// 		}}
	// 	/>;
	// }



	const autoExecInput = <FormControlLabel
		control = {
			<Switch
				checked = {props.autoExec}
				onChange = {(event, checked) =>
					props.autoExecChangeHandler(checked)}
				value = "autoExec"
				inputProps = {{'aria-label': 'secondary checkbox'}}
			/>
		}
		label = {intl.formatMessage({ id: "PROJECTS.LABEL.AUTOMATIC.EXECUTION",
			defaultMessage: 'Automatic Execution' })}
		className = {`${classes.textField} row mt-6`}
	/>;

	const usersAccessTransferListInput = <Grid container spacing = {6} justify = "center"
	                                      alignItems = "center" className = {classesTransfer.root}>
		<Grid item xs={12} sm={5} md={5}>
			<h5 className='text-center mb-5 text-primary'>
				<FormattedMessage id="PROJECT.ACCESS.AVAILABLE.USERS"
				                  defaultMessage='Available Users'/></h5>
			{customList(props.usersLeft)}
		</Grid>
		<Grid item xs={12} sm={2} md={2}>
			<Grid container direction = "column" alignItems = "center">
				<Button
					variant = "outlined" size = "small"
					className = {classesTransfer.button}
					onClick = {props.handleAllRight}
					disabled = {props.usersLeft.length === 0}
					aria-label = "move all right">
					<i className = "flaticon2-fast-next"/>
				</Button>
				<Button
					variant = "outlined" size = "small"
					className = {classesTransfer.button}
					onClick = {props.handleCheckedRight}
					disabled = {props.usersLeftChecked.length === 0}
					aria-label = "move selected right"
				>
					<i className = "flaticon2-next"/>
				</Button>
				<Button
					variant = "outlined"
					size = "small"
					className = {classesTransfer.button}
					onClick = {props.handleCheckedLeft}
					disabled = {props.usersRightChecked.length === 0}
					aria-label = "move selected left"
				>
					<i className = "flaticon2-back"/>
				</Button>
				<Button
					variant = "outlined" size = "small"
					className = {classesTransfer.button}
					onClick = {props.handleAllLeft}
					disabled = {props.usersRight.length === 0}
					aria-label = "move all left"
				>
					<i className = "flaticon2-fast-back"/>
				</Button>
			</Grid>
		</Grid>
		<Grid item xs={12} sm={5} md={5}>
			<h5 className='text-center mb-5 text-success'>
				<FormattedMessage id="PROJECT.TITLE.USERS.WITH.ACCESS"
				                  defaultMessage='Users with Access'/></h5>
			{customList(props.usersRight)}
		</Grid>
	</Grid>;

	// const descriptionInput = <TinyEditor
	// 	value={props.description.value}
	// 	init={{
	// 		height: 500,
	// 		menubar: false,
	// 		plugins: [
	// 			'print preview paste autolink autosave save directionality code visualblocks visualchars ' +
	// 			'fullscreen link codesample charmap hr pagebreak nonbreaking toc advlist lists textpattern ' +
	// 			'noneditable charmap quickbars emoticons',
	// 		],
	// 		toolbar:
	// 			'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
	// 			'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
	// 			'forecolor backcolor removeformat | pagebreak | charmap emoticons | ' +
	// 			'fullscreen  preview save print | insertfile image media template link anchor codesample | ' +
	// 			'ltr rtl',
	// 		autosave_interval: '15s',
	// 		autosave_retention: '2m',
	// 		toolbar_mode: 'wrap',
	// 		branding: false,
	// 		statusbar: false
	// 	}}
	// 	onEditorChange={(value) => props.descriptionChangeHandler(value)}
	// 	onBlur={() => props.syncValueWithParentContainer('description')}
	// />;

	// chinese one
	const descriptionInput = <Editor value={props.description.value}
									 onChange={(value) => {
										 props.descriptionChangeHandler(value);
										 props.syncValueWithParentContainer('description');
									 }}
									 onSave={() => props.syncValueWithParentContainer('description')} language="en" subfield={true} preview={true}
									 toolbar={{
										 h1: true, // h1
										 h2: true, // h2
										 h3: true, // h3
										 h4: true, // h4
										 link: true, // 链接
										 code: true, // 代码块
										 preview: true,
										 expand: true, // 全屏
										 undo: true, // 撤销
										 redo: true, // 重做
										 save: true,
										 subfield: true, // 单双栏模式
									 }}/>;

	// const descriptionInput = <LzEditor image={false}
	// 								   video={false}
	// 								   audio={false}
	// 								   convertFormat="markdown"/>
	// const mdParser = new MarkdownIt(/* Markdown-it options */);
	//
	// const descriptionInput =  <MdEditor
	// 	value={props.description.value}
	// 	style={{ height: '500px' }}
	// 	renderHTML={text => <ReactMarkdown source={text} />}
	// 									onChange={({html, text}) => {
	// 										const newValue = text.replace(/\d/g, "");
	// 										// console.log("newValue: ", newValue);
	// 										props.descriptionChangeHandler(newValue);
	// 									}} />

	let urlSectionForWeb = null, urlSectionForAPI;

	if(props.projectType.selected.value === Type.WEB){
		urlSectionForWeb = <>
			<Row>
				<Col>{operatingSystemInput}</Col>
				<Col>{typeAdditionalField2}</Col>
				<Col>{browserVersionInput}</Col>
			</Row>
			<Row>
				<Col sm="12">{typeAdditionalField1}</Col>
			</Row>
		</>;
	} else if(props.projectType.selected.value === Type.API){
		urlSectionForAPI = <Row>
			<Col xs = {12} sm = {6}>{typeAdditionalField1}</Col>
			<Col xs = {12} sm = {6}>{typeAdditionalField2}</Col>
		</Row>
	}


	// TODO make Component for get rid of duplicate: here and in /project
	// since android redirect directly to update to continue filling the rest of the information, suggest default must be shown
	let suggestDefaultDialog = null;
	// console.log('props.project && props.projectType.selected.value === Type.ANDROID && props.type === \'update\' && props.suggestDefault',
	// 	props.label.value , props.projectType.selected.value === Type.ANDROID , props.type === 'update' , props.suggestDefault)
	if(props.label.value && props.projectType.selected.value === Type.ANDROID && props.type === 'update' && props.suggestDefault){
		suggestDefaultDialog = <Dialog
			open = {props.suggestDefaultDialogOpen}
			onClose = {props.handleCloseSuggestDefaultDialog}
			aria-labelledby = "alert-dialog-title"
			aria-describedby = "alert-dialog-description"
		>
			<DialogTitle id = "alert-dialog-title">
				<FormattedMessage id="PROJECTS.DIALOG.MAKE.PROJECT.DEFAULT"
								  defaultMessage='Make this the default project'/> : {props.label.value} ?
			</DialogTitle>
			<DialogActions>
				<MUIButton onClick = {props.handleCloseSuggestDefaultDialog} color = "primary">
					<FormattedMessage id="BUTTON.NO" defaultMessage='No'/>
				</MUIButton>
				<MUIButton onClick = {() => {
					props.handleCloseSuggestDefaultDialog();
					// props.delete(props.project.id);
					// Prefered
					// const loggedInUser = {...props.loggedInUser};
					// loggedInUser.preferedProjectId = props.project.id;
					// loggedInUser.preferedProjectLabel = props.project.label;
					// loggedInUser.preferedProjectSubscribed = props.project.subscripted;
					// loggedInUser.preferedProjectType = props.project.type;
					// props.onUpdateUser(loggedInUser);
					props.onUpdatePreferred();
					// props.onSetProject(props.project);
				}} color = "primary">
					<FormattedMessage id="BUTTON.YES" defaultMessage='Yes'/>
				</MUIButton>
			</DialogActions>
		</Dialog>;
	}

	let deviceGroupInput = null, devicesNames = null;
	if((props.projectType.selected.value === Type.ANDROID || props.projectType.selected.value === Type.ANDROID_WEB) && props.type === 'update'){
		deviceGroupInput = <FormControl className = {`${classes.formControl} w-100 mt-6`}
										error = {!props.deviceGroup.valid}>
			<InputLabel htmlFor = "select-device-group">
				<FormattedMessage id = "PROJECTS.LABEL.DEVICE.GROUP" defaultMessage = 'Device Group'/>
			</InputLabel>
			<Select
				value = {props.deviceGroup.selected} label={props.deviceGroup.selected.name}
				onChange = {props.selectDeviceGroup}
				input = {<Input id = "select-device-group"/>}>
				{props.deviceGroup.types.map(device => {
					return <MenuItem key = {device.id} value = {device}>{device.name}</MenuItem>
				})}
			</Select>
			<FormHelperText>{props.deviceGroup.validationMessage}</FormHelperText>
		</FormControl>;
		devicesNames = Object.keys(props.deviceGroup.selected).length > 0 ? props.deviceGroup.selected.devices.map(dev =>
			<span key={dev.id} className="label label-lg label-light-primary label-inline m-2 font-weight-bold">
			{dev.name}</span>) : null;

	}

	return <><Container>
		<Row>
			<Col >
				<h1 className='text-center mt-4'>{titleHeader}</h1>
			</Col>
		</Row>
		<Divider />
		<Row>
			<Col xs = {12} sm = {6}>
				{labelInput}
			</Col>
			<Col >
				{projectTypeInput}
			</Col>
		</Row>
		{(props.projectType.selected.value === 'ANDROID' || props.projectType.selected.value === 'ANDROID_WEB') ?
			<Row>
				<Col xs = {12} sm = {6}>{typeAdditionalField1}</Col>
				{/*<Col>{typeAdditionalField2}</Col>*/}
				{/*<Col xs='auto' className='d-flex align-items-center px-0 mt-6'>*/}
				{/*	<Tooltip*/}
				{/*		title={<Typography fontSize={30}>*/}
				{/*			<FormattedMessage id="PROJECT.DEVICE.MODEL.TOOLTIP" defaultMessage='We will add recent devices in future versions'/>*/}
				{/*		</Typography>}>*/}
				{/*		<InfoIcon className='' color='secondary'/>*/}
				{/*	</Tooltip>*/}
				{/*</Col>*/}
			</Row>
			:
			null
			// <Row>
			// 	<Col xs = {12} sm = {6}>{typeAdditionalField1}</Col>
			// 	<Col xs = {12} sm = {6}>{typeAdditionalField2}</Col>
			// </Row>
		}
		{urlSectionForWeb}

		{urlSectionForAPI}

		{/*<Row>*/}
		{/*	<Col xs = {12} sm = {6}>*/}
		{/*		{liveInput}*/}
		{/*	</Col>*/}
		{/*	<Col xs = {12} sm = {6}>*/}
		{/*		{passwordInput}*/}
		{/*	</Col>*/}
		{/*</Row>*/}
		{props.timeZoneError && <Row>
			<Col>
				<Alert elevation = {6} variant = "standard"  severity = "error" className = ''>
					{props.timeZoneError}
				</Alert>
			</Col>
		</Row>
		}

		{((props.projectType.selected.value === 'ANDROID' || props.projectType.selected.value === 'ANDROID_WEB') && props.type === 'update') ? <Row>
			<Col>
				{deviceGroupInput}
				{devicesNames}
			</Col>
			<Col className={`align-self-center mb-1 ${classes.buttons}`}>
				<DeviceGroupDialogContainerForProject/>
				<DeviceGroupManagementDialog/>
			</Col>
		</Row>: null}

		{(props.projectType.selected.value === 'ANDROID' && props.type === 'create') ? null : <Row>
			<Col xs = {12} sm = {4}>
				{autoExecInput}
			</Col>
			<Col xs = {12} sm = {4} className='d-flex align-items-center'>
				{timeZone}
			</Col>
			<Col xs = {12} sm = {4} className='d-flex align-items-center'>
				{timeInput}
			</Col>
		</Row>}
		{(props.projectType.selected.value === 'ANDROID' && props.type === 'create') ? null : localStorage.getItem('Top-Role') === Role.ROLE_TESTER ? null : <Row>
			<Col>
				<h3 className='text-center mb-5'>
					<FormattedMessage id="PROJECT.TITLE.GRANT.ACCESS.TO.USERS"
					                  defaultMessage='Grant Users Access to The Project'/>
				</h3>
				{usersAccessTransferListInput}
			</Col>
		</Row>}
		{(props.projectType.selected.value === 'ANDROID' && props.type === 'create') ? null : <Row>
			<Col className='mb-6'>
				{descriptionInput}
				{descriptionValidationMessage}
			</Col>
		</Row>}

	</Container>
	{suggestDefaultDialog}
	</>

}

export default CreationProjectFormJSX;