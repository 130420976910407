import React, {useState} from "react";
import {AppBar, Divider, Tab, Tabs} from "@material-ui/core";
import {useIntl} from "react-intl";

import SuiteTestActions from "./SuiteTestActions";
import SuiteTestsDisplay from "./SuiteTestsDisplay";
import UpdateSuite from "../../containers/Suite/UpdateSuite";
import TabPanel from "../../UI/TabPanel";
import RequisiteTab from "./RequisiteTab";
import * as Type from "../Project/enum/ProjectType";
import SuiteRestRequestActions from "./SuiteRestRequestActions";
import SuiteRestRequestsDisplay from "./SuiteRestRequestsDisplay";
import AddAuthenticationToSuiteForm from "../../containers/Suite/AddAuthenticationToSuiteForm";
import AddVariablesToSuiteForm from "../../containers/Suite/AddVariablesToSuiteForm";

const SuiteDisplayTabs = props => {
	const intl = useIntl();

	const [tab, setTab] = useState(0);

	function tabChangeHandler(event, newValue) {
		setTab(newValue);
	}

	let testsOrRequestsActions;

	let suiteName = null;
	if(props.suite){
		suiteName = props.suite.label;
	}

	let testsOrRequestsTabHeader, testOrRequestsTabContent;
	let preRequestsTabHeader, preRequestsTabContent;
	let postRequestsTabHeader, postRequestsTabContent;

	let authorizationTabHeader, authorizationTabContent;
	let variablesTabHeader, variablesTabContent;
	if(props.type === Type.API){
		testsOrRequestsActions = <SuiteRestRequestActions {...props}/>;
		testsOrRequestsTabHeader = <Tab label = {intl.formatMessage({ id: "SUITE.TAB.API.TESTS",
			defaultMessage: 'API Tests'})}/>;
		testOrRequestsTabContent = <TabPanel value={tab} index={1} py={0}>
			{testsOrRequestsActions}
			<Divider/>
			<SuiteRestRequestsDisplay
				deleteRequest={props.deleteRequest}
				requests={props.requests}
				setRequests={props.setRequests}
				updateRequests={props.updateRequests}
				updatingError={props.updatingError}
			/>
		</TabPanel>;

		authorizationTabHeader = <Tab
			label = {intl.formatMessage({ id: "SUITE.TAB.AUTHENTICATION", defaultMessage: 'Authentication'})}/>;
		authorizationTabContent = <TabPanel value={tab} index={2} py={0} className='bg-white px-10 py-6'>
			<AddAuthenticationToSuiteForm
				suite = {props.suite}
				updateSuite={props.updateSuite}/>
		</TabPanel>;

		variablesTabHeader = <Tab
			label = {intl.formatMessage({ id: "SUITE.TAB.VARIABLES", defaultMessage: 'Variables'})}/>;
		variablesTabContent = <TabPanel value={tab} index={3} py={0} className='bg-white px-10 py-6'>
			<AddVariablesToSuiteForm suite = {props.suite} updateSuite={props.updateSuite}/>
		</TabPanel>;

	} else {
		testsOrRequestsActions = <SuiteTestActions {...props} />;
		testsOrRequestsTabHeader = <Tab label = {intl.formatMessage({ id: "SUITE.TAB.TEST.CASES",
			defaultMessage: 'Test Cases'})}/>;
		testOrRequestsTabContent = <TabPanel value={tab} index={1} py={0}>
			{testsOrRequestsActions}
			<Divider/>
			<SuiteTestsDisplay
				suiteName = {suiteName}
				deleteTest = {props.deleteTest}
				tests={props.tests}
				setTests={props.setTests}
				updateTests={props.updateTests}
				updatingError={props.updatingError}
			/>
		</TabPanel>;

		preRequestsTabHeader = <Tab label = {intl.formatMessage({ id: "SUITE.TAB.PREREQUISITE", defaultMessage: 'Prerequisite'})}/>;
		postRequestsTabHeader = <Tab label = {intl.formatMessage({ id: "SUITE.TAB.POSTREQUISITE", defaultMessage: 'Postrequisite'})}/>;

		preRequestsTabContent = <TabPanel value={tab} index={2} py={0}>
			<RequisiteTab
				type='pre'
				loading={props.loading}
				deleteRequisite={props.deletePrerequisite}
				requisites={props.prerequisites}
				setRequisites={props.setPrerequisites}
				updateRequisites={props.updatePrerequisites}
				updatingError={props.updatingError}
			/>
		</TabPanel>;
		postRequestsTabContent = <TabPanel value={tab} index={3} py={0}>
			<RequisiteTab
				type='post'
				loading={props.loading}
				deleteRequisite = {props.deletePostrequisite}
				requisites = {props.postrequisites}
				setRequisites = {props.setPostrequisites}
				updateRequisites = {props.updatePostrequisites}
				updatingError={props.updatingError}
			/>
		</TabPanel>;
	}
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		testsOrRequestsActions = null;

	}
	return (
		<>
			<AppBar position = "static" color='transparent' elevation={0}>
				<Tabs value = {tab} onChange = {tabChangeHandler}
				      variant="scrollable" scrollButtons="auto">
					<Tab label = {intl.formatMessage({ id: "SUITE.TAB.INFORMATIONS",
						defaultMessage: 'Informations'})}/>
					{testsOrRequestsTabHeader}
					{preRequestsTabHeader}
					{postRequestsTabHeader}
					{authorizationTabHeader}
					{variablesTabHeader}
				</Tabs>
			</AppBar>

			<TabPanel value={tab} index={0} py={0}>
				<UpdateSuite {...props}/>
			</TabPanel>

			{testOrRequestsTabContent}

			{preRequestsTabContent}

			{postRequestsTabContent}

			{authorizationTabContent}

			{variablesTabContent}

		</>
	)
}

export default SuiteDisplayTabs;