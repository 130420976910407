import React from "react";
import {useIntl} from "react-intl";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import SVG from "react-inlinesvg";
import {Container} from "react-bootstrap";

import TabPanel from "../../UI/TabPanel";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import NormalUserForm from "./NormalUserForm";
import NormalUserAddressForm from "./NormalUserAddressForm";

const FormUserTabs = props => {
	const intl = useIntl();

	const tabChangeHandler = (event, newValue) => {
		props.setSelectTab(newValue);
	};

	let normalUserHeaderTitle = props.type === 'create' ?
		intl.formatMessage({ id: "NEW.USER.TAB.TITLE.CREATE.NEW", defaultMessage: 'Create a new' }) :
		intl.formatMessage({ id: "NEW.USER.TAB.TITLE.UPDATE.EXISTING", defaultMessage: 'Update existing' });
	normalUserHeaderTitle = normalUserHeaderTitle +
		intl.formatMessage({ id: "NEW.USER.TAB.TITLE.USER", defaultMessage: ' User' });

	let cicdHeaderTitle = props.type === 'create' ?
		intl.formatMessage({ id: "NEW.USER.TAB.TITLE.CREATE.NEW", defaultMessage: 'Create a new' }) :
		intl.formatMessage({ id: "NEW.USER.TAB.TITLE.UPDATE.EXISTING", defaultMessage: 'Update existing' });
	cicdHeaderTitle = cicdHeaderTitle +
		intl.formatMessage({ id: "NEW.USER.TAB.TITLE.CICD.USER", defaultMessage: ' CI / CD User' });


	let addressInput = null;
	if(props.type === 'update' && props.includeAddress){
		addressInput = <NormalUserAddressForm type={props.type} loggedInUser={props.loggedInUser}
		                                      includeAddress={props.includeAddress} setIncludeAddress={props.setIncludeAddress}
		                                      // streetNumber = {props.streetNumber} setStreetNumber = {props.setStreetNumber}
		                                      route = {props.route} setRoute = {props.setRoute}
		                                      locality = {props.locality} setLocality = {props.setLocality}
		                                      postalCode = {props.postalCode} setPostalCode = {props.setPostalCode}
		                                      state = {props.state} setState = {props.setState}
		                                      country={props.country} setCountry={props.setCountry}
		                                      registerValidateNormalUserAddressFormCallback={props.registerValidateNormalUserAddressFormCallback}
											  completeInformationMsg={props.completeInformationMsg}
		/>
	}

	return (
		// <div className = "bg-gray-600">
		<>
			<AppBar position = "static" color='transparent' elevation={0}>
				<Tabs value = {props.selectTab} onChange = {tabChangeHandler}
				      variant="scrollable" scrollButtons="auto">
					<Tab label = {normalUserHeaderTitle}
					     disabled = {props.type === 'update' && (props.cicd)}
					     hidden = {props.type === 'update' && (props.cicd)}
					     icon = {
						     <SVG
							     src = {toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}
						     />
					     }/>
					{/*<Tab label = {cicdHeaderTitle}*/}
					{/*     disabled = {props.type === 'update' && (!props.cicd)}*/}
					{/*     hidden = {props.type === 'update' && (!props.cicd)}*/}
					{/*     icon = {*/}
					{/*	     <SVG*/}
					{/*		     src = {toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")}*/}
					{/*	     />*/}
					{/*     }*/}
					{/*/>*/}
				</Tabs>
			</AppBar>

			<TabPanel value={props.selectTab} index={0} px={3}>
				<Container className='px-0'>
					<NormalUserForm type={props.type} loggedInUser={props.loggedInUser}
									registerValidateNormalUserFormCallback={props.registerValidateNormalUserFormCallback}
					                username = {props.username} setUsername = {props.setUsername}
					                firstName = {props.firstName} setFirstName = {props.setFirstName}
					                lastName = {props.lastName} setLastName = {props.setLastName}
					                email = {props.email} setEmail = {props.setEmail}
									company={props.company} setCompany={props.setCompany}
					                authorities={props.authorities} setAuthorities={props.setAuthorities}

									rolesSelectionList={props.rolesSelectionList}

					                includeRoles={props.includeRoles}

									isAdmin={props.isAdmin}
									completeInformationMsg={props.completeInformationMsg}
					/>

					{addressInput}
				</Container>

			</TabPanel>

			{/*<TabPanel value={props.selectTab} index={1} px={3}>*/}
			{/*	<CICDUserForm username = {props.username} setUsername = {props.setUsername}*/}
			{/*	              password={props.password} setPassword={props.setPassword}*/}
			{/*	              registerValidateCICDUserFormCallback={props.registerValidateCICDUserFormCallback}*/}
			{/*	              type={props.type}*/}
			{/*	/>*/}
			{/*</TabPanel>*/}
		</>
		// </div>
	);
}

export default FormUserTabs;