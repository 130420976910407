import * as actionTypes from "./actionTypes";

export const loadReport = (id) => {
	return {
		type: actionTypes.LOAD_REPORT,
		id: id
	}
};

export const loadReportStart = () => {
	return {
		type: actionTypes.LOAD_REPORT_START
	}
};

export const loadReportSuccess = (report) => {
	return {
		type: actionTypes.LOAD_REPORT_SUCCESS,
		report: report
	}
};

export const loadReportFail = (error) => {
	return {
		type: actionTypes.LOAD_REPORT_FAIL,
		error: error
	}
};

export const downloadScreenshot = (imagekey) => {
	return {
		type: actionTypes.DOWNLOAD_SCREENSHOT,
		imagekey: imagekey
	}
};

export const downloadScreenshotStart = () => {
	return {
		type: actionTypes.DOWNLOAD_SCREENSHOT_START,
	}
};

export const downloadScreenshotSuccess = (imageBlob, imagekey) => {
	return {
		type: actionTypes.DOWNLOAD_SCREENSHOT_SUCCESS,
		imageBlob: imageBlob,
		imagekey: imagekey
	}
};

export const downloadScreenshotFail = (error) => {
	return {
		type: actionTypes.DOWNLOAD_SCREENSHOT_FAIL,
		error: error
	}
};