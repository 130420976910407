export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties
	}
}

export const extractRole = (authorities) => {
	let topRole;
	if( authorities.indexOf('ROLE_SUPER') >= 0) {
		topRole = 'ROLE_SUPER';
	} else if( authorities.indexOf('ROLE_ADMIN') >= 0 ){
		topRole = 'ROLE_ADMIN';
	} else if( authorities.indexOf('ROLE_MANAGER') >= 0 ){
		topRole = 'ROLE_MANAGER';
	} else if( authorities.indexOf('ROLE_TESTER') >= 0 ){
		topRole = 'ROLE_TESTER';
	} else if( authorities.indexOf('ROLE_USER') >= 0 ){
		topRole = 'ROLE_USER';
	} else if( authorities.indexOf('ROLE_CICD') >= 0 ){
		topRole = 'ROLE_CICD';
	}
	return topRole;
}