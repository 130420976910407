import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Container, Row} from "react-bootstrap";
import {FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select, TextField} from "@material-ui/core";

import {HttpMethodObjects} from "./enum/HttpMethod";
import {changeText, selectElement} from "./Shared";


const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(4),
		minWidth: 120
	}
}));

const RestRequestCoreForm = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [name, setName] = useState({
		value: props.core.name,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [method, setMethod] = useState({
		method: props.core.method,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		methods: HttpMethodObjects
	});

	const [URL, setURL] = useState({
		value: props.core.url,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 2000,
			isURL: true
		},
		valid: true,
		validationMessage: null
	});

	useEffect(() => {
		// console.log('useEffect props.core');
		// Instead of making this check for each one individually. if name is different then
		// the rest is also different
		if(props.core.name !== name.value || props.core.url !== URL.value){
			const updatedName = {...name};
			updatedName.value = props.core.name;
			setName(updatedName);
			const updatedMethod = {...method};
			updatedMethod.method = props.core.method;
			setMethod(updatedMethod);
			const updatedURL = {...URL};
			updatedURL.value = props.core.url;
			setURL(updatedURL);
		}
	}, [props.core]);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event.target.value, 'name', intl);
		setName(updatedName);
	}

	const methodSelectHandler = (event) => {
		const updatedComponent = selectElement(method, event.target.value, 'method', 'method', intl);
		setMethod(updatedComponent);
		const core = {
			name: name.value,
			method: updatedComponent.method,
			url: URL.value
		}
		props.setCore(core);
	};

	const URLChangeHandler = (event) => {
		const updatedURL = changeText(URL, event.target.value, 'url', intl);
		setURL(updatedURL);
	}

	const onBlur = () => {
		const core = {
			name: name.value,
			method: method.method,
			url: URL.value
		}
		// console.log('blur', core);
		props.setCore(core);
	}

	// old behaviour: for update, no need for button. New: sending data to the parent.
	let nameOnEnterKey = null;
	// 	(event) => {
	// 	if (event.key === 'Enter' || event.keyCode === 13){
	// 		// No need.Verified because on blur always executed and
	// 		// if not. submit.validate gets all updated values
	// 		// onBlur();
	// 	}
	// };

	const checkAllInputsValidity = () => {
		let allValid = true;

		const updatedName = changeText(name, name.value, 'name', intl);
		setName(updatedName);
		allValid = allValid && updatedName.valid;

		const updatedMethod = selectElement(method, method.method, 'method', 'method', intl);
		setMethod(updatedMethod);
		allValid = allValid && updatedMethod.valid;

		const updatedURL = changeText(URL, URL.value, 'url', intl);
		setURL(updatedURL);
		allValid = allValid && updatedURL.valid;

		return allValid;
	}
	props.registerValidateCoreFormCallback(checkAllInputsValidity);

	let nameInputText = <TextField
		id = "name"  name = "name"
		label = {intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.NAME.LABEL",
			defaultMessage: 'Request Name'})}
		className = {`${classes.textField}`}
		value = {name.value}
		onChange = {nameChangeHandler}
		onBlur={onBlur}
		onKeyDown={nameOnEnterKey}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.NAME.PLACEHOLDER",
			defaultMessage: 'Get Info'})}
		error = {!name.valid}
		helperText = {name.validationMessage}
	/>;

	let httpMethodSelect = <FormControl className = {`${classes.formControl}`}  fullWidth={true}
	                                 error = {!method.valid}>
		<InputLabel htmlFor = "select-method">
			<FormattedMessage id="REST.REQUEST.FORM.CORE.METHOD.LABEL"
			                  defaultMessage='HTTP Method'/>
		</InputLabel>
		<Select
			value = {method.method}
			onChange = {(event) => methodSelectHandler(event)}
			input = {<Input id = "select-method"/>}
		>

			{method.methods.map(method => {
				return <MenuItem key = {method.value}
				                 value = {method.value}>{method.label}</MenuItem>
			})}
		</Select>
		<FormHelperText>{method.validationMessage}</FormHelperText>
	</FormControl>;

	let URLInputText = <TextField
		id = "url"  name = "url"
		label = {intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.URL.LABEL",
			defaultMessage: 'URL'})}
		className = {`${classes.textField}`}
		value = {URL.value}
		onChange = {URLChangeHandler}
		onBlur={onBlur}
		onKeyDown={nameOnEnterKey}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.URL.PLACEHOLDER",
			defaultMessage: 'https://tigmat.io'})}
		error = {!URL.valid}
		helperText = {URL.validationMessage}
	/>;

	return (
		<Container className='px-0'>
			<Row>
				<Col>
					{nameInputText}
				</Col>
			</Row>
			<Row className='align-items-center'>
				<Col md={3} sm={12}>
					{httpMethodSelect}
				</Col>
				<Col md={9} sm={12}>
					{URLInputText}
				</Col>
			</Row>
		</Container>
	);
}

export default RestRequestCoreForm;