/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import {Switch} from "react-router-dom";
import {Redirect} from "react-router";

import {ContentRoute, Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import Authentication from "../tigmat/components/Authentication/Authentication";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes(props) {

	let routes;
	// const auth = true;
	// console.log("how many times props.isAuthenticated: ", props.isAuthenticated);
	// // TODO wait until authCheck saga finishes then return, else try return null
	if (props.isAuthenticated) {
		// if (auth) {
		routes =
			<Switch>
				<ContentRoute path = "/error" component = {ErrorsPage}/>
				<Redirect from = "/auth" to = "/"/>
				<Authentication path = '/resetPassword' resetPasswordOnly = {true}
								authCheckFinished={props.authCheckFinished}/>

				<Layout>
					<BasePage/>
				</Layout>
			</Switch>;
	} else {
		routes =
			<Switch>
				<ContentRoute path = "/error" component = {ErrorsPage}/>
				<Authentication authCheckFinished={props.authCheckFinished}/>
			</Switch>
	}
	return routes;
}
