import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {FormattedMessage, useIntl} from "react-intl";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {MultiSelect} from "primereact/multiselect";
import {makeStyles} from "@material-ui/styles";
import {HttpMethodObjects} from "./enum/HttpMethod";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";

import {RestRequestDeletionLoadingDialog, RestRequestsLoadingDialog} from "../../UI/LoadingDialog";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));
const RestRequestsDisplay = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [toBeRemovedRequest, setToBeRemovedRequest] = useState(null); // Label also needed
	const [toBeRemovedRequestConfirmationDialogOpen, setToBeRemovedRequestConfirmationDialogOpen] =
		useState(false);

	let requestCreatedSuccessfully = null;

	if(props.label){
		requestCreatedSuccessfully = <Alert elevation = {3} variant = "standard"
		                                    severity = "success" className = 'my-4'>
			{props.label}
		</Alert>
	}

	let errorMessages = props.errorMessages.map((error, index) => {
		return <Alert elevation = {3} variant = "standard" key={index}
		              severity = "error" className = 'mb-4'>
			{error}
		</Alert>
	});

	const methodFilter = <MultiSelect value = {props.selectedMethods}
	                                  options = {HttpMethodObjects}
	                                  onChange = {props.onMethodChange} optionLabel = "label"
	                                  optionValue = "value"
	                                  placeholder = {intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
	                                  className = "p-column-filter"/>;

	// Removing
	const closeRemoveConfirmationDialog = () => {
		setToBeRemovedRequestConfirmationDialogOpen(false);
	};

	const sprintActionsBodyTemplate = (rowData) => {

		return (
			<div className = {` ${classes.root}`}>
				<Link to = {`/rest-request/${rowData.id}`}
				      title = {intl.formatMessage({
					      id: "REST.REQUESTS.TABLE.ACTIONS.UPDATE.REST.REQUEST",
					      defaultMessage: 'Update Rest Request'
				      })}
				      className = "btn btn-icon btn-light btn-hover-primary btn-sm"
				>
					<span className = "svg-icon svg-icon-md svg-icon-primary">
					    <SVG title = {intl.formatMessage({
						    id: "REST.REQUESTS.TABLE.ACTIONS.UPDATE.REST.REQUEST",
						    defaultMessage: 'Update Rest Request'
					    })}
					         src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
					    />
					</span>
				</Link>
				<a title = {intl.formatMessage({
					id: "REST.REQUESTS.TABLE.HOVER.LINK.DELETE.REQUEST",
					defaultMessage: 'Delete Request'
				})}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				   onClick = {() => {
					   setToBeRemovedRequest(rowData);
					   setToBeRemovedRequestConfirmationDialogOpen(true);
				   }}
				>
				        <span className = "svg-icon svg-icon-md svg-icon-danger">
				          <SVG
					          title = {intl.formatMessage({
						          id: "REST.REQUESTS.TABLE.HOVER.LINK.DELETE.REQUEST",
						          defaultMessage: 'Delete Request'
					          })}
					          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
				        </span>
				</a>
			</div>
		);
	}

	let buttons =
		<div className='text-center'>
			<Link className = "btn btn-primary my-2"
			      to = '/rest-request/new'>
				<FormattedMessage id="REST.REQUEST.BUTTON.NEW"
				                  defaultMessage="New Request"/>
			</Link>
		</div>;

	let display = <div className='bg-white px-3'>
		<RestRequestsLoadingDialog/>
		<RestRequestDeletionLoadingDialog/>
		{buttons}
		{requestCreatedSuccessfully}
		{errorMessages}
		<DataTable value={props.restRequests} lazy ref={props.dt}
				   paginator first={props.lazyParams.first} rows={props.lazyParams.rows}
				   totalRecords={props.totalRecords} onPage={props.onPage} onSort={props.onSort}
				   sortField={props.lazyParams.sortField} sortOrder={props.lazyParams.sortOrder}
				   onFilter={props.onFilter}
				   filters={props.lazyParams.filters}

				   paginatorTemplate = "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
				   rowsPerPageOptions = {[10, 20, 50, 100]}
				   emptyMessage={intl.formatMessage({ id: "REST.REQUESTS.EMPTY.LIST",
					   defaultMessage: 'No Rest Requests'})}>
			<Column field="label" columnKey='label'
					header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.NAME",
						defaultMessage: 'Name'})}
					sortable filter
					filterPlaceholder={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER",
						defaultMessage: 'Search by name'})}
					filterMatchMode='contains'
			/>
			<Column field="method" columnKey='method'
					header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.METHOD",
						defaultMessage: 'Method'})}
					sortable filter
					filterPlaceholder="Search by Method"
					filterMatchMode='contains'
					filterElement={methodFilter}/>
			<Column field="uri" columnKey='uri'
					header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.URL",
						defaultMessage: 'URL'})}
					sortable filter
					filterPlaceholder={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.URL.FILTER.PLACEHOLDER",
						defaultMessage: 'Search by URL'})}
					filterMatchMode='contains'
			/>
			<Column key = 'actions'
					header = {intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.ACTIONS",
						defaultMessage: 'Actions'})}
					body = {sprintActionsBodyTemplate}
					className = 'p-text-right'
			/>
		</DataTable>

		<DeletionConfirmationDialog open={toBeRemovedRequestConfirmationDialogOpen} closeDialog={closeRemoveConfirmationDialog}
									message={<FormattedMessage id="REST.REQUESTS.TABLE.REMOVE.REQUEST"
															   defaultMessage='Remove Request'/> }
									name={toBeRemovedRequest ? `${toBeRemovedRequest.label}` : ''}
									deleteAction={() => {
										closeRemoveConfirmationDialog();
										props.deleteRequest(toBeRemovedRequest.id);
									}}
		/>

	</div>;


	return display;
}

export default RestRequestsDisplay;