/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo} from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {Link} from "react-router-dom";
import Bowser from "bowser";
import {FormattedMessage, useIntl} from "react-intl";

import {useHtmlClassService} from "../../../_metronic/layout";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import * as Role from "../../containers/Users/enum/Roles";

const getChartOptions = (layoutProps, successRate, title) => {
	const strokeColor = "#c4f0f0";

	// TODO {names:[], dates:[]} make a new array for categories
	//  {new Date(rowData.createdDate).toLocaleDateString()}

	const names = ['it 	1', 'it 2', 'it 3', 'it 4', 'it 5', 'it 6', 'it 7', 'it 8', 'it 9', 'it 10'];
	const datesRaw = ['2021-01-01T17:12', '2021-02-01T17:12', '2021-03-01T17:12', '2021-04-01T17:12', '2021-05-01T17:12',
		'2021-06-01T17:12', '2021-07-01T17:12', '2021-08-01T17:12', '2021-09-01T17:12', '2021-10-01T17:12'];
	const dates = successRate.dates.map((date) => new Date(date).toLocaleString())
	// const dates = [new Date('2021-01-01T12:17').toLocaleString()];


	const categories = [];
	for (let index in successRate.names) {
		categories.push(successRate.names[index] + ',\t' + dates[index]);
	}
	// console.log(categories);

	const options = {
		series: [
			{
				name: title,
				// data: [30, 45, 32, 70, 40, 40, 40, 88, 79, 83]
				data: successRate.rates

			}
		],
		chart: {
			type: "area",
			height: 200,
			toolbar: {
				show: false
			},
			zoom: {
				enabled: false
			},
			sparkline: {
				enabled: true
			},
			dropShadow: {
				enabled: true,
				enabledOnSeries: undefined,
				top: 5,
				left: 0,
				blur: 3,
				color: strokeColor,
				opacity: 0.5
			}
		},
		plotOptions: {},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		fill: {
			type: "solid",
			opacity: 0
		},
		stroke: {
			curve: "smooth",
			show: true,
			width: 3,
			colors: [strokeColor]
		},
		xaxis: {
			// categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
			categories: categories,
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			},
			labels: {
				show: false,
				style: {
					colors: layoutProps.colorsGrayGray500,
					fontSize: "12px",
					fontFamily: layoutProps.fontFamily
				}
			},
			crosshairs: {
				show: false,
				position: "front",
				stroke: {
					color: layoutProps.colorsGrayGray300,
					width: 1,
					dashArray: 3
				}
			}
		},
		yaxis: {
			min: 0,
			max: 110,
			labels: {
				show: false,
				style: {
					colors: layoutProps.colorsGrayGray500,
					fontSize: "12px",
					fontFamily: layoutProps.fontFamily
				}
			}
		},
		states: {
			normal: {
				filter: {
					type: "none",
					value: 0
				}
			},
			hover: {
				filter: {
					type: "none",
					value: 0
				}
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: "none",
					value: 0
				}
			}
		},
		tooltip: {
			style: {
				fontSize: "12px",
				fontFamily: layoutProps.fontFamily
			},
			y: {
				formatter: function (val) {
					return val + " %";
				}
			},
			marker: {
				show: false
			}
		},
		colors: ["transparent"],
		markers: {
			colors: layoutProps.colorsThemeBaseDanger,
			strokeColor: [strokeColor],
			strokeWidth: 3
		}
	};
	return options;
};

const SuccessRateWidget = props => {
	const intl = useIntl();

	const uiService = useHtmlClassService();

	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
			colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
			colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
			colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.success"),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily")
		};
	}, [uiService]);

	useEffect(() => {
		const element = document.getElementById("success_rate_chart");
		if (!element) {
			return;
		}

		const options = getChartOptions(layoutProps, props.successRate, intl.formatMessage({ id: "DASHBOARD.SUCCESS.RATE", defaultMessage: 'Success Rate'}));

		const chart = new ApexCharts(element, options);
		chart.render();
		return () => {
			chart.destroy();
		};
	}, [layoutProps]);

	let firstTile = <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
							<span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder-plus.svg")}/>
							</span>
		<Link to="/project/new" className="text-warning font-weight-bold font-size-h6">
			<FormattedMessage id="PROJECTS.BUTTON.NEW.PROJECT" defaultMessage='New Project'/>
		</Link>
	</div>;
	let secondTile = <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
							<span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}/>
							</span>
		<Link to="/users/new" className="text-primary font-weight-bold font-size-h6 mt-2">
			<FormattedMessage id="USERS.BUTTON.NEW.USER" defaultMessage='New User'/>
		</Link>
	</div>;

	switch (localStorage.getItem('Top-Role')) {
		case Role.ROLE_SUPER:
			break;
		case Role.ROLE_ADMIN:
			break;
		case Role.ROLE_MANAGER:
			firstTile = <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
				<span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")}/>
				</span>
				<Link to="/domains" className="text-warning font-weight-bold font-size-h6">
					<FormattedMessage id="CATEGORY.DOMAINS" defaultMessage='Domains'/>
				</Link>
			</div>;
			break;
		case Role.ROLE_TESTER:
			firstTile = <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
				<span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")}/>
				</span>
				<Link to="/domains" className="text-warning font-weight-bold font-size-h6">
					<FormattedMessage id="CATEGORY.DOMAINS" defaultMessage='Domains'/>
				</Link>
			</div>;
			secondTile = <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
			<span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
				{/*<SVG src={toAbsoluteUrl("/media/svg/icons/Media/Repeat.svg")}/>*/}
				<SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-3d.svg")}/>
			</span>
				<Link to="/sprints" className="text-primary font-weight-bold font-size-h6 mt-2">
					<FormattedMessage id="CATEGORY.SPRINTS" defaultMessage='Sprints'/>
				</Link>
			</div>;
			break;
		case Role.ROLE_USER:
			firstTile = <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
				<span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")}/>
				</span>
				<Link to="/domains" className="text-warning font-weight-bold font-size-h6">
					<FormattedMessage id="CATEGORY.DOMAINS" defaultMessage='Domains'/>
				</Link>
			</div>;
			secondTile = <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
			<span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
				{/*<SVG src={toAbsoluteUrl("/media/svg/icons/Media/Repeat.svg")}/>*/}
				<SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-3d.svg")}/>
			</span>
				<Link to="/sprints" className="text-primary font-weight-bold font-size-h6 mt-2">
					<FormattedMessage id="CATEGORY.SPRINTS" defaultMessage='Sprints'/>
				</Link>
			</div>;
			break;
	}
	if (props.canAddProjectLoading === false && props.canAddProject === false) {
		firstTile = <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
			<span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
				<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")}/>
			</span>
			<Link to="/domains" className="text-warning font-weight-bold font-size-h6">
				<FormattedMessage id="CATEGORY.DOMAINS" defaultMessage='Domains'/>
			</Link>
		</div>;
	}


	if (props.canAddUserLoading === false && props.canAddUser === false) {
		secondTile = <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
			<span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
				{/*<SVG src={toAbsoluteUrl("/media/svg/icons/Media/Repeat.svg")}/>*/}
				<SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-3d.svg")}/>
			</span>
			<Link to="/sprints" className="text-primary font-weight-bold font-size-h6 mt-2">
				<FormattedMessage id="CATEGORY.SPRINTS" defaultMessage='Sprints'/>
			</Link>
		</div>;
	}

	return (
		<div className={`card card-custom bg-gray-100 ${props.className}`}>
			{/* Header */}
			<div className="card-header border-0 bg-success py-5">
				<h3 className="card-title font-weight-bolder text-white">
					<FormattedMessage id="DASHBOARD.SUCCESS.RATE" defaultMessage='Success Rate'/>
				</h3>
				{/*<div className="card-toolbar">*/}
				{/*	<Dropdown className="dropdown-inline" drop="down" alignRight>*/}
				{/*		<Dropdown.Toggle*/}
				{/*			className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"*/}
				{/*			variant="transparent"*/}
				{/*			id="dropdown-toggle-top">*/}
				{/*			Export*/}
				{/*		</Dropdown.Toggle>*/}
				{/*		<Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">*/}
				{/*			<DropdownMenu2/>*/}
				{/*		</Dropdown.Menu>*/}
				{/*	</Dropdown>*/}
				{/*</div>*/}
			</div>
			{/* Body */}
			<div className="card-body p-0 position-relative overflow-hidden">
				{/* Chart */}
				<div id="success_rate_chart" className="card-rounded-bottom bg-success" style={{height: "200px"}}/>

				{/* Stat */}
				<div className="card-spacer mt-n25">
					<div className="row m-0">
						{firstTile}
						{secondTile}
					</div>
					<div className="row m-0">
						<div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
							<span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
							</span>
							<a href="https://docs.tigmat.io/" className="text-danger font-weight-bold font-size-h6 mt-2"
							   target='_blank'>
								<FormattedMessage id="HEADER.DOCUMENTATION" defaultMessage='Documentation'/>
							</a>
						</div>
						<div className="col bg-light-success px-6 py-8 rounded-xl">
							<span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Swiss-knife.svg")}/>
							</span>
							<a href={Bowser.getParser(window.navigator.userAgent).getBrowser().name === 'Chrome' ?
								'https://chrome.google.com/webstore/detail/tigmat-ide-the-functional/chjoocjagcohmngaiciohfcjohfdaemg' :
								'https://addons.mozilla.org/en-US/firefox/addon/tigmat-ide/'}
							   className="text-success font-weight-bold font-size-h6 mt-2"
							   target='_blank'>
								<FormattedMessage id="HEADER.PLUGIN" defaultMessage='Plugin'/>
							</a>
						</div>
					</div>
				</div>

				{/* Resize */}
				<div className="resize-triggers">
					<div className="expand-trigger">
						<div style={{width: "411px", height: "461px"}}/>
					</div>
					<div className="contract-trigger"/>
				</div>
			</div>
		</div>
	);
}

export default SuccessRateWidget;