import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	loading: false,
	error: null,
	report: null,

	screenshotLoading: false,
	screenshotError: null,
	imagekey: null,
	imageBlob: null
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.LOAD_REPORT_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_REPORT_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				report: action.report
			});
		case actionTypes.LOAD_REPORT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				report: null
			});
		case actionTypes.DOWNLOAD_SCREENSHOT_START:
			return updateObject(state, {
				screenshotLoading: true
			});
		case actionTypes.DOWNLOAD_SCREENSHOT_SUCCESS:
			return updateObject(state, {
				screenshotLoading: false,
				screenshotError: null,
				imagekey: action.imagekey,
				imageBlob: action.imageBlob
			});
		case actionTypes.DOWNLOAD_SCREENSHOT_FAIL:
			return updateObject(state, {
				screenshotLoading: false,
				screenshotError: action.error,
				imageBlob: null
			});
		default: {
			return state;
		}
	}
}

export default reducer;