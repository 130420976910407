import {Col, Container, Row} from "react-bootstrap";
import {makeStyles, TextField} from "@material-ui/core";
import {useIntl} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import {changeText} from "../Shared";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	}
}));

const AuthBasicForm = props => {
	const intl = useIntl();
	const classes = useStyles();

	// mounted is used when tab Authentication is not shown/mounted which means
	// validation in useEffect props.validate.basic === true will act on an unmounted component => warning
	// solution: useRef. if false => don't validate / act on state
	const mounted = useRef(false);

	useEffect(() => {
		// mounted:
		mounted.current = true;
		// submit clicked and auth type is basic
		if(props.validate.basic === true){
			checkAllInputsValidity();
		}
		return () => mounted.current = false;
	}, []);

	const [username, setUsername] = useState({
		value: props.basic.username,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [showPassword, setShowPassword] =  useState(false);
	const [password, setPassword] = useState({
		value: props.basic.password,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const usernameChangeHandler = (event) => {
		const updatedName = changeText(username, event.target.value, 'basic-username', intl);
		setUsername(updatedName);
	}

	const passwordChangeHandler = (event) => {
		const updatedPassword = changeText(password, event.target.value, 'basic-password', intl);
		setPassword(updatedPassword);
	}

	const onBlur = () => {
		const basic = {
			username: username.value,
			password: password.value
		}
		// console.log('blur', core);
		props.setBasic(basic);
		props.createAuthObject(basic.username, basic.password);
	}

	const checkAllInputsValidity = () => {
		let allValid = true;

		const updatedUsername = changeText(username, username.value, 'basic-username', intl);
		if(mounted.current === true ){
			setUsername(updatedUsername);
		}
		allValid = allValid && updatedUsername.valid;

		const updatedPassword = changeText(password, password.value, 'basic-password', intl);
		if(mounted.current === true ){
			setPassword(updatedPassword);
		}
		allValid = allValid && updatedPassword.valid;

		return allValid;
	}
	props.registerValidateAuthBasicFormCallback(checkAllInputsValidity);

	let nameOnEnterKey = null;

	return <Container className='px-0'>
		<Row>
			<Col>
				<TextField
					id = "basic-username"  name = "basic-username"
					label = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.BASIC.USERNAME.LABEL",
						defaultMessage: 'Username'})}
					className = {`${classes.textField}`}
					value = {username.value}
					onChange = {usernameChangeHandler}
					autoComplete='new-password'
					onBlur={onBlur}
					onKeyDown={nameOnEnterKey}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.BASIC.USERNAME.PLACEHOLDER",
						defaultMessage: 'admin'})}
					error = {!username.valid}
					helperText = {username.validationMessage}
				/>
			</Col>
		</Row>
		<Row>
			<Col>
				<TextField
					id = "basic-password"
					name = "basic-password"
					type={showPassword ? 'text' : 'password'}
					label = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.BASIC.PASSWORD.LABEL",
						defaultMessage: 'Password' })}
					className = {`${classes.textField}`}
					value = {password.value}
					onChange = {passwordChangeHandler}
					autoComplete='new-password'
					onBlur={onBlur}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.BASIC.PASSWORD.PLACEHOLDER",
						defaultMessage: 'pizoaeuefjpiq' })}
					error = {!password.valid}
					helperText = {password.validationMessage}
					InputProps={{endAdornment: (<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword);}}
							>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>)
					}}
				/>
			</Col>
		</Row>
	</Container>

}

export default AuthBasicForm;