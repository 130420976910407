import React from "react";
import {
	AppBar,
	Button,
	Dialog,
	FormControl,
	FormHelperText,
	Input,
	InputLabel,
	MenuItem,
	Select,
	Slide,
	TextField,
	Toolbar,
	Typography
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const AddForm = props => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const save = () => {
		if(props.submitInstructionHandler()){
			handleClose();
		}
	}

	let component = null;
	if(props.showComponent){
		component = <Row>
			<Col>
				<FormControl className = {`${classes.formControl} row d-flex mb-6`}
				             error = {!props.component.valid}>
					<InputLabel htmlFor = "select-component">
						<FormattedMessage id="INSTRUCTION.FORM.LABEL.COMPONENT"
						                  defaultMessage='Component'/>
					</InputLabel>
					<Select
						value = {props.component.component}
						onChange = {(event) => props.componentSelectHandler(event)}
						input = {<Input id = "select-component"/>}
					>

						{props.component.components.map(component => {
							return <MenuItem key = {component}
							                 value = {component}>{component}</MenuItem>
						})}
					</Select>
					<FormHelperText>{props.component.validationMessage}</FormHelperText>
				</FormControl>
			</Col>
		</Row>;
	}

	let identifier = null;
	if(props.showIdentifier){
		identifier = <Row>
			<Col>
				<TextField
					id = "identifier"
					name = "identifier"
					label = {intl.formatMessage({ id: "INSTRUCTION.FORM.LABEL.IDENTIFIER",
						defaultMessage: 'Component Identifier'})}
					className = {`mb-6 ${classes.textField}`}
					value = {props.identifier.value}
					onChange = {event => props.identifierChangeHandler(event, 'identifier')}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({ id: "INSTRUCTION.FORM.PLACEHOLDER.IDENTIFIER",
						defaultMessage: 'Identifier'})}
					error = {!props.identifier.valid}
					helperText = {props.identifier.validationMessage}
				/>
			</Col>
		</Row>;
	}

	let value = null;
	if(props.showValue){
		value = <Row>
			<Col>
				<TextField
					id = "value"
					name = "value"
					label = {intl.formatMessage({ id: "INSTRUCTION.FORM.LABEL.VALUE",
						defaultMessage: 'Value'})}
					className = {`mb-6 ${classes.textField}`}
					value = {props.value.value}
					onChange = {event => props.valueChangeHandler(event, 'value')}
					margin = "normal"
					variant = "outlined"
					placeholder = {intl.formatMessage({ id: "INSTRUCTION.FORM.PLACEHOLDER.VALUE",
						defaultMessage: 'value'})}
					error = {!props.value.valid}
					helperText = {props.value.validationMessage}
				/>
			</Col>
		</Row>;
	}

	let toComponent = null;
	if(props.showToComponent){
		toComponent = <Row>
			<Col>
				<FormControl className = {`${classes.formControl} row d-flex mb-6`}
				             error = {!props.toComponent.valid}>
					<InputLabel htmlFor = "select-to-component">
						<FormattedMessage id="INSTRUCTION.FORM.LABEL.TO.COMPONENT"
						                  defaultMessage='toComponent'/>
					</InputLabel>
					<Select
						value = {props.toComponent.toComponent}
						onChange = {(event) => props.toComponentSelectHandler(event)}
						input = {<Input id = "select-to-component"/>}
					>

						{props.toComponent.components.map(component => {
							return <MenuItem key = {component}
							                 value = {component}>{component}</MenuItem>
						})}
					</Select>
					<FormHelperText>{props.toComponent.validationMessage}</FormHelperText>
				</FormControl>
			</Col>
		</Row>;
	}

	return (
		<>
			<Button variant = "contained"
			        size = 'small'
			        startIcon = {<AddIcon/>}
			        onClick = {handleClickOpen}
			        className = ''>
				<FormattedMessage id="TEST.ACTIONS.ADD.INSTRUCTION"
				                  defaultMessage='Instruction'/>
			</Button>
			<Dialog fullScreen open = {open} onClose = {handleClose} TransitionComponent = {Transition}>
				<AppBar className = {classes.appBar}>
					<Toolbar>
						<IconButton edge = "start" color = "inherit" onClick = {handleClose} aria-label = "close">
							<CloseIcon/>
						</IconButton>
						<Typography variant = "h6" className = {classes.title}>
							<FormattedMessage id="INSTRUCTION.TITLE"
							                  defaultMessage='Instruction'/>
						</Typography>
						<Button autoFocus color = "inherit" onClick = {save}>
							<FormattedMessage id="BUTTON.SAVE"
							                  defaultMessage='Save'/>
						</Button>
					</Toolbar>
				</AppBar>
				<div className = "row">
					<div className = "col-md-12 gutter-b">
						<Card>
							<Card.Body>
								{/*<form className = {classes.container} noValidate autoComplete = "off"*/}
								{/*      onSubmit = {props.submitDomainHandler}>*/}

									<Container>
										<Row>
											<Col>
												<TextField
													id = "name"
													name = "name"
													label = {intl.formatMessage({ id: "INSTRUCTION.FORM.LABEL.NAME",
														defaultMessage: 'Instruction Name'})}
													className = {`mb-6 ${classes.textField}`}
													value = {props.name.value}
													onChange = {event => props.nameChangeHandler(event, 'name')}
													margin = "normal"
													variant = "outlined"
													placeholder = {intl.formatMessage({ id: "INSTRUCTION.FORM.PLACEHOLDER.NAME",
														defaultMessage: 'Elements'})}
													error = {!props.name.valid}
													helperText = {props.name.validationMessage}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormControl className = {`${classes.formControl} row d-flex mb-6`}
												             error = {!props.action.valid}>
													<InputLabel htmlFor = "select-action">
														<FormattedMessage id="INSTRUCTION.FORM.LABEL.ACTION"
														                  defaultMessage='Action'/>
													</InputLabel>
													<Select
														value = {props.action.action}
														onChange = {(event) => props.actionSelectHandler(event)}
														input = {<Input id = "select-action"/>}
													>

														{props.action.actions.map(action => {
															return <MenuItem key = {action.name}
															                 value = {action.name}>{action.label}</MenuItem>
														})}
													</Select>
													<FormHelperText>{props.action.validationMessage}</FormHelperText>
												</FormControl>
											</Col>
										</Row>
										{component}
										{identifier}
										{toComponent}
										{value}

									</Container>
								{/*</form>*/}
							</Card.Body>
						</Card>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default AddForm;