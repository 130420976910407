import {put, select} from "redux-saga/effects";
import {push} from "connected-react-router";

import * as actions from "../actions";
import axios from "../../axios/axios";
import {loadProject, loadProjectViewPort} from "./project";

export function* loadUsers(action) {
	yield put(actions.loadUsersStart());
	try {

		const response = yield axios.get(`/users/${action.username}/page`, {
			params: {...action.config}
		});
		// console.log('response', response.data);

		// yield put(actions.loadUsersSuccess(response.data));
		yield put(actions.loadUsersSuccess(response.data.content, //TODO when the back is done
			response.data.totalElements));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadUsersFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadUsersFail('Error Loading Users, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadUsersFail(error.message));
		}
	}
}

export function* loadAllUsers(action) {
	yield put(actions.loadAllUsersStart());
	try {

		const response = yield axios.get(`/users/${action.username}/all`);
		// console.log('response All Users', response.data);

		yield put(actions.loadAllUsersSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadAllUsersFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadAllUsersFail('Error Loading All Users, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadAllUsersFail(error.message));
		}
	}
}

export function* loadUser(action) {
	yield put(actions.loadUserStart());
	try {
		const response = yield axios.get(`/users/${action.username}`);
		// console.log('response', response.data);

		yield put(actions.loadUserSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadUserFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadUserFail('Error Loading The User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadUserFail(error.message));
		}
	}
}

export function* toggleActivation(action) {
	yield put(actions.toggleActivationStart());
	try {
		yield axios.put(`/users/${action.username}/activate`, action.active, {headers: {"Content-Type": "application/json"}});

		yield put(actions.toggleActivationSuccess(action.users, action.id));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.toggleActivationFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.toggleActivationFail('Error Activating The User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.toggleActivationFail(error.message));
		}
	}
}

export function* updateUser(action) {
	yield put(actions.updateUserStart());
	try {
		const response = yield axios.put(`/users`, action.user);
		// console.log('response', response.data);

		yield put(actions.updateUserSuccess(response.data));
		// if user prefered project is is changed, then must call login success
		// so that state auth.loggedin user gets updated.
		// Change in memory
		if(action.loggedInUser){
			yield put(actions.loginSuccess(response.data.login, response.data));
		}
		if(action.redirect){
			if(action.redirect !== true){
				yield put(push(`/dashboard`));
				return;
			}
			yield put(push(`/users`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateUserFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateUserFail('Error Updating The User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateUserFail(error.message));
		}
	}
}

export function* updateUserCICD(action) {
	yield put(actions.updateUserCICDStart());
	try {
		const response = yield axios.put(`/users/cicd`, action.user);
		// console.log('response', response.data);

		yield put(actions.updateUserCICDSuccess(response.data));
		// console.log('response', response.data.login);
		yield put(push(`/user/${response.data.login}`));


	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateUserCICDFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateUserCICDFail('Error Updating The User CICD, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateUserCICDFail(error.message));
		}
	}
}

export function* registerAccountUser(action) {
	yield put(actions.registerAccountUserStart());
	try {
		const response = yield axios.post("/users", action.user);
		// console.log('response', response);
		if (response.status === 201) {
			yield put(actions.registerAccountUserSuccess());
			yield put(push(`/users`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.registerAccountUserFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.registerAccountUserFail('Error Creating The User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.registerAccountUserFail(error.message));
		}
	}
}

export function* registerAccountUserCICD(action) {
	yield put(actions.registerAccountUserCICDStart());
	try {
		const response = yield axios.post("/users/cicd", action.user);
		// console.log('response', response);
		if (response.status === 201) {
			yield put(actions.registerAccountUserCICDSuccess());
			yield put(push(`/user/${action.user.login}`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.registerAccountUserCICDFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.registerAccountUserCICDFail('Error Creating The CICD User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.registerAccountUserCICDFail(error.message));
		}
	}
}

export function* deleteUser(action) {
	yield put(actions.deleteUserStart());
	try {
		yield axios.delete(`/users/${action.id}`);
		// console.log('response', response.data);

		yield put(actions.deleteUserSuccess(action.id));
		if(action.redirect){
			yield put(push(action.redirect));
		}

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteUserFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteUserFail('Error Deleting The User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteUserFail(error.message));
		}
	}
}

export function* updateMyAccount(action) {
	yield put(actions.updateMyAccountStart());
	try {
		let response = yield axios.put(`/users/myAccount/${action.user.id}`, action.user);
		// console.log('response', response.data);

		yield put(actions.updateMyAccountSuccess());

		response = yield axios.get(`/users/${localStorage.getItem('username')}`);
		// console.log('response', response.data);
		// state auth.loggedin user gets updated.
		// Change in memory
		yield put(actions.loginSuccess(localStorage.getItem('username'), response.data));

		if(action.redirect) {
			yield put(push(action.redirect));
		} else {
			yield put(push(`/user/${action.user.login}`));
		}

		// }
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateMyAccountFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateMyAccountFail('Error Updating User My Account, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateMyAccountFail(error.message));
		}
	}
}

export function* updatePreferredProject(action) {
	yield put(actions.updatePreferredProjectStart());
	try {
		yield axios.put(`/users/preferedProject/${action.userId}`, action.preferredId,
			{headers: {"Content-Type": "text/plain"}});
		// console.log('response', response.data);

		yield put(actions.updatePreferredProjectSuccess());

		const loggedInUser = yield select(state => state.authentication.loggedInUser);
		// console.log('loggedInUser from fancy select()', loggedInUser);
		const loggedInUserCopy = {...loggedInUser};
		// in case the new prefered project is null MEANING one exists and the user removed it => skip
		if(action.preferredId !== null){
			yield loadProject({id: action.preferredId, view: false});

			// update Store loggedInUser
			const project = yield select(state => state.project.project);
			// console.log('project from fancy select()', project);

			loggedInUserCopy.preferedProjectId = project.id;
			loggedInUserCopy.preferedProjectLabel = project.label;
			loggedInUserCopy.preferedProjectType = project.type;

			yield loadProjectViewPort({id: action.preferredId});
		} else {
			// update Store loggedInUser

			loggedInUserCopy.preferedProjectId = null;
			loggedInUserCopy.preferedProjectLabel = null;
			loggedInUserCopy.preferedProjectType = null;
		}

		yield put(actions.loginSuccess(loggedInUser.login, loggedInUserCopy));

		if(action.redirect === true){
			yield put(push(`/dashboard`));
		}

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updatePreferredProjectFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updatePreferredProjectFail('Error Updating Preferred Project, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updatePreferredProjectFail(error.message));
		}
	}
}

export function* canCreateUser(action) {
	yield put(actions.canCreateUserStart());
	try {
		const response = yield axios.get(`/users/can-add`);
		// console.log('response', response.data);

		yield put(actions.canCreateUserSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.canCreateUserFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.canCreateUserFail('Error Finding Out User Creation Possibility, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.canCreateUserFail(error.message, action.view));
		}
	}
}

export function* requestDeleteMyAccount(action) {
	yield put(actions.requestDeleteMyAccountStart());
	try {
		yield axios.delete(`/account/${action.userID}`);
		// console.log('response', response.data);

		yield put(actions.requestDeleteMyAccountSuccess());

		yield put(actions.logout());
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.requestDeleteMyAccountFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.requestDeleteMyAccountFail('Error Request Deleting My Account, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.requestDeleteMyAccountFail(error.message));
		}
	}
}

export function* closeAccount(action) {
	yield put(actions.closeAccountStart());
	try {
		yield axios.post(`/account/close/${action.key}`);
		// console.log('response', response.data);

		yield put(actions.closeAccountSuccess());
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.closeAccountFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.closeAccountFail('Error Closing Account, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.closeAccountFail(error.message));
		}
	}
}

export function* changeLanguage(action) {
	yield put(actions.changeLanguageStart());
	try {
		yield axios.put(`/users/langKey/${action.userId}`, action.language, {headers: {"Content-Type": "text/plain"}});
		// console.log('response', response.data);
		// const response = yield axios.get(`/users/profile/${action.userId}`);
		// console.log('response.data', response.data);
		yield put(actions.changeLanguageSuccess());

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.changeLanguageFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.changeLanguageFail('Error Updating Profile, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.changeLanguageFail(error.message));
		}
	}
}

export function* generateCICDKeys(action) {
	yield put(actions.generateCICDKeysStart());
	try {
		const response = yield axios.put(`/users/accessKey/${action.userId}`);
		// console.log('response.data', response.data);
		yield put(actions.generateCICDKeysSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.generateCICDKeysFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.generateCICDKeysFail('Error Geneareting CICD Keys, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.generateCICDKeysFail(error.message));
		}
	}
}

export function* loadCICDSecretKey(action) {
	yield put(actions.loadCICDSecretKeyStart());
	try {
		const response = yield axios.get(`/users/secretKey/${action.accessKey}`);
		// console.log('response.data', response.data);
		yield put(actions.loadCICDSecretKeySuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadCICDSecretKeyFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadCICDSecretKeyFail('Error Load CICD Secret Key, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadCICDSecretKeyFail(error.message));
		}
	}
}

export function* deleteCICDKeys(action) {
	yield put(actions.deleteCICDKeysStart());
	try {
		yield axios.delete(`/users/accessKey/${action.userId}`);
		// console.log('response.data', response.data);

		// nullify CICD

		yield put(actions.deleteCICDKeysSuccess());

		// refresh user
		const response = yield axios.get(`/users/${localStorage.getItem('username')}`);
		// console.log('user reloaded from deleteCICDKeys Saga', response.data);

		yield put(actions.loadUserSuccess(response.data));


	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteCICDKeysFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request);
			yield put(actions.deleteCICDKeysFail('Error Deleting CICD Keys, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteCICDKeysFail(error.message));
		}
	}
}