import React from "react";
import {FormattedMessage} from "react-intl";
import {Card} from "react-bootstrap";
import {Divider} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

import RestRequestCoreForm from "./RestRequestCoreForm";
import RestRequestActions from "./RestRequestActions";
import RestRequestTabs from "./RestRequestTabs";
import {RestRequestCreationLoadingDialog, RestRequestUpdatingLoadingDialog} from "../../UI/LoadingDialog";

const RestRequestDisplay = props => {

	let errorMessages = props.errorMessages.map((error, index) => {
		return <Alert elevation = {3} variant = "standard" key={index}
		              severity = "error" className = 'mb-4'>
			{error}
		</Alert>
	});

	let headerTitle;
	switch (props.type) {
		case 'create':
			headerTitle = <FormattedMessage id="REST.REQUEST.HEADER.TITLE.CREATE"
			                                defaultMessage='New Rest Request'/>;
			break;
		case 'update':
			headerTitle = <FormattedMessage id="REST.REQUEST.HEADER.TITLE.UPDATE"
			                                defaultMessage='Update Rest Request'/>;
			break;
		default:
			headerTitle = <FormattedMessage id="REST.REQUEST.HEADER.TITLE.CREATE"
			                                defaultMessage='New Rest Request'/>;
	}

	return (
		// <div className = {``}>
		<form  noValidate autoComplete = "off" onSubmit = {props.submitForm}>
			<RestRequestCreationLoadingDialog/>
			<RestRequestUpdatingLoadingDialog/>
			<Card>
				<Card.Header className='py-4 px-8'>
					<h2>{headerTitle}</h2>
				</Card.Header>
				<Card.Body className='py-4 px-8'>
					<RestRequestCoreForm core={props.core}
					                     setCore={props.setCore}
					                     registerValidateCoreFormCallback={props.registerValidateCoreFormCallback}/>
					<Divider className='m-3' />
					<RestRequestTabs validate={props.validate}
					                 description={props.description}
					                 setDescription={props.setDescription}
					                 registerValidateDescriptionFormCallback={props.registerValidateDescriptionFormCallback}

					                 parameters={props.parameters}
					                 setParameters={props.setParameters}

					                 selectTab={props.selectTab}
					                 setSelectTab={props.setSelectTab}

					                 headers={props.headers}
					                 setHeaders={props.setHeaders}

					                 body = {props.body}
					                 setBody = {props.setBody}

					                 authentication = {props.authentication}
					                 setAuthentication = {props.setAuthentication}
					                 registerValidateAuthBasicFormCallback={props.registerValidateAuthBasicFormCallback}

					                 registerValidateAuthTokenFormCallback={props.registerValidateAuthTokenFormCallback}
					                 registerValidateAuthAPIKeyFormCallback={props.registerValidateAuthAPIKeyFormCallback}

					                 assertions={props.assertions}
					                 setAssertions={props.setAssertions}

									 responseCollection={props.responseCollection}
									 setResponseCollection={props.setResponseCollection}
									 registerValidateResponseCollectionFormCallback={props.registerValidateResponseCollectionFormCallback}
					/>

				</Card.Body>
				{errorMessages}
				<Card.Footer className='py-4 px-8'>
					<RestRequestActions type= {props.type} updatingLoading={props.updatingLoading}/>
				</Card.Footer>
			</Card>
		</form>
		// </div>
	);
}

export default RestRequestDisplay;