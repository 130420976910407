import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import AddForm from "../../components/Sprint/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";

const UpdateSprint = (props) => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage: null
	});

	const [previousNameAndDescription, setPreviousNameAndDescription] = useState({name: '', description: ''});

	useEffect(() => {
		if (props.sprint) {
			const updatedName = {...name};
			updatedName.value = props.sprint.label;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = props.sprint.description ? props.sprint.description : '';
			setDescription(updatedDescription);

			// Save Original Values in case there is an error on update
			setPreviousNameAndDescription({name: props.sprint.label, description: props.sprint.description ? props.sprint.description : ''});

			document.title = intl.formatMessage({ id: "PAGE.TITLE.UPDATE.SPRINT",
				defaultMessage: 'Update Sprint: '}) + props.sprint.label;
		}
	}, [props.sprint])

	useEffect(() => {
		if (props.error) {
			const updatedName = {...name};
			updatedName.value = previousNameAndDescription.name;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = previousNameAndDescription.description;
			setDescription(updatedDescription);
		}
	}, [props.error]);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}

		return allValid;
	}

	const submitSprintHandler = (event) => {
		event.preventDefault();

		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const sprint = {
			label: {value: name.value},
			description: {value: description.value}
		};
		const stateSprint = {
			label: name.value,
			description: description.value
		};
		const updatedSprint = {...props.sprint};
		updatedSprint.label = name.value;
		updatedSprint.description = description.value;
		props.updateSprint(updatedSprint);
		props.onUpdateSprint(props.sprint.id, sprint, stateSprint);
	}

	return (
		<AddForm name = {name}
		         description = {description}
		         nameChangeHandler = {nameChangeHandler}
		         descriptionChangeHandler = {descriptionChangeHandler}
		         submitSprintHandler = {submitSprintHandler}
		         {...props}
		         error={props.error}
		         type = 'update'/>
	);
}

const mapStateToProps = state => {
	return {
		loading: state.sprint.updatingLoading,
		error: state.sprint.updatingError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateSprint: (id, sprint, stateSprint) => dispatch(actions.updateSprint(id, sprint, stateSprint)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSprint);