import {checkSelectionValidity, checkTimeValidity, checkValidity} from "../../utility/utility";
import {UINames} from "../../containers/Project/Messages";

export const changeText = (inputTextState, value, idMessageTranslation, intl) => {
	const updatedInputTextState = {...inputTextState};
	updatedInputTextState.value = value;
	const [valid, message] = checkValidity(updatedInputTextState, UINames(idMessageTranslation, intl), intl);
	updatedInputTextState.valid = valid;
	updatedInputTextState.validationMessage = message;
	return updatedInputTextState;
}

export const selectElement = (component, value, idMessageTranslation, valueHolder, intl) => {
	const updatedComponent = {...component};
	updatedComponent[valueHolder] = value;
	[updatedComponent.valid, updatedComponent.validationMessage] =
		checkSelectionValidity(updatedComponent, valueHolder, UINames(idMessageTranslation, intl), intl);
	return updatedComponent;
};

export const apkUploadHandler = (component, value, valueName, label, intl) => {
	const updatedComponent = {...component};
	// updatedComponent.value = event.target.value;
	// console.log('event.target.files[0]', event.target.files[0]);
	let touched = false;
	if(value){
		updatedComponent.value = value;
		updatedComponent.label = valueName;
		const [valid, message] = checkValidity(updatedComponent, label, intl);
		updatedComponent.valid = valid;
		updatedComponent.validationMessage = message;
		touched = true;
	}
	return [updatedComponent, touched];
};

export const updateStartTimeState = (component, time, valueHolder, label, intl) => {
	const updatedComponent = {...component};

	updatedComponent[valueHolder] = time;
	const [valid, message] = checkTimeValidity(updatedComponent, label, intl);
	updatedComponent.valid = valid;
	updatedComponent.validationMessage = message;

	return updatedComponent;
};