import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Col, Container, Dropdown, Row} from "react-bootstrap";
import {Button, Chip} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";

import * as Role from '../../containers/Users/enum/Roles';
import {UserLoadingDialog} from "../../UI/LoadingDialog";
import {DropdownCustomToggler} from "../../../_metronic/_partials/dropdowns";
import {getTopRole, getTopRoleObjectForDisplay} from "./Shared";
import {history} from "../../../redux/store";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";
import * as Roles from "../../containers/Users/enum/Roles";

const useStyles = makeStyles((theme) => ({
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const Display = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    const [hideSecretKey, setHideSecretKey] = useState(true);

    const [executed, setExecuted] = useState(false);
    useEffect(() => {
        if(executed === false){
            if (props.secretKey) {
                setHideSecretKey(false);
                setExecuted(true);
            }
        }
    }, [props.secretKey])

    useEffect(() => {
        if (localStorage.getItem('Top-Role') === Role.ROLE_SUPER) {
            setShowStatus(true);
        }
    }, []);

    useEffect(() => {
        if (props.user) {
            document.title = intl.formatMessage({id: "PAGE.TITLE.USER", defaultMessage: 'User: '}) +
                props.user.login;
        }
    }, [props.user]);

    if (!props.user) {
        return <UserLoadingDialog/>
    }

    let fullName = '';//props.user.firstName + ' ' + props.user.lastName
    if (typeof props.user.firstName !== 'undefined') {
        fullName = props.user.firstName;
    }
    if (typeof props.user.lastName !== 'undefined') {
        fullName = fullName + ' ' + props.user.lastName;
    }
    const company = props.user.companyName ? props.user.companyName : null;

    //Super

    // I need nothing. should be working for itself.
    // now for below user. it must work.$
    // logedUser from back. get Role Top role
    // then check if equall => null. if not enable

    let disableToggle, cursor = '';
    if (props.disableToggle === null) {
        disableToggle = null;
        cursor = 'default';
    } else {
        disableToggle = () => props.disableToggle(props.user);
        cursor = 'pointer';
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let address = null;
    if (getTopRole(props.user) === Role.ROLE_ADMIN) {
        const addressDTO = props.user.addressDTO;
        address = (addressDTO.streetNumber ? addressDTO.streetNumber + ' ' : '') +
            (addressDTO.route ? addressDTO.route + ' ' : '') +
            (addressDTO.locality ? addressDTO.locality + ', ' : '') +
            (addressDTO.state ? addressDTO.state + ', ' : '') +
            (addressDTO.postalCode ? addressDTO.postalCode + ', ' : '') +
            (addressDTO.countryName ? addressDTO.countryName : '');
    }

    let activationStatusTitle;
    if (props.user.activated) {
        activationStatusTitle = 'Active';
    } else {
        activationStatusTitle = 'Suspended';
    }

    let activeStyle;
    if (props.user.activated) {
        activeStyle = `label label-lg label-light-success label-inline mr-10`;
    } else {
        activeStyle = `label label-lg label-light-danger label-inline mr-10`;
    }

    let removeDropDownItem = <li className="navi-item">
        <a onClick={handleClickOpen} className="navi-link">
            <span className="navi-icon"><i className="flaticon2-delete"/></span>
            <span className="navi-text"><FormattedMessage id="BUTTON.REMOVE" defaultMessage='Remove'/></span>
        </a>
    </li>;

    if (props.loggedInUser === true) {
        removeDropDownItem = null;
    }

    let showCreatedBy = true;
    if (!props.user.createdBy || props.user.createdBy === 'anonymousUser') {
        showCreatedBy = false;
    }

    let showModifiedBy = true;
    if (!props.user.lastModifiedBy) {
        showModifiedBy = false;
    }

    // console.log('props.secretKey', props.secretKey);
    // console.log('props.CICDKeys', props.CICDKeys);
    // console.log('displayBothCICDKeys', props.displayBothCICDKeys);


    const toggleDisplaySecretKey = () => {
        if (props.CICDKeys) {
            setHideSecretKey(!hideSecretKey);
        } else if(props.user.accessKey && props.secretKey === null){
            props.loadCICDSecretKey();
        } else if(props.user.accessKey && props.secretKey !== null){
            setHideSecretKey(!hideSecretKey);
        }
    };

    let CICDKeysDisplay = null;

    let generateButtonLabel = intl.formatMessage({id: "USER.CICD.BUTTON.GENERATE", defaultMessage: 'Generate'});


    if (props.CICDKeys && props.displayBothCICDKeys === true) {
        generateButtonLabel = intl.formatMessage({id: "USER.CICD.BUTTON.REGENERATE", defaultMessage: 'Regenerate'});
        CICDKeysDisplay = <>
            <Row>
                <Col sm="3" xl='3'><FormattedMessage id="USER.CICD.ACCESS" defaultMessage='Access'/></Col>
                <Col sm="8" xl='8'>{props.CICDKeys.accessKey}</Col>
            </Row>
            <Row>
                <Col sm="3" xl='3'><FormattedMessage id="USER.CICD.SECRET" defaultMessage='Secret'/></Col>
                <Col>{hideSecretKey === true ? '*******' : props.CICDKeys.secretKey}</Col>
                <Col sm="auto" xl='auto' className=''>
					<span className="switch switch-outline switch-icon switch-success">
						<label>
							<input type="checkbox" checked={!hideSecretKey} onChange={(one) => {
                                // console.log('one.target.checked', one.target.checked);
                                toggleDisplaySecretKey();
                            }}/>
							<span/>
						</label>
					</span>
                    {/*<Button onClick={() => setHideSecretKey(!hideSecretKey)}>Toggle Display Secret Key</Button>*/}
                </Col>
            </Row>
        </>;
    } else if (props.user.accessKey) {
        generateButtonLabel = intl.formatMessage({id: "USER.CICD.BUTTON.REGENERATE", defaultMessage: 'Regenerate'});
        CICDKeysDisplay = <>
            <Row>
                <Col sm="3" xl='3'><FormattedMessage id="USER.CICD.ACCESS" defaultMessage='Access'/></Col>
                <Col sm="8" xl='8'>{props.user.accessKey}</Col>
            </Row>
            <Row>
                <Col sm="3" xl='3'><FormattedMessage id="USER.CICD.SECRET" defaultMessage='Secret'/></Col>
                <Col>{hideSecretKey === true ? '*******' : props.secretKey}</Col>
                <Col sm="auto" xl='auto' className=''>
					<span className="switch switch-outline switch-icon switch-success">
						<label>
							<input type="checkbox" checked={!hideSecretKey} onChange={(one) => {
                                // console.log('one.target.checked', one.target.checked);
                                toggleDisplaySecretKey();
                            }}/>
							<span/>
						</label>
					</span>
                    {/*<Button variant='contained' color='primary' onClick={() => toggleDisplaySecretKey()}>Toggle Display Secret Key</Button>*/}
                </Col>
            </Row>
        </>;
    }
    let CICDSection = null;
    if ((localStorage.getItem('Top-Role') === Roles.ROLE_ADMIN || localStorage.getItem('Top-Role') === Roles.ROLE_MANAGER ||
        localStorage.getItem('Top-Role') === Roles.ROLE_TESTER) && props.loggedInUser === true) {
        CICDSection = <Col xl='6'>
            <Container className='bg-white h-100 p-5 font-size-h3 rounded'>
                <Row className='text-center'>
                    <Col>
                        <h2><FormattedMessage id="USER.CICD.HEADER" defaultMessage='CICD Keys'/></h2>
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col className={classes.buttons}>
                        <Button variant="contained" color='secondary' onClick={() => {
                            setHideSecretKey(false);
                            props.generateCICDKeys();
                        }}>
                            {generateButtonLabel}
                        </Button>
                        <Button variant="contained" color='default' onClick={() => {
                            setHideSecretKey(true);
                            props.deleteCICDKeys();
                        }}>
                            <FormattedMessage id="BUTTON.DELETE" defaultMessage='Delete'/>
                        </Button>
                    </Col>
                </Row>
                {CICDKeysDisplay}
            </Container>
        </Col>;
    }
    return (
        <div className="">
            <Container>
                <Row>
                    <Col xl={`${props.loggedInUser === true ? 6 : 12}`}>
                        <div className="card card-custom card-stretch">
                            <div className="card-body pt-4 px-0">
                                <Container className=''>
                                    {props.deletionError &&
                                        <Alert elevation={2} variant="standard" severity="error" className='my-3'
                                               onClose={props.onHideDeletionError}>
                                            {props.deletionError}
                                        </Alert>}
                                    <Row>
                                        <Col className='px-1'>

                                            <div className="text-center">
                                                <h1 className="font-weight-bolder text-dark-75 text-hover-primary">
                                                    {fullName}
                                                </h1>
                                                {company &&
                                                    <h2 className="font-weight-bolder text-dark-75 text-hover-success">
                                                        {company}
                                                    </h2>}
                                                <span className="text-dark-75 d-inline mr-30 font-size-h4 ">
								{props.user.login}
							</span>
                                                <Chip label={getTopRoleObjectForDisplay(props.user.authorities).label}
                                                      color='primary' className='text-white mr-30'/>
                                                {showStatus === true &&
                                                    <span className={activeStyle} style={{cursor: cursor}}
                                                          onClick={disableToggle}>
								{activationStatusTitle}
						    </span>}
                                                {/*<div className='mt-2'>*/}
                                                <Link to={`/user/${props.user.login}/edit`}
                                                      className="btn btn-sm btn-outline-primary font-weight-bold py-2 px-3 px-xxl-5 align-items-baseline">
                                                    <i className="flaticon2-edit icon-1x"/>
                                                    <FormattedMessage id="BUTTON.EDIT" defaultMessage='Edit'/>
                                                </Link>
                                                {/*</div>*/}
                                            </div>


                                            <div className="d-flex flex-center flex-wrap mt-6">
						<span className='font-weight-bolder font-size-lg mr-6'>
							<i className="flaticon2-new-email mr-2 font-size-lg"/>
                            {props.user.email}
						</span>
                                            </div>

                                            <div className="d-flex flex-md-center flex-wrap mt-14">
                                                <div className="mr-12 d-flex flex-column mb-7">
							<span className="d-block font-weight-bold mb-4 text-center">
								<FormattedMessage id="USER.DISPLAY.LABEL.CREATED" defaultMessage='Created'/>
							</span>
                                                    <span
                                                        className="bg-light-primary text-primary font-weight-bold px-12 py-2 rounded">
								{new Date(props.user.createdDate).toLocaleDateString()}
							</span>
                                                </div>
                                                {showCreatedBy && <div className="mr-12 d-flex flex-column mb-7">
							<span className="d-block font-weight-bold mb-4 text-center">
								<FormattedMessage id="USER.DISPLAY.LABEL.CREATED.BY" defaultMessage='Created By'/>
							</span>
                                                    <span
                                                        className="bg-light-danger text-danger font-weight-bold px-12 py-2 rounded">
								{props.user.createdBy}
							</span>
                                                </div>}

                                                <div className="mr-12 d-flex flex-column mb-7">
							<span className="d-block font-weight-bold mb-4 text-center">
								<FormattedMessage id="USER.DISPLAY.LABEL.LAST.MODIFIED" defaultMessage='Last Modified'/>
							</span>
                                                    <span
                                                        className="bg-light-primary text-primary font-weight-bold px-12 py-2 rounded">
								{new Date(props.user.lastModifiedDate).toLocaleDateString()}
							</span>
                                                </div>
                                                {showModifiedBy && <div className="mr-12 d-flex flex-column mb-7">
							<span className="d-block font-weight-bold mb-4 text-center">
								<FormattedMessage id="USER.DISPLAY.LABEL.MODIFIED.BY" defaultMessage='Modified By'/>
							</span>
                                                    <span
                                                        className="bg-light-danger text-danger font-weight-bold px-12 py-2 rounded">
								{props.user.lastModifiedBy}
							</span>
                                                </div>}
                                            </div>

                                            {address && <div className="d-flex flex-center flex-wrap mb-4">
						<span className='font-size-h1'>
							<i className="flaticon2-location mr-2 font-size-h1"/>
                            {address}
						</span>
                                            </div>}

                                            {props.return &&
                                                <div className='text-center'>
                                                    <Button variant="contained" className='mr-6'
                                                            startIcon={<ArrowBack/>}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                // if(localStorage.getItem('Top-Role') === Role.ROLE_TESTER || props.loggedInUser === true){
                                                                    history.goBack();
                                                                // } else {
                                                                //     history.push('/users');
                                                                // }
                                                            }}>
                                                        <FormattedMessage id="BUTTON.BACK" defaultMessage='Back'/>
                                                    </Button>
                                                </div>}

                                        </Col>
                                        <Col xs='auto'>
                                            <div className="d-flex justify-content-end">
                                                <Dropdown className="dropdown-inline">
                                                    <Dropdown.Toggle
                                                        id="dropdown-toggle-top"
                                                        as={DropdownCustomToggler}
                                                    >
                                                        <i className="ki ki-bold-more-hor"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                        <ul className="navi navi-hover">

                                                            {removeDropDownItem}

                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Col>
                                    </Row>

                                </Container>

                                <DeletionConfirmationDialog open={open} closeDialog={handleClose}
                                                            message={<FormattedMessage id="USERS.DIALOG.REMOVE.USER"
                                                                                       defaultMessage='Remove User '/>}
                                                            name={props.user ? `${props.user.firstName ? props.user.firstName : ''} ${props.user.lastName ? props.user.lastName : ''}` : ''}
                                                            deleteAction={() => {
                                                                handleClose();
                                                                props.delete();
                                                            }}
                                />
                            </div>
                        </div>
                    </Col>
                    {CICDSection}
                </Row>
            </Container>
        </div>

    );
}

export default Display;