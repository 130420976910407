import * as Role from '../../containers/Users/enum/Roles';
import {ROLE_USER} from '../../containers/Users/enum/Roles';
import {checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "../../containers/Users/Messages";
import {AuthenticationTypeObjects} from "./enum/Authorities";

export const getTopUser = users => {
	let topUser = null;
	let tmpRoleOrder = 0; // 10, 9, 8, 7, 6
	users.forEach((user, index) => {
		if( user.authorities.indexOf('ROLE_SUPER') >= 0) { // normally the oldest is the top dog
			tmpRoleOrder = 10;
			topUser = user;
		} else if( user.authorities.indexOf('ROLE_ADMIN') >= 0 ){
			if(tmpRoleOrder < 9){
				tmpRoleOrder = 9;
				topUser = user;
			}
		} else if( user.authorities.indexOf('ROLE_MANAGER') >= 0 ){
			if(tmpRoleOrder < 8){
				tmpRoleOrder = 8;
				topUser = user;
			}
		} else if( user.authorities.indexOf('ROLE_TESTER') >= 0 ){
			if(tmpRoleOrder < 7){
				tmpRoleOrder = 7;
				topUser = user;
			}
		} else if( user.authorities.indexOf('ROLE_USER') >= 0 ){
			if(tmpRoleOrder < 6){
				tmpRoleOrder = 6;
				topUser = user;
			}
		}
	});
	return topUser;
}

export const getTopRole = user => {
	if( user.authorities.indexOf(Role.ROLE_SUPER) >= 0) {
		return Role.ROLE_SUPER;
	} else if( user.authorities.indexOf(Role.ROLE_ADMIN) >= 0 ){
		return Role.ROLE_ADMIN;
	} else if( user.authorities.indexOf(Role.ROLE_MANAGER) >= 0 ){
		return Role.ROLE_MANAGER;
	} else if( user.authorities.indexOf(Role.ROLE_TESTER) >= 0 ){
		return Role.ROLE_TESTER;
	} else if( user.authorities.indexOf(Role.ROLE_USER) >= 0 ){
		return Role.ROLE_USER
	} else if( user.authorities.indexOf(Role.ROLE_CICD) >= 0 ){
		return Role.ROLE_CICD
	}
}

export const getTopRoleObject = user => {
	if( user.authorities.indexOf(Role.ROLE_ADMIN) >= 0 ){
		return AuthenticationTypeObjects[0];
	} else if( user.authorities.indexOf(Role.ROLE_MANAGER) >= 0 ){
		return AuthenticationTypeObjects[1];
	} else if( user.authorities.indexOf(Role.ROLE_TESTER) >= 0 ){
		return AuthenticationTypeObjects[2];
	} else if( user.authorities.indexOf(Role.ROLE_USER) >= 0 ){
		return AuthenticationTypeObjects[3];
	}
}

export const getCurrentUserAllowedUserCreationAuthorities = () => {
	const authoritiesExcludeAdmin = AuthenticationTypeObjects.slice(1, AuthenticationTypeObjects.length); // removes Admin
	switch (localStorage.getItem('Top-Role')){
		case Role.ROLE_USER:
			// console.log('topRole is user');
			authoritiesExcludeAdmin.splice(2, 1);
		case Role.ROLE_TESTER:
			// console.log('topRole is tester');
			authoritiesExcludeAdmin.splice(1, 1);
		case Role.ROLE_MANAGER:
			// console.log('topRole is manager');
			authoritiesExcludeAdmin.splice(0, 1);
	}
	// console.log('topRole is admin');
	// console.log('updatedAuthorities', updatedAuthorities);
	return authoritiesExcludeAdmin;
}

export const changeText = (inputTextState, value, idMessageTranslation, intl) => {
	const updatedInputTextState = {...inputTextState};
	updatedInputTextState.value = value;
	const [valid, message] = checkValidity(updatedInputTextState, UINames(idMessageTranslation, intl), intl);
	updatedInputTextState.valid = valid;
	updatedInputTextState.validationMessage = message;
	return updatedInputTextState;
}

export const selectElement = (component, value, idMessageTranslation, valueHolder, intl) => {
	const updatedComponent = {...component};
	updatedComponent[valueHolder] = value;
	[updatedComponent.valid, updatedComponent.validationMessage] =
		checkSelectionValidity(updatedComponent, valueHolder, UINames(idMessageTranslation, intl), intl);
	return updatedComponent;
};

// export const selectElements = (component, value, idMessageTranslation, valueHolder, intl) => {
// 	const updatedComponent = {...component};
// 	updatedComponent[valueHolder] = value;
// 	[updatedComponent.valid, updatedComponent.validationMessage] =
// 		checkArraySelectionValidity(updatedComponent, valueHolder, UINames(idMessageTranslation, intl), intl);
// 	return updatedComponent;
// };

//checkArraySelectionValidity


// For Users Display Table: should inculde CICD user, the original one should not be tempered with because the logic make assumptions
//  on the total number.
export const getTopRoleObjectForDisplay = authorities => {
	if( authorities.indexOf(Role.ROLE_SUPER) >= 0 ){
		return AllAuthenticationTypeObjectsDisplay[5];
	} else if( authorities.indexOf(Role.ROLE_ADMIN) >= 0 ){
		return AllAuthenticationTypeObjectsDisplay[0];
	} else if( authorities.indexOf(Role.ROLE_MANAGER) >= 0 ){
		return AllAuthenticationTypeObjectsDisplay[1];
	} else if( authorities.indexOf(Role.ROLE_TESTER) >= 0 ){
		return AllAuthenticationTypeObjectsDisplay[2];
	} else if( authorities.indexOf(Role.ROLE_USER) >= 0 ){
		return AllAuthenticationTypeObjectsDisplay[3];
	} else if( authorities.indexOf(Role.ROLE_CICD) >= 0 ){
		return AllAuthenticationTypeObjectsDisplay[4];
	}
}

// For Display
export const AllAuthenticationTypeObjectsDisplay = [
	{label: 'ADMIN', value: Role.ROLE_ADMIN},
	{label: 'MANAGER', value: Role.ROLE_MANAGER},
	{label: 'TESTER', value: Role.ROLE_TESTER},
	{label: 'USER', value: Role.ROLE_USER},
	{label: 'CICD', value: Role.ROLE_CICD},
	{label: 'SUPER', value: Role.ROLE_SUPER}
]