import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	loading: false,
	error: null,
	sessionIDs: null,
	outcome: null,

	currentPlan: null,
	currentPlanLoading: false,
	currentPlanError: null,

	availablePlans: null,
	availablePlansLoading: false,
	availablePlansError: null,

	checkPaymentLoading: false,
	checkPaymentError: null,

	cancelPaidSubscriptionLoading: false,
	cancelPaidSubscriptionError: null,

	changePaidSubscriptionLoading: false,
	changePaidSubscriptionError: null,

	availablePaidCustomerPlans : null,
	availablePaidCustomerPlansLoading: false,
	availablePaidCustomerPlansError: null,

	deletePaymentSessionsLoading: false,
	deletePaymentSessionsError: null,

	invoices: null,
	totalInvoices: 0,
	invoicesLoading: false,
	invoicesError: null
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		// case actionTypes.LOAD_SESSION_IDS_START:
		// 	return updateObject(state, {
		// 		loading: true,
		// 		sessionIDs: null,
		// 	});
		// case actionTypes.LOAD_SESSION_IDS_SUCCESS:
		// 	return updateObject(state, {
		// 		loading: false,
		// 		error: null,
		// 		sessionIDs: action.sessionIDs
		// 	});
		// case actionTypes.LOAD_SESSION_IDS_FAIL:
		// 	return updateObject(state, {
		// 		error: action.error,
		// 		loading: false,
		// 		sessionIDs: null
		// 	});
		// case actionTypes.VERIFY_PAYMENT_START:
		// 	return updateObject(state, {
		// 		loading: true,
		// 		error: null,
		// 		outcome: null
		// 	});
		// case actionTypes.VERIFY_PAYMENT_SUCCESS:
		// 	return updateObject(state, {
		// 		loading: false,
		// 		error: null,
		// 		outcome: action.outcome
		// 	});
		// case actionTypes.VERIFY_PAYMENT_FAIL:
		// 	return updateObject(state, {
		// 		loading: false,
		// 		error: action.error,
		// 		outcome: false
		// 	});
		case actionTypes.LOAD_CURRENT_PLAN_START:
			return updateObject(state, {
				currentPlan : null,
				currentPlanLoading: true,
				currentPlanError: null
			});
		case actionTypes.LOAD_CURRENT_PLAN_SUCCESS:
			return updateObject(state, {
				currentPlan : action.plan,
				currentPlanLoading: false,
			});
		case actionTypes.LOAD_CURRENT_PLAN_FAIL:
			return updateObject(state, {
				currentPlanLoading: false,
				currentPlanError: action.error
			});
		case actionTypes.LOAD_PLANS_START:
			return updateObject(state, {
				availablePlans : null,
				availablePlansLoading: true,
				availablePlansError: null
			});
		case actionTypes.LOAD_PLANS_SUCCESS:
			return updateObject(state, {
				availablePlans : action.plans,
				availablePlansLoading: false,
			});
		case actionTypes.LOAD_PLANS_FAIL:
			return updateObject(state, {
				availablePlansLoading: false,
				availablePlansError: action.error
			});
		case actionTypes.CHECK_PAYMENT_START:
			return updateObject(state, {
				checkPaymentLoading: true,
				checkPaymentError: null,
				// currentPlan: null // override currentPlan ??
			});
		case actionTypes.CHECK_PAYMENT_SUCCESS:
			return updateObject(state, {
				checkPaymentLoading: false,
				currentPlan: action.plan
			});
		case actionTypes.CHECK_PAYMENT_FAIL:
			return updateObject(state, {
				checkPaymentLoading: false,
				checkPaymentError: action.error
			});
		case actionTypes.CANCEL_PAID_SUBSCRIPTION_START:
			return updateObject(state, {
				cancelPaidSubscriptionLoading: true,
				cancelPaidSubscriptionError: null,
				// currentPlan: null // override currentPlan ??
			});
		case actionTypes.CANCEL_PAID_SUBSCRIPTION_SUCCESS:
			return updateObject(state, {
				cancelPaidSubscriptionLoading: false
			});
		case actionTypes.CANCEL_PAID_SUBSCRIPTION_FAIL:
			return updateObject(state, {
				cancelPaidSubscriptionLoading: false,
				cancelPaidSubscriptionError: action.error
			});
		case actionTypes.CHANGE_PAID_SUBSCRIPTION_START:
			return updateObject(state, {
				changePaidSubscriptionLoading: true,
				changePaidSubscriptionError: null,
				// currentPlan: null // override currentPlan ??
			});
		case actionTypes.CHANGE_PAID_SUBSCRIPTION_SUCCESS:
			return updateObject(state, {
				changePaidSubscriptionLoading: false,
				currentPlan: action.plan
			});
		case actionTypes.CHANGE_PAID_SUBSCRIPTION_FAIL:
			return updateObject(state, {
				changePaidSubscriptionLoading: false,
				changePaidSubscriptionError: action.error
			});
		case actionTypes.LOAD_PAID_CUSTOMER_PLANS_START:
			return updateObject(state, {
				availablePaidCustomerPlans : null,
				availablePaidCustomerPlansLoading: true,
				availablePaidCustomerPlansError: null
			});
		case actionTypes.LOAD_PAID_CUSTOMER_PLANS_SUCCESS:
			return updateObject(state, {
				availablePaidCustomerPlans : action.plans,
				availablePaidCustomerPlansLoading: false
			});
		case actionTypes.LOAD_PAID_CUSTOMER_PLANS_FAIL:
			return updateObject(state, {
				availablePaidCustomerPlansLoading: false,
				availablePaidCustomerPlansError: action.error
			});
		case actionTypes.DELETE_PAYMENT_SESSIONS_START:
			return updateObject(state, {
				deletePaymentSessionsLoading: true,
				deletePaymentSessionsError: null
			});
		case actionTypes.DELETE_PAYMENT_SESSIONS_SUCCESS:
			return updateObject(state, {
				deletePaymentSessionsLoading: false
			});
		case actionTypes.DELETE_PAYMENT_SESSIONS_FAIL:
			return updateObject(state, {
				deletePaymentSessionsLoading: false,
				deletePaymentSessionsError: action.error
			});
		case actionTypes.LOAD_INVOICES_START:
			return updateObject(state, {
				invoices : null,
				totalInvoices: 0,
				invoicesLoading: true,
				invoicesError: null
			});
		case actionTypes.LOAD_INVOICES_SUCCESS:
			return updateObject(state, {
				invoices : action.invoices,
				totalInvoices: action.totalInvoices,
				invoicesLoading: false
			});
		case actionTypes.LOAD_INVOICES_FAIL:
			return updateObject(state, {
				invoicesLoading: false,
				invoicesError: action.error
			});
		default: {
			return state;
		}
	}
}

export default reducer;