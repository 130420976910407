export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const PATCH = "PATCH";
export const DELETE = "DELETE";
export const HEAD = "HEAD";
export const OPTIONS = "OPTIONS";

// Form 1
export const HttpMethodObjects = [
	{label: 'Get', value: GET},
	{label: 'Post', value: POST},
	{label: 'Put', value: PUT},
	{label: 'Patch', value: PATCH},
	{label: 'Delete', value: DELETE},
	{label: 'Head', value: HEAD},
	{label: 'Options', value: OPTIONS},
]

// Form 2
export const HttpMethodValues = [
	"GET",
	"POST",
	"PUT",
	"PATCH",
	"DELETE",
	"HEAD",
	"OPTIONS"
]