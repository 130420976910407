import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";

import * as AuthenticationType from "./enum/AuthenticationType";
import {selectElement} from "./Shared";
import AuthBasicForm from "./form/AuthBasicForm";
import AuthTokenForm from "./form/AuthTokenForm";
import AuthAPIKeyForm from "./form/AuthAPIKeyForm";
import {HEADER} from "./enum/APIKeyType";

export const apiKeyDefault = {
	key: '',
	value: '',
	type: HEADER
};

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(4),
		minWidth: 120
	}
}));
const RestRequestTabAuthentication = props => {
	const classes = useStyles();
	const intl = useIntl();

	const [basic, setBasic] = useState({
		username: props.authentication.username,
		password: props.authentication.password
	});

	// const [isVariable, setIsVariable] = useState(false);
	const [token, setToken] = useState(props.authentication.token);
	const [tokenVariable, setTokenVariable] = useState(props.authentication.tokenVariable);

	const [APIKey, setAPIKey] = useState({
		key: props.authentication.apiKey.key,
		value: props.authentication.apiKey.value,
		type: props.authentication.apiKey.type
	});

	const [authenticationType, setAuthenticationType] = useState({
		authType: props.authentication.authType,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		types: props.forSuite === true ? AuthenticationType.AuthenticationTypeObjectsForSuite : AuthenticationType.AuthenticationTypeObjects
	});

	useEffect(() => {
		setBasic({username: props.authentication.username, password: props.authentication.password});
		setToken(props.authentication.token);
		setTokenVariable(props.authentication.tokenVariable);
		setAPIKey({
			key: props.authentication.apiKey.key,
			value: props.authentication.apiKey.value,
			type: props.authentication.apiKey.type
		});
		setAuthenticationType({
			authType: props.authentication.authType,
			validation: {
				required: true
			},
			valid: true,
			validationMessage: null,
			types: props.forSuite === true ? AuthenticationType.AuthenticationTypeObjectsForSuite : AuthenticationType.AuthenticationTypeObjects
		});
	}, [props.authentication]);

	const authTypeSelectHandler = (event) => {
		const updatedComponent = selectElement(authenticationType, event.target.value, 'authType', 'authType', intl);
		setAuthenticationType(updatedComponent);
		const authentication = {...props.authentication};
		authentication.authType = updatedComponent.authType;
		props.setAuthentication(authentication);
	};

	const createAuthObject = (username = '', password = '', token = '',
	                          apiKey = apiKeyDefault, tokenVariable = '') => {
		const authentication = {...props.authentication};
		authentication.username = username;
		authentication.password = password;
		// empty others
		authentication.token = token;
		authentication.tokenVariable = tokenVariable;
		authentication.apiKey = apiKey;
		props.setAuthentication(authentication);
	}

	let form = null;
	switch (authenticationType.authType) {
		case AuthenticationType.NOAUTH:
			form = <h5 className='mt-6'>No Auth</h5>;
			break;
		case AuthenticationType.BASIC_AUTH:
			form = <AuthBasicForm basic = {basic}
			                      setBasic = {setBasic}
								  // authentication = {props.authentication}
								  validate = {props.validate}
								  createAuthObject = {createAuthObject}
			                      registerValidateAuthBasicFormCallback = {props.registerValidateAuthBasicFormCallback}
			/>
			break;
		case AuthenticationType.BEARER_TOKEN:
			form = <AuthTokenForm token={token}
			                      setToken={setToken}
								  tokenVariable={tokenVariable}
								  setTokenVariable={setTokenVariable}
								  isVariable={props.isVariable}
								  setIsVariable={props.setIsVariable}
			                      validate={props.validate}
			                      createAuthObject={createAuthObject}
			                      registerValidateAuthTokenFormCallback={props.registerValidateAuthTokenFormCallback}
								  restVariables={props.restVariables}
								  forSuite={props.forSuite}/>;
			break;
		case AuthenticationType.API_KEY:
			form = <AuthAPIKeyForm APIKey={APIKey}
			                       setAPIKey={setAPIKey}
			                       validate={props.validate}
			                       createAuthObject={createAuthObject}
			                       registerValidateAuthAPIKeyFormCallback={props.registerValidateAuthAPIKeyFormCallback}
			/>;
			break;
		default:
			form = null;
	}

	return (
		<Container className='px-0'>
			<Row>
				<Col md={3} xs={12}>
					<FormControl className = {`${classes.formControl}`} fullWidth={true}
					             error = {!authenticationType.valid}>
						<InputLabel htmlFor = "select-type">
							<FormattedMessage id="REST.REQUEST.FORM.AUTH.TYPE.LABEL"
							                  defaultMessage='Authentication Type'/>
						</InputLabel>
						<Select
							value = {authenticationType.authType}
							onChange = {(event) => authTypeSelectHandler(event)}
							input = {<Input id = "select-type"/>}
						>

							{authenticationType.types.map(type => {
								return <MenuItem key = {type.value}
								                 value = {type.value}>{type.label}</MenuItem>
							})}
						</Select>
						<FormHelperText>{authenticationType.validationMessage}</FormHelperText>
					</FormControl>
				</Col>
				<Col>
					{form}
				</Col>
			</Row>
		</Container>
	);
}

export default RestRequestTabAuthentication;