import React from "react";
import {makeStyles} from "@material-ui/styles";
import {Button} from "@material-ui/core";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
import {FormattedMessage} from "react-intl";

import AddSuitesToIterationForm from "../../containers/Iteration/AddSuitesToIterationForm";
import {history} from "../../../redux/store";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const IterationSuiteActions = props => {
	const classes = useStyles();

	return (
			<div className = {`bg-white p-1 text-center ${classes.root}`}>

				<AddSuitesToIterationForm {...props}/>
				<Button variant = "contained"
				        size = 'small'
				        startIcon={<KeyboardReturn/>}
				        onClick = {(event) => {
					        event.preventDefault();
					        if(props.sprint){
						        history.push('/sprint/' + props.sprint.id, {tab: 1});
					        } else {
						        history.goBack();
					        }
				        }}
				        className = ''>
					<FormattedMessage id = "BUTTON.BACK" defaultMessage = 'Back'/>
				</Button>
			</div>
	);
}

export default IterationSuiteActions;