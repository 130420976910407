/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";

import * as actions from "../../store/actions";
import Display from "../../components/Users/Display";
import * as Role from "./enum/Roles";

const User = props => {
	const intl = useIntl();

	const [loggedInUser, setLoggedInUser] = useState(false);

	useEffect(() => {
		props.onLoadUser(props.match.params.username);

		if(localStorage.getItem('Top-Role') === Role.ROLE_TESTER ||
			localStorage.getItem('Top-Role') === Role.ROLE_USER ||
			localStorage.getItem('Top-Role') === Role.ROLE_CICD){
		} else {
			const config = {
				size: 100,
				page: 0,
				sortField: 'login',
				sortOrder: 'ASC'
			};
			props.onLoadAllUsers(localStorage.getItem("username"), config);
			// TODO delete this call after backend fix
		}
		return () => {
			props.onNullifyCICDKeys();
			props.onLoadUser(props.match.params.username);
		}
	}, [props.match.params.username]);

	useEffect(() => {
		if (props.user) {
			document.title = intl.formatMessage({id: "PAGE.TITLE.USER", defaultMessage: 'User: '}) + props.user.login;
		}
	}, [props.user]);

	const deleteUser = () => {
		const redirect = '/users';
		props.onDeleteUser(props.user.id, redirect)
	}

	const disableToggle = (user) => {
		props.onToggleActivation(user.login, !user.activated, false, user.id);
	}

	useEffect(() => {
		if (props.loggedInUser && props.user && props.users) {
			if (props.loggedInUser.id === props.user.id) {
				setLoggedInUser(true);
			} else {
				setLoggedInUser(false);
			}
		}
	}, [props.loggedInUser, props.user, props.users]);

	let forbid = false;
	let disableToggleFunction = null;
	if (props.loggedInUser && props.user && props.users) {
		if (props.loggedInUser.id === props.user.id) {
			disableToggleFunction = null;
			forbid = false;
		} else if(props.users.findIndex(user => user.id === props.user.id) >= 0){
			forbid = false;
			disableToggleFunction = disableToggle;
		} else {
			forbid = true;
		}
	}

	const [displayBothCICDKeys, setDisplayBothCICDKeys] = useState(false);

	useEffect(() => {
		if(props.CICDKeys !== null){
			setDisplayBothCICDKeys(true);
		}
	}, [props.CICDKeys]);

	const generateCICDKeys = () => {
		setDisplayBothCICDKeys(false);
		props.onGenerateCICDKeys(props.user.id);
	}

	const loadCICDSecretKey = () => {
		// setDisplayBothCICDKeys(false);
		// if(props.CICDKeys?.accessKey){
		// 	props.onLoadCICDSecretKey(props.CICDKeys.accessKey);
		// } else {
			props.onLoadCICDSecretKey(props.user.accessKey);
		// }
	}

	const deleteCICDKeys = () => {
		// two cases: 1 CICD keys, 2. user.access
		// if(props.CICDKeys !== null){
		// 	props.onDeleteCICDKeys(props.user.id, props.CICDKeys.accessKey)
			props.onDeleteCICDKeys(props.user.id);
		// } else {
		// 	props.onDeleteCICDKeys(props.user.id, props.user.accessKey)
		// }
	}

	if (forbid || (props.loading === false && props.user === null )) {
		return <h2><FormattedMessage id = "ACCESS.DENIED"
		                             defaultMessage = 'Access Denied'/></h2>
	} else {
		return <Display user = {props.user}
		                delete = {deleteUser}
		                disableToggle = {disableToggleFunction}
		                return = {true}
						loggedInUser={loggedInUser}
						deletionError={props.deletionError}
						onHideDeletionError={props.onHideDeletionError}
						CICDKeys={props.CICDKeys}
						generateCICDKeys={generateCICDKeys}
						displayBothCICDKeys={displayBothCICDKeys}
						loadCICDSecretKey={loadCICDSecretKey}
						secretKey={props.secretKey}
						deleteCICDKeys={deleteCICDKeys}
		/>;
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.user,
		error: state.users.error,
		loading: state.users.loading,
		loggedInUser: state.authentication.loggedInUser,
		users: state.users.users, // need only for refreshing user display.
		deletionError: state.users.deletionError,
		// it triggers re-render for activation status
		CICDKeys: state.users.CICDKeys,
		secretKey: state.users.secretKey
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadUser: (username) => dispatch(actions.loadUser(username)),
		onDeleteUser: (id, redirect) => dispatch(actions.deleteUser(id, redirect)),
		onToggleActivation: (username, active, users, id) => dispatch(actions.toggleActivation(username, active, users, id)),
		onLoadAllUsers: (username, config) => dispatch(actions.loadUsers(username, config)),
		onHideDeletionError: () => dispatch(actions.deleteUserNullifyError()),
		onGenerateCICDKeys: (id) => dispatch(actions.generateCICDKeys(id)),
		onNullifyCICDKeys: (id) => dispatch(actions.nullifyCICDKeys()),
		onLoadCICDSecretKey: (accessKey) => dispatch(actions.loadCICDSecretKey(accessKey)),
		onDeleteCICDKeys: (userId) => dispatch(actions.deleteCICDKeys(userId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(User);