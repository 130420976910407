import React from "react";
import {Alert} from "@material-ui/lab";
import {Card} from "react-bootstrap";

import {ProjectCreationLoadingDialog, ProjectUpdatingLoadingDialog} from "../../UI/LoadingDialog";
import CreationProjectForm from "./CreationProjectForm";
import ProjectActions from "./ProjectActions";

const FormProjectDisplay = props => {
	let errorMessages = props.errorMessages.map((error, index) => {
		return <Alert elevation = {3} variant = "standard" key={index}
		              severity = "error" className = 'my-4'>
			{error}
		</Alert>
	});

	let submitFunction;
	if(props.type === 'create'){
		submitFunction = props.createProject;
	} else if(props.type === 'update'){
		submitFunction = props.updateProject;
	}

	return (
		<form noValidate autoComplete = "off"  onSubmit = {submitFunction}  >
			<ProjectCreationLoadingDialog/>
			<ProjectUpdatingLoadingDialog/>
			<Card>

				<Card.Body className='p-0'>

						<CreationProjectForm type={props.type}
						                     label={props.label} setLabel={props.setLabel}
						                     url={props.url} setUrl={props.setUrl}
						                     // password={props.password} setPassword={props.setPassword}
						                     // live={props.live} setLive={props.setLive}
						                     autoExec={props.autoExec} setAutoExec={props.setAutoExec}
											 timeZone={props.timeZone} setTimeZone={props.setTimeZone}
											 // timeZones={props.timeZones}
						                     startTime={props.startTime} setStartTime={props.setStartTime}
						                     projectType={props.projectType} setProjectType={props.setProjectType}
						                     browser={props.browser} setBrowser={props.setBrowser}
						                     device={props.device} setDevice={props.setDevice}
						                     description={props.description} setDescription={props.setDescription}
						                     apk={props.apk} setApk={props.setApk}
						                     apkTouched={props.apkTouched} setApkTouched={props.setApkTouched}
						                     apkLabel={props.apkLabel} setApkLabel={props.setApkLabel}
						                     registerValidateFormCallback={props.registerValidateFormCallback}

						                     usersLeft={props.usersLeft} setUsersLeft={props.setUsersLeft}
						                     usersRight={props.usersRight} setUsersRight={props.setUsersRight}
						                     // usersSelected={props.usersSelected} setUsersSelected={props.setUsersSelected}

						                     allUsers={props.allUsers}
											 operatingSystem={props.operatingSystem} setOperatingSystem={props.setOperatingSystem}
											 browserVersion={props.browserVersion} setBrowserVersion={props.setBrowserVersion}

											 createProjectToBeCalledByAPKChangeHandler={props.createProject}
											 creationLoading={props.creationLoading}

											 suggestDefault={props.suggestDefault}
											 onUpdatePreferred={props.onUpdatePreferred}

											 deviceGroup={props.deviceGroup} setDeviceGroup={props.setDeviceGroup}
											 deviceGroups={props.deviceGroups} setDeviceGroups={props.setDeviceGroups}
						/>

				</Card.Body>
				{errorMessages}

				<Card.Footer className='py-4 px-8'>
					<ProjectActions type= {props.type}
					                creationLoading={props.creationLoading}
					                updatingLoading={props.updatingLoading}
									projectType={props.projectType}/>
				</Card.Footer>
			</Card>
		</form>
	);

}

export default FormProjectDisplay;