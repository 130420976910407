import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../../axios/axios";
import {push} from "connected-react-router";

export function* createRestRequest(action) {
	yield put(actions.createRestRequestStart());
	try {
		// yield delay(5000);
		const response = yield axios.post(`/restapis/project/${action.projectID}`, action.restRequest);
		// console.log('saga createRestRequest', response.data);
		if (response.status === 201) {
			yield put(actions.createRestRequestSuccess(response.data));
			yield put(push(`/rest-requests/`, {label: action.restRequest.label}));
		}
	} catch (error) {
		if (error.response) {
			// Note if the url is not correct, error.response.data.message is undefined,
			// the message is in error.message
			console.log('error.response', error.response.data.message);
			yield put(actions.createRestRequestFail(error.response.data.message));
		} else if (error.request) {
			console.log('error.request', error.request)
			yield put(actions.createRestRequestFail('Error Creating RestRequest, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createRestRequestFail(error.message));
		}
	}
}

export function* loadRestRequests(action) {
	yield put(actions.loadRestRequestsStart());
	// console.log('action.config', action.config);
	try {
		const response = yield axios.get(`/restapis/project/${action.projectID}`, {
			params: {...action.config}
		});
		// console.log('action.config', );

		yield put(actions.loadRestRequestsSuccess(response.data.content,
			response.data.totalElements));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadRestRequestsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadRestRequestsFail('Error Loading RestRequests, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadRestRequestsFail(error.message));
		}
	}
}

export function* deleteRestRequest(action) {
	yield put(actions.deleteRestRequestStart());
	try {
		yield axios.delete(`/restapis/${action.id}`);
		yield put(actions.deleteRestRequestSuccess());
		if(action.redirect){
			yield put(push(action.redirect));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteRestRequestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteRestRequestFail('Error Deleting RestRequest, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteRestRequestFail(error.message));
		}
	}
}

export function* loadRestRequest(action) {
	yield put(actions.loadRestRequestStart());
	try {
		const response = yield axios.get(`/restapis/${action.id}`);
		yield put(actions.loadRestRequestSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadRestRequestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadRestRequestFail('Error Loading RestRequest, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadRestRequestFail(error.message));
		}
	}
}

export function* updateRestRequest(action) {
	yield put(actions.updateRestRequestStart());
	try {
		yield axios.put(`/restapis/`, action.restRequest);

		yield put(actions.updateRestRequestSuccess());
		// yield put(push(`/restRequests`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateRestRequestFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateRestRequestFail('Error Updating RestRequest, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateRestRequestFail(error.message));
		}
	}
}

export function* loadRestRequestsNames(action) {
	yield put(actions.loadRestRequestsNamesStart());
	try {
		const response = yield axios.get(`/restapis/project/${action.projectID}/part/`);
		yield put(actions.loadRestRequestsNamesSuccess(response.data));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadRestRequestsNamesFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request);
			yield put(actions.loadRestRequestsNamesFail('Error Loading RestRequestsNames, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadRestRequestsNamesFail(error.message));
		}
	}
}

export function* createRestRequestOfDomain(action) {
	yield put(actions.createRestRequestOfDomainStart());
	try {
		// yield delay(5000);
		const response = yield axios.post(`domains/${action.domainId}/restapis`, action.restRequest);
		// console.log('saga createRestRequestOfDomain', response.data);
		if (response.status === 201) {
			yield put(actions.createRestRequestOfDomainSuccess());
			yield put(push(`/domain/${action.domainId}`, {tab: 1, restRequestLabel: action.restRequest.label}));
		}
	} catch (error) {
		if (error.response) {
			// Note if the url is not correct, error.response.data.message is undefined,
			// the message is in error.message
			console.log('error.response', error.response.data.message);
			yield put(actions.createRestRequestOfDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log('error.request', error.request)
			yield put(actions.createRestRequestOfDomainFail('Error Creating RestRequestOfDomain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createRestRequestOfDomainFail(error.message));
		}
	}
}

export function* loadProjectRestRequests(action) {
	yield put(actions.loadProjectRestRequestsStart());
	try {
		const response = yield axios.get(`/restapis/project/${action.id}/all`);
		yield put(actions.loadProjectRestRequestsSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadProjectRestRequestsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadProjectRestRequestsFail('Error Loading Project RestRequests, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadProjectRestRequestsFail(error.message));
		}
	}
}

export function* deleteRestRequestOfDomain(action) {
	yield put(actions.deleteRestRequestOfDomainStart());
	try {
		yield axios.delete(`domains/${action.domainId}/restapis/${action.requestId}`);
		yield put(actions.deleteRestRequestOfDomainSuccess());
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteRestRequestOfDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteRestRequestOfDomainFail('Error Deleting RestRequest Of Domain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteRestRequestOfDomainFail(error.message));
		}
	}
}