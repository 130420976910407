import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";

import * as actions from "../../store/actions";
import CountriesList from "../../utility/CountriesList";
import RegistrationDisplay from "../../components/Authentication/RegistrationDisplay";
import {backendUrl} from "../../axios/axios";

const Registration = props => {
	const intl = useIntl();

	// const [humanKey, setHumanKey] = useState('');
	const [captchaErrorMsg, setCaptchaErrorMsg] = useState(null);

	const [username, setUsername] = useState('');
	// const [firstName, setFirstName] = useState('');
	// const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessages, setErrorMessages] = useState([]);
	// const [streetNumber, setStreetNumber] = useState('');
	// const [route, setRoute] = useState('');
	// const [locality, setLocality] = useState('');
	// const [postalCode, setPostalCode] = useState('');
	// const [state, setState] = useState('');
	let currentCountry = JSON.parse(localStorage.getItem('i18nConfig')).selectedLang === 'fr' ? CountriesList[36] : CountriesList[141];
	const [country, setCountry] = useState(currentCountry);

	useEffect(() => {
		document.title = intl.formatMessage({id: "PAGE.TITLE.REGISTRATION", defaultMessage: 'Sign Up'});
		var script = document.createElement('script')
		script.src = 'https://www.google.com/recaptcha/api.js';
		script.class = "external-script"
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
			window.signupHandler = null;
		};
	}, []);

	useEffect(() => {
		const updatedGlobalMessages = [];
		if (props.error) { // refer to CreationError
			updatedGlobalMessages.push(props.error);
			window.grecaptcha.reset();
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.error]);

	const validateNormalUserFormRef = useRef();
	const registerValidateNormalUserFormCallback = callback => {
		validateNormalUserFormRef.current = callback;
	};

	const validateNormalUserAddressFormRef = useRef();
	const registerValidateNormalUserAddressFormCallback = callback => {
		validateNormalUserAddressFormRef.current = callback;
	};

	// Terms
	const termsAcceptedRef = useRef();
	const registerTermsAcceptedCallback = callback => {
		termsAcceptedRef.current = callback;
	};

	const signupHandler = async (token) => {
		// event.preventDefault();
		const isHuman = await fetch(`${backendUrl}recaptcha`, {
			method: "post",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
			},
			body: token
		})
			.then(res => res)
			// .then(json => {console.log('json', json); return json;})
			.catch(err => {
				window.grecaptcha.reset();
				setCaptchaErrorMsg(<div className = "my-6 alert alert-custom alert-light-danger alert-dismissible">
					<div className = "alert-text font-weight-bold">
						<FormattedMessage id = "CAPTCHA.ERROR"
						                  defaultMessage = 'Captcha Error'/>
					</div>
				</div>);
			});

		if (!token || !isHuman) {
			window.grecaptcha.reset();
			setCaptchaErrorMsg(<div className = "my-6 alert alert-custom alert-light-danger alert-dismissible">
				<div className = "alert-text font-weight-bold">
					<FormattedMessage id = "CAPTCHA.ERROR"
					                  defaultMessage = 'Captcha Error'/>
				</div>
			</div>);
			return;
		}

		// The code below will run only after the reCAPTCHA is succesfully validated.

		setCaptchaErrorMsg(null);

		// Terms
		if (typeof termsAcceptedRef.current === 'function' && !termsAcceptedRef.current()) {
			// console.log('Terms not accepted');
			window.grecaptcha.reset();
			return null;
		}

		// validate all
		if (typeof validateNormalUserFormRef.current === 'function' && !validateNormalUserFormRef.current()) {
			// console.log('Registration Form invalid');
			window.grecaptcha.reset();
			return null;
		}

		if (typeof validateNormalUserAddressFormRef.current === 'function'
			&& !validateNormalUserAddressFormRef.current()) {
			window.grecaptcha.reset();
			// console.log('Registration Form Address invalid');
			return null;
		}

		const user = {
			login: username,
			companyName: company,
			// firstName: firstName,
			// lastName: lastName,
			email: email,
			password: password,
			langKey: JSON.parse(localStorage.getItem('i18nConfig')).selectedLang,
			addressDTO: {
				countryCode: country.alpha2Code,
				countryName: country.name,
				// locality: locality,
				// postalCode: postalCode,
				// route: route,
				// state: state,
				// streetNumber: streetNumber
			}
		}
		props.onSignUp(user);
	}
	window.signupHandler = signupHandler;
	return (
		<RegistrationDisplay errorMessages = {errorMessages}
		                     userRegistered = {props.userRegistered}

		                     registerValidateNormalUserFormCallback = {registerValidateNormalUserFormCallback}
		                     username = {username} setUsername = {setUsername}
		                     // firstName = {firstName} setFirstName = {setFirstName}
		                     // lastName = {lastName} setLastName = {setLastName}
							 company = {company} setCompany = {setCompany}
		                     email = {email} setEmail = {setEmail}

		                     password = {password} setPassword = {setPassword}

		                     // streetNumber = {streetNumber} setStreetNumber = {setStreetNumber}
		                     // route = {route} setRoute = {setRoute}
		                     // locality = {locality} setLocality = {setLocality}
		                     // postalCode = {postalCode} setPostalCode = {setPostalCode}
		                     // state = {state} setState = {setState}
		                     country = {country} setCountry = {setCountry}
		                     registerValidateNormalUserAddressFormCallback = {registerValidateNormalUserAddressFormCallback}

		                     signupHandler = {signupHandler}
		                     captchaErrorMsg = {captchaErrorMsg}
							 registerTermsAcceptedCallback={registerTermsAcceptedCallback}
		/>

	);
}

const mapStateToProps = state => {
	return {
		loading: state.authentication.registrationLoading,
		error: state.authentication.registrationError,
		userRegistered: state.authentication.userRegistered
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSignUp: (user) => dispatch(actions.register(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
