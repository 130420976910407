import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../../axios/axios";
import {push} from "connected-react-router";

export function* loadSuites(action) {
	yield put(actions.loadSuitesStart());
	try {
		// TODO delete
		// const allSuites = [];
		// let currentPage = 0;
		// while (true) {
		// 	const response = yield axios.get(`/suites/project/${action.projectID}?currentPage=${currentPage}`);
		// 	currentPage++;
		// 	if(response.data.content.length === 0){
		// 		break;
		// 	}
		// 	allSuites.push(...response.data.content);
		// }
		//TODO
		const response = yield axios.get(`/suites/project/${action.projectID}`, {
			params: {...action.config}
		});
		yield put(actions.loadSuitesSuccess(response.data.content, //TODO when the back is done
			response.data.totalElements));
		// yield put(actions.loadSuitesSuccess(allSuites));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadSuitesFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadSuitesFail('Error Loading Suites, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadSuitesFail(error.message));
		}
	}
}

export function* deleteSuite(action) {
	yield put(actions.deleteSuiteStart());
	try {
		const response = yield axios.delete(`/suites/${action.id}`);
		yield put(actions.deleteSuiteSuccess(action.id));
		if(action.redirect){
			yield put(push(action.redirect));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteSuiteFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteSuiteFail('Error Deleting Suite, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteSuiteFail(error.message));
		}
	}
}

export function* createSuite(action) {
	yield put(actions.createSuiteStart());
	try {
		const response = yield axios.post(`/suites/project/${action.projectID}`, action.suite);
		// console.log('response', response);
		if (response.status === 201) {
			yield put(actions.createSuiteSuccess(response.data));
			yield put(push(`/suite/${response.data.id}`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createSuiteFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createSuiteFail('Error Creating Suite, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createSuiteFail(error.message));
		}
	}
}

export function* loadSuite(action) {
	yield put(actions.loadSuiteStart());
	try {
		const response = yield axios.get(`/suites/${action.id}`);
		// console.log('response', response.data);

		yield put(actions.loadSuiteSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadSuiteFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadSuiteFail('Error Loading Suite, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadSuiteFail(error.message));
		}
	}
}

export function* updateSuite(action) {
	yield put(actions.updateSuiteStart());
	try {
		const response = yield axios.patch(`/suites/${action.id}`, action.suite);

		yield put(actions.updateSuiteSuccess(action.stateSuite));
		// yield put(push(`/suites`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateSuiteFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateSuiteFail('Error Updating Suite, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateSuiteFail(error.message));
		}
	}
}

export function* loadProjectSuites(action) {
	yield put(actions.loadProjectSuitesStart());
	try {
		const response = yield axios.get(`/suites/project/${action.id}/soft`);
		yield put(actions.loadProjectSuitesSuccess(response.data));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadProjectSuitesFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadProjectSuitesFail('Error Loading Suites, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadProjectSuitesFail(error.message));
		}
	}
}

export function* loadVariables(action) {
	yield put(actions.loadVariablesStart());
	try {
		const response = yield axios.get(`/suites/${action.suiteID}/variables`);
		yield put(actions.loadVariablesSuccess(response.data));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadVariablesFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadVariablesFail('Error Loading Variable For Suites, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadVariablesFail(error.message));
		}
	}
}