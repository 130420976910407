import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	updatingLoading: false,
	updatingError: null,

	listAllLoading: false,
	listAllError: null,

	listOneLoading: false,
	listOneError: null,

	loading: false,
	error: null,
	sprints: null,			// New Paradigm: initial = null, success = value, fail = null;
	sprint: null,
	totalRecords: 0,
	refreshList: false
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.LOAD_SPRINTS_START:
			return updateObject(state, {
				sprints: null,
				listAllLoading: true,
				listAllError: null,

			});
		case actionTypes.LOAD_SPRINTS_SUCCESS:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: null,
				sprints: action.sprints,
				totalRecords: action.totalRecords,
				refreshList: false
			});
		case actionTypes.LOAD_SPRINTS_FAIL:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: action.error,
				sprints: null
			});
		case actionTypes.CREATE_SPRINT_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.CREATE_SPRINT_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				sprint: action.sprint
			});
		case actionTypes.CREATE_SPRINT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				sprint: null
			});
		case actionTypes.LOAD_SPRINT_START:
			return updateObject(state, {
				listOneLoading: true
			});
		case actionTypes.LOAD_SPRINT_SUCCESS:
			return updateObject(state, {
				listOneLoading: false,
				listOneError: null,
				sprint: action.sprint
			});
		case actionTypes.LOAD_SPRINT_FAIL:
			return updateObject(state, {
				listOneLoading: false,
				listOneError: action.error,
				sprint: null
			});
		case actionTypes.UPDATE_SPRINT_START:
			return updateObject(state, {
				updatingLoading: true,
				updatingError: null
			});
		case actionTypes.UPDATE_SPRINT_SUCCESS:
			const mergeSprint = updateObject(state.sprint, {
				...action.sprint
			});
			const mergeSprints = state.sprints.map((sprint) => {
				if(sprint.id === mergeSprint.id){
					const copySprint = {...mergeSprint};
					delete copySprint.iterations;
					return copySprint;
				}
				return sprint;
			});
			return updateObject(state, {
				sprint: mergeSprint,
				sprints: mergeSprints,
				updatingLoading: false,
			});
		case actionTypes.UPDATE_SPRINT_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error,
			});
		case actionTypes.DELETE_SPRINT_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.DELETE_SPRINT_SUCCESS:
			let sprintsCopy = [...state.sprints];
			sprintsCopy = sprintsCopy.filter((sprint) => {
				if(sprint.id !== action.id){
					return true;
				}
			});
			return updateObject(state, {
				loading: false,
				error: null,
				sprints: sprintsCopy,
				sprint: null,
				refreshList: true
			});
		case actionTypes.DELETE_SPRINT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		default: {
			return state;
		}
	}
}

export default reducer;