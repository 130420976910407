import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'

import * as auth from "../app/modules/Auth/_redux/authRedux";
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import authenticationReducer , { initialState as authenticationIS} from "../tigmat/store/reducers/authenticationReducer";
import usersReducer , { initialState as usersIS} from "../tigmat/store/reducers/usersReducer";
import projectReducer , { initialState as projectIS} from "../tigmat/store/reducers/projectReducer";
import domainReducer, { initialState as domainIS} from "../tigmat/store/reducers/domainReducer";
import testReducer, { initialState as testIS} from "../tigmat/store/reducers/testReducer";
import suiteReducer, { initialState as suiteIS} from "../tigmat/store/reducers/suiteReducer";
import sprintReducer , { initialState as sprintIS} from "../tigmat/store/reducers/sprintReducer";
import iterationReducer , { initialState as iterationIS} from "../tigmat/store/reducers/iterationReducer";
import reportReducer, { initialState as reportIS} from "../tigmat/store/reducers/reportReducer";
import paymentReducer, { initialState as paymentIS} from "../tigmat/store/reducers/paymentReducer";
import dashboardReducer, { initialState as dashboardReducerIS} from "../tigmat/store/reducers/dashboardReducer";
import * as actionTypes from "../tigmat/store/actions/actionTypes";
import settingsReducer, { initialState as settingsIS} from "../tigmat/store/reducers/settingsReducer";
import restRequestReducer, { initialState as restRequestIS} from "../tigmat/store/reducers/restRequestReducer";

const appReducer = (history) => combineReducers({
	auth: auth.reducer,
	router: connectRouter(history),
	authentication: authenticationReducer,
	users: usersReducer,
	project: projectReducer,
	domain: domainReducer,
	test: testReducer,
	suite: suiteReducer,
	sprint: sprintReducer,
	iteration: iterationReducer,
	report: reportReducer,
	payment: paymentReducer,
	dashboard: dashboardReducer,
	settings: settingsReducer,
	restRequest: restRequestReducer
});

const initialState = {
	auth: {},
	authentication: authenticationIS,
	users: usersIS,
	project: projectIS,
	domain: domainIS,
	test: testIS,
	suite: suiteIS,
	sprint: sprintIS,
	iteration: iterationIS,
	report: reportIS,
	payment: paymentIS,
	dashboard: dashboardReducerIS,
	settings: settingsIS,
	restRequest: restRequestIS
}

export const rootReducer = history => (state, action) => {
	if (action.type === actionTypes.LOGOUT_SUCCESS) {
		initialState.authentication.authCheckFinished = true;
		state = initialState;
	}
	return appReducer(history)(state, action);
};

export function* rootSaga() {
	// yield all([auth.saga()]);
}
