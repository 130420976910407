export const MATCHES = "MATCHES";
export const NOT_MATCHES = "NOT_MATCHES";
export const LESS_THAN = "LESS_THAN";
export const GREATER_THAN = "GREATER_THAN";

export const AssertionQuantityConditionObjects = [
	{label: 'Matches', value: MATCHES},
	{label: 'Does Not Matches', value: NOT_MATCHES},
	{label: 'Less Than', value: LESS_THAN},
	{label: 'Greater Than', value: GREATER_THAN}
];

export const AssertionQuantityConditionValues = [
	"MATCHES",
	"NOT_MATCHES",
	"LESS_THAN",
	"GREATER_THAN"
];