import React from "react";
import Chart from "react-apexcharts";

const getChartOptions = (lastFiveIterations, history) => {
	const barColors = ['#008ffb', '#00e396', '#ff4560', '#FEB019'];

	// const iteartionsNames = ['it 1', 'it 2'];
	// const iteartionsIDs = ['123', '456'];

	const options = {
		title: {
			text: 'Last 5 Iterations Results',
			offsetY: 15,
			offsetX: 15,
			style: {
				fontSize:  '18px',
			}
		},
		colors: barColors,
		xaxis: {
			// categories: props.lastFiveExecutions[0].jobs
			categories: lastFiveIterations.iteartionsNames
		},
		chart: {
			events: {
				dataPointSelection: (event, chartContext, config) => {
					// console.log('Redirect To Report ID', props.lastFiveExecutions[0].iterationId[config.dataPointIndex]);
					history.push(`/report/${lastFiveIterations.iteartionsIDs[config.dataPointIndex]}`);
				}
			}
		}

	}
	return options;
}
// const getChartSeries = (lastFiveExecutions) => {
//
// 	const series = [{name: 'total', data: [2, 2]}, {name: 'success', data: [1, 2]}, {name: 'error', data: [1, 0]}, {name: 'failures', data: [0, 0]}];
// 	// lastFiveExecutions.forEach((execution) => {
// 	// 	updatedBarSeries.push({
// 	// 		name: execution.key,
// 	// 		data: execution.value
// 	// 	});
// 	// });
// 	return series;
// }

const LastFiveExecutionsWidget = props => {

	return <div className='card card-custom gutter-b card-stretch' >
		<Chart
			options={getChartOptions(props.lastFiveIterations, props.history)}
			// series={getChartSeries(props.lastFiveExecutions)}
			series={props.lastFiveIterations.series}
			type="bar"/>
	</div>
}

export default LastFiveExecutionsWidget;