// AUTHENTICATION
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
// export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT_EXECUTE = 'CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT_EXECUTE';
export const ACTIVATE_ACCOUNT_START = 'ACTIVATE_ACCOUNT_START';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAIL = 'ACTIVATE_ACCOUNT_FAIL';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const AUTH_CHECK_STATE_FINISHED = 'AUTH_CHECK_STATE_FINISHED';

// Saga
export const LOGIN = 'LOGIN';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const TIMEOUT = 'TIMEOUT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT = 'CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

// USERS
export const ON_LOAD_USERS_START = 'ON_LOAD_USERS_START';
export const ON_LOAD_USERS_SUCCESS = 'ON_LOAD_USERS_SUCCESS';
export const ON_LOAD_USERS_FAIL = 'ON_LOAD_USERS_FAIL';
export const ON_LOAD_USER_START = 'ON_LOAD_USER_START';
export const ON_LOAD_USER_SUCCESS = 'ON_LOAD_USER_SUCCESS';
export const ON_LOAD_USER_FAIL = 'ON_LOAD_USER_FAIL';
export const CHANGE_ACTIVATION_STATUS_START = 'CHANGE_ACTIVATION_STATUS_START';
export const CHANGE_ACTIVATION_STATUS_SUCCESS = 'CHANGE_ACTIVATION_STATUS_SUCCESS';
export const CHANGE_ACTIVATION_STATUS_FAIL = 'CHANGE_ACTIVATION_STATUS_FAIL';
export const REGISTER_ACCOUNT_USER_START  = 'REGISTER_ACCOUNT_USER_START';
export const REGISTER_ACCOUNT_USER_SUCCESS = 'REGISTER_ACCOUNT_USER_SUCCESS';
export const REGISTER_ACCOUNT_USER_FAIL = 'REGISTER_ACCOUNT_USER_FAIL';
export const REGISTER_ACCOUNT_USER_CICD_START  = 'REGISTER_ACCOUNT_USER_CICD_START';
export const REGISTER_ACCOUNT_USER_CICD_SUCCESS = 'REGISTER_ACCOUNT_USER_CICD_SUCCESS';
export const REGISTER_ACCOUNT_USER_CICD_FAIL = 'REGISTER_ACCOUNT_USER_CICD_FAIL';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const UPDATE_USER_CICD_START = 'UPDATE_USER_CICD_START';
export const UPDATE_USER_CICD_SUCCESS = 'UPDATE_USER_CICD_SUCCESS';
export const UPDATE_USER_CICD_FAIL = 'UPDATE_USER_CICD_FAIL';
export const LOAD_ALL_USERS_START = 'LOAD_ALL_USERS_START';
export const LOAD_ALL_USERS_SUCCESS = 'LOAD_ALL_USERS_SUCCESS';
export const LOAD_ALL_USERS_FAIL = 'LOAD_ALL_USERS_FAIL';
export const UPDATE_USER_MY_ACCOUNT_START = 'UPDATE_USER_MY_ACCOUNT_START';
export const UPDATE_USER_MY_ACCOUNT_SUCCESS = 'UPDATE_USER_MY_ACCOUNT_SUCCESS';
export const UPDATE_USER_MY_ACCOUNT_FAIL = 'UPDATE_USER_MY_ACCOUNT_FAIL';
export const UPDATE_PREFERRED_PROJECT_START  = 'UPDATE_PREFERRED_PROJECT_START';
export const UPDATE_PREFERRED_PROJECT_SUCCESS = 'UPDATE_PREFERRED_PROJECT_SUCCESS';
export const UPDATE_PREFERRED_PROJECT_FAIL = 'UPDATE_PREFERRED_PROJECT_FAIL';
export const CAN_CREATE_USER_START = 'CAN_CREATE_USER_START';
export const CAN_CREATE_USER_SUCCESS = 'CAN_CREATE_USER_SUCCESS';
export const CAN_CREATE_USER_FAIL = 'CAN_CREATE_USER_FAIL';
export const DELETE_USER_NULLIFY_ERROR = 'DELETE_USER_NULLIFY_ERROR';
export const REQUEST_DELETE_MY_ACCOUNT_START = 'REQUEST_DELETE_MY_ACCOUNT_START';
export const REQUEST_DELETE_MY_ACCOUNT_SUCCESS = 'REQUEST_DELETE_MY_ACCOUNT_SUCCESS';
export const REQUEST_DELETE_MY_ACCOUNT_FAIL = 'REQUEST_DELETE_MY_ACCOUNT_FAIL';
export const CLOSE_ACCOUNT_START = 'CLOSE_ACCOUNT_START';
export const CLOSE_ACCOUNT_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';
export const CLOSE_ACCOUNT_FAIL = 'CLOSE_ACCOUNT_FAIL';
export const CHANGE_LANGUAGE_START = 'CHANGE_LANGUAGE_START';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAIL = 'CHANGE_LANGUAGE_FAIL';
export const GENERATE_CICD_KEYS_START = 'GENERATE_CICD_KEYS_START';
export const GENERATE_CICD_KEYS_SUCCESS = 'GENERATE_CICD_KEYS_SUCCESS';
export const GENERATE_CICD_KEYS_FAIL = 'GENERATE_CICD_KEYS_FAIL';
export const NULLIFY_CICD_KEYS = 'NULLIFY_CICD_KEYS';
export const LOAD_CICD_SECRET_KEY_START = 'LOAD_CICD_SECRET_KEY_START';
export const LOAD_CICD_SECRET_KEY_SUCCESS = 'LOAD_CICD_SECRET_KEY_SUCCESS';
export const LOAD_CICD_SECRET_KEY_FAIL = 'LOAD_CICD_SECRET_KEY_FAIL';
export const DELETE_CICD_KEYS_START = 'DELETE_CICD_KEYS_START';
export const DELETE_CICD_KEYS_SUCCESS = 'DELETE_CICD_KEYS_SUCCESS';
export const DELETE_CICD_KEYS_FAIL = 'DELETE_CICD_KEYS_FAIL';

// Saga
export const ON_LOAD_USERS = 'ON_LOAD_USERS';
export const ON_LOAD_USER = 'ON_LOAD_USER';
export const CHANGE_ACTIVATION_STATUS = 'CHANGE_ACTIVATION_STATUS';
export const REGISTER_ACCOUNT_USER = 'REGISTER_ACCOUNT_USER';
export const REGISTER_ACCOUNT_USER_CICD  = 'REGISTER_ACCOUNT_USER_CICD';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER_CICD = 'UPDATE_USER_CICD';
export const LOAD_ALL_USERS = 'LOAD_ALL_USERS';
export const UPDATE_USER_MY_ACCOUNT = 'UPDATE_USER_MY_ACCOUNT';
export const UPDATE_PREFERRED_PROJECT = 'UPDATE_PREFERRED_PROJECT';
export const CAN_CREATE_USER = 'CAN_CREATE_USER';
export const REQUEST_DELETE_MY_ACCOUNT = 'REQUEST_DELETE_MY_ACCOUNT';
export const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const GENERATE_CICD_KEYS = 'GENERATE_CICD_KEYS';
export const LOAD_CICD_SECRET_KEY = 'LOAD_CICD_SECRET_KEY';
export const DELETE_CICD_KEYS = 'DELETE_CICD_KEYS';

// PROJECT
export const CREATE_PROJECT_START  = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';
export const LOAD_PROJECT_START = 'LOAD_PROJECT_START';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAIL = 'LOAD_PROJECT_FAIL';
export const LOAD_PROJECTS_START = 'LOAD_PROJECTS_START';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_FAIL = 'LOAD_PROJECTS_FAIL';
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export const UPDATE_PROJECT_START  = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export const TOGGLE_EXEMPT_PROJECT_START  = 'TOGGLE_EXEMPT_PROJECT_START';
export const TOGGLE_EXEMPT_PROJECT_SUCCESS = 'TOGGLE_EXEMPT_PROJECT_SUCCESS';
export const TOGGLE_EXEMPT_PROJECT_FAIL = 'TOGGLE_EXEMPT_PROJECT_FAIL';
export const CREATE_PROJECT_ANDROID_START  = 'CREATE_PROJECT_ANDROID_START';
export const CREATE_PROJECT_ANDROID_SUCCESS = 'CREATE_PROJECT_ANDROID_SUCCESS';
export const CREATE_PROJECT_ANDROID_FAIL = 'CREATE_PROJECT_ANDROID_FAIL';
export const UPDATE_PROJECT_ANDROID_START  = 'UPDATE_PROJECT_ANDROID_START';
export const UPDATE_PROJECT_ANDROID_SUCCESS = 'UPDATE_PROJECT_ANDROID_SUCCESS';
export const UPDATE_PROJECT_ANDROID_FAIL = 'UPDATE_PROJECT_ANDROID_FAIL';
export const LOAD_PROJECT_VIEW_PORT_START = 'LOAD_PROJECT_VIEW_PORT_START';
export const LOAD_PROJECT_VIEW_PORT_SUCCESS = 'LOAD_PROJECT_VIEW_PORT_SUCCESS';
export const LOAD_PROJECT_VIEW_PORT_FAIL = 'LOAD_PROJECT_VIEW_PORT_FAIL';
export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export const CAN_CREATE_PROJECT_START = 'CAN_CREATE_PROJECT_START';
export const CAN_CREATE_PROJECT_SUCCESS = 'CAN_CREATE_PROJECT_SUCCESS';
export const CAN_CREATE_PROJECT_FAIL = 'CAN_CREATE_PROJECT_FAIL';
export const LOAD_TIMEZONES_START = 'LOAD_TIMEZONES_START';
export const LOAD_TIMEZONES_SUCCESS = 'LOAD_TIMEZONES_SUCCESS';
export const LOAD_TIMEZONES_FAIL = 'LOAD_TIMEZONES_FAIL';
export const CHECK_ANDROID_ENVIRONMENT_STATUS_START = 'CHECK_ANDROID_ENVIRONMENT_STATUS_START';
export const CHECK_ANDROID_ENVIRONMENT_STATUS_SUCCESS = 'CHECK_ANDROID_ENVIRONMENT_STATUS_SUCCESS';
export const CHECK_ANDROID_ENVIRONMENT_STATUS_FAIL = 'CHECK_ANDROID_ENVIRONMENT_STATUS_FAIL';
export const NULLIFY_ANDROID_ENVIRONMENT_STATUS_ERROR = 'NULLIFY_ANDROID_ENVIRONMENT_STATUS_ERROR';
export const LOAD_DEVICES_START = 'LOAD_DEVICES_START';
export const LOAD_DEVICES_SUCCESS = 'LOAD_DEVICES_SUCCESS';
export const LOAD_DEVICES_FAIL = 'LOAD_DEVICES_FAIL';
export const CREATE_DEVICE_GROUP_START = 'CREATE_DEVICE_GROUP_START';
export const CREATE_DEVICE_GROUP_SUCCESS = 'CREATE_DEVICE_GROUP_SUCCESS';
export const NULLIFY_CREATED_DEVICE_GROUP = 'NULLIFY_CREATED_DEVICE_GROUP';
export const CREATE_DEVICE_GROUP_FAIL_INCOMPATIBLE = 'CREATE_DEVICE_GROUP_FAIL_INCOMPATIBLE';
export const CREATE_DEVICE_GROUP_FAIL = 'CREATE_DEVICE_GROUP_FAIL';
export const DELETE_DEVICE_GROUP_START = 'DELETE_DEVICE_GROUP_START';
export const DELETE_DEVICE_GROUP_SUCCESS = 'DELETE_DEVICE_GROUP_SUCCESS';
export const DELETE_DEVICE_GROUP_FAIL = 'DELETE_DEVICE_GROUP_FAIL';
export const NULLIFY_DELETION_DEVICE_GROUP_ERROR = 'NULLIFY_DELETION_DEVICE_GROUP_ERROR';
export const UPDATE_DEVICE_GROUP_START = 'UPDATE_DEVICE_GROUP_START';
export const UPDATE_DEVICE_GROUP_SUCCESS = 'UPDATE_DEVICE_GROUP_SUCCESS';
export const UPDATE_DEVICE_GROUP_FAIL_INCOMPATIBLE = 'UPDATE_DEVICE_GROUP_FAIL_INCOMPATIBLE';
export const UPDATE_DEVICE_GROUP_FAIL = 'UPDATE_DEVICE_GROUP_FAIL';
export const LOAD_TEST_FILES_START = 'LOAD_TEST_FILES_START';
export const LOAD_TEST_FILES_SUCCESS = 'LOAD_TEST_FILES_SUCCESS';
export const LOAD_TEST_FILES_FAIL = 'LOAD_TEST_FILES_FAIL';
export const UPLOAD_TEST_FILE_START = 'UPLOAD_TEST_FILE_START';
export const UPLOAD_TEST_FILE_SUCCESS = 'UPLOAD_TEST_FILE_SUCCESS';
export const UPLOAD_TEST_FILE_FAIL = 'UPLOAD_TEST_FILE_FAIL';
export const DELETE_TEST_FILE_START = 'DELETE_TEST_FILE_START';
export const DELETE_TEST_FILE_SUCCESS = 'DELETE_TEST_FILE_SUCCESS';
export const DELETE_TEST_FILE_FAIL = 'DELETE_TEST_FILE_FAIL';

// Saga
export const CREATE_PROJECT  = 'CREATE_PROJECT';
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT  = 'UPDATE_PROJECT';
export const TOGGLE_EXEMPT_PROJECT  = 'TOGGLE_EXEMPT_PROJECT';
export const SET_PROJECT = 'SET_PROJECT';
export const CREATE_PROJECT_ANDROID = 'CREATE_PROJECT_ANDROID';
export const UPDATE_PROJECT_ANDROID  = 'UPDATE_PROJECT_ANDROID';
export const LOAD_PROJECT_VIEW_PORT = 'LOAD_PROJECT_VIEW_PORT';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CAN_CREATE_PROJECT = 'CAN_CREATE_PROJECT';
export const LOAD_TIMEZONES = 'LOAD_TIMEZONES';
export const CHECK_ANDROID_ENVIRONMENT_STATUS = 'CHECK_ANDROID_ENVIRONMENT_STATUS';
export const LOAD_DEVICES = 'LOAD_DEVICES';
export const CREATE_DEVICE_GROUP = 'CREATE_DEVICE_GROUP';
export const DELETE_DEVICE_GROUP = 'DELETE_DEVICE_GROUP';
export const UPDATE_DEVICE_GROUP = 'UPDATE_DEVICE_GROUP';
export const LOAD_TEST_FILES = 'LOAD_TEST_FILES';
export const UPLOAD_TEST_FILE = 'UPLOAD_TEST_FILE';
export const DELETE_TEST_FILE = 'DELETE_TEST_FILE';

// DOMAIN
export const CREATE_DOMAIN_START  = 'CREATE_DOMAIN_START';
export const CREATE_DOMAIN_SUCCESS = 'CREATE_DOMAIN_SUCCESS';
export const CREATE_DOMAIN_FAIL = 'CREATE_DOMAIN_FAIL';
export const LOAD_DOMAINS_START = 'LOAD_DOMAINS_START';
export const LOAD_DOMAINS_SUCCESS = 'LOAD_DOMAINS_SUCCESS';
export const LOAD_DOMAINS_FAIL = 'LOAD_DOMAINS_FAIL';
export const DELETE_DOMAIN_START = 'DELETE_DOMAIN_START';
export const DELETE_DOMAIN_SUCCESS = 'DELETE_DOMAIN_SUCCESS';
export const DELETE_DOMAIN_FAIL = 'DELETE_DOMAIN_FAIL';
export const LOAD_DOMAIN_START = 'LOAD_DOMAIN_START';
export const LOAD_DOMAIN_SUCCESS = 'LOAD_DOMAIN_SUCCESS';
export const LOAD_DOMAIN_FAIL = 'LOAD_DOMAIN_FAIL';
export const UPDATE_DOMAIN_START  = 'UPDATE_DOMAIN_START';
export const UPDATE_DOMAIN_SUCCESS = 'UPDATE_DOMAIN_SUCCESS';
export const UPDATE_DOMAIN_FAIL = 'UPDATE_DOMAIN_FAIL';
export const IMPORT_TESTS_START  = 'IMPORT_TESTS_START';
export const IMPORT_TESTS_SUCCESS  = 'IMPORT_TESTS_SUCCESS';
export const IMPORT_TESTS_FAIL  = 'IMPORT_TESTS_FAIL';
export const LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_START = 'LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_START';
export const LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_SUCCESS = 'LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_SUCCESS';
export const LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_FAIL = 'LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS_FAIL';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_START  = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_START';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS_DONE  = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS_DONE';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_TOGGLE_LOADING_MESSAGE  = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_TOGGLE_LOADING_MESSAGE';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_SUCCESS';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_STOP_TO_CONTINUE = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_STOP_TO_CONTINUE';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_ALREADY_IN_USE = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_ALREADY_IN_USE';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_FAIL = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_FAIL';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT_NULLIFY_ERROR = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT_NULLIFY_ERROR';
export const STOP_ANDROID_RECORDING_ENVIRONMENT_START  = 'STOP_ANDROID_RECORDING_ENVIRONMENT_START';
export const STOP_ANDROID_RECORDING_ENVIRONMENT_SUCCESS = 'STOP_ANDROID_RECORDING_ENVIRONMENT_SUCCESS';
export const STOP_ANDROID_RECORDING_ENVIRONMENT_FAIL = 'STOP_ANDROID_RECORDING_ENVIRONMENT_FAIL';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_START  = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_START';
export const REMOVE_DOMAIN_ID_FROM_REQUESTED_ANDROID_ENVIRNOMENT_LIST  = 'REMOVE_DOMAIN_ID_FROM_REQUESTED_ANDROID_ENVIRNOMENT_LIST';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_CREATED = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_CREATED';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_SUCCESS = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_SUCCESS';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_STOP_TO_CONTINUE = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_STOP_TO_CONTINUE';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_ALREADY_IN_USE = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_ALREADY_IN_USE';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_FAIL = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN_FAIL';

// Saga
export const CREATE_DOMAIN = 'CREATE_DOMAIN';
export const LOAD_DOMAINS = 'LOAD_DOMAINS';
export const DELETE_DOMAIN = 'DELETE_DOMAIN';
export const LOAD_DOMAIN = 'LOAD_DOMAIN';
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
export const IMPORT_TESTS  = 'IMPORT_TESTS';
export const LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS = 'LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS';
export const PREPARE_ANDROID_RECORDING_ENVIRONMENT = 'PREPARE_ANDROID_RECORDING_ENVIRONMENT';
export const STOP_ANDROID_RECORDING_ENVIRONMENT = 'STOP_ANDROID_RECORDING_ENVIRONMENT';
// export const LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN = 'LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN';

// TEST
export const CREATE_TEST_START  = 'CREATE_TEST_START';
export const CREATE_TEST_SUCCESS = 'CREATE_TEST_SUCCESS';
export const CREATE_TEST_FAIL = 'CREATE_TEST_FAIL';
export const LOAD_TEST_START = 'LOAD_TEST_START';
export const LOAD_TEST_SUCCESS = 'LOAD_TEST_SUCCESS';
export const LOAD_TEST_FAIL = 'LOAD_TEST_FAIL';
export const UPDATE_TEST_START  = 'UPDATE_TEST_START';
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS';
export const UPDATE_TEST_FAIL = 'UPDATE_TEST_FAIL';
export const DELETE_TEST_START = 'DELETE_TEST_START';
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS';
export const DELETE_TEST_FAIL = 'DELETE_TEST_FAIL';
export const DELETE_TEST_FROM_LOADED_DOMAIN = 'DELETE_TEST_FROM_LOADED_DOMAIN';
export const LOAD_PROJECT_TESTS_START = 'LOAD_PROJECT_TESTS_START';
export const LOAD_PROJECT_TESTS_SUCCESS = 'LOAD_PROJECT_TESTS_SUCCESS';
export const LOAD_PROJECT_TESTS_FAIL = 'LOAD_PROJECT_TESTS_FAIL';
export const NULLIFY_TEST_ERROR = 'NULLIFY_TEST_ERROR';
export const NULLIFY_UPDATING_TEST_ERROR = 'NULLIFY_UPDATING_TEST_ERROR';
export const MOVE_TEST_TO_DOMAIN_START = 'MOVE_TEST_TO_DOMAIN_START';
export const MOVE_TEST_TO_DOMAIN_SUCCESS = 'MOVE_TEST_TO_DOMAIN_SUCCESS';
export const MOVE_TEST_TO_DOMAIN_FAIL = 'MOVE_TEST_TO_DOMAIN_FAIL';


// Saga
export const CREATE_TEST = 'CREATE_TEST';
export const LOAD_TEST = 'LOAD_TEST';
export const UPDATE_TEST = 'UPDATE_TEST';
export const DELETE_TEST = 'DELETE_TEST';
export const LOAD_PROJECT_TESTS = 'LOAD_PROJECT_TESTS';
export const MOVE_TEST_TO_DOMAIN = 'MOVE_TEST_TO_DOMAIN';

// SUITE
export const LOAD_SUITES_START = 'LOAD_SUITES_START';
export const LOAD_SUITES_SUCCESS = 'LOAD_SUITES_SUCCESS';
export const LOAD_SUITES_FAIL = 'LOAD_SUITES_FAIL';
export const DELETE_SUITE_START = 'DELETE_SUITE_START';
export const DELETE_SUITE_SUCCESS = 'DELETE_SUITE_SUCCESS';
export const DELETE_SUITE_FAIL = 'DELETE_SUITE_FAIL';
export const CREATE_SUITE_START  = 'CREATE_SUITE_START';
export const CREATE_SUITE_SUCCESS = 'CREATE_SUITE_SUCCESS';
export const CREATE_SUITE_FAIL = 'CREATE_SUITE_FAIL';
export const LOAD_SUITE_START = 'LOAD_SUITE_START';
export const LOAD_SUITE_SUCCESS = 'LOAD_SUITE_SUCCESS';
export const LOAD_SUITE_FAIL = 'LOAD_SUITE_FAIL';
export const UPDATE_SUITE_START  = 'UPDATE_SUITE_START';
export const UPDATE_SUITE_SUCCESS = 'UPDATE_SUITE_SUCCESS';
export const UPDATE_SUITE_FAIL = 'UPDATE_SUITE_FAIL';
export const LOAD_PROJECT_SUITES_START = 'LOAD_PROJECT_SUITES_START';
export const LOAD_PROJECT_SUITES_SUCCESS = 'LOAD_PROJECT_SUITES_SUCCESS';
export const LOAD_PROJECT_SUITES_FAIL = 'LOAD_PROJECT_SUITES_FAIL';
export const LOAD_VARIABLES_FOR_SUITES_START = 'LOAD_VARIABLES_FOR_SUITES_START';
export const LOAD_VARIABLES_FOR_SUITES_SUCCESS = 'LOAD_VARIABLES_FOR_SUITES_SUCCESS';
export const LOAD_VARIABLES_FOR_SUITES_FAIL = 'LOAD_VARIABLES_FOR_SUITES_FAIL';

// Saga
export const LOAD_SUITES = 'LOAD_SUITES';
export const DELETE_SUITE = 'DELETE_SUITE';
export const CREATE_SUITE = 'CREATE_SUITE';
export const LOAD_SUITE = 'LOAD_SUITE';
export const UPDATE_SUITE = 'UPDATE_SUITE';
export const LOAD_PROJECT_SUITES = 'LOAD_PROJECT_SUITES';
export const LOAD_VARIABLES_FOR_SUITES = 'LOAD_VARIABLES_FOR_SUITES';

// Sprint
export const LOAD_SPRINTS_START = 'LOAD_SPRINTS_START';
export const LOAD_SPRINTS_SUCCESS = 'LOAD_SPRINTS_SUCCESS';
export const LOAD_SPRINTS_FAIL = 'LOAD_SPRINTS_FAIL';
export const CREATE_SPRINT_START  = 'CREATE_SPRINT_START';
export const CREATE_SPRINT_SUCCESS = 'CREATE_SPRINT_SUCCESS';
export const CREATE_SPRINT_FAIL = 'CREATE_SPRINT_FAIL';
export const LOAD_SPRINT_START = 'LOAD_SPRINT_START';
export const LOAD_SPRINT_SUCCESS = 'LOAD_SPRINT_SUCCESS';
export const LOAD_SPRINT_FAIL = 'LOAD_SPRINT_FAIL';
export const UPDATE_SPRINT_START  = 'UPDATE_SPRINT_START';
export const UPDATE_SPRINT_SUCCESS = 'UPDATE_SPRINT_SUCCESS';
export const UPDATE_SPRINT_FAIL = 'UPDATE_SPRINT_FAIL';
export const DELETE_SPRINT_START = 'DELETE_SPRINT_START';
export const DELETE_SPRINT_SUCCESS = 'DELETE_SPRINT_SUCCESS';
export const DELETE_SPRINT_FAIL = 'DELETE_SPRINT_FAIL';

// Saga
export const LOAD_SPRINTS = 'LOAD_SPRINTS';
export const CREATE_SPRINT = 'CREATE_SPRINT';
export const LOAD_SPRINT = 'LOAD_SPRINT';
export const UPDATE_SPRINT = 'UPDATE_SPRINT';
export const DELETE_SPRINT = 'DELETE_SPRINT';

// ITERATION
export const CREATE_ITERATION_START = 'CREATE_ITERATION_START';
export const CREATE_ITERATION_SUCCESS = 'CREATE_ITERATION_SUCCESS';
export const CREATE_ITERATION_FAIL = 'CREATE_ITERATION_FAIL';
export const LOAD_ITERATION_START = 'LOAD_ITERATION_START';
export const LOAD_ITERATION_SUCCESS = 'LOAD_ITERATION_SUCCESS';
export const LOAD_ITERATION_FAIL = 'LOAD_ITERATION_FAIL';
export const UPDATE_ITERATION_START  = 'UPDATE_ITERATION_START';
export const UPDATE_ITERATION_SUCCESS = 'UPDATE_ITERATION_SUCCESS';
export const UPDATE_ITERATION_FAIL = 'UPDATE_ITERATION_FAIL';
export const EXECUTE_ITERATION_START = 'EXECUTE_ITERATION_START';
export const EXECUTE_ITERATION_SUCCESS = 'EXECUTE_ITERATION_SUCCESS';
export const EXECUTE_ITERATION_FAIL = 'EXECUTE_ITERATION_FAIL';
export const CAN_CREATE_ITERATION_START = 'CAN_CREATE_ITERATION_START';
export const CAN_CREATE_ITERATION_SUCCESS = 'CAN_CREATE_ITERATION_SUCCESS';
export const CAN_CREATE_ITERATION_FAIL = 'CAN_CREATE_ITERATION_FAIL';

// Saga
export const CREATE_ITERATION = 'CREATE_ITERATION';
export const LOAD_ITERATION = 'LOAD_ITERATION';
export const UPDATE_ITERATION = 'UPDATE_ITERATION';
export const EXECUTE_ITERATION = 'EXECUTE_ITERATION';
export const CAN_CREATE_ITERATION = 'CAN_CREATE_ITERATION';

// Report
export const LOAD_REPORT_START = 'LOAD_REPORT_START';
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_FAIL = 'LOAD_REPORT_FAIL';
export const DOWNLOAD_SCREENSHOT_START = 'DOWNLOAD_SCREENSHOT_START';
export const DOWNLOAD_SCREENSHOT_SUCCESS = 'DOWNLOAD_SCREENSHOT_SUCCESS';
export const DOWNLOAD_SCREENSHOT_FAIL = 'DOWNLOAD_SCREENSHOT_FAIL';

// Saga
export const LOAD_REPORT = 'LOAD_REPORT';
export const DOWNLOAD_SCREENSHOT = 'DOWNLOAD_SCREENSHOT';

// Payment
// export const LOAD_SESSION_IDS_START = 'LOAD_SESSION_IDS_START';
// export const LOAD_SESSION_IDS_SUCCESS = 'LOAD_SESSION_IDS_SUCCESS';
// export const LOAD_SESSION_IDS_FAIL = 'LOAD_SESSION_IDS_FAIL';
// export const VERIFY_PAYMENT_START = 'VERIFY_PAYMENT_START';
// export const VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS';
// export const VERIFY_PAYMENT_FAIL = 'VERIFY_PAYMENT_FAIL';
export const LOAD_CURRENT_PLAN_START = 'LOAD_CURRENT_PLAN_START';
export const LOAD_CURRENT_PLAN_SUCCESS = 'LOAD_CURRENT_PLAN_SUCCESS';
export const LOAD_CURRENT_PLAN_FAIL = 'LOAD_CURRENT_PLAN_FAIL';
export const LOAD_PLANS_START = 'LOAD_PLANS_START';
export const LOAD_PLANS_SUCCESS = 'LOAD_PLANS_SUCCESS';
export const LOAD_PLANS_FAIL = 'LOAD_PLANS_FAIL';
export const LOAD_PAID_CUSTOMER_PLANS_START = 'LOAD_PAID_CUSTOMER_PLANS_START';
export const LOAD_PAID_CUSTOMER_PLANS_SUCCESS = 'LOAD_PAID_CUSTOMER_PLANS_SUCCESS';
export const LOAD_PAID_CUSTOMER_PLANS_FAIL = 'LOAD_PAID_CUSTOMER_PLANS_FAIL';
export const CHECK_PAYMENT_START = 'CHECK_PAYMENT_START';
export const CHECK_PAYMENT_SUCCESS = 'CHECK_PAYMENT_SUCCESS';
export const CHECK_PAYMENT_FAIL = 'CHECK_PAYMENT_FAIL';
export const CANCEL_PAID_SUBSCRIPTION_START = 'CANCEL_PAID_SUBSCRIPTION_START';
export const CANCEL_PAID_SUBSCRIPTION_SUCCESS = 'CANCEL_PAID_SUBSCRIPTION_SUCCESS';
export const CANCEL_PAID_SUBSCRIPTION_FAIL = 'CANCEL_PAID_SUBSCRIPTION_FAIL';
export const CHANGE_PAID_SUBSCRIPTION_START = 'CHANGE_PAID_SUBSCRIPTION_START';
export const CHANGE_PAID_SUBSCRIPTION_SUCCESS = 'CHANGE_PAID_SUBSCRIPTION_SUCCESS';
export const CHANGE_PAID_SUBSCRIPTION_FAIL = 'CHANGE_PAID_SUBSCRIPTION_FAIL';
export const DELETE_PAYMENT_SESSIONS_START = 'DELETE_PAYMENT_SESSIONS_START';
export const DELETE_PAYMENT_SESSIONS_SUCCESS = 'DELETE_PAYMENT_SESSIONS_SUCCESS';
export const DELETE_PAYMENT_SESSIONS_FAIL = 'DELETE_PAYMENT_SESSIONS_FAIL';
export const LOAD_INVOICES_START = 'LOAD_INVOICES_START';
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS';
export const LOAD_INVOICES_FAIL = 'LOAD_INVOICES_FAIL';

// Saga
// export const LOAD_SESSION_IDS = 'LOAD_SESSION_IDS';
// export const VERIFY_PAYMENT = 'VERIFY_PAYMENT';
// export const CREATE_CUSTOMER_PORTAL_SESSION = 'CREATE_CUSTOMER_PORTAL_SESSION';
export const LOAD_CURRENT_PLAN = 'LOAD_CURRENT_PLAN';
export const LOAD_PLANS = 'LOAD_PLANS';
export const LOAD_PAID_CUSTOMER_PLANS = 'LOAD_PAID_CUSTOMER_PLANS';
export const CHECK_PAYMENT = 'CHECK_PAYMENT';
export const CANCEL_PAID_SUBSCRIPTION = 'CANCEL_PAID_SUBSCRIPTION';
export const CHANGE_PAID_SUBSCRIPTION = 'CHANGE_PAID_SUBSCRIPTION';
export const DELETE_PAYMENT_SESSIONS = 'DELETE_PAYMENT_SESSIONS';
export const LOAD_INVOICES = 'LOAD_INVOICES';


// summary Summary Graph
export const LOAD_SUMMARY_CHART_DATA_START = 'LOAD_SUMMARY_CHART_DATA_START';
export const LOAD_SUMMARY_CHART_DATA_SUCCESS = 'LOAD_SUMMARY_CHART_DATA_SUCCESS';
export const LOAD_SUMMARY_CHART_DATA_FAIL = 'LOAD_SUMMARY_CHART_DATA_FAIL';
export const LOAD_DASHBOARD_STATISTIQUES_START = 'LOAD_DASHBOARD_STATISTIQUES_START';
export const LOAD_DASHBOARD_STATISTIQUES_SUCCESS = 'LOAD_DASHBOARD_STATISTIQUES_SUCCESS';
export const LOAD_DASHBOARD_STATISTIQUES_FAIL = 'LOAD_DASHBOARD_STATISTIQUES_FAIL';

// Saga
export const LOAD_SUMMARY_CHART_DATA = 'LOAD_SUMMARY_CHART_DATA';
export const LOAD_DASHBOARD_STATISTIQUES = 'LOAD_DASHBOARD_STATISTIQUES';

// REST
export const CREATE_REST_REQUEST_START = 'CREATE_REST_REQUEST_START';
export const CREATE_REST_REQUEST_SUCCESS = 'CREATE_REST_REQUEST_SUCCESS';
export const CREATE_REST_REQUEST_FAIL = 'CREATE_REST_REQUEST_FAIL';
export const LOAD_REST_REQUESTS_START = 'LOAD_REST_REQUESTS_START';
export const LOAD_REST_REQUESTS_SUCCESS = 'LOAD_REST_REQUESTS_SUCCESS';
export const LOAD_REST_REQUESTS_FAIL = 'LOAD_REST_REQUESTS_FAIL';
export const DELETE_REST_REQUEST_START = 'DELETE_REST_REQUEST_START';
export const DELETE_REST_REQUEST_SUCCESS = 'DELETE_REST_REQUEST_SUCCESS';
export const DELETE_REST_REQUEST_FAIL = 'DELETE_REST_REQUEST_FAIL';
export const LOAD_REST_REQUEST_START = 'LOAD_REST_REQUEST_START';
export const LOAD_REST_REQUEST_SUCCESS = 'LOAD_REST_REQUEST_SUCCESS';
export const LOAD_REST_REQUEST_FAIL = 'LOAD_REST_REQUEST_FAIL';
export const UPDATE_REST_REQUEST_START  = 'UPDATE_REST_REQUEST_START';
export const UPDATE_REST_REQUEST_SUCCESS = 'UPDATE_REST_REQUEST_SUCCESS';
export const UPDATE_REST_REQUEST_FAIL = 'UPDATE_REST_REQUEST_FAIL';
export const LOAD_REST_REQUESTS_NAMES_START = 'LOAD_REST_REQUESTS_NAMES_START';
export const LOAD_REST_REQUESTS_NAMES_SUCCESS = 'LOAD_REST_REQUESTS_NAMES_SUCCESS';
export const LOAD_REST_REQUESTS_NAMES_FAIL = 'LOAD_REST_REQUESTS_NAMES_FAIL';
export const CREATE_REST_REQUEST_OF_DOMAIN_START = 'CREATE_REST_REQUEST_OF_DOMAIN_START';
export const CREATE_REST_REQUEST_OF_DOMAIN_SUCCESS = 'CREATE_REST_REQUEST_OF_DOMAIN_SUCCESS';
export const CREATE_REST_REQUEST_OF_DOMAIN_FAIL = 'CREATE_REST_REQUEST_OF_DOMAIN_FAIL';
export const LOAD_PROJECT_REST_REQUESTS_START = 'LOAD_PROJECT_REST_REQUESTS_START';
export const LOAD_PROJECT_REST_REQUESTS_SUCCESS = 'LOAD_PROJECT_REST_REQUESTS_SUCCESS';
export const LOAD_PROJECT_REST_REQUESTS_FAIL = 'LOAD_PROJECT_REST_REQUESTS_FAIL';
export const DELETE_REST_REQUEST_OF_DOMAIN_START = 'DELETE_REST_REQUEST_OF_DOMAIN_START';
export const DELETE_REST_REQUEST_OF_DOMAIN_SUCCESS = 'DELETE_REST_REQUEST_OF_DOMAIN_SUCCESS';
export const DELETE_REST_REQUEST_OF_DOMAIN_FAIL = 'DELETE_REST_REQUEST_OF_DOMAIN_FAIL';

// Saga
export const CREATE_REST_REQUEST = 'CREATE_REST_REQUEST';
export const LOAD_REST_REQUESTS = 'LOAD_REST_REQUESTS';
export const DELETE_REST_REQUEST = 'DELETE_REST_REQUEST';
export const LOAD_REST_REQUEST = 'LOAD_REST_REQUEST';
export const UPDATE_REST_REQUEST = 'UPDATE_REST_REQUEST';
export const LOAD_REST_REQUESTS_NAMES = 'LOAD_REST_REQUESTS_NAMES';
export const CREATE_REST_REQUEST_OF_DOMAIN = 'CREATE_REST_REQUEST_OF_DOMAIN';
export const LOAD_PROJECT_REST_REQUESTS = 'LOAD_PROJECT_REST_REQUESTS';
export const DELETE_REST_REQUEST_OF_DOMAIN = 'DELETE_REST_REQUEST_OF_DOMAIN';

// SETTINGS

// USERS TABLE
export const SET_USERS_TABLE_PROPERTIES = 'SET_USERS_TABLE_PROPERTIES';

