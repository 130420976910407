import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {connect} from "react-redux";

import RestRequestDisplay from "../../components/Rest/RestRequestDisplay";
import * as AuthenticationType from "../../components/Rest/enum/AuthenticationType";
import * as AssertionType from "../../components/Rest/enum/AssertionType";
import {HEADER} from "../../components/Rest/enum/APIKeyType";
import {
	validateAuthentication,
	validateCore,
	validateDescription,
	validateHeaders,
	validateParameters,
	validateResponseCollection
} from "./Validator";
import * as actions from "../../store/actions";

const NewRestRequest = (props) => {
	const intl = useIntl();

	useEffect(() => {
		document.title = intl.formatMessage({ id: "PAGE.TITLE.NEW.REST", defaultMessage: 'New Rest Request'});
	}, []);

	// Error Messages
	const [errorMessages, setErrorMessages] = useState([]);

	// Validate forms on mount. for Auth Forms,
	// excluded: Parameters, Headers, Body JSON, Assertions
	// exception: Description always validate on mount
	const [validate, setValidate] = useState({});

	// Select Tab
	const [selectTab, setSelectTab] = useState(0);

	// Core
	const [core, setCore] = useState({name: '', method: '', url: ''});

	useEffect(() => {
		if(props.project){
			setCore({...core, url: props.project.url});
		}
	}, [props.project])

	const validateCoreFormRef = React.useRef();
	const registerValidateCoreFormCallback = callback => {
		validateCoreFormRef.current = callback;
	};

	// Description
	const [description, setDescription] = useState('');
	const validateDescriptionFormRef = React.useRef();
	const registerValidateDescriptionFormCallback = callback => {
		validateDescriptionFormRef.current = callback;
	};

	// Parameters
	const [parameters, setParameters] = useState([{key: '', value: ''}]);

	// Headers
	//Content-Type :
	// - Accept : "*/*"
	// - Accept-Encoding : "gzip", "deflate", "br"
	const [headers, setHeaders] = useState([{key: 'Content-Type', value: ["application/json"]},
		{key: 'Accept', value: ["*/*"]}, {key: 'Accept-Encoding', value: ["gzip", "deflate", "br"]}
	]);

	// Body
	const [body, setBody] = useState('');

	// Authentication
	const [authentication, setAuthentication] = useState({
		authType: AuthenticationType.INHERIT,
		username: '',
		password: '',
		token: '',
		apiKey: {
			key: '',
			value: '',
			type: HEADER
		}
	});

	// Assertions: empty by default
	const [assertions, setAssertions] = useState([
	// 	{
	// 	type: RESPONSE_BODY,
	// 	responseBodyCondition: CONTAINS,
	// 	quantityCondition: MATCHES,
	// 	value: ''
	// }
	]);

	const [responseCollection, setResponseCollection] = useState({responsePath: '', suiteVariable: ''});

	useEffect(() => {
		const updatedGlobalMessages = [...errorMessages];
		if(props.error){
			updatedGlobalMessages.push(props.error);
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.error]);

	const validateAuthBasicFormRef = React.useRef();
	const registerValidateAuthBasicFormCallback = callback => {
		validateAuthBasicFormRef.current = callback;
	};

	const validateAuthTokenFormRef = React.useRef();
	const registerValidateAuthTokenFormCallback = callback => {
		validateAuthTokenFormRef.current = callback;
	};

	const validateAuthAPIKeyFormRef = React.useRef();
	const registerValidateAuthAPIKeyFormCallback = callback => {
		validateAuthAPIKeyFormRef.current = callback;
	};

	const validateAuthResponseCollectionFormRef = useRef();
	const registerValidateResponseCollectionFormCallback = callback => {
		validateAuthResponseCollectionFormRef.current = callback;
	};


	const addAuthAPIKeyToParametersOrHeaders = (headersResult, parametersResult) => {
		if (authentication.authType === AuthenticationType.API_KEY) {
			if (authentication.apiKey.key.trim() !== '' && authentication.apiKey.value.trim() !== '') {
				if (authentication.apiKey.type === HEADER) {
					const injectMe = {
						key: authentication.apiKey.key.trim(),
						value: [authentication.apiKey.value.trim()]
					}
					headersResult.push(injectMe);
				} else {
					const injectMe = {
						key: authentication.apiKey.key.trim(),
						value: authentication.apiKey.value.trim()
					}
					parametersResult.push(injectMe);
				}
			}
		}
	};

	const cleanAndBuildParametersAndHeaders = () => {
		const headersResult = [...headers];
		const parametersResult = [...parameters];
		// Clean headers and parameters
		// make sure that parameters & headers: if length is 1 and key is empty it means, empty object
		if (headersResult.length === 1 && headersResult[0].key.trim() === '') {
			headersResult.splice(0, 1);
		}
		if (parametersResult.length === 1 && parametersResult[0].key.trim() === '') {
			parametersResult.splice(0, 1);
		}

		// API Key move to Headers or Parameters
		addAuthAPIKeyToParametersOrHeaders(headersResult, parametersResult);
		const parametersObject = buildParameters(parametersResult);
		const headersObject = buildHeaders(headersResult);
		return [parametersObject, headersObject]
	}

	const buildParameters = (parametersRes) => {
		const parametersAsObject = {};
		for(let param of parametersRes){
			parametersAsObject[param.key] = param.value;
		}
		return parametersAsObject;
	}

	const buildHeaders = (headersResult) => {
		const HeadersAsObject = {};
		for(let header of headersResult){
			HeadersAsObject[header.key] = header.value;
		}
		return HeadersAsObject
	}

	const submit = (event) => {
		event.preventDefault();
		const updatedGlobalMessage = [];
		setErrorMessages(updatedGlobalMessage);
		// validate core only
		if(!validateCore(validateCoreFormRef)){
			return null;
		}

		if(!validateDescription(validateDescriptionFormRef, setSelectTab)){
			return null;
		}

		// Parameters
		if(!validateParameters(updatedGlobalMessage, parameters, intl, setErrorMessages, setSelectTab)){
			return null;
		}

		// Headers
		if(!validateHeaders(updatedGlobalMessage, headers, intl, setErrorMessages, setSelectTab)){
			return null;
		}

		// Body Validation not required

		// Authentication
		if(!validateAuthentication(authentication, validateAuthBasicFormRef, setSelectTab,
			setValidate, validateAuthTokenFormRef, validateAuthAPIKeyFormRef)){
			return null;
		}

		// Response Collection
		if(!validateResponseCollection(validateAuthResponseCollectionFormRef)){
			return null;
		}

		// Assertions
		// if(!validateAssertions(updatedGlobalMessage, assertions, intl, setErrorMessages, setSelectTab)){
		// 	return null;
		// }

		const restRequest = {};
		restRequest.label = core.name;
		restRequest.method = core.method;
		restRequest.uri = core.url;
		restRequest.projectId = props.project.id;

		restRequest.description = description;

		const [parametersResult, headersResult] = cleanAndBuildParametersAndHeaders();

		restRequest.parameters = parametersResult;
		restRequest.httpHeaders = headersResult;
		restRequest.body = body;

		restRequest.authorization = authentication;
		restRequest.assertions = buildAssertions();

		restRequest.reuseResponseApi = responseCollection;

		const domainId = new URLSearchParams(props.location.search).get('domainId')
		const isDomainRequest = domainId ? true : false;
		if(isDomainRequest){
			// console.log('Domain Saga');
			props.onCreateRestRequestOfDomain(domainId, restRequest);
		} else {
			// console.log('Rest Saga');
			props.onCreateRestRequest(props.project.id, restRequest);
		}

	}

	const buildAssertions = () => {
		const cleanedAssertions = [];
		for(let assertItem of assertions){
			switch (assertItem.type) {
				case AssertionType.RESPONSE_BODY:
					delete assertItem.quantityCondition;
					break;
				case AssertionType.STATUS_CODE:
					delete assertItem.responseBodyCondition;
					break;
			}
			cleanedAssertions.push(assertItem);
		}
		return cleanedAssertions;
	}

	return <RestRequestDisplay submitForm = {submit}
	                           validate={validate}

	                           core={core}
	                           setCore={setCore}
	                           registerValidateCoreFormCallback={registerValidateCoreFormCallback}

	                           description={description}
	                           setDescription={setDescription}
	                           registerValidateDescriptionFormCallback={registerValidateDescriptionFormCallback}

	                           parameters={parameters}
	                           setParameters={setParameters}

	                           errorMessages={errorMessages}

	                           selectTab={selectTab}
	                           setSelectTab={setSelectTab}

	                           headers={headers}
	                           setHeaders={setHeaders}

	                           body = {body}
	                           setBody = {setBody}

	                           authentication = {authentication}
	                           setAuthentication = {setAuthentication}
	                           registerValidateAuthBasicFormCallback = {registerValidateAuthBasicFormCallback}

	                           registerValidateAuthTokenFormCallback={registerValidateAuthTokenFormCallback}
	                           registerValidateAuthAPIKeyFormCallback={registerValidateAuthAPIKeyFormCallback}

	                           assertions={assertions}
	                           setAssertions={setAssertions}

							   responseCollection={responseCollection}
							   setResponseCollection={setResponseCollection}
							   registerValidateResponseCollectionFormCallback={registerValidateResponseCollectionFormCallback}

	                           type='create'
	/>
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		error: state.restRequest.creationError,
		// loading: state.restRequest.creationLoading    not need because we're using RestRequestCreationLoadingDialog
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateRestRequest: (projectID, restRequest) => dispatch(actions.createRestRequest(projectID, restRequest)),
		onCreateRestRequestOfDomain: (domainId, restRequest) => dispatch(actions.createRestRequestOfDomain(domainId, restRequest)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRestRequest);
