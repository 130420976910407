export const UINames = (name, intl) => {
	switch (name) {
		case 'name':
			return intl.formatMessage({ id: "SUITE.MESSAGE.NAME",
				defaultMessage: 'Suite Name' });
		case 'description':
			return intl.formatMessage({ id: "SUITE.MESSAGE.DESCRIPTION",
				defaultMessage: 'Description' });
		case 'addTests':
			return intl.formatMessage({ id: "SUITE.MESSAGE.TESTS",
				defaultMessage: 'Tests' });
		case 'test':
			return intl.formatMessage({ id: "SUITE.MESSAGE.TEST",
				defaultMessage: 'Test'});
		case 'rest-request':
			return intl.formatMessage({ id: "SUITE.PREREQUISITE.NAME.MESSAGE",
				defaultMessage: 'Rest Request' });
		default:
			return '';
	}
}