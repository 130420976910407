import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import * as Role from "../../containers/Users/enum/Roles";

const UpgradePlanWidget = props => {
	const intl = useIntl();
	// `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`

	let header = intl.formatMessage({id: "DASHBOARD.UPGRADE.HEADER", defaultMessage: 'Unlock Full Features'});
	let message1 = intl.formatMessage({id: "DASHBOARD.UPGRADE.MESSAGE1", defaultMessage: 'Upgrade To Pro Or Premium Plan'});
	let message2 = intl.formatMessage({id: "DASHBOARD.UPGRADE.MESSAGE2", defaultMessage: 'More Dashboard Graphs'});
	let disabledBillingLink = <Link to="/billing" className={`btn btn-danger font-weight-bold px-6 py-3 ${disabledBillingLink}`}>
		<FormattedMessage id="DASHBOARD.UPGRADE.UNLOCK" defaultMessage='Unlock'/>
	</Link>;
	if (localStorage.getItem('Top-Role') !== Role.ROLE_SUPER && localStorage.getItem('Top-Role') !== Role.ROLE_ADMIN) {
		// header = "Unlock Full Features";
		message1 = intl.formatMessage({id: "DASHBOARD.UPGRADE.MESSAGE1.ALTERNATIVE", defaultMessage: 'Ask Your Admin To Upgrade To'});
		message2 = intl.formatMessage({id: "DASHBOARD.UPGRADE.MESSAGE2.ALTERNATIVE", defaultMessage: 'Pro Or Premium Plan'});
		disabledBillingLink = null;
	}
	return <div className="card card-custom bgi-no-repeat gutter-b card-stretch"
				style={{
					backgroundColor: "#1B283F", backgroundPosition: "0 calc(100% + 0.5rem)", backgroundSize: "100% auto",
					// backgroundImage: "url(/media/svg/patterns/rhone.svg)"}}>
					backgroundImage: `url(${toAbsoluteUrl("/media/svg/patterns/rhone.svg")})`
				}}>
		<div className="card-body">
			<div className="p-4">
				<h3 className="text-white font-weight-bolder my-7">{header}</h3>
				<p className="text-muted font-size-lg mb-7">
					{message1}<br/>
					{message2}
					{/*<br/>that seeks to increase profits*/}
					{/*<br/>to better society*/}
				</p>
				{disabledBillingLink}
			</div>
		</div>
	</div>;

}
export default UpgradePlanWidget;