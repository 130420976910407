import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import AddForm from "../../components/Domain/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";

const NewDomain = (props) => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage:
			null
	});

	useEffect(() => {
		document.title = intl.formatMessage({
			id: "PAGE.TITLE.NEW.DOMAIN",
			defaultMessage: 'New Domain'
		});
	}, []);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}

		return allValid;
	}

	const submitDomainHandler = (event) => {
		event.preventDefault();

		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const domain = {
			label: name.value,
			description: description.value
		};
		const projectID = props.project.id;
		props.onCreateDomain(domain, projectID);
	}

	return (
		<AddForm name = {name}
		         description = {description}
		         nameChangeHandler = {nameChangeHandler}
		         descriptionChangeHandler = {descriptionChangeHandler}
		         submitDomainHandler = {submitDomainHandler}
		         error = {props.error}
		         loading = {props.loading}
		         type = 'create'/>
	)
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		error: state.domain.creationError,
		loading: state.domain.creationLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateDomain: (domain, projectID) => dispatch(actions.createDomain(domain, projectID)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDomain);