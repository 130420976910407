import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import * as actions from "../../store/actions";
import TestDisplay from "../../components/Test/TestDisplay";
import DomainsView from "../Domain/DomainsView";

const Test = props => {

	const [test, setTest] = useState(null);
	const [instructions, setInstructions] = useState([]);

	const [asserts, setAsserts] = useState([]);

	useEffect(() => {
		if(props.test){
			setTest(props.test);
			if(Array.isArray(props.test.instructions)){
				setInstructions(props.test.instructions);
			}
			if(Array.isArray(props.test.testAsserts)){
				setAsserts(props.test.testAsserts);
			}
			document.title = 'Test: ' + props.test.label;
		}
	}, [props.test]);

	useEffect(() => {
		props.onLoadTest(props.match.params.testID);
	}, []);

	useEffect(() => {
		if(props.error){
			if(props.test.instructions === undefined){
				setInstructions([]);
			} else if(Array.isArray(props.test.instructions)){
				setInstructions(props.test.instructions);
			}
			if(props.test.testAsserts === undefined){
				setInstructions([]);
			} else if(Array.isArray(props.test.testAsserts)){
				setAsserts(props.test.testAsserts);
			}
		}
	}, [props.error]);

	const updateInstructions = (instructions) => {
		const id = props.test.id;
		const testPatch = {instructions: {value: instructions}};
		props.onUpdateTest(id, testPatch);
		// props.onLoadTest(id);
	}

	const updateAsserts = (asserts) => {
		const id = props.test.id;
		const testPatch = {testAsserts: {value: asserts}};
		props.onUpdateTest(id, testPatch);
		// props.onLoadTest(id);
	}

	const updateBulk = newTest => {
		props.onUpdateTest(props.test.id, newTest);
	}

	const query = new URLSearchParams(props.location.search);
	const edit = query.get('edit') ? true : false;

	return (
		<Container className = 'p-0'>
			<Row>
				<Col md = {12} lg = {3}>
					<DomainsView {...props}/>
				</Col>
				<Col md={12} lg={9}>
					{test && <TestDisplay domain = {props.domain}
					                      test = {test}
					                      instructions = {instructions}
					                      setInstructions = {setInstructions}
					                      updateInstructions = {updateInstructions}
					                      asserts = {asserts}
					                      setAsserts = {setAsserts}
					                      updateAsserts = {updateAsserts}
					                      updateBulk = {updateBulk}
					                      edit = {edit} testID = {props.match.params.testID}
					                      loading={props.loading}
										  error={props.error}
										  nullifyUpdatingTestError={props.nullifyUpdatingTestError}
					/>}
				</Col>
			</Row>
		</Container>
	);
}

const mapStateToProps = state => {
	return {
		test: state.test.test,
		loading: state.test.updatingLoading,
		error: state.test.updatingError,
		domain: state.domain.domain
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadTest: (id) => dispatch(actions.loadTest(id)),
		onUpdateTest: (id, test) => dispatch(actions.updateTest(id, test)),
		nullifyUpdatingTestError: () => dispatch(actions.nullifyUpdatingTestError()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
