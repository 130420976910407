import React from "react";
import {Link} from "react-router-dom";
import {Alert} from "@material-ui/lab";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import SVG from "react-inlinesvg";
import {FormattedMessage, useIntl} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {SprintsLoadingDialog} from "../../UI/LoadingDialog";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const SprintsDisplay = (props) => {
	const intl = useIntl();

	const [open, setOpen] = React.useState(false);

	const [sprint, setSprint] = React.useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sprintNameBodyTemplate = (rowData) => {
		let select = 'text-secondary';
		if (props.sprintID === rowData.id) {
			select = 'text-success';
		}
		return <Link to = {`/sprint/${rowData.id}`}
		             title = {intl.formatMessage({
			             id: "SPRINTS.TABLE.HOVER.LINK.VIEW.SPRINT",
			             defaultMessage: 'View Sprint'
		             })}
		             className = {`h4 ${select}`}>
			{rowData.label}
		</Link>;
	}

	const sprintActionsBodyTemplate = (rowData) => {

		return (
			<a title = {intl.formatMessage({
				id: "SPRINTS.TABLE.HOVER.LINK.DELETE.SPRINT",
				defaultMessage: 'Delete Sprint'
			})}
			   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				// onClick = {() => props.delete(rowData.id)}
               onClick = {() => {
	               setSprint(rowData);
	               handleClickOpen();
               }}
			>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
			          <SVG
				          title = {intl.formatMessage({
					          id: "SPRINTS.TABLE.HOVER.LINK.DELETE.SPRINT",
					          defaultMessage: 'Delete Sprint'
				          })}
				          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
			</a>
		);
	}


	const labelColumn = <Column key = 'label' columnKey = 'label' field = 'label'
											header = {intl.formatMessage({
												id: "SPRINTS.TABLE.COLUMN.NAME",
												defaultMessage: 'Name'
											})}
											body = {sprintNameBodyTemplate}
											filter filterPlaceholder = {intl.formatMessage({
		id: 'SPRINTS.TABLE.COLUMN.SEARCH.BY.NAME',
		defaultMessage: 'Search by name'
	})}
											filterMatchMode = 'contains'/>;
	let actionsColumn = null;
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		actionsColumn = <Column key = 'actions' header = {intl.formatMessage({
			id: "SPRINTS.TABLE.COLUMN.ACTIONS", defaultMessage: 'Actions'
		})}
								body = {sprintActionsBodyTemplate} className = 'p-text-right'/>;
	}

	let actions =
		<div className = 'text-center'>
			<Link className = "btn btn-primary mb-2 mt-2"
			      to = '/sprint/new'>
				<FormattedMessage id = "SPRINTS.BUTTON.NEW"
				                  defaultMessage = "New Sprint"/>
			</Link>
		</div>
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		actions = null;

	}

	let errorMessage = null;
	if (props.error) {
		errorMessage = <Alert elevation = {6} variant = "filled"
		                      severity = "error" className = 'mt-6 mb-5'>
			{props.error}
		</Alert>
	}

	let sprintsTable = <DataTable value = {props.sprints} lazy ref = {props.dt}
								  paginator first = {props.lazyParams.first} rows = {props.lazyParams.rows}
								  totalRecords = {props.totalSprints}
								  onPage = {props.onPage} onSort = {props.onSort}
								  sortField = {props.lazyParams.sortField} sortOrder = {props.lazyParams.sortOrder}
								  onFilter = {props.onFilter}
								  filters = {props.lazyParams.filters}
								  autoLayout={true} /*make sure it does niot introduce layout problem*/
								  currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
								  rowsPerPageOptions = {[10, 20, 50, 100]}
								  paginatorTemplate =
									  "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
								  emptyMessage = {intl.formatMessage({
									  id: "SPRINTS.EMPTY.LIST",
									  defaultMessage: 'No Sprints'
								  })}
		>
			{labelColumn}
			{actionsColumn}
		</DataTable>;

	const display = <div className = 'bg-white'>
		<SprintsLoadingDialog/>
		{actions}
		{errorMessage}
		{sprintsTable}
		<DeletionConfirmationDialog open={open} closeDialog={handleClose}
									message={<FormattedMessage id = "SPRINTS.TABLE.REMOVE.SPRINT"
															   defaultMessage = 'Remove Sprint'/> }
									name={sprint ? `${sprint.label}` : ''}
									deleteAction={() => {
										handleClose();
										props.delete(sprint.id);
									}}
		/>
	</div>;

	return display;
}

export default SprintsDisplay;