import React from "react";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from "@material-ui/styles";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const APITestActions = props => {
	const classes = useStyles();

	return <div className={`bg-white p-1 text-center ${classes.root}`}>
		<Button variant="contained" component={Link} size='small' startIcon={<AddIcon/>} to={`/rest-request/new?domainId=${props.domainId}`}>
			<FormattedMessage id="ADD.TEST" defaultMessage="Test"/>
		</Button>

	</div>;
}

export default APITestActions;