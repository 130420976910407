import React from "react";
import {makeStyles} from "@material-ui/styles";

import AddRestRequestsToSuiteForm from "../../containers/Suite/AddRestRequestsToSuiteForm";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const SuiteRestRequestActions = props => {
	const classes = useStyles();

	return (
			<div className = {`bg-white p-1 text-center ${classes.root}`}>

				<AddRestRequestsToSuiteForm {...props}/>

			</div>
	)
}

export default SuiteRestRequestActions;