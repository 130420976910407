import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import PasswordChecklist from "react-password-checklist"

const RegistrationFormJSX = props => {
	const intl = useIntl();

	const usernameInput = <div className = "form-group fv-plugins-icon-container">
		<label htmlFor='username'>
			<FormattedMessage id = 'REGISTRATION.LABEL.USERNAME' defaultMessage = 'User Name'/>
		</label>
		<input
			placeholder = {intl.formatMessage({
				id: "REGISTRATION.PLACEHOLDER.USERNAME",
				defaultMessage: 'User Name'
			})}
			className = {`form-control form-control-solid h-auto py-5 px-6 `}
			name = "username"
			id = "username"
			value = {props.username.value}
			onChange = {props.usernameChangeHandler}
			onBlur = {() => props.syncValueWithParentContainer('username')}
		/>
		{!props.username.valid ?
			(
				<div className = "fv-plugins-message-container ml-3">
					<div className = "fv-help-block">
						{props.username.validationMessage}
					</div>
				</div>
			) : null}
	</div>;

	const emailInput = <div className = "form-group fv-plugins-icon-container">
            <label htmlFor='email'>
	            <FormattedMessage id='REGISTRATION.LABEL.EMAIL' defaultMessage='Email'/>
            </label>
			<input
				placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.EMAIL',
					defaultMessage: 'Email' })}
				type = "email"
				className = {`form-control form-control-solid h-auto py-5 px-6 `}
				id = "email"
				name = "email"
				value = {props.email.value}
				onChange = {props.emailChangeHandler}
				onBlur={() => props.syncValueWithParentContainer('email')}
			/>
			{!props.email.valid ?
				(
					<div className = "fv-plugins-message-container ml-3">
						<div className = "fv-help-block">
							{props.email.validationMessage}
						</div>
					</div>
				) : null}
		</div>;

	const companyInput = <div className = "form-group fv-plugins-icon-container">
	    <label htmlFor='first-name'>
	        <FormattedMessage id='REGISTRATION.LABEL.COMPANY' defaultMessage='Company Name'/>
	    </label>
		<input
			placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.COMPANY',
				defaultMessage: 'Company Name' })}
			className = {`form-control form-control-solid h-auto py-5 px-6 `}
			id = "company"
			name = "company"
			value = {props.company.value}
			onChange = {props.companyChangeHandler}
			onBlur={() => props.syncValueWithParentContainer('company')}
		/>
		{!props.company.valid ?
			(
				<div className = "fv-plugins-message-container ml-3">
					<div className = "fv-help-block">
						{props.company.validationMessage}
					</div>
				</div>
			) : null}
	</div>;

	// const firstNameInput = <div className = "form-group fv-plugins-icon-container">
    //     <label htmlFor='first-name'>
    //         <FormattedMessage id='REGISTRATION.LABEL.FIRSTNAME'
	// 	                         defaultMessage='First Name'/>
    //     </label>
	// 	<input
	// 		placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.FIRSTNAME',
	// 			defaultMessage: 'First Name' })}
	// 		className = {`form-control form-control-solid h-auto py-5 px-6 `}
	// 		id = "first-name"
	// 		name = "first-name"
	// 		value = {props.firstName.value}
	// 		onChange = {props.firstNameChangeHandler}
	// 		onBlur={() => props.syncValueWithParentContainer('firstName')}
	// 	/>
	// 	{!props.firstName.valid ?
	// 		(
	// 			<div className = "fv-plugins-message-container ml-3">
	// 				<div className = "fv-help-block">
	// 					{props.firstName.validationMessage}
	// 				</div>
	// 			</div>
	// 		) : null}
	// </div>

	// const lastNameInput = <div className = "form-group fv-plugins-icon-container">
    //                 <label htmlFor='last-name'>
	//                     <FormattedMessage id='REGISTRATION.LABEL.LASTNAME'
	// 				                         defaultMessage='Last Name'/>
    //                 </label>
	// 				<input
	// 					placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.LASTNAME',
	// 						defaultMessage: 'Last Name' })}
	// 					className = {`form-control form-control-solid h-auto py-5 px-6 `}
	// 					id = "last-name"
	// 					name = "last-name"
	// 					value = {props.lastName.value}
	// 					onChange = {props.lastNameChangeHandler}
	// 					onBlur={() => props.syncValueWithParentContainer('lastName')}
	// 				/>
	// 				{!props.lastName.valid ?
	// 					(
	// 						<div className = "fv-plugins-message-container ml-3">
	// 							<div className = "fv-help-block">
	// 								{props.lastName.validationMessage}
	// 							</div>
	// 						</div>
	// 					) : null}
	// 			</div>;

	const passwordInput = <div className = "form-group fv-plugins-icon-container">
            <label htmlFor="password">
	            <FormattedMessage id='REGISTRATION.LABEL.PASSWORD'
			                         defaultMessage='Password'/>
            </label>
			<input
				placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.PASSWORD',
					defaultMessage: 'Password' })}
				type = "password"
				autoComplete='new-password'
				className = {`form-control form-control-solid h-auto py-5 px-6 `}
				id = "password"
				name = "password"
				value = {props.password.value}
				onChange = {props.passwordChangeHandler}
				onBlur={() => props.syncValueWithParentContainer('password')}
			/>

			{!props.password.valid ?
				(
					<div className = "fv-plugins-message-container ml-3">
						<div className = "fv-help-block">
							{props.password.validationMessage}
						</div>
					</div>
				) : null}

		</div>;

	const passwordConfirmationInput = <div className = "form-group fv-plugins-icon-container">
            <label htmlFor='password-confirmation'>
	            <FormattedMessage id='REGISTRATION.LABEL.CONFIRM.PASSWORD'
			                         defaultMessage='Confirm Password'/>
            </label>
			<input
				placeholder = {intl.formatMessage({ id: 'REGISTRATION.PLACEHOLDER.CONFIRM.PASSWORD',
					defaultMessage: 'Confirm Password' })}
				type = "password"
				autoComplete='new-password'
				className = {`form-control form-control-solid h-auto py-5 px-6 `}
				id = "password-confirmation"
				name = "password-confirmation"
				value = {props.confirmPassword.value}
				onChange = {props.confirmPasswordChangeHandler}
			/>

			{!props.confirmPassword.valid ?
				(
					<div className = "fv-plugins-message-container ml-3">
						<div className = "fv-help-block">
							{props.confirmPassword.validationMessage}
						</div>
					</div>
				) : null}

		</div>

	return (
		<>
			{/*<h2 className='text-center'>*/}
            {/*    <FormattedMessage id="REGISTRATION.INFORMATION.TITLE" defaultMessage='Informations'/>*/}
			{/*</h2>*/}

			{usernameInput}
			{emailInput}
			{companyInput}
			{passwordInput}
			{passwordConfirmationInput}
			<PasswordChecklist
				rules={["minLength","specialChar","number","capital","match", "maxLength", "lowercase"]}
				minLength={8}
				maxLength={255}
				value={props.password.value}
				valueAgain={props.confirmPassword.value}
				onChange={(isValid) => {
					props.setIsValidPassword(isValid);
				}}
				className='mb-4'
				messages={{
					maxLength: intl.formatMessage({ id: "PASSWORD.CHECKER.MAX.LENGTH", defaultMessage: 'Password has no more than 255 characters.'}),
					minLength: intl.formatMessage({ id: "PASSWORD.CHECKER.MIN.LENGTH", defaultMessage: 'Password has more than 8 characters.'}),
					capital: intl.formatMessage({ id: "PASSWORD.CHECKER.UPPERCASE", defaultMessage: 'Password has a capital letter.'}),
					lowercase: intl.formatMessage({ id: "PASSWORD.CHECKER.LOWERCASE", defaultMessage: 'Password has a lowercase letter.'}),
					match: intl.formatMessage({ id: "PASSWORD.CHECKER.MATCH", defaultMessage: 'Passwords match.'}),
					number: intl.formatMessage({ id: "PASSWORD.CHECKER.NUMBER", defaultMessage: 'Password has a number.'}),
					specialChar: intl.formatMessage({ id: "PASSWORD.CHECKER.SPECIAL.CHARACTER", defaultMessage: 'Password has special characters.'}),
				}}
			/>
		</>
	)
}

export default RegistrationFormJSX;