import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../../axios/axios";

// export function* loadSessionIDs(action) {
// 	yield put(actions.loadSessionIDsStart());
// 	try {
// 		const response = yield axios.post(`/payments/${action.projectID}/project`);
// 		console.log('loadSessionIDs', response.data);
// 		yield put(actions.loadSessionIDsSuccess(response.data));
//
// 	} catch (error) {
// 		if (error.response) {
// 			console.log(error.response.data.message);
// 			yield put(actions.loadSessionIDsFail(error.response.data.message));
// 		} else if (error.request) {
// 			console.log(error.request)
// 			yield put(actions.loadSessionIDsFail('Error Loading SessionIDs, Try Again'));
// 		} else {
// 			console.log('Error', error.message);
// 			yield put(actions.loadSessionIDsFail(error.message));
// 		}
// 	}
// }

// export function* verifyPayment(action) {
// 	yield put(actions.verifyPaymentStart());
// 	try {
// 		const response = yield axios.put(
// 			`/payments/${action.projectID}/project?sessionId=${action.sessionID}`);
// 		console.log('verifyPayment', response.data);
// 		yield put(actions.verifyPaymentSuccess(response.data));
//
// 	} catch (error) {
// 		if (error.response) {
// 			console.log(error.response.data.message);
// 			yield put(actions.verifyPaymentFail(error.response.data.message));
// 		} else if (error.request) {
// 			console.log(error.request)
// 			yield put(actions.verifyPaymentFail('Error Loading verifyPayment, Try Again'));
// 		} else {
// 			console.log('Error', error.message);
// 			yield put(actions.verifyPaymentFail(error.message));
// 		}
// 	}
// }

// export function* createCustomerPortalSession(action) {
// 	try {
// 		yield axios.post(`/payments`, action.returnToUrl);
// 	} catch (error) {
// 		if (error.response) {
// 			console.log(error.response.data.message);
// 		} else if (error.request) {
// 			console.log(error.request)
// 		} else {
// 			console.log('Error', error.message);
// 		}
// 	}
// }

export function* loadCurrentPlan(action) {
	yield put(actions.loadCurrentPlanStart());
	try {
		const response = yield axios.get(`/payments/currentPlan`);
		// console.log('loadCurrentPlan Saga', response.data);
		yield put(actions.loadCurrentPlanSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadCurrentPlanFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadCurrentPlanFail('Error Loading loadCurrentPlan, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadCurrentPlanFail(error.message));
		}
	}
}

export function* loadPlans(action) {
	yield put(actions.loadPlansStart());
	try {
		const response = yield axios.get(`/payments/sessions?redirectUrl=${action.returnUrl}`);
		// console.log('loadPlans Saga', response.data);
		yield put(actions.loadPlansSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadPlansFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadPlansFail('Error Loading loadPlans, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadPlansFail(error.message));
		}
	}
}

export function* checkPayment(action) {
	yield put(actions.checkPaymentStart());
	try {
		const response = yield axios.get(`/payments/sessions/${action.sessionID}/plan`);
		// console.log('checkPayment', response.data);
		yield put(actions.checkPaymentSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.checkPaymentFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.checkPaymentFail('Error Loading checkPayment, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.checkPaymentFail(error.message));
		}
	}
}

export function* cancelPaidSubscription(action) {
	yield put(actions.cancelPaidSubscriptionStart());
	try {
		const response = yield axios.post(`/payments/subscriptions/cancel`);
		// console.log('cancelPaidSubscription');
		yield put(actions.cancelPaidSubscriptionSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.cancelPaidSubscriptionFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.cancelPaidSubscriptionFail('Error Loading cancelPaidSubscription, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.cancelPaidSubscriptionFail(error.message));
		}
	}
}

export function* changePaidSubscription(action) {
	yield put(actions.changePaidSubscriptionStart());
	try {
		const response = yield axios.post(`/payments/subscriptions/upgrade?priceId=${action.priceId}`);
		// console.log('changePaidSubscription');
		yield put(actions.changePaidSubscriptionSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.changePaidSubscriptionFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.changePaidSubscriptionFail('Error Loading changePaidSubscription, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.changePaidSubscriptionFail(error.message));
		}
	}
}

export function* loadPaidCustomerPlans(action) {
	yield put(actions.loadPaidCustomerPlansStart());
	try {
		const response = yield axios.get(`/payments/plans`);
		// console.log('loadPaidCustomerPlans Saga', response.data);
		yield put(actions.loadPaidCustomerPlansSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadPaidCustomerPlansFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadPaidCustomerPlansFail('Error Loading loadPaidCustomerPlans, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadPaidCustomerPlansFail(error.message));
		}
	}
}

export function* deletePaymentSessions(action) {
	yield put(actions.deletePaymentSessionsStart());
	try {
		const response = yield axios.delete(`/payments/sessions`);
		// console.log('deletePaymentSessions Saga', response.data);
		yield put(actions.deletePaymentSessionsSuccess());

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deletePaymentSessionsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deletePaymentSessionsFail('Error Loading deletePaymentSessions, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deletePaymentSessionsFail(error.message));
		}
	}
}

export function* loadInvoices(action) {
	yield put(actions.loadInvoicesStart());
	try {
		const response = yield axios.get(`/payments/invoices` ,{
			params: {...action.config}
		});
		// console.log('loadInvoices Saga', response.data.content);
		yield put(actions.loadInvoicesSuccess(response.data.content,response.data.totalElements));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadInvoicesFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadInvoicesFail('Error Saga loadInvoices, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadInvoicesFail(error.message));
		}
	}
}