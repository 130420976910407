import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import AddForm from "../../components/Iteration/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";

const UpdateIteration = (props) => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage: null
	});

	const [previousNameAndDescription, setPreviousNameAndDescription] = useState({name: '', description: ''});

	const [allNames, setAllNames] = useState([]);

	useEffect(() => {
		const names = [];
		if (props.sprint && props.sprint.iterations) {
			props.sprint.iterations.forEach((it) => {
				names.push(it.label);
			})
		}
		setAllNames(names);
	}, [props.sprint]);

	useEffect(() => {
		if (props.iteration) {
			const updatedName = {...name};
			updatedName.value = props.iteration.label;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = props.iteration.description ? props.iteration.description : '';
			setDescription(updatedDescription);
			setPreviousNameAndDescription({name: props.iteration.label, description: props.iteration.description ? props.iteration.description : ''});

			document.title = intl.formatMessage({
				id: "PAGE.TITLE.ITERATION",
				defaultMessage: 'Iteration: '
			}) + props.iteration.label;
		}
	}, [props.iteration]);

	useEffect(() => {
		if (props.error) {
			const updatedName = {...name};
			updatedName.value = previousNameAndDescription.name;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = previousNameAndDescription.description;
			setDescription(updatedDescription);
		}
	}, [props.error]);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		if (updatedName.valid) {
			// check if name already exists
			allNames.forEach((name) => {
				if (name === updatedName.value) {
					updatedName.valid = false;
					updatedName.validationMessage = intl.formatMessage(
						{
							id: "SPRINT.ITERATION.FORM.VALIDATION.NAME.ALREADY.EXISTS",
							defaultMessage: 'Iteration name already exists'
						});
				}
			});
		}
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		allNames.forEach((name) => {
			if (name === updatedName.value) {
				valid = false;
				message = intl.formatMessage(
					{
						id: "SPRINT.ITERATION.FORM.VALIDATION.NAME.ALREADY.EXISTS",
						defaultMessage: 'Iteration name already exists'
					});
			}
		});

		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}

		return allValid;
	}

	const submitIterationHandler = (event) => {
		event.preventDefault();
		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const iteration = {
			label: {value: name.value},
			description: {value: description.value}
		};
		const updatedIteration = {...props.iteration};
		updatedIteration.label = name.value;
		updatedIteration.description = description.value;
		props.updateIteration(updatedIteration);
		props.onUpdateIteration(props.iteration.id, iteration);
	}

	return (
		<AddForm name = {name}
		         description = {description}
		         nameChangeHandler = {nameChangeHandler}
		         descriptionChangeHandler = {descriptionChangeHandler}
		         submitIterationHandler = {submitIterationHandler}
		         loading = {props.loading}
		         error = {props.error}
		         {...props}
		         type = 'update'
				 sprint={props.sprint}/>
	);
}

const mapStateToProps = state => {
	return {
		loading: state.iteration.updatingLoading,
		error: state.iteration.updatingError,
		sprint: state.sprint.sprint,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateIteration: (id, iteration) => dispatch(actions.updateIteration(id, iteration)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateIteration);
