export const UINames = (name, intl) => {
	switch (name) {
		case 'name':
			return intl.formatMessage({ id: "DOMAINS.LABEL.NAME",
				defaultMessage: 'Domain Name' });
		case 'description':
			return intl.formatMessage({ id: "DOMAINS.LABEL.DESCRIPTION",
				defaultMessage: 'Description' });
		case 'project':
			return intl.formatMessage({ id: "HEADER.SELECT.PROJECT",
				defaultMessage: 'Project' })
		default:
			return '';
	}
}