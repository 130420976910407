export const MONTHLY = "MONTHLY";
export const ANNUAL = "ANNUAL";

export const PlanTypeObjects = [
	{label: 'MONTHLY', value: MONTHLY},
	{label: 'ANNUAL', value: ANNUAL}
];

export const PlanTypeValues = [
	MONTHLY, ANNUAL
];