import React, {useState} from "react";
import {useIntl} from "react-intl";

import {componentsValues, operationsValues} from "../../components/Instruction/ActionTypes";
import {changeText, selectElement} from "./Shared";
import {checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import AddAssertForm from "../../components/Instruction/AddAssertForm";

const NewAssert = props => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: false,
			minLength: 0,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [operation, setOperation] = useState({
		operation: '',
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		operations: [...operationsValues]
	});

	const [component, setComponent] = useState({
		component: '',
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		components: [...componentsValues]
	});

	const [identifier, setIdentifier] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 500
		},
		valid: true,
		validationMessage: null
	});

	const [value, setValue] = useState({
		value: '',
		validation: {
			required: false,
			minLength: 0,
			maxLength: 1000
		},
		valid: true,
		validationMessage: null
	});


	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const identifierChangeHandler = (event) => {
		const updatedIdentifier = changeText(identifier, event, 'identifier', intl);
		setIdentifier(updatedIdentifier);
	}

	const valueChangeHandler = (event) => {
		const updatedValue = changeText(value, event, 'value', intl);
		setValue(updatedValue);
	}

	const operationSelectHandler = (event) => {
		const updatedOperation = selectElement(operation, event, 'operation', 'operation', intl);
		setOperation(updatedOperation);
	};

	const componentSelectHandler = (event) => {
		const updatedComponent = selectElement(component, event, 'component', 'component', intl);
		setComponent(updatedComponent);
	};

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Operation
		const updatedOperation = {...operation};
		[valid, message] = checkSelectionValidity(updatedOperation, 'operation',
			UINames('operation', intl), intl);
		updatedOperation.valid = valid;
		updatedOperation.validationMessage = message;
		setOperation(updatedOperation);
		if (!valid) {
			allValid = false;
		}

		// Component
		const updatedComponent = {...component};
		[valid, message] = checkSelectionValidity(updatedComponent, 'component',
			UINames('component', intl), intl);
		updatedComponent.valid = valid;
		updatedComponent.validationMessage = message;
		setComponent(updatedComponent);
		if (!valid) {
			allValid = false;
		}

		// Name
		const updatedIdentifier = {...identifier};
		[valid, message] = checkValidity(updatedIdentifier, UINames('identifier', intl), intl);
		updatedIdentifier.valid = valid;
		updatedIdentifier.validationMessage = message;
		setIdentifier(updatedIdentifier);
		if (!valid) {
			allValid = false;
		}

		// Name
		const updatedValue = {...value};
		[valid, message] = checkValidity(updatedValue, UINames('value', intl), intl);
		updatedValue.valid = valid;
		updatedValue.validationMessage = message;
		setValue(updatedValue);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitAssertHandler = (event) => {
		// validate all
		if (!checkValidityForAll()) {
			return false;
		}

		let updatedAsserts = [...props.asserts];
		const assert = {
			description: name.value,
			operation: operation.operation,
			component: component.component,
			componentName: identifier.value,
			checkedValue: value.value
		};
		updatedAsserts.push(assert);
		props.setAsserts(updatedAsserts);
		props.updateAsserts(updatedAsserts);
		clear();
		return true;
	}

	const clear = () => {
		const upName = {...name};
		upName.value = '';
		setName(upName);
		const upIdentifier = {...identifier};
		upIdentifier.value = '';
		setIdentifier((upIdentifier));
		const upValue = {...value};
		upValue.value = '';
		setValue(upValue);
		const upComponent = {...component};
		upComponent.component = '';
		setComponent(upComponent);
		const upOperation = {...operation};
		upOperation.operation = '';
		setOperation(upOperation);
	}

	return <AddAssertForm name = {name}
	                      operation = {operation}
	                      component = {component}
	                      identifier = {identifier}
	                      value = {value}
	                      nameChangeHandler = {nameChangeHandler}
	                      identifierChangeHandler = {identifierChangeHandler}
	                      valueChangeHandler = {valueChangeHandler}
	                      operationSelectHandler = {operationSelectHandler}
	                      componentSelectHandler = {componentSelectHandler}
	                      submitAssertHandler = {submitAssertHandler}
	/>
}

export default NewAssert;