import React, {useState} from "react";
import {Card} from "react-bootstrap";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import SVG from "react-inlinesvg";
import {Dialog, DialogContent, Snackbar, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import {Dropdown} from "primereact/dropdown";
import {Player} from "video-react";
import {FormattedMessage, useIntl} from "react-intl";

import "../../../../node_modules/video-react/dist/video-react.css";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {SplashInline} from "../../UI/Splash";
import * as Type from "../Project/enum/ProjectType";
import {backendUrl} from "../../axios/axios";
import {getLabel as getOSLabel, OperatingSystemObjects, WINDOWS, LINUX} from "../Project/enum/OperatingSystem";
import {getLabel as getVersionLabel, BrowserVersionObjects} from "../Project/enum/BrowserVersion";
import {getLabel as getBrowserLabel, BrowserTypeObjects} from "../Project/enum/BrowserType";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		}
	},
}));

export const browsers = [
	{name: "FIREFOX"},
	{name: "CHROME"},
	{name: "EDGE"},
];

const SprintIterationsDisplay = (props) => {
	const intl = useIntl();
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const [iteration, setIteration] = useState(null);

	// Handling Alert
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const [tempLoadingForExecuteIterations, setTempLoadingForExecuteIterations] = useState([]);

	const handleClickAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlertOpen(false);
	};

	const iterationNameBodyTemplate = (rowData) => {
		return <h5>{rowData.label}</h5>;
	}

	const [showVideo, setShowVideo] = useState(false);
	const [iterationID, setIterationID] = useState(null);

	const showVideoDialog = (iterationID) => {
		setShowVideo(true);
		setIterationID(iterationID);
	}

	const hideVideoDialog = () => {
		setShowVideo(false);
	};

	let videoDialog;
	if(props.project && iterationID){
		if(props.project.type === Type.ANDROID || props.project.type === Type.ANDROID_WEB){
			videoDialog = <Dialog open = {showVideo} onClose = {hideVideoDialog}>
				<DialogContent >
					<Player autoPlay={true} fluid={false}
							src={`${backendUrl}reporting/video/${props.project.id}/${iterationID}?key=` + process.env.REACT_APP_KEY}
					/>
				</DialogContent>
			</Dialog>
		} else if (props.project.type === Type.WEB) {
			videoDialog = <Dialog open={showVideo} onClose = {hideVideoDialog} fullWidth={true} maxWidth='lg'>
				<DialogContent >
					<Player autoPlay={true}
							src={`${backendUrl}reporting/video/${props.project.id}/${iterationID}?key=` + process.env.REACT_APP_KEY}
					/>
				</DialogContent>
			</Dialog>
		}
	} else {
		videoDialog = null;
	}
	// OLD
	//  = (props.project && iterationID)? <Dialog
	// 		open = {showVideo}
	// 		onClose = {hideVideoDialog}
	// 		fullWidth={true}>
	// 		<DialogContent >
	// 			<Player autoPlay={true} fluid={true} // responsive={true}
	// 				src={`${backendUrl}reporting/video/${props.project.id}/${iterationID}?key=` + process.env.REACT_APP_KEY}
	// 			/>
	// 		</DialogContent>
	// 	</Dialog> : null;

	const iterationActionsBodyTemplate = (rowData) => {
		let executeIterationButton;
		if(rowData.suites && rowData.suites.length !== 0){
			if(props.project.type === Type.WEB || props.project.type === Type.API || ((props.project.type === Type.ANDROID || props.project.type === Type.ANDROID_WEB) && rowData.deviceGroup)){
				executeIterationButton = <a
					title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.START.ITERATION",
						defaultMessage: 'Start Iteration'})}
					className = {`btn btn-icon btn-light btn-hover-success btn-sm`}
					onClick = {() => {
						props.onExecuteIteration(rowData.id, props.sprint.id);
						const tempLoadingForExecuteIterations2 = [...tempLoadingForExecuteIterations];
						tempLoadingForExecuteIterations2.push(rowData.id);
						// console.log('adding, temp iteration to array', tempLoadingForExecuteIterations2);
						setTempLoadingForExecuteIterations(tempLoadingForExecuteIterations2);
						// temp loading state
						setTimeout(() => {
							props.onLoadSprint(props.sprint.id);
							// console.log('before, temp iterations', tempLoadingForExecuteIterations);
							const tempLoadingForExecuteIterations2 = tempLoadingForExecuteIterations.filter(id => id !== rowData.id);
							setTempLoadingForExecuteIterations(tempLoadingForExecuteIterations2);
							// console.log('after saga call load sprint, temp iterations', tempLoadingForExecuteIterations2);
						}, 1000);
					}}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-success">
				        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.START.ITERATION",
							defaultMessage: 'Start Iteration'})}
							 src = {toAbsoluteUrl("/media/svg/icons/Media/Play.svg")}/>
			        </span>
				</a>;
			} else if((props.project.type === Type.ANDROID || props.project.type === Type.ANDROID_WEB) && !rowData.deviceGroup) {
				executeIterationButton = <Tooltip  title={<Typography fontSize={30}>
					<FormattedMessage id="SPRINT.ITERATION.DEVICE.GROUP.CANNOT.EXECUTE.YET"
									  defaultMessage='Cannot run any iteration until you create atleast one Group Device'/>
				</Typography>}><span><a
					title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.START.ITERATION",
						defaultMessage: 'Start Iteration'})}
					className = {`btn btn-icon btn-light btn-hover-success btn-sm disabled`}
					onClick = {() => {
						props.onExecuteIteration(rowData.id, props.sprint.id);
						const tempLoadingForExecuteIterations2 = [...tempLoadingForExecuteIterations];
						tempLoadingForExecuteIterations2.push(rowData.id);
						// console.log('adding, temp iteration to array', tempLoadingForExecuteIterations2);
						setTempLoadingForExecuteIterations(tempLoadingForExecuteIterations2);
						// temp loading state
						setTimeout(() => {
							props.onLoadSprint(props.sprint.id);
							// console.log('before, temp iterations', tempLoadingForExecuteIterations);
							const tempLoadingForExecuteIterations2 = tempLoadingForExecuteIterations.filter(id => id !== rowData.id);
							setTempLoadingForExecuteIterations(tempLoadingForExecuteIterations2);
							// console.log('after saga call load sprint, temp iterations', tempLoadingForExecuteIterations2);
						}, 1000);
					}}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-success">
				        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.START.ITERATION",
							defaultMessage: 'Start Iteration'})}
							 src = {toAbsoluteUrl("/media/svg/icons/Media/Play.svg")}/>
			        </span>
				</a></span></Tooltip>;
			}
		}

		if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
			localStorage.getItem('Top-Role') === 'ROLE_CICD') {
			executeIterationButton = null;
		}
		let actions;
		if (!rowData.state) {
			// TODO if state.launchedIteration then loading if not normal.
			if((props.project.id in props.launchedIterations) && props.launchedIterations[props.project.id].includes(rowData.id)){
				actions = <SplashInline/>;
			}
			// if(tempLoadingForExecuteIterations.includes(rowData.id)){
			// 	actions = <SplashInline/>;
			// }
			else {
				actions = <div className = {`${classes.root}`}>

					<Link to = {`/iteration/${rowData.id}`}
					      title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.EDIT.ITERATION",
						      defaultMessage: 'Edit Iteration'})}
					      className = "btn btn-icon btn-light btn-hover-primary btn-sm"
					>
				        <span className = "svg-icon svg-icon-md svg-icon-primary">
					        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.EDIT.ITERATION",
						        defaultMessage: 'Edit Iteration'})}
						        src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
					        />
				        </span>
					</Link>

					{executeIterationButton}

				</div>;
			}

		} else {
			// This will become duplicate code because after 1 second the iteration is removed from the temp
			// if(tempLoadingForExecuteIterations.includes(rowData.id)){
			// 	const tempLoadingForExecuteIterations2 = tempLoadingForExecuteIterations.filter((id => id !== rowData.id));
			// 	setTempLoadingForExecuteIterations(tempLoadingForExecuteIterations2);
			// }
			// Execute, Success, Errror
			switch (rowData.state) {
				case "EXECUTE":
					// console.log('show me port name project.viewPort', props.viewPort);
					// console.log('props.liveMode', props.liveMode);

					actions = <div className = {`${classes.root}`}>
						<SplashInline/>

						{props.liveMode && <a href = {`http://tigmat.io:${props.viewPort}`} target = '_blank'
						    title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.WATCH.LIVE",
							    defaultMessage: 'Watch Live'})}
						    className = "btn btn-icon btn-light btn-hover-primary btn-lg">
					        <span className = "svg-icon svg-icon-lg svg-icon-primary">
						        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.WATCH.LIVE",
							        defaultMessage: 'Watch Live'})}
						             src = {toAbsoluteUrl("/media/svg/icons/Devices/Video-camera.svg")}
						        />
					        </span>
						</a>}
					</div>;
					break;
				case "SUCCESS":
					let videoButton = null;

					if(props.project.type !== Type.API && rowData.video === true){
						videoButton = <a onClick={e => showVideoDialog(rowData.id)}
										 title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.WATCH.VIDEO",
											 defaultMessage: 'View Video'})}
										 className = "btn btn-icon btn-light btn-hover-primary btn-lg">
							<span className = "svg-icon svg-icon-lg svg-icon-primary">
						        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.WATCH.VIDEO",
									defaultMessage: 'View Video'})}
									 src = {toAbsoluteUrl("/media/svg/icons/Media/Movie-Lane2.svg")}
								/>
					        </span>
						</a>;
					}

					actions = <div className = {`${classes.root}`}>
						{videoButton}

						<Link to = {`/report/${rowData.id}`}
						      title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.VIEW.REPORT",
							      defaultMessage: 'View Report'})}
						      className = "btn btn-icon btn-light btn-hover-success btn-lg">
					        <span className = "svg-icon svg-icon-lg svg-icon-success">
						        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.VIEW.REPORT",
							        defaultMessage: 'View Report'})}
						             src = {toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
						        />
					        </span>
						</Link>
					</div>;
					break;
				case "TECHNICAL_ERROR":
					actions = <div className = {`${classes.root}`}>

						<Link to={`/report/${rowData.id}`}
							  title={intl.formatMessage({
								  id: "SPRINT.ITERATION.TABLE.VIEW.REPORT", defaultMessage: 'View Report'
							  })}
							  className="btn btn-icon btn-light btn-hover-danger btn-lg">
					        <span className="svg-icon svg-icon-lg svg-icon-danger">
						        <SVG title={intl.formatMessage({id: "SPRINT.ITERATION.TABLE.VIEW.REPORT", defaultMessage: 'View Report'})}
									 src={toAbsoluteUrl("/media/svg/icons/Code/Backspace.svg")}
								/>
					        </span>
						</Link>
					</div>;
					break;
				case "ERROR":
					let videoButton2 = null;

					if(props.project.type !== Type.API && rowData.video === true){
						videoButton2 = <a onClick={e => showVideoDialog(rowData.id)}
										 title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.WATCH.VIDEO",
											 defaultMessage: 'View Video'})}
										 className = "btn btn-icon btn-light btn-hover-primary btn-lg">
							<span className = "svg-icon svg-icon-lg svg-icon-primary">
						        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.WATCH.VIDEO",
									defaultMessage: 'View Video'})}
									 src = {toAbsoluteUrl("/media/svg/icons/Media/Movie-Lane2.svg")}
								/>
					        </span>
						</a>;
					}

					actions = <div className = {`${classes.root}`}>
						{videoButton2}

						<Link to = {`/report/${rowData.id}`}
						      title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.VIEW.REPORT",
							      defaultMessage: 'View Report'})}
						      className = "btn btn-icon btn-light btn-hover-danger btn-lg">
					        <span className = "svg-icon svg-icon-lg svg-icon-danger">
						        <SVG title = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.VIEW.REPORT",
							        defaultMessage: 'View Report'})}
						             src = {toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
						        />
					        </span>
						</Link>
					</div>;
					break;
			}
		}

		return actions;
	}

	const browserEditor = (tableProps) => {
		if (!tableProps.rowData.state) {
			return (
				<Dropdown value = {tableProps.rowData['browser']} options = {BrowserTypeObjects} optionLabel = "label"
				          optionValue = "value"
				          onChange = {(e) => {
					          onEditorValueChange(tableProps, e.value);
					          // console.log('should send id, browser', tableProps.rowData['id'], e.value);
							  patchWeb(tableProps.rowData['id'], tableProps.rowData['platform'], e.value, tableProps.rowData['browserVersion']);
				          }}
				          style = {{width: '100%'}}
				          placeholder = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.SELECT.BROWSER", defaultMessage: 'Select Browser'})}
				          appendTo = {document.body}
				/>
			);
		} else {
			return getBrowserLabel(tableProps.rowData.browser);
		}
	}

	const operatingSystemCell = (tableProps) => {
		// console.log('tableProps.rowData', tableProps.rowData); // iteration
		if (!tableProps.rowData.state) {

			// const value = tableProps.rowData['platform'] ? tableProps.rowData['platform'] : OperatingSystemObjects[0];
			// console.log('value', value);
			return (
				<Dropdown value = {tableProps.rowData['platform']} options = {OperatingSystemObjects} optionLabel = "label"
						  optionValue = "value"
						  onChange = {(e) => {
							  // change versionsList
							  // console.log('before tableProps', tableProps.rowData);
							  if (e.value === LINUX){
								  tableProps.rowData['browserVersion'] = BrowserVersionObjects[0].value;
							  }
							  // console.log('after tableProps', tableProps.rowData);
							  onEditorValueChange(tableProps, e.value);
							  // console.log('should send id, browser', tableProps.rowData['id'], e.value);
							  patchWeb(tableProps.rowData['id'], e.value, tableProps.rowData['browser'], tableProps.rowData['browserVersion']);
						  }}
						  style = {{width: '100%'}}
						  placeholder = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.SELECT.OS", defaultMessage: 'Select Operating System'})}
						  appendTo = {document.body}
				/>
			);
		} else {
			return getOSLabel(tableProps.rowData.platform);
		}
	}

	const getUpdatedVersions = (os) => {
		if(os === WINDOWS){
			return BrowserVersionObjects;
		} else if (os === LINUX){
			return [BrowserVersionObjects[0]];
		}
	}

	const browserVersionCell = (tableProps) => {
		if (!tableProps.rowData.state) {
			return (
				<Dropdown value = {tableProps.rowData['browserVersion']} options = {getUpdatedVersions(tableProps.rowData['platform'])} optionLabel = "label"
						  optionValue = "value"
						  onChange = {(e) => {
							  onEditorValueChange(tableProps, e.value);
							  // console.log('should send id, browser', tableProps.rowData['id'], e.value);
							  patchWeb(tableProps.rowData['id'], tableProps.rowData['platform'], tableProps.rowData['browser'], e.value);
						  }}
						  style = {{width: '100%'}}
						  placeholder = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.SELECT.BROWSER.VERSION", defaultMessage: 'Select Browser Version'})}
						  appendTo = {document.body}
				/>
			);
		} else {
			return getVersionLabel(tableProps.rowData.browserVersion);
		}
	}

	const browserBodyTemplate = (rowData) => {
		// console.log('operatingSystemBodyTemplate rowData', rowData);
		return getBrowserLabel(rowData.browser);
	}

	const operatingSystemBodyTemplate = (rowData) => {
		// console.log('operatingSystemBodyTemplate rowData', rowData);
		return getOSLabel(rowData.platform);
	}

	const browserVersionBodyTemplate = (rowData) => {
		// console.log('operatingSystemBodyTemplate rowData', rowData);
		return getVersionLabel(rowData.browserVersion);
	}

	const onEditorValueChange = (tableProps, value) => {
		let updatedIterations = [...tableProps.value];
		// console.log('updatedIterations', updatedIterations);
		// console.log('updatedIterations[tableProps.rowIndex][tableProps.field]', updatedIterations[tableProps.rowIndex][tableProps.field]);
		// console.log('value', value);
		updatedIterations[tableProps.rowIndex][tableProps.field] = value;
		props.setIterations(updatedIterations);
		// console.log('updatedIterations', updatedIterations);
	}

	const patchWeb = (iterationID, os, browser, version) => {
		props.updateIteration({os: os, browser: browser, version: version}, null, iterationID);
	}

	const patchAndroid = (iterationID, deviceGroup) => {
		props.updateIteration(null, {deviceGroup: deviceGroup}, iterationID);
	}


	const labelColumn = <Column key = 'label' columnKey = 'label' field = 'label'
								header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
								body = {iterationNameBodyTemplate} sortable/>;

	const launcherColumn = <Column key = 'launcher' columnKey = 'launcher' field = 'launcher'
								   header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.LAUNCHER", defaultMessage: 'Launcher'})}/>;

	let browserColumn = null;
	if(props.project && props.project.type === Type.ANDROID_WEB){
		browserColumn = <Column columnKey = 'browser' field = 'browser'
								body={browserBodyTemplate}
								header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.BROWSER", defaultMessage: 'Browser'})}/>;
	}

	let operatingSystemColumn = null;
	let browserVersionColumn = null;
	if(props.project && props.project.type === Type.WEB){
		browserColumn = <Column columnKey = 'browser' field = 'browser'
								body={browserBodyTemplate}
								header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.BROWSER", defaultMessage: 'Browser'})}
								editor = {(props) => browserEditor(props)}/>;

		operatingSystemColumn = <Column columnKey = 'platform' field='platform'
										body={operatingSystemBodyTemplate}
										header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.OS", defaultMessage: 'Operating System'})}
										editor = {(props) => operatingSystemCell(props)}/>;

		browserVersionColumn = <Column columnKey='browserVersion' field='browserVersion'
									   body={browserVersionBodyTemplate}
									   header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.BROWSER.VERSION", defaultMessage: 'Version'})}
									   editor = {(props) => browserVersionCell(props)}/>;
	}

	let actionsColumn = null;
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		actionsColumn = <Column key = 'actions'
		                      header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.ACTIONS",
			                      defaultMessage: 'Actions'})}
		                      body = {iterationActionsBodyTemplate}
		                      style = {{width: '25%'}}
		                      className = 'p-text-right'
		/>;
	}

	const deviceGroupBodyTemplate = (rowData) => {
		// console.log('deviceGroupBodyTemplate rowData', rowData);
		// return getOSLabel(rowData.platform);
		return rowData.deviceGroup?.name;
	}

	const deviceGroupCell = (tableProps) => {
		// console.log('tableProps.rowData', tableProps.rowData); // iteration
		if (!tableProps.rowData.state) {
			// const value = tableProps.rowData['platform'] ? tableProps.rowData['platform'] : OperatingSystemObjects[0];
			// console.log('value', value);
			return (
				<Dropdown value = {tableProps.rowData['deviceGroup']} options = {props.project.deviceGroups} optionLabel = "name"

						  onChange = {(e) => {
							  // // console.log('after tableProps', tableProps.rowData);
							  onEditorValueChange(tableProps, e.value);
							  // console.log('should send id, deviceGroup', tableProps.rowData['id'], e.value);
							  patchAndroid(tableProps.rowData['id'], e.value);
						  }}
						  style = {{width: '100%'}}
						  placeholder = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.DEVICE.GROUP.EMPTY.MESSAGE",
							  defaultMessage: 'No Device Groups Created Yet'})}
						  appendTo = {document.body}
				/>
			);
		} else {
			return tableProps.rowData.deviceGroup.name;
		}
	}

	let deviceGroupColumn = null;
	if(props.project && props.project.type === Type.ANDROID){
		deviceGroupColumn = <Column columnKey = 'deviceGroup' field='deviceGroup'
									body={deviceGroupBodyTemplate}
									header = {intl.formatMessage({ id: "SPRINT.ITERATION.TABLE.COLUMN.DEVICE.GROUP", defaultMessage: 'Device Group'})}
									editor = {(props) => deviceGroupCell(props)}/>;
	}

	const display = <><Card className = "border-0 rounded-0">
			<Card.Body className='p-0'>
				{props.iterationCreationError && <Alert elevation={6} variant="standard" severity="error" className='m-3'>
					{props.iterationCreationError}
				</Alert>}
				<DataTable value = {props.iterations}
				           className="p-datatable-striped"
				           paginator
				           paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				           currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
				           rows = {20} rowsPerPageOptions = {[20, 50, 100, 500, 1000]}
						   emptyMessage={intl.formatMessage({
								   id: "SPRINT.NO.ITERATION",
								   defaultMessage: 'No Iterations Exists For Sprint'
							   }) + ' ' + props.sprintName}
					// onRowReorder = {onRowReorder}
					       autoLayout = {true}
				>
					{/*<Column rowReorder style = {{width: '3em'}}/>*/}
					{labelColumn}
					{launcherColumn}
					{operatingSystemColumn}
					{browserColumn}
					{browserVersionColumn}
					{deviceGroupColumn}
					{actionsColumn}
				</DataTable>

				<Snackbar
					anchorOrigin = {{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open = {alertOpen}
					autoHideDuration = {2000}
					onClose = {handleCloseAlert}
					message = {alertMessage}
				>
					<Alert onClose = {handleCloseAlert} severity = "success">
						{alertMessage}
					</Alert>
				</Snackbar>
			</Card.Body>
		</Card>
	{videoDialog}

		{/*Web*/}
		{/*<Dialog open = {true} onClose = {hideVideoDialog} fullWidth={true} maxWidth='lg'>*/}
		{/*	<DialogContent >*/}
		{/*		<Player autoPlay={true}*/}
		{/*				src={'https://devicefarm-prod-us-west-2-testgrid-results-nwo17noa.s3.us-west-2.amazonaws.com/203347848294/73470562-972e-408a-a2be-714c97802bbc/baba2d81-c55d-4738-85bc-651e397e5729/AROAUYCPRZIW2JOQZP3Y2%3Ai-02f5018a5f40e75de/video/video_testgrid_2023-01-19_13-28-02_000.mp4?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEPv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJIMEYCIQCwaEb89Teiv4dYalAKMAH379LJSrND1JjEVnVgnAryOAIhAJii5rBw062WNsjExoYj7RtQA74sJkdwOMWApXXSuaLvKuACCHQQAhoMMzQwNjgxNTI3ODkxIgyIk8q%2FdSXmP640rY0qvQLN0RZQJl8OnML7Nj2gfh6B8%2BCANd3EUMj3TWnGMAJMuhZqQwtnTh0rNcI7gOvZdrgSnJhvecrH5JaLmUlwBusoNPxpXF1LP3ldR1z98%2Fw0SK0gY2r4aYqpuTEFdvJq4DrAyrzhFIc5x0%2BB6pVXv9sdphT2zd%2FYZqPmk6usT83gAjGddI6cYe5VDXSscrFihDTx7xZQnqbVH9KNM4Wtkokb8CCvZsnr3uAnWwMqvTGgSFz4vqxM1Oh62DuPBKIDnOC5KBCfAAtDrPrH6%2BJkBthsU8bXKO8UsBnS7jlC42v71SYJ3o9auUdLisASJ3HQLnGTgfPxk3Q%2BdNqy5mVB0xQCwXwUywnWuEmkHyWFS67IVfvB6%2F6%2F2TPTLxOPYvEjunnfSDoKuVNgAFYmzfWnPsEV58TNM0LH%2Bz6CKqrcZzDon9meBjq%2BAUZvHc2zAz0zkF2U96MWejG3ZItiQJHSkKaf%2BUtRZ2%2FcC1ceKKwN8FzUvzGA1%2FPvnLYgR9KZxV%2BW126rmr89peDmNLsVj2gnu%2Fy8%2FDT8bKArRcG%2BXoAG3Du4OVF4GRcFKMf2tvlOz9lU5%2Bcd1dWNEtyIyzp6uI%2BOPTF9TdwInWCO3IbXTPEwEpMsnbU1E1ItkSRAJUby7b%2FTWwcUJcQSh9ifK%2B0NOb9BfvAlBLECwAi4ePSJA1YWs6BuIbO4zEo%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230129T113012Z&X-Amz-SignedHeaders=host&X-Amz-Expires=10800&X-Amz-Credential=ASIAU6URTSJJT2PHHNXV%2F20230129%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=b0794a28aa77594fea91ed5893f7608722946ee7a0dfbb60131d36c8b6286fc2'}*/}
		{/*		/>*/}
		{/*	</DialogContent>*/}
		{/*</Dialog>*/}

		{/*Android */}
		{/*<Dialog open = {true} onClose = {hideVideoDialog}>*/}
		{/*	<DialogContent >*/}
		{/*		<Player autoPlay={true} fluid={false}*/}
		{/*			src={'https://prod-us-west-2-results.s3-us-west-2.amazonaws.com/203347848294/165f9ee8-7817-44ca-9fea-1067ef6499f6/c0f58aa3-b191-434b-b805-5c106c11376b/00001/00001/00000/638da330-94e4-42e3-b4c9-867c80c27820.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230129T103714Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=AKIAUJHLTYS5AWNTRO6L%2F20230129%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=a4102d66fc8e971e910ce5755405c862e87e8d7dd9352577b146cc522225cc0f'}*/}
		{/*		/>*/}
		{/*	</DialogContent>*/}
		{/*</Dialog>*/}
	</>;

	return display;
}

export default SprintIterationsDisplay;