/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import {Link as MUILink} from "@material-ui/core";

import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {DropdownTopbarItemToggler} from "../../_metronic/_partials/dropdowns";
import {FormattedMessage} from "react-intl";


export const UserActionsDropdown = () => {
	let myAccount = <li className='navi-item'>
		<MUILink to={`/user/${localStorage.getItem('username')}`} component={Link} className='navi-link'>
							<span className="symbol symbol-20 mr-3">
				                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
				                </span>
			<span className="navi-text">
					<FormattedMessage id="HEADER.MY.ACCOUNT"
									  defaultMessage='My Account'/>
				</span>
		</MUILink>
	</li>;

	return (
		<Dropdown drop = "down" alignRight className='mr-1'>
			<Dropdown.Toggle
				as = {DropdownTopbarItemToggler}
				id = "dropdown-toggle-my-cart"
			>
				<OverlayTrigger
					placement = "bottom"
					overlay = {
						<Tooltip id = "language-panel-tooltip">
							<FormattedMessage id="TOPBAR.USER.ACTIONS"
							                  defaultMessage='User Actions'/></Tooltip>
					}
				>
					<div className = "btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
						<SVG src = {toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")}/>
					</div>
				</OverlayTrigger>
			</Dropdown.Toggle>
			<Dropdown.Menu className = "p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround w-300px">
				<ul className = "navi navi-hover py-4">

					{myAccount}

					<li className = 'navi-item'>
						<MUILink to='/changePassword' component={Link} className='navi-link'>
							<span className = "symbol symbol-20 mr-3">
				                  <SVG src = {toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}/>
				                </span>
							<span className = "navi-text">
							<FormattedMessage id="TOPBAR.USER.ACTION.CHANGE.PASSWORD"
							                  defaultMessage='Change Password'/></span>
						</MUILink>
					</li>
					<li className = 'navi-item'>
						<MUILink to='/logout' component={Link} className='navi-link'>
							<span className = "symbol symbol-20 mr-3">
				                  <SVG src = {toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}/>
				                </span>
							<span className = "navi-text">
								<FormattedMessage id="TOPBAR.USER.ACTION.LOGOUT"
							                  defaultMessage='Logout'/></span>
						</MUILink>
					</li>
					{/*<li className = 'navi-item'>*/}
					{/*	<a href = "/users" className = "navi-link">*/}
			        {/*        <span className = "symbol symbol-20 mr-3">*/}
			        {/*          <SVG src = {toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}/>*/}
			        {/*        </span>*/}
					{/*		<span className = "navi-text">Change Password</span>*/}
					{/*	</a>*/}
					{/*</li>*/}

				</ul>
			</Dropdown.Menu>
		</Dropdown>
	);
};
