export const NOAUTH = "NOAUTH";
export const BASIC_AUTH = "BASIC_AUTH";
export const BEARER_TOKEN = "BEARER_TOKEN";
export const API_KEY = "API_KEY";
export const INHERIT = "INHERIT";

// Form 1
export const AuthenticationTypeObjects = [
	{label: 'INHERIT', value: INHERIT},
	{label: 'No Auth', value: NOAUTH},
	{label: 'Basic Auth', value: BASIC_AUTH},
	{label: 'Bearer Token', value: BEARER_TOKEN},
	{label: 'API Key', value: API_KEY}
]

export const AuthenticationTypeObjectsForSuite = [
	{label: 'No Auth', value: NOAUTH},
	{label: 'Basic Auth', value: BASIC_AUTH},
	{label: 'Bearer Token', value: BEARER_TOKEN},
	{label: 'API Key', value: API_KEY},
]

// Form 2
export const AuthenticationTypeValues = [
	NOAUTH,
	BASIC_AUTH,
	BEARER_TOKEN,
	API_KEY,
	INHERIT
]