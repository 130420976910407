import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Spinner} from "react-bootstrap";
import {Button} from "@material-ui/core";
import {Redirect} from "react-router";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";

import * as actions from "../../store/actions";
import {backendUrl} from "../../axios/axios";

// const SITE_KEY = "6Lduu6QUAAAAADQo9CWQ3HETbUBtxO-88OhalKMb";  old
// const SITE_KEY = "6Le-4T4eAAAAAPPzRafq6_bVKfNXSVvYh_iUd9_9";

const LoginPage = (props) => {
	const intl = useIntl();

	const [user, setUser] = useState({username: '', password: ''})
	const [captchaErrorMsg, setCaptchaErrorMsg] = useState(null);
	const [closeAccountRequestedMsg, setCloseAccountRequestedMsg] = useState(null);

	useEffect(() => {
		document.title = intl.formatMessage({
			id: "PAGE.TITLE.LOGIN",
			defaultMessage: 'Login'
		});
		var script = document.createElement('script')
		script.src = 'https://www.google.com/recaptcha/api.js';
		script.class = "external-script"
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);

		// Show Message for Close Account request
		// console.log('props.location.search', props.location.search);
		const query = new URLSearchParams(props.location.search);
		const closeAccountRequested = query.get('closeAccountRequested') ? true : false;
		if(closeAccountRequested === true){
			setCloseAccountRequestedMsg(intl.formatMessage({
				id: "ACCOUNT.TERMINATION.REQUEST.SENT.SUCCESSFULLY",
				defaultMessage: 'Request to close account was sent successfully. we\'ve sent you an email to confirm your request.'
			}));
		}

		return () => {
			document.body.removeChild(script);
			window.login = null;
		};

	}, []);

	const handleChange = (event) => {
		setUser({...user, [event.target.name]: event.target.value})
	}

	const login = async (token) => {  // token is sent by <Button class = 'g-recaptcha' ../>
		// console.log('token', token);
		// console.log('window.grecaptcha.getResponse()', window.grecaptcha.getResponse());
		// console.log('document.getElementById("g-recaptcha-response").value', document.getElementById('g-recaptcha-response').value);
		// const isHuman = await fetch(`https://www.google.com/recaptcha/api/siteverify`, {
		const isHuman = await fetch(`${backendUrl}recaptcha`, {
			method: "post",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
			},
			body: token
		})
			.then(res => res)
			// .then(json => {console.log('json', json); return json;})
			.catch(err => {
				window.grecaptcha.reset();
				setCaptchaErrorMsg(
					<div className = "mb-10 alert alert-custom alert-light-danger alert-dismissible">
						<div className = "alert-text font-weight-bold">
							<FormattedMessage id = "CAPTCHA.ERROR"
							                  defaultMessage = 'Captcha Error'/>
						</div>
					</div>);
				// throw new Error(`Error in Google Siteverify API. ${err.message}`)
			});

		if (!token || !isHuman) {
			// message you're not human
			window.grecaptcha.reset();
			setCaptchaErrorMsg(
				<div className = "mb-10 alert alert-custom alert-light-danger alert-dismissible">
					<div className = "alert-text font-weight-bold">
						<FormattedMessage id = "CAPTCHA.ERROR"
						                  defaultMessage = 'Captcha Error'/>
					</div>
				</div>);
			return;
		}

		// The code below will run only after the reCAPTCHA is succesfully validated.
		setCaptchaErrorMsg(null);
		props.onLogin(user);
	}

	// const [humanKey, setHumanKey] = useState('');

	let auth = null;
	if (props.authenticated) {
		auth = <Redirect to = '/dashboard'/>;
	}

	let errorMessage = null;
	if (props.error) {
		window.grecaptcha.reset();
		errorMessage =
			<div className = "mb-10 alert alert-custom alert-light-danger alert-dismissible">
				<div className = "alert-text font-weight-bold">{props.error}</div>
			</div>
	}

	const onEnterClicked = (event) => {
		if (event.key === 'Enter' || event.keyCode === 13) {
			login();
		}
	}

	window.login = login;
	return <div className = "login-form login-signin" id = "kt_login_signin_form">
			{auth}

			{props.loading && <div className='text-center mb-6'>
				<Spinner animation="border" variant="danger">
					<span className="sr-only">
						<FormattedMessage id="LOADING" defaultMessage='Loading...'/>
					</span>
				</Spinner>
			</div>}

			{/* begin::Head */}
			<div className = "text-center mb-10 mb-lg-20">
				<h3 className = "font-size-h1">
					<FormattedMessage id = "LOGIN.TITLE"
					                  defaultMessage = 'Login Account'/>
				</h3>
				<p className = "text-muted font-weight-bold">
					<FormattedMessage id = "LOGIN.INPUT.CREDENTIALS"
					                  defaultMessage = 'Enter your username and password'/>
				</p>
			</div>
			{/* end::Head */}


			{/*begin::Form*/}
			<form className = "form fv-plugins-bootstrap fv-plugins-framework">

				{errorMessage}

				{captchaErrorMsg}

				{closeAccountRequestedMsg && <div className = "mb-10 alert alert-custom alert-light-success alert-dismissible">
					<div className = "alert-text font-weight-bold">
						{closeAccountRequestedMsg}
					</div>
				</div>}

				<div className = "form-group fv-plugins-icon-container">
					<input placeholder = {intl.formatMessage({
						id: "LOGIN.PLACEHOLDER.USERNAME",
						defaultMessage: 'User Name'
					})} type = "username"
					       className = {`form-control form-control-solid h-auto py-5 px-6`}
					       name = "username" onChange = {handleChange}
					/>
				</div>
				<div className = "form-group fv-plugins-icon-container">
					<input placeholder = {intl.formatMessage({
						id: "LOGIN.PLACEHOLDER.PASSWORD",
						defaultMessage: 'Password'
					})}
					       type = "password"
					       className = {`form-control form-control-solid h-auto py-5 px-6 `}
					       name = "password" onChange = {handleChange} onKeyDown = {(event) => {
						onEnterClicked(event)
					}}/>
				</div>
				{/*<ReCAPTCHA sitekey = {SITE_KEY} onChange = {setHumanKey} hl = 'en'/>*/}
				{/*<div className="g-recaptcha" data-sitekey={SITE_KEY} >*/}

				{/*</div>*/}

				<div className = "form-group d-flex flex-wrap justify-content-between align-items-center">
					<Link
						to = "/auth/forgot-password"
						className = "text-dark-50 text-hover-primary my-3 mr-2"
						id = "kt_login_forgot">
						<FormattedMessage id="LOGIN.FORGOT.PASSWORD"
						                  defaultMessage='Forgot Password'/>
					</Link>
					<span id = "kt_login_forgot"></span>

					<Button id='login-button' variant = "outlined" color = "primary"
					        className = 'btn btn-primary font-weight-bold px-9 py-4 my-3 g-recaptcha'
					        onClick = {null}
							data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} data-callback='login'
							hidden={props.loading}>
						<FormattedMessage id = "LOGIN.BUTTON.LOGIN"
						                  defaultMessage = 'Login'/>
					</Button>
				</div>
			</form>
			{/*end::Form*/}
		</div>;
}

const mapStateToProps = state => {
	return {
		loading: state.authentication.loading,
		authenticated: state.authentication.authenticated,
		error: state.authentication.error
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLogin: (user) => dispatch(actions.login(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);