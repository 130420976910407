import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import SuiteDisplay from "../../components/Suite/SuiteDisplay";
import * as Type from "../../components/Project/enum/ProjectType";

const SuiteDisplaySection = props => {
	const intl = useIntl();

	const [tests, setTests] = useState([]); //props.suite ? props.suite.indexedCases :
	const [previousTests, setPreviousTests] = useState([]);

	const [requests, setRequests] = useState([]); //props.suite && props.suite.indexedRestApis ? props.suite.indexedRestApis :
	const [previousRequests, setPreviousRequests] = useState([]);

	const [suite, setSuite] = useState(null); // props.suite ? props.suite :
	const [previousSuite, setPreviousSuite] = useState(null);

	const [prerequisites, setPrerequisites] = useState([]); //props.suite ? props.suite.prerequisites :
	const [previousPrerequisites, setPreviousPrerequisites] = useState([]);

	const [postrequisites, setPostrequisites] = useState([]); // props.suite ? props.suite.postrequisites :
	const [previousPostrequisites, setPreviousPostrequisites] = useState([]);

	useEffect(() => {
		// console.log('props.match.params.suiteID', props.match.params.suiteID);
		props.onLoadSuite(props.match.params.suiteID);
	}, [props.match.params.suiteID]);

	// useEffect(() => {
	// 	console.log('tests', tests);
	// }, [tests]);

	// useEffect(() => {
	// 	props.onLoadSuite(props.match.params.suiteID);
	// }, []);

	useEffect(() => {
		if (props.suite) {
			setSuite(props.suite);
			if(Array.isArray(props.suite.indexedCases)){
				setTests(props.suite.indexedCases);
				setPreviousTests(props.suite.indexedCases);
			} else {
				setTests([]);
				setPreviousTests([]);
			}
			if(Array.isArray(props.suite.indexedRestApis)){
				setRequests(props.suite.indexedRestApis);
				setPreviousRequests(props.suite.indexedRestApis);
			} else {
				setRequests([]);
				setPreviousRequests([]);
			}
			if(Array.isArray(props.suite.prerequisites)){
				setPrerequisites(props.suite.prerequisites);
				setPreviousPrerequisites(props.suite.prerequisites);
			} else {
				setPrerequisites([]);
				setPreviousPrerequisites([]);
			}
			if(Array.isArray(props.suite.postrequisites)){
				setPostrequisites(props.suite.postrequisites);
				setPreviousPostrequisites(props.suite.postrequisites);
			} else {
				setPostrequisites([]);
				setPreviousPostrequisites([]);
			}
			document.title = intl.formatMessage({ id: "PAGE.TITLE.SUITE", defaultMessage: 'Suite: ' }) + props.suite.label;
		}
	}, [props.suite]);

	useEffect(() => {
		if (props.updatingError) {
			setTests(previousTests);
			setRequests(previousRequests);
			setPrerequisites(previousPrerequisites);
			setPostrequisites(previousPostrequisites);
		}
	}, [props.updatingError]);

	// this delete test from the list of suite.tests Not test itself
	const deleteTest = index => {
		const updatedSuite = {...suite};
		let updatedTests = [...tests];
		updatedTests.splice(index, 1);
		// update index
		updatedTests = updatedTests.map((test, index) => {
			test.index = index;
			return test;
		});
		// make patch object
		const patchSuite = {
			indexedCases: {value: updatedTests}
		}
		updatedSuite.indexedCases = updatedTests;
		setTests(updatedTests);
		setSuite(updatedSuite);
		props.onUpdateSuite(props.match.params.suiteID, patchSuite, updatedSuite);
	}

	const deleteRequest = index => {
		const updatedSuite = {...suite};
		let updatedRequests = [...requests];
		updatedRequests.splice(index, 1);
		// update index
		updatedRequests = updatedRequests.map((request, index) => {
			request.index = index;
			return request;
		});
		// make patch object
		const patchSuite = {
			indexedRestApis: {value: updatedRequests}
		}
		updatedSuite.indexedRestApis = updatedRequests;
		setRequests(updatedRequests);
		setSuite(updatedSuite);
		props.onUpdateSuite(props.match.params.suiteID, patchSuite, updatedSuite);
	}

	const deletePrerequisite = (request) => {
		let updatedPrerequisites = [...prerequisites];
		// deleting
		updatedPrerequisites = updatedPrerequisites.filter((item) => item !== request);
		// update index
		updatedPrerequisites = updatedPrerequisites.map((request, index) => {
			request.index = index;
			return request;
		});
		// make patch object
		const prerequisitesPatch = {
			prerequisites: {value: updatedPrerequisites}
		}
		const updatedSuite = {...suite};
		updatedSuite.prerequisites = updatedPrerequisites;
		setSuite(updatedSuite);
		setPrerequisites(updatedPrerequisites);
		props.onUpdateSuite(suite.id, prerequisitesPatch, updatedSuite);
	}

	const deletePostrequisite = (request) => {
		let updatedPostrequisites = [...postrequisites];
		// deleting
		updatedPostrequisites = updatedPostrequisites.filter((item) => item !== request);
		// update index
		updatedPostrequisites = updatedPostrequisites.map((request, index) => {
			request.index = index;
			return request;
		});
		// make patch object
		const postrequisitesPatch = {
			postrequisites: {value: updatedPostrequisites}
		}
		const updatedSuite = {...suite};
		updatedSuite.postrequisites = updatedPostrequisites;
		setSuite(updatedSuite);
		setPostrequisites(updatedPostrequisites);
		props.onUpdateSuite(suite.id, postrequisitesPatch, updatedSuite);
	}

	const updateTests = (tests) => {
		const id = props.suite.id;
		const updatedSuite = {...suite};
		const testsPatch = {indexedCases: {value: tests}};
		updatedSuite.indexedCases = tests;
		props.onUpdateSuite(id, testsPatch, updatedSuite);
	}

	const updateRequests = (requests) => {
		const id = props.suite.id;
		const updatedSuite = {...suite};
		const requestsPatch = {indexedRestApis: {value: requests}};
		updatedSuite.indexedRestApis = requests;
		props.onUpdateSuite(id, requestsPatch, updatedSuite);
	}

	const updatePrerequisites = (prerequisites) => {
		const id = props.suite.id;
		const updatedSuite = {...suite};
		const prerequisitesPatch = {prerequisites: {value: prerequisites}};
		updatedSuite.prerequisites = prerequisites;
		props.onUpdateSuite(id, prerequisitesPatch, updatedSuite);
	}

	const updatePostrequisites = (postrequisites) => {
		const id = props.suite.id;
		const updatedSuite = {...suite};
		const postrequisitesPatch = {postrequisites: {value: postrequisites}};
		updatedSuite.postrequisites = postrequisites;
		props.onUpdateSuite(id, postrequisitesPatch, updatedSuite);
	}

	const updateSuite = (updatedSuite) => {
		setSuite(updatedSuite);
	}
	const type = props.project ? props.project.type : Type.WEB;
	return <SuiteDisplay suite = {suite}
	                     updateSuite={updateSuite}
	                     deleteTest = {deleteTest}
	                     suiteID = {props.match.params.suiteID}
	                     tests={tests}
	                     setTests={setTests}
	                     updateTests={updateTests}

	                     loading={props.loading} // for Prere.. it does not access state, unlike testCases
	                     deletePrerequisite={deletePrerequisite}
	                     prerequisites={prerequisites}
	                     setPrerequisites={setPrerequisites}
	                     updatePrerequisites={updatePrerequisites}

	                     deletePostrequisite={deletePostrequisite}
	                     postrequisites={postrequisites}
	                     setPostrequisites={setPostrequisites}
	                     updatePostrequisites={updatePostrequisites}
						 type={type}

						 deleteRequest={deleteRequest}
						 requests={requests}
						 setRequests={setRequests}
						 updateRequests={updateRequests}

						 updatingError={props.updatingError}
	/>;

}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		suite: state.suite.suite,
		loading: state.suite.loading,
		updatingError: state.suite.updatingError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadSuite: (id) => dispatch(actions.loadSuite(id)),
		onUpdateSuite: (id, suite, updatedSuite) => dispatch(actions.updateSuite(id, suite, updatedSuite)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SuiteDisplaySection);
