import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Snackbar} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";

import RequisitesAddition from "../../containers/Suite/internal/RequisitesAddition";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const RequisiteTab = props => {
	const classes = useStyles();
	const intl = useIntl();

	// Deletion Dialog
	const [toBeRemovedRequest, setToBeRemovedRequest] = useState(null); // Label also needed
	const [toBeRemovedRequestConfirmationDialogOpen, setToBeRemovedRequestConfirmationDialogOpen] =
		useState(false);

	// Snackbar
	const [requestsUpdateSnackbarOpen, setRequestsUpdateSnackbarOpen] = React.useState(false);
	const [requestsUpdateSnackbarMessage, setRequestsUpdateSnackbarMessage] = React.useState('');

	// flag for loading. snackbar should not show up until an action is taken.
	// unlike xxxLoading which is related to the action not the whole object in the state
	const [allowLoading, setAllowLoading] = useState(false);

	useEffect(() => {
		if(props.type === 'pre'){
			if(props.loading === true && allowLoading){
				showRequestsSnackbar(intl.formatMessage({ id: "SUITE.PREREQUISITE.SNACKBAR.UPDATING",
					defaultMessage: 'Updating Prerequisites'}));
			}
			if (!props.loading && requestsUpdateSnackbarOpen) {
				showRequestsSnackbar(intl.formatMessage({ id: "SUITE.PREREQUISITE.SNACKBAR.UPDATED",
					defaultMessage: 'Prerequisites Updated'}));
			}
		} else {
			if(props.loading === true && allowLoading){
				showRequestsSnackbar(intl.formatMessage({ id: "SUITE.POSTREQUISITE.SNACKBAR.UPDATING",
					defaultMessage: 'Updating Postrequisites'}));
			}
			if (!props.loading && requestsUpdateSnackbarOpen) {
				showRequestsSnackbar(intl.formatMessage({ id: "SUITE.POSTREQUISITE.SNACKBAR.UPDATED",
					defaultMessage: 'Postrequisites Updated'}));
			}
		}

	}, [props.loading]);

	const showRequestsSnackbar = (message) => {
		setRequestsUpdateSnackbarOpen(true);
		setRequestsUpdateSnackbarMessage(message);
	}

	const hideRequestsSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setRequestsUpdateSnackbarOpen(false);
	}

	const closeRemoveConfirmationDialog = () => {
		setToBeRemovedRequestConfirmationDialogOpen(false);
	};

	const testNameBodyTemplate = (rowData) => {
		return <Link to={`/rest-request/${rowData.id}`} className='text-dark'><h5>{rowData.label}</h5></Link>;
	}

	const onRowReorder = (e) => {
		let requests = e.value;
		requests = requests.map((request, index) => {
			request.index = index;
			return request;
		});
		props.setRequisites(requests);
		props.updateRequisites(requests);
		if(!allowLoading) {
			setAllowLoading(true);
		}
	}

	const requisiteActionsBodyTemplate = (rowData) => {

		return (
			<div className = {` ${classes.buttons}`}>
				<a title = {intl.formatMessage({
					id: "REST.REQUESTS.TABLE.HOVER.LINK.DELETE.REQUEST",
					defaultMessage: 'Delete Request'
				})}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				   onClick = {() => {
					   setToBeRemovedRequest(rowData);
					   setToBeRemovedRequestConfirmationDialogOpen(true);
				   }}
				>
				        <span className = "svg-icon svg-icon-md svg-icon-danger">
				          <SVG
					          title = {intl.formatMessage({
						          id: "REST.REQUESTS.TABLE.HOVER.LINK.DELETE.REQUEST",
						          defaultMessage: 'Delete Request'
					          })}
					          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
				        </span>
				</a>
			</div>
		);
	}

	const methodBodyTemplate = (rowData) => {
		return <span className="font-size-h6">
			{rowData.method}
		</span>;
	}

	const urlBodyTemplate = (rowData) => {
		return <span className="font-size-h6">
			{rowData.uri}
		</span>;
	}

	const labelColumn = <Column columnKey='label' field='label'
								header={intl.formatMessage({id: "SUITE.PREREQUISITE.TABLE.COLUMN.LABEL", defaultMessage: 'Name'})}
								body={testNameBodyTemplate}/>;

	const methodColumn = <Column field="method" columnKey='method'
								 header={intl.formatMessage({id: "SUITE.PREREQUISITE.TABLE.COLUMN.METHOD", defaultMessage: 'Method'})}
								 body={methodBodyTemplate}/>;
	const urlColumn = <Column field="uri" columnKey='uri'
							  header={intl.formatMessage({id: "SUITE.PREREQUISITE.TABLE.COLUMN.URL", defaultMessage: 'URL'})}
							  body={urlBodyTemplate}/>;

	const emptyTableMessage = props.type === 'pre' ? intl.formatMessage({
		id: "SUITE.PREREQUISITE.TABLE.EMPTY",
		defaultMessage: 'No Prerequisites'
	}) : intl.formatMessage({
		id: "SUITE.POSTREQUISITE.TABLE.EMPTY",
		defaultMessage: 'No Postrequisites'});

	let error = null;
	if (props.updatingError) {
		error = <Row>
			<Col>
				<Alert elevation = {6} variant = "standard" severity = "error" className = 'm-3'>
					{props.updatingError}
				</Alert>
			</Col>
		</Row>
	}

	return <Container className='bg-white p-0'>
		<Row>
			<Col className = {`p-1 text-center ${classes.buttons}`}>
				<RequisitesAddition {...props}/>
			</Col>
		</Row>
		{error}
		<Row>
			<Col>
				<DataTable value={props.requisites}
				           paginator className="p-datatable-striped"
				           paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				           rows = {10} rowsPerPageOptions = {[10, 20, 50, 100]}
				           onRowReorder = {onRowReorder}
				           emptyMessage={emptyTableMessage}>
					<Column rowReorder style = {{width: '3em'}}/>
					{labelColumn}
					{methodColumn}
					{urlColumn}

					<Column key = 'actions'
					        header = {intl.formatMessage({ id: "SUITE.PREREQUISITE.TABLE.COLUMN.ACTIONS",
						        defaultMessage: 'Actions'})}
					        body = {requisiteActionsBodyTemplate}
					        className = 'p-text-right'
					/>
				</DataTable>
				<DeletionConfirmationDialog open={toBeRemovedRequestConfirmationDialogOpen} closeDialog={closeRemoveConfirmationDialog}
											message={<FormattedMessage id="REST.REQUESTS.TABLE.REMOVE.REQUEST"
																	   defaultMessage='Remove Request'/> }
											name={toBeRemovedRequest ? `${toBeRemovedRequest.label}` : ''}
											deleteAction={() => {
												closeRemoveConfirmationDialog();
												props.deleteRequisite(toBeRemovedRequest); // TODO send index, right now it uses whole object
												if(!allowLoading) {
													setAllowLoading(true);
												}
											}}
				/>
				<Snackbar
					anchorOrigin = {{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open = {requestsUpdateSnackbarOpen}
					autoHideDuration = {2000}
					onClose = {hideRequestsSnackbar}
					message = {requestsUpdateSnackbarMessage}
				>
					<Alert onClose = {hideRequestsSnackbar} severity = "success">
						{requestsUpdateSnackbarMessage}
					</Alert>
				</Snackbar>
			</Col>
		</Row>
	</Container>

}

export default RequisiteTab;