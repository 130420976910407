import React from "react";
import {Card} from "react-bootstrap";
import {Alert} from "@material-ui/lab";

import FormUserTabs from "./FormUserTabs";
import {UserCreationLoadingDialog, UserUpdatingLoadingDialog} from "../../UI/LoadingDialog";
import UserActions from "./UserActions";

const FormUserDisplay = props => {

	let errorMessages = props.errorMessages.map((error, index) => {
		return <Alert elevation = {3} variant = "standard" key={index}
		              severity = "error" className = 'my-4'>
			{error}
		</Alert>
	});

	let submitFunction;
	if(props.type === 'create'){
		submitFunction = props.selectTab === 0 ? props.createNormalUser : props.createCICDUser;
	} else if(props.type === 'update'){
		submitFunction = props.selectTab === 0 ? props.updateNormalUser : props.updateCICDUser;
	}

	return (
		// <div className = {``}>
		<form noValidate autoComplete = "off"  onSubmit = {submitFunction}>
			<UserCreationLoadingDialog/>
			<UserUpdatingLoadingDialog/>
			<Card>
				{errorMessages}
				{props.successMessage && <Alert elevation={3} variant="standard" severity="success" className='my-4'>
					{props.successMessage}
				</Alert>}
				<Card.Body className='p-0'>
					<FormUserTabs {...props}/>
				</Card.Body>
				<Card.Footer className='py-4 px-8'>
					<UserActions type= {props.type}
					             loading={props.loading}
								 myAccount={props.myAccount}
								 userID={props.userID}
								 deleteMyAccount={props.deleteMyAccount}/>
				</Card.Footer>
			</Card>
		</form>
	);
}

export default FormUserDisplay;