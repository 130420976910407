import React from "react";
import {FormattedMessage} from "react-intl";
import {Col, Container, Row} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {useIntl} from 'react-intl';
import {Tooltip, Typography} from "@material-ui/core";

import * as Plan from "../../containers/Payment/enum/PlanType";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const PricingTableJSX = props => {
	const intl = useIntl();

	let annualButtonActive = '', monthlyButtonActive = '';
	let annualTabActive = '', monthlyTabActive = '';
	if(props.showMonthly === true){
		monthlyButtonActive = 'active';
		monthlyTabActive = 'show active';
	} else {
		annualButtonActive = 'active';
		annualTabActive = 'show active';
	}

	// limit downgrade
	let disbaleDowngradeButtons = false;
	if(props.currentPlan && props.currentPlan.status === 'TRIALING'){
		// disabledDowngradeOptions
		disbaleDowngradeButtons = true;
	}

	const downgradeButtonTooltipMessage = `${intl.formatMessage({id: "PAYMENT.DOWNGRADE.DISABLED.TOOLTIP.1", 
		defaultMessage: 'You had already downgraded to the plan '})}${props.nextPlan}
	${intl.formatMessage({id: "PAYMENT.DOWNGRADE.DISABLED.TOOLTIP.2", defaultMessage: ', you have to wait until '})}${props.currentPeriodEndAt}
	${intl.formatMessage({id: "PAYMENT.DOWNGRADE.DISABLED.TOOLTIP.3", defaultMessage: ' to be able to downgrade to another plan.'})}`
	const downgradeButtonTooltip = <Tooltip title={<Typography fontSize={30}>
		{downgradeButtonTooltipMessage}
	</Typography>}>
		<span>
			<button type="button" disabled={true} style={{pointerEvents: 'none'}}
					className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
				{props.basicButtonLabel}
			</button>
		</span>
	</Tooltip>;

	return <div className="container p-0">
		<div className="card">
			<div className="position-absolute w-100 h-50 rounded-card-top" style={{backgroundColor: "#22B9FF"}}/>
			<div className="card-body position-relative">
				<h3 className="7 text-white text-center my-10 my-lg-15">
					<FormattedMessage id = "PAYMENT.HEADER" defaultMessage = 'Transparent & Simple Pricing'/>
				</h3>
				<div className="d-flex justify-content-center">
					<ul className="nav nav-pills nav-primary mb-10 mb-lg-20 bg-white rounded" id="pills-tab" role="tablist">
						<li className="nav-item p-0 m-0">
							<a className={`nav-link ${monthlyButtonActive} font-weight-bolder rounded-right-0 px-8 py-5`} id="pills-tab-1"
							   data-toggle="pill" aria-expanded="true" aria-controls="kt-pricing-2_content1"
							   onClick={() => props.showTab(1)}>
								<FormattedMessage id = "PAYMENT.TAB.MONTHLY.PLANS" defaultMessage = 'Monthly Plans'/>
							</a>
						</li>
						<li className="nav-item p-0 m-0">
							<a className={`nav-link ${annualButtonActive} font-weight-bolder rounded-left-0 px-8 py-5`} id="pills-tab-2"
							   data-toggle="pill" aria-expanded="true" aria-controls="kt-pricing-2_content2"
							   onClick={() => props.showTab(2)}>
								<FormattedMessage id = "PAYMENT.TAB.ANNUAL.PLANS" defaultMessage = 'Annual Plans'/>
							</a>
						</li>
					</ul>
				</div>
				<div className="tab-content">
					<div className={`tab-pane ${annualTabActive} row text-center`} role="tabpanel"
						 aria-labelledby="pills-tab-1">
						<div className="card-body bg-white">
							<div className="row justify-content-center">
								<div className="col-md-6 col-xl-4 col-xxl-3">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">

										<div className="card card-custom wave wave-animate-slow wave-danger mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0'>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-danger svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																		<FormattedMessage id = "PAYMENT.PLAN.FREE" defaultMessage = 'Free Plan'/>
																	</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl pb-10">
													<span className='font-size-h4'>
														{props.freePlan !== null ? props.freePlan.projectsNumber : 1} <FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECT" defaultMessage = 'Project'/>
													</span>
											<span className='font-size-h4'>
												{props.freePlan !== null ? props.freePlan.webExecutionTime : 30}
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												{props.freePlan !== null ? props.freePlan.mobileExecutionTime : 15}
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												{(props.freePlan !== null ? props.freePlan.mobileInspectTime : 60)}
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER.MINUTES" defaultMessage = ' Minutes Of Android Recorder'/>
											</span>
											<span className='font-size-h4'>
														{props.freePlan !== null ? props.freePlan.usersNumber : 5} <FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS" defaultMessage = 'Users'/>
													</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing'/></span>
											<span className='font-size-h4 mb-9'> </span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													<FormattedMessage id = "PAYMENT.PRICE.FREE" defaultMessage = 'Free'/>
											{/*<sup className="font-size-h3 font-weight-normal pl-1">$</sup>*/}
												</span>


										{props.showPurchaseButtons && <div className="mt-7">
											{!props.freeButtonIsCurrent ?
												<button type="button"
														onClick={() => props.freeButtonAction.current()}
														className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
													{props.freeButtonLabel}
												</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.freeButtonLabel}
												</div>
											}
										</div> }
									</div>
								</div>

								<div className="col-md-6 col-xl-4 col-xxl-3 border-x-0 border-x-md border-y border-y-md-0">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">

										<div className="card card-custom wave wave-animate-slow wave-warning mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0'>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-warning svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																		<FormattedMessage id = "PAYMENT.PLAN.BASIC" defaultMessage = 'Basic'/>
																	</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl mb-10">
													<span className='font-size-h4'>
														<span className='text-warning'>{props.annualPlans.BASIC !== null ? props.annualPlans.BASIC.projectsNumber : 2} </span>
														<FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECTS" defaultMessage = 'Projects'/>
													</span>
											<span className='font-size-h4'>
												{/*<span className='text-warning'>{props.annualPlans.BASIC !== null ? props.annualPlans.BASIC.executionsNumber : 1_200} </span>*/}
												{/*<FormattedMessage id = "PAYMENT.PLAN.FEATURE.TESTS.RUNS" defaultMessage = 'Tests Runs'/>*/}

												<span className='text-warning'>{props.annualPlans.BASIC !== null ? props.annualPlans.BASIC.webExecutionTime : 1500}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												<span className='text-warning'>{props.annualPlans.BASIC !== null ? props.annualPlans.BASIC.mobileExecutionTime : 750}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												<span className='text-warning'>{(props.annualPlans.BASIC !== null ? props.annualPlans.BASIC.mobileInspectTime : 1200) / 60}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER" defaultMessage = ' Hours Of Mobile Recorder'/>
											</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS.UNLIMITED" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.SUPPORT" defaultMessage = 'Support'/></span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													{props.annualPlans.BASIC !== null ? props.annualPlans.BASIC.price.amount : 1_980}
											<sup className="font-size-h3 font-weight-normal pl-1">€ </sup>
											<sup className="font-size-h6 font-weight-lighter pl-1">
												<FormattedMessage id = "PAYMENT.PRICE.EXCLU.TAX" defaultMessage = 'Excl Tax'/>
											</sup>
										</span>
										{props.showPurchaseButtons && <div className="mt-7">
											{!props.basicButtonIsCurrent ?
												props.disabledDowngradeOptions.annual.includes(Plan.BASIC) ?
													<Tooltip title={<Typography fontSize={30}>
														{downgradeButtonTooltipMessage}
													</Typography>}>
														<span>
															<button type="button" disabled={true} style={{pointerEvents: 'none'}}
																	className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
																{props.basicButtonLabel}
															</button>
														</span>
													</Tooltip>:
													<button type="button"
															onClick={() => props.basicButtonAction.current()}
															className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
														{props.basicButtonLabel}
													</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.basicButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>


								<div className="col-md-6 col-xl-4 col-xxl-3 border-x-0 border-x-md border-y border-y-md-0">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">

										<div className="card card-custom wave wave-animate-slow wave-primary mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0'>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-primary svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Mirror.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																		<FormattedMessage id = "PAYMENT.PLAN.PROFESSIONAL" defaultMessage = 'Professional'/>
																	</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl mb-10">
													<span className='font-size-h4'>
														<span className='text-primary'>{props.annualPlans.PROFESSIONAL !== null ? props.annualPlans.PROFESSIONAL.projectsNumber : 5} </span>
														<FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECTS" defaultMessage = 'Projects'/>
													</span>
											<span className='font-size-h4'>
												<span className='text-primary'>{props.annualPlans.PROFESSIONAL !== null ? props.annualPlans.PROFESSIONAL.webExecutionTime : 4500}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												<span className='text-primary'>{props.annualPlans.PROFESSIONAL !== null ? props.annualPlans.PROFESSIONAL.mobileExecutionTime : 2250}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												<span className='text-primary'>{(props.annualPlans.PROFESSIONAL !== null ? props.annualPlans.PROFESSIONAL.mobileInspectTime : 4800) / 60}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER" defaultMessage = ' Hours Of Mobile Recorder'/>
											</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS.UNLIMITED" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.SUPPORT" defaultMessage = 'Support'/></span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													{props.annualPlans.PROFESSIONAL !== null ? props.annualPlans.PROFESSIONAL.price.amount : 4_980}
											<sup className="font-size-h3 font-weight-normal pl-1">€ </sup>
											<sup className="font-size-h6 font-weight-lighter pl-1">
												<FormattedMessage id = "PAYMENT.PRICE.EXCLU.TAX" defaultMessage = 'Excl Tax'/>
											</sup>
										</span>
										{props.showPurchaseButtons && <div className="mt-7">
											{!props.professionalButtonIsCurrent ?
												props.disabledDowngradeOptions.annual.includes(Plan.PROFESSIONAL) ?
													<Tooltip title={<Typography fontSize={30}>
														{downgradeButtonTooltipMessage}
													</Typography>}>
														<span>
															<button type="button" disabled={true} style={{pointerEvents: 'none'}}
																	className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
																{props.professionalButtonLabel}
															</button>
														</span>
													</Tooltip>:
													<button type="button"
															onClick={() => props.professionalButtonAction.current()}
															className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
														{props.professionalButtonLabel}
													</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.professionalButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>
								<div className="col-md-6 col-xl-4 col-xxl-3">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">
										<div className="card card-custom wave wave-animate-slow wave-success mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0 '>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-success svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Sketch.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																		<FormattedMessage id = "PAYMENT.PLAN.PREMIUM" defaultMessage = 'Premium'/>
																	</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl mb-10">
											<span className='font-size-h4 text-success'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECTS.UNLIMITED" defaultMessage = 'Unlimited Projects'/></span>
											<span className='font-size-h4'>
												<span className='text-success'>{props.annualPlans.PREMIUM !== null ? props.annualPlans.PREMIUM.webExecutionTime : 11250}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												<span className='text-success'>{props.annualPlans.PREMIUM !== null ? props.annualPlans.PREMIUM.mobileExecutionTime : 4500}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												<span className='text-success'>{(props.annualPlans.PREMIUM !== null ? props.annualPlans.PREMIUM.mobileInspectTime : 14400) / 60}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER" defaultMessage = ' Hours Of Mobile Recorder'/>
											</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS.UNLIMITED" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.SUPPORT" defaultMessage = 'Support'/></span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													{props.annualPlans.PREMIUM !== null ? props.annualPlans.PREMIUM.price.amount : 9980}
											<sup className="font-size-h3 font-weight-normal pl-1">€ </sup>
											<sup className="font-size-h6 font-weight-lighter pl-1">
												<FormattedMessage id = "PAYMENT.PRICE.EXCLU.TAX" defaultMessage = 'Excl Tax'/>
											</sup>
										</span>
										{props.showPurchaseButtons && <div className="mt-7">
											{!props.premiumButtonIsCurrent ?
												// There is no need to do premium annual downgrade limit
												<button type="button"
														onClick={() => props.premiumButtonAction.current()}
														className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
													{props.premiumButtonLabel}
												</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.premiumButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*TODO Change this to just one TAB and change the data inside*/}
					<div className={`tab-pane ${monthlyTabActive} row text-center`} role="tabpanel"
						 aria-labelledby="pills-tab-2">
						<div className="card-body bg-white">
							<div className="row justify-content-center">
								<div className="col-md-6 col-xl-4 col-xxl-3">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">

										<div className="card card-custom wave wave-animate-slow wave-danger mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0'>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-danger svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																		<FormattedMessage id = "PAYMENT.PLAN.FREE" defaultMessage = 'Free Plan'/>
																	</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl pb-10">
													<span className='font-size-h4'>
														{props.freePlan !== null ? props.freePlan.projectsNumber : 1} <FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECT" defaultMessage = 'Project'/>
													</span>
											<span className='font-size-h4'>
												{props.freePlan !== null ? props.freePlan.webExecutionTime : 30}
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												{props.freePlan !== null ? props.freePlan.mobileExecutionTime : 15}
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												{(props.freePlan !== null ? props.freePlan.mobileInspectTime : 60)}
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER.MINUTES" defaultMessage = ' Minutes Of Android Recorder'/>
											</span>
											<span className='font-size-h4'>
														{props.freePlan !== null ? props.freePlan.usersNumber : 5} <FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing'/></span>
											<span className='font-size-h4 mb-9'> </span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													<FormattedMessage id = "PAYMENT.PRICE.FREE" defaultMessage = 'Free'/>
											{/*<sup className="font-size-h3 font-weight-normal pl-1">$</sup>*/}
												</span>
										{props.showPurchaseButtons && <div className="mt-7">
											{!props.freeButtonIsCurrent ?
												<button type="button"
														onClick={() => props.freeButtonAction.current()}
														className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
													{props.freeButtonLabel}
												</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.freeButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>

								<div className="col-md-6 col-xl-4 col-xxl-3 border-x-0 border-x-md border-y border-y-md-0">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">

										<div className="card card-custom wave wave-animate-slow wave-warning mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0'>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-warning svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																	<FormattedMessage id = "PAYMENT.PLAN.BASIC" defaultMessage = 'Basic'/>
																</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl mb-10">
													<span className='font-size-h4'>
														<span className='text-warning'>{props.monthlyPlans.BASIC !== null ? props.monthlyPlans.BASIC.projectsNumber : 2} </span>
														<FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECTS" defaultMessage = 'Projects'/>
													</span>
											<span className='font-size-h4'>
												<span className='text-warning'>{props.monthlyPlans.BASIC !== null ? props.monthlyPlans.BASIC.webExecutionTime : 1500}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												<span className='text-warning'>{props.monthlyPlans.BASIC !== null ? props.monthlyPlans.BASIC.mobileExecutionTime : 750}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												<span className='text-warning'>{(props.monthlyPlans.BASIC !== null ? props.monthlyPlans.BASIC.mobileInspectTime : 1200) / 60}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER" defaultMessage = ' Hours Of Mobile Recorder'/>
											</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS.UNLIMITED" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.SUPPORT" defaultMessage = 'Support'/></span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													{props.monthlyPlans.BASIC !== null ? props.monthlyPlans.BASIC.price.amount : 199}
											<sup className="font-size-h3 font-weight-normal pl-1">€ </sup>
											<sup className="font-size-h6 font-weight-lighter pl-1">
												<FormattedMessage id = "PAYMENT.PRICE.EXCLU.TAX" defaultMessage = 'Excl Tax'/>
											</sup>
										</span>
										{props.showPurchaseButtons && <div className="mt-7 ">
											{!props.basicButtonIsCurrent ?
												props.disabledDowngradeOptions.monthly.includes(Plan.BASIC) ?
													<Tooltip title={<Typography fontSize={30}>
														{downgradeButtonTooltipMessage}
													</Typography>}>
														<span>
															<button type="button" disabled={true} style={{pointerEvents: 'none'}}
																	className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
																{props.basicButtonLabel}
															</button>
														</span>
													</Tooltip>:
													<button type="button"
															onClick={() => props.basicButtonAction.current()}
															className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
														{props.basicButtonLabel}
													</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.basicButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>

								<div className="col-md-6 col-xl-4 col-xxl-3 border-x-0 border-x-md border-y border-y-md-0">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">

										<div className="card card-custom wave wave-animate-slow wave-primary mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0'>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-primary svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Mirror.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																	<FormattedMessage id = "PAYMENT.PLAN.PROFESSIONAL" defaultMessage = 'Professional'/>
																</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl mb-10">
													<span className='font-size-h4'>
														<span className='text-primary'>{props.monthlyPlans.PROFESSIONAL !== null ? props.monthlyPlans.PROFESSIONAL.projectsNumber : 5} </span>
															<FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECTS" defaultMessage = 'Projects'/>
													</span>
											<span className='font-size-h4'>
												<span className='text-primary'>{props.monthlyPlans.PROFESSIONAL !== null ? props.monthlyPlans.PROFESSIONAL.webExecutionTime : 4500}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												<span className='text-primary'>{props.monthlyPlans.PROFESSIONAL !== null ? props.monthlyPlans.PROFESSIONAL.mobileExecutionTime : 2250}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												<span className='text-primary'>{(props.monthlyPlans.PROFESSIONAL !== null ? props.monthlyPlans.PROFESSIONAL.mobileInspectTime : 4800) / 60}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER" defaultMessage = ' Hours Of Mobile Recorder'/>
											</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS.UNLIMITED" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing: coming soon..'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.SUPPORT" defaultMessage = 'Support'/></span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													{props.monthlyPlans.PROFESSIONAL !== null ? props.monthlyPlans.PROFESSIONAL.price.amount : 499}
											<sup className="font-size-h3 font-weight-normal pl-1">€ </sup>
											<sup className="font-size-h6 font-weight-lighter pl-1">
												<FormattedMessage id = "PAYMENT.PRICE.EXCLU.TAX" defaultMessage = 'Excl Tax'/>
											</sup>
										</span>
										{props.showPurchaseButtons && <div className="mt-7 ">
											{!props.professionalButtonIsCurrent ?
												props.disabledDowngradeOptions.monthly.includes(Plan.PROFESSIONAL) ?
													<Tooltip title={<Typography fontSize={30}>
														{downgradeButtonTooltipMessage}
													</Typography>}>
														<span>
															<button type="button" disabled={true} style={{pointerEvents: 'none'}}
																	className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
																{props.professionalButtonLabel}
															</button>
														</span>
													</Tooltip>:
													<button type="button"
															onClick={() => props.professionalButtonAction.current()}
															className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
														{props.professionalButtonLabel}
													</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.professionalButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>
								<div className="col-md-6 col-xl-4 col-xxl-3 ">
									<div className="pt-15 pt-md-10 pb-15 px-2 text-center">
										<div className="card card-custom wave wave-animate-slow wave-success mb-10 min-h-120px">
											<div className="card-body px-1 d-flex align-items-center">
												<Container className='p-0 '>
													<Row className='justify-content-center align-items-center'>
														<Col xs='auto'>
																	<span className="svg-icon svg-icon-success svg-icon-4x svg-icon-md-3x svg-icon-lg-4x">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Sketch.svg")}/>
																	</span>
														</Col>
														<Col md='12' lg='8' className='text-lg-left text-md-center'>
																	<span className="text-dark font-weight-bold font-size-h3 mb-3 vw-100">
																		<FormattedMessage id = "PAYMENT.PLAN.PREMIUM" defaultMessage = 'Premium'/>
																	</span>
														</Col>
													</Row>
												</Container>
											</div>
										</div>

										<div className="d-flex flex-column line-height-xl mb-10">


											<span className='font-size-h4 text-success'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PROJECTS.UNLIMITED" defaultMessage = 'Unlimited Projects'/></span>
											<span className='font-size-h4'>
												<span className='text-success'>{props.monthlyPlans.PREMIUM !== null ? props.monthlyPlans.PREMIUM.webExecutionTime : 11250}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.WEB.TESTING.MINUTES" defaultMessage = ' WTM*** Or '/>
												<br/>
												<span className='text-success'>{props.monthlyPlans.PREMIUM !== null ? props.monthlyPlans.PREMIUM.mobileExecutionTime : 4500}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.REAL.DEVICE.TESTING.MINUTES" defaultMessage = ' RDTM***'/>
											</span>
											<span className='font-size-h4'>
												<span className='text-success'>{(props.monthlyPlans.PREMIUM !== null ? props.monthlyPlans.PREMIUM.mobileInspectTime : 14400) / 60}</span>
												<FormattedMessage id = "PAYMENT.PLAN.FEATURE.ANDROID.RECORDER" defaultMessage = ' Hours Of Mobile Recorder'/>
											</span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.USERS.UNLIMITED" defaultMessage = 'Users'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.CICD" defaultMessage = 'CICD Capability'/></span>
											{/*<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.PLUGIN" defaultMessage = 'Plugin: Chrome and Firefox'/></span>*/}
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.API" defaultMessage = 'API Testing: coming soon..'/></span>
											<span className='font-size-h4'><FormattedMessage id = "PAYMENT.PLAN.FEATURE.SUPPORT" defaultMessage = 'Support'/></span>
										</div>
										<span className="font-size-h1 d-block font-weight-boldest text-dark">
													{props.monthlyPlans.PREMIUM !== null ? props.monthlyPlans.PREMIUM.price.amount : 999}
											<sup className="font-size-h3 font-weight-normal pl-1">€ </sup>
											<sup className="font-size-h6 font-weight-lighter pl-1">
												<FormattedMessage id = "PAYMENT.PRICE.EXCLU.TAX" defaultMessage = 'Excl Tax'/>
											</sup>
										</span>
										{props.showPurchaseButtons && <div className="mt-7">
											{!props.premiumButtonIsCurrent ?
												props.disabledDowngradeOptions.monthly.includes(Plan.PREMIUM) ?
													<Tooltip title={<Typography fontSize={30}>
														{downgradeButtonTooltipMessage}
													</Typography>}>
														<span>
															<button type="button" disabled={true} style={{pointerEvents: 'none'}}
																	className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
																{props.premiumButtonLabel}
															</button>
														</span>
													</Tooltip>:
													<button type="button"
															onClick={() => props.premiumButtonAction.current()}
															className={`btn btn-primary text-uppercase font-weight-bolder px-15 py-3`}>
														{props.premiumButtonLabel}
													</button>
												:
												<div
													className={`border border-success border-3 d-inline-block text-uppercase font-weight-bolder px-15 py-3`}>
													{props.premiumButtonLabel}
												</div>
											}
										</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<span className='font-size-h4'>
					<FormattedMessage id = "PAYMENT.PLAN.NOTE.1" defaultMessage = '* WTM, RDTM And Mobile Recorder Limits Are Per Each Month'/> <br/>
					<FormattedMessage id = "PAYMENT.PLAN.NOTE.2" defaultMessage = '** All Prices Excluding Taxes'/><br/>
					<FormattedMessage id = "PAYMENT.PLAN.NOTE.3" defaultMessage = '*** WTM: Web Testing Minutes. RDTM: Real Device Testing Minutes'/>
				</span>
			</div>
		</div>
	</div>
}

export default PricingTableJSX;