import {put, select, delay} from "redux-saga/effects";
import {push} from "connected-react-router";
import axios from "axios";

import generalAxios, {backendUrl} from "../../axios/axios";
import * as actions from "../actions";
import {updatePreferredProject} from "./users";
import {loadIteration} from "./iteration";

const uploadAxios = axios.create({
	baseURL: backendUrl,
	timeout: 86_400_000, // 1day 24H
});
uploadAxios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');


export function* createProject(action) {
	yield put(actions.createProjectStart());
	try {
		const response = yield generalAxios.post(`/projects/user/${action.username}`, action.project);
		// console.log('CHECK THIS response', response);
		if (response.status === 201) {
			yield put(actions.createProjectSuccess(response.data));

			yield loadProjects({username: localStorage.getItem('username')});
			if(!action.suggestDefault){
				// Set Preferred
				yield updatePreferredProject({
					userId: action.loggedInUser.id,
					preferredId: response.data.id,
					redirect: false
				});
				// yield put(actions.loadProjectSuccess(response.data, false));  //commented because updatePreferredProject calls loadProject

			}
			let suggestDefaultURLPart = action.suggestDefault ? '?suggestDefault=true' : '';
			yield put(push(`/project/${response.data.id}${suggestDefaultURLPart}`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createProjectFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createProjectFail('Error Creating Project, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createProjectFail(error.message));
		}
	}
}

export function* createAndroidProject(action) {
	yield put(actions.createProjectAndroidStart()); // HERE
	let isInfoUpdated = false;
	try {
		let response = yield generalAxios.post(`/projects/android/user/${localStorage.getItem('username')}`, action.project);
		// console.log('response', response);
		if (response.status === 201) {
			isInfoUpdated = true;
			// Upload To AWS S3
			const projectId = response.data.id;
			const responseProject = response.data;
			response = yield generalAxios.get(`/projects/${response.data.id}/presigned-url?fileName=${action.apkLabel}`);
			const presignedURL = response.data;

			yield axios.put(presignedURL, action.apk, {headers: {"Content-Type": "application/octet-stream"}});

			yield generalAxios.put(`/projects/${projectId}/upload-success?fileName=${action.apkLabel}`);

			yield put(actions.loadProjects(localStorage.getItem('username')));

			if(!action.suggestDefault){
				yield updatePreferredProject({
					userId: action.loggedInUser.id,
					preferredId: projectId,
					redirect: false
				});
			}
			yield put(actions.createProjectAndroidSuccess(responseProject));

			let suggestDefaultURLPart = action.suggestDefault ? '?suggestDefault=true' : '';
			yield put(push(`/project/${projectId}/edit${suggestDefaultURLPart}`));
		}
	} catch (error) {
		if (error.response && error.response.data.message) {
			console.log(error.response.data.message);
			yield put(actions.createProjectAndroidFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			if(isInfoUpdated){
				yield put(actions.createProjectAndroidFail('The project is created successfully but there was an error uploading the APK file, try again later. ' +
					'Navigate to Administration / Projects, to view your project'));
			} else {
				yield put(actions.createProjectAndroidFail('Error Creating Android Project, Try Again'));
			}
		} else {
			console.log('Error', error.message);
			yield put(actions.createProjectAndroidFail(error.message));
		}
	}
}

export function* loadProject(action) {
	yield put(actions.loadProjectStart(action.view));
	try {
		const response = yield generalAxios.get(`/projects/${action.id}`);
		// console.log('response', response.data);

		yield put(actions.loadProjectSuccess(response.data, action.view));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadProjectFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadProjectFail('Error Loading The Project, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadProjectFail(error.message, action.view));
		}
	}
}

export function* loadProjectViewPort(action) {
	yield put(actions.loadProjectViewPortStart());
	try {
		const response = yield generalAxios.get(`/projects/${action.id}/iterations`);
			// Example: enableLive: true
			// id: "5f86044124aa9a000642b274"
			// identifier: "5f86044142b274"
			// label: "Demoqa Web"
			// subscripted: false
			// type: "WEB"
			// viewPort: 15000

		const viewPort = response.data.viewPort;
		yield put(actions.loadProjectViewPortSuccess(viewPort));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadProjectViewPortFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadProjectViewPortFail('Error Loading The Project View Port, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadProjectViewPortFail(error.message));
		}
	}
}

export function* loadProjects(action) {
	yield put(actions.loadProjectsStart());
	try {
		const response = yield generalAxios.get(`/projects/user/${action.username}`);

		yield put(actions.loadProjectsSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadProjectsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadProjectsFail('Error Loading Projects, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadProjectsFail(error.message));
		}
	}
}

// function* onDeleteSetProjectAndPreferred(userId, preferredId) {
// 	// console.log('onDeleteSetProjectAndPreferred(action)', action);
// 	if(action.isPreferredProject){
//
// 		yield updatePreferredProject({
// 			userId: action.loggedInUser.id,
// 			preferredId: action.newPreferred !== null ? action.newPreferred.id : null,
// 			redirect: false
// 		});
// 		// yield updatePreferredProject({
// 		// 	userId: action.loggedInUser.id,
// 		// 	preferredId: response.data.id,
// 		// 	redirect: false
// 		// });
// 		// console.log('isPreferredProject', action.isPreferredProject);
// 		// yield put(actions.loadProjectSuccess(action.newPreferred, false)); // commented because updatePreferredProject is called instead
//
// 		// console.log('action.loggedInUser', action.loggedInUser);
// 		// yield put(actions.updateUser(action.loggedInUser, '/dashboard', true)); // commented because updatePreferredProject is called instead
// 		// console.log('onDeleteSetProjectAndPreferred completed succesfully');
// 	} else {
// 		// nothing
// 	}
//
// }

export function* deleteProject(action) {
	yield put(actions.deleteProjectStart());
	try {
		const response = yield generalAxios.delete(`/projects/${action.id}`);
		// console.log('response', response.data);

		yield put(actions.deleteProjectSuccess(action.id));
		// yield checkProjectsLimit({username: localStorage.getItem('username')});
		if(action.isPreferredProject === true ){
			if(action.newPreferred !== null){
				// console.log('action.newPreferred !== null');
				yield updatePreferredProject({
					userId: action.loggedInUser.id,
					preferredId: action.newPreferred.id,
					redirect: false
				});
			} else {
				// console.log('action.newPreferred === null');
				const loggedInUser = yield select(state => state.authentication.loggedInUser);
				const loggedInUserCopy = {...loggedInUser};
				loggedInUserCopy.preferedProjectId = null;
				loggedInUserCopy.preferedProjectLabel = null;
				loggedInUserCopy.preferedProjectType = null;
				yield put(actions.loginSuccess(loggedInUser.login, loggedInUserCopy));

				yield put(actions.loadProjectSuccess(null, false));
			}
		}
		// yield onDeleteSetProjectAndPreferred(
		// 	action.loggedInUser.id,
		// 	action.newPreferred !== null ? action.newPreferred.id : null,
		// // 	{
		// // 	isPreferredProject: action.isPreferredProject,
		// // 	newPreferred: action.newPreferred,
		// // 	loggedInUser: action.loggedInUser
		// // }
		// );

		if(action.redirect){
			yield put(push(action.redirect));
		}

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteProjectFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteProjectFail('Error Deleting The Project, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteProjectFail(error.message));
		}
	}
}

export function* updateProject(action) {
	yield put(actions.updateProjectStart());
	try {

		yield generalAxios.patch(`/projects/${action.id}`, action.project);
		// console.log('response', response.data);

		if(action.preferredProject){
			yield loadProject({id: action.id, view: false});
		}
		yield put(actions.loadProjects(localStorage.getItem('username')));
		yield put(actions.updateProjectSuccess());
		yield put(push(`/project/${action.id}`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateProjectFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateProjectFail('Error Updating Project, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateProjectFail(error.message));
		}
	}
}

export function* updateAndroidProject(action) {
	yield put(actions.updateProjectAndroidStart());
	let isInfoUpdated = false;
	try {
		const responseInfo = yield generalAxios.patch(`/projects/${action.id}`, action.projectInfo);
		if (responseInfo.status === 200) {
			isInfoUpdated = true;
			if(action.isNewAPK === true){
				// Upload To AWS S3
				const response = yield generalAxios.get(`/projects/${action.id}/presigned-url?fileName=${action.apkLabel}`);
				const presignedURL = response.data;

				yield axios.put(presignedURL, action.apk, {headers: {"Content-Type": "application/octet-stream"}});

				yield generalAxios.put(`/projects/${action.id}/upload-success?fileName=${action.apkLabel}`);
			}
			if(action.isPreferredProject){
				// yield loadProject({id: action.id, view: false});
				yield put(actions.loadProject(action.id, false));
			}
			yield put(actions.loadProjects(localStorage.getItem('username')));
			yield put(actions.updateProjectAndroidSuccess());
			yield put(push(`/project/${action.id}`));
		}

	} catch (error) {
		if (error.response && error.response.data.message) {
			console.log(error.response.data.message);
			yield put(actions.updateProjectAndroidFail(error.response.data.message));
		} else if (error.request) {
			console.log('Error', error.request);
			if(isInfoUpdated){
				yield put(actions.updateProjectAndroidFail('The project is updated successfully but there was an error uploading the APK file, try again later'));
			} else {
				yield put(actions.updateProjectAndroidFail('Error Updating Android Project, Try Again'));
			}
		} else {
			console.log(error.message)
			yield put(actions.updateProjectAndroidFail(error.message));
		}
	}
}

export function* toggleExemptProject(action) {
	yield put(actions.toggleExemptProjectStart());
	try {
		let url;
		if(action.exempt){
			//acc
			url = `/projects/${action.id}/accountable`;
		} else {
			// exempt
			url = `/projects/${action.id}/exempt`;
		}
		const response = yield generalAxios.put(url, {observe: "response"});

		yield put(actions.toggleExemptProjectSuccess(action.id)); // should switch false/true

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.toggleExemptProjectFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.toggleExemptProjectFail('Error Toggle Exempt Project, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.toggleExemptProjectFail(error.message));
		}
	}
}

export function* cancelSubscription(action) {
	yield put(actions.cancelSubscriptionStart());
	try {
		// backend does not return anything. just and OK 200 Response
		yield generalAxios.put(`/projects/${action.id}/cancelPayment`);
		yield put(actions.cancelSubscriptionSuccess());

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.cancelSubscriptionFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.cancelSubscriptionFail('Error Canceling Subscription, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.cancelSubscriptionFail(error.message));
		}
	}
}

export function* canCreateProject(action) {
	yield put(actions.canCreateProjectStart());
	try {
		const response = yield generalAxios.get(`/projects/can-add`);
		// console.log('response', response.data);

		yield put(actions.canCreateProjectSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.canCreateProjectFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.canCreateProjectFail('Error Finding Out Project Creation Possibility, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.canCreateProjectFail(error.message, action.view));
		}
	}
}

export function* loadTimeZones(action) {
	yield put(actions.loadTimeZonesStart());
	try {
		const response = yield generalAxios.get(`/projects/timezones`);

		yield put(actions.loadTimeZonesSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadTimeZonesFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadTimeZonesFail('Error Loading TimeZones, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadTimeZonesFail(error.message, action.view));
		}
	}
}

export function* checkAndroidEnvironmentStatus(action) {
	yield put(actions.checkAndroidEnvironmentStatusStart());
	try {
		const inspectURL = process.env.REACT_APP_INSPECT_API_URL;
		// console.log('inspectURL', inspectURL);
		const inspectAxios = axios.create({baseURL: inspectURL, timeout: 15_000});
		inspectAxios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');

		const response = yield inspectAxios.get(`/inspect/projects/${action.projectID}/status/${action.domainID}`);

		const result = response.data;
		// const result = {type: 'ERROR', message: 'You cannot create a new Android Recorder Environment. You have reached the limit of your subscription'};
		if(result.status === 'ERROR'){
			yield put(actions.checkAndroidEnvironmentStatusFail(result.message));
		} else {
			yield put(actions.checkAndroidEnvironmentStatusSuccess(result));
		}

	} catch (error) {
		const result = {type: 'ERROR', message: 'You cannot create a new Android Recorder Environment. You have reached the limit of your subscription'};
		yield put(actions.checkAndroidEnvironmentStatusFail(result.message));
		// if (error.response) {
		// 	console.log(error.response.data.message);
		// 	yield put(actions.checkAndroidEnvironmentStatusFail(error.response.data.message));
		// } else if (error.request) {
		// 	console.log(error.request)
		// 	yield put(actions.checkAndroidEnvironmentStatusFail('Error Check Android Environment Status, Try Again'));
		// } else {
		// 	console.log('Error', error.message);
		// 	yield put(actions.checkAndroidEnvironmentStatusFail(error.message));
		// }
	}
}

export function* loadDevices(action) {
	yield put(actions.loadDevicesStart());
	try {
		const response = yield generalAxios.get(`/devices/android`);
		// console.log('devices', response.data);

		yield put(actions.loadDevicesSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadDevicesFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadDevicesFail('Error Loading Devices, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadDevicesFail(error.message, action.view));
		}
	}
}

export function* createDeviceGroup(action) {
	yield put(actions.createDeviceGroupStart());
	try {
		let response;
		if(action.forIteration === false){
			response = yield generalAxios.post(`/device-groups/projects/${action.projectId}`, action.group);
		} else {
			response = yield generalAxios.post(`/device-groups/iterations/${action.iterationId}`, action.group);
		}

		// console.log('create group', response.data);

		const deviceGroup = response.data;

		if(deviceGroup.compatible === false){
			yield put(actions.createDeviceGroupIncompatible(deviceGroup));
		} else {
			if(action.forIteration === false){
				if(action.both === true){
					yield loadProject({id: action.projectId, view: false});
				}
				yield loadProject({id: action.projectId, view: true});

			} else {
				yield loadIteration({id: action.iterationId});
				yield loadProject({id: action.projectId, view: false});
			}
			yield put(actions.createDeviceGroupSuccess(deviceGroup));
		}

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createDeviceGroupFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createDeviceGroupFail('Error Creating Group Device, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createDeviceGroupFail(error.message));
		}
	}
}

export function* deleteDeviceGroup(action) {
	yield put(actions.deleteDeviceGroupStart());
	try {
		let response;
		if(action.forIteration === false){
			response = yield generalAxios.delete(`/device-groups/${action.id}`);
			yield put(actions.deleteDeviceGroupSuccess(action.id));
		} else {
			response = yield generalAxios.delete(`/device-groups/${action.id}/iterations/${action.iterationId}`);
			yield put(actions.deleteDeviceGroupSuccess(action.id, response.data));
		}
		// console.log('saga deleteDeviceGroup', response.data);

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteDeviceGroupFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteDeviceGroupFail('Error Deleting The DeviceGroup, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteDeviceGroupFail(error.message));
		}
	}
}

export function* updateDeviceGroup(action) {
	yield put(actions.updateDeviceGroupStart());
	try {
		const response = yield generalAxios.put(`/device-groups/projects/${action.projectId}`, action.group);
		// console.log('update group', response.data);

		const deviceGroup = response.data;

		if(deviceGroup.compatible === false){
			yield put(actions.updateDeviceGroupIncompatible(deviceGroup));
		} else {
			yield loadProject({id: action.projectId, view: true});
			yield put(actions.updateDeviceGroupSuccess());
		}

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateDeviceGroupFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateDeviceGroupFail('Error Updating Group Device, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateDeviceGroupFail(error.message, action.view));
		}
	}
}

export function* loadTestFiles(action) {
	yield put(actions.loadTestFilesStart());
	try {
		const response = yield generalAxios.get(`/upload/projects/${action.id}`);
		console.log('loadTestFiles saga', response.data);

		yield put(actions.loadTestFilesSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadTestFilesFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadTestFilesFail('Error Loading The TestFiles, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadTestFilesFail(error.message));
		}
	}
}

export function* uploadTestFile(action) {
	yield put(actions.uploadTestFileStart());
	try {
		const formData = new FormData();

		formData.append("file", action.file, action.name);
		const response = yield uploadAxios.post(`/upload/projects/${action.id}`, formData, {headers: {"Content-Type": "multipart/form-data"}});
		console.log('uploadTestFile saga', response.data);

		yield put(actions.uploadTestFileSuccess());

		yield loadTestFiles({id: action.id});

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.uploadTestFileFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.uploadTestFileFail('Error Uploading The File, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.uploadTestFileFail(error.message));
		}
	}
}

export function* deleteTestFile(action) {
	yield put(actions.deleteTestFileStart());
	try {
		const response = yield generalAxios.delete(`/upload/${action.id}`);
		console.log('deleteTestFile saga', response.data);

		yield put(actions.deleteTestFileSuccess());

		yield loadTestFiles({id: action.projectId});

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteTestFileFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteTestFileFail('Error Deleting The File, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteTestFileFail(error.message));
		}
	}
}
