/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import ProjectSelector from "../../../../../tigmat/containers/Project/ProjectSelector";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import * as Role from "../../../../../tigmat/containers/Users/enum/Roles";

const HeaderMenu = props => {
	const layoutProps = props.layoutProps;
	const location = useLocation();
	const getMenuItemActive = (url) => {
		return checkIsActive(location, url) ? "menu-item-active" : "";
	}

	let administration = <ul className = {`menu-nav ${layoutProps.ulClasses} ml-6 mr-6`}>

		<li
			data-menu-toggle = {layoutProps.menuDesktopToggle}
			aria-haspopup = "true"
			className = {`menu-item menu-item-submenu menu-item-rel `}>
			<NavLink className = "menu-link menu-toggle" to = "">
                <span className = "menu-text">
                    <FormattedMessage id = "HEADER.TITLE"
                                      defaultMessage = 'Administration'/></span>
				<i className = "menu-arrow"></i>
			</NavLink>
			<div className = "menu-submenu menu-submenu-classic menu-submenu-left">
				<ul className = "menu-subnav">
					{(localStorage.getItem('Top-Role') === Role.ROLE_SUPER ||
						localStorage.getItem('Top-Role') === Role.ROLE_ADMIN ||
						localStorage.getItem('Top-Role') === Role.ROLE_MANAGER ) && <li className = {`menu-item ${getMenuItemActive('/user')}`}>
						<NavLink className = "menu-link" to = "/users">
                            <span className = "menu-text">
                                <FormattedMessage id = "HEADER.USERS"
                                          defaultMessage = 'Users'/></span>
						</NavLink>
					</li>}
					<li className = {`menu-item ${getMenuItemActive('/project')}`}>
						<NavLink className = "menu-link" to = "/projects">
							<span className = "menu-text">
                                <FormattedMessage id = "HEADER.PROJECTS"
                                                  defaultMessage = 'Projects'/></span>
						</NavLink>
					</li>
					{/*{payment}*/}
				</ul>
			</div>
		</li>
	</ul>;

	if(!props.project){
		administration = <ul className = {`menu-nav ${layoutProps.ulClasses} ml-6 mr-6`}>

			<li
				data-menu-toggle = {layoutProps.menuDesktopToggle}
				aria-haspopup = "true"
				className = {`menu-item menu-item-submenu menu-item-rel `}>
				<NavLink className = "menu-link menu-toggle" to = "">
                <span className = "menu-text">
                    <FormattedMessage id = "HEADER.TITLE"
                                      defaultMessage = 'Administration'/></span>
					<i className = "menu-arrow"></i>
				</NavLink>
				<div className = "menu-submenu menu-submenu-classic menu-submenu-left">
					<ul className = "menu-subnav">
						{(localStorage.getItem('Top-Role') === Role.ROLE_SUPER ||
							localStorage.getItem('Top-Role') === Role.ROLE_ADMIN ||
							localStorage.getItem('Top-Role') === Role.ROLE_MANAGER ) && <li className = {`menu-item ${getMenuItemActive('/user')}`}>
							<NavLink className = "menu-link" to = "/users">
                            <span className = "menu-text">
                                <FormattedMessage id = "HEADER.USERS"
                                                  defaultMessage = 'Users'/></span>
							</NavLink>
						</li>}
						<li className = {`menu-item ${getMenuItemActive('/project')}`}>
							<NavLink className = "menu-link" to = "/projects">
							<span className = "menu-text">
                                <FormattedMessage id = "HEADER.PROJECTS"
                                                  defaultMessage = 'Projects'/></span>
							</NavLink>
						</li>
					</ul>
				</div>
			</li>
		</ul>;
	}

	if (localStorage.getItem('Top-Role') !== Role.ROLE_SUPER &&
		localStorage.getItem('Top-Role') !== Role.ROLE_ADMIN &&
		localStorage.getItem('Top-Role') !== Role.ROLE_MANAGER &&
		localStorage.getItem('Top-Role') !== Role.ROLE_TESTER) {
		administration =  null;
	}


	let billing = <ul className={`menu-nav ${layoutProps.ulClasses}`}>
		<li className = {`menu-item ${getMenuItemActive('/billing')}`}>
			<NavLink className = "menu-link" to = "/billing">
				<span className = "menu-text">
					<FormattedMessage id = "HEADER.BILLING" defaultMessage = 'Billing'/>
				</span>
			</NavLink>
		</li>
	</ul>;

	if(localStorage.getItem('Top-Role') !== Role.ROLE_ADMIN){
		billing = null;
	}

	return <div
		id = "kt_header_menu"
		className = {`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
		{...layoutProps.headerMenuAttributes}
	>
		<ProjectSelector/>
		{/*begin::Header Nav*/}
		{administration}
		{/*end::Header Nav*/}


		{billing}

	</div>;
};

const mapStateToProps = state => {
	return {
		project: state.project.project
	};
};

const mapDispatchToProps = dispatch => {
	return {
		// onUpgrade: (returnToUrl) => dispatch(actions.createCustomerPortalSession(returnToUrl)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
