export const UINames = (name, intl) => {
	switch (name) {
		case 'name':
			return intl.formatMessage({ id: "TEST.LABEL.NAME",
				defaultMessage: 'Test Name' });
		case 'description':
			return intl.formatMessage({ id: "TEST.LABEL.Description",
				defaultMessage: 'Description' });
		default:
			return '';
	}
}