import {takeEvery} from "redux-saga/effects";

import * as actionTypes from '../actions/actionTypes';
import {
	activateAccount,
	authCheckState,
	changePassword,
	firePasswordChangeSuccessMessageTimeout,
	fireTimeout,
	forgotPassword,
	login,
	logout,
	register,
	resetPassword
} from "./authentication";
import {
	canCreateUser,
	changeLanguage,
	closeAccount,
	deleteCICDKeys,
	deleteUser,
	generateCICDKeys,
	loadAllUsers,
	loadCICDSecretKey,
	loadUser,
	loadUsers,
	registerAccountUser,
	registerAccountUserCICD,
	requestDeleteMyAccount,
	toggleActivation,
	updateMyAccount,
	updatePreferredProject,
	updateUser,
	updateUserCICD
} from "./users";
import {
	cancelSubscription,
	canCreateProject,
	checkAndroidEnvironmentStatus,
	createAndroidProject, createDeviceGroup,
	createProject, deleteDeviceGroup,
	deleteProject, deleteTestFile, loadDevices,
	loadProject,
	loadProjects,
	loadProjectViewPort, loadTestFiles,
	loadTimeZones,
	toggleExemptProject,
	updateAndroidProject, updateDeviceGroup,
	updateProject, uploadTestFile
} from "./project";
import {
	createDomain,
	deleteDomain,
	importTests,
	loadDomain,
	loadDomains,
	loadDomainsWithTestsOrRequests,
	prepareAndroidRecoringEnvironment,
	stopAndroidRecoringEnvironment,
	updateDomain
} from "./domain";
import {createTest, deleteTest, loadProjectTests, loadTest, moveTestToDomain, updateTest} from "./test";
import {createSuite, deleteSuite, loadProjectSuites, loadSuite, loadSuites, loadVariables, updateSuite} from "./suite";
import {createSprint, deleteSprint, loadSprint, loadSprints, updateSprint} from "./sprint";
import {canCreateIteration, createIteration, executeIteration, loadIteration, updateIteration} from "./iteration";
import {downloadScreenshot, loadReport} from "./report";
import {
	cancelPaidSubscription,
	changePaidSubscription,
	checkPayment,
	deletePaymentSessions,
	loadCurrentPlan,
	loadInvoices,
	loadPaidCustomerPlans,
	loadPlans
} from "./payment";
import {loadDashboardStats, loadSummaryChartData} from "./dashboardCaller";
import {
	createRestRequest,
	createRestRequestOfDomain,
	deleteRestRequest,
	deleteRestRequestOfDomain,
	loadProjectRestRequests,
	loadRestRequest,
	loadRestRequests,
	loadRestRequestsNames,
	updateRestRequest
} from "./restRequestCaller";
import {DELETE_DEVICE_GROUP, MOVE_TEST_TO_DOMAIN} from "../actions/actionTypes";

export function* watchAuthentication() {
	yield takeEvery(actionTypes.LOGIN, login);
	yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckState);
	yield takeEvery(actionTypes.LOGOUT, logout);
	yield takeEvery(actionTypes.REGISTER, register);
	yield takeEvery(actionTypes.TIMEOUT, fireTimeout);
	yield takeEvery(actionTypes.RESET_PASSWORD, resetPassword);
	yield takeEvery(actionTypes.CHANGE_PASSWORD, changePassword);
	yield takeEvery(actionTypes.CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT, firePasswordChangeSuccessMessageTimeout);
	yield takeEvery(actionTypes.ACTIVATE_ACCOUNT, activateAccount);
	yield takeEvery(actionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchUsers() {
	yield takeEvery(actionTypes.ON_LOAD_USERS, loadUsers);
	yield takeEvery(actionTypes.ON_LOAD_USER, loadUser);
	yield takeEvery(actionTypes.CHANGE_ACTIVATION_STATUS, toggleActivation);
	yield takeEvery(actionTypes.REGISTER_ACCOUNT_USER, registerAccountUser);
	yield takeEvery(actionTypes.REGISTER_ACCOUNT_USER_CICD, registerAccountUserCICD);
	yield takeEvery(actionTypes.UPDATE_USER, updateUser);
	yield takeEvery(actionTypes.DELETE_USER, deleteUser);
	yield takeEvery(actionTypes.UPDATE_USER_CICD, updateUserCICD);
	yield takeEvery(actionTypes.LOAD_ALL_USERS, loadAllUsers);
	yield takeEvery(actionTypes.UPDATE_USER_MY_ACCOUNT, updateMyAccount);
	yield takeEvery(actionTypes.UPDATE_PREFERRED_PROJECT, updatePreferredProject);
	yield takeEvery(actionTypes.CAN_CREATE_USER, canCreateUser);
	yield takeEvery(actionTypes.REQUEST_DELETE_MY_ACCOUNT, requestDeleteMyAccount);
	yield takeEvery(actionTypes.CLOSE_ACCOUNT, closeAccount);
	yield takeEvery(actionTypes.CHANGE_LANGUAGE, changeLanguage);
	yield takeEvery(actionTypes.GENERATE_CICD_KEYS, generateCICDKeys);
	yield takeEvery(actionTypes.LOAD_CICD_SECRET_KEY, loadCICDSecretKey);
	yield takeEvery(actionTypes.DELETE_CICD_KEYS, deleteCICDKeys);
}

export function* watchProject() {
	yield takeEvery(actionTypes.CREATE_PROJECT, createProject);
	yield takeEvery(actionTypes.LOAD_PROJECT, loadProject);
	yield takeEvery(actionTypes.LOAD_PROJECTS, loadProjects);
	yield takeEvery(actionTypes.DELETE_PROJECT, deleteProject);
	yield takeEvery(actionTypes.UPDATE_PROJECT, updateProject);
	yield takeEvery(actionTypes.TOGGLE_EXEMPT_PROJECT, toggleExemptProject);
	yield takeEvery(actionTypes.CREATE_PROJECT_ANDROID, createAndroidProject);
	yield takeEvery(actionTypes.UPDATE_PROJECT_ANDROID, updateAndroidProject);
	yield takeEvery(actionTypes.LOAD_PROJECT_VIEW_PORT, loadProjectViewPort);
	yield takeEvery(actionTypes.CANCEL_SUBSCRIPTION, cancelSubscription);
	yield takeEvery(actionTypes.CAN_CREATE_PROJECT, canCreateProject);
	yield takeEvery(actionTypes.LOAD_TIMEZONES, loadTimeZones);
	yield takeEvery(actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS, checkAndroidEnvironmentStatus);
	yield takeEvery(actionTypes.LOAD_DEVICES, loadDevices);
	yield takeEvery(actionTypes.CREATE_DEVICE_GROUP, createDeviceGroup);
	yield takeEvery(actionTypes.DELETE_DEVICE_GROUP, deleteDeviceGroup);
	yield takeEvery(actionTypes.UPDATE_DEVICE_GROUP, updateDeviceGroup);
	yield takeEvery(actionTypes.LOAD_TEST_FILES, loadTestFiles);
	yield takeEvery(actionTypes.UPLOAD_TEST_FILE, uploadTestFile);
	yield takeEvery(actionTypes.DELETE_TEST_FILE, deleteTestFile);
}

export function* watchDomain() {
	yield takeEvery(actionTypes.CREATE_DOMAIN, createDomain);
	yield takeEvery(actionTypes.LOAD_DOMAINS, loadDomains);
	yield takeEvery(actionTypes.DELETE_DOMAIN, deleteDomain);
	yield takeEvery(actionTypes.LOAD_DOMAIN, loadDomain);
	yield takeEvery(actionTypes.UPDATE_DOMAIN, updateDomain);
	yield takeEvery(actionTypes.IMPORT_TESTS, importTests);
	yield takeEvery(actionTypes.LOAD_DOMAINS_WITH_TESTS_OR_REQUESTS, loadDomainsWithTestsOrRequests);
	yield takeEvery(actionTypes.PREPARE_ANDROID_RECORDING_ENVIRONMENT, prepareAndroidRecoringEnvironment);
	yield takeEvery(actionTypes.STOP_ANDROID_RECORDING_ENVIRONMENT, stopAndroidRecoringEnvironment);
	// yield takeEvery(actionTypes.LOAD_CURRENT_ANDROID_RECORDING_ENVIRONMENT_STATE_OF_DOMAIN, loadCurrentAndroidRecoringEnvironmentStateOfDomain);
}

export function* watchTest() {
	yield takeEvery(actionTypes.CREATE_TEST, createTest);
	yield takeEvery(actionTypes.LOAD_TEST, loadTest);
	yield takeEvery(actionTypes.UPDATE_TEST, updateTest);
	yield takeEvery(actionTypes.DELETE_TEST, deleteTest);
	yield takeEvery(actionTypes.LOAD_PROJECT_TESTS, loadProjectTests);
	yield takeEvery(actionTypes.MOVE_TEST_TO_DOMAIN, moveTestToDomain);
}

export function* watchSuite() {
	yield takeEvery(actionTypes.LOAD_SUITES, loadSuites);
	yield takeEvery(actionTypes.DELETE_SUITE, deleteSuite);
	yield takeEvery(actionTypes.CREATE_SUITE, createSuite);
	yield takeEvery(actionTypes.LOAD_SUITE, loadSuite);
	yield takeEvery(actionTypes.UPDATE_SUITE, updateSuite);
	yield takeEvery(actionTypes.LOAD_PROJECT_SUITES, loadProjectSuites);
	yield takeEvery(actionTypes.LOAD_VARIABLES_FOR_SUITES, loadVariables);

}

export function* watchSprint() {
	yield takeEvery(actionTypes.LOAD_SPRINTS, loadSprints);
	yield takeEvery(actionTypes.CREATE_SPRINT, createSprint);
	yield takeEvery(actionTypes.LOAD_SPRINT, loadSprint);
	yield takeEvery(actionTypes.UPDATE_SPRINT, updateSprint);
	yield takeEvery(actionTypes.DELETE_SPRINT, deleteSprint);
}

export function* watchIteration() {
	yield takeEvery(actionTypes.CREATE_ITERATION, createIteration);
	yield takeEvery(actionTypes.LOAD_ITERATION, loadIteration);
	yield takeEvery(actionTypes.UPDATE_ITERATION, updateIteration);
	yield takeEvery(actionTypes.EXECUTE_ITERATION, executeIteration);
	yield takeEvery(actionTypes.CAN_CREATE_ITERATION, canCreateIteration);
}

export function* watchReport() {
	yield takeEvery(actionTypes.LOAD_REPORT, loadReport);
	yield takeEvery(actionTypes.DOWNLOAD_SCREENSHOT, downloadScreenshot);
}

export function* watchPayment() {
	// yield takeEvery(actionTypes.LOAD_SESSION_IDS, loadSessionIDs);
	// yield takeEvery(actionTypes.VERIFY_PAYMENT, verifyPayment);
	// yield takeEvery(actionTypes.CREATE_CUSTOMER_PORTAL_SESSION, createCustomerPortalSession);
	yield takeEvery(actionTypes.LOAD_CURRENT_PLAN, loadCurrentPlan);
	yield takeEvery(actionTypes.LOAD_PLANS, loadPlans);
	yield takeEvery(actionTypes.CHECK_PAYMENT, checkPayment);
	yield takeEvery(actionTypes.CANCEL_PAID_SUBSCRIPTION, cancelPaidSubscription);
	yield takeEvery(actionTypes.CHANGE_PAID_SUBSCRIPTION, changePaidSubscription);
	yield takeEvery(actionTypes.LOAD_PAID_CUSTOMER_PLANS, loadPaidCustomerPlans);
	yield takeEvery(actionTypes.DELETE_PAYMENT_SESSIONS, deletePaymentSessions);
	yield takeEvery(actionTypes.LOAD_INVOICES, loadInvoices);
}

export function* watchSummaryChart() {
	yield takeEvery(actionTypes.LOAD_SUMMARY_CHART_DATA, loadSummaryChartData);
	yield takeEvery(actionTypes.LOAD_DASHBOARD_STATISTIQUES, loadDashboardStats);
}

export function* watchRestRequest() {
	yield takeEvery(actionTypes.CREATE_REST_REQUEST, createRestRequest);
	yield takeEvery(actionTypes.LOAD_REST_REQUESTS, loadRestRequests);
	yield takeEvery(actionTypes.DELETE_REST_REQUEST, deleteRestRequest);
	yield takeEvery(actionTypes.LOAD_REST_REQUEST, loadRestRequest);
	yield takeEvery(actionTypes.UPDATE_REST_REQUEST, updateRestRequest);
	yield takeEvery(actionTypes.LOAD_REST_REQUESTS_NAMES, loadRestRequestsNames);
	yield takeEvery(actionTypes.CREATE_REST_REQUEST_OF_DOMAIN, createRestRequestOfDomain);
	yield takeEvery(actionTypes.LOAD_PROJECT_REST_REQUESTS, loadProjectRestRequests);
	yield takeEvery(actionTypes.DELETE_REST_REQUEST_OF_DOMAIN, deleteRestRequestOfDomain);
}