import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";

import CountriesList from "../../utility/CountriesList";
import * as Role from "./enum/Roles";
import * as actions from "../../store/actions";
import {getCurrentUserAllowedUserCreationAuthorities, getTopRole, getTopRoleObject} from "../../components/Users/Shared";
import {validateCICD, validateNormal, validateNormalAddress} from "./Validator";
import FormUserDisplay from "../../components/Users/FormUserDisplay";

const UpdateUser = props => {
	const intl = useIntl();

	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [authorities, setAuthorities] = useState({});
	const [rolesSelectionList, setRolesSelectionList] = useState([]);

	const [password, setPassword] = useState('');

	const [selectTab, setSelectTab] = useState(0);

	const [errorMessages, setErrorMessages] = useState([]);

	const [cicd, setCicd] = useState(false);

	const [includeAddress, setIncludeAddress] = useState(false);
	const [includeRoles, setIncludeRoles] = useState(false);

	// const [streetNumber, setStreetNumber] = useState('');
	const [route, setRoute] = useState('');
	const [locality, setLocality] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState(CountriesList[77]);

	useEffect(() => {
		const updatedGlobalMessages = [];
		if (props.error) { // refer to CreationError
			updatedGlobalMessages.push(props.error);
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.error]);

	const validateNormalUserFormRef = React.useRef();
	const registerValidateNormalUserFormCallback = callback => {
		validateNormalUserFormRef.current = callback;
	};

	const validateNormalUserAddressFormRef = React.useRef();
	const registerValidateNormalUserAddressFormCallback = callback => {
		validateNormalUserAddressFormRef.current = callback;
	};

	const validateCICDUserFormRef = React.useRef();
	const registerValidateCICDUserFormCallback = callback => {
		validateCICDUserFormRef.current = callback;
	};

	const [userIsManagerOrUpper, setUserIsManagerOrUpper] = useState(true);
	const [completeInformationMsg, setCompleteInformationMsg] = useState(null);

	useEffect(() => {
		props.onLoadUser(props.match.params.username);

		const filteredList = getCurrentUserAllowedUserCreationAuthorities();
		setRolesSelectionList(filteredList);
		if(localStorage.getItem('Top-Role') === Role.ROLE_TESTER ||
			localStorage.getItem('Top-Role') === Role.ROLE_USER ||
			localStorage.getItem('Top-Role') === Role.ROLE_CICD){
			setUserIsManagerOrUpper(false);
		} else {
			const config = {
				size: 100,
				page: 0,
				sortField: 'login',
				sortOrder: 'ASC'
			};
			props.onLoadAllUsers(localStorage.getItem("username"), config);
		}

		const incompleteProfile = new URLSearchParams(props.location.search).get('incompleteProfile');
		if(incompleteProfile === 'true'){
			setCompleteInformationMsg(intl.formatMessage({
				id: "REGISTRATION.COMPLETE.INFORMATION.FOR.BILLING",
				defaultMessage: 'All informations must be filled to upgrade from the Free Plan'}));
		}

	}, []);

	// loading values
	useEffect(() => {
		if (props.user) {
			document.title = intl.formatMessage({
				id: "PAGE.TITLE.UPDATE.USER",
				defaultMessage: 'Update User: '
			}) + props.user.login;
			if (props.user.authorities[0] === 'ROLE_CICD') {
				// CICD
				setCicd(true);
				setSelectTab(1);
				loadCICDUserValues();
			} else {
				// Normal
				const topRole = getTopRole(props.user);
				const isAdminParam = topRole === Role.ROLE_ADMIN;
				if(isAdminParam){
					setIsAdmin(true);
				}
				loadNormalUserValues(isAdminParam);
				setIncludeFlags();
			}
		}
	}, [props.user]);

	const [isAdmin, setIsAdmin] = useState(false);
	const setIncludeFlags = () => {
		const topRole = getTopRole(props.user);
		if (localStorage.getItem('Top-Role') === 'ROLE_SUPER' && topRole === 'ROLE_SUPER') {
			// address false, roles: false
		} else if (localStorage.getItem('Top-Role') === 'ROLE_SUPER' && topRole === 'ROLE_ADMIN') {
			setIncludeAddress(true);
			setIncludeRoles(false);
			if (props.user.addressDTO) {
				loadAddress(props.user.addressDTO);
			}
		} else if (localStorage.getItem('Top-Role') === 'ROLE_ADMIN' && topRole === 'ROLE_ADMIN') {
			setIncludeAddress(true);
			setIncludeRoles(false);
			if (props.user.addressDTO) {
				loadAddress(props.user.addressDTO);
			}
		} else {
			// default include roles, do not include address
			setIncludeAddress(false);
			setIncludeRoles(true);
			// Set User Role
			setUserRole();
		}
		if(!userIsManagerOrUpper){
			setIncludeRoles(false);
		}
		// if acting on my account, do not include roles
		if(props.loggedInUser.id === props.user.id){
			setIncludeRoles(false);
		}
	}

	const setUserRole = () => {
		// console.log('USER ROLE', user);
		const userRole = getTopRoleObject(props.user);
		for (let role of rolesSelectionList) {
			if (userRole.value === role.value) {
				setAuthorities(role);
				break;
			}
		}
	}

	const loadAddress = (address) => {
		// setStreetNumber(address.streetNumber ? address.streetNumber.toString() : '');
		setRoute(address.route ? address.route : '');
		setLocality(address.locality ? address.locality : '');
		setPostalCode(address.postalCode ? address.postalCode : '');
		setState(address.state ? address.state : '');

		for (let countryItem of CountriesList) {
			if (address.countryCode === countryItem.alpha2Code) {
				// console.log('Setting country to:', countryItem);
				setCountry(countryItem);
				break;
			}
		}
	}

	const loadNormalUserValues = (isAdminParam = false) => {
		setUsername(props.user.login);
		setFirstName(props.user.firstName ? props.user.firstName : firstName);
		setLastName(props.user.lastName ? props.user.lastName : lastName);
		setEmail(props.user.email);
		if(isAdminParam){
			setCompany(props.user.companyName ? props.user.companyName : company);
		}
	}

	const loadCICDUserValues = () => {
		setUsername(props.user.login);
	}

	const buildAuthoritiesArray = topRoleWholeObject => {
		const authoritiesArray = []
		// noinspection FallThroughInSwitchStatementJS
		switch (topRoleWholeObject.value) {
			case Role.ROLE_ADMIN:
				authoritiesArray.push(Role.ROLE_ADMIN);
			case Role.ROLE_MANAGER:
				authoritiesArray.push(Role.ROLE_MANAGER);
			case Role.ROLE_TESTER:
				authoritiesArray.push(Role.ROLE_TESTER);
			case Role.ROLE_USER:
				authoritiesArray.push(Role.ROLE_USER);
		}
		return authoritiesArray;
	}

	const updateNormalUser = (event) => {
		event.preventDefault();

		// validate all
		if (!validateNormal(validateNormalUserFormRef)) {
			return null;
		}

		// address
		if (!validateNormalAddress(validateNormalUserAddressFormRef)) {
			return null;
		}

		const user = {...props.user};
		cleanUserRedunantFields(user);
		// user.id = props.user.id;
		// user.login = props.user.login;
		// user.activated = props.user.activated;
		user.firstName = firstName;
		user.lastName = lastName;
		user.email = email;
		if(isAdmin){
			user.companyName = company;
		}
		user.authorities = buildAuthoritiesArray(authorities);

		if (includeAddress) {
			user.addressDTO = {
				// streetNumber: +streetNumber,
				route: route,
				locality: locality,
				postalCode: postalCode,
				state: state,
				countryCode: country.alpha2Code,
				countryName: country.name,
			}
		}

		if(props.loggedInUser.id === props.user.id){
			// MY ACCOUNT
			delete user.authorities; // always delete authorities

			switch (getTopRole(props.loggedInUser)) {
				case Role.ROLE_MANAGER:
				case Role.ROLE_TESTER:
				case Role.ROLE_USER:
					delete user.addressDTO;
					break;
				default: break;
			}
			props.onUpdateMyAccount(user, new URLSearchParams(props.location.search).get('incompleteProfile') === 'true' ? '/billing' : null);
		} else {
			// USER ACTING ON OTHER USER
			// switch (getTopRole(props.loggedInUser)) {
			// 	case Role.ROLE_ADMIN:
			// 		// info + roles
			// 		break;
			// 	case Role.ROLE_MANAGER:
			// 		// info + roles
			// 		break;
			//
			// }
			props.onUpdateUser(user);
		}
	}

	const cleanUserRedunantFields = (user) => {
		delete user.activated;
		delete user.langKey;
		delete user.createdBy;
		delete user.createdDate;
		delete user.lastModifiedBy;
		delete user.lastModifiedDate;
		delete user.preferedProjectId;
		delete user.preferedProjectLabel;
		delete user.preferedProjectType;
		delete user.preferedProjectSubscribed;
		delete user.users;
	}

	const updateCICDUser = (event) => {
		event.preventDefault();

		// validate all
		if (!validateCICD(validateCICDUserFormRef)) {
			return null;
		}

		const user = {
			id: props.user.id,
			login: props.user.login,
			password: password,
		}
		props.onUpdateUserCICD(user);
	}

	const deleteMyAccount = () => {
		props.onRequestDeleteMyAccount(props.loggedInUser.id);
		const scrollToTopBtn = document.getElementById("kt_scrolltop");
		if (scrollToTopBtn) {
			scrollToTopBtn.click();
		}
	}

	const [successMessage, setsuccessMessage] = useState(null);
	useEffect(() => {
		if(props.requestClosingAccountSuccess === true){
			setsuccessMessage(intl.formatMessage({
				id: "ACCOUNT.TERMINATION.REQUEST.SENT.SUCCESSFULLY",
				defaultMessage: 'Request to close account was sent successfully. we\'ve sent you an email to confirm your request.'
			}));
			setTimeout(()=>{
				props.onLogout('closeAccountRequested=true');
			}, 2000);
		} else if(props.requestClosingAccountSuccess === false){
			setErrorMessages([props.requestClosingAccountError]);
		}
	}, [props.requestClosingAccountSuccess]);

	let forbid = false;
	if (props.loggedInUser && props.user && props.users) {
		if (props.loggedInUser.id === props.user.id) {
			forbid = false;
		} else if (props.users.findIndex(user => user.id === props.user.id) >= 0) {
			forbid = false;
		} else {
			forbid = true;
		}
	}

	if (forbid) {
		return <h2><FormattedMessage id="ACCESS.DENIED"
									 defaultMessage='Access Denied'/></h2>
	} else {
		const myAccount = props.loggedInUser && props.user && props.loggedInUser.id === props.user.id;
		const userID = props.user ? props.user.id : null;
		return <FormUserDisplay selectTab={selectTab}
								setSelectTab={setSelectTab}
								type='update'
								loggedInUser={props.loggedInUser}
								isAdmin={isAdmin}
								errorMessages={errorMessages}

								registerValidateNormalUserFormCallback={registerValidateNormalUserFormCallback}
								username={username} setUsername={setUsername}
								firstName={firstName} setFirstName={setFirstName}
								lastName={lastName} setLastName={setLastName}
								email={email} setEmail={setEmail}
								company={company} setCompany={setCompany}
								authorities={authorities} setAuthorities={setAuthorities}
								updateNormalUser={updateNormalUser}

								rolesSelectionList={rolesSelectionList}

								password={password} setPassword={setPassword}
								registerValidateCICDUserFormCallback={registerValidateCICDUserFormCallback}
								updateCICDUser={updateCICDUser}

								cicd={cicd}

								includeAddress={includeAddress} setIncludeAddress={setIncludeAddress}
								includeRoles={includeRoles}

								// streetNumber={streetNumber} setStreetNumber={setStreetNumber}
								route={route} setRoute={setRoute}
								locality={locality} setLocality={setLocality}
								postalCode={postalCode} setPostalCode={setPostalCode}
								state={state} setState={setState}
								country={country} setCountry={setCountry}
								registerValidateNormalUserAddressFormCallback={registerValidateNormalUserAddressFormCallback}
								loading={props.loading}

								completeInformationMsg={completeInformationMsg}
								myAccount={myAccount}
								userID={userID}
								deleteMyAccount={deleteMyAccount}
								successMessage={successMessage}
		/>;
	}
}

const mapStateToProps = state => {
	return {
		users: state.users.users, // need only for refreshing user display.
		user: state.users.user,
		loading: state.users.updatingLoading,
		error: state.users.updatingError,
		userRegistered: state.users.userRegistered,
		loggedInUser: state.authentication.loggedInUser,

		requestClosingAccountSuccess: state.users.requestClosingAccountSuccess,
		requestClosingAccountLoading: state.users.requestClosingAccountLoading,
		requestClosingAccountError: state.users.requestClosingAccountError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadUser: (username) => dispatch(actions.loadUser(username)),
		onUpdateUser: (user) => dispatch(actions.updateUser(user)), // toggle = update. TODO rename
		onLoadAllUsers: (username, config) => dispatch(actions.loadUsers(username, config)),
		onUpdateUserCICD: (user) => dispatch(actions.updateUserCICD(user)),
		onUpdateMyAccount: (user, redirect) => dispatch(actions.updateMyAccount(user, redirect)),
		onRequestDeleteMyAccount: (userID) => dispatch(actions.requestDeleteMyAccount(userID)),
		onLogout: (qureyParams) => dispatch(actions.logout(qureyParams))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
