import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert, AlertTitle} from "@material-ui/lab";

import {changeText} from "./Shared";
import {checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import SprintAddIterationForm from "../../components/Sprint/SprintAddIterationForm";
import * as actions from "../../store/actions";

const AddIteratioToSprintForm = props => {
	const intl = useIntl();

	useEffect(() => {
		props.onCanCreateIteration();
	}, []);

	// TODO input + select
	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [allSuite, setAllSuite] = useState(false);

	const [allNames, setAllNames] = useState([]);

	useEffect(()=>{
		const names = [];
		if(props.sprint && props.sprint.iterations){
			props.sprint.iterations.forEach((it) => {
				names.push(it.label);
			})
		}
		setAllNames(names);
	}, [props.sprint]);


	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'iteration', intl);
		if(updatedName.valid){
			// check if name already exists
			let nameExists = false;
			// console.log('allNames', allNames);
			allNames.forEach((name) => {
				if(name === updatedName.value){
					nameExists = true;
					updatedName.valid = false;
					updatedName.validationMessage = intl.formatMessage(
						{ id: "SPRINT.ITERATION.FORM.VALIDATION.NAME.ALREADY.EXISTS",
						defaultMessage: 'Iteration name already exists' });
				}
			});
		}
		setName(updatedName);
	}

	const toggleAllSuiteHandler = (event, checked) => {
		setAllSuite(checked);
	};

	const checkValidityForAll = () => {
		let allValid = true;

		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('iteration', intl), intl);

		// check if name already exists
		let nameExists = false;
		allNames.forEach((name) => {
			if(name === updatedName.value){
				nameExists = true;
				valid = false;
				message = intl.formatMessage(
					{ id: "SPRINT.ITERATION.FORM.VALIDATION.NAME.ALREADY.EXISTS",
						defaultMessage: 'Iteration name already exists' });
			}
		});

		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		return allValid;
	}

	const submitAddIterationHandler = () => {
		// validate all
		if (!checkValidityForAll()) {
			return false;
		}
		let newIteration = {
			browser: props.project.browser,
			includeAllSuites: allSuite,
			label: name.value,
			sprintId: props.sprint.id
		};
		// update ui: don't because id is undefined
		// call saga
		// const existingIterations = [...props.iterations];
		// existingIterations.push(newIteration);
		// props.setIterations(existingIterations);
		props.createAndAddIterationToSprint(props.sprint.id, newIteration);
		clear();
		return true;
	}

	const clear = () => {
		const updatedName = {...name};
		updatedName.value = '';
		setName(updatedName);
		setAllSuite(false);
	}

	if(props.canAddLoading === true){
		return null;
	}

	if(props.canAddLoading === false && props.canAdd === false){
		return <Alert elevation={2} variant="standard" severity="warning" className='text-left'>
			<AlertTitle>
				<h6>
					<FormattedMessage id="ITERATION.LIMIT.REACHED.1"
									  defaultMessage="You've Reached the maxixmum amount of Iterations you can create."/>
				</h6>
			</AlertTitle>
			<h6>
				<FormattedMessage id="ITERATION.LIMIT.REACHED.2"
								  defaultMessage="Click 'Upgrade To Premium' on the top of the page to lift the restrictions."/>
			</h6>
			{props.canAddError}
		</Alert>;
	}

	return <SprintAddIterationForm name = {name}
	                               nameChangeHandler = {nameChangeHandler}
	                               allSuite = {allSuite}
	                               toggleAllSuiteHandler = {toggleAllSuiteHandler}
	                               submitAddIterationHandler = {submitAddIterationHandler}
	/>
}

const mapStateToProps = state => {
	return {
		// projectTests: state.test.projectTests,
		project: state.project.project,
		canAdd: state.iteration.canAdd,
		canAddLoading: state.iteration.canAddLoading,
		canAddError: state.iteration.canAddError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		// onProjectTests: (id) => dispatch(actions.loadProjectTests(id)),
		onCanCreateIteration: () => dispatch(actions.canCreateIteration())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIteratioToSprintForm);