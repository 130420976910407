import React from "react";
import {AppBar, Button, Dialog, Slide, Toolbar, Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Tree} from "primereact/tree";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const SuiteAddTestsForm = props => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);

	};

	const handleClose = () => {
		setOpen(false);
		// props.clear();
	};

	const save = () => {
		if(props.submitAddTestsHandler()){
			handleClose();
		}
	}

	return (
		<>
			<Button variant = "contained"
			        size = 'small'
			        startIcon = {<AddIcon/>}
			        onClick = {handleClickOpen}
			        className = ''>
				<FormattedMessage id = "SUITE.TESTS.FORM.BUTTON.ADD" defaultMessage = 'Tests'/>
			</Button>
			<Dialog fullScreen open = {open} onClose = {handleClose} TransitionComponent = {Transition}>
				<AppBar className = {classes.appBar}>
					<Toolbar>
						<IconButton edge = "start" color = "inherit" onClick = {handleClose} aria-label = "close">
							<CloseIcon/>
						</IconButton>
						<Typography variant = "h6" className = {classes.title}>
							<FormattedMessage id = "SUITE.TESTS.FORM.TITLE.ADD" defaultMessage = 'Tests'/>
						</Typography>
						<Button autoFocus color = "inherit" onClick = {save}>
							<FormattedMessage id="BUTTON.SAVE" defaultMessage='Save'/>
						</Button>
					</Toolbar>
				</AppBar>
				<div className = "bg-white mt-4">
					<Container>
						<Row>
							<Col>
								<Tree value={props.addTests.tests} selectionMode="checkbox" selectionKeys={props.addTests.selected}
									  className='font-size-h3'
									  onSelectionChange={(event) => props.addTestsSelectHandler(event.value)} />
							</Col>
						</Row>
					</Container>
				</div>
			</Dialog>
		</>
	);
}

export default SuiteAddTestsForm;