import React from "react";
import {Box} from "@material-ui/core";

const TabPanel = props => {
	const { children, value, index, py = 3, px = 0, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box py={py} px={px}>
					{children}
				</Box>
			)}
		</div>
	);
};

export default TabPanel;