import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../../axios/axios";

export function* loadReport(action) {
	yield put(actions.loadReportStart());
	try {
		const response = yield axios.get(`/reporting/iteration/${action.id}`);
		yield put(actions.loadReportSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadReportFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadReportFail('Error Loading Report, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadReportFail(error.message));
		}
	}
}

export function* downloadScreenshot(action) {
	yield put(actions.downloadScreenshotStart());
	try {
		const response = yield axios.get(`/reporting/images/${action.imagekey}`,
			{ responseType: 'blob' });
		// console.log('Saga Screenshot response', response);
		yield put(actions.downloadScreenshotSuccess(response.data, action.imagekey));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.downloadScreenshotFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.downloadScreenshotFail('Error Downloading ScreenShot, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.downloadScreenshotFail(error.message));
		}
	}
}
