import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import {FormattedMessage, useIntl} from "react-intl";

import {useHtmlClassService} from "../../../_metronic/layout";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

function getChartOption(layoutProps, testsAddition, intl) {

	// const monthsNumbers = [1,2,3,4,5,6];
	const months = [];
	for(let month of testsAddition.months){
		months.push(Intl.DateTimeFormat(navigator.language, { month: 'long' }).format(new Date(0, month -1)));
	}
	// const data = [40, 30, 35, 35, 50, 60];
	let maxValue = Math.max(...testsAddition.tests);
	// maxValue = maxValue > 10 ? maxValue + (maxValue / 10) : maxValue + 1;
	maxValue = maxValue + (maxValue / 10);
	// console.log('maxValue', maxValue);

	var options = {
		series: [{
			name: "", data: testsAddition.tests
		}], chart: {
			type: "area", height: 150, toolbar: {
				show: false
			}, zoom: {
				enabled: false
			}, sparkline: {
				enabled: true
			}
		}, plotOptions: {}, legend: {
			show: false
		}, dataLabels: {
			enabled: false
		}, fill: {
			type: "solid", opacity: 1
		}, stroke: {
			curve: "smooth", show: true, width: 3, colors: [layoutProps.colorsThemeBasePrimary]
		}, xaxis: {
			categories: months, axisBorder: {
				show: false
			}, axisTicks: {
				show: false
			}, labels: {
				show: false, style: {
					colors: layoutProps.colorsGrayGray500, fontSize: "12px", fontFamily: layoutProps.fontFamily
				}
			}, crosshairs: {
				show: false, position: "front", stroke: {
					color: layoutProps.colorsGrayGray300, width: 1, dashArray: 3
				}
			}, tooltip: {
				enabled: true, formatter: undefined, offsetY: 0, style: {
					fontSize: "12px", fontFamily: layoutProps.fontFamily
				}
			}
		}, yaxis: {
			// min: 0,
			max: maxValue,
			labels: {
				show: false, style: {
					colors: layoutProps.colorsGrayGray500, fontSize: "12px", fontFamily: layoutProps.fontFamily
				}
			}
		}, states: {
			normal: {
				filter: {
					type: "none", value: 0
				}
			}, hover: {
				filter: {
					type: "none", value: 0
				}
			}, active: {
				allowMultipleDataPointsSelection: false, filter: {
					type: "none", value: 0
				}
			}
		}, tooltip: {
			style: {
				fontSize: "12px", fontFamily: layoutProps.fontFamily
			}, y: {
				formatter: function (val) {
					return val + intl.formatMessage({id: "DASHBOARD.TESTS.ADDITION.FRAGEMENT.TEXT", defaultMessage: ' added'});
				}
			}
		}, colors: [layoutProps.colorsThemeLightPrimary], markers: {
			colors: [layoutProps.colorsThemeLightPrimary], strokeColor: [layoutProps.colorsThemeBasePrimary], strokeWidth: 3
		}
	};
	return options;
}


const TestsAdditionWidget = props => {
	const intl = useIntl();

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
			colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
			colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
			colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
			colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily")
		};
	}, [uiService]);

	useEffect(() => {
		const element = document.getElementById("tests_addition_chart");

		if (!element) {
			return;
		}

		const options = getChartOption(layoutProps, props.testsAddition, intl);
		const chartTestsAddiction = new ApexCharts(element, options);
		chartTestsAddiction.render();
		return function cleanUp() {
			chartTestsAddiction.destroy();
		};
	}, [layoutProps]);

	// const totalTests = 150;

	return (<div className={`card card-custom ${props.className}`}>
		<div className="card-body d-flex flex-column p-0">
			<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
				<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
					<span className="symbol-label">
						<span className="svg-icon svg-icon-xl svg-icon-primary">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Pixels.svg")}/>
						</span>
					</span>
				</span>
				<div className="d-flex flex-column text-right">
					<span className="text-dark-75 font-weight-bolder font-size-h3">
						{props.testsAddition.totalTests}
					</span>
					<span className="text-muted font-weight-bold mt-2">
						<FormattedMessage id="DASHBOARD.TESTS.ADDITION.HEADER" defaultMessage='New Tests'/>
					</span>
				</div>
			</div>
			<div id="tests_addition_chart" className="card-rounded-bottom" style={{height: "150px"}}/>
		</div>
	</div>);
}

export default TestsAdditionWidget;