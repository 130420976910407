import React, {useState} from "react";
import {AppBar, Divider, Tab, Tabs} from "@material-ui/core";
import {Dialog} from "primereact/dialog";
import {Alert} from "@material-ui/lab";
import {useIntl} from "react-intl";

import InstructionActions from "../Instruction/InstructionActions";
import InstructionsDisplay from "../Instruction/InstructionsDisplay";
import TestAssertDisplay from "./TestAssertDisplay";
import UpdateTest from "../../containers/Test/UpdateTest";
import TabPanel from "../../UI/TabPanel";
import FilesForTest from "../../containers/Test/FilesForTest";

const TestDisplayTabs = props => {
	const intl = useIntl();
	const [tab, setTab] = useState(props.edit ? 1 : 2);
	const [showInstructionsTableMaximized, setShowInstructionsTableMaximized] = useState(false);
	const [showAssertsTableMaximized, setShowAssertsTableMaximized] = useState(false);

	function tabChangeHandler(event, newValue) {
		setTab(newValue);
	}

	let instructionActions = <InstructionActions test = {props.test}
	                                             testID = {props.test.id}
	                                             instructions = {props.instructions}
	                                             setInstructions = {props.setInstructions}
	                                             updateInstructions = {props.updateInstructions}
	                                             asserts = {props.asserts}
	                                             setAsserts = {props.setAsserts}
	                                             updateAsserts = {props.updateAsserts}
	                                             updateBulk = {props.updateBulk}
	                                             domain = {props.domain}
												 setShowInstructionsTableMaximized={setShowInstructionsTableMaximized}
												 setShowAssertsTableMaximized={setShowAssertsTableMaximized}
	/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		instructionActions = null;

	}

	const instructionsDisplay = <InstructionsDisplay instructions = {props.instructions}
													 setInstructions = {props.setInstructions}
													 testName = {props.test.label}
													 updateInstructions = {props.updateInstructions}
													 loading = {props.loading}
	/>;
	const assertsDisplay = <TestAssertDisplay asserts = {props.asserts}
											  setAsserts = {props.setAsserts}
											  updateAsserts = {props.updateAsserts}
											  loading = {props.loading}/>;
	let fullscreenIntructionsTableModal = <Dialog
		header={intl.formatMessage({id: "TEST.INSTRUCTIONS.FULLSCREEN.TITLE", defaultMessage: 'Test Steps'})}
		visible={showInstructionsTableMaximized} maximized={true} modal={true}
		onHide={() => setShowInstructionsTableMaximized(false)}
	>
		{instructionsDisplay}
	</Dialog>;

	let fullscreenAssertsTableModal = <Dialog
		header={intl.formatMessage({id: "TEST.ASSERTS.FULLSCREEN.TITLE", defaultMessage: 'Asserts Results'})}
		visible={showAssertsTableMaximized}
		maximized={true} modal={true}
		onHide={() => setShowAssertsTableMaximized(false)}
	>
		{assertsDisplay}
	</Dialog>;

	let errorAlert = null;
	if(props.error){
		errorAlert = <Alert elevation={2} variant="standard" severity="error" className='my-3' onClose={props.nullifyUpdatingTestError}>
			{props.error}
		</Alert>;
	}

	return (
		<>
			<AppBar position = "static" color = 'transparent' elevation = {0}>
				<Tabs value = {tab} onChange = {tabChangeHandler}>
					<Tab label = {intl.formatMessage({
						id: "TEST.TAB.TITLE.INFORMATIONS",
						defaultMessage: 'Informations'
					})}
						// icon = {
						//     <SVG
						//      src = {toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}
						//     />
						// }
					/>
					<Tab
						// icon = {
						// 	 <SVG
						// 		 src = {toAbsoluteUrl("/media/svg/icons/Layout/Layout-arrange.svg")}
						// 	 />
						//  }
						label = {intl.formatMessage({
							id: "TEST.TAB.TITLE.TEST.STEPS",
							defaultMessage: 'Test Steps'
						})}/>
					<Tab label = {intl.formatMessage({id: "TEST.TAB.TITLE.TEST.STEPS??", defaultMessage: 'Files'})}/>
				</Tabs>
			</AppBar>
			<TabPanel value = {tab} index = {0} py = {0}>
				<UpdateTest {...props}/>
			</TabPanel>
			<TabPanel value = {tab} index = {1} py = {0}>
				{fullscreenIntructionsTableModal}
				{fullscreenAssertsTableModal}
				{instructionActions}
				<Divider/>
				{errorAlert}
				{instructionsDisplay}
				{assertsDisplay}
			</TabPanel>
			<TabPanel value = {tab} index = {2} py = {0}>
				<FilesForTest {...props}/>
			</TabPanel>
		</>
	)
}

export default TestDisplayTabs;