/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import store, {persistor} from "./redux/store";
// import store, {persistor} from "./dev_road/store/store";
import App from "./app/App";
// import App from "./dev_road/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Docs
// import "./tigmat/docs/style/style.module.css";
// import "./tigmat/docs/plugins/tiny-slider/dist/tiny-slider.css?v=7.0.8";
// import "./tigmat/docs/plugins/highlight.js/styles/railscasts.css";
// import "./tigmat/docs/plugins/jquery/dist/jquery.min.js?v=7.0.8";
// import "./tigmat/docs/plugins/tether/dist/js/tether.min.js?v=7.0.8";
// import "./tigmat/docs/plugins/popper.js/dist/umd/popper.min.js?v=7.0.8";
// import "./tigmat/docs/plugins/bootstrap/dist/js/bootstrap.min.js?v=7.0.8";
// import "./tigmat/docs/plugins/perfect-scrollbar/dist/perfect-scrollbar.js?v=7.0.8";
// import "./tigmat/docs/plugins/tiny-slider/dist/tiny-slider.js?v=7.0.8";
// import "./tigmat/docs/js/custom.js?v=7.0.8";
// import "./tigmat/docs/plugins/highlight.js/lib/highlight.js";
// import "./tigmat/docs/plugins/highlight.js/build/highlight.pack.js";
// import "./tigmat/docs/js/docs.js";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
// PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import "video-react/styles/scss/video-react.scss";

// Custom CSS
// Font
import "./tigmat/scss/font/fira_code.css";

import "./app/spinner-css/spin.css";
// import '@yaireo/ui-switch/src/switch.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const PUBLIC_URL = "";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */
// _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store);



ReactDOM.render(
	<MetronicI18nProvider>
		<MetronicLayoutProvider>
			<MetronicSubheaderProvider>
				<MetronicSplashScreenProvider>
					<App store = {store} persistor = {persistor} basename = {PUBLIC_URL}/>
				</MetronicSplashScreenProvider>
			</MetronicSubheaderProvider>
		</MetronicLayoutProvider>
	</MetronicI18nProvider>,
	document.getElementById("root")
);

