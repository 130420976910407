import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";

import {changeText, selectElement} from "./Shared";
import * as Role from "../../containers/Users/enum/Roles";
import NormalUserFormJSX from "./NormalUserFormJSX";
import RegistrationFormJSX from "../Authentication/RegistrationFormJSX";

const GetNormalUserHeaderTitle = (props, intl) => {
	let normalUserHeaderTitle = props.type === 'create' ?
		intl.formatMessage({id: "NEW.USER.TAB.TITLE.CREATE.NEW", defaultMessage: 'Create a new'}) :
		intl.formatMessage({id: "NEW.USER.TAB.TITLE.UPDATE.EXISTING", defaultMessage: 'Update existing'});
	normalUserHeaderTitle = normalUserHeaderTitle +
		intl.formatMessage({id: "NEW.USER.TAB.TITLE.USER", defaultMessage: ' User'});
	return normalUserHeaderTitle;
};

const NormalUserForm = props => {
	const intl = useIntl();

	// get now
	const [username, setUsername] = useState({
		value: props.username,
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255,
			prohibitedValues: ['new'],
			noSpace: true
		},
		valid: true,
		validationMessage: null
	});

	const [company, setCompany] = useState({
		value: props.company !== null ? props.company : '',
		validation: {
			required: true,
			minLength: 2,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [firstName, setFirstName] = useState({
		value: props.firstName,
		validation: {
			required: props.completeInformationMsg ? true : false,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	useEffect(() => {
		let updatedElement = {...firstName};
		let validation = {...updatedElement.validation};
		validation.required = props.completeInformationMsg ? true : false;
		updatedElement.validation = validation;
		setFirstName(updatedElement);

		updatedElement = {...lastName};
		validation = {...updatedElement.validation};
		validation.required = props.completeInformationMsg ? true : false;
		updatedElement.validation = validation;
		setLastName(updatedElement);

	}, [props.completeInformationMsg])

	const [lastName, setLastName] = useState({
		value: props.lastName,
		validation: {
			required: props.completeInformationMsg ? true : false,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [email, setEmail] = useState({
		value: props.email,
		validation: {
			required: true,
			maxLength: 320,
			isEmail: true
		},
		valid: true,
		validationMessage: null
	});

	const [authorities, setAuthorities] = useState({
			roles: props.authorities,
			validation: {
				required: true
			},
			valid: true,
			validationMessage: null,
			authorities: props.rolesSelectionList
		}
	);

	// For Registration
	const [password, setPassword] = useState({
		value: '',
		validation: {
			// required: true,
			// minLength: 8,
			// maxLength: 255
		},
		valid: false,
		validationMessage: null
	});

	const [confirmPassword, setConfirmPassword] = useState({
		value: '',
		validation: {
			// required: true,
			// minLength: 8,
			// maxLength: 255
		},
		valid: false
	});

	const [isValidPassword, setIsValidPassword] = useState(false);

	// Instead of making a useEffect hook for each field. practically if username is updated
	// in the parent and different from local then update all.
	// As for Address, if includeAddress is true
	useEffect(() => {
		if (username.value !== props.username) {
			let updatedElement = {...username};
			updatedElement.value = props.username;
			setUsername(updatedElement);

			// if(props.type === null || props.type !== 'create'){
			// registration || new hide, update admin show
			if(props.loggedInUser === null || (props.type === 'update' && props.isAdmin === true )){
				updatedElement = {...company};
				updatedElement.value = props.company;
				setCompany(updatedElement);
			}

			if (props.loggedInUser !== null) {
				updatedElement = {...firstName};
				updatedElement.value = props.firstName;
				setFirstName(updatedElement);

				updatedElement = {...lastName};
				updatedElement.value = props.lastName;
				setLastName(updatedElement);
			}

			updatedElement = {...email};
			updatedElement.value = props.email;
			setEmail(updatedElement);

		}
	}, [props.username]);

	useEffect(() => {
		const updatedElement = {...authorities};
		updatedElement.roles = props.authorities;
		updatedElement.authorities = props.rolesSelectionList;
		setAuthorities(updatedElement);
	}, [props.authorities, props.rolesSelectionList]);

	const usernameChangeHandler = (event) => {
		const updatedUsername = changeText(username, event.target.value, 'username', intl);
		setUsername(updatedUsername);
	}

	const companyChangeHandler = (event) => {
		const updatedFirstName = changeText(company, event.target.value, 'company', intl);
		setCompany(updatedFirstName);
	}

	const firstNameChangeHandler = (event) => {
		const updatedFirstName = changeText(firstName, event.target.value, 'firstName', intl);
		setFirstName(updatedFirstName);
	}
	const lastNameChangeHandler = (event) => {
		const updatedLastName = changeText(lastName, event.target.value, 'lastName', intl);
		setLastName(updatedLastName);
	}

	const emailChangeHandler = (event) => {
		const updatedEmail = changeText(email, event.target.value, 'email', intl);
		setEmail(updatedEmail);
	}
	const authoritiesSelectHandler = (event) => {
		const updatedAuthorities = selectElement(authorities, event.target.value,
			'authorities', 'roles', intl);
		setAuthorities(updatedAuthorities);
		// call same as blur
		syncValueWithParentContainer('authorities', event.target.value);

		// the following section is for Super Role. Super can create an admin
		if (localStorage.getItem('Top-Role') === 'ROLE_SUPER') {
			// admin includeAddress, else false
			if (event.target.value.value === Role.ROLE_ADMIN) {
				props.setIncludeAddress(true);
			} else {
				props.setIncludeAddress(false);
			}
		}
	};

	// Registration
	const passwordChangeHandler = (event) => {
		const updatedPassword = changeText(password, event.target.value, 'password', intl);

		if (updatedPassword.valid === true && confirmPassword.value !== ''
			&& updatedPassword.value !== confirmPassword.value) {

			updatedPassword.valid = false;
			updatedPassword.validationMessage = intl.formatMessage({
				id: 'CHANGE.PASSWORD.VALIDATION.NO.PASSWORD.CONFIRM.MATCH',
				defaultMessage: "Password and Confirmation don't match"
			});
		}
		updatedPassword.valid = isValidPassword;
		updatedPassword.validationMessage = null;
		setPassword(updatedPassword);

		const updatedConfirmPassword = {...confirmPassword};
		updatedConfirmPassword.valid = isValidPassword;
		updatedConfirmPassword.validationMessage = null;
		setConfirmPassword(updatedConfirmPassword);
	}

	const confirmPasswordChangeHandler = (event) => {
		const updatedConfirmPassword = changeText(confirmPassword, event.target.value, 'confirmPassword', intl);

		// Matching
		if (updatedConfirmPassword.valid === true && password.value !== ''
			&& updatedConfirmPassword.value !== password.value) {
			updatedConfirmPassword.valid = false;
			updatedConfirmPassword.validationMessage = intl.formatMessage({
				id: 'CHANGE.PASSWORD.VALIDATION.NO.PASSWORD.CONFIRM.MATCH',
				defaultMessage: "Password and Confirmation don't match"
			});
		}
		updatedConfirmPassword.valid = isValidPassword;
		updatedConfirmPassword.validationMessage = null;
		setConfirmPassword(updatedConfirmPassword);

		const updatedPassword = {...password};
		updatedPassword.valid = isValidPassword;
		updatedPassword.validationMessage = null;
		setPassword(updatedPassword);

	}

	useEffect(() => {
		const updatedPassword = {...password};
		updatedPassword.valid = isValidPassword;
		updatedPassword.validationMessage = null;
		setPassword(updatedPassword);

		const updatedConfirmPassword = {...confirmPassword};
		updatedConfirmPassword.valid = isValidPassword;
		updatedConfirmPassword.validationMessage = null;
		setConfirmPassword(updatedConfirmPassword);
	}, [isValidPassword]);

	const syncValueWithParentContainer = (element, role) => {
		switch (element) {
			case 'username':
				props.setUsername(username.value);
				break;
			case 'company':
				props.setCompany(company.value);
				break;
			case 'firstName':
				props.setFirstName(firstName.value);
				break;
			case 'lastName':
				props.setLastName(lastName.value);
				break;
			case 'email':
				props.setEmail(email.value);
				break;
			case 'authorities':
				props.setAuthorities(role);
				break;
			case 'password':
				props.setPassword(password.value);
				break;
		}
	}

	const checkAllInputsValidity = () => {
		let allValid = true;
		const updatedUsername = changeText(username, username.value, 'username', intl);
		setUsername(updatedUsername);
		allValid = allValid && updatedUsername.valid;

		if(props.loggedInUser === null || (props.type === 'update' && props.isAdmin === true )){
			const companyName = changeText(company, company.value, 'company', intl);
			setCompany(companyName);
			allValid = allValid && companyName.valid;
		}

		const updatedEmail = changeText(email, email.value, 'email', intl);
		setEmail(updatedEmail);
		allValid = allValid && updatedEmail.valid;

		if (props.includeRoles) {
			const updatedAuthorities = selectElement(authorities, authorities.roles, 'authorities', 'roles', intl);
			setAuthorities(updatedAuthorities);
			allValid = allValid && updatedAuthorities.valid;
		}

		if (props.loggedInUser === null) {
			// const updatedPassword = changeText(password, password.value, 'password', intl);
			// setPassword(updatedPassword);
			allValid = allValid && password.valid;

			// const updatedConfirmPassword = changeText(confirmPassword, confirmPassword.value, 'confirmPassword', intl);
			// setConfirmPassword(updatedConfirmPassword);
			allValid = allValid && confirmPassword.valid;
		} else {
			const updatedFirstName = changeText(firstName, firstName.value, 'firstName', intl);
			setFirstName(updatedFirstName);
			allValid = allValid && updatedFirstName.valid;

			const updatedLastName = changeText(lastName, lastName.value, 'lastName', intl);
			setLastName(updatedLastName);
			allValid = allValid && updatedLastName.valid;
		}
		return allValid;
	}
	props.registerValidateNormalUserFormCallback(checkAllInputsValidity);


	const [normalUserHeaderTitle, setNormalUserHeaderTitle] = useState('');
	useEffect(() => {
		setNormalUserHeaderTitle(GetNormalUserHeaderTitle(props, intl));
	}, []);


	// for Registration: this could be an if statement, externalize the front in two
	// seperate files: one for loggedIn, another for regisitration.

	let userForm;

	if (props.loggedInUser) {
		userForm = <NormalUserFormJSX username={username}
									  usernameChangeHandler={usernameChangeHandler}
									  syncValueWithParentContainer={syncValueWithParentContainer}
									  firstName={firstName}
									  firstNameChangeHandler={firstNameChangeHandler}
									  lastName={lastName}
									  lastNameChangeHandler={lastNameChangeHandler}
									  email={email}
									  emailChangeHandler={emailChangeHandler}
									  company={company}
									  companyChangeHandler={companyChangeHandler}
									  authorities={authorities}
									  authoritiesSelectHandler={authoritiesSelectHandler}
									  normalUserHeaderTitle={normalUserHeaderTitle}

									  type={props.type} includeRoles={props.includeRoles}
									  loggedInUser={props.loggedInUser}
									  isAdmin={props.isAdmin}
									  completeInformationMsg={props.completeInformationMsg}
		/>
	} else {
		userForm = <RegistrationFormJSX username={username}
										usernameChangeHandler={usernameChangeHandler}
										syncValueWithParentContainer={syncValueWithParentContainer}
										// firstName={firstName}
										// firstNameChangeHandler={firstNameChangeHandler}
										// lastName={lastName}
										// lastNameChangeHandler={lastNameChangeHandler}
										email={email}
										emailChangeHandler={emailChangeHandler}
										normalUserHeaderTitle={normalUserHeaderTitle}
										company={company}
										companyChangeHandler={companyChangeHandler}
										password={password}
										setIsValidPassword={setIsValidPassword}
										passwordChangeHandler={passwordChangeHandler}
										confirmPassword={confirmPassword}
										confirmPasswordChangeHandler={confirmPasswordChangeHandler}
		/>
	}

	return userForm;

}

export default NormalUserForm;