import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

import * as actions from "../../store/actions";
import DomainRestRequestsDisplay from "../../components/Domain/DomainRestRequestsDisplay";

const ListDomainRestRequests = props => {

	let [restRequestCreatedSuccessfullyAlertMessage, setRestRequestCreatedSuccessfullyAlertMessage] = useState(props.restRequestLabel); // state, effect

	const [restRequests, setRestRequests] = useState(props.restRequests);

	const [selectedMethods, setSelectedMethods] = useState(null);

	const dt = useRef(null);

	useEffect(() => {
		// Request Created, show success message
		setTimeout(() => {
			window.history.replaceState({tab: 1}, document.title);
			setRestRequestCreatedSuccessfullyAlertMessage(null);
		}, 2000);
	}, []);

	useEffect(() => {
		setRestRequests(props.restRequests);
	}, [props.restRequests]);

	useEffect(() => {
		// TODO This Runs only runs once after refreshList is set to true, right?
		if (props.refreshList === true) {
			props.onLoadDomain(props.domain.id);
		}
	}, [props.refreshList]);

	const onMethodChange = (e) => {
		// console.log('onMethodChange event:', e);
		dt.current.filter(e.value, 'method', 'in');
		setSelectedMethods(e.value);
	}

	const deleteRequest = (id) => {
		props.onDeleteRequestOfDomain(props.domain.id, id);
	}

	const [errorMessages, setErrorMessages] = useState([]);

	useEffect(() => {
		if (props.deletionError) {
			const updatedErrorMessages = [...errorMessages]
			updatedErrorMessages.push(props.deletionError)
			setErrorMessages(updatedErrorMessages);
		}
	}, [props.deletionError]);

	return <DomainRestRequestsDisplay restRequests={restRequests}
								onLoadRestRequests={props.onLoadRestRequests}
								dt={dt}
								selectedMethods={selectedMethods}
								onMethodChange={onMethodChange}
								deleteRequest={deleteRequest}
								errorMessages={errorMessages}

								label={restRequestCreatedSuccessfullyAlertMessage}
		// label is for when creating a request => show message
	/>;
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		domain: state.domain.domain,
		refreshList: state.restRequest.refreshList,
		deletionError: state.restRequest.deletionError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onDeleteRequestOfDomain: (domainId, requestId) => dispatch(actions.deleteRestRequestOfDomain(domainId, requestId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDomainRestRequests);