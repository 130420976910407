/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, useLocation} from "react-router-dom";
import {Redirect} from "react-router";
import {FormattedMessage} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {ContentRoute} from "../../../_metronic/layout"
import LoginPage from "../../containers/Authentication/LoginPage";
import Registration from "../../containers/Authentication/Registration";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ResetPassword from "../../containers/Authentication/ResetPassword";
import LanguageSelectorDropdown from "../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import ActivateAccount from "../../containers/Authentication/ActivateAccount";
import ForgotPassword from "../../containers/Authentication/ForgotPassword";
import CloseAccount from "../../containers/Authentication/CloseAccount";
import CookieConsent from "../../UI/CookieConsent";

export const Authentication = (props) => {
	const { search } = useLocation();
	let switchRoutes;
	if(props.resetPasswordOnly){
		switchRoutes = <Switch>
			<ContentRoute path = "/resetPassword" component = {ResetPassword}/>
		</Switch>
	} else {
		switchRoutes = <Switch>
			<ContentRoute path = "/auth/login" component = {LoginPage}/>
			<ContentRoute path = "/auth/forgot-password" component = {ForgotPassword}/>
			<ContentRoute path = "/auth/registration" component = {Registration}/>
			<ContentRoute path = "/resetPassword" component = {ResetPassword}/>
			<ContentRoute path = "/activate/:key" component = {ActivateAccount}/>
			<ContentRoute path = "/closeAccount" component = {CloseAccount}/>
			{props.authCheckFinished === true && <Redirect to = {`/auth/login${search}`}/>}
		</Switch>
	}

	return (
		<>
			<div className = "d-flex flex-column flex-root">
				{/*begin::Login*/}
				<div
					className = "login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
					id = "kt_login"
				>
					{/*begin::Aside*/}
					<div
						className = "login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
						style = {{
							backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
						}}
					>
						{/*begin: Aside Container*/}
						<div className = "d-flex flex-row-fluid flex-column justify-content-between">
							{/* start:: Aside header */}
							<Link to = "/" className = "flex-column-auto mt-5">
								<img
									alt = "Logo"
									className = "max-h-70px"
									src = {toAbsoluteUrl("/media/logos/tigmat/logo.png")}
								/>
							</Link>
							{/* end:: Aside header */}

							{/* start:: Aside content */}
							<div className = "flex-column-fluid d-flex flex-column justify-content-center">
								<h3 className = "font-size-h1 mb-5 text-white">
									<FormattedMessage id="LOGIN.WELCOME.TITLE"
									                  defaultMessage='Welcome to Tigmat!'/>
								</h3>
								<p className = "font-weight-lighter text-white opacity-80">
									<FormattedMessage id="LOGIN.WELCOME.DESCRIPTION"
									                  defaultMessage='The ultimate cloud-based test automation
									                  platform designed to simplify test automation	for
									                  non-technical users.'/>
								</p>
							</div>
							{/* end:: Aside content */}

							{/* start:: Aside footer for desktop */}
							<div className = "d-none flex-column-auto d-lg-flex justify-content-between mt-10">
								<div className = "opacity-70 font-weight-bold	text-white">
									&copy; {new Date().getFullYear().toString()} Tigmat
								</div>
								<div className = "d-flex">
									{/*<Link to = "/terms" className = "text-white">*/}
									{/*	<FormattedMessage id="FOOTER.PRIVACY"*/}
									{/*	                  defaultMessage='Privacy'/>*/}
									{/*</Link>*/}
									{/*<Link to = "/terms" className = "text-white ml-10">*/}
									{/*	<FormattedMessage id="FOOTER.LEGAL"*/}
									{/*	                  defaultMessage='Legal'/>*/}
									{/*</Link>*/}
									{/*<Link to = "/terms" className = "text-white ml-10">*/}
									{/*	<FormattedMessage id="FOOTER.CONTACT"*/}
									{/*	                  defaultMessage='Contact'/>*/}
									{/*</Link>*/}
								</div>
							</div>
							{/* end:: Aside footer for desktop */}
						</div>
						{/*end: Aside Container*/}
					</div>
					{/*begin::Aside*/}

					{/*begin::Content*/}
					<div className = "flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
						{/*begin::Content header*/}
						<LanguageSelectorDropdown noAlign={true}/>
						<div
							className = "position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

							<span className = "font-weight-bold text-dark-50">
								<FormattedMessage id="LOGIN.NO.ACCOUNT.TEXT"
								                  defaultMessage="Don't have an account yet?"/></span>
							<Link to = "/auth/registration"
							      className = "font-weight-bold ml-2" id = "kt_login_signup">
								<FormattedMessage id="SIGN.UP"
								                  defaultMessage='Sign Up'/>!
							</Link>

						</div>

						{/*end::Content header*/}

						{/* begin::Content body */}
						<div className = "d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
							{switchRoutes}
						</div>
						{/*end::Content body*/}

						{/* begin::Mobile footer */}
						<div
							className = "d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
							<div className = "text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
								&copy; {new Date().getFullYear().toString()} Tigmat
							</div>
							<div className = "d-flex order-1 order-sm-2 my-2">
								{/*<Link to = "/terms" className = "text-dark-75 text-hover-primary">*/}
								{/*	<FormattedMessage id="FOOTER.PRIVACY"*/}
								{/*	                  defaultMessage='Privacy'/>*/}
								{/*</Link>*/}
								{/*<Link*/}
								{/*	to = "/terms"*/}
								{/*	className = "text-dark-75 text-hover-primary ml-4"*/}
								{/*>*/}
								{/*	<FormattedMessage id="FOOTER.LEGAL"*/}
								{/*	                  defaultMessage='Legal'/>*/}
								{/*</Link>*/}
								{/*<Link*/}
								{/*	to = "/terms"*/}
								{/*	className = "text-dark-75 text-hover-primary ml-4"*/}
								{/*>*/}
								{/*	<FormattedMessage id="FOOTER.CONTACT"*/}
								{/*	                  defaultMessage='Contact'/>*/}
								{/*</Link>*/}
							</div>
						</div>
						{/* end::Mobile footer */}
					</div>
					{/*end::Content*/}
				</div>
				{/*end::Login*/}
			</div>
			<CookieConsent/>
		</>
	);
}

export default Authentication;