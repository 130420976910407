export const WINDOWS = "WINDOWS";
export const LINUX = "LINUX";

export const OperatingSystemObjects = [
	{
		// id: 1,
		label: 'Windows', value: WINDOWS},
	{
		// id: 2,
		label: 'Linux', value: LINUX},
];

export const OperatingSystemValues = [WINDOWS, LINUX];

export const getLabel = (value) => {
	if(!value) return '';
	return OperatingSystemObjects.find((os) => {
		return os.value === value;
	}).label;
}