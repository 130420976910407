export const ID = "ID";
export const NAME = "NAME";
export const XPATH = "XPATH";
export const LINKTEXT = "LINKTEXT";
export const PARTIALLINK = "PARTIALLINK";
export const CLASSNAME = "CLASSNAME";
export const CSSSELECTOR = "CSSSELECTOR";
export const ACCESSIBILITY = "ACCESSIBILITY";
export const IMAGE = "IMAGE";
export const CUSTOM = "CUSTOM";
