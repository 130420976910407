import React from "react";

import {IterationLoadingDialog, SprintOneLoadingDialog} from "../../UI/LoadingDialog";
import SprintDisplayTabs from "./SprintDisplayTabs";

const SprintDisplay = props => {

	return (
		<>
			{/*<DomainActions {...props}/>*/}
			<SprintOneLoadingDialog/>
			<IterationLoadingDialog/>
			<SprintDisplayTabs {...props}/>
		</>
	);
}

export default SprintDisplay;