import React from "react";
import {Breadcrumbs, Link as MLink, Paper, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		justifyContent: 'center',
		flexWrap: 'inherit',
		marginBottom: 6
	},
	paper: {
		padding: theme.spacing(1, 2),
	},
}));

const TestHeader = props => {
	const classes = useStyles();

	let domainID = '', domainName = '', testName = '';
	if(props.domain){
		domainID = props.domain.id;
		domainName = props.domain.label;
	}
	if(props.test){
		testName = props.test.label;
	}

	return (
		<div

			// className={classes.root}
		>
			<Paper
				elevation={0} className={`border-0 rounded-0 ${classes.paper}`}
			>
				<Breadcrumbs aria-label="breadcrumb" >

					<MLink color="inherit" to={`/domain/${domainID}`} component={Link}>
						{domainName}
					</MLink>
					<Typography color="textPrimary">{testName}</Typography>
				</Breadcrumbs>
			</Paper>
		</div>
	)
}

export default TestHeader;