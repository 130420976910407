import {put, delay, } from "redux-saga/effects";
import {push} from "connected-react-router";

import * as actions from "../actions";
import axios, {backendUrl} from "../../axios/axios";
import axiosLib from 'axios';
import {
	loadCurrentAndroidRecoringEnvironmentStateOfDomainAlreadyInUse, loadCurrentAndroidRecoringEnvironmentStateOfDomainCreated,
	loadCurrentAndroidRecoringEnvironmentStateOfDomainStopToContinue,
	removeDomainIDFromRequestedAndroidEnvironmentList
} from "../actions";
import {checkAndroidEnvironmentStatus} from "./project";

export function* createDomain(action) {
	yield put(actions.createDomainStart());
	try {
		const response = yield axios.post(`/domains/project/${action.projectID}`, action.domain);
		// console.log('response', response);
		if (response.status === 201) {
			yield put(actions.createDomainSuccess(response.data));
			yield put(push(`/domain/${response.data.id}`));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createDomainFail('Error Creating Domain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createDomainFail(error.message));
		}
	}
}

export function* loadDomains(action) {
	yield put(actions.loadDomainsStart());
	try {
		const response = yield axios.get(
			`/domains/project/${action.projectID}`, {
				params: {...action.config}
			});
		// console.log('response', response.data);

		yield put(actions.loadDomainsSuccess(response.data.content, response.data.totalElements));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadDomainsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadDomainsFail('Error Loading Domains, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadDomainsFail(error.message));
		}
	}
}

export function* deleteDomain(action) {
	yield put(actions.deleteDomainStart());
	try {
		const response = yield axios.delete(`/domains/${action.id}`);
		if(!response.data){
			yield put(actions.deleteDomainSuccess(action.id));
			if(action.redirect){
				yield put(push(action.redirect));
			}
		} else {
			const [testName, suiteName] = response.data.split('=', 2);
			const msg = `Cannot Delete This Domain because a test that belongs to it called 
			'${testName}' is used by Suite '${suiteName}'`
			yield put(actions.deleteDomainFail(msg));
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.deleteDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.deleteDomainFail('Error Deleting Domain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.deleteDomainFail(error.message));
		}
	}
}

export function* loadDomain(action) {
	yield put(actions.loadDomainStart());
	try {
		const response = yield axios.get(`/domains/${action.id}`);
		// console.log('response', response.data);

		yield put(actions.loadDomainSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadDomainFail('Error Loading Domain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadDomainFail(error.message));
		}
	}
}

export function* updateDomain(action) {
	yield put(actions.updateDomainStart());
	try {
		const response = yield axios.patch(`/domains/${action.id}`, action.domain);
		yield put(actions.updateDomainSuccess(action.stateDomain));
		// yield put(push(`/domains`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateDomainFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateDomainFail('Error Updating Domain, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateDomainFail(error.message));
		}
	}
}

export function* importTests(action) {
	yield put(actions.importTestsStart());
	try {
		const response = yield axios.patch(`/domains/import/${action.id}`, action.cases);
		// console.log('saga importTests response', response);
		yield put(actions.importTestsSuccess(response.data));


	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.importTestsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.importTestsFail('Error Importing Tests, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.importTestsFail(error.message));
		}
	}
}

export function* loadDomainsWithTestsOrRequests(action) {
	yield put(actions.loadDomainsWithTestsOrRequestsStart());
	try {
		const response = yield axios.get(`/domains/project/${action.projectID}/list`);
		// console.log('response', response.data);

		yield put(actions.loadDomainsWithTestsOrRequestsSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadDomainsWithTestsOrRequestsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadDomainsWithTestsOrRequestsFail('Error Loading loadDomainsWithTestsOrRequests, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadDomainsWithTestsOrRequestsFail(error.message));
		}
	}
}


export function* prepareAndroidRecoringEnvironment(action) {
	yield put(actions.prepareAndroidRecoringEnvironmentStart());
	try{
		const inspectAxios = axiosLib.create({baseURL: process.env.REACT_APP_INSPECT_API_URL, timeout: 3_600_000});
		inspectAxios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');
		// weird using domain, but that what abderrahim used
		const response = yield inspectAxios.put(`/inspect/domains/${action.domainID}/start`);
		// console.log('prepareAndroidRecoringEnvironment response.data', response.data)
		const result = response.data;

		// yield put(actions.prepareAndroidRecoringEnvironmentSuccesssDone(action.domainID));


		if(result.type === 'OK'){
			// normal
			yield put(actions.prepareAndroidRecoringEnvironmentSuccess(result, action.domainID, action.projectId));
		} else if(result.type === 'STOP_TO_CONTINUE'){
			// the recording environment is already running for the same user but a different domain
			yield put(actions.prepareAndroidRecoringEnvironmentStopToContinue(true, result.domainLabel));
		} else if(result.type === 'ALREADY_IN_USE'){
			// the recording environment is already running for a different user
			yield put(actions.prepareAndroidRecoringEnvironmentAlreadyInUse( true, result.userFullname));
		}


		// yield put(push(`/test/${response.data.id}`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.prepareAndroidRecoringEnvironmentFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.prepareAndroidRecoringEnvironmentFail('Error Preparing Android Recording Environment, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.prepareAndroidRecoringEnvironmentFail(error.message));
		}
	}
}

export function* stopAndroidRecoringEnvironment(action) {
	yield put(actions.stopAndroidRecoringEnvironmentStart());
	try{
		const inspectAxios = axiosLib.create({baseURL: process.env.REACT_APP_INSPECT_API_URL, timeout: 3_600_000});
		inspectAxios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');

		const response = yield inspectAxios.put(`/inspect/projects/${action.projectID}/stop`);

		// console.log('stopAndroidRecoringEnvironment response', response)
		yield put(actions.stopAndroidRecoringEnvironmentSuccess());

		// yield put(push(`/test/${response.data.id}`));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.stopAndroidRecoringEnvironmentFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.stopAndroidRecoringEnvironmentFail('Error Stopping Android Recording Environment, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.stopAndroidRecoringEnvironmentFail(error.message));
		}
	}
}

// export function* loadCurrentAndroidRecoringEnvironmentStateOfDomain(action) {
// 	yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainStart());
// 	try{
// 		const response = yield axios.get(`/inspect/domains/${action.domainID}/state`);
//
// 		const result = response.data;
// 		if(result.status === 'CREATED'){
// 			// do nothing
// 			yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainCreated());
// 			yield put(actions.checkAndroidEnvironmentStatusSuccess({status : result.status}));
// 			// This project Call is to sync  Project:Check Status with Domain:State
// 		} else {
// 			// remove from listIDs and open + new methods for StopToContinue... do not use old one
// 			yield put(actions.removeDomainIDFromRequestedAndroidEnvironmentList(action.domainID));
//
// 			if(result.type === 'OK'){
// 				// normal
// 				yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainSuccess(result, action.domainID, action.projectID));
// 			}
// 			else if(result.type === 'STOP_TO_CONTINUE'){
// 				// the recording environment is already running for the same user but a different domain
// 				yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainStopToContinue(true, result.domainLabel));
// 			} else if(result.type === 'ALREADY_IN_USE'){
// 				// the recording environment is already running for a different user
// 				yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainAlreadyInUse( true, result.userFullname));
// 			}
// 			yield put(actions.checkAndroidEnvironmentStatus(action.projectID, action.domainID));
// 		}
// 	} catch (error) {
// 		if (error.response) {
// 			console.log(error.response.data.message);
// 			yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainFail(error.response.data.message));
// 		} else if (error.request) {
// 			console.log(error.request)
// 			yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainFail('Error Loading Android Recording Environment State, Try Again'));
// 		} else {
// 			console.log('Error', error.message);
// 			yield put(actions.loadCurrentAndroidRecoringEnvironmentStateOfDomainFail(error.message));
// 		}
// 	}
// }