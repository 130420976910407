import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Spinner} from "react-bootstrap";
import {Button} from "@material-ui/core";
import PasswordChecklist from "react-password-checklist";
import {FormattedMessage, useIntl} from "react-intl";

import {history} from "../../../redux/store";
import {changeText} from "./Shared";
import * as actions from "../../store/actions";

const ResetPassword = (props) => {
	const intl = useIntl();

	const [password, setPassword] = useState({
		value: '',
		validation: {
			// required: true,
			// minLength: 6,
			// maxLength: 30
		},
		valid: false,
		validationMessage: null
	});
	const [confirm, setConfirm] = useState({
		value: '',
		validation: {
			// required: true,
			// minLength: 6,
			// maxLength: 30
		},
		valid: false,
		validationMessage: null
	});

	const [isValidPassword, setIsValidPassword] = useState(false);

	useEffect(() => {
		document.title = intl.formatMessage({
			id: "PAGE.TITLE.CHANGE.PASSWORD",
			defaultMessage: 'Reset Password' });
	}, []);

	useEffect(() => {
		if(props.successMessage){
			setTimeout(() => {
				history.push('/auth/login');
			}, 1500);
		}
	}, [props.successMessage]);


	const reset = () => {
		if(!checkAllInputsValidity()){
			return null;
		}

		const requestBody = {
			key: new URLSearchParams(props.location.search).get('key'),
			newPassword: password.value
		}
		props.onResetPassword(requestBody);
	}

	const passwordChangeHandler = (event) => {
		const updatedPassword = changeText(password, event.target.value, 'password', intl);

		if(updatedPassword.valid === true && confirm.value !== ''
			&& updatedPassword.value !== confirm.value){

			updatedPassword.valid = false;
			updatedPassword.validationMessage = intl.formatMessage({
				id: 'RESET.PASSWORD.VALIDATION.NO.PASSWORD.CONFIRM.MATCH',
				defaultMessage: "Password and Confirmation don't match" });
		}
		updatedPassword.valid = isValidPassword;
		updatedPassword.validationMessage = null;
		setPassword(updatedPassword);

		const updatedConfirmPassword = {...confirm};
		updatedConfirmPassword.valid = isValidPassword;
		updatedConfirmPassword.validationMessage = null;
		setConfirm(updatedConfirmPassword);
	}

	const confirmPasswordChangeHandler = (event) => {
		const updatedConfirmPassword = changeText(confirm, event.target.value, 'confirmPassword', intl);

		// Matching
		if(updatedConfirmPassword.valid === true && password.value !== ''
			&& updatedConfirmPassword.value !== password.value){
			updatedConfirmPassword.valid = false;
			updatedConfirmPassword.validationMessage = intl.formatMessage({
				id: 'RESET.PASSWORD.VALIDATION.NO.PASSWORD.CONFIRM.MATCH',
				defaultMessage: "Password and Confirmation don't match" }) ;
		}
		updatedConfirmPassword.valid = isValidPassword;
		updatedConfirmPassword.validationMessage = null;
		setConfirm(updatedConfirmPassword);

		const updatedPassword = {...password};
		updatedPassword.valid = isValidPassword;
		updatedPassword.validationMessage = null;
		setPassword(updatedPassword);
	}

	useEffect(() => {
		const updatedPassword = {...password};
		updatedPassword.valid = isValidPassword;
		updatedPassword.validationMessage = null;
		setPassword(updatedPassword);

		const updatedConfirmPassword = {...confirm};
		updatedConfirmPassword.valid = isValidPassword;
		updatedConfirmPassword.validationMessage = null;
		setConfirm(updatedConfirmPassword);
	}, [isValidPassword]);

	const checkAllInputsValidity = () => {
		let allValid = true;

		// const updatedPassword = changeText(password, password.value, 'password', intl);
		// setPassword(updatedPassword);
		allValid = allValid && password.valid; // TODO or isValidPassword

		// const updatedConfirmPassword = changeText(confirm, confirm.value, 'confirmPassword', intl);
		// setConfirm(updatedConfirmPassword);

		// Matching
		// if(updatedConfirmPassword.valid === true && password.value !== ''
		// 	&& updatedConfirmPassword.value !== password.value){
		// 	updatedConfirmPassword.valid = false;
		// 	updatedConfirmPassword.validationMessage = intl.formatMessage({
		// 		id: 'RESET.PASSWORD.VALIDATION.NO.PASSWORD.CONFIRM.MATCH',
		// 		defaultMessage: "Password and Confirmation don't match" }) ;
		// }
		allValid = allValid && confirm.valid;

		return allValid;
	}

	let show;

	let errorMessageServer = null;
	if (props.error) {
		errorMessageServer =
			<div className = "mb-10 alert alert-custom alert-light-danger alert-dismissible">
				<div className = "alert-text font-weight-bold">{props.error}</div>
			</div>
	}

	let successMessageServer = null;
	if (props.successMessage) {
		successMessageServer =
			<div className = "mb-10 alert alert-custom alert-light-success alert-dismissible">
				<div className = "alert-text font-weight-bold">{props.successMessage}</div>
			</div>
	}

	if (props.loading) {
		show = <Spinner animation = "border" variant = "danger">
			<span className = "sr-only">
				<FormattedMessage id="LOADING"
				                  defaultMessage='Loading...'/></span>
		</Spinner>
	} else {
		show = (<div className = "login-form login-signin" id = "kt_login_signin_form">

			{/* begin::Head */}
			<div className = "text-center mb-10 mb-lg-20">
				<h3 className = "font-size-h1">
					<FormattedMessage id="RESET.PASSWORD.TITLE.RESET.PASSWORD"
					                  defaultMessage='Reset Password'/>
				</h3>
				<p className = "text-muted font-weight-bold">
					<FormattedMessage id="RESET.PASSWORD.TEXT.ENTER.PASSWORD"
					                  defaultMessage='Enter your new password'/>
				</p>
			</div>
			{/* end::Head */}

			{/*begin::Form*/}
			<form className = "form fv-plugins-bootstrap fv-plugins-framework">

				{errorMessageServer}
				{successMessageServer}

				<div className = "form-group fv-plugins-icon-container">
					<input placeholder = {intl.formatMessage({ id: "RESET.PASSWORD.PLACEHOLDER.PASSWORD",
						defaultMessage: 'Password' })}
					       type = "password"
					       className = {`form-control form-control-solid h-auto py-5 px-6 my-5`}
					       name = "password"
					       autoComplete='new-password'
					       onChange = {passwordChangeHandler}/>
					{password.validationMessage && <div className = "alert alert-custom alert-light-danger alert-dismissible">
						<div className = "alert-text font-weight-bold">{password.validationMessage}</div>
					</div>}
				</div>
				<div className = "form-group fv-plugins-icon-container">
					<input placeholder = {intl.formatMessage({ id: "RESET.PASSWORD.PLACEHOLDER.CONFIRM.PASSWORD",
						defaultMessage: 'Confirm Password' })}
					       type = "password"
					       className = {`form-control form-control-solid h-auto py-5 px-6 my-5`}
					       name = "confirmPassword"
					       autoComplete='new-password'
					       onChange = {confirmPasswordChangeHandler}/>
					{confirm.validationMessage && <div className = "alert alert-custom alert-light-danger alert-dismissible">
						<div className = "alert-text font-weight-bold">{confirm.validationMessage}</div>
					</div>}
				</div>

				<PasswordChecklist
					rules={["minLength","specialChar","number","capital","match", "maxLength", "lowercase"]}
					minLength={8}
					maxLength={255}
					value={password.value}
					valueAgain={confirm.value}
					onChange={(isValid) => {
						setIsValidPassword(isValid);
					}}
					className='mb-4'
					messages={{
						maxLength: intl.formatMessage({ id: "PASSWORD.CHECKER.MAX.LENGTH", defaultMessage: 'Password has no more than 255 characters.'}),
						minLength: intl.formatMessage({ id: "PASSWORD.CHECKER.MIN.LENGTH", defaultMessage: 'Password has more than 8 characters.'}),
						capital: intl.formatMessage({ id: "PASSWORD.CHECKER.UPPERCASE", defaultMessage: 'Password has a capital letter.'}),
						lowercase: intl.formatMessage({ id: "PASSWORD.CHECKER.LOWERCASE", defaultMessage: 'Password has a lowercase letter.'}),
						match: intl.formatMessage({ id: "PASSWORD.CHECKER.MATCH", defaultMessage: 'Passwords match.'}),
						number: intl.formatMessage({ id: "PASSWORD.CHECKER.NUMBER", defaultMessage: 'Password has a number.'}),
						specialChar: intl.formatMessage({ id: "PASSWORD.CHECKER.SPECIAL.CHARACTER", defaultMessage: 'Password has special characters.'}),
					}}
				/>

				<div className = "form-group d-flex flex-wrap justify-content-between align-items-center">

					<Button variant = "outlined" color = "primary"
					        className = 'btn btn-primary font-weight-bold px-9 py-4 my-3'
					        onClick = {reset}>
						<FormattedMessage id="RESET.PASSWORD.BUTTON.UPDATE"
						                  defaultMessage='Update'/>
					</Button>
				</div>
			</form>
			{/*end::Form*/}
		</div>)
	}
	return show;
}

const mapStateToProps = state => {
	return {
		loading: state.authentication.loading,
		error: state.authentication.error,
		successMessage: state.authentication.successMessage
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onResetPassword: (body) => dispatch(actions.resetPassword(body))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);