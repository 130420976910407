import React from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import Domain from "./Domain";
import DomainsView from "./DomainsView";

const Domains = (props) => {

	let domainColumn = null;
	if (props.match.params.domainID) {
		domainColumn = <Domain {...props} />
	}
	let domainsViewWidth = 3;
	if(domainColumn === null){
		domainsViewWidth = 6;
	}
	return (
		<Container className = 'p-0'>
			<Row>
				<Col sm = {12} md = {domainsViewWidth}>
					<DomainsView {...props}/>
				</Col>
				<Col>
					{domainColumn}
				</Col>
			</Row>
		</Container>
	);
}

const mapStateToProps = state => {
	return {

	};
};

const mapDispatchToProps = dispatch => {
	return {

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Domains);