import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";

import * as actions from "../../store/actions";

const ActivateAccount = (props) => {
	const intl = useIntl();

	const [activationMessage, setActivationMessage] = useState(
		intl.formatMessage({id: "ACTIVATION.ACTIVATING", defaultMessage: 'Activating...'})
	);

	useEffect(() => {
		document.title = intl.formatMessage({
			id: "PAGE.TITLE.ACCOUNT.ACTIVATION", defaultMessage: 'Account Activation'
		});
		const key = props.match.params.key;
		if (key) {
			props.onActivateAccount(key);
		}
	}, []);

	useEffect(() => {
		if (props.activated === null) {
			setActivationMessage(
				intl.formatMessage({id: "ACTIVATION.ACTIVATING", defaultMessage: 'Activating...'})
			);
		} else if (props.activated) {
			setActivationMessage(
				intl.formatMessage({id: "ACTIVATION.SUCCESS", defaultMessage: 'Account Activated.'})
			);
		} else {
			setActivationMessage(
				intl.formatMessage({id: "ACTIVATION.FAIL", defaultMessage: 'Activation Account Failed.'})
			);
		}
	}, [props.activated]);

	const show = <div className = "login-form login-signin" id = "kt_login_signin_form">
		<div className = "text-center mb-10 mb-lg-20">
			<h1 className = "font-size-h1">
				{activationMessage}
			</h1>
			{props.activated === false && <p className = "text-muted font-weight-bold">
				{props.error}
			</p>}

			{props.activated === true && <Link to = '/login'>
				<h4><FormattedMessage id = "ACTIVATION.CAN.LOGIN"
				                      defaultMessage = 'You can login now'/>
				</h4>
			</Link>}
		</div>

	</div>;
	return show;
}

const mapStateToProps = state => {
	return {
		// loading: state.authentication.loading,
		activated: state.authentication.activated,
		error: state.authentication.error
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onActivateAccount: (key) => dispatch(actions.activateAccount(key))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);