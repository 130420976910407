import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Snackbar} from "@material-ui/core";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import SVG from "react-inlinesvg";
import {FormattedMessage, useIntl} from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import {actions, components, getActionLabelForName, getComponentLabelForName} from "./ActionTypes";
import {inputShouldBeVisible} from "../../containers/Instruction/Shared";
import * as Action from "../../containers/Test/Actions";
import {Card, CardBody,} from "../../../_metronic/_partials/controls";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const InstructionsDisplay = (props) => {
	const intl = useIntl();

	const deleteInstruction = (instruction) => {
		// alert('Instruction ID: ' + id + ' deleted');
		let instructions = [...props.instructions];
		instructions.splice(props.instructions.indexOf(instruction), 1);
		// instructions = instructions.filter(instr => instr.index !==
		instructions = instructions.map((instruction, index) => {
			instruction.index = index;
			return instruction;
		});
		props.setInstructions(instructions);
		props.updateInstructions(instructions);
		handleClick(intl.formatMessage({ id: "TEST.SNACKBAR.INSTRUCTION.DELETED",
			defaultMessage: 'Instruction Deleted'}));

	}

	const [open, setOpen] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');

	const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);

	const [instruction, setInstruction] = React.useState(null);

	const handleRemoveDialogOpen = () => {
		setRemoveDialogOpen(true);
	};

	const handleRemoveDialogClose = () => {
		setRemoveDialogOpen(false);
	};

	const handleClick = (message) => {
		setOpen(true);
		setAlertMessage(message);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const onRowReorder = (e) => {
		// Not Needed: index is updated automatically
		let instructions = e.value;
		instructions = instructions.map((instruction, index) => {
			instruction.index = index;
			return instruction;
		});
		props.setInstructions(instructions);
		handleClick(intl.formatMessage({ id: "TEST.TABLE.INSTRUCTION.REORDERED",
			defaultMessage: 'Instruction Reordered'}));
		props.updateInstructions(instructions);
	}

	const componentNameEditor = (tableProps) => {
		const [,identifier] = inputShouldBeVisible(tableProps.rowData['action']);
		if(!identifier){
			return null;
		} else {
			// console.log('tableProps', tableProps);
			return inputTextEditor(tableProps, 'componentName');
		}
	}

	const componentValueEditor = (tableProps) => {
		const [,,value] = inputShouldBeVisible(tableProps.rowData['action']);
		if(!value){
			return null;
		} else {
			return inputTextEditor(tableProps, 'typeValue');
		}
	}

	const componentDescriptionEditor = (tableProps) => {
		return inputTextEditor(tableProps, 'description');
	}

	//{/*problem: input is not validated for required*/}
	const inputTextEditor = (tableProps, field) => {
		return <div className = 'p-fluid '>
			<div className = 'p-field p-grid'>
				<InputText type = "text"
				           className = ''
				           value = {tableProps.rowData[field]}
						   aria-expanded={true}
				           onChange = {(e) =>
					           onEditorValueChange(tableProps, e.target.value, field, true)}
				           onBlur = {() => patch(tableProps)}
				           onKeyDown = {(event) => {
					           if (event.key === 'Enter' || event.keyCode === 13)
						           patch(tableProps);
				           }}/>
			</div>
		</div>;
	}

	const [previousInputValue, setPreviousInputValue] = useState('');
	const [validInputValue, setValidInputValue] = useState(true);
	const [InputValueCoordinates, setInputValueCoordinates] = useState({row: null, field: null});

	const checkNumberConstraint = (value, tableProps, field) => {
		let validtmp, validationMessage;
		setInputValueCoordinates({row: tableProps.rowIndex, field: tableProps.field})
		if (value !== '') {
			if (tableProps.rowData['action'] === Action.SELECT_FRAME && field === 'componentName') {
				const pattern = /^\d+$/;
				setValidInputValue(pattern.test(value));
				validtmp = pattern.test(value);
				validationMessage = !validtmp ? `${value} ${intl.formatMessage({
					id: "VALIDATION.NOT.NUMBER",
					defaultMessage: 'is not a number!'
				})}` : null;
			}
			if (tableProps.rowData['action'] === Action.WAIT_SECONDS && field === 'typeValue') {
				const pattern = /^\d+$/;
				setValidInputValue(pattern.test(value));
				validtmp = pattern.test(value);
				validationMessage = !validtmp ? `${value} ${intl.formatMessage({
					id: "VALIDATION.NOT.NUMBER",
					defaultMessage: 'is not a number!'
				})}` : null;
			}
		}

		if (validtmp === false) {
			// alert(validationMessage);
			handleInputTextClick(validationMessage); // show snackbar
		} else {
			setOpenInputText(false);// hide snackbar if value is valid
		}
	};

	const emptyStringConstraint = (value, tableProps, field) => {
		let validtmp, validationMessage;
		setInputValueCoordinates({row: tableProps.rowIndex, field: tableProps.field})
		if (field === 'componentName' || field === 'typeValue') {
			if(value === ''){
				setValidInputValue(false);
				validtmp = false;
				validationMessage = !validtmp ? `Field ${intl.formatMessage({ id: "VALIDATION.REQUIRED",
					defaultMessage: 'is required!'})}` : null;
			} else {
				setValidInputValue(true);
				validtmp = true;
			}
		}
		if (validtmp === false) {
			handleInputTextClick(validationMessage); // show snackbar
		} else {
			setOpenInputText(false);// hide snackbar if value is valid
		}
		return validtmp;
	}

	const [errorMessage, setErrorMessage] = useState(null);
	const onEditorValueChange = (tableProps, value, field, inputText = false) => {
		let updatedInstructions = [...tableProps.value];
		if(inputText){
			if(value.length > 2_000){
				setErrorMessage(intl.formatMessage({
					id: "INSTRUCTION.FORM.LENGTH.VALIDATION.MESSAGE",
					defaultMessage: 'Last Entry is more than 2 000 in length'
				}));
				return;
			} else {
				setErrorMessage(null);
			}
			// if action is select frame && field is componentName, then value must be number if not discard change
			if(emptyStringConstraint(value, tableProps, field)){
				checkNumberConstraint(value, tableProps, field);
			}
			if(previousInputValue === ''){ // sets only on initial 1st edit. discard subsequent changes
				setPreviousInputValue(updatedInstructions[tableProps.rowIndex][tableProps.field]);
			}
		}
		updatedInstructions[tableProps.rowIndex][tableProps.field] = value;
		props.setInstructions(updatedInstructions);
	}

	const patch = (tableProps) => {
		setErrorMessage(null);
		// console.log('patch tableProps', tableProps);
		if(tableProps
			&& InputValueCoordinates.row !== null       // this means no change has been made,
			// meaning changeValueEditor is not called => coordinates are null
		){     // text
			let updatedInstructions = [...tableProps.value];
			// current value to see if empty String
			const currentValue = updatedInstructions[InputValueCoordinates.row][InputValueCoordinates.field];
			if(!validInputValue || currentValue === ''){
				updatedInstructions[InputValueCoordinates.row][InputValueCoordinates.field] = previousInputValue;
				props.setInstructions(updatedInstructions);
			}
			props.updateInstructions(updatedInstructions);
			setPreviousInputValue('');// reset for each cell
		} else {        // select
			props.updateInstructions(props.instructions);
		}
	}

	// This Snackbar is for Tabel Input Text Error Validation Messages
	const [openInputText, setOpenInputText] = React.useState(false);
	const [alertMessageInputText, setAlertMessageInputText] = React.useState('');

	const handleInputTextClick = (message) => {
		setOpenInputText(true);
		setAlertMessageInputText(message);
	};

	const handleInputTextClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenInputText(false);
	};

	const inputTextSnackbar = <Snackbar
		anchorOrigin = {{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		open = {openInputText}
		autoHideDuration = {5000}
		onClose = {handleInputTextClose}
		message = {alertMessageInputText}
		action = {
			<IconButton size = "small" aria-label = "close" color = "inherit"
			            onClick = {handleInputTextClose}>
				<CloseIcon fontSize = "small"/>
			</IconButton>
		}
	>
		<Alert onClose = {handleInputTextClose} severity = "error">
			{alertMessageInputText}
		</Alert>
	</Snackbar>;


	const actionBodyTemplate = (rowData) => {
		return getActionLabelForName(rowData.action);
	}

	const deleteBodyTemplate = (rowData) => {

		return <a
			title = {intl.formatMessage({ id: "TEST.TABLE.INSTRUCTION.DELETE",
				defaultMessage: 'Delete Instruction'})}
			className = "btn btn-icon btn-light btn-hover-danger btn-sm"
			onClick = {
				// () => deleteInstruction(rowData)
				() => {
					setInstruction(rowData);
					handleRemoveDialogOpen();
				}
			}>
			<span className = "svg-icon svg-icon-md svg-icon-danger">
		        <SVG src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
	        </span>
		</a>;
	}

	const componentBodyTemplate = (rowData) => {
		const [component] = inputShouldBeVisible(rowData.action);
		if(!component){
			return null;
		}
		return getComponentLabelForName(rowData.component);
	}

	const toComponentBodyTemplate = (rowData) => {
		const [toComponent] = inputShouldBeVisible(rowData.componentTo);
		return toComponent ? rowData.componentTo : null;
	}

	const identifierBodyTemplate = (rowData) => {
		const [,identifier] = inputShouldBeVisible(rowData.action);
		return identifier ? rowData.componentName : null;
	}

	const valueBodyTemplate = (rowData) => {
		const [,,value] = inputShouldBeVisible(rowData.action);
		return value ? rowData.typeValue : null;
	}

	const actionEditor = (tableProps) => {
		return (
			// <DropdownBoot>
			// 	<DropdownBoot.Toggle variant="light">
			// 		{tableProps.rowData['action']}
			// 	</DropdownBoot.Toggle>
			//
			// 	<DropdownBoot.Menu style={{overflowY: 'scroll', minHeight: '200px', height: '300px'}}>
			// 		{actions.map(action => {
			// 			return <DropdownBoot.Item key = {action.name}
			// 			                          onClick={(e) => {
			// 			                          	// console.log('action.name:', action.name);
			// 				                          onEditorValueChange(tableProps, action.name);
			// 				                          patch();
			// 			                          }}
			// 			                 value = {action.name}>{action.name}</DropdownBoot.Item>
			// 		})}
			// 	</DropdownBoot.Menu>
			// </DropdownBoot>
			<Dropdown value = {tableProps.rowData['action']} options = {actions} optionLabel = "label"
			          optionValue = "name"
			          onChange = {(e) => {
				          onEditorValueChange(tableProps, e.value);
				          patch();
			          }}
			          style = {{width: '100%'}}
			          placeholder = {intl.formatMessage({ id: "TEST.TABLE.INSTRUCTION.SELECT.ACTION",
				          defaultMessage: 'Select Action'})}
			          appendTo = {document.body}
			/>
		);
	}

	const componentEditor = (tableProps) => {
		// Start: nullifying, set property to boolean, so other read it first to render or not
		//, identifier, value
		const [component] = inputShouldBeVisible(tableProps.rowData['action']);
		// console.log("tableProps.rowData['action']", tableProps.rowData['action']);
		if(!component){
			return null;
		} else {
			return (
				<Dropdown value = {tableProps.rowData['component']} options = {components} optionLabel = "label"
				          optionValue = "name"
				          onChange = {(e) => {
					          onEditorValueChange(tableProps, e.value);
					          patch();
				          }}
				          style = {{width: '100%'}}
				          placeholder = {intl.formatMessage({ id: "TEST.TABLE.INSTRUCTION.SELECT.COMPONENT",
					          defaultMessage: 'Select Component'})}
				          appendTo = {document.body}
				          // itemTemplate = {(option) => {
					      //     return <span className = {`product-badge status-instock`}>
						  //         {option.name}
					      //     </span>
				          // }}
				/>
			);
		}
	}

	const toComponentEditor = (tableProps) => {
		// Start: nullifying, set property to boolean, so other read it first to render or not
		//, identifier, value
		const [, , , toComponent] = inputShouldBeVisible(tableProps.rowData['action']);
		// console.log("tableProps.rowData['action']", tableProps.rowData['action']);
		if(!toComponent){
			return null;
		} else {
			return (
				<Dropdown value = {tableProps.rowData['componentTo']} options = {components} optionLabel = "name"
				          optionValue = "name"
				          onChange = {(e) => {
					          onEditorValueChange(tableProps, e.value);
					          patch();
				          }}
				          style = {{width: '100%'}}
				          placeholder = {intl.formatMessage({ id: "TEST.TABLE.INSTRUCTION.SELECT.TO.COMPONENT",
					          defaultMessage: 'To Component'})}
				          appendTo = {document.body}
					// itemTemplate = {(option) => {
					//     return <span className = {`product-badge status-instock`}>
					//         {option.name}
					//     </span>
					// }}
				/>
			);
		}
	}


	let columns2 = [
		<Column key = 'description' columnKey = 'description' field = 'description'
		        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.NAME",
			        defaultMessage: 'Name'})}
		        editor = {(props) => componentDescriptionEditor(props)}/>,
		<Column key = 'action' columnKey = 'action' field = 'action'
		        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.ACTION",
			        defaultMessage: 'Action'})}
		        body = {actionBodyTemplate} editor = {(props) => actionEditor(props)}/>,
		<Column key = 'component' columnKey = 'component' field = 'component'
		        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.COMPONENT",
			        defaultMessage: 'Component'})}
		        body = {componentBodyTemplate} editor = {(props) => componentEditor(props)}/>,
		<Column key = 'componentName' columnKey = 'componentName' field = 'componentName'
		        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.IDENTIFIER",
			        defaultMessage: 'Component Identifier'})}
		        body = {identifierBodyTemplate} editor = {(props) => componentNameEditor(props)}
				style={{width:'40%'}}/>,
		<Column key = 'componentTo' columnKey = 'componentTo' field = 'componentTo'
		        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.TO.COMPONENT",
			        defaultMessage: 'To Component'})}
		        body = {toComponentBodyTemplate} editor = {(props) => toComponentEditor(props)}/>,
		<Column key = 'typeValue' columnKey = 'typeValue' field = 'typeValue'
		        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.VALUE",
			        defaultMessage: 'Value'})}
		        body = {valueBodyTemplate} editor = {(props) => componentValueEditor(props)}/>,
		<Column key = 'actions'
		        header = {intl.formatMessage({ id: "TEST.TABLE.INSTRUCTION.COLUMN.ACTIONS",
			        defaultMessage: 'Actions'})}
		        body = {deleteBodyTemplate}/>
	];
	let table = <DataTable value = {props.instructions}
	                       editMode = "cell" className = "editable-cells-table p-datatable-striped"
	                       onRowReorder = {onRowReorder}
	                       autoLayout = {false}
	                       loading = {props.loading}
	>
		<Column rowReorder style = {{width: '3em'}}/>
		{columns2}
	</DataTable>;
	if(localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD'){

		columns2 = [
			<Column key = 'description' columnKey = 'description' field = 'description'
			        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.NAME",
				        defaultMessage: 'Name'})}/>,
			<Column key = 'action' columnKey = 'action' field = 'action'
			        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.ACTION",
				        defaultMessage: 'Action'})}/>,
			<Column key = 'component' columnKey = 'component' field = 'component'
			        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.COMPONENT",
				        defaultMessage: 'Component'})}/>,
			<Column key = 'componentName' columnKey = 'componentName' field = 'componentName'
			        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.IDENTIFIER",
				        defaultMessage: 'Component Identifier'})}
					style={{width:'40%'}}/>,
			<Column key = 'componentTo' columnKey = 'componentTo' field = 'componentTo'
			        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.TO.COMPONENT",
				        defaultMessage: 'To Component'})}/>,
			<Column key = 'typeValue' columnKey = 'typeValue' field = 'typeValue'
			        header = {intl.formatMessage({ id: "INSTRUCTION.LABEL.VALUE",
				        defaultMessage: 'Value'})}/>
		];
		table = <DataTable value = {props.instructions}
		                   autoLayout = {false}>
			{columns2}
		</DataTable>;
	}

	let display = null;

	if (!props.instructions) {
		// loading
	} else if (props.instructions.length === 0) {
		// empty domains
		display =
			<Alert variant = "outlined" severity = "info" className='my-6 h3'>
				<FormattedMessage id="TEST.TABLE.INSTRUCTION.EMPTY"
				                  defaultMessage='No Instructions Exists For Test:'/> {props.testName}
			</Alert>

	} else {
		// show domains
		display = <Card className = "border-0 rounded-0 shadow-none">
			<CardBody >

				{errorMessage && <Alert elevation = {3} variant = "standard"
											   severity = "error" className = 'my-3'>
					{errorMessage}
				</Alert>}

				{table}

				<DeletionConfirmationDialog open={removeDialogOpen} closeDialog={handleRemoveDialogClose}
											message={<FormattedMessage id="TEST.TABLE.INSTRUCTION.DIALOG.REMOVE.INSTRUCTION"
																	   defaultMessage='Remove Instruction order:'/> }
											name={instruction ? `${instruction.index}` : ''}
											deleteAction={() => {
												handleRemoveDialogClose();
												deleteInstruction(instruction);
											}}
				/>

				<Snackbar
					anchorOrigin = {{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open = {open}
					autoHideDuration = {2000}
					onClose = {handleClose}
					message = {alertMessage}
					// action = {
					// 	<IconButton size = "small" aria-label = "close" color = "inherit"
					// 	            onClick = {handleClose}>
					// 		<CloseIcon fontSize = "small"/>
					// 	</IconButton>
					// }
				>
					<Alert onClose = {handleClose} severity = "success">
						{alertMessage}
					</Alert>
				</Snackbar>
				{inputTextSnackbar}
			</CardBody>
		</Card>;
	}
	return display;
}

export default InstructionsDisplay;