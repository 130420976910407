import React from "react";
import {Button} from "@material-ui/core";
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import {FormattedMessage} from "react-intl";
import {makeStyles} from "@material-ui/styles";

import NewInstruction from "../../containers/Instruction/NewInstruction";
import NewAssert from "../../containers/Instruction/NewAssert";
import ImportTest from "../../containers/Test/ImportTest";
import {history} from "../../../redux/store";
import ExportTest from "../../containers/Test/ExportTest";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const InstructionActions = props => {
	const classes = useStyles();

	return (
		<div className = {`bg-white p-1 text-center ${classes.root}`}>
			<NewInstruction {...props}/>
			<NewAssert {...props}/>
			{/*<Button variant = "contained"*/}
			{/*        size = 'large'*/}
			{/*        startIcon={<ImportExport/>}*/}
			{/*        to = '/test/new' component = {Link}*/}
			{/*        className = 'mr-6'>*/}
			{/*	Import*/}
			{/*</Button>*/}
			<ImportTest {...props}/>
			<ExportTest {...props}/>
			<Button variant = "contained"
					size = 'small'
					startIcon={<KeyboardReturn/>}
					onClick = {(event) => {
						event.preventDefault();
						// means domain/id + state tab = 1
						if(props.domain){
							history.push('/domain/' + props.domain.id, {tab: 1});
						} else {
							history.goBack()
						}
					}}
					className = ''>
				<FormattedMessage id="BUTTON.BACK"
								  defaultMessage='Back'/>
			</Button>
			<br/>
			{/*<Button*/}
			{/*	variant="contained"*/}
			{/*	size='small'*/}
			{/*	startIcon={<LaunchIcon/>}*/}
			{/*	onClick={() => props.setShowInstructionsTableMaximized(true)}*/}
			{/*>*/}
			{/*	<FormattedMessage id="TEST.ACTIONS.EXPAND.INSTRUCTIONS.TABLE"*/}
			{/*					  defaultMessage='Expand Instructions'/>*/}
			{/*</Button>*/}
			{/*<Button*/}
			{/*	variant="contained"*/}
			{/*	size='small'*/}
			{/*	startIcon={<LaunchIcon/>}*/}
			{/*	onClick={() => props.setShowAssertsTableMaximized(true)}*/}
			{/*>*/}
			{/*	<FormattedMessage id="TEST.ACTIONS.EXPAND.ASSERTS.TABLE"*/}
			{/*					  defaultMessage='Expand Asserts'/>*/}
			{/*</Button>*/}

		</div>
	)
}

export default InstructionActions;