export const CLICK = "CLICK";
export const DOUBLE_CLICK = "DOUBLE_CLICK";
export const TYPE = "TYPE";
export const SELECT = "SELECT";
export const SEND_KEYS = "SEND_KEYS";
export const WAIT_SECONDS = "WAIT_SECONDS";
export const CHOOSE_OK_ON_NEXT_CONFIRMATION = "CHOOSE_OK_ON_NEXT_CONFIRMATION";
export const WEBDRIVER_CHOOSE_OK_ON_VISIBLE_CONFIRMATION = "WEBDRIVER_CHOOSE_OK_ON_VISIBLE_CONFIRMATION";
export const WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_CONFIRMATION = "WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_CONFIRMATION";
export const WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT = "WEBDRIVER_ANSWER_ON_VISIBLE_PROMPT";
export const WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_PROMPT = "WEBDRIVER_CHOOSE_CANCEL_ON_VISIBLE_PROMPT";
export const MOUSE_OUVER = "MOUSE_OUVER";
export const MOUSE_OUT = "MOUSE_OUT";
export const WAIT_FOR_ELEMENT_PRESENT = "WAIT_FOR_ELEMENT_PRESENT";
export const ADD_SELECTION = "ADD_SELECTION";
export const REMOVE_SELECTION = "REMOVE_SELECTION";
export const DRAG_AND_DROP = "DRAG_AND_DROP";
export const RUN_SCRIPT = "RUN_SCRIPT";
export const SELECT_WINDOW = "SELECT_WINDOW";
export const SELECT_FRAME = "SELECT_FRAME";
// OPEN = "OPEN",
export const CHECK = "CHECK";
export const UNCHECK = "UNCHECK";
export const STORE_VALUE = "STORE_VALUE";
export const STORE_TEXT = "STORE_TEXT";
//CLOSE = "CLOSE"
