import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";

import {ContentRoute, LayoutSplashScreen} from "../_metronic/layout";
import Logout from "../tigmat/containers/Authentication/Logout";
import Users from "../tigmat/containers/Users/Users";
import User from "../tigmat/containers/Users/User";
import NewUser from "../tigmat/containers/Users/NewUser";
import UpdateUser from "../tigmat/containers/Users/UpdateUser";
import NewProject from "../tigmat/containers/Project/NewProject";
import Project from "../tigmat/containers/Project/Project";
import Projects from "../tigmat/containers/Project/Projects";
import UpdateProject from "../tigmat/containers/Project/UpdateProject";
import ChangePassword from "../tigmat/containers/Authentication/ChangePassword";
import NewDomain from "../tigmat/containers/Domain/NewDomain";
import Domains from "../tigmat/containers/Domain/Domains";
import UpdateDomain from "../tigmat/containers/Domain/UpdateDomain";
import NewTest from "../tigmat/containers/Test/NewTest";
import Test from "../tigmat/containers/Test/Test";
import ForbiddenAccess from "../tigmat/UI/ForbiddenAccess";
import Suites from "../tigmat/containers/Suite/Suites";
import NewSuite from "../tigmat/containers/Suite/NewSuite";
import Suite from "../tigmat/containers/Suite/Suite";
import Sprints from "../tigmat/containers/Sprint/Sprints";
import NewSprint from "../tigmat/containers/Sprint/NewSprint";
import Sprint from "../tigmat/containers/Sprint/Sprint";
import Iteration from "../tigmat/containers/Iteration/Iteration";
import Report from "../tigmat/containers/Report/Report";
import Billing from "../tigmat/containers/Payment/Billing";
import NewRestRequest from "../tigmat/containers/Rest/NewRestRequest";
import ListRestRequests from "../tigmat/containers/Rest/ListRestRequests";
import UpdateRestRequest from "../tigmat/containers/Rest/UpdateRestRequest";
import Invoices from "../tigmat/containers/Payment/Invoices";
import * as Role from "../tigmat/containers/Users/enum/Roles";
import Dashboard from "../tigmat/containers/Summary/Dashboard";

export default function BasePage() {
	// useEffect(() => {
	//   console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	let usersRoutes = <ContentRoute path = "/users" exact component = {Users}/>;
	let usersNewRoutes = <ContentRoute path = "/users/new" exact component = {NewUser}/>;
	let userRoutes = <ContentRoute path = "/user/:username" exact component = {User}/>;
	let userUpdateRoutes = <ContentRoute path = "/user/:username/edit" exact component = {UpdateUser}/>;

	if (localStorage.getItem('Top-Role') !== 'ROLE_SUPER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_ADMIN' &&
		localStorage.getItem('Top-Role') !== 'ROLE_MANAGER') {
		usersRoutes = <ContentRoute path = "/users" exact component = {ForbiddenAccess}/>;
		usersNewRoutes = <ContentRoute path = "/users/new" exact component = {ForbiddenAccess}/>;
		// userRoutes = <ContentRoute path = "/user/:username" exact component = {ForbiddenAccess}/>;
		// userUpdateRoutes = <ContentRoute path = "/user/:username/edit" exact component = {ForbiddenAccess}/>;


	}

	let projectsRoute = <ContentRoute path = "/projects" exact component = {Projects}/>;
	let projectRoute = <ContentRoute path = "/project/:projectID" exact component = {Project}/>;
	let projectUpdateRoute = <ContentRoute path = "/project/:projectID/edit" exact component = {UpdateProject}/>;
	let projectNewRoute = <ContentRoute path = "/project/new" exact component = {NewProject}/>;
	if(localStorage.getItem('Top-Role') !== Role.ROLE_SUPER &&
		localStorage.getItem('Top-Role') !== Role.ROLE_ADMIN &&
		localStorage.getItem('Top-Role') !== Role.ROLE_MANAGER &&
		localStorage.getItem('Top-Role') !== Role.ROLE_TESTER){
		projectsRoute = <ContentRoute path = "/projects" exact component = {ForbiddenAccess}/>;
		projectRoute = <ContentRoute path = "/project/:projectID" exact component = {ForbiddenAccess}/>;
		projectUpdateRoute = <ContentRoute path = "/project/:projectID/edit" exact component = {ForbiddenAccess}/>;
	}

	if(localStorage.getItem('Top-Role') !== Role.ROLE_SUPER &&
		localStorage.getItem('Top-Role') !== Role.ROLE_ADMIN &&
		localStorage.getItem('Top-Role') !== Role.ROLE_MANAGER){
		projectNewRoute = <ContentRoute path = "/project/new" exact component = {ForbiddenAccess}/>;
	}


	let domainNewRoute = <ContentRoute path = "/domain/new" exact component = {NewDomain}/>;
	let domainsRoute = <ContentRoute path = "/domains" exact component = {Domains}/>;
	let domainRoute = <ContentRoute path = "/domain/:domainID" exact component = {Domains}/>;
	let domainUpdateRoute = <ContentRoute path = "/domain/:domainID/edit" exact component = {UpdateDomain}/>;

	let testNewRoute = <ContentRoute path = "/test/new" exact component = {NewTest}/>;
	let testRoute = <ContentRoute path = "/test/:testID" exact component = {Test}/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		domainNewRoute = <ContentRoute path = "/domain/new" exact component = {ForbiddenAccess}/>;
		domainsRoute = <ContentRoute path = "/domains" exact component = {ForbiddenAccess}/>;
		domainRoute = <ContentRoute path = "/domain/:domainID" exact component = {ForbiddenAccess}/>;
		domainUpdateRoute = <ContentRoute path = "/domain/:domainID/edit" exact component = {ForbiddenAccess}/>;

		testNewRoute = <ContentRoute path = "/test/new" exact component = {ForbiddenAccess}/>;
		testRoute = <ContentRoute path = "/test/:testID" exact component = {ForbiddenAccess}/>;
	}

	let suitesRoute = <ContentRoute path = "/suites" exact component = {Suites}/>;
	let suiteNewRoute = <ContentRoute path = "/suite/new" exact component = {NewSuite}/>;
	let suiteRoute = <ContentRoute path = "/suite/:suiteID" exact component = {Suite}/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		suitesRoute = <ContentRoute path = "/suites" exact component = {ForbiddenAccess}/>;
		suiteNewRoute = <ContentRoute path = "/suite/new" exact component = {ForbiddenAccess}/>;
		suiteRoute = <ContentRoute path = "/suite/:suiteID" exact component = {ForbiddenAccess}/>;
	}

	let sprintsRoute = <ContentRoute path = "/sprints" exact component = {Sprints}/>;
	let sprintNewRoute = <ContentRoute path = "/sprint/new" exact component = {NewSprint}/>;
	let sprintRoute = <ContentRoute path = "/sprint/:sprintID" exact component = {Sprint}/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		sprintsRoute = <ContentRoute path = "/sprints" exact component = {ForbiddenAccess}/>;
		sprintNewRoute = <ContentRoute path = "/sprint/new" exact component = {ForbiddenAccess}/>;
		sprintRoute = <ContentRoute path = "/sprint/:sprintID" exact component = {ForbiddenAccess}/>;
	}

	let iterationRoute = <ContentRoute path = "/iteration/:iterationID" exact component = {Iteration}/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		iterationRoute = <ContentRoute path = "/iteration/:iterationID" exact component = {ForbiddenAccess}/>;
	}

	//
	let reportRoute = <ContentRoute path = "/report/:iterationID" exact component = {Report}/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		reportRoute = <ContentRoute path = "/report/:iterationID" exact component = {ForbiddenAccess}/>;
	}

	let paymentRoute = <ContentRoute path = "/billing" exact component = {Billing}/>;
	let invoicesRoute = <ContentRoute path = "/invoices" exact component = {Invoices}/>;
	if (localStorage.getItem('Top-Role') !== Role.ROLE_ADMIN ){
		paymentRoute = <ContentRoute path = "/billing" exact component = {ForbiddenAccess}/>;
		invoicesRoute = <ContentRoute path = "/invoices" exact component = {ForbiddenAccess}/>;
	}
	// let paymentResultRoute = <ContentRoute path = "/payment/result" exact component = {PaymentResult}/>;

	// TODO if CICD then block all application and show message: CICD Account cannot access web application.
	const summaryChartRoute = <ContentRoute path = "/dashboard" exact component = {Dashboard}/>;

	let restNewRoute = <ContentRoute path = "/rest-request/new" exact component = {NewRestRequest}/>;
	let restRequestsRoute = <ContentRoute path = "/rest-requests" exact component = {ListRestRequests}/>;
	let restRequestRoute = <ContentRoute path = "/rest-request/:restRequestID" exact component = {UpdateRestRequest}/>;
	if (localStorage.getItem('Top-Role') === 'ROLE_CICD' ||
		localStorage.getItem('Top-Role') === 'ROLE_USER') {
		restNewRoute = <ContentRoute path = "/rest-request/new" exact component = {ForbiddenAccess}/>;
		restRequestsRoute = <ContentRoute path = "/rest-requests" exact component = {ForbiddenAccess}/>;
		restRequestRoute = <ContentRoute path = "/rest-request/:restRequestID" exact component = {ForbiddenAccess}/>;
	}

	return (
		<Suspense fallback = {<LayoutSplashScreen/>}>
			<Switch>

				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from = "/" to = "/dashboard"/>
				}
				{usersRoutes}
				{usersNewRoutes}
				{userRoutes}
				{userUpdateRoutes}
				{/*<ContentRoute path = "/users" exact component = {Users} />*/}
				{/*<ContentRoute path = "/users/new" exact component = {NewUser}/>*/}
				{/*<ContentRoute path = "/user/:username" exact component = {User}/>*/}
				{/*<ContentRoute path = "/user/:username/edit" exact component = {UpdateUser}/>*/}

				{/*<ContentRoute path = "/projects" exact component = {Projects}/>*/}
				{projectsRoute}
				{projectNewRoute}
				{projectRoute}
				{projectUpdateRoute}
				{/*<ContentRoute path = "/project/:projectID" exact component = {Project}/>*/}
				{/*<ContentRoute path = "/project/:projectID/edit" exact component = {UpdateProject}/>*/}

				<ContentRoute path = "/logout" component = {Logout}/>
				{/*<ContentRoute path = "/transfer" exact component = {Transfer}/>*/}
				{/*<ContentRoute path = "/transferholder" exact component = {TransferHolder}/>*/}
				<ContentRoute path = "/changePassword" exact component = {ChangePassword}/>

				{/*Domain*/}
				{domainNewRoute}
				{domainsRoute}
				{domainRoute}
				{domainUpdateRoute}

				{testNewRoute}
				{testRoute}

				{/*Suite*/}
				{suitesRoute}
				{suiteNewRoute}
				{suiteRoute}

				{/*Sprint*/}
				{sprintsRoute}
				{sprintNewRoute}
				{sprintRoute}

				{/*Iteration*/}
				{iterationRoute}

				{/*Report*/}
				{reportRoute}

				{/*Payment*/}
				{/*{paymentResultRoute}*/}
				{paymentRoute}
				{invoicesRoute}

				{/*Summary*/}
				{summaryChartRoute}

				{restNewRoute}

				{restRequestsRoute}

				{restRequestRoute}

				<Redirect to = "/error/error-v1"/>
			</Switch>
		</Suspense>
	);
}
