import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	usersTableProperties: {
		first: 0,
		rows: 20
		// page: 0,
		// pageCount: 1
	}
};
const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.SET_USERS_TABLE_PROPERTIES:
			return updateObject(state, {
				usersTableProperties: {
					first: action.first,
					rows: action.rows
				}
			});
		default: {
			return state;
		}

	}
}

export default reducer;