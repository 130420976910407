import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FormattedMessage, useIntl} from "react-intl";
import SVG from "react-inlinesvg";
import Viewer from 'react-viewer';

import {history} from "../../../redux/store";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import * as Type from "../Project/enum/ProjectType";

const ReportDisplay = props => {
	const intl = useIntl();

	const [expandedRows, setExpandedRows] = React.useState(null);

	const expandAll = () => {
		let expandedRows = {};
		props.report.forEach(report => expandedRows[`${report.id}`] = true);
		setExpandedRows(expandedRows);
	}

	const collapseAll = () => {
		setExpandedRows(null);
	}

	const [showScreenshot, setShowScreenshot] = useState(false);
	const [imageID, setImageID] = useState(null);
	const [url, setUrl] = useState(null);

	const showScreenshotDialog = (imagekey) => {
		setImageID(imagekey);
		// get image
		props.onDownloadScreenshot(imagekey);
	}

	useEffect(() => {
		if(imageID && imageID === props.imagekey){
			setShowScreenshot(true);
			setUrl(URL.createObjectURL(props.imageBlob));
		}
	}, [props.imagekey, imageID]);

	const hideScreenshotDialog = () => {
		setShowScreenshot(false);
		setImageID(null);
		setUrl(null);
	};

	// url = 'https://images.unsplash.com/photo-1540202404-d0c7fe46a087?ixid=MnwxMjA3fDB8MHxwaG' +
	// 	'90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1490&q=80'
	const ScreenshotDialog = <Viewer
			visible={showScreenshot}
			onClose={hideScreenshotDialog}
			images={[{src: url, alt: 'Screenshot'}]}
			downloadable={true}
			rotatable={false}
			attribute={false}
			noNavbar={true}
			noImgDetails={true}
			changeable={false}
			zoomSpeed={0.25}
			loop={false}
			downloadInNewWindow={true}
			defaultScale={1.20}
			scalable={false}

		/>
		// 	<ImgsViewer
		// 	imgs={[
		// 		{ src: url, caption: 'Screenshot'}
		// 	]}
		// 	isOpen={showScreenshot}
		// 	onClose={hideScreenshotDialog}
		// 	backdropCloseable={true}
		// />
		// 	<Dialog
		// 	open = {showScreenshot}
		// 	onClose = {hideScreenshotDialog}
		// 	fullWidth={true}
		// 	aria-labelledby = "alert-dialog-title"
		// 	aria-describedby = "alert-dialog-description">
		// 	<DialogContent >
		// 		<img src={url} alt='screenshot' />
		// 	</DialogContent>
		// </Dialog>;


	const screenshotBodyTemplate = (rowData, parentRow) => {
		// parentRow:sprint execution{ iterationId, suiteId} . rowData.testId
		// console.log('screenshotBodyTemplate', rowData, parentRow);
		if((!rowData.error && !rowData.fail) || !rowData.imagekey){
			return null;
		}
		// className btn-lg removed
		return <a onClick = {e => showScreenshotDialog(rowData.imagekey)}
		          title = {intl.formatMessage({
			          id: "REPORT.VIEW.SCREENSHOT",
			          defaultMessage: 'View Screenshot'
		          })}
		          className = "btn btn-icon btn-light btn-hover-primary ">
						<span className = "svg-icon svg-icon-lg-xxl svg-icon-primary">
					        <SVG title = {intl.formatMessage({
						        id: "REPORT.VIEW.SCREENSHOT",
						        defaultMessage: 'View Screenshot'
					        })}
					             src = {toAbsoluteUrl("/media/svg/icons/Home/Picture.svg")}
					        />
				        </span>
		</a>
		// <Link to = {`/rest-request/${rowData.id}`}
		//              title = {intl.formatMessage({
		// 	             id: "REST.REQUESTS.TABLE.ACTIONS.UPDATE.REST.REQUEST",
		// 	             defaultMessage: 'Update Rest Request'
		//              })}
		//              className = "btn btn-icon btn-light btn-hover-primary btn-sm"
		// >
		// 			<span className = "svg-icon svg-icon-md svg-icon-primary">
		// 			    <SVG title = {intl.formatMessage({
		// 				    id: "REST.REQUESTS.TABLE.ACTIONS.UPDATE.REST.REQUEST",
		// 				    defaultMessage: 'Update Rest Request'
		// 			    })}
		// 			         src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
		// 			    />
		// 			</span>
		// </Link>;
	}

	const rowExpansionTemplate = (data) => {
		// console.log('rowExpansionTemplate', data);

		let testsList, screenshotColumn;
		let failureMessageColumn = <Column key = 'fail.message' columnKey = 'fail.message' field = 'fail.message'
										   header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.FAILURES", defaultMessage: 'Failures'})}/>;
		if(props.project.type === Type.API){
			testsList = data.restApis;
			 const failuresTemplate = (rowData) => {
				 let i = 1;
				 return <ol>
					 {rowData.failure.map(failure => <li key={i++}>{failure.message}</li>)}
				 </ol>
			}
			failureMessageColumn = <Column field = 'fail.message' body={failuresTemplate}
										   header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.FAILURES", defaultMessage: 'Failures'})}/>;
		} else{
			testsList = data.testcase;
			screenshotColumn = <Column key = 'screenshot' body = {(testcase) =>
				screenshotBodyTemplate(testcase, data)}
									   header = {intl.formatMessage({
										   id: "REPORT.TABLE.COLUMN.SCREENSHOT",
										   defaultMessage: 'ScreenShot'
									   })}/>;
		}

		return (
			<div className = "orders-subtable">
				<h5><FormattedMessage id = "REPORT.DETAILS.FOR"
				                      defaultMessage = 'Details for'/> {data.name}</h5>
				<DataTable value = {testsList} autoLayout={true}>
					<Column key = 'name' columnKey = 'name' field = 'name'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.NAME", defaultMessage: 'Name'})} sortable/>
					<Column key = 'time' columnKey = 'time' field = 'time'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.DURATION", defaultMessage: 'Duration'})}
							sortable/>
					<Column key = 'error.message' columnKey = 'error.message' field = 'error.message'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.ERRORS", defaultMessage: 'Errors'})}/>

					{/*<Column key = 'fail.message' columnKey = 'fail.message' field = 'fail.message'*/}
					{/*        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.FAILURES", defaultMessage: 'Failures'})}/>*/}
					{failureMessageColumn}
					{screenshotColumn}
				</DataTable>
			</div>
		);
	}

	// const header = (
	// 	<div className="table-header-container">
	// 		<Button icon="pi pi-plus" onClick={expandAll} className="p-mr-2" >Expand All</Button>
	// 		<Button icon="pi pi-minus" onClick={collapseAll} >Collapse All</Button>
	// 	</div>
	// );


	// let videoURL = '';
	// if(props.project && props.report){
	// 	videoURL = `https://tigmat.io/api/reporting/video/${props.project.id}/${props.report[0].iterationId}`;
	// }

	return (
		<div className = "datatable-rowexpansion-demo">
			<div className = 'text-right'>
				<Button onClick = {(event) => {
					event.preventDefault();
					if (props.sprint) {
						history.push('/sprint/' + props.sprint.id, {tab: 1});
					} else {
						history.goBack();
					}
				}} variant = 'contained' className = 'm-4 '>
					<FormattedMessage id = "BUTTON.BACK" defaultMessage = 'Back'/>
				</Button>
			</div>

			<div className = "card">
				<DataTable value = {props.report}
				           paginator className = "p-datatable-striped"
				           paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				           currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
				           rows = {20} rowsPerPageOptions = {[20, 50, 100, 500, 1000]}
				           expandedRows = {expandedRows}
				           onRowToggle = {(e) => setExpandedRows(e.data)}
					// onRowExpand={onRowExpand} onRowCollapse={onRowCollapse}
					       rowExpansionTemplate = {rowExpansionTemplate} dataKey = "id"
					// header={header}
				>
					<Column expander style = {{width: '3em'}}/>
					<Column key = 'name' columnKey = 'name' field = 'name'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
							sortable/>
					<Column key = 'time' columnKey = 'time' field = 'time'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.DURATION", defaultMessage: 'Duration'})}
							sortable/>
					<Column key = 'tests' columnKey = 'tests' field = 'tests'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.NUMBER.OF.TESTS", defaultMessage: 'Tests N°'})}
							sortable/>
					<Column key = 'errors' columnKey = 'errors' field = 'errors'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.ERRORS", defaultMessage: 'Errors'})}
							sortable/>
					<Column key = 'failures' columnKey = 'failures' field = 'failures'
					        header = {intl.formatMessage({id: "REPORT.TABLE.COLUMN.FAILURES", defaultMessage: 'Failures'})}
							sortable/>

				</DataTable>
				{/*<Player*/}
				{/*	playsInline*/}
				{/*	poster="/assets/poster.png"*/}
				{/*	src={videoURL}*/}
				{/*/>*/}
				{ScreenshotDialog}
			</div>
		</div>
	);
}

export default ReportDisplay;
