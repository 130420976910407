import React from "react";
import {
	AppBar,
	Button,
	Dialog,
	FormControl,
	FormHelperText,
	Input,
	InputLabel,
	MenuItem,
	Select,
	Slide,
	TextField,
	Toolbar,
	Typography
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const AddForm = props => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const save = () => {
		if(props.submitAssertHandler()){
			handleClose();
		}
	}

	return (
		<>
			<Button variant = "contained"
			        size = 'small'
			        startIcon = {<AddIcon/>}
			        onClick = {handleClickOpen}
			        className = ''>
				<FormattedMessage id="TEST.ACTIONS.ADD.ASSERT.RESULT"
				                  defaultMessage='Assert'/>
			</Button>
			<Dialog fullScreen open = {open} onClose = {handleClose} TransitionComponent = {Transition}>
				<AppBar className = {classes.appBar}>
					<Toolbar>
						<IconButton edge = "start" color = "inherit" onClick = {handleClose} aria-label = "close">
							<CloseIcon/>
						</IconButton>
						<Typography variant = "h6" className = {classes.title}>
							<FormattedMessage id="ASSERT.RESULT.TITLE"
							                  defaultMessage='Add Assert'/>
						</Typography>
						<Button autoFocus color = "inherit" onClick = {save}>
							<FormattedMessage id="BUTTON.SAVE"
							                  defaultMessage='Save'/>
						</Button>
					</Toolbar>
				</AppBar>
				<div className = "row">
					<div className = "col-md-12 gutter-b">
						<Card>

							<Card.Body>
								{/*<form className = {classes.container} noValidate autoComplete = "off"*/}
								{/*      onSubmit = {props.submitDomainHandler}>*/}

									<Container>
										<Row>
											<Col>
												<TextField
													id = "name"
													name = "name"
													label = {intl.formatMessage({ id: "ASSERT.RESULT.FORM.LABEL.NAME",
														defaultMessage: 'Assert Name'})}
													className = {`mb-6 ${classes.textField}`}
													value = {props.name.value}
													onChange = {event => props.nameChangeHandler(event, 'name')}
													margin = "normal"
													variant = "outlined"
													placeholder = {intl.formatMessage({ id: "ASSERT.RESULT.FORM.PLACEHOLDER.NAME",
														defaultMessage: 'Test Exists'})}
													error = {!props.name.valid}
													helperText = {props.name.validationMessage}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormControl className = {`${classes.formControl} row d-flex mb-6`}
												             error = {!props.operation.valid}>
													<InputLabel htmlFor = "select-operation">
														<FormattedMessage id="ASSERT.RESULT.FORM.LABEL.OPERATION"
														                  defaultMessage='Operation'/>
													</InputLabel>
													<Select
														value = {props.operation.operation}
														onChange = {(event) => props.operationSelectHandler(event)}
														input = {<Input id = "select-operation"/>}
													>

														{props.operation.operations.map(operation => {
															return <MenuItem key = {operation}
															                 value = {operation}>{operation}</MenuItem>
														})}
													</Select>
													<FormHelperText>{props.operation.validationMessage}</FormHelperText>
												</FormControl>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormControl className = {`${classes.formControl} row d-flex mb-6`}
												             error = {!props.component.valid}>
													<InputLabel htmlFor = "select-component">
														<FormattedMessage id="ASSERT.RESULT.FORM.LABEL.COMPONENT"
														                  defaultMessage='Component'/>
													</InputLabel>
													<Select
														value = {props.component.component}
														onChange = {(event) => props.componentSelectHandler(event)}
														input = {<Input id = "select-component"/>}
													>

														{props.component.components.map(component => {
															return <MenuItem key = {component}
															                 value = {component}>{component}</MenuItem>
														})}
													</Select>
													<FormHelperText>{props.component.validationMessage}</FormHelperText>
												</FormControl>
											</Col>
										</Row>
										<Row>
											<Col>
												<TextField
													id = "identifier"
													name = "identifier"
													label = {intl.formatMessage({ id: "ASSERT.RESULT.FORM.LABEL.IDENTIFIER",
														defaultMessage: 'Component Identifier'})}
													className = {`mb-6 ${classes.textField}`}
													value = {props.identifier.value}
													onChange = {event => props.identifierChangeHandler(event, 'identifier')}
													margin = "normal"
													variant = "outlined"
													placeholder = {intl.formatMessage({ id: "ASSERT.RESULT.FORM.PLACEHOLDER.IDENTIFIER",
														defaultMessage: 'Identifier'})}
													error = {!props.identifier.valid}
													helperText = {props.identifier.validationMessage}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<TextField
													id = "value"
													name = "value"
													label = {intl.formatMessage({ id: "ASSERT.RESULT.FORM.LABEL.VALUE",
														defaultMessage: 'Value'})}
													className = {`mb-6 ${classes.textField}`}
													value = {props.value.value}
													onChange = {event => props.valueChangeHandler(event, 'value')}
													margin = "normal"
													variant = "outlined"
													placeholder = {intl.formatMessage({ id: "ASSERT.RESULT.FORM.PLACEHOLDER.VALUE",
														defaultMessage: 'home'})}
													error = {!props.value.valid}
													helperText = {props.value.validationMessage}
												/>
											</Col>
										</Row>

									</Container>
								{/*</form>*/}
							</Card.Body>
						</Card>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default AddForm;