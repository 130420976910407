import React, {useState} from "react";
import {Card} from "react-bootstrap";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import SVG from "react-inlinesvg";
import {FormattedMessage, useIntl} from "react-intl";
import {Snackbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const SuiteRestRequestsDisplay = (props) => {
	const intl = useIntl();

	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const [request, setRequest] = useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Handling Alert
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const handleClickAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlertOpen(false);
	};

	const testNameBodyTemplate = (rowData) => {
		return <Link to={`/rest-request/${rowData.id}`} className='text-dark'><h5>{rowData.label}</h5></Link>;
		// return <h5>{rowData.label}</h5>;
	}

	const methodBodyTemplate = (rowData) => {
		return <span className="font-size-h6">
			{rowData.method}
		</span>;
	}

	const urlBodyTemplate = (rowData) => {
		return <span className="font-size-h6">
			{rowData.uri}
		</span>;
	}

	const onRowReorder = (e) => {
		let requests = e.value;
		requests = requests.map((request, index) => {
			request.index = index;
			return request;
		});
		props.setRequests(requests);
		handleClickAlert(intl.formatMessage({id: "SUITE.REQUESTS.DISPLAY.REQUESTS.REORDERED", defaultMessage: 'Requests Reordered'}));
		props.updateRequests(requests);
	}

	const requestActionsBodyTemplate = (rowData) => {

		return (
			<div className={` ${classes.root}`}>
				<a title={intl.formatMessage({id: "SUITE.REQUESTS.DISPLAY.TABLE.DELETE.REQUEST", defaultMessage: 'Delete Request'})}
				   className="btn btn-icon btn-light btn-hover-danger btn-sm "
					// onClick = {() => deleteTest(rowData.id)}
				   onClick={() => {
					   setRequest(rowData); // to see this {index+testCase}
					   handleClickOpen();
				   }}
				>
			        <span className="svg-icon svg-icon-md svg-icon-danger">
				        <SVG title={intl.formatMessage({
							id: "SUITE.REQUESTS.DISPLAY.TABLE.DELETE.REQUEST",
							defaultMessage: 'Delete Request'
						})} src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</div>
		);
	}

	const labelColumn = <Column key='label' columnKey='label' field='label'
								header={intl.formatMessage({id: "SUITE.REQUESTS.DISPLAY.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
								body={testNameBodyTemplate}/>;

	const methodColumn = <Column field="method" columnKey='method'
								 header={intl.formatMessage({
									 id: "SUITE.REQUESTS.DISPLAY.TABLE.COLUMN.METHOD", defaultMessage: 'Method'
								 })}
								 body={methodBodyTemplate}/>;
	const urlColumn = <Column field="uri" columnKey='uri'
							  header={intl.formatMessage({id: "SUITE.REQUESTS.DISPLAY.TABLE.COLUMN.URL", defaultMessage: 'URL'})}
							  body={urlBodyTemplate}/>;

	let actionsColumn = null;
	if (localStorage.getItem('Top-Role') !== 'ROLE_USER' &&
		localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		actionsColumn = <Column key='actions'
								header={intl.formatMessage({
									id: "SUITE.REQUESTS.DISPLAY.TABLE.COLUMN.ACTIONS",
									defaultMessage: 'Actions'
								})}
								body={requestActionsBodyTemplate}
								style={{width: '25%'}}
								className='p-text-right'/>;
	}

	const display = <Card className="border-0 rounded-0">
		<Card.Body className='p-0'>
			{props.updatingError && <Alert elevation={6} variant="standard" severity="error" className='m-3'>
				{props.updatingError}
			</Alert>}
			<DataTable value={props.requests}
					   paginator className="p-datatable-striped "
					   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					   rows={20} rowsPerPageOptions={[20, 50, 100, 500, 1000]}
					   onRowReorder={onRowReorder}
					   emptyMessage={intl.formatMessage({ id: "REST.REQUESTS.EMPTY.LIST", defaultMessage: 'No Rest Requests'})}
			>
				<Column rowReorder style={{width: '3em'}}/>
				{labelColumn}
				{methodColumn}
				{urlColumn}
				{actionsColumn}
			</DataTable>
			<DeletionConfirmationDialog open={open} closeDialog={handleClose}
										message={<FormattedMessage id="SUITE.REQUESTS.DISPLAY.REMOVE.REQUEST" defaultMessage='Remove Request'/> }
										name={request ? `${request.label}` : ''}
										deleteAction={() => {
											handleClose();
											props.deleteRequest(request.index);
										}}
			/>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={alertOpen}
				autoHideDuration={2000}
				onClose={handleCloseAlert}
				message={alertMessage}
			>
				<Alert onClose={handleCloseAlert} severity="success">
					{alertMessage}
				</Alert>
			</Snackbar>
		</Card.Body>
	</Card>;
	return display;
}

export default SuiteRestRequestsDisplay;