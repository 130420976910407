import React, {useEffect} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Button, makeStyles, Snackbar, TextField} from "@material-ui/core";
import {Editor as TinyEditor} from '@tinymce/tinymce-react';
import {Alert} from "@material-ui/lab";
import ReactHtmlParser from "react-html-parser";
import {FormattedMessage, useIntl} from "react-intl";

import {SuiteLoadingDialog} from "../../UI/LoadingDialog";
import {history} from "../../../redux/store";
import Editor from "for-editor";

const useStyles = () => makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}
}));

const AddForm = props => {
	const intl = useIntl();

	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');
	const [severity, setSeverity] = React.useState('');

	const handleUpdate = (message) => {
		setOpen(true);
		setAlertMessage(message);
		if (message === 'Updating Suite') {
			setSeverity('info');
		} else {
			setSeverity('success');
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	// if (props.loading) {
	// 	// loading
	// 	handleUpdate('Updating Domain');
	// }

	useEffect(() => {
		if (!props.loading && open) {
			handleUpdate(intl.formatMessage({ id: "SUITE.SNACKBAR.UPDATED",
				defaultMessage: 'Suite Updated'}));
		}
	}, [props.loading]);

	let descriptionValidationMessage = null;
	if (!props.description.valid) {
		descriptionValidationMessage = <Alert elevation = {6} variant = "filled"
		                                      severity = "error" className = 'mt-6 mb-5'>
			{props.description.validationMessage}
		</Alert>
	}
	let headerTitle = intl.formatMessage({ id: "SUITE.HEADER.TITLE.CREATE",
		defaultMessage: 'Create New Suite'});
	if (props.type === 'update') {
		headerTitle = '';
	}

	let actions = <Card.Footer>
		{props.type === 'create' && <Button variant = "contained" type = "submit"
		                                    className = 'mr-6'>
			<FormattedMessage id="BUTTON.CREATE"
			                  defaultMessage='Create'/>
		</Button>}
		{props.type === 'update' && <Button variant = "contained" type = "submit"
		                                    className = 'mr-6' id = 'submit' hidden = {true}>
			<FormattedMessage id="BUTTON.UPDATE"
			                  defaultMessage='Update'/>
		</Button>}
		{props.type === 'create' && <Button
			onClick = {(event) => {
				event.preventDefault();
				history.goBack();
			}}
			variant = "contained">
			<FormattedMessage id = "BUTTON.CANCEL" defaultMessage = 'Cancel'/>
		</Button>}
	</Card.Footer>;

	let nameOnBlur = null;
	let descriptionOnBlur = null;
	let nameOnEnterKey = null;

	if (props.type === 'update') {
		nameOnBlur = event => document.getElementById('submit').click();
		descriptionOnBlur = event => document.getElementById('submit').click();
		nameOnEnterKey = (event) => {
			if (event.key === 'Enter' || event.keyCode === 13) {
				document.getElementById('submit').click();
			}
		}
	}

	let name = <TextField
		id = "name"
		name = "name"
		label = {intl.formatMessage({ id: "SUITE.LABEL.NAME",
			defaultMessage: 'Suite Name'})}
		className = {`${classes.textField}`}
		value = {props.name.value}
		onChange = {event => props.nameChangeHandler(event, 'name')}
		onBlur = {nameOnBlur}
		onKeyDown = {nameOnEnterKey}
		margin = "normal"
		variant = "outlined"
		placeholder = {intl.formatMessage({ id: "SUITE.PLACEHOLDER.NAME",
			defaultMessage: 'Suite 1'})}
		error = {!props.name.valid}
		helperText = {props.name.validationMessage}
	/>;
	let description = <>
		{/*<TinyEditor value = {props.description.value}*/}
		{/*            init = {{*/}
		{/*	            height: 500,*/}
		{/*	            menubar: false,*/}
		{/*	            plugins: [*/}
		{/*		            'print preview paste autolink autosave save directionality code visualblocks visualchars ' +*/}
		{/*		            'fullscreen link codesample charmap hr pagebreak nonbreaking toc advlist lists textpattern ' +*/}
		{/*		            'noneditable charmap quickbars emoticons',*/}
		{/*	            ],*/}
		{/*	            toolbar:*/}
		{/*		            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +*/}
		{/*		            'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +*/}
		{/*		            'forecolor backcolor removeformat | pagebreak | charmap emoticons | ' +*/}
		{/*		            'fullscreen  preview save print | insertfile image media template link anchor codesample | ' +*/}
		{/*		            'ltr rtl',*/}
		{/*	            autosave_interval: '15s',*/}
		{/*	            autosave_retention: '2m',*/}
		{/*	            toolbar_mode: 'wrap',*/}
		{/*	            branding: false,*/}
		{/*	            statusbar: false,*/}
		{/*	            setup: ed => {*/}
		{/*		            ed.on('blur', descriptionOnBlur);*/}
		{/*	            }*/}
		{/*            }}*/}
		{/*            onEditorChange = {(value) => props.descriptionChangeHandler(value)}/>*/}
		<Editor value={props.description.value}
				onChange={(value) => props.descriptionChangeHandler(value)}
				onSave={() => {
					if (typeof descriptionOnBlur === 'function') {
						descriptionOnBlur();
					}
				}}
				language="en" subfield={true} preview={true}
				toolbar={{
					h1: true, // h1
					h2: true, // h2
					h3: true, // h3
					h4: true, // h4
					link: true, // 链接
					code: true, // 代码块
					preview: true,
					expand: true, // 全屏
					undo: true, // 撤销
					redo: true, // 重做
					save: true,
					subfield: true, // 单双栏模式
				}}/>
		{descriptionValidationMessage}
	</>
	let cardHeader = <Card.Header> {headerTitle} </Card.Header>;
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		actions = null;
		name = <h1>{props.name.value}</h1>;
		description = <div className = "mb-6">
			{ReactHtmlParser(props.description.value)}
		</div>
		cardHeader = null;
	}

	const submitForm = (event) => {
		props.submitSuiteHandler(event);
		if(props.type === 'update'){
			handleUpdate(intl.formatMessage({ id: "SUITE.SNACKBAR.UPDATING",
				defaultMessage: 'Updating Suite'}));
		}
	}

	return (
		<>
			<SuiteLoadingDialog/>
			<Card className = "border-0 rounded-0">
				{cardHeader}
				<form className = {classes.container} noValidate autoComplete = "off"
				      onSubmit = {submitForm}>
					<Card.Body>
						{props.error && <Alert elevation = {6} variant = "standard"
											   severity = "error" className = 'm-3'>
							{props.error}
						</Alert>}
						<Container>
							<Row>
								<Col>
									{name}
								</Col>
							</Row>
							<Row>
								<Col>
									{description}
								</Col>
							</Row>
						</Container>

					</Card.Body>

					{actions}
				</form>
				<Snackbar
					anchorOrigin = {{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open = {open}
					autoHideDuration = {2000}
					onClose = {handleClose}
					message = {alertMessage}
				>
					<Alert onClose = {handleClose} severity = "success">
						{alertMessage}
					</Alert>
				</Snackbar>
			</Card>
		</>
	)
}

export default AddForm;