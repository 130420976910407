import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@material-ui/core";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";

import * as actions from "../../store/actions";
import AddForm from "../../components/Test/AddForm";
import {changeDescription, changeText} from "./Shared";
import {UINames} from "./Messages";
import {checkValidity} from "../../utility/utility";
import {history} from "../../../redux/store";

const UpdateTest = (props) => {
	const intl = useIntl();
	const [name, setName] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 1,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [description, setDescription] = useState({
		value: '',
		validation:
			{
				maxLength: 200_000,
			},
		valid: true,
		validationMessage:
			null
	});

	const [previousNameAndDescription, setPreviousNameAndDescription] = useState({name: '', description: ''});

	useEffect(() => {
		if(props.test){
			const updatedName = {...name};
			updatedName.value = props.test.label;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = props.test.description ? props.test.description : '';
			setDescription(updatedDescription);

			// Save Original Values in case there is an error on update
			setPreviousNameAndDescription({name: props.test.label, description: props.test.description ? props.test.description : ''});

			document.title = intl.formatMessage({ id: "PAGE.TITLE.UPDATE.TEST",
				defaultMessage: 'Update Test: ' }) + props.test.label;
		}
	}, [props.test])

	useEffect(() => {
		if (props.error) {
			const updatedName = {...name};
			updatedName.value = previousNameAndDescription.name;
			setName(updatedName);

			const updatedDescription = {...description};
			updatedDescription.value = previousNameAndDescription.description;
			setDescription(updatedDescription);
		}
	}, [props.error]);

	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const descriptionChangeHandler = value => {
		const updatedDescription = changeDescription(description, value, 'description', intl);
		setDescription(updatedDescription);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Description
		const updatedDescription = {...description};
		[valid, message] = checkValidity(updatedDescription, UINames('description', intl), intl);
		updatedDescription.valid = valid;
		updatedDescription.validationMessage = message;
		setDescription(updatedDescription);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitTestHandler = (event) => {
		event.preventDefault();

		// validate all
		if (!checkValidityForAll()) {
			return null;
		}

		const test = {
			label: {value: name.value},
			description: {value: description.value}
		};

		props.onUpdateTest(props.test.id, test);
	}

	return (
		<>
			<div className = {`bg-white p-2 text-center`}>
				<Button variant = "contained"
				        size = 'small'
				        startIcon={<KeyboardReturn/>}
				        onClick = {(event) => {
					        event.preventDefault();
					        // means domain/id + state tab = 1
					        if(props.domain){
						        history.push('/domain/' + props.domain.id, {tab: 1});
					        } else {
						        history.goBack();
					        }
				        }}
				        className = ''>
					<FormattedMessage id="BUTTON.BACK"
					                  defaultMessage='Back'/>
				</Button>
			</div>
			<AddForm name={name}
					 description={description}
					 nameChangeHandler={nameChangeHandler}
					 descriptionChangeHandler={descriptionChangeHandler}
					 submitTestHandler={submitTestHandler}
					 error={props.error}
					 {...props}
					 type='update'/>
		         </>
	)
}

const mapStateToProps = state => {
	return {
		loading: state.test.updatingLoading,
		error: state.test.updatingError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateTest: (id, test) => dispatch(actions.updateTest(id, test)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTest);
