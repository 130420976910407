import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import {selectAddSuitesAction} from "./Shared";
import {checkArraySelectionValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import * as actions from "../../store/actions";
import IterationAddSuitesForm from "../../components/Iteration/IterationAddSuitesForm";

const AddSuitesToIterationForm = props => {
	const intl = useIntl();

	const [addSuites, setAddSuites] = useState({
		selected: [],
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		suites: []
	});

	useEffect(() => {
		if (props.project) {
			props.onProjectSuites(props.project.id);
		}
	}, [props.project]);

	useEffect(() => {
		if (props.project) {
			props.onProjectSuites(props.project.id);
		}
	}, []);

	useEffect(() => {

		if (props.projectSuites && props.suites) {
			const updatedAddSuites = {...addSuites};
			let allSuites = [...props.projectSuites];
			allSuites = allSuites.filter((suite, index) => {
				let inculde = true;
				for (const toRemoveSuite of props.suites) {
					if (suite.id === toRemoveSuite.id) {
						inculde = false;
						break;
					}
				}
				return inculde;
			});
			updatedAddSuites.suites = allSuites;
			setAddSuites(updatedAddSuites);
		}
	}, [props.projectSuites, props.suites]);

	const addSuitesSelectHandler = (event) => {
		const updatedAddSuites = selectAddSuitesAction(addSuites, event, intl);
		setAddSuites(updatedAddSuites);
	};

	const checkValidityForAll = () => {
		let allValid = true;

		const updatedAddSuites = {...addSuites};
		let [valid, message] = checkArraySelectionValidity(updatedAddSuites, 'selected',
			UINames('suite', intl), intl);
		updatedAddSuites.valid = valid;
		updatedAddSuites.validationMessage = message;
		setAddSuites(updatedAddSuites);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitAddSuitesHandler = () => {
		// validate all
		if (!checkValidityForAll()) {
			return false;
		}

		let existingSuites = [...props.suites];
		existingSuites.push(...addSuites.selected);
		props.setSuites(existingSuites);
		props.updateSuites(existingSuites);
		clear();
		return true;
	}

	const clear = () => {
		addSuites.selected = [];
		props.onProjectSuites(props.project.id);
		setAddSuites(addSuites);
	}

	return <IterationAddSuitesForm addSuites = {addSuites}
	                               addSuitesSelectHandler = {addSuitesSelectHandler}
	                               submitAddSuitesHandler = {submitAddSuitesHandler}
	/>
}

const mapStateToProps = state => {
	return {
		projectSuites: state.suite.projectSuites,
		project: state.project.project,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onProjectSuites: (id) => dispatch(actions.loadProjectSuites(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSuitesToIterationForm);