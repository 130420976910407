import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";

const CloseAccount = (props) => {
	const intl = useIntl();

	const [closingMessage, setClosingMessage] = useState(
		intl.formatMessage({id: "ACCOUNT.TERMINATION.CLOSING", defaultMessage: 'Closing...'})
	);

	useEffect(() => {
		document.title = intl.formatMessage({id: "PAGE.TITLE.ACCOUNT.TERMINATION", defaultMessage: 'Close Account'});
		const key = new URLSearchParams(props.location.search).get('key');
		if (key) {
			props.onCloseAccount(key);
		}
	}, []);

	useEffect(() => {
		if (props.closed === null) {
			setClosingMessage(intl.formatMessage({id: "ACCOUNT.TERMINATION.CLOSING", defaultMessage: 'Closing...'}));
		} else if (props.closed) {
			setClosingMessage(intl.formatMessage({id: "ACCOUNT.TERMINATION.SUCCESS", defaultMessage: 'Account Closed.'}));
		} else {
			setClosingMessage(intl.formatMessage({id: "ACCOUNT.TERMINATION.FAIL", defaultMessage: 'Account Closing Failed.'}));
		}
	}, [props.closed]);

	return <div className = "login-form login-signin" id = "kt_login_signin_form">
		<div className = "text-center mb-10 mb-lg-20">
			<h1 className = "font-size-h1">
				{closingMessage}
			</h1>
			{props.closed === false && <p className = "text-muted font-weight-bold">{props.error}</p>}

		</div>

	</div>;
}

const mapStateToProps = state => {
	return {
		// loading: state.authentication.loading,
		closed: state.users.closed,
		error: state.users.closedError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCloseAccount: (key) => dispatch(actions.closeAccount(key))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseAccount);