/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../_helpers";
import {setLanguage, useLang} from "../../../../i18n";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import {FormattedMessage} from "react-intl";
import * as actions from "../../../../../tigmat/store/actions";
import {connect} from "react-redux";

const languages = [
	{
		lang: "en",
		name: "English",
		flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
	},
	// {
	//   lang: "zh",
	//   name: "Mandarin",
	//   flag: toAbsoluteUrl("/media/svg/flags/015-china.svg"),
	// },
	// {
	//   lang: "es",
	//   name: "Spanish",
	//   flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
	// },
	// {
	//   lang: "ja",
	//   name: "Japanese",
	//   flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg"),
	// },
	// {
	//   lang: "de",
	//   name: "Deutsche",
	//   flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg"),
	// },
	{
		lang: "fr",
		name: "Français",
		flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
	},
	// {
	//   lang: "ar",
	//   name: "العربية",
	//   flag: toAbsoluteUrl("/media/svg/flags/166-morocco.svg"),
	// }
];

function LanguageSelectorDropdown(props) {
	const lang = useLang();
	const currentLanguage = languages.find((x) => x.lang === lang);

	const [refreshPageWhenSagaSucceed, setRefreshPageWhenSagaSucceed] = useState(false);
	const [newLanguage, setNewLanguage] = useState(null);

	useEffect(() => {
		if(refreshPageWhenSagaSucceed === true && props.languageUpdatingLoading === false){
			setRefreshPageWhenSagaSucceed(false);
			setLanguage(newLanguage);
		}
	}, [refreshPageWhenSagaSucceed, props.languageUpdatingLoading]);

	return (
		<Dropdown drop="down" alignRight={!props.noAlign}>
			<Dropdown.Toggle
				as={DropdownTopbarItemToggler}
				id="dropdown-toggle-my-cart"
			>
				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip id="language-panel-tooltip">
							<FormattedMessage id="TOPBAR.SELECT.LANGUAGE"
											  defaultMessage='Select Language'/></Tooltip>
					}
				>
					<div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
						<img
							className="h-25px w-25px rounded"
							src={currentLanguage.flag}
							alt={currentLanguage.name}
						/>
					</div>
				</OverlayTrigger>
			</Dropdown.Toggle>
			<Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
				<ul className="navi navi-hover py-4">
					{languages.map((language) => (
						<li
							key={language.lang}
							className={clsx("navi-item", {
								active: language.lang === currentLanguage.lang,
							})}
						>
							<a
								href="#"
								onClick={() => {
									// if loggedin user call saga
									if(props.loggedInUser !== null){
										props.onChangeLanguage(props.loggedInUser.id, language.lang);
										setRefreshPageWhenSagaSucceed(true);
										setNewLanguage(language.lang);
									} else {
										setLanguage(language.lang);
									}
								}}
								className="navi-link"
							>
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name}/>
                </span>
								<span className="navi-text">{language.name}</span>
							</a>
						</li>
					))}
				</ul>
			</Dropdown.Menu>
		</Dropdown>
	);
}

const mapStateToProps = state => {
	return {
		loggedInUser: state.authentication.loggedInUser,
		languageUpdatingLoading: state.users.languageUpdatingLoading,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChangeLanguage: (userId, lang) => dispatch(actions.changeLanguage(userId, lang)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorDropdown);
