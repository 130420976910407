import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../../axios/axios";

export function* loadSummaryChartData(action) {
	yield put(actions.loadSummaryChartDataStart());
	try {
		// 1st call: bar chart
		const lastFiveExecutionsResponse = yield axios.get(`/reporting/project/${action.projectID}/list`);
		// 2nd call: pie chart
		const lastExecutionResponse = yield axios.get(`/reporting/project/${action.projectID}/last`);
		yield put(actions.loadSummaryChartDataSuccess(lastFiveExecutionsResponse.data,
			lastExecutionResponse.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadSummaryChartDataFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadSummaryChartDataFail('Error Loading Summary Summary Data, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadSummaryChartDataFail(error.message));
		}
	}
}

export function* loadDashboardStats(action) {
	yield put(actions.loadDashboardStatsStart());
	try {
		const response = yield axios.get(`/reporting/users/${action.userId}/projects/${action.projectId}`);
		// console.log('loadDashboardStats response.data', response.data);
		yield put(actions.loadDashboardStatsSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadDashboardStatsFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadDashboardStatsFail('Error Loading Dashboard Stats, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadDashboardStatsFail(error.message));
		}
	}
}