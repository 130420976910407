import {Alert} from "@material-ui/lab";
import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

import NormalUserForm from "../Users/NormalUserForm";
import RegistrationActions from "./RegistrationActions";
import NormalUserAddressForm from "../Users/NormalUserAddressForm";
import {RegistrationLoadingDialog} from "../../UI/LoadingDialog";

// const SITE_KEY = "6Lduu6QUAAAAADQo9CWQ3HETbUBtxO-88OhalKMb";  old

const RegistrationDisplay = props => {

	const [termsAccepted, setTermsAccepted] = useState(false);
	const [showTermsError, setShowTermsError] = useState(false);

	const toggleAcceptTerms = (checked) =>{
		setTermsAccepted(checked);
	}

	let termsErrorMessage = null;
	if(showTermsError === true){
		termsErrorMessage = <Alert elevation = {3} variant = "standard" severity = "error" className = 'my-4'>
			<FormattedMessage id="REGISTRATION.AGREE.TERMS.ERROR" defaultMessage='You must accept the terms'/>
		</Alert>;
	}


	props.registerTermsAcceptedCallback(() => {
		setShowTermsError(!termsAccepted);
		return termsAccepted
	});

	let errorMessages = props.errorMessages.map((error, index) => {
		return <Alert elevation = {3} variant = "standard" key={index}
		              severity = "error" className = 'my-4'>
			{error}
		</Alert>
	});

	return (
		// <div className = {``}>
		<div className = "login-form login-signin" style = {{display: "block",maxWidth: '800px'}}>
			 		<div className = "text-center mb-10 ">
			 			<h3 className = "font-size-h1">
			 				<FormattedMessage id="REGISTRATION.TITLE"
			 				                  defaultMessage='Sign Up'/>
			 			</h3>
			 			<p className = "text-muted font-weight-bold">
			 				<FormattedMessage id="REGISTRATION.INPUT.DETAILS"
			 				                  defaultMessage='Enter your details to create your account'/>
			 			</p>
			 		</div>

					<form id = "kt_login_signin_form"
					      className = "form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
					      >

						{/*{props.error ?*/}
						{/*	<div className = "mb-10 alert alert-custom alert-light-danger alert-dismissible">*/}
						{/*		<div className = "alert-text font-weight-bold">{props.error}</div>*/}
						{/*	</div> : null*/}
						{/*}*/}
						{errorMessages}
						<RegistrationLoadingDialog/>
						<Container>
			                <Row>
			                    <Col
									// md={6} xs={12}
								>

				                    <NormalUserForm type='create' loggedInUser={null}
					                    // type create or update for address
					                                registerValidateNormalUserFormCallback={props.registerValidateNormalUserFormCallback}
					                                username = {props.username} setUsername = {props.setUsername}
					                                // firstName = {props.firstName} setFirstName = {props.setFirstName}
					                                // lastName = {props.lastName} setLastName = {props.setLastName}
													company = {props.company} setCompany = {props.setCompany}
					                                email = {props.email} setEmail = {props.setEmail}
					                    // authorities={props.authorities} setAuthorities={props.setAuthorities}

					                                includeAddress={true} setIncludeAddress={null}
					                                includeRoles={false}
					                                password={props.password} setPassword={props.setPassword}
				                    />
									<NormalUserAddressForm type={null} loggedInUser={null}
														   includeAddress={props.includeAddress} setIncludeAddress={props.setIncludeAddress}
										// streetNumber = {props.streetNumber} setStreetNumber = {props.setStreetNumber}
										// route = {props.route} setRoute = {props.setRoute}
										// locality = {props.locality} setLocality = {props.setLocality}
										// postalCode = {props.postalCode} setPostalCode = {props.setPostalCode}
										// state = {props.state} setState = {props.setState}
														   country={props.country} setCountry={props.setCountry}
														   registerValidateNormalUserAddressFormCallback={props.registerValidateNormalUserAddressFormCallback}
									/>
									<div className="form-group">
										<label className="checkbox">
											<input
												type="checkbox" checked={termsAccepted}
												name="acceptTerms"
												className="m-1"
												onChange={(event) => toggleAcceptTerms(event.target.checked)}
											/>
											<a href="https://tigmat.io/legal/terms.html" target="_blank" className="mr-1" rel="noopener noreferrer">
												<FormattedMessage id="REGISTRATION.AGREE.TERMS" defaultMessage='I agree to the Terms & Conditions'/>
											</a>
											<span />
										</label>
									</div>
									{termsErrorMessage}
			                    </Col>
				                {/*<Col md={6} xs={12}>*/}
					            {/*    */}
				                {/*</Col>*/}
			                </Row>
							{/*<Row>*/}
							{/*	<Col>*/}
							{/*		<div className="g-recaptcha" data-sitekey={SITE_KEY} >*/}

							{/*		</div>*/}
							{/*	</Col>*/}
							{/*</Row>*/}
						</Container>

						{props.captchaErrorMsg}

						{props.userRegistered ?
							<div className = "my-6 alert alert-custom alert-light-success alert-dismissible">
								<div className = "alert-text font-weight-bold">
									<FormattedMessage id = "REGISTRATION.INPUT.SUCCESS"
									                  defaultMessage = "You're successfully registered.
									                  Check you email to activate your account.
									                  (note: you cannot login until you activate your account!)."/>
								</div>
							</div> : null
						}



						<RegistrationActions registrationLoading={props.registrationLoading}/>
					</form>
			</div>
	);
}

export default RegistrationDisplay;