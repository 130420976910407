import {put} from "redux-saga/effects";
import * as actions from "../actions";
import axios, {backendUrl} from "../../axios/axios";
import {loadSprint} from "./sprint";

export function* createIteration(action) {
	yield put(actions.createIterationStart());
	try {
		const response = yield axios.post(`/iterations`, action.iteration);
		if (response.status === 201) {
			yield put(actions.createIterationSuccess(response.data));
			yield loadSprint({id: action.sprintID});
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.createIterationFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.createIterationFail('Error Creating Iteration, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.createIterationFail(error.message));
		}
	}
}

export function* loadIteration(action) {
	yield put(actions.loadIterationStart());
	try {
		const response = yield axios.get(`/iterations/${action.id}`);
		yield put(actions.loadIterationSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.loadIterationFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.loadIterationFail('Error Loading Iteration, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.loadIterationFail(error.message));
		}
	}
}

export function* updateIteration(action) {
	yield put(actions.updateIterationStart());
	try {
		const response = yield axios.patch(`/iterations/${action.id}`, action.iteration);

		yield put(actions.updateIterationSuccess());

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.updateIterationFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.updateIterationFail('Error Updating Iteration, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.updateIterationFail(error.message));
		}
	}
}

export function* executeIteration(action) {
	yield put(actions.executeIterationStart(action.id, action.projectId));
	try {
		const noTimeoutAxios = axios.create({
			baseURL: backendUrl,
			timeout: 0, // no timeout
		});
		noTimeoutAxios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');
		const response = yield noTimeoutAxios.put(`/execute/${action.id}`);
		// console.log('executeIteration response', response.data)
		yield put(actions.executeIterationSuccess());
		// yield loadSprint({id: action.sprintID});

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.executeIterationFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.executeIterationFail('Error Executing Iteration, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.executeIterationFail(error.message));
		}
	}
}

export function* canCreateIteration(action) {
	yield put(actions.canCreateIterationStart());
	try {
		const response = yield axios.get(`/iterations/can-add`);
		// console.log('response', response.data);

		yield put(actions.canCreateIterationSuccess(response.data));

	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.canCreateIterationFail(error.response.data.message, action.view));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.canCreateIterationFail('Error Finding Out Iteration Creation Possibility, Try Again', action.view));
		} else {
			console.log('Error', error.message);
			yield put(actions.canCreateIterationFail(error.message, action.view));
		}
	}
}