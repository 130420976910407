import React from "react";
import {AppBar, Divider, Tab, Tabs} from "@material-ui/core";
import {useIntl} from "react-intl";

import SprintIterationActions from "./SprintIterationActions";
import SprintIterationsDisplay from "./SprintIterationsDisplay";
import UpdateSprint from "../../containers/Sprint/UpdateSprint";
import TabPanel from "../../UI/TabPanel";

const SprintDisplayTabs = props => {
	const intl = useIntl();

	const [tab, setTab] = React.useState(props.tab);

	function tabChangeHandler(event, newValue) {
		setTab(newValue);
	}

	let sprintActions = <SprintIterationActions {...props} />;
	// let sprintActions = <h6>Add Iteration</h6>;
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		sprintActions = null;
	}

	// set actions to null if an iteration is running
	if(props.sprint && props.sprint.iterations){
		for(let it of props.sprint.iterations){
			if(it.state === 'EXECUTE'){
				// console.log('set sprintActions = null');
				sprintActions = null;
				break;
			}
		}
	}


	return (
		<>
			<AppBar position = "static" color='transparent' elevation={0}>
				<Tabs value = {tab} onChange = {tabChangeHandler} >
					<Tab label = {intl.formatMessage({ id: "SPRINT.TAB.INFORMATIONS",
						defaultMessage: 'Informations'})}/>
					<Tab label = {intl.formatMessage({ id: "SPRINT.TAB.ITERATION.CASES",
						defaultMessage: 'Iterations'})}/>
				</Tabs>
			</AppBar>

			<TabPanel value={tab} index={0} py={0}>
				<UpdateSprint {...props}/>
			</TabPanel>
			<TabPanel value={tab} index={1} py={0}>
				<>
					{sprintActions}
					<Divider/>
					<SprintIterationsDisplay {...props}
					                         sprintName = {props.sprint ? props.sprint.label : ''}
						// deleteIteration = {props.deleteIteration}
						// tests = {props.tests}
						// setIterations = {props.setIterations}
						// updateIterations = {props.updateIterations}
					/>

				</>
			</TabPanel>
		</>
	)
}

export default SprintDisplayTabs;