import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";
import {DELETE_USER_NULLIFY_ERROR, NULLIFY_CICD_KEYS} from "../actions/actionTypes";

export const initialState = {
	creationLoading: false,
	creationError: null,

	updatingLoading: false,
	updatingError: null,

	listAllLoading: false,
	listAllError: null,

	loading: false,
	error: null,
	users: [], // TODO to null
	user: null,

	totalRecords: 0,
	refreshList: false,

	allUsers: null,
	allUsersLoading: false,
	allUsersError: null,

	updatingMyAccountLoading: false,
	updatingMyAccountError: false,

	// Preferred Project
	updatingPreferredLoading: false,
	updatingPreferredError: null,

	// can add
	canAdd: false,
	canAddLoading: false,
	canAddError: null,

	deletionLoading: false,
	deletionError: null,

	requestClosingAccountSuccess: null,    // null no request issued
	requestClosingAccountLoading: false,
	requestClosingAccountError: null,

	closed: null,
	closedLoading: false,
	closedError: null,

	profile: null,
	profileLoading: false,
	profileError: null,

	languageUpdatingLoading: false,
	languageUpdatingError: null,

	CICDKeys: null,
	generatingCICDKeysLoading: false,
	generatingCICDKeysError: null,
	secretKey: null,
	secretKeyLoading: false,
	secretKeyError: null,

	deletingCICDKeysLoading: false,
	deletingCICDKeysError: null,
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.ON_LOAD_USERS_START:
			return updateObject(state, {
				listAllLoading: true,
				refreshList: false
			});
		case actionTypes.ON_LOAD_USERS_SUCCESS:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: null,
				users: action.users,
				totalRecords: action.totalRecords
			});
		case actionTypes.ON_LOAD_USERS_FAIL:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: action.error,
				users: []
			});
		case actionTypes.LOAD_ALL_USERS_START:
			return updateObject(state, {
				allUsers: null,
				allUsersLoading: true,
				allUsersError: null
			});
		case actionTypes.LOAD_ALL_USERS_SUCCESS:
			return updateObject(state, {
				allUsers: action.users,
				allUsersLoading: false,
				allUsersError: null
			});
		case actionTypes.LOAD_ALL_USERS_FAIL:
			return updateObject(state, {
				allUsers: null,
				allUsersLoading: false,
				allUsersError: action.error
			});
		case actionTypes.ON_LOAD_USER_START:
			return updateObject(state, {
				loading: true,
				deletionError: null
			});
		case actionTypes.ON_LOAD_USER_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				user: action.user
			});
		case actionTypes.ON_LOAD_USER_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				user: null
			});
		case actionTypes.CHANGE_ACTIVATION_STATUS_START:
			return updateObject(state, {
				loading: true,
				error: null
			});
		case actionTypes.CHANGE_ACTIVATION_STATUS_SUCCESS:
			// Two possibilities
			// reload /users
			// simply find and change the boolean in the list
			if(action.users === true){
				let copy = [...state.users];
				let index = -1;
				let updatedUser = null;
				// Problem here
				for(let i in copy){
					if(action.id === copy[i].id){
						index = i;
						updatedUser = {...copy[i]};
						updatedUser.activated = !updatedUser.activated;
						break;
					}
				}
				copy[index] = updatedUser;
				return updateObject(state, {
					users: copy,
					loading: false,
					error: null
				});
			} else {
				// reload /user
				// simply take the current user from state. switch the boolean
				let updatedUser = {...state.user};
				updatedUser.activated = !updatedUser.activated;
				return updateObject(state, {
					user: updatedUser,
					loading: false,
					error: null
				});
			}
		case actionTypes.CHANGE_ACTIVATION_STATUS_FAIL:
			return updateObject(state, {
				loading: false,
				error: action.error,
			});
		case actionTypes.REGISTER_ACCOUNT_USER_START:
			return updateObject(state, {
				creationLoading: true
			});
		case actionTypes.REGISTER_ACCOUNT_USER_SUCCESS:
			return updateObject(state, {
				creationLoading: false,
				creationError: null
			});
		case actionTypes.REGISTER_ACCOUNT_USER_FAIL:
			return updateObject(state, {
				creationLoading: false,
				creationError: action.error
			});
		case actionTypes.REGISTER_ACCOUNT_USER_CICD_START:
			return updateObject(state, {
				creationLoading: true
			});
		case actionTypes.REGISTER_ACCOUNT_USER_CICD_SUCCESS:
			return updateObject(state, {
				creationLoading: false,
				creationError: null
			});
		case actionTypes.REGISTER_ACCOUNT_USER_CICD_FAIL:
			return updateObject(state, {
				creationLoading: false,
				creationError: action.error
			});
		case actionTypes.UPDATE_USER_START:
			return updateObject(state, {
				updatingLoading: true
			});
		case actionTypes.UPDATE_USER_SUCCESS:
			return updateObject(state, {
				updatingLoading: false,
				user: action.user,
				updatingError: null
			});
		case actionTypes.UPDATE_USER_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error
			});
		case actionTypes.UPDATE_USER_CICD_START:
			return updateObject(state, {
				updatingLoading: true
			});
		case actionTypes.UPDATE_USER_CICD_SUCCESS:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: null,
				user: action.user
			});
		case actionTypes.UPDATE_USER_CICD_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error
			});
		case actionTypes.DELETE_USER_START:
			return updateObject(state, {
				deletionLoading: true,
				deletionError: null
			});
		case actionTypes.DELETE_USER_SUCCESS:
			let usersCopy = [...state.users];
			usersCopy = usersCopy.filter((user) => {
				if(user.id !== action.id){
					return true;
				}
			});
			return updateObject(state, {
				deletionLoading: false,
				users: usersCopy,
				refreshList: true
			});
		case actionTypes.DELETE_USER_FAIL:
			return updateObject(state, {
				deletionLoading: false,
				deletionError: action.error,
			});
		case actionTypes.UPDATE_USER_MY_ACCOUNT_START:
			return updateObject(state, {
				updatingMyAccountLoading: true,
				updatingMyAccountError: null,
			});
		case actionTypes.UPDATE_USER_MY_ACCOUNT_SUCCESS:
			return updateObject(state, {
				updatingMyAccountLoading: false,
				updatingMyAccountError: null,
			});
		case actionTypes.UPDATE_USER_MY_ACCOUNT_FAIL:
			return updateObject(state, {
				updatingMyAccountLoading: false,
				updatingMyAccountError: action.error,
			});
		case actionTypes.UPDATE_PREFERRED_PROJECT_START:
			return updateObject(state, {
				updatingPreferredLoading: true,
				updatingPreferredError: null
			});
		case actionTypes.UPDATE_PREFERRED_PROJECT_SUCCESS:
			return updateObject(state, {
				updatingPreferredLoading: false,
				updatingPreferredError: null
			});
		case actionTypes.UPDATE_PREFERRED_PROJECT_FAIL:
			return updateObject(state, {
				updatingPreferredLoading: false,
				updatingPreferredError: action.error
			});
		case actionTypes.CAN_CREATE_USER_START:
			return updateObject(state, {
				canAdd: false,
				canAddLoading: true,
				canAddError: null
			});
		case actionTypes.CAN_CREATE_USER_SUCCESS:
			return updateObject(state, {
				canAdd: action.canAdd,
				canAddLoading: false,
				canAddError: null
			});
		case actionTypes.CAN_CREATE_USER_FAIL:
			return updateObject(state, {
				canAddLoading: false,
				canAddError: action.error
			});
		case actionTypes.DELETE_USER_NULLIFY_ERROR:
			return updateObject(state, {
				deletionError: null,
			});
		case actionTypes.REQUEST_DELETE_MY_ACCOUNT_START:
			return updateObject(state, {
				requestClosingAccountSuccess: null,
				requestClosingAccountLoading: true,
				requestClosingAccountError: null,
			});
		case actionTypes.REQUEST_DELETE_MY_ACCOUNT_SUCCESS:
			return updateObject(state, {
				requestClosingAccountSuccess: true,
				requestClosingAccountLoading: false,
			});
		case actionTypes.REQUEST_DELETE_MY_ACCOUNT_FAIL:
			return updateObject(state, {
				requestClosingAccountSuccess: false,
				requestClosingAccountLoading: false,
				requestClosingAccountError: action.error,
			});
		case actionTypes.CLOSE_ACCOUNT_START:
			return updateObject(state, {
				closed: null,
				closedLoading: true,
				closedError: null,
			});
		case actionTypes.CLOSE_ACCOUNT_SUCCESS:
			return updateObject(state, {
				closed: true,
				closedLoading: false,
			});
		case actionTypes.CLOSE_ACCOUNT_FAIL:
			return updateObject(state, {
				closed: false,
				closedLoading: false,
				closedError: action.error
			});
		case actionTypes.CHANGE_LANGUAGE_START:
			return updateObject(state, {
				languageUpdatingLoading: true,
				languageUpdatingError: null,
			});
		case actionTypes.CHANGE_LANGUAGE_SUCCESS:
			return updateObject(state, {
				languageUpdatingLoading: false,
			});
		case actionTypes.CHANGE_LANGUAGE_FAIL:
			return updateObject(state, {
				languageUpdatingLoading: false,
				languageUpdatingError: action.error
			});
		case actionTypes.GENERATE_CICD_KEYS_START:
			return updateObject(state, {
				CICDKeys: null,
				generatingCICDKeysLoading: true,
				generatingCICDKeysError: null,
			});
		case actionTypes.GENERATE_CICD_KEYS_SUCCESS:
			return updateObject(state, {
				CICDKeys: action.keys,
				generatingCICDKeysLoading: false,
			});
		case actionTypes.GENERATE_CICD_KEYS_FAIL:
			return updateObject(state, {
				generatingCICDKeysLoading: false,
				generatingCICDKeysError: action.error
			});
		case actionTypes.NULLIFY_CICD_KEYS:
			return updateObject(state, {
				CICDKeys: null,
				secretKey: null,
			});
		case actionTypes.LOAD_CICD_SECRET_KEY_START:
			return updateObject(state, {
				secretKey: null,
				secretKeyLoading: true,
				secretKeyError: null,
			});
		case actionTypes.LOAD_CICD_SECRET_KEY_SUCCESS:
			return updateObject(state, {
				secretKey: action.secret,
				secretKeyLoading: false,
			});
		case actionTypes.LOAD_CICD_SECRET_KEY_FAIL:
			return updateObject(state, {
				secretKeyLoading: false,
				secretKeyError: action.error,
			});
		case actionTypes.DELETE_CICD_KEYS_START:
			return updateObject(state, {
				deletingCICDKeysLoading: true,
				deletingCICDKeysError: null
			});
		case actionTypes.DELETE_CICD_KEYS_SUCCESS:
			return updateObject(state, {
				CICDKeys: null,
				deletingCICDKeysLoading: false
			});
		case actionTypes.DELETE_CICD_KEYS_FAIL:
			return updateObject(state, {
				deletingCICDKeysLoading: false,
				deletingCICDKeysError: action.error
			});
		default: {
			return state;
		}
	}
};

export default reducer;