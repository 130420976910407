import * as actionTypes from "./actionTypes";

export const login = (user, dev = false) => {
	return {
		type: actionTypes.LOGIN,
		user: user,
		dev: dev
	}
};

export const loginStart = () => {
	return {
		type: actionTypes.LOGIN_START
	}
};

export const loginSuccess = (username, loggedInUser) => {
	return {
		type: actionTypes.LOGIN_SUCCESS,
		username: username,
		loggedInUser: loggedInUser
	}
};

export const loginFail = (error) => {
	return {
		type: actionTypes.LOGIN_FAIL,
		error: error
	}
};

export const authCheckState = () => {
	return {
		type: actionTypes.AUTH_CHECK_STATE
	};
}

export const authCheckStateFinished = () => {
	return {
		type: actionTypes.AUTH_CHECK_STATE_FINISHED
	};
}

export const logout = (qureyParams) => {
	return {
		type: actionTypes.LOGOUT,
		qureyParams: qureyParams
	}
};

// export const logoutStart = () => {
// 	return {
// 		type: actionTypes.LOGOUT_START
// 	}
// };

export const logoutSuccess = () => {
	return {
		type: actionTypes.LOGOUT_SUCCESS
	}
};

// export const logoutFail = (error) => {
// 	return {
// 		type: actionTypes.LOGOUT_FAIL
// 	}
// };

export const register = (user) => {
	return {
		type: actionTypes.REGISTER,
		user: user
	}
};

export const registerStart = () => {
	return {
		type: actionTypes.REGISTER_START
	}
};

export const registerSuccess = () => {
	return {
		type: actionTypes.REGISTER_SUCCESS
	}
};

export const registerFail = (error) => {
	return {
		type: actionTypes.REGISTER_FAIL,
		error: error
	}
};

export const fireTimeout = expirationTime => {
	return {
		type: actionTypes.TIMEOUT,
		expirationTime: expirationTime
	};
};

export const resetPassword = (body) => {
	return {
		type: actionTypes.RESET_PASSWORD,
		body: body
	}
};

export const resetPasswordStart = () => {
	return {
		type: actionTypes.RESET_PASSWORD_START
	}
};

export const resetPasswordSuccess = () => {
	return {
		type: actionTypes.RESET_PASSWORD_SUCCESS
	}
};

export const resetPasswordFail = (error) => {
	return {
		type: actionTypes.RESET_PASSWORD_FAIL,
		error: error
	}
};

export const changePassword = (body) => {
	return {
		type: actionTypes.CHANGE_PASSWORD,
		body: body
	}
};

export const changePasswordStart = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_START
	}
};

export const changePasswordSuccess = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_SUCCESS
	}
};

export const changePasswordFail = (error) => {
	return {
		type: actionTypes.CHANGE_PASSWORD_FAIL,
		error: error
	}
};

export const resetPasswordChangeSuccessMessageTimeout = delay => {
	return {
		type: actionTypes.CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT,
		delay: delay
	};
};

export const resetPasswordChangeSuccessMessageTimeoutExecute = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT_EXECUTE
	};
};

export const activateAccount = (key) => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT,
		key: key
	}
};

export const activateAccountStart = () => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT_START
	}
};

export const activateAccountSuccess = (activated) => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT_SUCCESS,
		activated: activated
	}
};

export const activateAccountFail = (error) => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT_FAIL,
		error: error
	}
};

export const forgotPassword = (email) => {
	return {
		type: actionTypes.FORGOT_PASSWORD,
		email: email
	}
};

export const forgotPasswordStart = () => {
	return {
		type: actionTypes.FORGOT_PASSWORD_START
	}
};

export const forgotPasswordSuccess = () => {
	return {
		type: actionTypes.FORGOT_PASSWORD_SUCCESS
	}
};

export const forgotPasswordFail = (error) => {
	return {
		type: actionTypes.FORGOT_PASSWORD_FAIL,
		error: error
	}
};