import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import UsersDisplay from "../../components/Users/UsersDisplay";

const Users  = props => {
	const intl = useIntl();

	// const [mainType, setMainType] = useState(null);

	const [users, setUsers] = useState(props.users);
	const [totalUsers, setTotalUsers] = useState(props.totalUsers);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 10,
		page: 0,
		pageCount: 1,
		sortField: 'login',
		sortOrder: 1
	});

	const [filterRole, setFilterRole] = useState(null);
	const [filterStatus, setFilterStatus] = useState(null);
	const dt = useRef(null);

	const filterTimeout = useRef(0);
	// This Effect runs when the user visits: /users , because primereact sets lazyParams on Table render.
	useEffect(() => {
		clearTimeout(filterTimeout.current);
		filterTimeout.current = setTimeout(() => {
			props.onLoadAllUsers(localStorage.getItem("username"),
				createLazyParamsForBackend()
			);
			props.onCanCreateUser();
		}, 500);
	}, [lazyParams]);

	const createLazyParamsForBackend = () => {
		const params = {};
		params.size = lazyParams.rows;
		// convert first to page => 0 => 0, 15 => 1, 30 => 2
		params.page = lazyParams.first / lazyParams.rows;
		// console.log('lazyParams.filters', lazyParams.filters);
		if(lazyParams.filters){
			Object.keys(lazyParams.filters).forEach(filter => {
				if(filter === 'activated'){
					params[filter] = lazyParams.filters[filter].value === 'ACTIVE' ? true : false;
					return;
				}
				// if(filter === 'roles'){
				// 	let methodsAsString = '';
				// 	lazyParams.filters[filter].value.forEach((value) => methodsAsString = methodsAsString + value + ',');
				// 	params[filter] = methodsAsString;
				// } else {
					// all text + roles + status
					params[filter] = lazyParams.filters[filter].value;
				// }
			});
		}

		if(lazyParams.hasOwnProperty('sortField')){
			params.sortField = lazyParams.sortField;
			if (lazyParams.sortOrder === 1) {
				params.sortOrder = 'ASC';
			} else {
				params.sortOrder = 'DESC';
			}
		}

		// console.log('createLazyParamsForBackend params', params);
		return params;
	}

	useEffect(() => {
		setUsers(props.users);
	}, [props.users]);

	useEffect(() => {
		setTotalUsers(props.totalUsers);
	}, [props.totalUsers]);

	useEffect(() => {
		if(props.refreshList === true){
			props.onLoadAllUsers(localStorage.getItem("username"),
				createLazyParamsForBackend()
			);
			props.onCanCreateUser();
		}
	}, [props.refreshList]);

	const onPage = (event) => {
		// console.log('onPage event:', event)
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		// console.log('onSort event:', event)
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onFilter = (event) => {
		// console.log('onFilter event:', event)
		let _lazyParams = { ...lazyParams, ...event };
		_lazyParams['first'] = 0;
		setLazyParams(_lazyParams);
	}

	const onRoleChange = (e) => {
		// console.log('onRoleChange event:', e);
		dt.current.filter(e.value, 'authorities', 'in');
		setFilterRole(e.value);
	}

	const onStatusChange = (e) => {
		// console.log('onStatusChange event:', e);
		dt.current.filter(e.value, 'activated', 'in');
		setFilterStatus(e.value);
		// backend: value, takes it if not present then no filter
	}

	const disableToggle = (user) => {
		props.onToggleActivation(user.login, !user.activated, true, user.id);
	}

	// useEffect(() => {
	// 	if(props.users && props.users.length !== 0){
	// 		// console.log('props.users', props.users);
	// 		const topUser = getTopUser(props.users);
	// 		if (topUser.authorities.indexOf(Role.ROLE_SUPER) >= 0) { // normally the oldest is the top dog
	// 			setMainType(Role.ROLE_SUPER);
	// 		} else if (topUser.authorities.indexOf(Role.ROLE_ADMIN) >= 0) {
	// 			setMainType(Role.ROLE_ADMIN);
	// 		} else if (topUser.authorities.indexOf(Role.ROLE_MANAGER) >= 0) {
	// 			setMainType(Role.ROLE_MANAGER);
	// 		} else if (topUser.authorities.indexOf(Role.ROLE_TESTER) >= 0) {
	// 			setMainType(Role.ROLE_TESTER);
	// 		} else if (topUser.authorities.indexOf(Role.ROLE_USER) >= 0) {
	// 			setMainType(Role.ROLE_USER);
	// 		}
	// 	}
	// }, [props.users]);

	useEffect(() => {
		// props.onLoadAllUsers(localStorage.getItem("username"), createLazyParamsForBackend());
		document.title = intl.formatMessage({ id: "PAGE.TITLE.USERS", defaultMessage: 'Users' });
	},[]);


	return <UsersDisplay mainType={localStorage.getItem('Top-Role')}
	                     disableToggle={disableToggle}
	                     onDeleteUser={props.onDeleteUser}
	                     users={users}
	                     loggedInUser={props.loggedInUser}
	                     onFilter={onFilter}
	                     onSort={onSort}
	                     onPage={onPage}
	                     totalUsers={totalUsers}
	                     lazyParams={lazyParams}
	                     dt={dt}
	                     filterRole={filterRole}
	                     filterStatus={filterStatus}
	                     onRoleChange={onRoleChange}
	                     onStatusChange={onStatusChange}
	                     loading={props.loading}
						 canAdd={props.canAdd}
						 canAddLoading={props.canAddLoading}
						 canAddError={props.canAddError}
						 deletionError={props.deletionError}
						 onHideDeletionError={props.onHideDeletionError}

	/>
}

const mapStateToProps = state => {
	return {
		username: state.authentication.username,
		users: state.users.users,
		totalUsers: state.users.totalRecords,
		refreshList: state.users.refreshList,
		loading: state.users.listAllLoading,
		usersTableProperties: state.settings.usersTableProperties,
		loggedInUser: state.authentication.loggedInUser,
		canAdd: state.users.canAdd,
		canAddLoading: state.users.canAddLoading,
		canAddError: state.users.canAddError,
		deletionError: state.users.deletionError
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadAllUsers: (username, config) => dispatch(actions.loadUsers(username, config)),
		onToggleActivation: (username, active, users, id) => dispatch(actions.toggleActivation(username, active, users, id)),
		onDeleteUser: (id) => dispatch(actions.deleteUser(id)),
		onSetUsersTableProperties: (first, rows) => dispatch(actions.setUsersTableProperties(first, rows)),
		onCanCreateUser: () => dispatch(actions.canCreateUser()),
		onHideDeletionError: () => dispatch(actions.deleteUserNullifyError()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);