import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AppBar, Button, Dialog, Slide, Toolbar, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";

import DeviceGroup from "../../containers/Project/Device/DeviceGroup";
import * as actions from "../../store/actions";
import {DeviceGroupCreationLoadingDialog} from "../../UI/LoadingDialog";

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const DeviceGroupDialogContainerForProject = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [showDeviceGroupDialog, setShowDeviceGroupDialog] = useState(false);

	const hideDeviceGroupDialog = () => {
		setShowDeviceGroupDialog(false);
	}

	const createGroupRef = useRef();
	const registerCreateGroupCallback = callback => {
		createGroupRef.current = callback;
	};

	// this is called by initiateSave
	const saveDeviceGroup = (group) => {
		if(props.forIteration === true){
			props.onSaveDeviceGroupForIteration(props.iterationId, group, true, props.projectPref.id);
		} else {
			if(props.projectView.id === props.projectPref.id) {
				props.onSaveDeviceGroup(props.projectView.id, group, true);
			} else {
				props.onSaveDeviceGroup(props.projectView.id, group, false);
			}
		}
	}

	useEffect(() => {
		if(props.createDeviceGroupLoading === true){
			setStartedSaga(true);
		}
	}, [props.createDeviceGroupLoading])

	const [startedSaga, setStartedSaga] = useState(false);

	useEffect(() => {
		if(startedSaga === true && props.incompatibleDeviceGroup === null && props.createDeviceGroupLoading === false
			&& props.createDeviceGroupError === null){
			hideDeviceGroupDialog();
		}
	}, [startedSaga, props.incompatibleDeviceGroup, props.createDeviceGroupLoading, props.createDeviceGroupError])

	const initiateSave = () => {
		createGroupRef.current();
	}

	return <>
		<Button variant='contained' color='primary' size='large' onClick={() => setShowDeviceGroupDialog(true)}>
			<FormattedMessage id = "DEVICE.GROUP.ENTRY.BUTTON.NEW" defaultMessage = 'Add New Device Group'/>
		</Button>

		<Dialog fullScreen open = {showDeviceGroupDialog} onClose = {hideDeviceGroupDialog} TransitionComponent = {Transition} style={{zIndex: 900}}>
			<DeviceGroupCreationLoadingDialog/>
			<AppBar className = {classes.appBar}>
				<Toolbar>
					<IconButton edge = "start" color = "inherit" onClick = {hideDeviceGroupDialog} aria-label = "close">
						<CloseIcon/>
					</IconButton>
					<Typography variant = "h6" className = {classes.title}>
						<FormattedMessage id = "DEVICE.GROUP.DIALOG.TITLE.NEW" defaultMessage = 'New Device Group'/>
					</Typography>
					<Button autoFocus color = "inherit" onClick = {initiateSave} disabled={props.createDeviceGroupLoading}>
						<FormattedMessage id="BUTTON.SAVE" defaultMessage='Save'/>
					</Button>
				</Toolbar>
			</AppBar>
			<DeviceGroup save={saveDeviceGroup} registerSaveGroupCallback={registerCreateGroupCallback}/>
		</Dialog>
	</>
}

const mapStateToProps = state => {
	return {
		createDeviceGroupLoading: state.project.createDeviceGroupLoading,
		createDeviceGroupError: state.project.createDeviceGroupError,
		incompatibleDeviceGroup: state.project.incompatibleDeviceGroup,
		projectView: state.project.projectView,
		projectPref: state.project.project,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSaveDeviceGroup: (projectId, group, both) => dispatch(actions.createDeviceGroup(projectId, group, false, null, both)),
		onSaveDeviceGroupForIteration: (iterationId, group, forIteration= false, projectId ) =>
			dispatch(actions.createDeviceGroup(projectId, group, forIteration, iterationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroupDialogContainerForProject);