import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import {selectAddTestsAction} from "./Shared";
import SuiteAddTestsForm from "../../components/Suite/SuiteAddTestsForm";
import * as actions from "../../store/actions";

const AddTestsToSuiteForm = props => {
	const intl = useIntl();

	const [addTests, setAddTests] = useState({
		selected: {},
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		tests: []
	});

	useEffect(() => {
		if(props.project){
			props.onProjectTests(props.project.id); //TODO??
			props.onLoadDomainsWithTestsOrRequests(props.project.id);
		}
	}, [props.project]);

	useEffect(() => {
		if(Array.isArray(props.listDomainsForSuiteAddition)){
			// [{Dname, tests: [ R1, R2, R3, R4]}, {Dname, tests: [ R5, R6, R7, R8]} ]
			// filter
			// [{key: DomId, label: Dname, children: [{key: T.id, label: T.name}, R1, R2]}, {Dname, tests: [ R5, R6, R8]}]
			// if a domain.tests is empty => remove the domain itself from the list.

			const updatedAddTests = {...addTests};
			let allDomainsWithTests = [...props.listDomainsForSuiteAddition];  // props.restRequests is All Requests from the backend.
			let filteredDomains = [];
			for(let domainWithTest of allDomainsWithTests){
				const primeTreeDomainWithChildrenTests = {};
				primeTreeDomainWithChildrenTests.children = [];
				if(Array.isArray(domainWithTest.cases)){
					domainWithTest.cases.forEach((test, index) => {
						// for(const toRemoveTest of props.tests){   // props.tests is the list of addedRequests in the suite.
						// 	if(test.id === toRemoveTest.testCase.id){
						// 		return;
						// 	}
						// }
						primeTreeDomainWithChildrenTests.children.push(
							// test
							{key: test.id, label: test.label}
						);
					});
				}
				if(primeTreeDomainWithChildrenTests.children.length > 0){
					primeTreeDomainWithChildrenTests.key = domainWithTest.id;
					primeTreeDomainWithChildrenTests.label = domainWithTest.label;
					filteredDomains.push(primeTreeDomainWithChildrenTests);
				}
			}
			updatedAddTests.tests = filteredDomains;
			setAddTests(updatedAddTests);
		}
	}, [props.listDomainsForSuiteAddition]);

	useEffect(() => {
		if(Array.isArray(props.tests)){
			const selectedTestsByDefault = {};
			for(const selectTest of props.tests){   // props.tests is the list of addedRequests in the suite.
				selectedTestsByDefault[selectTest.testCase.id] = {checked: true, partialChecked: false};
			}
			const updatedAddTests = {...addTests};
			updatedAddTests.selected = selectedTestsByDefault;
			setAddTests(updatedAddTests);
		}
	}, [props.tests]);


	const addTestsSelectHandler = (value) => {
		const updatedAddTests = selectAddTestsAction(addTests, value, intl);
		setAddTests(updatedAddTests);
	};

	const submitAddTestsHandler = () => {

		// let existingTests = [...props.tests];
		let existingTests = [];
		const newTests = []; // rename newTests to testsIds

		let indexStart = existingTests.length;
		for(let id of Object.keys(addTests.selected)){
			// console.log('id', id);
			for(let test of props.projectTests){
				if(test.id === id){
					newTests.push({index: indexStart++, testCase: test});
				}
			}
		}
		existingTests.push(...newTests);
		props.setTests(existingTests);
		props.updateTests(existingTests);
		clear();
		return true;
	}

	const clear = () => {
		// const updatedAddTests = {...addTests};
		// updatedAddTests.selected = [];
		// setAddTests(updatedAddTests);
		props.onProjectTests(props.project.id);
	}
	return <SuiteAddTestsForm addTests = {addTests}
	                          addTestsSelectHandler = {addTestsSelectHandler}
	                          submitAddTestsHandler = {submitAddTestsHandler}
	                          clear={clear}
	/>
}

const mapStateToProps = state => {
	return {
		projectTests: state.test.projectTests,
		listDomainsForSuiteAddition: state.domain.listDomainsForSuiteAddition,
		project: state.project.project,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onProjectTests: (id) => dispatch(actions.loadProjectTests(id)),
		onLoadDomainsWithTestsOrRequests: (id) => dispatch(actions.loadDomainsWithTestsOrRequests(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTestsToSuiteForm);