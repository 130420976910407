import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {FormattedMessage} from "react-intl";

import {useHtmlClassService} from "../../../_metronic/layout";

const getChartOptions = (testDistribution) => {

	const options = {
		series: testDistribution.execution,
		chart: {
			type: 'donut', height: 400,
		}, labels: testDistribution.projects,
		legend: {
			position: 'bottom'
		}, fill: {
			type: 'gradient',
		}
	};
	return options;
}

const TestsRunsDistribution = props => {

	const uiService = useHtmlClassService();

	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
			colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
			colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
			colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily")
		};
	}, [uiService]);

	useEffect(() => {
		const element = document.getElementById("tests_runs_distribution_chart");
		if (!element) {
			return;
		}

		const options = getChartOptions(props.testDistribution);

		const chart = new ApexCharts(element, options);
		chart.render();
		return () => {
			chart.destroy();
		};
	}, [layoutProps]);

	// const total = 890_344;
	return <div className="card card-custom gutter-b card-stretch">
		<div className="card-header border-0 pt-5">
			<div className="card-title">
				<div className="card-label">
					<div className="font-weight-bolder">
						<FormattedMessage id="DASHBOARD.TESTS.DISTRIBUTION" defaultMessage='Tests Runs Distribution'/>
					</div>
					<div className="font-size-sm text-muted mt-2">
						{props.testDistribution.total.toLocaleString()} <FormattedMessage id="DASHBOARD.TESTS.DISTRIBUTION.TOTAL" defaultMessage='Total'/>
					</div>
				</div>
			</div>

		</div>
		<div className="card-body d-flex flex-column">
			<div className="flex-grow-1">
				<div id="tests_runs_distribution_chart" style={{height: "400px"}}/>
			</div>
		</div>
	</div>;
}

export default TestsRunsDistribution;