import React from "react";
import {Button} from "@material-ui/core";
import ImportExport from "@material-ui/icons/ImportExport";
import {FormattedMessage} from "react-intl";

import * as Action from "./Actions";
import {componentsValues} from "../../components/Instruction/ActionTypes";

const ExportTest = props => {

	const exportTest = () => {
		// make sure index is correct and drag and drop handle old schema of id=mmmm
		const test = {...props.test};
		test.instructions = [];
		let index = 0;
		for(let inst of props.test.instructions){
			const updatedInstruction = {...inst};
			// set index
			updatedInstruction.index = index++;
			if(inst.action === Action.DRAG_AND_DROP){
				if(!inst.componentTo){
					const splitMe = inst.typeValue;
					const index = splitMe.indexOf('=');
					const typeValue = splitMe.substring(index + 1);
					const componentTo = splitMe.substring(0, index);
					// check if componentTo is valid
					if(componentsValues.indexOf(componentTo.toUpperCase()) > -1){
						updatedInstruction.typeValue = typeValue;
						updatedInstruction.componentTo = componentTo.toUpperCase();
					}
				}
			}
			test.instructions.push(updatedInstruction);
		}

		const json = {
			cases: [
				test
			]
		};
		json.cases[0].id = undefined;
		const element = document.createElement("a");
		element.setAttribute(
			"href", "data:text/json;charset=UTF-8,"
			+ encodeURIComponent(JSON.stringify(json))
		);
		element.setAttribute("download", "test.json");
		element.style.display = "none";
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	return (
		<Button
			variant = "contained"
			size = 'small'
			startIcon={<ImportExport/>}
			onClick={() => exportTest()}
			className = ''>
			<FormattedMessage id="TEST.ACTIONS.EXPORT"
			                  defaultMessage='Export'/>
		</Button>
	)
}

export default ExportTest;