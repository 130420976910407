import * as actionTypes from "./actionTypes";
import {NULLIFY_CREATED_DEVICE_GROUP} from "./actionTypes";

export const createProject = (project, username, suggestDefault, loggedInUser) => {
	return {
		type: actionTypes.CREATE_PROJECT,
		project: project,
		username: username,
		suggestDefault: suggestDefault,
		loggedInUser: loggedInUser
	}
};

export const createProjectStart = () => {
	return {
		type: actionTypes.CREATE_PROJECT_START
	}
};

export const createProjectSuccess = (project) => {
	return {
		type: actionTypes.CREATE_PROJECT_SUCCESS,
		project: project
	}
};

export const createProjectFail = (error) => {
	return {
		type: actionTypes.CREATE_PROJECT_FAIL,
		error: error
	}
};

export const loadProject = (id, view = false) => {
	return {
		type: actionTypes.LOAD_PROJECT,
		id: id,
		view: view
	}
};

export const loadProjectStart = (view) => {
	return {
		type: actionTypes.LOAD_PROJECT_START,
		view: view
	}
};

export const loadProjectSuccess = (project, view) => {
	return {
		type: actionTypes.LOAD_PROJECT_SUCCESS,
		project: project,
		view: view
	}
};

export const loadProjectFail = (error, view) => {
	return {
		type: actionTypes.LOAD_PROJECT_FAIL,
		error: error,
		view: view
	}
};

export const loadProjectViewPort = (id) => {
	return {
		type: actionTypes.LOAD_PROJECT_VIEW_PORT,
		id: id
	}
};

export const loadProjectViewPortStart = () => {
	return {
		type: actionTypes.LOAD_PROJECT_VIEW_PORT_START
	}
};

export const loadProjectViewPortSuccess = (viewPort) => {
	return {
		type: actionTypes.LOAD_PROJECT_VIEW_PORT_SUCCESS,
		viewPort: viewPort
	}
};

export const loadProjectViewPortFail = (error) => {
	return {
		type: actionTypes.LOAD_PROJECT_VIEW_PORT_FAIL,
		error: error
	}
};

export const loadProjects = (username) => {
	return {
		type: actionTypes.LOAD_PROJECTS,
		username: username
	}
};

export const loadProjectsStart = () => {
	return {
		type: actionTypes.LOAD_PROJECTS_START
	}
};

export const loadProjectsSuccess = (projects) => {
	return {
		type: actionTypes.LOAD_PROJECTS_SUCCESS,
		projects: projects
	}
};

export const loadProjectsFail = (error) => {
	return {
		type: actionTypes.LOAD_PROJECTS_FAIL,
		error: error
	}
};

export const deleteProject = (id, redirect, isPreferredProject, newPreferred, loggedInUser) => {
	return {
		type: actionTypes.DELETE_PROJECT,
		id: id,
		redirect: redirect,
		isPreferredProject: isPreferredProject,
		newPreferred: newPreferred,
		loggedInUser: loggedInUser
	}
};

export const deleteProjectStart = () => {
	return {
		type: actionTypes.DELETE_PROJECT_START
	}
};

export const deleteProjectSuccess = (id) => {
	return {
		type: actionTypes.DELETE_PROJECT_SUCCESS,
		id: id
	}
};

export const deleteProjectFail = (error) => {
	return {
		type: actionTypes.DELETE_PROJECT_FAIL,
		error: error
	}
};

export const updateProject = (id, project, preferredProject = false) => {
	return {
		type: actionTypes.UPDATE_PROJECT,
		id: id,
		project: project,
		preferredProject: preferredProject
	}
};

export const updateProjectStart = () => {
	return {
		type: actionTypes.UPDATE_PROJECT_START
	}
};

export const updateProjectSuccess = () => {
	return {
		type: actionTypes.UPDATE_PROJECT_SUCCESS
	}
};

export const updateProjectFail = (error) => {
	return {
		type: actionTypes.UPDATE_PROJECT_FAIL,
		error: error
	}
};

export const toggleExemptProject = (id, exempt) => {
	return {
		type: actionTypes.TOGGLE_EXEMPT_PROJECT,
		id: id,
		exempt : exempt
	}
};

export const toggleExemptProjectStart = () => {
	return {
		type: actionTypes.TOGGLE_EXEMPT_PROJECT_START
	}
};

export const toggleExemptProjectSuccess = (id) => {
	return {
		type: actionTypes.TOGGLE_EXEMPT_PROJECT_SUCCESS,
		id: id
	}
};

export const toggleExemptProjectFail = (error) => {
	return {
		type: actionTypes.TOGGLE_EXEMPT_PROJECT_FAIL,
		error: error
	}
};

export const createProjectAndroid = (project, suggestDefault, loggedInUser, apkLabel, apk) => {
	return {
		type: actionTypes.CREATE_PROJECT_ANDROID,
		project: project,
		suggestDefault: suggestDefault,
		loggedInUser: loggedInUser,
		apkLabel: apkLabel,
		apk : apk
	}
};

export const createProjectAndroidStart = () => {
	return {
		type: actionTypes.CREATE_PROJECT_ANDROID_START
	}
};

export const createProjectAndroidSuccess = () => {
	return {
		type: actionTypes.CREATE_PROJECT_ANDROID_SUCCESS
	}
};

export const createProjectAndroidFail = (error) => {
	return {
		type: actionTypes.CREATE_PROJECT_ANDROID_FAIL,
		error: error
	}
};

export const updateProjectAndroid = (id, projectInfo, isNewAPK, apkLabel, apk, isPreferredProject) => {
	return {
		type: actionTypes.UPDATE_PROJECT_ANDROID,
		projectInfo: projectInfo,
		isNewAPK: isNewAPK,
		id: id,
		apkLabel: apkLabel,
		apk: apk,
		isPreferredProject: isPreferredProject
	}
};

export const updateProjectAndroidStart = () => {
	return {
		type: actionTypes.UPDATE_PROJECT_ANDROID_START
	}
};

export const updateProjectAndroidSuccess = () => {
	return {
		type: actionTypes.UPDATE_PROJECT_ANDROID_SUCCESS,
		// project: project
	}
};

export const updateProjectAndroidFail = (error) => {
	return {
		type: actionTypes.UPDATE_PROJECT_ANDROID_FAIL,
		error: error
	}
};

export const cancelSubscription = (id) => {
	return {
		type: actionTypes.CANCEL_SUBSCRIPTION,
		id: id
	}
};

export const cancelSubscriptionStart = () => {
	return {
		type: actionTypes.CANCEL_SUBSCRIPTION_START
	}
};

export const cancelSubscriptionSuccess = () => {
	return {
		type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS
	}
};

export const cancelSubscriptionFail = (error) => {
	return {
		type: actionTypes.CANCEL_SUBSCRIPTION_FAIL,
		error: error
	}
};

export const canCreateProject = () => {
	return {
		type: actionTypes.CAN_CREATE_PROJECT
	}
};

export const canCreateProjectStart = () => {
	return {
		type: actionTypes.CAN_CREATE_PROJECT_START
	}
};

export const canCreateProjectSuccess = (canAdd) => {
	return {
		type: actionTypes.CAN_CREATE_PROJECT_SUCCESS,
		canAdd: canAdd
	}
};

export const canCreateProjectFail = (error) => {
	return {
		type: actionTypes.CAN_CREATE_PROJECT_FAIL,
		error: error
	}
};

export const loadTimeZones = () => {
	return {
		type: actionTypes.LOAD_TIMEZONES
	}
};

export const loadTimeZonesStart = () => {
	return {
		type: actionTypes.LOAD_TIMEZONES_START
	}
};

export const loadTimeZonesSuccess = (timeZones) => {
	return {
		type: actionTypes.LOAD_TIMEZONES_SUCCESS,
		timeZones: timeZones
	}
};

export const loadTimeZonesFail = (error) => {
	return {
		type: actionTypes.LOAD_TIMEZONES_FAIL,
		error: error
	}
};

export const checkAndroidEnvironmentStatus = (projectID, domainID) => {
	return {
		type: actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS,
		projectID: projectID,
		domainID: domainID
	}
};

export const checkAndroidEnvironmentStatusStart = () => {
	return {
		type: actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS_START
	}
};

export const checkAndroidEnvironmentStatusSuccess = (status) => {
	return {
		type: actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS_SUCCESS,
		status: status
	}
};

export const checkAndroidEnvironmentStatusFail = (error) => {
	return {
		type: actionTypes.CHECK_ANDROID_ENVIRONMENT_STATUS_FAIL,
		error: error
	}
};

export const nullifyAndroidEnvironmentStatusError = () => {
	return {
		type: actionTypes.NULLIFY_ANDROID_ENVIRONMENT_STATUS_ERROR
	}
};

export const loadDevices = (id) => {
	return {
		type: actionTypes.LOAD_DEVICES,
		id: id,
	}
};

export const loadDevicesStart = () => {
	return {
		type: actionTypes.LOAD_DEVICES_START,
	}
};

export const loadDevicesSuccess = (devices) => {
	return {
		type: actionTypes.LOAD_DEVICES_SUCCESS,
		devices: devices,
	}
};

export const loadDevicesFail = (error) => {
	return {
		type: actionTypes.LOAD_DEVICES_FAIL,
		error: error
	}
};

export const createDeviceGroup = (projectId, group, forIteration = false, iterationId, both = false) => {
	return {
		type: actionTypes.CREATE_DEVICE_GROUP,
		projectId: projectId,
		group: group,
		forIteration: forIteration,
		iterationId: iterationId,
		both: both
	}
};

export const createDeviceGroupStart = () => {
	return {
		type: actionTypes.CREATE_DEVICE_GROUP_START
	}
};

export const createDeviceGroupSuccess = (group) => {
	return {
		type: actionTypes.CREATE_DEVICE_GROUP_SUCCESS,
		group: group,
	}
};

export const nullifyCreatedDeviceGroup = () => {
	return {
		type: actionTypes.NULLIFY_CREATED_DEVICE_GROUP,
	}
};

export const createDeviceGroupIncompatible = (group) => {
	return {
		type: actionTypes.CREATE_DEVICE_GROUP_FAIL_INCOMPATIBLE,
		group: group,
	}
};

export const createDeviceGroupFail = (error) => {
	return {
		type: actionTypes.CREATE_DEVICE_GROUP_FAIL,
		error: error
	}
};

export const deleteDeviceGroup = (id, forIteration = false, iterationId) => {
	return {
		type: actionTypes.DELETE_DEVICE_GROUP,
		id: id,
		forIteration: forIteration,
		iterationId: iterationId
	}
};

export const deleteDeviceGroupStart = () => {
	return {
		type: actionTypes.DELETE_DEVICE_GROUP_START
	}
};

export const deleteDeviceGroupSuccess = (id, project) => {
	return {
		type: actionTypes.DELETE_DEVICE_GROUP_SUCCESS,
		id: id,
		project: project
	}
};

export const deleteDeviceGroupFail = (error) => {
	return {
		type: actionTypes.DELETE_DEVICE_GROUP_FAIL,
		error: error
	}
};

export const nullifyDeletionDeviceGroupError = () => {
	return {
		type: actionTypes.NULLIFY_DELETION_DEVICE_GROUP_ERROR,
	}
};

export const updateDeviceGroup = (projectId, group) => {
	return {
		type: actionTypes.UPDATE_DEVICE_GROUP,
		projectId: projectId,
		group: group,
	}
};

export const updateDeviceGroupStart = () => {
	return {
		type: actionTypes.UPDATE_DEVICE_GROUP_START
	}
};

export const updateDeviceGroupSuccess = () => {
	return {
		type: actionTypes.UPDATE_DEVICE_GROUP_SUCCESS,
	}
};
export const updateDeviceGroupIncompatible = (group) => {
	return {
		type: actionTypes.UPDATE_DEVICE_GROUP_FAIL_INCOMPATIBLE,
		group: group,
	}
};


export const updateDeviceGroupFail = (error) => {
	return {
		type: actionTypes.UPDATE_DEVICE_GROUP_FAIL,
		error: error
	}
};

export const loadTestFiles = (id) => {
	return {
		type: actionTypes.LOAD_TEST_FILES,
		id: id,
	}
};

export const loadTestFilesStart = () => {
	return {
		type: actionTypes.LOAD_TEST_FILES_START,
	}
};

export const loadTestFilesSuccess = (files) => {
	return {
		type: actionTypes.LOAD_TEST_FILES_SUCCESS,
		files: files,
	}
};

export const loadTestFilesFail = (error) => {
	return {
		type: actionTypes.LOAD_TEST_FILES_FAIL,
		error: error,
	}
};

export const uploadTestFile = (id, file, name) => {
	return {
		type: actionTypes.UPLOAD_TEST_FILE,
		id: id,
		file: file,
		name: name
	}
};

export const uploadTestFileStart = () => {
	return {
		type: actionTypes.UPLOAD_TEST_FILE_START,
	}
};

export const uploadTestFileSuccess = () => {
	return {
		type: actionTypes.UPLOAD_TEST_FILE_SUCCESS,
	}
};

export const uploadTestFileFail = (error) => {
	return {
		type: actionTypes.UPLOAD_TEST_FILE_FAIL,
		error: error,
	}
};

export const deleteTestFile = (id, projectId) => {
	return {
		type: actionTypes.DELETE_TEST_FILE,
		id: id,
		projectId: projectId,
	}
};

export const deleteTestFileStart = () => {
	return {
		type: actionTypes.DELETE_TEST_FILE_START,
	}
};

export const deleteTestFileSuccess = () => {
	return {
		type: actionTypes.DELETE_TEST_FILE_SUCCESS,
	}
};

export const deleteTestFileFail = (error) => {
	return {
		type: actionTypes.DELETE_TEST_FILE_FAIL,
		error: error,
	}
};