const CountriesList = [
	{
		name: "Afghanistan",
		alpha2Code: "AF"
	},
	{
		name: "Albania",
		alpha2Code: "AL"
	},
	{
		name: "Algeria",
		alpha2Code: "DZ"
	},
	{
		name: "Argentina",
		alpha2Code: "AR"
	},
	{
		name: "Australia",
		alpha2Code: "AU"
	},
	{
		name: "Austria",
		alpha2Code: "AT"
	},
	{
		name: "Azerbaijan",
		alpha2Code: "AZ"
	},
	{
		name: "Bahrain",
		alpha2Code: "BH"
	},
	{
		name: "Bangladesh",
		alpha2Code: "BD"
	},
	{
		name: "Belgium",
		alpha2Code: "BE"
	},
	{
		name: "Bosnia and Herzegovina",
		alpha2Code: "BA"
	},
	{
		name: "Brazil",
		alpha2Code: "BR"
	},
	{
		name: "Bulgaria",
		alpha2Code: "BG"
	},
	{
		name: "Burundi",
		alpha2Code: "BI"
	},
	{
		name: "Cameroon",
		alpha2Code: "CM"
	},
	{
		name: "Canada",
		alpha2Code: "CA"
	},
	{
		name: "Central African Republic",
		alpha2Code: "CF"
	},
	{
		name: "Chile",
		alpha2Code: "CL"
	},
	{
		name: "China",
		alpha2Code: "CN"
	},
	{
		name: "Colombia",
		alpha2Code: "CO"
	},
	{
		name: "Comoros",
		alpha2Code: "KM"
	},
	{
		name: "Congo",
		alpha2Code: "CG"
	},
	{
		name: "Costa Rica",
		alpha2Code: "CR"
	},
	{
		name: "Croatia",
		alpha2Code: "HR"
	},
	{
		name: "Cuba",
		alpha2Code: "CU"
	},
	{
		name: "Czech Republic",
		alpha2Code: "CZ"
	},
	{
		name: "Denmark",
		alpha2Code: "DK"
	},
	{
		name: "Djibouti",
		alpha2Code: "DJ"
	},
	{
		name: "Ecuador",
		alpha2Code: "EC"
	},
	{
		name: "Egypt",
		alpha2Code: "EG"
	},
	{
		name: "El Salvador",
		alpha2Code: "SV"
	},
	{
		name: "Equatorial Guinea",
		alpha2Code: "GQ"
	},
	{
		name: "Eritrea",
		alpha2Code: "ER"
	},
	{
		name: "Estonia",
		alpha2Code: "EE"
	},
	{
		name: "Ethiopia",
		alpha2Code: "ET"
	},
	{
		name: "Finland",
		alpha2Code: "FI"
	},
	{
		name: "France",
		alpha2Code: "FR"
	},
	{
		name: "Gabon",
		alpha2Code: "GA"
	},
	{
		name: "Gambia",
		alpha2Code: "GM"
	},
	{
		name: "Georgia",
		alpha2Code: "GE"
	},
	{
		name: "Germany",
		alpha2Code: "DE"
	},
	{
		name: "Ghana",
		alpha2Code: "GH"
	},
	{
		name: "Greece",
		alpha2Code: "GR"
	},
	{
		name: "Guatemala",
		alpha2Code: "GT"
	},
	{
		name: "Guinea",
		alpha2Code: "GN"
	},
	{
		name: "Guinea-Bissau",
		alpha2Code: "GW"
	},
	{
		name: "Honduras",
		alpha2Code: "HN"
	},
	{
		name: "Hong Kong",
		alpha2Code: "HK"
	},
	{
		name: "Hungary",
		alpha2Code: "HU"
	},
	{
		name: "Iceland",
		alpha2Code: "IS"
	},
	{
		name: "India",
		alpha2Code: "IN"
	},
	{
		name: "Indonesia",
		alpha2Code: "ID"
	},
	{
		name: "Côte d'Ivoire",
		alpha2Code: "CI"
	},
	{
		name: "Iran",
		alpha2Code: "IR"
	},
	{
		name: "Iraq",
		alpha2Code: "IQ"
	},
	{
		name: "Ireland",
		alpha2Code: "IE"
	},
	{
		name: "Italy",
		alpha2Code: "IT"
	},
	{
		name: "Jamaica",
		alpha2Code: "JM"
	},
	{
		name: "Japan",
		alpha2Code: "JP"
	},
	{
		name: "Jordan",
		alpha2Code: "JO"
	},
	{
		name: "Kazakhstan",
		alpha2Code: "KZ"
	},
	{
		name: "Kenya",
		alpha2Code: "KE"
	},
	{
		name: "Kuwait",
		alpha2Code: "KW"
	},
	{
		name: "Kyrgyzstan",
		alpha2Code: "KG"
	},
	{
		name: "Latvia",
		alpha2Code: "LV"
	},
	{
		name: "Lebanon",
		alpha2Code: "LB"
	},
	{
		name: "Liberia",
		alpha2Code: "LR"
	},
	{
		name: "Libya",
		alpha2Code: "LY"
	},
	{
		name: "Lithuania",
		alpha2Code: "LT"
	},
	{
		name: "Luxembourg",
		alpha2Code: "LU"
	},
	{
		name: "Macedonia",
		alpha2Code: "MK"
	},
	{
		name: "Madagascar",
		alpha2Code: "MG"
	},
	{
		name: "Malawi",
		alpha2Code: "MW"
	},
	{
		name: "Malaysia",
		alpha2Code: "MY"
	},
	{
		name: "Maldives",
		alpha2Code: "MV"
	},
	{
		name: "Mali",
		alpha2Code: "ML"
	},
	{
		name: "Malta",
		alpha2Code: "MT"
	},
	{
		name: "Mauritania",
		alpha2Code: "MR"
	},
	{
		name: "Mexico",
		alpha2Code: "MX"
	},
	{
		name: "Moldova",
		alpha2Code: "MD"
	},
	{
		name: "Monaco",
		alpha2Code: "MC"
	},
	{
		name: "Mongolia",
		alpha2Code: "MN"
	},
	{
		name: "Montenegro",
		alpha2Code: "ME"
	},
	{
		name: "Morocco",
		alpha2Code: "MA"
	},
	{
		name: "Mozambique",
		alpha2Code: "MZ"
	},
	{
		name: "Myanmar",
		alpha2Code: "MM"
	},
	{
		name: "Namibia",
		alpha2Code: "NA"
	},
	{
		name: "Nepal",
		alpha2Code: "NP"
	},
	{
		name: "Netherlands",
		alpha2Code: "NL"
	},
	{
		name: "New Zealand",
		alpha2Code: "NZ"
	},
	{
		name: "Nicaragua",
		alpha2Code: "NI"
	},
	{
		name: "Niger",
		alpha2Code: "NE"
	},
	{
		name: "Nigeria",
		alpha2Code: "NG"
	},
	{
		name: "North Korea",
		alpha2Code: "KP"
	},
	{
		name: "Norway",
		alpha2Code: "NO"
	},
	{
		name: "Oman",
		alpha2Code: "OM"
	},
	{
		name: "Pakistan",
		alpha2Code: "PK"
	},
	{
		name: "Palau",
		alpha2Code: "PW"
	},
	{
		name: "Palestine",
		alpha2Code: "PS"
	},
	{
		name: "Panama",
		alpha2Code: "PA"
	},
	{
		name: "Paraguay",
		alpha2Code: "PY"
	},
	{
		name: "Peru",
		alpha2Code: "PE"
	},
	{
		name: "Philippines",
		alpha2Code: "PH"
	},
	{
		name: "Poland",
		alpha2Code: "PL"
	},
	{
		name: "Portugal",
		alpha2Code: "PT"
	},
	{
		name: "Qatar",
		alpha2Code: "QA"
	},
	{
		name: "Republic of Kosovo",
		alpha2Code: "XK"
	},
	{
		name: "Romania",
		alpha2Code: "RO"
	},
	{
		name: "Russian Federation",
		alpha2Code: "RU"
	},
	{
		name: "Rwanda",
		alpha2Code: "RW"
	},
	{
		name: "Saudi Arabia",
		alpha2Code: "SA"
	},
	{
		name: "Senegal",
		alpha2Code: "SN"
	},
	{
		name: "Serbia",
		alpha2Code: "RS"
	},
	{
		name: "Seychelles",
		alpha2Code: "SC"
	},
	{
		name: "Sierra Leone",
		alpha2Code: "SL"
	},
	{
		name: "Singapore",
		alpha2Code: "SG"
	},
	{
		name: "Slovakia",
		alpha2Code: "SK"
	},
	{
		name: "Slovenia",
		alpha2Code: "SI"
	},
	{
		name: "Somalia",
		alpha2Code: "SO"
	},
	{
		name: "South Africa",
		alpha2Code: "ZA"
	},
	{
		name: "South Korea",
		alpha2Code: "KR"
	},
	{
		name: "Spain",
		alpha2Code: "ES"
	},
	{
		name: "Sri Lanka",
		alpha2Code: "LK"
	},
	{
		name: "Sudan",
		alpha2Code: "SD"
	},
	{
		name: "Swaziland",
		alpha2Code: "SZ"
	},
	{
		name: "Sweden",
		alpha2Code: "SE"
	},
	{
		name: "Switzerland",
		alpha2Code: "CH"
	},
	{
		name: "Syria",
		alpha2Code: "SY"
	},
	{
		name: "Taiwan",
		alpha2Code: "TW"
	},
	{
		name: "Tajikistan",
		alpha2Code: "TJ"
	},
	{
		name: "Tanzania",
		alpha2Code: "TZ"
	},
	{
		name: "Thailand",
		alpha2Code: "TH"
	},
	{
		name: "Togo",
		alpha2Code: "TG"
	},
	{
		name: "Tonga",
		alpha2Code: "TO"
	},
	{
		name: "Tunisia",
		alpha2Code: "TN"
	},
	{
		name: "Turkey",
		alpha2Code: "TR"
	},
	{
		name: "Turkmenistan",
		alpha2Code: "TM"
	},
	{
		name: "Uganda",
		alpha2Code: "UG"
	},
	{
		name: "Ukraine",
		alpha2Code: "UA"
	},
	{
		name: "United Arab Emirates",
		alpha2Code: "AE"
	},
	{
		name: "United Kingdom",
		alpha2Code: "GB"
	},
	{
		name: "United States of America",
		alpha2Code: "US"
	},
	{
		name: "Uruguay",
		alpha2Code: "UY"
	},
	{
		name: "Uzbekistan",
		alpha2Code: "UZ"
	},
	{
		name: "Vanuatu",
		alpha2Code: "VU"
	},
	{
		name: "Venezuela",
		alpha2Code: "VE"
	},
	{
		name: "Viet Nam",
		alpha2Code: "VN"
	},
	{
		name: "Wallis and Futuna",
		alpha2Code: "WF"
	},
	{
		name: "Yemen",
		alpha2Code: "YE"
	},
	{
		name: "Zambia",
		alpha2Code: "ZM"
	},
	{
		name: "Zimbabwe",
		alpha2Code: "ZW"
	}

];

export default CountriesList;