export const HEADER = "HEADER";
export const PARAMS = "PARAMS";

export const AuthenticationTypeObjects = [
	{label: 'Header', value: HEADER},
	{label: 'Parameter', value: PARAMS},
]

export const AuthenticationTypeValues = [
	"HEADER",
	"PARAMS"
]