import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import * as actions from "../../store/actions";
import MoveTestToDomainJSX from "../../components/Test/MoveTestToDomainJSX";

const MoveTestToDomain = props => {

	const [targetDomains, setTargetDomains] = useState([]);

	const [targetDomain, setTargetDomain] = useState(null);
	const [moveErrorVisible, setMoveErrorVisible] = useState(false);

	// const [toMoveTest, setToMoveTest] = useState(null); // this is will be provided by props.toMoveTest

	const [moveTestDialogVisible, setMoveTestDialogVisible] = useState(false);

	const showTestDialog = () => {
		setMoveTestDialogVisible(true);
	}

	const hideTestDialog = () => {
		setMoveTestDialogVisible(false);
	}

	useEffect(() => {
		if(props.allDomains?.length > 0){
			setTargetDomains(props.allDomains.filter(domain => domain.id !== props.toMoveTest.domainId));
		}
	}, [props.allDomains, props.toMoveTest]);


	const move = () => {

		// handle no selection
		if (targetDomain === null) {
			setMoveErrorVisible(true);
			return;
		}

		// get test
		// console.log('props.domain.id', props.domain);
		// console.log('targetDomain.id', targetDomain);
		hideTestDialog();
		props.onMoveTestToDomain(props.toMoveTest.id, props.domain.id, targetDomain.id);
	}

	return <MoveTestToDomainJSX moveErrorVisible={moveErrorVisible} setMoveErrorVisible={setMoveErrorVisible} targetDomains={targetDomains}
								targetDomain={targetDomain} setTargetDomain={setTargetDomain} move={move}
								moveTestDialogVisible={moveTestDialogVisible} setMoveTestDialogVisible={setMoveTestDialogVisible}
								showTestDialog={showTestDialog} hideTestDialog={hideTestDialog}/>
}

const mapStateToProps = state => {
	return {
		allDomains: state.domain.listDomainsForSuiteAddition,
		domain: state.domain.domain,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMoveTestToDomain: (testID, sourceDomainId, targetDomainId) => dispatch(actions.moveTestToDomain(testID, sourceDomainId, targetDomainId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveTestToDomain);