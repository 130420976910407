export const UINames = (name, intl) => {
	switch (name) {
		case 'name':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.NAME.MESSAGE",
				defaultMessage: 'Request Name' });
		case 'method':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.METHOD.MESSAGE",
				defaultMessage: 'HTTP Method' });
		case 'url':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.CORE.URL.MESSAGE",
				defaultMessage: 'URL' });
		case 'description':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.DESCRIPTION.MESSAGE",
				defaultMessage: 'Description' });
		case 'authType':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.TYPE.MESSAGE",
				defaultMessage: 'Authentication Type' });
		case 'basic-username':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.BASIC.USERNAME.MESSAGE",
				defaultMessage: 'Username' });
		case 'basic-password':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.BASIC.PASSWORD.MESSAGE",
				defaultMessage: 'Password' });
		case 'token':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.TOKEN.MESSAGE",
				defaultMessage: 'Token' });
		case 'apikey-key':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.APIKEY.KEY.MESSAGE",
				defaultMessage: 'Key' });
		case 'apikey-value':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.APIKEY.VALUE.MESSAGE",
				defaultMessage: 'Value' });
		case 'apikey-type':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.AUTH.APIKEY.TYPE.MESSAGE",
				defaultMessage: 'Type' });
		case 'responsePath':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.RESPONSE.COLLECTION.RESPONSE.PATH.MESSAGE",
				defaultMessage: 'Response Path' });
		case 'suiteVariable':
			return intl.formatMessage({ id: "REST.REQUEST.FORM.RESPONSE.COLLECTION.SUITE.VARIABLE.MESSAGE",
				defaultMessage: 'Suite Variable' });
		default:
			return '';
	}
}