import React, {useState} from "react";

import {actions, componentsValues} from "../../components/Instruction/ActionTypes";
import {changeText, inputShouldBeVisible, selectElement} from "./Shared";
import {checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import AddForm from "../../components/Instruction/AddForm";
import {useIntl} from "react-intl";
import * as Action from "../Test/Actions";

const NewInstruction = props => {
	const intl = useIntl();

	const [name, setName] = useState({
		value: '',
		validation: {
			required: false,
			minLength: 0,
			maxLength: 150
		},
		valid: true,
		validationMessage: null
	});

	const [action, setAction] = useState({
		action: '',
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		actions: actions
	});

	const [component, setComponent] = useState({
		component: '',
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		components: [...componentsValues]
	});

	const [identifier, setIdentifier] = useState({
		value: '',
		validation: {
			required: true,
			minLength: 0,
			maxLength: 500
		},
		valid: true,
		validationMessage: null
	});

	const [value, setValue] = useState({
		value: '',
		validation: {
			required: false,
			minLength: 0,
			maxLength: 1000
		},
		valid: true,
		validationMessage: null
	});

	const [toComponent, setToComponent] = useState({
		toComponent: '',
		validation: {
			required: false
		},
		valid: true,
		validationMessage: null,
		components: [...componentsValues]
	});

	const [showComponent, setShowComponent] = useState(true);
	const [showIdentifier, setShowIdentifier] = useState(true);
	const [showValue, setShowValue] = useState(true);
	const [showToComponent, setShowToComponent] = useState(false);


	const nameChangeHandler = (event) => {
		const updatedName = changeText(name, event, 'name', intl);
		setName(updatedName);
	}

	const identifierChangeHandler = (event) => {
		const updatedIdentifier = changeText(identifier, event, 'identifier', intl);
		setIdentifier(updatedIdentifier);
	}

	const valueChangeHandler = (event) => {
		const updatedValue = changeText(value, event, 'value', intl);
		setValue(updatedValue);
	}

	const actionSelectHandler = (event) => {
		const updatedAction = selectElement(action, event, 'action', 'action', intl);
		const [component, identifier, value, toComponent] = inputShouldBeVisible(event.target.value);
		setShowComponent(component);
		setShowIdentifier(identifier);
		setShowValue(value);
		setShowToComponent(toComponent);
		updateRequiredFields(component, identifier, value, toComponent, event.target.value);
		setAction(updatedAction);
	};

	const updateRequiredFields = (showComponent, showIdentifier, showValue, showToComponent, action) => {

		const updatedComponent = {...component};
		let updatedValidation = {...updatedComponent.validation};
		updatedValidation.required = showComponent;
		updatedComponent.validation = updatedValidation;
		setComponent(updatedComponent);

		const updatedIdentifier = {...identifier};
		updatedValidation = {...updatedIdentifier.validation};
		updatedValidation.required = showIdentifier;
		if (showIdentifier) {
			updatedValidation.isNumeric = action === Action.SELECT_FRAME;
		}
		updatedIdentifier.validation = updatedValidation;
		setIdentifier(updatedIdentifier);

		const updatedValue = {...value};
		updatedValidation = {...updatedValue.validation};
		updatedValidation.required = showValue;
		if (showValue) {
			updatedValidation.isNumeric = action === Action.WAIT_SECONDS;
		}
		updatedValue.validation = updatedValidation;
		setValue(updatedValue);

		const updatedToComponent = {...toComponent};
		updatedValidation = {...updatedToComponent.validation};
		updatedValidation.required = showToComponent;
		updatedToComponent.validation = updatedValidation;
		setToComponent(updatedToComponent);
	}

	const componentSelectHandler = (event) => {
		const updatedComponent = selectElement(component, event, 'component', 'component', intl);
		setComponent(updatedComponent);
	};

	const toComponentSelectHandler = (event) => {
		const updatedToComponent = selectElement(toComponent, event, 'toComponent', 'toComponent', intl);
		setToComponent(updatedToComponent);
	}

	const checkValidityForAll = () => {
		let allValid = true;
		// Name
		const updatedName = {...name};
		let [valid, message] = checkValidity(updatedName, UINames('name', intl), intl);
		updatedName.valid = valid;
		updatedName.validationMessage = message;
		setName(updatedName);
		if (!valid) {
			allValid = false;
		}

		// Action
		const updatedAction = {...action};
		[valid, message] = checkSelectionValidity(updatedAction, 'action',
			UINames('action', intl), intl);
		updatedAction.valid = valid;
		updatedAction.validationMessage = message;
		setAction(updatedAction);
		if (!valid) {
			allValid = false;
		}


		// Component
		const updatedComponent = {...component};
		[valid, message] = checkSelectionValidity(updatedComponent, 'component',
			UINames('component', intl), intl);
		updatedComponent.valid = valid;
		updatedComponent.validationMessage = message;
		setComponent(updatedComponent);
		if (!valid) {
			allValid = false;
		}

		// Name
		const updatedIdentifier = {...identifier};
		[valid, message] = checkValidity(updatedIdentifier, UINames('identifier', intl), intl);
		updatedIdentifier.valid = valid;
		updatedIdentifier.validationMessage = message;
		setIdentifier(updatedIdentifier);
		if (!valid) {
			allValid = false;
		}

		// Value
		const updatedValue = {...value};
		[valid, message] = checkValidity(updatedValue, UINames('value', intl), intl);
		updatedValue.valid = valid;
		updatedValue.validationMessage = message;
		setValue(updatedValue);
		if (!valid) {
			allValid = false;
		}

		// To Component
		const updatedToComponent = {...toComponent};
		[valid, message] = checkSelectionValidity(updatedToComponent,
			'toComponent', UINames('toComponent', intl), intl);
		updatedToComponent.valid = valid;
		updatedToComponent.validationMessage = message;
		setToComponent(updatedToComponent);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitInstructionHandler = (event) => {
		// validate all
		if (!checkValidityForAll()) {
			return false;
		}

		let updatedInstructions = [...props.instructions];
		const instruction = {
			description: name.value,
			index: updatedInstructions.length,
			action: action.action,
			component: component.component,
			componentName: identifier.value,
			typeValue: value.value,
			componentTo: toComponent.toComponent
		};
		updatedInstructions.push(instruction);
		props.setInstructions(updatedInstructions);
		props.updateInstructions(updatedInstructions);
		clear();
		return true;
	}

	const clear = () => {
		const upName = {...name};
		upName.value = '';
		setName(upName);
		const upIdentifier = {...identifier};
		upIdentifier.value = '';
		setIdentifier((upIdentifier));
		const upValue = {...value};
		upValue.value = '';
		setValue(upValue);
		const upComponent = {...component};
		upComponent.component = '';
		setComponent(upComponent);
		const upAction = {...action};
		upAction.action = '';
		setAction(upAction);
		const upToComponent = {...toComponent};
		upToComponent.toComponent = '';
		setToComponent(upToComponent);
	}

	return <AddForm name = {name}
	                action = {action}
	                component = {component}
	                identifier = {identifier}
	                value = {value}
	                toComponent = {toComponent}
	                nameChangeHandler = {nameChangeHandler}
	                identifierChangeHandler = {identifierChangeHandler}
	                valueChangeHandler = {valueChangeHandler}
	                toComponentSelectHandler = {toComponentSelectHandler}
	                actionSelectHandler = {actionSelectHandler}
	                componentSelectHandler = {componentSelectHandler}
	                submitInstructionHandler = {submitInstructionHandler}
	                showComponent = {showComponent}
	                showIdentifier = {showIdentifier}
	                showValue = {showValue}
	                showToComponent = {showToComponent}
	/>
}

export default NewInstruction;