import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import {loadStripe} from '@stripe/stripe-js';
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import {Col, Container, Row} from "react-bootstrap";

import * as actions from "../../store/actions";
import * as Plan from "./enum/PlanType";
import * as Period from "./enum/Period";
import CurrentPlanInformationsJSX from "../../components/Payment/CurrentPlanInformationsJSX";
import PricingTableJSX from "../../components/Payment/PricingTableJSX";
import {history} from "../../../redux/store";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
	},
	snackbarMessageAlert: {
		"& .MuiAlert-icon": {
			fontSize: 36
		},
		// "MuiAlert-action": {
		// 	fontSize: 36
		// }
	}
}));

const Billing = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [errorMsg, setErrorMsg] = useState('');
	const [showMonthly, setShowMonthly] = useState(true);

	const [monthlyPlans, setMonthlyPlans] = useState({BASIC: null, PROFESSIONAL: null, PREMIUM: null});
	const [annualPlans, setAnnualPlans] = useState({BASIC: null, PROFESSIONAL: null, PREMIUM: null});
	const [freePlan, setFreePlan] = useState(null);

	const [freeButtonLabel, setFreeButtonLabel] = useState('');
	const freeButtonAction = useRef(null);
	const [basicButtonLabel, setBasicButtonLabel] = useState('');
	const basicButtonAction = useRef(null);
	const [professionalButtonLabel, setProfessionalButtonLabel] = useState('');
	const professionalButtonAction = useRef(null);
	const [premiumButtonLabel, setPremiumButtonLabel] = useState('');
	const premiumButtonAction = useRef(null);


	const [freeButtonIsCurrent, setFreeButtonIsCurrent] = useState(false);
	const [basicButtonIsCurrent, setBasicButtonIsCurrent] = useState(false);
	const [professionalButtonIsCurrent, setProfessionalButtonIsCurrent] = useState(false);
	const [premiumButtonIsCurrent, setPremiumButtonIsCurrent] = useState(false);

	const [showDowngradeConfirmationDialog, setShowDowngradeConfirmationDialog] = useState(false);
	const [showUpgradeConfirmationDialog, setShowUpgradeConfirmationDialog] = useState(false);
	const downgradeAction = useRef(null);
	const [downgradeToPlan, setDowngradeToPlan] = useState(null);
	const [upgradeToPlan, setUpgradeToPlan] = useState(null);
	const upgradeAction = useRef(null);

	const [showCannotSwitchPlanConfirmationDialog, setShowCannotSwitchPlanConfirmationDialog] = useState(false);

	const [disabledDowngradeOptions, setDisabledDowngradeOptions] = useState({monthly: [], annual: []})
	//monthly: [basic, pro, prem], annual: [basic, pro, prem]

	const applyPurchaseButtonsStyle = (planType) => {
		switch (planType) {
			case Plan.FREE:
				setFreeButtonIsCurrent(true);
				setBasicButtonIsCurrent(false);
				setProfessionalButtonIsCurrent(false);
				setPremiumButtonIsCurrent(false);
				break;
			case Plan.BASIC:
				setFreeButtonIsCurrent(false);
				setBasicButtonIsCurrent(true);
				setProfessionalButtonIsCurrent(false);
				setPremiumButtonIsCurrent(false);
				break;
			case Plan.PROFESSIONAL:
				setFreeButtonIsCurrent(false);
				setBasicButtonIsCurrent(false);
				setProfessionalButtonIsCurrent(true);
				setPremiumButtonIsCurrent(false);
				break;
			case Plan.PREMIUM:
				setFreeButtonIsCurrent(false);
				setBasicButtonIsCurrent(false);
				setProfessionalButtonIsCurrent(false);
				setPremiumButtonIsCurrent(true);
				break;
			default:
				setFreeButtonIsCurrent(false);
				setBasicButtonIsCurrent(false);
				setProfessionalButtonIsCurrent(false);
				setPremiumButtonIsCurrent(false);
		}
	}

	// Snackbar
	const [showTransactionSuccessfullSnackbar, setShowTransactionSuccessfullSnackbar] = useState(false);
	const [transactionSuccessfullSnackbarMessage, setTransactionSuccessfullSnackbarMessage] = useState(null);

	const [completeInformationMsg, setCompleteInformationMsg] = useState('');

	const [startEffectRun, setStartEffectRun] = useState(false);

	useEffect(() => {
		if(startEffectRun === false){
			// Payment Fail
			const failed = new URLSearchParams(props.location.search).get('failed');
			if (failed) {
				props.onDeletePaymentSessions();
				setErrorMsg(intl.formatMessage({id: "PAYMENT.MESSAGE.FAILED", defaultMessage: 'Payment Failed, Try Again.'}));
			}
			// Payment Successfull
			const session_id = new URLSearchParams(props.location.search).get('session_id')
			if (session_id && !checkPaymenInitiatedFlag){
				setCheckPaymenInitiatedFlag(true);
				props.onCheckPayment(session_id);
				window.history.pushState('', '', `${props.location.pathname}`);
			} else {
				if(props.user && props.user.completeData !== true){
					// setCompleteInformationMsg(intl.formatMessage({id: "PAYMENT.MESSAGE.COMPLETE.INFORMATION",
					// 	defaultMessage: 'You Must Complete All Information In Your Profile. Redirecting In 5 Seconds'}));
					// setTimeout(() => {
					history.push(`/user/${props.user.login}/edit?incompleteProfile=true`);
					// }, 5000);
				}
				if(props.currentPlan === null){
					loadCurrentPlan();
				}
			}
			setStartEffectRun(true);
			document.title = intl.formatMessage({id: "PAGE.TITLE.PAYMENT", defaultMessage: 'Billing'});
		}
	}, [props.user]);

	const [cancelRefreshFlag, setCancelRefreshFlag] = useState(false);
	const [switchPaidPlanRefreshFlag, setSwitchPaidPlanRefreshFlag] = useState(false);
	const [checkPaymenInitiatedFlag, setCheckPaymenInitiatedFlag] = useState(false);

	const [showPurchaseButtons, setShowPurchaseButtons] = useState(false);

	// const [loadCurrentPlansSkipped, setLoadCurrentPlansSkipped] = useState(false);
	// When currentPlan is loaded AND whenever currentPlan is changed
	useEffect(() => {
		if (props.currentPlan && props.checkPaymentLoading === false){
			loadCurrentPlans();
		}
			// else if(props.currentPlan && props.checkPaymentLoading === true) {
		// 	console.log('set skip to true');
		// 	setLoadCurrentPlansSkipped(true);
		// }
	}, [props.currentPlan, props.checkPaymentLoading]);

	// useEffect(() => {
	// 	console.log('Effect: checkPaymentLoading');
	// 	if(props.checkPaymentLoading === false && loadCurrentPlansSkipped === true){
	// 		console.log('call loadCurrentPlans()');
	// 		loadCurrentPlans();
	// 		setLoadCurrentPlansSkipped(false);
	// 	}
	// }, [props.checkPaymentLoading, loadCurrentPlansSkipped])

	useEffect(() => {
		if(switchPaidPlanRefreshFlag === true && props.changePaidSubscriptionLoading === false){
			setSwitchPaidPlanRefreshFlag(false);
			// show snackbar
			setShowTransactionSuccessfullSnackbar(true);
			setTransactionSuccessfullSnackbarMessage(
				intl.formatMessage({id: "PAYMENT.SNACKBAR.CHANGE.PLAN.SUCCESSFUL", defaultMessage: 'Plan Changed Successfully'}));
		}

		if(props.changePaidSubscriptionLoading === true){
			setShowPurchaseButtons(false);
		}

	}, [props.changePaidSubscriptionLoading]);

	const loadCurrentPlan = () => {
		props.onLoadCurrentPlan();
	}

	const loadCurrentPlans = () => {
		if(props.currentPlan.type === Plan.FREE){
			// Load Sessions
			const url = window.location.protocol + '//' + window.location.host + '/billing';
			props.onLoadPlans(url);
		} else {
			// Load Paid Customer Plans
			props.onLoadPaidCustomerPlans();
		}
	}

	useEffect(() => {
		if(cancelRefreshFlag === true && props.cancelPaidSubscriptionLoading === false){
			setCancelRefreshFlag(false);
			// show snackbar
			setShowTransactionSuccessfullSnackbar(true);
			setTransactionSuccessfullSnackbarMessage(
				intl.formatMessage({id: "PAYMENT.SNACKBAR.CANCELLATION.SUCCESSFUL", defaultMessage: 'Cancellation Successfull'}));
			loadCurrentPlan();
		}
		if(props.cancelPaidSubscriptionLoading === true){
			setShowPurchaseButtons(false);
		}
	}, [props.cancelPaidSubscriptionLoading]);

	useEffect(() => {
		if (props.checkPaymentError !== null){
			setErrorMsg(props.checkPaymentError);
		}
	}, [props.checkPaymentError]);

	useEffect(() => {
		if (props.cancelPaidSubscriptionError !== null){
			setErrorMsg(props.cancelPaidSubscriptionError);
		}
	}, [props.cancelPaidSubscriptionError]);

	// useEffect(() => {
	// 	console.log('effect disabledDowngradeOptions ', disabledDowngradeOptions);
	// }, [disabledDowngradeOptions]);

	useEffect(() => {
		if(Array.isArray(props.availablePlans)){
			// setDisabledDowngradeOptions({monthly: [], annual: []});
			setPlans(props.availablePlans);
			// console.log('calling setDisabledDowngradeOptions effect props.availablePlans');
		}
	}, [props.availablePlans]);

	useEffect(() => {
		if(Array.isArray(props.availablePaidCustomerPlans)){
			// setDisabledDowngradeOptions({monthly: [], annual: []});
			setPlans(props.availablePaidCustomerPlans);
			// reset downgrade disabled options
			// console.log('calling setDisabledDowngradeOptions effect props.availablePaidCustomerPlans');
		}
	}, [props.availablePaidCustomerPlans]);

	const setPlans = (plans) => {
		const monthlyPlansUpdated = {...monthlyPlans};
		const annualPlansUpdated = {...annualPlans};
		for(let plan of plans){
			switch (plan.period) {
				case Period.MONTHLY:
					if(plan.type === Plan.BASIC) {
						monthlyPlansUpdated.BASIC = plan;
					} else if(plan.type === Plan.PROFESSIONAL){
						monthlyPlansUpdated.PROFESSIONAL = plan;
					} else {
						monthlyPlansUpdated.PREMIUM = plan;
					}
					break;
				case Period.ANNUAL:
					if(plan.type === Plan.BASIC) {
						annualPlansUpdated.BASIC = plan;
					} else if(plan.type === Plan.PROFESSIONAL){
						annualPlansUpdated.PROFESSIONAL = plan;
					} else {
						annualPlansUpdated.PREMIUM = plan;
					}
					break;
				case undefined:
					setFreePlan(plan);
					break;
			}
		}
		setMonthlyPlans(monthlyPlansUpdated);
		setAnnualPlans(annualPlansUpdated);
	}

	useEffect(() => {
		// freePlan is used to check when the state is set
		if(props.currentPlan && freePlan !== null){
			switch (props.currentPlan.type) {
				case Plan.FREE:
					setFreeButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
					freeButtonAction.current = null;
					applyPurchaseButtonsStyle(Plan.FREE);

					setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.PURCHASE", defaultMessage: 'Purchase'}))
					basicButtonAction.current = () => createSubscription(
						showMonthly ? monthlyPlans.BASIC.sessionId : annualPlans.BASIC.sessionId );

					setProfessionalButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.PURCHASE", defaultMessage: 'Purchase'}));
					professionalButtonAction.current = () => createSubscription(
						showMonthly ? monthlyPlans.PROFESSIONAL.sessionId : annualPlans.PROFESSIONAL.sessionId );

					setPremiumButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.PURCHASE", defaultMessage: 'Purchase'}));
					premiumButtonAction.current = () => createSubscription(
						showMonthly ? monthlyPlans.PREMIUM.sessionId : annualPlans.PREMIUM.sessionId );
					break;

				case Plan.BASIC:
					// PAYMENT.BUTTON.DOWNGRADE

					setFreeButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.DOWNGRADE", defaultMessage: 'Downgrade'}));
					freeButtonAction.current = () => {
						showDowngradePlanConfirmationDialog();
						setDowngradeToPlan(Plan.FREE);
						downgradeAction.current = () => {
							setCancelRefreshFlag(true);
							props.onCancel();
						}
					}

					if(props.currentPlan.period === Period.MONTHLY){
						if(showMonthly === true){
							setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
							basicButtonAction.current = null;
							applyPurchaseButtonsStyle(Plan.BASIC);
						} else {
							// monthly to annual
							setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.TO.ANNUAL", defaultMessage: 'Switch To Annual'}));
							basicButtonAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(annualPlans.BASIC.price.id);
							};
							applyPurchaseButtonsStyle(null);
						}
						if(props.currentPlan.status === 'TRIALING') {
							updateDisabledDowngradeOptions();
						}
					} else {
						//annual
						if(showMonthly === false){
							setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
							basicButtonAction.current = null;
							applyPurchaseButtonsStyle(Plan.BASIC);
						} else {
							// monthly to annual
							setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.TO.MONTHLY", defaultMessage: 'Switch To Monthly'}));
							basicButtonAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(monthlyPlans.BASIC.price.id);
							};
							applyPurchaseButtonsStyle(null);
						}
						if(props.currentPlan.status === 'TRIALING') {
							updateDisabledDowngradeOptions();
						}
					}

					setProfessionalButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.PURCHASE", defaultMessage: 'Purchase'}));
					professionalButtonAction.current = () => {
						showUpgradePlanConfirmationDialog();
						setUpgradeToPlan(Plan.PROFESSIONAL);
						upgradeAction.current = () => {
							setSwitchPaidPlanRefreshFlag(true);
							props.onChangePaidSubscription(showMonthly ? monthlyPlans.PROFESSIONAL.price.id : annualPlans.PROFESSIONAL.price.id);
						}
					}

					setPremiumButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.PURCHASE", defaultMessage: 'Purchase'}));
					premiumButtonAction.current = () => {
						showUpgradePlanConfirmationDialog();
						setUpgradeToPlan(Plan.PROFESSIONAL);
						upgradeAction.current = () => {
							setSwitchPaidPlanRefreshFlag(true);
							props.onChangePaidSubscription(showMonthly ? monthlyPlans.PREMIUM.price.id : annualPlans.PREMIUM.price.id);
						}

					}
					break;

				case Plan.PROFESSIONAL:
					// PAYMENT.BUTTON.DOWNGRADE

					setFreeButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.DOWNGRADE", defaultMessage: 'Downgrade'}));
					freeButtonAction.current = () => {
						showDowngradePlanConfirmationDialog();
						setDowngradeToPlan(Plan.FREE);
						downgradeAction.current = () => {
							setCancelRefreshFlag(true);
							props.onCancel();
						}
					}

					setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.DOWNGRADE", defaultMessage: 'Downgrade'}));
					if(props.projetcs === null){
						//no limit
						basicButtonAction.current = () => {
							showDowngradePlanConfirmationDialog();
							setDowngradeToPlan(Plan.BASIC);
							downgradeAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(showMonthly ? monthlyPlans.BASIC.price.id : annualPlans.BASIC.price.id);
							}
						}
					} else if(Array.isArray(props.projetcs)){
						const projectsLimit = monthlyPlans.BASIC.projectsNumber;
						const totalProjects = props.projetcs.length;
						// console.log('props.projetcs length', props.projetcs.length);
						// console.log('projectsNumber', monthlyPlans.BASIC.projectsNumber);
						if(totalProjects > projectsLimit){
							basicButtonAction.current = () => {
								setShowCannotSwitchPlanConfirmationDialog(true);
								setDowngradeToPlan(Plan.BASIC);
							}
						} else {
							basicButtonAction.current = () => {
								showDowngradePlanConfirmationDialog();
								setDowngradeToPlan(Plan.BASIC);
								downgradeAction.current = () => {
									setSwitchPaidPlanRefreshFlag(true);
									props.onChangePaidSubscription(showMonthly ? monthlyPlans.BASIC.price.id : annualPlans.BASIC.price.id);
								}
							}
						}
					}

					// This needs to provide the ability to switch from monthly to annual and vice versa
					if(props.currentPlan.period === Period.MONTHLY){
						if(showMonthly === true){
							setProfessionalButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
							professionalButtonAction.current = null;
							applyPurchaseButtonsStyle(Plan.PROFESSIONAL);
						} else {
							// monthly to annuallkqsjdljlk
							setProfessionalButtonLabel(
								intl.formatMessage({id: "PAYMENT.BUTTON.TO.ANNUAL", defaultMessage: 'Switch To Annual'}));
							professionalButtonAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(annualPlans.PROFESSIONAL.price.id);
							};
							applyPurchaseButtonsStyle(null);
						}
						// if downgrade is false then should be clickable: active
						// console.log('monthlyPlans', monthlyPlans);
						if(props.currentPlan.status === 'TRIALING') {
							updateDisabledDowngradeOptions();
						}
					} else {
						//annual
						if(showMonthly === false){
							setProfessionalButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
							professionalButtonAction.current = null;
							applyPurchaseButtonsStyle(Plan.PROFESSIONAL);
						} else {
							// monthly to annual
							setProfessionalButtonLabel(
								intl.formatMessage({id: "PAYMENT.BUTTON.TO.MONTHLY", defaultMessage: 'Switch To Monthly'}));
							professionalButtonAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(monthlyPlans.PROFESSIONAL.price.id);
							};
							applyPurchaseButtonsStyle(null);
						}
						if(props.currentPlan.status === 'TRIALING') {
							updateDisabledDowngradeOptions();
						}
					}

					setPremiumButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.PURCHASE", defaultMessage: 'Purchase'}));
					premiumButtonAction.current = () => {
						showUpgradePlanConfirmationDialog();
						setUpgradeToPlan(Plan.PROFESSIONAL);
						upgradeAction.current = () => {
							setSwitchPaidPlanRefreshFlag(true);
							props.onChangePaidSubscription(showMonthly ? monthlyPlans.PREMIUM.price.id : annualPlans.PREMIUM.price.id);
						}
					}
					break;
				case Plan.PREMIUM:
					setFreeButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.DOWNGRADE", defaultMessage: 'Downgrade'}));
					freeButtonAction.current = () => {
						showDowngradePlanConfirmationDialog();
						setDowngradeToPlan(Plan.FREE);
						downgradeAction.current = () => {
							setCancelRefreshFlag(true);
							props.onCancel();
						}
					}

					setBasicButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.DOWNGRADE", defaultMessage: 'Downgrade'}));
					if(props.projetcs === null){
						//no limit
						basicButtonAction.current = () => {
							showDowngradePlanConfirmationDialog();
							setDowngradeToPlan(Plan.BASIC);
							downgradeAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(showMonthly ? monthlyPlans.BASIC.price.id : annualPlans.BASIC.price.id);
							}
						}
					} else if(Array.isArray(props.projetcs)){
						const projectsLimit = monthlyPlans.BASIC.projectsNumber;
						const totalProjects = props.projetcs.length;
						// console.log('props.projetcs length', props.projetcs.length);
						// console.log('projectsNumber', monthlyPlans.BASIC.projectsNumber);
						if(totalProjects > projectsLimit){
							basicButtonAction.current = () => {
								setShowCannotSwitchPlanConfirmationDialog(true);
								setDowngradeToPlan(Plan.BASIC);
							}
						} else {
							basicButtonAction.current = () => {
								showDowngradePlanConfirmationDialog();
								setDowngradeToPlan(Plan.BASIC);
								downgradeAction.current = () => {
									setSwitchPaidPlanRefreshFlag(true);
									props.onChangePaidSubscription(showMonthly ? monthlyPlans.BASIC.price.id : annualPlans.BASIC.price.id);
								}
							}
						}
					}
					// basicButtonAction.current = () => {
					// 	showDowngradePlanConfirmationDialog();
					// 	setDowngradeToPlan(Plan.BASIC);
					// 	downgradeAction.current = () => {
					// 		setSwitchPaidPlanRefreshFlag(true);
					// 		props.onChangePaidSubscription(showMonthly ? monthlyPlans.BASIC.price.id : annualPlans.BASIC.price.id);
					// 	}
					// }


					setProfessionalButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.DOWNGRADE", defaultMessage: 'Downgrade'}));
					if(props.projetcs === null){
						//no limit
						professionalButtonAction.current = () => {
							showDowngradePlanConfirmationDialog();
							setDowngradeToPlan(Plan.PROFESSIONAL);
							downgradeAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(showMonthly ? monthlyPlans.PROFESSIONAL.price.id : annualPlans.PROFESSIONAL.price.id);
							}
						}
					} else if(Array.isArray(props.projetcs)){
						const projectsLimit = monthlyPlans.PROFESSIONAL.projectsNumber;
						const totalProjects = props.projetcs.length;
						// console.log('props.projetcs length', props.projetcs.length);
						// console.log('projectsNumber', monthlyPlans.BASIC.projectsNumber);
						if(totalProjects > projectsLimit){
							professionalButtonAction.current = () => {
								setShowCannotSwitchPlanConfirmationDialog(true);
								setDowngradeToPlan(Plan.PROFESSIONAL);
							}
						} else {
							professionalButtonAction.current = () => {
								showDowngradePlanConfirmationDialog();
								setDowngradeToPlan(Plan.PROFESSIONAL);
								downgradeAction.current = () => {
									setSwitchPaidPlanRefreshFlag(true);
									props.onChangePaidSubscription(showMonthly ? monthlyPlans.PROFESSIONAL.price.id : annualPlans.PROFESSIONAL.price.id);
								}
							}
						}
					}


					// This needs to provide ability to switch from monthly to annual and vice versa
					if(props.currentPlan.period === Period.MONTHLY){
						if(showMonthly === true){
							setPremiumButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
							premiumButtonAction.current = null;
							applyPurchaseButtonsStyle(Plan.PREMIUM);
						} else {
							// monthly to annual
							setPremiumButtonLabel(
								intl.formatMessage({id: "PAYMENT.BUTTON.TO.ANNUAL", defaultMessage: 'Switch To Annual'}));
							premiumButtonAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(annualPlans.PREMIUM.price.id);
							};
							applyPurchaseButtonsStyle(null);
						}
						if(props.currentPlan.status === 'TRIALING') {
							updateDisabledDowngradeOptions();
						}
					} else {
						//annual
						if(showMonthly === false){
							setPremiumButtonLabel(intl.formatMessage({id: "PAYMENT.CURRENT.PLAN", defaultMessage: 'Current Plan'}));
							premiumButtonAction.current = null;
							applyPurchaseButtonsStyle(Plan.PREMIUM);
						} else {
							// monthly to annual
							setPremiumButtonLabel(intl.formatMessage({id: "PAYMENT.BUTTON.TO.MONTHLY", defaultMessage: 'Switch To Monthly'}));
							premiumButtonAction.current = () => {
								setSwitchPaidPlanRefreshFlag(true);
								props.onChangePaidSubscription(monthlyPlans.PREMIUM.price.id);
							};
							applyPurchaseButtonsStyle(null);
						}
						if(props.currentPlan.status === 'TRIALING') {
							updateDisabledDowngradeOptions();
						}
					}
					break;
			}
			setShowPurchaseButtons(true);

			// Support Limit Downgrade
			// if(props.currentPlan.status === 'TRIALING'){
			//
			// }
		}

	}, [props.currentPlan, monthlyPlans, annualPlans, freePlan, showMonthly, props.projetcs]);

	const updateDisabledDowngradeOptions = () => {
		const monthly = [];
		// console.log('monthlyPlans', monthlyPlans);
		if(monthlyPlans.BASIC.downgrade === true) {
			monthly.push(Plan.BASIC);
		}
		if(monthlyPlans.PROFESSIONAL.downgrade === true) {
			monthly.push(Plan.PROFESSIONAL);
		}
		if(monthlyPlans.PREMIUM.downgrade === true) {
			monthly.push(Plan.PREMIUM);
		}
		// console.log('populated monthly', monthly);
		const annual = [];
		// console.log('annualPlans', annualPlans);
		if(annualPlans.BASIC.downgrade === true) {
			annual.push(Plan.BASIC);
		}
		if(annualPlans.PROFESSIONAL.downgrade === true) {
			annual.push(Plan.PROFESSIONAL);
		}
		if(annualPlans.PREMIUM.downgrade === true) {
			annual.push(Plan.PREMIUM);
		}
		// console.log('populated annual', annual);
		setDisabledDowngradeOptions({monthly: monthly, annual: annual})
	}

	const createSubscription = async (sessionId) => {
		const stripe = await stripePromise;
		const result = await stripe.redirectToCheckout({
			sessionId: sessionId
		});
		if (result.error) {
			// If `redirectToCheckout` fails due to a browser or network
			// error, display the localized error message to your customer
			// using `result.error.message`.
			setErrorMsg(result.error.message);
		}
	}

	const hideTransactionSuccessfullSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowTransactionSuccessfullSnackbar(false);
	};

	let showErrorMsg = null;
	if (errorMsg !== '') {
		showErrorMsg = <Alert elevation={6} variant="standard" severity="error" className='mt-6 mb-5'>
			<span className='font-size-h4'>{errorMsg}</span>
		</Alert>
	}



	const showTab = (tabNumber) => {
		if(tabNumber === 1){
			setShowMonthly(true);
		} else {
			setShowMonthly(false);
		}
	}

	const showDowngradePlanConfirmationDialog = () => {
		setShowDowngradeConfirmationDialog(true);
	}

	const hideDowngradePlanConfirmationDialog = () => {
		setShowDowngradeConfirmationDialog(false);
	};

	const showUpgradePlanConfirmationDialog = () => {
		setShowUpgradeConfirmationDialog(true);
	}

	const hideUpgradePlanConfirmationDialog = () => {
		setShowUpgradeConfirmationDialog(false);
	};


	if(completeInformationMsg !== ''){
		return <div className='bg-white h-100 d-flex align-items-center justify-content-center p-4'>
			<Alert elevation = {3} variant = "standard" severity = "info" >
				<h1 className = 'font-weight-light'>{completeInformationMsg}</h1>
		</Alert></div>;
	}
	let nextPlan = '', currentPeriodEndAt = '';
	if(props.currentPlan && props.currentPlan.nextPlan){
		nextPlan = props.currentPlan.nextPlan.price.name;
		currentPeriodEndAt = new Date(props.currentPlan.currentPeriodEndAt).toLocaleString();
	}


	return (
		<>
			{/*TransitionComponent={(props) => <Slide {...props} direction="down" />}>*/}
			<Container className='p-0'>
				<Row>
					<Col>
						<Button variant="contained" component={Link} color="primary" fullWidth={true}  size='large' to='/invoices'
								className='text-white text-hover-white mb-2  h4' style={{textTransform: 'none'}}>
							<FormattedMessage id="INVOICES.PAGE.HEADER" defaultMessage='Invoices'/>
						</Button>
					</Col>
					<Col md='auto' className='pl-0'>
						<Button variant="contained" size='large' style={{textTransform: 'none'}} color='secondary'
								className=' h4 mb-2' fullWidth={true}
							onClick={() => loadCurrentPlan()}>
							<FormattedMessage id="PAYMENT.BUTTON.REFRESH.STATS" defaultMessage='Refresh Stats'/>
						</Button>
					</Col>
				</Row>
			</Container>

			<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center',}}
					  open={showTransactionSuccessfullSnackbar}  onClose={hideTransactionSuccessfullSnackbar}
					  message={transactionSuccessfullSnackbarMessage}>
				<Alert
					// onClose={hideTransactionSuccessfullSnackbar}
					severity="success" className={`h2  mt-20 ${classes.snackbarMessageAlert}`}
					action={
						<IconButton color="inherit" onClick={hideTransactionSuccessfullSnackbar} >
							<CloseIcon/>
						</IconButton>
					}>
					{transactionSuccessfullSnackbarMessage}
				</Alert>
			</Snackbar>

			<Dialog
				open = {showDowngradeConfirmationDialog}
				onClose = {hideDowngradePlanConfirmationDialog}
				aria-labelledby = "alert-dialog-title"
				aria-describedby = "alert-dialog-description"
			>
				<DialogTitle id = "alert-dialog-title">
					<FormattedMessage id = "SPRINTS.TABLE.Plan"
									  defaultMessage = 'Downgrade Plan'/>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<FormattedMessage id = "PAYMENT.DOWNGRADE.CONFIRMATION.MESSAGE"
										  defaultMessage = 'Are you sure you want to downgrade to '/> {downgradeToPlan} ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick = {hideDowngradePlanConfirmationDialog} color = "primary">
						<FormattedMessage id = "BUTTON.CANCEL" defaultMessage = 'Cancel'/>
					</Button>
					<Button onClick = {() => {
						hideDowngradePlanConfirmationDialog();
						downgradeAction.current();
					}} color = "primary">
						<FormattedMessage id = "PAYMENT.BUTTON.DOWNGRADE" defaultMessage = 'Downgrade'/>
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open = {showCannotSwitchPlanConfirmationDialog}
				onClose = {() => setShowCannotSwitchPlanConfirmationDialog(false)}
				aria-labelledby = "alert-dialog-title"
				aria-describedby = "alert-dialog-description"
			>
				<DialogTitle id = "alert-dialog-title">
					<FormattedMessage id = "PAYMENT.DOWNGRADE.PLAN"
									  defaultMessage = 'Downgrade Plan'/>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<FormattedMessage id = "PAYMENT.DOWNGRADE.NOT.ALLOWED.MESSAGE.1"
										  defaultMessage = {`You currently have more projects than what's allowed for the `}/> {downgradeToPlan}
						<FormattedMessage id = "PAYMENT.DOWNGRADE.NOT.ALLOWED.MESSAGE.2"
										  defaultMessage = {` plan.`}/>
						<br/>
						<FormattedMessage id = "PAYMENT.DOWNGRADE.NOT.ALLOWED.MESSAGE.3"
										  defaultMessage = {`Remove projects to be able to match the maximum allowed for the `}/> {downgradeToPlan}
						<FormattedMessage id = "PAYMENT.DOWNGRADE.NOT.ALLOWED.MESSAGE.2"
										  defaultMessage = {` plan.`}/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick = {() => setShowCannotSwitchPlanConfirmationDialog(false)} color = "primary">
						<FormattedMessage id = "BUTTON.CANCEL" defaultMessage = 'Cancel'/>
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open = {showUpgradeConfirmationDialog}
				onClose = {hideUpgradePlanConfirmationDialog}
				aria-labelledby = "alert-dialog-title"
				aria-describedby = "alert-dialog-description"
			>
				<DialogTitle id = "alert-dialog-title">
					<FormattedMessage id = "PAYMENT.UPGRADE.PLAN"
									  defaultMessage = 'Upgrade Plan'/>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<FormattedMessage id = "PAYMENT.UPGRADE.CONFIRMATION.MESSAGE"
										  defaultMessage = 'Are you sure you want to upgrade to '/> {upgradeToPlan} ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick = {hideUpgradePlanConfirmationDialog} color = "primary">
						<FormattedMessage id = "BUTTON.CANCEL" defaultMessage = 'Cancel'/>
					</Button>
					<Button onClick = {() => {
						hideUpgradePlanConfirmationDialog();
						upgradeAction.current();
					}} color = "primary">
						<FormattedMessage id = "PAYMENT.BUTTON.UPGRADE" defaultMessage = 'Upgrade'/>
					</Button>
				</DialogActions>
			</Dialog>

			{showErrorMsg}


			<CurrentPlanInformationsJSX currentPlan={props.currentPlan}/>

			<PricingTableJSX showMonthly={showMonthly} showTab={showTab}
							 freePlan={freePlan} showPurchaseButtons={showPurchaseButtons}
							 freeButtonIsCurrent={freeButtonIsCurrent} freeButtonAction={freeButtonAction} freeButtonLabel={freeButtonLabel}
							 annualPlans={annualPlans} basicButtonIsCurrent={basicButtonIsCurrent}
							 basicButtonAction={basicButtonAction} basicButtonLabel={basicButtonLabel}
							 professionalButtonIsCurrent={professionalButtonIsCurrent}
							 professionalButtonAction={professionalButtonAction} professionalButtonLabel={professionalButtonLabel}
							 premiumButtonIsCurrent={premiumButtonIsCurrent} premiumButtonAction={premiumButtonAction}
							 premiumButtonLabel={premiumButtonLabel}
							 monthlyPlans={monthlyPlans}
							 disabledDowngradeOptions={disabledDowngradeOptions}
							 nextPlan={nextPlan}
							 currentPeriodEndAt={currentPeriodEndAt}
			/>
		</>
	);
}

const mapStateToProps = state => {
	return {
		currentPlan: state.payment.currentPlan,
		currentPlanLoading: state.payment.currentPlanLoading,
		currentPlanError: state.payment.currentPlanError,

		availablePlans: state.payment.availablePlans,
		availablePlansLoading: state.payment.availablePlansLoading,
		availablePlansError: state.payment.availablePlansError,

		checkPaymentError: state.payment.checkPaymentError,
		cancelPaidSubscriptionLoading: state.payment.cancelPaidSubscriptionLoading,
		cancelPaidSubscriptionError: state.payment.cancelPaidSubscriptionError,

		changePaidSubscriptionLoading: state.payment.changePaidSubscriptionLoading,
		changePaidSubscriptionError: state.payment.changePaidSubscriptionError,

		availablePaidCustomerPlans: state.payment.availablePaidCustomerPlans,
		availablePaidCustomerPlansLoading: state.payment.availablePaidCustomerPlansLoading,
		availablePaidCustomerPlansError: state.payment.availablePaidCustomerPlansError,

		checkPaymentLoading: state.payment.checkPaymentLoading,

		user: state.authentication.loggedInUser,

		projetcs: state.project.projects,


	};
};

const mapDispatchToProps = dispatch => {
	return {
		// onLoadSessionIDs: (projectID) => dispatch(actions.loadSessionIDs(projectID)),
		onLoadCurrentPlan: () => dispatch(actions.loadCurrentPlan()),
		onLoadPlans: (returnUrl) => dispatch(actions.loadPlans(returnUrl)),
		onCancel: () => dispatch(actions.cancelPaidSubscription()),
		onChangePaidSubscription: (priceId) => dispatch(actions.changePaidSubscription(priceId)), // pro to prem & prem to pro
		onCheckPayment: (session_id) => dispatch(actions.checkPayment(session_id)),
		onLoadPaidCustomerPlans: () => dispatch(actions.loadPaidCustomerPlans()),
		onDeletePaymentSessions: () => dispatch(actions.deletePaymentSessions()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);