import * as actionTypes from "./actionTypes";

export const createIteration = (sprintID, iteration) => {
	return {
		type: actionTypes.CREATE_ITERATION,
		iteration: iteration,
		sprintID: sprintID // ???
	}
};

export const createIterationStart = () => {
	return {
		type: actionTypes.CREATE_ITERATION_START
	}
};

export const createIterationSuccess = () => {
	return {
		type: actionTypes.CREATE_ITERATION_SUCCESS
	}
};

export const createIterationFail = (error) => {
	return {
		type: actionTypes.CREATE_ITERATION_FAIL,
		error: error
	}
};

export const loadIteration = (id) => {
	return {
		type: actionTypes.LOAD_ITERATION,
		id: id
	}
};

export const loadIterationStart = () => {
	return {
		type: actionTypes.LOAD_ITERATION_START
	}
};

export const loadIterationSuccess = (iteration) => {
	return {
		type: actionTypes.LOAD_ITERATION_SUCCESS,
		iteration: iteration
	}
};

export const loadIterationFail = (error) => {
	return {
		type: actionTypes.LOAD_ITERATION_FAIL,
		error: error
	}
};

export const updateIteration = (id, iteration) => {
	return {
		type: actionTypes.UPDATE_ITERATION,
		id: id,
		iteration: iteration
	}
};

export const updateIterationStart = () => {
	return {
		type: actionTypes.UPDATE_ITERATION_START
	}
};

export const updateIterationSuccess = () => {
	return {
		type: actionTypes.UPDATE_ITERATION_SUCCESS,
		iteration: null
	}
};

export const updateIterationFail = (error) => {
	return {
		type: actionTypes.UPDATE_ITERATION_FAIL,
		error: error
	}
};

export const executeIteration = (id, sprintID, projectId) => {
	return {
		type: actionTypes.EXECUTE_ITERATION,
		id: id,
		sprintID: sprintID,
		projectId: projectId
	}
};

export const executeIterationStart = (id, projectId) => {
	return {
		type: actionTypes.EXECUTE_ITERATION_START,
		id: id,
		projectId: projectId
	}
};

export const executeIterationSuccess = () => {
	return {
		type: actionTypes.EXECUTE_ITERATION_SUCCESS
	}
};

export const executeIterationFail = (error) => {
	return {
		type: actionTypes.EXECUTE_ITERATION_FAIL,
		error: error
	}
};

export const canCreateIteration = () => {
	return {
		type: actionTypes.CAN_CREATE_ITERATION
	}
};

export const canCreateIterationStart = () => {
	return {
		type: actionTypes.CAN_CREATE_ITERATION_START
	}
};

export const canCreateIterationSuccess = (canAdd) => {
	return {
		type: actionTypes.CAN_CREATE_ITERATION_SUCCESS,
		canAdd: canAdd
	}
};

export const canCreateIterationFail = (error) => {
	return {
		type: actionTypes.CAN_CREATE_ITERATION_FAIL,
		error: error
	}
};