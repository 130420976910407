import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, FormControl, Input, InputLabel, makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import {Col, Container, Row} from "react-bootstrap";
import SVG from "react-inlinesvg";

import * as AssertionResponseBodyCondition from "./enum/AssertionResponseBodyCondition";
import * as AssertionQuantityCondition from "./enum/AssertionQuantityCondition";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import * as AssertionType from "./enum/AssertionType";

const useStyles = () => makeStyles(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	}
}));

const RestRequestTabAssertions = props => {
	const intl = useIntl()
	const classes = useStyles();
	const [values, setValues] = useState(props.assertions);

	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		setValues(props.assertions);
	}, [props.assertions]);

	const addAssertion = () => {
		const updatedValues = [...values];
		updatedValues.push({
			type: AssertionType.STATUS_CODE,
			responseBodyCondition: AssertionResponseBodyCondition.CONTAINS,
			quantityCondition: AssertionQuantityCondition.MATCHES,
			value: ''
		});
		setValues(updatedValues);
		props.setAssertions(updatedValues);
	}

	const deleteAssertion = (index) => {
		const updatedValues = [...values];
		updatedValues.splice(index, 1);
		setValues(updatedValues);
		props.setAssertions(updatedValues);
	}

	const updateKeyValue = (value, index, inputNumber = 0) => {
		// Validation < 2000
		if(value.length > 20_000){
			setErrorMessage(intl.formatMessage({
				id: "REST.REQUEST.FORM.ASSERTION.VALUE.LENGTH.VALIDATION.MESSAGE",
				defaultMessage: 'Last Entry is more than 2000 in length'
			}));
		} else {
			const updatedValues = [...values];
			const currentElement = {...updatedValues[index]};
			// console.log('updateKeyValue value:', value);
			switch (inputNumber) {
				case 0:
					currentElement.type = value;
					break;
				case 1:
					// console.log('responseBodyCondition value:', value);
					currentElement.responseBodyCondition = value;
					break;
				case 2:
					currentElement.quantityCondition = value;
					break;
				case 3:
					currentElement.value = value;
					break;
				default:
					break;
			}
			// console.log('updatedValues before:', updatedValues);
			updatedValues[index] = currentElement;
			// console.log('updatedValues after:', updatedValues);
			setValues(updatedValues);
			setErrorMessage('');
			props.setAssertions(updatedValues);
		}
	}

	const onBlur = () => {
		props.setAssertions(values);
	}

	let keyValueInputSection = values.map((assertion, index) => {
		const type = <FormControl className = {`${classes.formControl}`} fullWidth={true}>
			<InputLabel htmlFor = "assert-type">
				<FormattedMessage id="REST.REQUEST.FORM.ASSERTION.TYPE.LABEL"
				                  defaultMessage='Type'/>
			</InputLabel>
			<Select
				value = {assertion.type}
				onChange = {event => updateKeyValue(event.target.value, index, 0)}
				input = {<Input id = "assert-type"/>}
			>

				{AssertionType.AssertionTypeObjects.map(type => {
					return <MenuItem key = {type.value}
					                 value = {type.value}>{type.label}</MenuItem>
				})}
			</Select>
		</FormControl>;

		let condition = null;
		switch (assertion.type) {
			case AssertionType.RESPONSE_BODY:
				condition = <FormControl className = {`${classes.formControl}`}
				                                      fullWidth={true}>
					<InputLabel htmlFor = "condition">
						<FormattedMessage id="REST.REQUEST.FORM.ASSERTION.CONDITION.LABEL"
						                  defaultMessage='Condition'/>
					</InputLabel>
					<Select
						value = {assertion.responseBodyCondition}
						onChange = {event => updateKeyValue(event.target.value, index, 1)}
						input = {<Input id = "condition"/>}
					>

						{AssertionResponseBodyCondition.AssertionResponseBodyConditionObjects.map(condition => {
							return <MenuItem key = {condition.value}
							                 value = {condition.value}>{condition.label}</MenuItem>
						})}
					</Select>
				</FormControl>;
				break;
			case AssertionType.STATUS_CODE:
				condition = <FormControl className = {`${classes.formControl}`}
				                         fullWidth={true}>
					<InputLabel htmlFor = "condition">
						<FormattedMessage id="REST.REQUEST.FORM.ASSERTION.CONDITION.LABEL"
						                  defaultMessage='Condition'/>
					</InputLabel>
					<Select
						value = {assertion.quantityCondition}
						onChange = {event => updateKeyValue(event.target.value, index, 2)}
						input = {<Input id = "condition"/>}
					>

						{AssertionQuantityCondition.AssertionQuantityConditionObjects.map(condition => {
							return <MenuItem key = {condition.value}
							                 value = {condition.value}>{condition.label}</MenuItem>
						})}
					</Select>
				</FormControl>;
				break;
			default: break;
		}

		let value = <TextField
			id = {'value' + index} name = {'value' + index}
			label = {intl.formatMessage({
				id: "REST.REQUEST.FORM.ASSERTION.VALUE.LABEL",
				defaultMessage: 'Value'
			}) + ' ' + (index + 1)}
			className = {`${classes.textField}`}
			value = {assertion.value}
			onChange = {event => updateKeyValue(event.target.value, index, 3)}
			onBlur = {onBlur}
			margin = "normal"
			variant = "outlined"
			placeholder = {intl.formatMessage({
				id: "REST.REQUEST.FORM.ASSERTION.VALUE.PLACEHOLDER",
				defaultMessage: 'Value'
			}) + ' ' + (index + 1)}
		/>;
		if(assertion.type === AssertionType.RESPONSE_BODY
			&& assertion.responseBodyCondition === AssertionResponseBodyCondition.IS_JSON){
			value = null;
		}

		return <Row key = {index} className='align-items-center'>
			<Col md = {3} xs = {12}>
				{type}
			</Col>
			<Col md = {4} xs = {12}>
				{condition}
			</Col>
			<Col md = {4} xs = {11}>
				{value}
			</Col>
			<Col className='mt-3'>
				<a title = {intl.formatMessage({
					id: "REST.REQUEST.FORM.ASSERTION.BUTTON.HOVER.DELETE.ASSERTION",
					defaultMessage: 'Delete Assertion '}) + (index +1)}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				   onClick = {() => deleteAssertion(index)}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
			          <SVG
				          title = {intl.formatMessage({
					          id: "REST.REQUEST.FORM.ASSERTION.BUTTON.HOVER.DELETE.ASSERTION",
					          defaultMessage: 'Delete Assertion '
				          }) + (index +1)}
				          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</Col>
		</Row>
	});

	let view = <Container className='px-0'>
		<Row>
			<Col>
				<Button variant = "outlined" className='mr-6' startIcon={<AddIcon/>}
				        onClick={addAssertion} color='secondary'>
					<FormattedMessage id="REST.REQUEST.FORM.ASSERTION.BUTTON.ADD.ASSERTION"
					                  defaultMessage='Assertion'/>
				</Button>
			</Col>
		</Row>
		{errorMessage !== '' && <Alert elevation = {3} variant = "standard" severity = "error" className = 'my-3'>
			{errorMessage}
		</Alert>}
		{keyValueInputSection}
	</Container>

	return view;

}

export default RestRequestTabAssertions;