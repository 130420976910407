import * as actionTypes from "./actionTypes";

export const loadSummaryChartData = (projectID) => {
	return {
		type: actionTypes.LOAD_SUMMARY_CHART_DATA,
		projectID: projectID
	}
};

export const loadSummaryChartDataStart = () => {
	return {
		type: actionTypes.LOAD_SUMMARY_CHART_DATA_START
	}
};

export const loadSummaryChartDataSuccess = (lastFiveExecutions, lastExecution) => {
	return {
		type: actionTypes.LOAD_SUMMARY_CHART_DATA_SUCCESS,
		lastFiveExecutions: lastFiveExecutions,
		lastExecution: lastExecution
	}
};

export const loadSummaryChartDataFail = (error) => {
	return {
		type: actionTypes.LOAD_SUMMARY_CHART_DATA_FAIL,
		error: error
	}
};

export const loadDashboardStats = (userId, projectId) => {
	return {
		type: actionTypes.LOAD_DASHBOARD_STATISTIQUES,
		projectId: projectId,
		userId: userId
	}
};

export const loadDashboardStatsStart = () => {
	return {
		type: actionTypes.LOAD_DASHBOARD_STATISTIQUES_START
	}
};

export const loadDashboardStatsSuccess = (dashboard) => {
	return {
		type: actionTypes.LOAD_DASHBOARD_STATISTIQUES_SUCCESS,
		dashboard: dashboard
	}
};

export const loadDashboardStatsFail = (error) => {
	return {
		type: actionTypes.LOAD_DASHBOARD_STATISTIQUES_FAIL,
		error: error
	}
};