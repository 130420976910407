import React from "react";
import {makeStyles} from "@material-ui/styles";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@material-ui/core";

import {history} from "../../../redux/store";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

// TODO: see if update needs snackbar
const ProjectActions = props => {

	const intl = useIntl();
	const classes = useStyles();

	return <div className = {classes.buttons}>
		<Button variant = "contained" type = "submit" className = 'mr-6'
		        disabled={props.creationLoading || props.updatingLoading}>
			{props.type === 'create' ?
				<FormattedMessage id = "BUTTON.CREATE" defaultMessage = 'Create'/> :
				<FormattedMessage id = "BUTTON.UPDATE" defaultMessage = 'Update'/>}
		</Button>
		<Button variant = "contained" className = 'mr-6'
		        onClick = {(event) => {
			        event.preventDefault();
			        history.goBack();
		        }}>
			{props.type === 'create' ?
				<FormattedMessage id = "BUTTON.CANCEL" defaultMessage = 'Cancel'/> :
				<FormattedMessage id = "BUTTON.BACK" defaultMessage = 'Back'/>}
		</Button>
	</div>;

}

export default ProjectActions;