export const CHROME = "CHROME";
export const FIREFOX = "FIREFOX";
export const EDGE = "EDGE";

export const BrowserTypeObjects = [
	{id: 1, label: 'Chrome', value: CHROME},
	{id: 2, label: 'Firefox', value: FIREFOX},
	{id: 2, label: 'Edge', value: EDGE}
];

export const BrowserTypeValues = [
	CHROME, FIREFOX, EDGE
];

export const getLabel = (value) => {
	if(!value) return '';
	return BrowserTypeObjects.find((browser) => {
		return browser.value === value;
	}).label;
}