// BrowserVersion
export const LATEST = "LATEST";
export const LATEST_1 = "LATEST_1";
export const LATEST_2 = "LATEST_2";

export const BrowserVersionObjects = [
	{
		// id: 1,
		label: 'Latest', value: LATEST},
	{
		// id: 2,
		label: 'Latest - 1', value: LATEST_1},
	{
		// id: 2,
		label: 'Latest - 2', value: LATEST_2},
];

export const BrowserVersionValues = [LATEST, LATEST_1, LATEST_2];

export const getLabel = (value) => {
	if(!value) return '';
	return BrowserVersionObjects.find((version) => {
		return version.value === value;
	}).label;
}