import * as AuthenticationType from "../../components/Rest/enum/AuthenticationType";

export const validateCore = (validateCoreFormRef) => {
	if(typeof validateCoreFormRef.current === 'function' && !validateCoreFormRef.current()){
		// console.log('Core Form invalid');
		return false;
	}
	return true;
}

export const validateDescription = (validateDescriptionFormRef, setSelectTab) => {
	if (typeof validateDescriptionFormRef.current === 'function' && !validateDescriptionFormRef.current()) {
		// console.log('Description Form invalid');
		setSelectTab(0);
		return false;
	}
	return true;
}

export const validateParameters = (updatedGlobalMessage, parameters, intl, setErrorMessages,
                                   setSelectTab) => {
	if (parameters.length > 1) {
		for (let param of parameters) {
			if (param.key.trim() === '') {
				updatedGlobalMessage.push(intl.formatMessage({
						id: "REST.REQUEST.FORM.PARAMETERS.ERROR.MESSAGE",
						defaultMessage: 'All Parameters Keys must be entered'
					}
				));
				setErrorMessages(updatedGlobalMessage);
				setSelectTab(1);
				return false;
			}
		}
		// Key Duplication Validation
		for (let param of parameters) {
			let keyCount = 0;
			for (let current of parameters) {
				if (param.key.trim() === current.key.trim()) {
					keyCount++;
				}
			}
			if (keyCount > 1) {
				updatedGlobalMessage.push(intl.formatMessage({
						id: "REST.REQUEST.FORM.PARAMETERS.ERROR.DUPLICATION.MESSAGE",
						defaultMessage: 'A Key or more is duplicated!'
					}
				));
				setErrorMessages(updatedGlobalMessage);
				setSelectTab(1);
				return false;
			}
		}
	}
	return true;
}

export const validateHeaders = (updatedGlobalMessage, headers, intl, setErrorMessages, setSelectTab) => {
	if (headers.length > 1) {
		for (let header of headers) {
			if (header.key.trim() === '') {
				updatedGlobalMessage.push(intl.formatMessage({
						id: "REST.REQUEST.FORM.HEADERS.ERROR.MESSAGE",
						defaultMessage: 'All Headers Keys must be entered'
					}
				));
				setErrorMessages(updatedGlobalMessage);
				setSelectTab(2);
				return false;
			}
		}
		// Key Duplication Validation
		for (let param of headers) {
			let keyCount = 0;
			for (let current of headers) {
				if (param.key.trim() === current.key.trim()) {
					keyCount++;
				}
			}
			if (keyCount > 1) {
				updatedGlobalMessage.push(intl.formatMessage({
						id: "REST.REQUEST.FORM.HEADERS.ERROR.DUPLICATION.MESSAGE",
						defaultMessage: 'A Key or more is duplicated!'
					}
				));
				setErrorMessages(updatedGlobalMessage);
				setSelectTab(2);
				return false;
			}
		}
	}
	return true;
}

export const validateAuthentication = (authentication, validateAuthBasicFormRef, setSelectTab,
                                       setValidate, validateAuthTokenFormRef,
                                       validateAuthAPIKeyFormRef) => {
	switch (authentication.authType) {
		case AuthenticationType.NOAUTH:
			break;
		case AuthenticationType.BASIC_AUTH:
			if (typeof validateAuthBasicFormRef.current === 'function' && !validateAuthBasicFormRef.current()) {
				// console.log('Authentication Basic Form invalid');
				setSelectTab(4);
				setValidate({basic: true});
				return false;
			}
			break;
		case AuthenticationType.BEARER_TOKEN:
			if (typeof validateAuthTokenFormRef.current === 'function' && !validateAuthTokenFormRef.current()) {
				// console.log('Authentication Basic Form invalid');
				setSelectTab(4);
				setValidate({token: true});
				return false;
			}
			break;
		case AuthenticationType.API_KEY:
			if (typeof validateAuthAPIKeyFormRef.current === 'function' && !validateAuthAPIKeyFormRef.current()) {
				// console.log('Authentication API Key Form invalid');
				setSelectTab(4);
				setValidate({APIKey: true});
				return false;
			}
			break;
	}
	return true;
}

export const validateResponseCollection = (validateAuthResponseCollectionFormRef, setSelectTab) => {
	if(typeof validateAuthResponseCollectionFormRef.current === 'function' && !validateAuthResponseCollectionFormRef.current()){
		// console.log('Response Collection Form invalid');
		setSelectTab(6);
		return false;
	}
	return true;
}

// Empty String for value is a valid value
// export const validateAssertions = (updatedGlobalMessage, assertions, intl, setErrorMessages, setSelectTab) => {
// 	if (assertions.length > 1) {
// 		for (let assertion of assertions) {
// 			if (assertion.type === AssertionType.RESPONSE_BODY
// 				&& assertion.responseBodyCondition === AssertionResponseBodyCondition.IS_JSON) {
// 				continue;
// 			}
// 			if (assertion.value.trim() === '') {
// 				updatedGlobalMessage.push(intl.formatMessage({
// 						id: "REST.REQUEST.FORM.ASSERTION.ERROR.MESSAGE",
// 						defaultMessage: 'All Assertions Values must be entered'
// 					}
// 				));
// 				setErrorMessages(updatedGlobalMessage);
// 				setSelectTab(5);
// 				return false;
// 			}
// 		}
// 	}
// 	return true;
// }