import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	updatingLoading: false,
	updatingError: null,

	listAllLoading: false,
	listAllError: null,

	loading: false,
	error: null,
	suites: null, // initial null: loading. done: [] empty array
	suite: null,
	projectSuites: null,
	totalRecords: 0,
	refreshList: false,

	variables: [],
	variablesLoading: false,
	variablesError: null,
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.LOAD_SUITES_START:
			return updateObject(state, {
				listAllLoading: true,
				refreshList: false
			});
		case actionTypes.LOAD_SUITES_SUCCESS:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: null,
				suites: action.suites,
				totalRecords: action.totalRecords
			});
		case actionTypes.LOAD_SUITES_FAIL:
			return updateObject(state, {
				listAllLoading: false,
				listAllError: action.error,
				suites: []
			});
		case actionTypes.DELETE_SUITE_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.DELETE_SUITE_SUCCESS:
			let suitesCopy = [...state.suites];
			suitesCopy = suitesCopy.filter((suite) => {
				if(suite.id !== action.id){
					return true;
				}
			});
			return updateObject(state, {
				loading: false,
				error: null,
				suites: suitesCopy,
				suite: null,
				refreshList: true
			});
		case actionTypes.DELETE_SUITE_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		case actionTypes.CREATE_SUITE_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.CREATE_SUITE_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				suite: action.suite
			});
		case actionTypes.CREATE_SUITE_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				suite: null
			});
		case actionTypes.LOAD_SUITE_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_SUITE_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				suite: action.suite
			});
		case actionTypes.LOAD_SUITE_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				suite: null
			});
		case actionTypes.UPDATE_SUITE_START:
			return updateObject(state, {
				updatingLoading: true,
				updatingError: null
			});
		case actionTypes.UPDATE_SUITE_SUCCESS:
			const mergeSuite = updateObject(state.suite, {
				...action.suite
			});
			const mergeSuites = state.suites.map((suite) => {
				if(suite.id === mergeSuite.id){
					const copySuite = {...mergeSuite};
					delete copySuite.indexedCases
					delete copySuite.prerequisites
					delete copySuite.postrequisites
					delete copySuite.testCases
					return copySuite;
				}
				return suite;
			});
			return updateObject(state, {
				suite: mergeSuite,
				suites: mergeSuites,
				updatingLoading: false
			});
		case actionTypes.UPDATE_SUITE_FAIL:
			return updateObject(state, {
				updatingLoading: false,
				updatingError: action.error,
			});
		case actionTypes.LOAD_PROJECT_SUITES_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOAD_PROJECT_SUITES_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				projectSuites: action.projectSuites
			});
		case actionTypes.LOAD_PROJECT_SUITES_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				projectTests: []
			});
		case actionTypes.LOAD_VARIABLES_FOR_SUITES_START:
			return updateObject(state, {
				variables: [],
				variablesLoading: true,
				variablesError: null,
			});
		case actionTypes.LOAD_VARIABLES_FOR_SUITES_SUCCESS:
			return updateObject(state, {
				variables: action.variables,
				variablesLoading: false,
			});
		case actionTypes.LOAD_VARIABLES_FOR_SUITES_FAIL:
			return updateObject(state, {
				variables: [],
				variablesLoading: false,
				variablesError: action.error
			});
		default: {
			return state;
		}
	}
}

export default reducer;