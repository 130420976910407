import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {FormattedMessage, useIntl} from "react-intl";
import {Column} from "primereact/column";
import {MultiSelect} from "primereact/multiselect";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";

import {RestRequestDeletionLoadingDialog, RestRequestsLoadingDialog} from "../../UI/LoadingDialog";
import {HttpMethodObjects} from "../Rest/enum/HttpMethod";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));
const DomainRestRequestsDisplay = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [toBeRemovedRequest, setToBeRemovedRequest] = useState(null); // Label also needed
	const [toBeRemovedRequestConfirmationDialogOpen, setToBeRemovedRequestConfirmationDialogOpen] = useState(false);

	let requestCreatedSuccessfully = null;

	if(props.label){
		requestCreatedSuccessfully = <Alert elevation = {3} variant = "standard"
		                                    severity = "success" className = 'mb-2'>
			{props.label} Added
		</Alert>
	}

	let errorMessages = props.errorMessages.map((error, index) => {
		return <Alert elevation = {3} variant = "standard" key={index}
		              severity = "error" className = 'mb-2'>
			{error}
		</Alert>
	});

	const methodFilter = <MultiSelect value = {props.selectedMethods}
	                                  options = {HttpMethodObjects}
	                                  onChange = {props.onMethodChange} optionLabel = "label"
	                                  optionValue = "value"
	                                  placeholder = {intl.formatMessage({id: "ALL", defaultMessage: 'All'})}
	                                  className = "p-column-filter"/>;

	// Removing
	const closeRemoveConfirmationDialog = () => {
		setToBeRemovedRequestConfirmationDialogOpen(false);
	};

	const restRequestsActionsBodyTemplate = (rowData) => {

		return (
			<div className = {` ${classes.root}`}>
				<Link to = {`/rest-request/${rowData.id}`}
				      title = {intl.formatMessage({
					      id: "REST.REQUESTS.TABLE.ACTIONS.UPDATE.REST.REQUEST", defaultMessage: 'Update Rest Request'
				      })}
				      className = "btn btn-icon btn-light btn-hover-primary btn-sm"
				>
					<span className = "svg-icon svg-icon-md svg-icon-primary">
					    <SVG title = {intl.formatMessage({
						    id: "REST.REQUESTS.TABLE.ACTIONS.UPDATE.REST.REQUEST", defaultMessage: 'Update Rest Request'
					    })}
							 src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
					    />
					</span>
				</Link>
				<a title = {intl.formatMessage({
					id: "REST.REQUESTS.TABLE.HOVER.LINK.DELETE.REQUEST", defaultMessage: 'Delete Request'
				})}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm"
				   onClick = {() => {
					   setToBeRemovedRequest(rowData);
					   setToBeRemovedRequestConfirmationDialogOpen(true);
				   }}
				>
				        <span className = "svg-icon svg-icon-md svg-icon-danger">
				          <SVG
					          title = {intl.formatMessage({
						          id: "REST.REQUESTS.TABLE.HOVER.LINK.DELETE.REQUEST", defaultMessage: 'Delete Request'
					          })}
					          src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
				        </span>
				</a>
			</div>
		);
	}

	return <div className='bg-white mt-3'>
			<RestRequestsLoadingDialog/>
			<RestRequestDeletionLoadingDialog/>

			{requestCreatedSuccessfully}
			{errorMessages}
			<DataTable value={props.restRequests} ref={props.dt}
			           paginator
					   rows={10}
					   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
			           paginatorTemplate = "CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
			           rowsPerPageOptions = {[10, 20, 50, 100]}
			           emptyMessage={intl.formatMessage({ id: "REST.REQUESTS.EMPTY.LIST", defaultMessage: 'No Rest Requests'})}>
				<Column field="label" columnKey='label'
				        header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.NAME", defaultMessage: 'Name'})}
				        sortable
						filter filterPlaceholder={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.NAME.FILTER.PLACEHOLDER",
					defaultMessage: 'Search by name'})} filterMatchMode='contains'
				/>
				<Column field="method" columnKey='method'
				        header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.METHOD", defaultMessage: 'Method'})}
				        sortable
						filter filterPlaceholder="Search by Method" filterElement={methodFilter}
				/>
				<Column field="uri" columnKey='uri'
				        header={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.URL",
					        defaultMessage: 'URL'})}
				        sortable
						filter filterPlaceholder={intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.URL.FILTER.PLACEHOLDER",
					defaultMessage: 'Search by URL'})} filterMatchMode='contains'
				/>
				<Column key = 'actions'
				        header = {intl.formatMessage({ id: "REST.REQUESTS.TABLE.COLUMN.ACTIONS",
					        defaultMessage: 'Actions'})}
				        body = {restRequestsActionsBodyTemplate}
				        className = 'p-text-right'
				/>
			</DataTable>
			<DeletionConfirmationDialog open={toBeRemovedRequestConfirmationDialogOpen} closeDialog={closeRemoveConfirmationDialog}
										message={<FormattedMessage id="REST.REQUESTS.TABLE.REMOVE.REQUEST"
																   defaultMessage='Remove Request'/> }
										name={toBeRemovedRequest ? `${toBeRemovedRequest.label}` : ''}
										deleteAction={() => {
											closeRemoveConfirmationDialog();
											props.deleteRequest(toBeRemovedRequest.id);
										}}
			/>

		</div>;
}

export default DomainRestRequestsDisplay;