import * as actionTypes from "./actionTypes";

export const loadSprints = (projectID, config) => {
	return {
		type: actionTypes.LOAD_SPRINTS,
		projectID: projectID,
		config: config
	}
};

export const loadSprintsStart = () => {
	return {
		type: actionTypes.LOAD_SPRINTS_START
	}
};

export const loadSprintsSuccess = (sprints, totalRecords) => {
	return {
		type: actionTypes.LOAD_SPRINTS_SUCCESS,
		sprints: sprints,
		totalRecords: totalRecords
	}
};

export const loadSprintsFail = (error) => {
	return {
		type: actionTypes.LOAD_SPRINTS_FAIL,
		error: error
	}
}

export const createSprint = (projectID, sprint) => {
	return {
		type: actionTypes.CREATE_SPRINT,
		sprint: sprint,
		projectID: projectID
	}
};

export const createSprintStart = () => {
	return {
		type: actionTypes.CREATE_SPRINT_START
	}
};

export const createSprintSuccess = () => {
	return {
		type: actionTypes.CREATE_SPRINT_SUCCESS
	}
};

export const createSprintFail = (error) => {
	return {
		type: actionTypes.CREATE_SPRINT_FAIL,
		error: error
	}
};

export const loadSprint = (id) => {
	return {
		type: actionTypes.LOAD_SPRINT,
		id: id
	}
};

export const loadSprintStart = () => {
	return {
		type: actionTypes.LOAD_SPRINT_START
	}
};

export const loadSprintSuccess = (sprint) => {
	return {
		type: actionTypes.LOAD_SPRINT_SUCCESS,
		sprint: sprint
	}
};

export const loadSprintFail = (error) => {
	return {
		type: actionTypes.LOAD_SPRINT_FAIL,
		error: error
	}
};

export const updateSprint = (id, sprint, stateSprint) => {
	return {
		type: actionTypes.UPDATE_SPRINT,
		id: id,
		sprint: sprint,
		stateSprint: stateSprint
	}
};

export const updateSprintStart = () => {
	return {
		type: actionTypes.UPDATE_SPRINT_START
	}
};

export const updateSprintSuccess = (sprint) => {
	return {
		type: actionTypes.UPDATE_SPRINT_SUCCESS,
		sprint: sprint
	}
};

export const updateSprintFail = (error) => {
	return {
		type: actionTypes.UPDATE_SPRINT_FAIL,
		error: error
	}
};

export const deleteSprint = (id, redirect) => {
	return {
		type: actionTypes.DELETE_SPRINT,
		id: id,
		redirect: redirect
	}
};

export const deleteSprintStart = () => {
	return {
		type: actionTypes.DELETE_SPRINT_START
	}
};

export const deleteSprintSuccess = (id) => { // for removal from red state
	return {
		type: actionTypes.DELETE_SPRINT_SUCCESS,
		id: id
	}
};

export const deleteSprintFail = (error) => {
	return {
		type: actionTypes.DELETE_SPRINT_FAIL,
		error: error
	}
};