import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

export const initialState = {
	loading: false,
	authenticated: false,
	error: null,
	username: '',
	loggedInUser: null,
	activated: null,

	authCheckFinished: false,

	passwordForgotDone: false,
	passwordForgotError: null
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.LOGIN_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.LOGIN_SUCCESS:
			action.loggedInUser.langKey = action.loggedInUser.langKey.toLowerCase();
			return updateObject(state, {
				loading: false,
				authenticated: true,
				error: null,
				username: action.username,
				loggedInUser: action.loggedInUser
			});
		case actionTypes.LOGIN_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				authenticated: false
			});
		case actionTypes.LOGOUT_SUCCESS:
			return updateObject(state, {
				authenticated: false,
				error: null
			});
		case actionTypes.REGISTER_START:
			return updateObject(state, {
				registrationLoading: true
			});
		case actionTypes.REGISTER_SUCCESS:
			return updateObject(state, {
				registrationLoading: false,
				registrationError: null,
				userRegistered: true
			});
		case actionTypes.REGISTER_FAIL:
			return updateObject(state, {
				registrationLoading: false,
				registrationError: action.error
			});
		case actionTypes.RESET_PASSWORD_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.RESET_PASSWORD_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				successMessage: 'Password Reset Successfully'
			});
		case actionTypes.RESET_PASSWORD_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			})
		case actionTypes.CHANGE_PASSWORD_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.CHANGE_PASSWORD_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				successMessage: 'Password Changed Successfully'
			});
		case actionTypes.CHANGE_PASSWORD_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		case actionTypes.CHANGE_PASSWORD_SUCCESS_MESSAGE_TIMEOUT_EXECUTE:
			return updateObject(state, {
				successMessage: null
			});
		case actionTypes.ACTIVATE_ACCOUNT_START:
			return updateObject(state, {
				loading: true
			});
		case actionTypes.ACTIVATE_ACCOUNT_SUCCESS:
			return updateObject(state, {
				loading: false,
				error: null,
				activated: action.activated
			});
		case actionTypes.ACTIVATE_ACCOUNT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
				activated: false
			});
		case actionTypes.FORGOT_PASSWORD_START:
			return updateObject(state, {
				passwordForgotDone: false,
				passwordForgotError: null
			});
		case actionTypes.FORGOT_PASSWORD_SUCCESS:
			return updateObject(state, {
				passwordForgotDone: true,
				passwordForgotError: null
			});
		case actionTypes.FORGOT_PASSWORD_FAIL:
			return updateObject(state, {
				passwordForgotDone: true,
				passwordForgotError: action.error
			});
		case actionTypes.AUTH_CHECK_STATE_FINISHED:
			return updateObject(state, {
				authCheckFinished: true
			});
		default: {
			return state;
		}
	}
};

export default reducer;