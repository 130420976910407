import {delay, put} from "redux-saga/effects";
import {push} from "connected-react-router";
import jwt_decode from "jwt-decode";

import * as actions from "../actions";
import axios from "../../axios/axios";
import {extractRole} from "../utility";
import {setLanguage} from "../../../_metronic/i18n";

export function* login(action) {
	yield put(actions.loginStart());
	try {
		const response = yield axios.post("/authenticate", action.user);

		// let token = response.headers.authorization;
		let token = 'Bearer ' + response.data.id_token;
		// token = token.replace('Bearer ', '')
		localStorage.setItem("jwt", token);
		localStorage.setItem("username", action.user.username);
		axios.defaults.headers.common['Authorization'] = token;

		const decoded = jwt_decode(token);
		const expDate = new Date((+decoded.exp) * 1000);
		localStorage.setItem("expDate", expDate.valueOf().toString()); // as milliseconds
		localStorage.setItem("expDateReadeable", expDate.toISOString());
		localStorage.setItem("auth", decoded.auth);

		const loggedInUser = yield axios.get(`/users/${action.user.username}`);
		// const profile = yield axios.get(`/users/profile/${loggedInUser.data.id}`);
		// yield put(actions.updateProfileSuccess(profile.data));
		localStorage.setItem('Top-Role', extractRole(loggedInUser.data.authorities));
		// localStorage.setItem("expires", response.headers.expires); does not return one.
		yield put(actions.loginSuccess(action.user.username, loggedInUser.data));

		yield put(
			actions.fireTimeout(
				expDate.getTime() - new Date().getTime()
				// 10 * 1000 // ms
			)
		);
		// TimeZones
		// console.log('loading TimeZones');
		// yield put(actions.loadTimeZones());

		if(loggedInUser.data.langKey && localStorage.getItem('i18nConfig')){
			if(loggedInUser.data.langKey !== JSON.parse(localStorage.getItem('i18nConfig')).selectedLang){
				setLanguage(loggedInUser.data.langKey);
			}
		} else {
			const lang = localStorage.getItem('i18nConfig');
			if(!lang){
				setLanguage('en');
			}
		}
		yield put(push(`/dashboard`));

	} catch (error) {
		// TODO Change error.response.data.detail to ???
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(error.response.data.message);
			yield put(actions.loginFail(error.response.data.message));
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request)
			yield put(actions.loginFail('Error Login, Try Again'));
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			yield put(actions.loginFail(error.message));
		}
	}
}

export function* authCheckState(action) {
	const token = localStorage.getItem("jwt");
	const expDate = localStorage.getItem("expDate");
	if (!token) {
		yield put(actions.logout()); // set state to authenticated: false.
	} else {
		const expirationDate = new Date(+expDate);
		if (expirationDate <= new Date()) {
			yield put(actions.logout());
		} else {
			axios.defaults.headers.common['Authorization'] = token;
			const username = localStorage.getItem('username');
			// console.log('HERE');
			// yield put(actions.loginSuccess());  // TODO remove this line
			const loggedInUser = yield axios.get(`/users/${username}`);
			localStorage.setItem('Top-Role', extractRole(loggedInUser.data.authorities));
			yield put(actions.loginSuccess(username, loggedInUser.data));
			// TimeZones
			// yield put(actions.loadTimeZones());
			yield put(
				actions.fireTimeout(
					(expirationDate.getTime() - new Date().getTime())
					// 10 * 1000 // ms
				)
			);

			if(loggedInUser.data.langKey){
				if(loggedInUser.data.langKey !== JSON.parse(localStorage.getItem('i18nConfig')).selectedLang){
					setLanguage(loggedInUser.data.langKey);
				}
			} else {
				const lang = localStorage.getItem('i18nConfig');
				if(!lang){
					setLanguage('en');
				}
			}
		}

	}
	yield put(actions.authCheckStateFinished());
}

export function* logout(action) {
	// console.log('action.qureyParams', action.qureyParams);
	const lang = localStorage.getItem('i18nConfig');
	localStorage.clear();
	if(lang){
		localStorage.setItem('i18nConfig', lang);
	}
	// localStorage.removeItem("jwt");
	// localStorage.removeItem("username");
	// localStorage.removeItem("expDate");
	// localStorage.removeItem("auth");
	// localStorage.removeItem("expires");

	axios.defaults.headers.common['Authorization'] = null;
	yield put(actions.logoutSuccess());

	if(action.qureyParams){
		yield put(push(`/?${action.qureyParams}`));
	}
}

export function* register(action) {
	yield put(actions.registerStart());
	try {
		const response = yield axios.post("/register", action.user);
		if (response.status === 201) {
			yield put(actions.registerSuccess());
			// const loginAction = {
			// 	user: {
			// 		username: action.user.username,
			// 		password: action.user.password
			// 	}
			// }
			// yield login(loginAction);
		}
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.registerFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.registerFail('Error Registring The User, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.registerFail(error.message));
		}
	}
}

export function* fireTimeout(action) {
	yield delay(action.expirationTime);
	yield put(actions.logout());
}

export function* resetPassword(action) {
	yield put(actions.resetPasswordStart());
	try {
		const response = yield axios.post("/account/reset-password/finish", action.body);

		yield put(actions.resetPasswordSuccess());
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.resetPasswordFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.resetPasswordFail('Error Reseting The Password, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.resetPasswordFail(error.message));
		}
	}
}

export function* changePassword(action) {
	yield put(actions.changePasswordStart());
	try {
		const response = yield axios.post("/account/change-password", action.body);

		yield put(actions.changePasswordSuccess());
		yield put(actions.resetPasswordChangeSuccessMessageTimeout(10_000));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.changePasswordFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.changePasswordFail('Error Changing Password, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.changePasswordFail(error.message));
		}
	}
}

export function* firePasswordChangeSuccessMessageTimeout(action) {
	yield delay(action.delay);
	yield put(actions.resetPasswordChangeSuccessMessageTimeoutExecute());
}

export function* activateAccount(action) {
	yield put(actions.activateAccountStart());
	try {
		const response = yield axios.post(`/activate/${action.key}`);

		yield put(actions.activateAccountSuccess(true));
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.activateAccountFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.activateAccountFail('Error Activating Account, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.activateAccountFail(error.message));
		}
	}
}

export function* forgotPassword(action) {
	// yield put(actions.resetPasswordStart());
	try {
		const response = yield axios.post("/account/reset-password/init", action.email, {headers: {"Content-Type": "text/plain"}});

		yield put(actions.forgotPasswordSuccess());
	} catch (error) {
		if (error.response) {
			console.log(error.response.data.message);
			yield put(actions.forgotPasswordFail(error.response.data.message));
		} else if (error.request) {
			console.log(error.request)
			yield put(actions.forgotPasswordFail('Error Sending Forgot The Password Request, Try Again'));
		} else {
			console.log('Error', error.message);
			yield put(actions.forgotPasswordFail(error.message));
		}
	}
}
