import React, {useState} from "react";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import {useIntl} from "react-intl";

import IterationSuiteActions from "./IterationSuiteActions";
import IterationSuitesDisplay from "./IterationSuitesDisplay";
import UpdateIteration from "../../containers/Iteration/UpdateIteration";
import TabPanel from "../../UI/TabPanel";
import IterationDeviceGroupTab from "../../containers/Iteration/IterationDeviceGroupTab";
import * as Type from "../Project/enum/ProjectType";

const IterationDisplayTabs = props => {
	const intl = useIntl();

	const [tab, setTab] = useState(0);

	function tabChangeHandler(event, newValue) {
		setTab(newValue);
	}

	let sprintActions = <IterationSuiteActions {...props} />;
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		sprintActions = null;
	}

	// {display}

	return (
		<div className = "gutter-b mb-3">
			<AppBar position = "static" color='transparent' elevation={0}>
				<Tabs value = {tab} onChange = {tabChangeHandler} >
					<Tab label = {intl.formatMessage({ id: "ITERATION.TAB.INFORMATIONS", defaultMessage: 'Informations'})}/>
					<Tab label = {intl.formatMessage({ id: "ITERATION.TAB.SUITES", defaultMessage: 'Suites'})}/>
					{(props.project?.type === Type.ANDROID || props.project?.type === Type.ANDROID_WEB) && <Tab label = {intl.formatMessage({ id: "ITERATION.TAB.DEVICE.GROUP", defaultMessage: 'Device Group'})}/>}
				</Tabs>
			</AppBar>

			<TabPanel value={tab} index={0} py={0}>
				<UpdateIteration {...props}/>
			</TabPanel>

			<TabPanel value={tab} index={1} py={0}>
				<>
					{sprintActions}
					<IterationSuitesDisplay {...props} iterationName = {props.iteration.label}
					/>
				</>
			</TabPanel>

			<TabPanel value={tab} index={2} py={0}>
				<>
					<IterationDeviceGroupTab iteration={props.iteration}/>
					{/*TODO new Container for iteration */}
					{/*<IterationSuitesDisplay {...props} iterationName = {props.iteration.label}/>*/}
				</>
			</TabPanel>

		</div>
	)
}

export default IterationDisplayTabs;