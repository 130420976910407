import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import {FormattedMessage} from "react-intl";

import "../../../../_metronic/_assets/sass/pages/wizard/wizard-1.scss"
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

const AndroidEnvironmentProgressStepper = props => {

	const [step, setStep] = useState(1);

	// console.log('Render step:', step);

	useEffect(() => {
		const interval = setInterval(() => {
			// console.log('Intervale step', step);
			if(step === 4){
				clearInterval(interval);
				return;
			}
			setStep(step + 1);
		}, 5_000);
		return () => clearInterval(interval);
	}, [step]);

	let state;
	let Step1State, Step2State, Step3State, Step4State;
	switch (step) {
		case 1:
			state = 'first';
			Step1State = 'current';
			Step2State = Step3State = Step4State = 'pending';
			break;
		case 2:
			state = 'between';
			Step1State = 'done';
			Step2State = 'current';
			Step3State = Step4State = 'pending';
			break;
		case 3:
			state = 'between';
			Step1State = Step2State = 'done';
			Step3State = 'current';
			Step4State = 'pending';
			break;
		case 4:
			state = 'between';
			Step1State = Step2State = Step3State = 'done';
			Step4State = 'current';
			// Step5State = 'pending';
			break;
		// case 5:
		// 	state = 'last';
		// 	Step1State = Step2State = Step3State = Step4State = 'done';
		// 	// Step5State = 'current';
		// 	break;
	}



	return <div className='bg-white'>
				<div className="wizard wizard-1" id="kt_wizard" data-wizard-state={state} data-wizard-clickable="false">{/* first, last */}
					<div className="wizard-nav border-bottom" >
						<div className="wizard-steps p-8 ">

							<div className="wizard-step" data-wizard-type="step" data-wizard-state={Step1State}>
								<div className="wizard-label">
									<i className="wizard-icon flaticon-rocket"></i>
									<h3 className="wizard-title">
										<FormattedMessage id="ANDROID.RECORDER.STEPPER.PHASE1"
														  defaultMessage="1. Preparing your environment"/></h3>
								</div>
								<span className="svg-icon svg-icon-xl wizard-arrow">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}/>
				</span>
							</div>

							<div className="wizard-step" data-wizard-type="step" data-wizard-state={Step2State}>
								<div className="wizard-label">
									<i className="wizard-icon flaticon-upload-1"></i>
									<h3 className="wizard-title">
										<FormattedMessage id="ANDROID.RECORDER.STEPPER.PHASE2"
														  defaultMessage="2. Allocating a cloud machine"/></h3>
								</div>
								<span className="svg-icon svg-icon-xl wizard-arrow">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}/>
				</span>
							</div>

							<div className="wizard-step" data-wizard-type="step" data-wizard-state={Step3State}>
								<div className="wizard-label">
									<i className="wizard-icon flaticon2-settings"></i>
									<h3 className="wizard-title">
										<FormattedMessage id="ANDROID.RECORDER.STEPPER.PHASE3"
														  defaultMessage="3. Configuring the operating system"/>
									</h3>
								</div>
								<span className="svg-icon svg-icon-xl wizard-arrow">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}/>
				</span>
							</div>

							<div className="wizard-step" data-wizard-type="step" data-wizard-state={Step4State}>
								<div className="wizard-label">
									<i className="wizard-icon flaticon-responsive"></i>
									<h3 className="wizard-title">
										<FormattedMessage id="ANDROID.RECORDER.STEPPER.PHASE4"
														  defaultMessage="4. Preparing mobile"/>
									</h3>
								</div>
								<span className="svg-icon svg-icon-xl wizard-arrow">
					<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}/>
				</span>
							</div>

							{/*<div className="wizard-step" data-wizard-type="step" data-wizard-state={Step5State}>*/}
							{/*	<div className="wizard-label">*/}
							{/*		<i className="wizard-icon flaticon-globe"></i>*/}
							{/*		<h3 className="wizard-title">5. Review and Submit</h3>*/}
							{/*	</div>*/}
							{/*	<span className="svg-icon svg-icon-xl wizard-arrow last">*/}
							{/*	<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}/>*/}
							{/*</span>*/}
							{/*</div>*/}
						</div>
						{/*<div className='p-8 bg-primary-o-50'>*/}
						{/*	<h6>*/}
						{/*		<FormattedMessage id="ANDROID.RECORDER.STEPPER.DESCRIPTION"*/}
						{/*						  defaultMessage="You can continue to use the application and when the server is done, we will open your android*/}
						{/*				  recording environment in a new tab automatically ,even if you navigate to a different page on the site."/>*/}
						{/*	</h6>*/}
						{/*	<Alert severity="error">*/}
						{/*		<FormattedMessage id="ANDROID.RECORDER.STEPPER.ALERT"*/}
						{/*						  defaultMessage="DON'T CLOSE THIS BROWSER TAB/WINDOW"/></Alert>*/}
						{/*</div>*/}
					</div>
				</div>
			</div>;
}

export default AndroidEnvironmentProgressStepper;
