import {Card} from "react-bootstrap";
import React, {useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import SVG from "react-inlinesvg";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";

import {components, getComponentLabelForName, getOperationLabelForName, operations} from "../Instruction/ActionTypes";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";

const TestAssertDisplay = props => {
	const intl = useIntl();

	const deleteAssert = (assert) => {
		let asserts = [...props.asserts];
		asserts.splice(props.asserts.indexOf(assert), 1);
		props.setAsserts(asserts);
		props.updateAsserts(asserts);
		handleClick(intl.formatMessage({
			id: "TEST.SNACKBAR.ASSERT.RESULT.DELETED",
			defaultMessage: 'Assert Deleted'
		}));

	}

	const [open, setOpen] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');

	const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);

	const [assert, setAssert] = React.useState(null);

	const handleRemoveDialogOpen = () => {
		setRemoveDialogOpen(true);
	};

	const handleRemoveDialogClose = () => {
		setRemoveDialogOpen(false);
	};

	const handleClick = (message) => {
		setOpen(true);
		setAlertMessage(message);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const [errorMessage, setErrorMessage] = useState(null);
	const onEditorValueChange = (tableProps, value) => {
		if(value.length > 2_000){
			setErrorMessage(intl.formatMessage({
				id: "INSTRUCTION.FORM.LENGTH.VALIDATION.MESSAGE",
				defaultMessage: 'Last Entry is more than 2 000 in length'
			}));
			return;
		} else {
			setErrorMessage(null);
		}
		let updatedAsserts = [...tableProps.value];
		updatedAsserts[tableProps.rowIndex][tableProps.field] = value;
		props.setAsserts(updatedAsserts);
		// console.log('updatedAsserts', updatedAsserts);
	}

	const patch = () => {
		setErrorMessage(null);
		props.updateAsserts(props.asserts);
	}

	const operationEditor = (tableProps) => {
		return (
			// <DropdownBoot
			// 	// style={{overflow: 'visible'}}
			// >
			// 	<DropdownBoot.Toggle variant="light">
			// 		{tableProps.rowData['operation']}
			// 	</DropdownBoot.Toggle>
			//
			// 	<DropdownBoot.Menu
			// 		style={{overflowY: 'scroll', minHeight: '200px', height: '300px', }}>
			// 		{oprations.map(operation => {
			// 			return <DropdownBoot.Item key = {operation.name}
			// 			                          onClick={(e) => {
			// 				                          // console.log('action.name:', action.name);
			// 				                          onEditorValueChange(tableProps, operation.name);
			// 				                          patch();
			// 			                          }}
			// 			                          value = {operation.name}>{operation.name}</DropdownBoot.Item>
			// 		})}
			// 	</DropdownBoot.Menu>
			// </DropdownBoot>
			<Dropdown value = {tableProps.rowData['operation']} options = {operations} optionLabel = "label"
					  optionValue = "name"
					  onChange = {(e) => {
				          onEditorValueChange(tableProps, e.value);
				          patch();
			          }}
					  style = {{width: '100%'}}
					  placeholder = {intl.formatMessage({
				          id: "TEST.TABLE.ASSERT.RESULT.SELECT.OPERATION",
				          defaultMessage: 'Select Operation'
			          })}
					  appendTo = {document.body}
			/>
		);
	}

	const operationBodyTemplate = (rowData) => {
		return getOperationLabelForName(rowData.operation);
	}

	const componentBodyTemplate = (rowData) => {
		return getComponentLabelForName(rowData.component);
	}

	const componentNameEditor = (tableProps) => {
		return inputTextEditor(tableProps, 'componentName');
	}

	const checkedValueEditor = (tableProps) => {
		return inputTextEditor(tableProps, 'checkedValue');
	}

	const componentDescriptionEditor = (tableProps) => {
		return inputTextEditor(tableProps, 'description');
	}

	const deleteBodyTemplate = (rowData) => {

		return <a
			title = {intl.formatMessage({
				id: "TEST.TABLE.ASSERT.RESULT.DELETE",
				defaultMessage: 'Delete Assert'
			})}
			className = "btn btn-icon btn-light btn-hover-danger btn-sm"
			onClick = {
				// () => deleteAssert(rowData)
				() => {
					setAssert(rowData);
					handleRemoveDialogOpen();
				}
			}>
			<span className = "svg-icon svg-icon-md svg-icon-danger">
		        <SVG title = {intl.formatMessage({
			        id: "TEST.TABLE.ASSERT.RESULT.DELETE",
			        defaultMessage: 'Delete Assert'
		        })}
		             src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
	        </span>
		</a>;
	}

	let columns = [
		<Column key = 'description' columnKey = 'description' field = 'description'
		        header = {intl.formatMessage({
			        id: "INSTRUCTION.LABEL.NAME",
			        defaultMessage: 'Name'
		        })}
		        editor = {(props) => componentDescriptionEditor(props)}/>,
		<Column key = 'operation' columnKey = 'operation' field = 'operation'
		        header = {intl.formatMessage({
			        id: "INSTRUCTION.LABEL.OPERATION",
			        defaultMessage: 'Operation'
		        })}
		        body = {operationBodyTemplate} editor = {(props) => operationEditor(props)}/>,
		<Column key = 'component' columnKey = 'component' field = 'component'
		        header = {intl.formatMessage({
			        id: "INSTRUCTION.LABEL.COMPONENT",
			        defaultMessage: 'Component'
		        })}
		        body = {componentBodyTemplate} editor = {(props) => componentEditor(props)}/>,
		<Column key = 'componentName' columnKey = 'componentName' field = 'componentName'
		        header = {intl.formatMessage({
			        id: "INSTRUCTION.LABEL.IDENTIFIER",
			        defaultMessage: 'Component Identifier'
		        })}
				style={{width:'40%'}}
		        editor = {(props) => componentNameEditor(props)}/>,
		<Column key = 'checkedValue' columnKey = 'checkedValue' field = 'checkedValue'
		        header = {intl.formatMessage({
			        id: "INSTRUCTION.LABEL.VALUE",
			        defaultMessage: 'Value'
		        })}
		        editor = {(props) => checkedValueEditor(props)}/>,
		<Column key = 'actions'
		        header = {intl.formatMessage({
			        id: "TEST.TABLE.INSTRUCTION.COLUMN.ACTIONS",
			        defaultMessage: 'Actions'
		        })}
		        body = {deleteBodyTemplate}/>
	];
	let table = <DataTable value = {props.asserts}
	                       editMode = "cell" className = "editable-cells-table "
		// scrollable={true} scrollHeight='300px'
		// style={{height: '500px', overflow: 'visible'}}
		                   loading = {props.loading}
		                   autoLayout = {false}
						   resizableColumns columnResizeMode="fit"
	>
		{columns}
	</DataTable>;
	if (localStorage.getItem('Top-Role') === 'ROLE_USER' ||
		localStorage.getItem('Top-Role') === 'ROLE_CICD') {
		columns = [
			<Column key = 'description' columnKey = 'description' field = 'description'
			        header = {intl.formatMessage({
				        id: "INSTRUCTION.LABEL.NAME",
				        defaultMessage: 'Name'
			        })}/>,
			<Column key = 'operation' columnKey = 'operation' field = 'operation'
			        header = {intl.formatMessage({
				        id: "INSTRUCTION.LABEL.OPERATION",
				        defaultMessage: 'Operation'
			        })}/>,
			<Column key = 'component' columnKey = 'component' field = 'component'
			        header = {intl.formatMessage({
				        id: "INSTRUCTION.LABEL.COMPONENT",
				        defaultMessage: 'Component'
			        })}/>,
			<Column key = 'componentName' columnKey = 'componentName' field = 'componentName'
			        header = {intl.formatMessage({
				        id: "INSTRUCTION.LABEL.IDENTIFIER",
				        defaultMessage: 'Component Identifier'
			        })}
					style={{width:'40%'}}/>,
			<Column key = 'checkedValue' columnKey = 'checkedValue' field = 'checkedValue'
			        header = {intl.formatMessage({
				        id: "INSTRUCTION.LABEL.VALUE",
				        defaultMessage: 'Value'
			        })}/>];
		table = <DataTable value = {props.asserts}
		                   autoLayout = {false}
						   resizableColumns columnResizeMode="fit">
			{columns}
		</DataTable>
	}

	const componentEditor = (tableProps) => {
		return (
			<Dropdown value = {tableProps.rowData['component']} options = {components} optionLabel = "label"
			          optionValue = "name"
			          onChange = {(e) => {
				          onEditorValueChange(tableProps, e.value);
				          patch();
			          }}
			          style = {{width: '100%'}}
			          placeholder = {intl.formatMessage({
				          id: "TEST.TABLE.INSTRUCTION.SELECT.COMPONENT",
				          defaultMessage: 'Select Component'
			          })}
			          appendTo = {document.body}
				// itemTemplate = {(option) => {
				//     return <span className = {`product-badge status-instock`}>
				//         {option.name}
				//     </span>
				// }}
			/>
		);
	}

	const inputTextEditor = (tableProps, field) => {
		return <div className = 'p-fluid '>
			<div className = 'p-field p-grid'>
				<InputText type = "text"
				           className = ''
				           value = {tableProps.rowData[field]}
				           onChange = {(e) => onEditorValueChange(tableProps, e.target.value)}
				           onBlur = {() => patch()}
				           onKeyDown = {(event) => {
					           if (event.key === 'Enter' || event.keyCode === 13)
						           patch();
				           }}/>
			</div>
		</div>;
	}

	let display = null;

	if (!props.asserts) {
		// loading
	} else if (props.asserts.length === 0) {
		// empty domains
		display =
			<Alert variant = "outlined" severity = "info" className = 'my-6 h3'>
				<FormattedMessage id = "TEST.TABLE.ASSERT.RESULT.EMPTY"
				                  defaultMessage = 'No Asserts Exists'/>
			</Alert>
	} else {
		display = <Card className = "border-0 rounded-0 shadow-none">
			<Card.Header className='pt-4 pb-0'>
				<h3 className = 'text-center'>
					<FormattedMessage id = "TEST.TABLE.ASSERT.RESULT.TITLE"
				                  defaultMessage = 'Result'/>
				</h3>
			</Card.Header>
			<Card.Body>

				{errorMessage && <Alert elevation = {3} variant = "standard"
										severity = "error" className = 'my-3'>
					{errorMessage}
				</Alert>}
				
				{table}

				<DeletionConfirmationDialog open={removeDialogOpen} closeDialog={handleRemoveDialogClose}
											message={<FormattedMessage id = "TEST.TABLE.ASSERT.RESULT.DIALOG.REMOVE.ASSERT"
																	   defaultMessage = 'Remove Assert '/> }
											name={assert && assert.description ? `${assert.description}` : ''}
											deleteAction={() => {
												handleRemoveDialogClose();
												deleteAssert(assert);
											}}
				/>
				<Snackbar
					anchorOrigin = {{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open = {open}
					autoHideDuration = {2000}
					onClose = {handleClose}
					message = {alertMessage}
				>
					<Alert onClose = {handleClose} severity = "success">
						{alertMessage}
					</Alert>
				</Snackbar>
			</Card.Body>
		</Card>;
	}

	return display;
}

export default TestAssertDisplay;