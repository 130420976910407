import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from "@material-ui/styles";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";

import * as Type from "../Project/enum/ProjectType";
import ImportTests from "../../containers/Test/ImportTests";
import AndroidEnvironmentProgressStepper from "../Domain/component/AndroidEnvironmentProgressStepper";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const TestActions = props => {
	const intl = useIntl();
	const classes = useStyles();

	// const timeoutRef = useRef(null);

	// const [showAndroidRecordButton, setShowAndroidRecordButton] = useState(true);

	const startAndroidRecording = () => {
		props.prepareAndroidRecoringEnvironment();
		// const timeout = setTimeout(() => {
		// 	// console.log('INSIDE TIMEOUT');
		// 	props.onShowAndroidRecordingEnvironmentLoadingMessage();
		// }, 2000);
		// timeoutRef.current = timeout;
	}

	// useEffect(() => {
	// 	if(props.prepareAndroidRecoringEnvironmentLoading === false && timeoutRef.current !== null){
	// 		clearTimeout(timeoutRef.current);
	// 	}
	// }, [props.prepareAndroidRecoringEnvironmentLoading]);

	const stopAndroidRecording = () => {
		props.stopAndroidRecoringEnvironment();

	}

	const stopAndroidRecordingAndHideStopToContinueModal = () => {
		stopAndroidRecording();
		props.toggleAndroidRecordingEnvironmentStopToContinueMessage(false);
	}

	let showStartRecordForAndroid = true, showStopRecordForAndroid = false;
	let startRecordForAndroidEnabled = true, stopRecordForAndroidEnabled = true;

	if(props.androidEnvironmentStatus !== null){
		if(props.androidEnvironmentStatus.status === 'STOPED'){
			// showStartRecordForAndroid = true;
			showStopRecordForAndroid = false;
		} else if(props.androidEnvironmentStatus.status === 'CREATED'){
			// showStartRecordForAndroid = true;
			startRecordForAndroidEnabled= false
			showStopRecordForAndroid = false;
		} else if(props.androidEnvironmentStatus.status === 'RUNNING'){
			if(props.androidEnvironmentStatus.runningStatus === 'SAME_DOMAIN'){
				showStopRecordForAndroid = true;
			} else if(props.androidEnvironmentStatus.runningStatus === 'DIFFERENT_DOMAIN' ||
				props.androidEnvironmentStatus.runningStatus === 'DIFFERENT_USER')    { // unneccessary but explicit for code readability
				showStopRecordForAndroid = false;
			}
		}
	}

	if(
		props.prepareAndroidRecoringEnvironmentLoading ||
		props.stopAndroidRecoringEnvironmentLoading){
		startRecordForAndroidEnabled = false;
		stopRecordForAndroidEnabled= false
	}

	if(props.androidEnvironmentStatusError !== null) {
		showStartRecordForAndroid = false;
		showStopRecordForAndroid = false;
	}

	const stopAndroidRecordingButton = <Tooltip title={intl.formatMessage({id: "DOMAINS.TESTS.ACTIONS.STOP.RECORDING", defaultMessage: 'Stop Recorder'})}>
						<span><Button variant = "contained" onClick={() => stopAndroidRecording()}
									  size = 'small'
									  startIcon={<StopIcon/>}
									  disabled={!stopRecordForAndroidEnabled || props.stoppedByRecorder}
									  hidden={!showStopRecordForAndroid}
									  className = ' '>
							<FormattedMessage id="DOMAINS.TESTS.ACTIONS.STOP.RECORDING" defaultMessage="Stop Recorder"/>
						</Button></span>
	</Tooltip>;

	let recordButton;
	if(props.project?.type === Type.WEB){
		recordButton = <Button id = '3f67db0a03f847a88bdb105b0230010c'
							   variant = "contained"
							   size = 'small'
							   startIcon={<FiberManualRecordIcon/>}
							   className = ' '>
			<FormattedMessage id="DOMAINS.TESTS.ACTIONS.RECORD"
							  defaultMessage="Record"/>
		</Button>;
	} else if(props.project?.type === Type.ANDROID){
		// recordButton = <Button id = 'record-android-btn'
		// 					   variant = "contained"
		// 					   size = 'small'
		// 					   startIcon={<FiberManualRecordIcon/>}
		// 					   className = ' '
		// 					   href='https://google.com'>
		// 	<FormattedMessage id="DOMAINS.TESTS.ACTIONS.RECORD"
		// 					  defaultMessage="Record"/>
		// </Button>;
		recordButton = <Tooltip title={intl.formatMessage({id: "DOMAINS.TESTS.ACTIONS.RECORD", defaultMessage: 'Record'})}>
						<span><Button variant = "contained" onClick={() => startAndroidRecording()}
									  size = 'small' disabled={!startRecordForAndroidEnabled || props.stoppedByRecorder}
									  startIcon={<FiberManualRecordIcon/>}
									  hidden={!showStartRecordForAndroid}
									  className = ' '>
							<FormattedMessage id="DOMAINS.TESTS.ACTIONS.RECORD" defaultMessage="Record"/>
						</Button></span>
		</Tooltip>;
	} else {
		recordButton = <Tooltip title={intl.formatMessage({id: "COMING.SOON", defaultMessage: 'Coming soon..'})}>
						<span><Button variant = "contained"
									  size = 'small'
									  startIcon={<FiberManualRecordIcon/>}
									  className = ' ' disabled={true}>
							<FormattedMessage id="DOMAINS.TESTS.ACTIONS.RECORD" defaultMessage="Record"/>
						</Button></span>
		</Tooltip>;
	}

	let showPrepareAndroidRecoringEnvironmentError = null;
	if(props.prepareAndroidRecoringEnvironmentError){
		showPrepareAndroidRecoringEnvironmentError = <Alert onClose = {() => props.hidePrepareAndroidRecoringEnvironmentError()} severity = "error">
			{props.prepareAndroidRecoringEnvironmentError}
		</Alert>
	}

	let showAndroidEnvironmentStatusError = null;
	if(props.androidEnvironmentStatusError){
		showAndroidEnvironmentStatusError = <Alert onClose = {() => props.setShowAndroidLimitMessageAlert(false)}
												   severity="error" className='font-size-h5' variant='outlined'
		hidden={!props.showAndroidLimitMessageAlert}>
			{props.androidEnvironmentStatusError}
		</Alert>
	}
	return (
		// <Card className = "text-center">
		// 	<Card.Title className = {` ${classes.root}`}>
			<div className = {`bg-white p-1 text-center ${classes.root}`}>
				{ props.project && <p id="url" hidden>{props.project.url}</p>}
				{/*display prepareAndroidRecoringEnvironmentError*/}
				{showAndroidEnvironmentStatusError}
				{showPrepareAndroidRecoringEnvironmentError}
				{stopAndroidRecordingButton}
				{recordButton}
				<Button variant = "contained"
				        size = 'small'
				        startIcon={<AddIcon/>}
				        to = '/test/new' component = {Link}
				        className = ''>
					<FormattedMessage id="DOMAINS.TESTS.ACTIONS.ADD.TEST"
					                  defaultMessage="Test"/>
				</Button>
				<ImportTests {...props}/>

				<Dialog fullWidth={true} maxWidth={"xl"}
					open={props.showAndroidRecordingEnvironmentLoadingMessage}
						// onClose={() => props.hideAndroidRecordingEnvironmentLoadingMessage()}
					aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogContent>
						<AndroidEnvironmentProgressStepper/>
					</DialogContent>
					{/*<DialogActions>*/}
					{/*	<Button onClick={() => props.hideAndroidRecordingEnvironmentLoadingMessage()} color="primary">*/}
					{/*		<FormattedMessage id="BUTTON.OK" defaultMessage='OK'/>*/}
					{/*	</Button>*/}
					{/*</DialogActions>*/}
				</Dialog>

				<Dialog
					open={props.showAndroidRecordingEnvironmentStopToContinueMessage}
					onClose={() => props.toggleAndroidRecordingEnvironmentStopToContinueMessage(false)}
					aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						<FormattedMessage id="ANDROID.RECORDER.MODAL.DIFFERENT.DOMAIN.TITLE"
										  defaultMessage="An Android Environment Is Already Running For The Domain: "/>
						{props.androidRecordingEnvironmentStopToContinueDomainLabel}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<FormattedMessage id="ANDROID.RECORDER.MODAL.DIFFERENT.DOMAIN.STOP.CURRENT.RECORDER"
											  defaultMessage="Do you want to stop the running environment to be able to start another one for this domain?"/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => stopAndroidRecordingAndHideStopToContinueModal()}
								color="primary">
							<FormattedMessage id="BUTTON.YES" defaultMessage='Yes'/>
						</Button>
						<Button onClick={() => props.toggleAndroidRecordingEnvironmentStopToContinueMessage(false)}
								color="primary">
							<FormattedMessage id="BUTTON.NO" defaultMessage='No'/>
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={props.showAndroidRecordingEnvironmentAlreadyInUseMessage} onClose={() => props.hideAndroidRecordingEnvironmentAlreadyInUseMessage()}
					aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						<FormattedMessage id="ANDROID.RECORDER.MODAL.DIFFERENT.USER.TITLE"
										  defaultMessage="An Android Environment Is Currently Running By: "/>
						{props.androidRecordingEnvironmentAlreadyInUseUserFullname}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<FormattedMessage id="ANDROID.RECORDER.MODAL.DIFFERENT.USER.DESCRIPTION.PART1" defaultMessage="The User "/>
							{props.androidRecordingEnvironmentAlreadyInUseUserFullname}
							<FormattedMessage id="ANDROID.RECORDER.MODAL.DIFFERENT.USER.DESCRIPTION.PART2"
											  defaultMessage=" is currently running an Android Envirnoment for this project. You will have to wait until
											  the user finishes or contact them to let them know you need to use The Android Recorder."/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => props.hideAndroidRecordingEnvironmentAlreadyInUseMessage()} color="primary">
							<FormattedMessage id="BUTTON.OK" defaultMessage='OK'/>
						</Button>
					</DialogActions>
				</Dialog>

				{/*<Dialog maxWidth='lg' open={showAndroidLimitMessageModal} onClose={() => setShowAndroidLimitMessageModal(false)}*/}
				{/*		aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">*/}
				{/*	<DialogContent>*/}
				{/*		<Alert severity="error" className='font-size-h5'>{props.androidEnvironmentStatusError}</Alert>*/}
				{/*	</DialogContent>*/}
				{/*	<DialogActions>*/}
				{/*		<Button onClick={() => setShowAndroidLimitMessageModal(false)} color="primary">*/}
				{/*			<FormattedMessage id="BUTTON.OK" defaultMessage='OK'/>*/}
				{/*		</Button>*/}
				{/*	</DialogActions>*/}
				{/*</Dialog>*/}

			</div>
		// 	</Card.Title>
		// </Card>
	)
}


const mapStateToProps = state => {
	return {
		project: state.project.project,
	};
};

export default connect(mapStateToProps, null)(TestActions);