export const UINames = (name, intl) => {
	switch (name) {
		case 'username':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.USERNAME",
				defaultMessage: 'User Name' });
		case 'firstName':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.FIRSTNAME",
				defaultMessage: 'First Name' });
		case 'lastName':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.LASTNAME",
				defaultMessage: 'Last Name' });
		case 'email':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.EMAIL",
				defaultMessage: 'Email' });
		case 'password':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.PASSWORD",
				defaultMessage: 'Password' });
		case 'confirmPassword':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.CONFIRM.PASSWORD",
				defaultMessage: 'Confirmation Password' });
		case 'streetNumber':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.STREET.NUMBER",
				defaultMessage: 'Street Number' });
		case 'route':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.STREET",
				defaultMessage: 'Street' });
		case 'locality':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.CITY",
				defaultMessage: 'City' });
		case 'postalCode':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.ZIP",
				defaultMessage: 'Postal Code' });
		case 'state':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.STATE",
				defaultMessage: 'State' });
		case 'country':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.USERNAME",
				defaultMessage: 'COUNTRY' });
		case 'currentPassword':
			return intl.formatMessage({ id: "REGISTRATION.LABEL.CURRENT.PASSWORD",
				defaultMessage: 'Current Password' });
		default:
			return '';
	}
}