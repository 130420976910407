import React from "react";
import {Card} from "react-bootstrap";

const ForbiddenAccess = props => {
	return <Card bg="danger" text="white"
	             // style={{ width: '18rem' }}
	>
		{/*<Card.Header>Header</Card.Header>*/}
		<Card.Body>
			<Card.Title>Forbidden Access</Card.Title>
			<Card.Text>
				You don't have the authority to visit this page.
			</Card.Text>
		</Card.Body>
	</Card>
}

export default ForbiddenAccess;