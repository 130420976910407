import React from "react";

import TestHeader from "./TestHeader";
import TestDisplayTabs from "./TestDisplayTabs";
import {TestLoadingDialog} from "../../UI/LoadingDialog";

const TestDisplay = props => {

	return (
		<>
			<TestLoadingDialog/>
			<TestHeader {...props}/>
			<TestDisplayTabs {...props}/>
		</>
	);
}

export default TestDisplay;