import * as actionTypes from "./actionTypes";
import {DELETE_USER_NULLIFY_ERROR, NULLIFY_CICD_KEYS} from "./actionTypes";

export const loadUsers = (username, config) => {
	return {
		type: actionTypes.ON_LOAD_USERS,
		username: username,
		config: config
	}
};

export const loadUsersStart = () => {
	return {
		type: actionTypes.ON_LOAD_USERS_START
	}
};

export const loadUsersSuccess = (users, totalRecords) => {
	return {
		type: actionTypes.ON_LOAD_USERS_SUCCESS,
		users: users,
		totalRecords: totalRecords
	}
};

export const loadUsersFail = (error) => {
	return {
		type: actionTypes.ON_LOAD_USERS_FAIL,
		error: error
	}
};

export const loadAllUsers = (username) => {
	return {
		type: actionTypes.LOAD_ALL_USERS,
		username: username
	}
};

export const loadAllUsersStart = () => {
	return {
		type: actionTypes.LOAD_ALL_USERS_START
	}
};

export const loadAllUsersSuccess = (users) => {
	return {
		type: actionTypes.LOAD_ALL_USERS_SUCCESS,
		users: users
	}
};

export const loadAllUsersFail = (error) => {
	return {
		type: actionTypes.LOAD_ALL_USERS_FAIL,
		error: error
	}
};

export const loadUser = (username) => {
	return {
		type: actionTypes.ON_LOAD_USER,
		username: username
	}
};

export const loadUserStart = () => {
	return {
		type: actionTypes.ON_LOAD_USER_START
	}
};

export const loadUserSuccess = (user) => {
	return {
		type: actionTypes.ON_LOAD_USER_SUCCESS,
		user: user
	}
};

export const loadUserFail = (error) => {
	return {
		type: actionTypes.ON_LOAD_USER_FAIL,
		error: error
	}
};

export const toggleActivation = (username, active, users, id) => { // true mean reload AllUsers. false reload current user
	return {
		type: actionTypes.CHANGE_ACTIVATION_STATUS,
		username: username,
		active: active,
		users: users,
		id: id
	}
};

export const toggleActivationStart = () => {
	return {
		type: actionTypes.CHANGE_ACTIVATION_STATUS_START
	}
};

export const toggleActivationSuccess = (users, id) => {
	return {
		type: actionTypes.CHANGE_ACTIVATION_STATUS_SUCCESS,
		users: users,
		id: id
	}
};

export const toggleActivationFail = (error) => {
	return {
		type: actionTypes.CHANGE_ACTIVATION_STATUS_FAIL,
		error: error
	}
};

export const registerAccountUser = (user) => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER,
		user: user
	}
};

export const registerAccountUserStart = () => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_START
	}
};

export const registerAccountUserSuccess = () => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_SUCCESS
	}
};

export const registerAccountUserFail = (error) => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_FAIL,
		error: error
	}
};

export const registerAccountUserCICD = (user) => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_CICD,
		user: user
	}
};

export const registerAccountUserCICDStart = () => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_CICD_START
	}
};

export const registerAccountUserCICDSuccess = () => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_CICD_SUCCESS
	}
};

export const registerAccountUserCICDFail = (error) => {
	return {
		type: actionTypes.REGISTER_ACCOUNT_USER_CICD_FAIL,
		error: error
	}
};

export const updateUser = (user, redirect = true, loggedInUser = false) => {
	return {
		type: actionTypes.UPDATE_USER,
		user: user,
		redirect: redirect,
		loggedInUser: loggedInUser
	}
};

export const updateUserStart = () => {
	return {
		type: actionTypes.UPDATE_USER_START
	}
};

export const updateUserSuccess = (user) => {
	return {
		type: actionTypes.UPDATE_USER_SUCCESS,
		user: user
	}
};

export const updateUserFail = (error) => {
	return {
		type: actionTypes.UPDATE_USER_FAIL,
		error: error
	}
};

export const updateUserCICD = (user) => {
	return {
		type: actionTypes.UPDATE_USER_CICD,
		user: user,
	}
};

export const updateUserCICDStart = () => {
	return {
		type: actionTypes.UPDATE_USER_CICD_START
	}
};

export const updateUserCICDSuccess = (user) => {
	return {
		type: actionTypes.UPDATE_USER_CICD_SUCCESS,
		user: user
	}
};

export const updateUserCICDFail = (error) => {
	return {
		type: actionTypes.UPDATE_USER_CICD_FAIL,
		error: error
	}
};

export const deleteUser = (id, redirect) => {
	return {
		type: actionTypes.DELETE_USER,
		id: id,
		redirect: redirect
	}
};

export const deleteUserStart = () => {
	return {
		type: actionTypes.DELETE_USER_START
	}
};

export const deleteUserSuccess = (id) => {
	return {
		type: actionTypes.DELETE_USER_SUCCESS,
		id: id
	}
};

export const deleteUserFail = (error) => {
	return {
		type: actionTypes.DELETE_USER_FAIL,
		error: error
	}
};

export const updateMyAccount = (user, redirect) => {
	return {
		type: actionTypes.UPDATE_USER_MY_ACCOUNT,
		user: user,
		redirect: redirect
	}
};

export const updateMyAccountStart = () => {
	return {
		type: actionTypes.UPDATE_USER_MY_ACCOUNT_START
	}
};

export const updateMyAccountSuccess = () => {
	return {
		type: actionTypes.UPDATE_USER_MY_ACCOUNT_SUCCESS
	}
};

export const updateMyAccountFail = (error) => {
	return {
		type: actionTypes.UPDATE_USER_MY_ACCOUNT_FAIL,
		error: error
	}
};

export const updatePreferredProject = (userId, preferredId, redirect = true) => {
	return {
		type: actionTypes.UPDATE_PREFERRED_PROJECT,
		userId: userId,
		preferredId: preferredId,
		redirect: redirect
	}
};

export const updatePreferredProjectStart = () => {
	return {
		type: actionTypes.UPDATE_PREFERRED_PROJECT_START
	}
};

export const updatePreferredProjectSuccess = () => {
	return {
		type: actionTypes.UPDATE_PREFERRED_PROJECT_SUCCESS
	}
};

export const updatePreferredProjectFail = (error) => {
	return {
		type: actionTypes.UPDATE_PREFERRED_PROJECT_FAIL,
		error: error
	}
};

export const canCreateUser = () => {
	return {
		type: actionTypes.CAN_CREATE_USER
	}
};

export const canCreateUserStart = () => {
	return {
		type: actionTypes.CAN_CREATE_USER_START
	}
};

export const canCreateUserSuccess = (canAdd) => {
	return {
		type: actionTypes.CAN_CREATE_USER_SUCCESS,
		canAdd: canAdd
	}
};

export const canCreateUserFail = (error) => {
	return {
		type: actionTypes.CAN_CREATE_USER_FAIL,
		error: error
	}
};

export const deleteUserNullifyError = () => {
	return {
		type: actionTypes.DELETE_USER_NULLIFY_ERROR,
	}
};

export const requestDeleteMyAccount = (userID) => {
	return {
		type: actionTypes.REQUEST_DELETE_MY_ACCOUNT,
		userID: userID,
	}
};

export const requestDeleteMyAccountStart = () => {
	return {
		type: actionTypes.REQUEST_DELETE_MY_ACCOUNT_START
	}
};

export const requestDeleteMyAccountSuccess = () => {
	return {
		type: actionTypes.REQUEST_DELETE_MY_ACCOUNT_SUCCESS,
	}
};

export const requestDeleteMyAccountFail = (error) => {
	return {
		type: actionTypes.REQUEST_DELETE_MY_ACCOUNT_FAIL,
		error: error
	}
};

export const closeAccount = (key) => {
	return {
		type: actionTypes.CLOSE_ACCOUNT,
		key: key
	}
};

export const closeAccountStart = () => {
	return {
		type: actionTypes.CLOSE_ACCOUNT_START
	}
};

export const closeAccountSuccess = () => {
	return {
		type: actionTypes.CLOSE_ACCOUNT_SUCCESS,
	}
};

export const closeAccountFail = (error) => {
	return {
		type: actionTypes.CLOSE_ACCOUNT_FAIL,
		error: error
	}
};

export const changeLanguage = (userId, language) => {
	return {
		type: actionTypes.CHANGE_LANGUAGE,
		userId: userId,
		language: language
	}
};

export const changeLanguageStart = () => {
	return {
		type: actionTypes.CHANGE_LANGUAGE_START
	}
};

export const changeLanguageSuccess = () => {
	return {
		type: actionTypes.CHANGE_LANGUAGE_SUCCESS,
	}
};

export const changeLanguageFail = (error) => {
	return {
		type: actionTypes.CHANGE_LANGUAGE_FAIL,
		error: error
	}
};

export const generateCICDKeys = (userId) => {
	return {
		type: actionTypes.GENERATE_CICD_KEYS,
		userId: userId
	}
};

export const generateCICDKeysStart = () => {
	return {
		type: actionTypes.GENERATE_CICD_KEYS_START
	}
};

export const generateCICDKeysSuccess = (keys) => {
	return {
		type: actionTypes.GENERATE_CICD_KEYS_SUCCESS,
		keys: keys
	}
};

export const generateCICDKeysFail = (error) => {
	return {
		type: actionTypes.GENERATE_CICD_KEYS_FAIL,
		error: error
	}
};

export const nullifyCICDKeys = () => {
	return {
		type: actionTypes.NULLIFY_CICD_KEYS
	}
};

export const loadCICDSecretKey = (accessKey) => {
	return {
		type: actionTypes.LOAD_CICD_SECRET_KEY,
		accessKey: accessKey
	}
};

export const loadCICDSecretKeyStart = () => {
	return {
		type: actionTypes.LOAD_CICD_SECRET_KEY_START
	}
};

export const loadCICDSecretKeySuccess = (secret) => {
	return {
		type: actionTypes.LOAD_CICD_SECRET_KEY_SUCCESS,
		secret: secret
	}
};

export const loadCICDSecretKeyFail = (error) => {
	return {
		type: actionTypes.LOAD_CICD_SECRET_KEY_FAIL,
		error: error
	}
};

export const deleteCICDKeys = (userId) => {
	return {
		type: actionTypes.DELETE_CICD_KEYS,
		userId: userId
	}
};

export const deleteCICDKeysStart = () => {
	return {
		type: actionTypes.DELETE_CICD_KEYS_START
	}
};

export const deleteCICDKeysSuccess = () => {
	return {
		type: actionTypes.DELETE_CICD_KEYS_SUCCESS
	}
};

export const deleteCICDKeysFail = (error) => {
	return {
		type: actionTypes.DELETE_CICD_KEYS_FAIL,
		error: error
	}
};