import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";

import SuccessRateWidget from "../../components/Summary/SuccessRateWidget";
import * as Roles from "../Users/enum/Roles";
import * as actions from "../../store/actions";
import RecentUsersWidget from "../../components/Summary/RecentUsersWidget";
import TestsExecutionWidget from "../../components/Summary/TestsExecutionWidget";
import TestsAdditionWidget from "../../components/Summary/TestsAdditionWidget";
import * as Plan from "../Payment/enum/PlanType";
import UpgradePlanWidget from "../../components/Summary/UpgradePlanWidget";
import TestsRunsDistribution from "../../components/Summary/TestsRunsDistribution";
import LastFiveExecutionsWidget from "../../components/Summary/LastFiveExecutionsWidget";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import ExecutionTimeDistribution from "../../components/Summary/ExecutionTimeDistribution";
import ExecutionTimeUsage from "../../components/Summary/ExecutionTimeUsage";
import MobileRecorderTimeUsage from "../../components/Summary/MobileRecorderTimeUsage";

const Dashboard = props => {

	const intl = useIntl();

	useEffect(() => {
		document.title = intl.formatMessage({id: "PAGE.TITLE.DASHBOARD", defaultMessage: 'Dashboard'});

		// props.onLoadDashboard();
		// console.log('before if onLoadDashboard', props.project, props.loggedInUser);
		if (props.project && props.loggedInUser) {
			// console.log('inside onLoadDashboard', props.project, props.loggedInUser);
			props.onLoadDashboard(props.loggedInUser.id, props.project.id);
		}
		if (localStorage.getItem('Top-Role') === Roles.ROLE_ADMIN || localStorage.getItem('Top-Role') === Roles.ROLE_SUPER) {
			props.onCanCreateProject();
		}
		if (localStorage.getItem('Top-Role') === Roles.ROLE_ADMIN || localStorage.getItem('Top-Role') === Roles.ROLE_SUPER &&
			localStorage.getItem('Top-Role') === Roles.ROLE_MANAGER) {
			props.onCanCreateUser();
		}
		props.onLoadCurrentPlan();
	}, [props.project, props.loggedInUser]);

	const refreshData = () => {
		if (props.project) {
			// props.onLoadSummaryChartData(props.project.id);
		}
	}

	if(props.project === null){
		return <div className="card card-custom bgi-no-repeat gutter-b"
					style={{
						height: '100%',
						backgroundColor: '#1B283F',
						backgroundPosition: 'calc(100% + 0.5rem) calc(100% + 0.5rem)',
						backgroundSize: '100% auto',
						backgroundImage: `url(${toAbsoluteUrl("/media/svg/patterns/rhone-2.svg")})`
					}}>
			<div className="card-body">
				<h1 className="text-white font-weight-bolder">
					<FormattedMessage id="DASHBOARD.EMPTY.HEADER" defaultMessage='No projects yet!'/>
				</h1>
				<p className="text-muted font-size-h3-lg mt-5 mb-10">
					<FormattedMessage id="DASHBOARD.EMPTY.TEXT"
									  defaultMessage='This Dashboard will be populated with graph and data as soon as you have access to atleast 1 project.'/>
				</p>
			</div>

		</div>;

		// return <div className="card card-custom bgi-no-repeat gutter-b"
		// 			// style="height: 225px; background-color: #663259; background-position: calc(100% + 0.5rem) 100%; background-size: 100% auto; background-image: url(assets/media/svg/patterns/taieri.svg)">
		// 	style={{height: '100%', backgroundColor: '#663259', backgroundPosition: 'calc(100% + 0.5rem) calc(100% + 0.5rem)',
		// 	backgroundSize: '100% auto', backgroundImage: `url(${toAbsoluteUrl("/media/svg/patterns/taieri.svg")})`}}>
		//
		// 	<div className="card-body d-flex ">
		// 		<div>
		// 			<h3 className="text-white font-weight-bolder line-height-lg mb-5">
		// 				This Dashboard will be populated with graph and data as soon as you have access to atleast 1 project.
		// 			</h3>
		// 			<a href='#' className="btn btn-success font-weight-bold px-6 py-3">Create Report</a>
		// 		</div>
		// 	</div>
		// </div>
		// return <div className='bg-white h-100'>This Dashboard will be populated with graph and data as soon as you have access to atleast 1 project.</div>;
	}
	if(props.dashboard !== null){
		let hideRecentUsers = false;
		if (localStorage.getItem('Top-Role') === Roles.ROLE_TESTER || localStorage.getItem('Top-Role') === Roles.ROLE_USER) {
			// if(true){
			hideRecentUsers = true;
		}

		// console.log('currentPlan', props.currentPlan);
		let thirdSection = <div className={`col-lg-6 ${hideRecentUsers ? 'col-xxl-8' : 'col-xxl-4'}`}>
			<TestsExecutionWidget className="card-stretch card-stretch-half gutter-b" testsExecuted={props.dashboard.testsExecuted}/>
			{/*<StatsWidget11*/}
			{/*	className="card-stretch card-stretch-half gutter-b"*/}
			{/*	symbolShape="circle"*/}
			{/*	baseColor="success"*/}
			{/*/>*/}
			<TestsAdditionWidget className="card-stretch card-stretch-half gutter-b" testsAddition={props.dashboard.testsAddition}/>
			{/*<StatsWidget12 className="card-stretch card-stretch-half gutter-b"/>*/}
		</div>;
		if(props.currentPlan !== null && props.currentPlan.type === Plan.FREE){
			thirdSection = <div className={`col-lg-6 ${hideRecentUsers ? 'col-xxl-6' : 'col-xxl-4'}`}><UpgradePlanWidget /></div>;
		}

		return <>
			<div className="row">
				<div className={`col-lg-6 ${hideRecentUsers ? 'col-xxl-4' : 'col-xxl-4'}`}>
					<SuccessRateWidget className="card-stretch gutter-b"
									   canAddProject={props.canAddProject}
									   canAddProjectLoading={props.canAddProjectLoading}
									   canAddUser={props.canAddUser}
									   canAddUserLoading={props.canAddUserLoading}
									   successRate={props.dashboard.successRate}
					/>
				</div>


				<div className={`col-lg-6 ${hideRecentUsers ? 'col-xxl-8' : 'col-xxl-8'}`}>
					<LastFiveExecutionsWidget {...props} lastFiveIterations={props.dashboard.lastFiveIterations}/>
				</div>

				{/*</div>*/}
				{/*<div className="row">*/}
				<div className={`col-lg-6 col-xxl-4`}>
					<TestsRunsDistribution testDistribution={props.dashboard.testDistribution}/>
				</div>

				{!hideRecentUsers && <div className="col-lg-6 col-xxl-4">
					<RecentUsersWidget recentUsers={props.dashboard.recentUsers}/>
				</div>}

				{thirdSection}

			</div>
			<div className='row justify-content-center'>
				<div className='col-lg-6 col-xl-6 col-xxl-4'>
					<ExecutionTimeDistribution executionTimeDistribution={props.dashboard.executionTimeDistribution}/>
				</div>
				<div className='col-lg-6 col-xl-6 col-xxl-5'>
					<ExecutionTimeUsage executionTimeUsage={props.dashboard.executionTimeUsage}/>
				</div>
				<div className='col-lg-6 col-xl-5 col-xxl-3'>
					<MobileRecorderTimeUsage mobileRecorderTimeUsage={props.dashboard.mobileRecorderTimeUsage}/>
				</div>
			</div>
		</>
	}

	return null;
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		// loading: state.summaryChart.loading,
		// error: state.summaryChart.error,
		// lastFiveExecutions: state.summaryChart.lastFiveExecutions,
		// lastExecution: state.summaryChart.lastExecution
		canAddProject: state.project.canAdd,
		canAddProjectLoading: state.project.canAddLoading,
		canAddUser: state.users.canAdd,
		canAddUserLoading: state.users.canAddLoading,
		currentPlan: state.payment.currentPlan,
		dashboard: state.dashboard.dashboard,
		loggedInUser: state.authentication.loggedInUser,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadDashboard: (userId, projectId) => dispatch(actions.loadDashboardStats(userId, projectId)),
		onCanCreateProject: () => dispatch(actions.canCreateProject()),
		onCanCreateUser: () => dispatch(actions.canCreateUser()),
		onLoadCurrentPlan: () => dispatch(actions.loadCurrentPlan()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);