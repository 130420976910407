import {checkArraySelectionValidity, checkSelectionValidity, checkValidity} from "../../utility/utility";
import {UINames} from "./Messages";
import {confirmPasswordChangeHandler} from "../../utility/GlobalValidation";

export const changeText = (inputTextState, value, idMessageTranslation, intl) => {
	const updatedInputTextState = {...inputTextState};
	updatedInputTextState.value = value;
	const [valid, message] = checkValidity(updatedInputTextState, UINames(idMessageTranslation, intl), intl);
	updatedInputTextState.valid = valid;
	updatedInputTextState.validationMessage = message;
	return updatedInputTextState;
}

export const checkValidityForAll = (form, confirm, intl, includeAddress = true) => {
	const updatedRegistrationForm = {...form};
	let allValid = true;

	for (let key in updatedRegistrationForm) {
		const updatedFormElement = {...updatedRegistrationForm[key]};
		let [valid, message] = [null, null];
		// skip Address
		if(!includeAddress){
			if(key === 'streetNumber' || key === 'route' || key === 'locality' ||
				key === 'postalCode' || key === 'state' || key === 'country' ){
				continue;
			}
		}

		if(key === 'password' || key === 'confirmPassword'){
			const otherId = key === 'password' ? 'confirmPassword' : 'password';
			const updatedPasswordForm = confirmPasswordChangeHandler(
				form,
				{target: {value: form[key].value}},
				key,
				confirm, UINames(key, intl),
				key === 'password' ? UINames('confirmPassword', intl) : UINames('password', intl));
			if (!updatedPasswordForm[key].valid || !updatedPasswordForm[otherId].valid) {
				allValid = false;
			}
			updatedRegistrationForm[key] = updatedPasswordForm[key];
			if(key === 'password'){
				updatedRegistrationForm['confirmPassword'] = updatedPasswordForm['confirmPassword'];
				if(updatedPasswordForm[key].value !== updatedPasswordForm['confirmPassword'].value ){
					allValid = false;
				}
			} else if (key === 'confirmPassword'){
				updatedRegistrationForm['password'] = updatedPasswordForm['password'];
				if(updatedPasswordForm[key].value !== updatedPasswordForm['password'].value ){
					allValid = false;
				}
			}
			continue;
		} else if( key === 'authorities'){
			// console.log('authorities');
			[valid, message] = checkArraySelectionValidity(updatedFormElement, 'roles', UINames(key, intl));
		} else if( key === 'country'){
			[valid, message] = checkSelectionValidity(updatedFormElement, 'selected', UINames(key, intl));
		}
		else {
			[valid, message] = checkValidity(updatedFormElement, UINames(key, intl));
		}
		// console.log('key:', key, valid, message);
		updatedFormElement.valid = valid;
		updatedFormElement.validationMessage = message;
		updatedRegistrationForm[key] = updatedFormElement;
		if (!valid) {
			allValid = false;
		}
	}
	return [allValid, updatedRegistrationForm];
}