import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {connect} from "react-redux";

import RestRequestDisplay from "../../components/Rest/RestRequestDisplay";
import * as AuthenticationType from "../../components/Rest/enum/AuthenticationType";
import * as AssertionType from "../../components/Rest/enum/AssertionType";
import {HEADER} from "../../components/Rest/enum/APIKeyType";
import {
	validateAuthentication,
	validateCore,
	validateDescription,
	validateHeaders,
	validateParameters,
	validateResponseCollection
} from "./Validator";
import * as actions from "../../store/actions";

const UpdateRestRequest = (props) => {
	const intl = useIntl();

	useEffect(() => {
		// load data
		props.onLoadRestRequest(props.match.params.restRequestID);
	}, []);

	useEffect(() => {
		if (props.restRequest) {
			loadValues();
			document.title = intl.formatMessage({ id: "PAGE.TITLE.UPDATE.REST.REQUEST",
				defaultMessage: 'Update Rest Request: '}) + props.restRequest.label;
		}
	}, [props.restRequest]);

	const loadValues = () => {
		loadCore();
		setDescription(props.restRequest.description ? props.restRequest.description : '');
		setBody(props.restRequest.body ? props.restRequest.body : '');
		loadParameters();
		loadHeaders();
		loadAuthentication();
		loadAssertions();
		loadResponseCollection();
	}

	const loadCore = () => {
		setCore({
			name: props.restRequest.label,
			method: props.restRequest.method,
			url: props.restRequest.uri
		});
	}

	const loadParameters = () => {
		// key, value => [{'key': key, 'value': value}]
		const parametersList = [];
		if(props.restRequest.parameters){
			Object.keys(props.restRequest.parameters).forEach(parameter => {
				parametersList.push({key: parameter, value: props.restRequest.parameters[parameter]});
			});
		}
		setParameters(parametersList);

	}

	const loadHeaders = () => {
		const headersList = [];
		if(props.restRequest.httpHeaders){
			Object.keys(props.restRequest.httpHeaders).forEach(header => {
				headersList.push({key: header, value: props.restRequest.httpHeaders[header]});
			});
		}
		// console.log('headersList:', headersList);
		setHeaders(headersList);
	}

	const loadAuthentication = () => {
		setAuthentication(props.restRequest.authorization);
	}

	const loadAssertions = () => {
		setAssertions(Array.isArray(props.restRequest.assertions) ?  props.restRequest.assertions : []);
	}

	const loadResponseCollection = () => {
		if(props.restRequest.reuseResponseApi){
			setResponseCollection({
				responsePath: props.restRequest.reuseResponseApi.responsePath ? props.restRequest.reuseResponseApi.responsePath : '',
				suiteVariable: props.restRequest.reuseResponseApi.suiteVariable ? props.restRequest.reuseResponseApi.suiteVariable : '',
			});
		}
	}

	// Error Messages
	const [errorMessages, setErrorMessages] = useState([]);

	// Validate forms on mount. for Auth Forms,
	// excluded: Parameters, Headers, Body JSON, Assertions
	// exception: Description always validate on mount
	const [validate, setValidate] = useState({});

	// Select Tab
	const [selectTab, setSelectTab] = useState(0);

	// Core
	const [core, setCore] = useState({name: '', method: '', url: ''});

	const validateCoreFormRef = React.useRef();
	const registerValidateCoreFormCallback = callback => {
		validateCoreFormRef.current = callback;
	};

	// Description
	const [description, setDescription] = useState('');
	const validateDescriptionFormRef = React.useRef();
	const registerValidateDescriptionFormCallback = callback => {
		validateDescriptionFormRef.current = callback;
	};

	// Parameters
	const [parameters, setParameters] = useState([{key: '', value: ''}]);

	// Headers
	const [headers, setHeaders] = useState([{key: '', value: []}]);

	// Body
	const [body, setBody] = useState('');

	// Authentication
	const [authentication, setAuthentication] = useState({
		authType: AuthenticationType.INHERIT,
		username: '',
		password: '',
		token: '',
		apiKey: {
			key: '',
			value: '',
			type: HEADER
		}
	});

	// Assertions: empty by default
	const [assertions, setAssertions] = useState([
	// 	{
	// 	type: RESPONSE_BODY,
	// 	responseBodyCondition: CONTAINS,
	// 	quantityCondition: MATCHES,
	// 	value: ''
	// }
	]);

	const [responseCollection, setResponseCollection] = useState({responsePath: '', suiteVariable: ''});


	useEffect(() => {
		// console.log('useEffect props.error', props.error)
		const updatedGlobalMessages = [...errorMessages];
		if(props.error){
			updatedGlobalMessages.push(props.error);
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.error]);

	const validateAuthBasicFormRef = React.useRef();
	const registerValidateAuthBasicFormCallback = callback => {
		validateAuthBasicFormRef.current = callback;
	};

	const validateAuthTokenFormRef = React.useRef();
	const registerValidateAuthTokenFormCallback = callback => {
		validateAuthTokenFormRef.current = callback;
	};

	const validateAuthAPIKeyFormRef = React.useRef();
	const registerValidateAuthAPIKeyFormCallback = callback => {
		validateAuthAPIKeyFormRef.current = callback;
	};

	const validateAuthResponseCollectionFormRef = useRef();
	const registerValidateResponseCollectionFormCallback = callback => {
		validateAuthResponseCollectionFormRef.current = callback;
	};


	const addAuthAPIKeyToParametersOrHeaders = (headersResult, parametersResult) => {
		if (authentication.authType === AuthenticationType.API_KEY) {
			if (authentication.apiKey.key.trim() !== '' && authentication.apiKey.value.trim() !== '') {
				if (authentication.apiKey.type === HEADER) {
					const injectMe = {
						key: authentication.apiKey.key.trim(),
						value: [authentication.apiKey.value.trim()]
					}
					headersResult.push(injectMe);
				} else {
					const injectMe = {
						key: authentication.apiKey.key.trim(),
						value: authentication.apiKey.value.trim()
					}
					parametersResult.push(injectMe);
				}
			}
		}
	};

	const cleanAndBuildParametersAndHeaders = () => {
		const headersResult = [...headers];
		const parametersResult = [...parameters];
		// Clean headers and parameters
		// make sure that parameters & headers: if length is 1 and key is empty it means, empty object
		if (headersResult.length === 1 && headersResult[0].key.trim() === '') {
			headersResult.splice(0, 1);
		}
		if (parametersResult.length === 1 && parametersResult[0].key.trim() === '') {
			parametersResult.splice(0, 1);
		}

		// API Key move to Headers or Parameters
		addAuthAPIKeyToParametersOrHeaders(headersResult, parametersResult);
		const parametersObject = buildParameters(parametersResult);
		const headersObject = buildHeaders(headersResult);
		return [parametersObject, headersObject]
	}

	const buildParameters = (parametersRes) => {
		const parametersAsObject = {};
		for(let param of parametersRes){
			parametersAsObject[param.key] = param.value;
		}
		return parametersAsObject;
	}

	const buildHeaders = (headersResult) => {
		const HeadersAsObject = {};
		for(let header of headersResult){
			HeadersAsObject[header.key] = header.value;
		}
		return HeadersAsObject
	}

	// const cleanAssertions = () => {
	// 	const assertionsResult = [...assertions];
	// 	if (assertionsResult.length === 1 && assertionsResult[0].value.trim() === '') {
	// 		assertionsResult.splice(0, 1);
	// 	}
	// 	return assertionsResult;
	// }

	const submit = (event) => {
		event.preventDefault();
		const updatedGlobalMessage = [];
		setErrorMessages(updatedGlobalMessage);
		// validate core only
		if(!validateCore(validateCoreFormRef)){
			return null;
		}

		if(!validateDescription(validateDescriptionFormRef, setSelectTab)){
			return null;
		}

		// Parameters
		if(!validateParameters(updatedGlobalMessage, parameters, intl, setErrorMessages, setSelectTab)){
			return null;
		}

		// Headers
		if(!validateHeaders(updatedGlobalMessage, headers, intl, setErrorMessages, setSelectTab)){
			return null;
		}

		// Body Validation not required

		// Authentication
		if(!validateAuthentication(authentication, validateAuthBasicFormRef, setSelectTab,
			setValidate, validateAuthTokenFormRef, validateAuthAPIKeyFormRef)){
			return null;
		}

		// Response Collection
		if(!validateResponseCollection(validateAuthResponseCollectionFormRef, setSelectTab)){
			return null;
		}

		// Assertions
		// if(!validateAssertions(updatedGlobalMessage, assertions, intl, setErrorMessages, setSelectTab)){
		// 	return null;
		// }

		const restRequest = {};
		restRequest.projectId = props.restRequest.projectId;
		restRequest.id = props.restRequest.id;

		restRequest.label = core.name;
		restRequest.method = core.method;
		restRequest.uri = core.url;

		restRequest.description = description;

		const [parametersResult, headersResult] = cleanAndBuildParametersAndHeaders();

		restRequest.parameters = parametersResult;
		restRequest.httpHeaders = headersResult;
		restRequest.body = body;

		restRequest.authorization = authentication;
		restRequest.assertions = buildAssertions();

		restRequest.reuseResponseApi = responseCollection;

		props.onUpdateRestRequest(restRequest);
	}

	const buildAssertions = () => {
		const cleanedAssertions = [];
		for(let assertItem of assertions){
			switch (assertItem.type) {
				case AssertionType.RESPONSE_BODY:
					delete assertItem.quantityCondition;
					break;
				case AssertionType.STATUS_CODE:
					delete assertItem.responseBodyCondition;
					break;
			}
			cleanedAssertions.push(assertItem);
		}
		return cleanedAssertions;
	}

	return <RestRequestDisplay submitForm = {submit}
	                           validate={validate}

	                           core={core}
	                           setCore={setCore}
	                           registerValidateCoreFormCallback={registerValidateCoreFormCallback}

	                           description={description}
	                           setDescription={setDescription}
	                           registerValidateDescriptionFormCallback={registerValidateDescriptionFormCallback}

	                           parameters={parameters}
	                           setParameters={setParameters}

	                           errorMessages={errorMessages}

	                           selectTab={selectTab}
	                           setSelectTab={setSelectTab}

	                           headers={headers}
	                           setHeaders={setHeaders}

	                           body = {body}
	                           setBody = {setBody}

	                           authentication = {authentication}
	                           setAuthentication = {setAuthentication}
	                           registerValidateAuthBasicFormCallback = {registerValidateAuthBasicFormCallback}

	                           registerValidateAuthTokenFormCallback={registerValidateAuthTokenFormCallback}
	                           registerValidateAuthAPIKeyFormCallback={registerValidateAuthAPIKeyFormCallback}

	                           assertions={assertions}
	                           setAssertions={setAssertions}

	                           updatingLoading={props.updatingLoading}

							   responseCollection={responseCollection}
							   setResponseCollection={setResponseCollection}
							   registerValidateResponseCollectionFormCallback={registerValidateResponseCollectionFormCallback}

	                           type='update'
	/>
}

const mapStateToProps = state => {
	return {
		project: state.project.project,
		error: state.restRequest.updatingError,
		restRequest: state.restRequest.restRequest,
		updatingLoading: state.restRequest.updatingLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLoadRestRequest: (restRequestID) => dispatch(actions.loadRestRequest(restRequestID)),
		onUpdateRestRequest: (restRequest) => dispatch(actions.updateRestRequest(restRequest))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRestRequest);
