import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../store/actions";
import FormUserDisplay from "../../components/Users/FormUserDisplay";
import * as Role from "./enum/Roles";
import {validateCICD, validateNormal} from './Validator';
import {getCurrentUserAllowedUserCreationAuthorities} from "../../components/Users/Shared";

const NewUser = props => {
	const intl = useIntl();

	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [authorities, setAuthorities] = useState({});
	const [rolesSelectionList, setRolesSelectionList] = useState([]);

	const [password, setPassword] = useState('');
	// const [confirmPassword, setConfirmPassword] = useState('');

	const [selectTab, setSelectTab] = useState(0);

	const [errorMessages, setErrorMessages] = useState([]);

	useEffect(() => {
		document.title = intl.formatMessage({id: "PAGE.TITLE.NEW.USER", defaultMessage: 'New User'});
		const filteredList = getCurrentUserAllowedUserCreationAuthorities();
		setRolesSelectionList(filteredList);
		setAuthorities(filteredList[(filteredList.length - 1)]);
	}, []);

	useEffect(() => {
		const updatedGlobalMessages = [...errorMessages];
		if (props.error) { // refer to CreationError
			updatedGlobalMessages.push(props.error);
		}
		setErrorMessages(updatedGlobalMessages);
	}, [props.error]);

	const validateNormalUserFormRef = React.useRef();
	const registerValidateNormalUserFormCallback = callback => {
		validateNormalUserFormRef.current = callback;
	};

	const validateCICDUserFormRef = React.useRef();
	const registerValidateCICDUserFormCallback = callback => {
		validateCICDUserFormRef.current = callback;
	};

	const buildAuthoritiesArray = topRoleWholeObject => {
		const authoritiesArray = []
		switch (topRoleWholeObject.value) {
			case Role.ROLE_ADMIN:
				authoritiesArray.push(Role.ROLE_ADMIN);
			case Role.ROLE_MANAGER:
				authoritiesArray.push(Role.ROLE_MANAGER);
			case Role.ROLE_TESTER:
				authoritiesArray.push(Role.ROLE_TESTER);
			case Role.ROLE_USER:
				authoritiesArray.push(Role.ROLE_USER);
		}
		return authoritiesArray;
	}

	const createNormalUser = (event) => {
		event.preventDefault();

		// validate all
		if (!validateNormal(validateNormalUserFormRef)) {
			return null;
		}

		const user = {
			login: username,
			firstName: firstName,
			lastName: lastName,
			email: email,
			authorities: buildAuthoritiesArray(authorities),
		}
		props.onCreateUser(user);
	}

	const createCICDUser = (event) => {
		event.preventDefault();

		// validate all
		if (!validateCICD(validateCICDUserFormRef)) {
			return null;
		}

		const user = {
			login: username,
			password: password,
		}
		props.onCreateUserCICD(user);
	}

	return <FormUserDisplay selectTab={selectTab}
							setSelectTab={setSelectTab}
							type='create'
							loggedInUser={props.loggedInUser}
							includeRoles={true}

							errorMessages={errorMessages}

							registerValidateNormalUserFormCallback={registerValidateNormalUserFormCallback}
							username={username} setUsername={setUsername}
							firstName={firstName} setFirstName={setFirstName}
							lastName={lastName} setLastName={setLastName}
							email={email} setEmail={setEmail}
							authorities={authorities} setAuthorities={setAuthorities}
							createNormalUser={createNormalUser}

							rolesSelectionList={rolesSelectionList}

							password={password} setPassword={setPassword}
							registerValidateCICDUserFormCallback={registerValidateCICDUserFormCallback}
							createCICDUser={createCICDUser}
							loading={props.loading}

							company={null} setCompany={null}
	/>;
}

const mapStateToProps = state => {
	return {
		loading: state.users.creationLoading,
		error: state.users.creationError,
		loggedInUser: state.authentication.loggedInUser,
		userRegistered: state.users.userRegistered
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateUser: (user) => dispatch(actions.registerAccountUser(user)),
		onCreateUserCICD: (user) => dispatch(actions.registerAccountUserCICD(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
