import React from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

const DeletionConfirmationDialog = props => {

	return <Dialog open={props.open} onClose={props.closeDialog} aria-labelledby="alert-dialog-title"
				   aria-describedby="alert-dialog-description">
		<DialogTitle id="alert-dialog-title">
			{props.message} {props.name} ?
		</DialogTitle>
		<DialogActions>
			<Button onClick={props.closeDialog} color="primary">
				<FormattedMessage id="BUTTON.CANCEL" defaultMessage='Cancel'/>
			</Button>
			<Button onClick={props.deleteAction} color="primary">
				<FormattedMessage id="BUTTON.REMOVE" defaultMessage='Remove'/>
			</Button>
		</DialogActions>
	</Dialog>
}

export default DeletionConfirmationDialog;