import React from "react";
import {Button, Snackbar} from "@material-ui/core";

import Cookie from 'cookie_js';
import {FormattedMessage} from "react-intl";

const CookieConsent = props => {

	const accept = () => {
		Cookie.set('user-consent', true, {
			expires: 10_000, path: '/'
		});
	}

	if(Cookie.get('user-consent')){
		return null;
	} else {
		return <Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={true}
		>
			<div className=' text-white px-6 py-2 rounded' style={{backgroundColor: '#323232'}}>
				<span className='mr-3'>
					<FormattedMessage id="COOKIE.CONSENT.MESSAGE" defaultMessage='We use cookies to give you the best experience'/>
				</span>
				<Button color="primary" size="medium" onClick={() => accept()} className='mr-2' variant='outlined'>
					<FormattedMessage id="COOKIE.CONSENT.ACCEPT" defaultMessage='Accept'/>
				</Button>
				<Button color="primary" size="medium" onClick={() => accept()}  variant='outlined'>
					<FormattedMessage id="COOKIE.CONSENT.ACCEPT.ALL" defaultMessage='Accept All'/>
				</Button>
				<div className="form-group mb-0 text-center">
					<div className="checkbox-inline text-center">
						<label className="checkbox checkbox-disabled text-white">
							<input type="checkbox" name="Checkboxes1" disabled="disabled" checked="checked"/>
							<span></span>
							<FormattedMessage id="COOKIE.CONSENT.TYPE.NECESSARY" defaultMessage='Necessary'/>
						</label>
						<label className="checkbox text-white">
							<input type="checkbox" name="Checkboxes1"/>
							<span></span>
							<FormattedMessage id="COOKIE.CONSENT.TYPE.PERFORMANCE" defaultMessage='Performance'/>
						</label>
						<label className="checkbox text-white">
							<input type="checkbox" name="Checkboxes1"/>
							<span></span>
							<FormattedMessage id="COOKIE.CONSENT.TYPE.COMFORT" defaultMessage='Comfort'/>
						</label>
					</div>
				</div>
			</div>
		</Snackbar>
	}

}

export default CookieConsent;