import React from "react";
import {Card} from "react-bootstrap";
import {Alert} from "@material-ui/lab";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {makeStyles} from "@material-ui/styles";
import {FormattedMessage, useIntl} from "react-intl";

import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import DeletionConfirmationDialog from "../../UI/DeletionConfirmationDialog";
import MoveTestToDomain from "../../containers/Test/MoveTestToDomain";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const TestsDisplay = (props) => {
	const intl = useIntl();
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const [test, setTest] = React.useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const testNameBodyTemplate = (rowData) => {
		return <h5>{rowData.label}</h5>;
	}

	const testActionsBodyTemplate = (rowData) => {
		if(localStorage.getItem('Top-Role') === 'ROLE_USER'){
			return (
				<div className = {` ${classes.root}`}>
					<Link to = {`/test/${rowData.id}`}
					      title = {intl.formatMessage({
						      id: "DOMAINS.TESTS.TABLE.ACTIONS.VIEW.TEST.INFORMATIONS",
						      defaultMessage: 'View Test Informations' })}
					      className = "btn btn-icon btn-light btn-hover-success btn-sm"
					>
				        <span className = "svg-icon svg-icon-md svg-icon-success">
					        <SVG title = {intl.formatMessage({
						        id: "DOMAINS.TESTS.TABLE.ACTIONS.VIEW.TEST.INFORMATIONS",
						        defaultMessage: 'View Test Informations' })}
						        src = {toAbsoluteUrl("/media/svg/icons/Map/Location-arrow.svg")}
					        />
				        </span>
					</Link>
					{/*<Link to = {`/test/${rowData.id}/?edit=true`}*/}
					{/*      title = {intl.formatMessage({*/}
					{/*	      id: "DOMAINS.TESTS.TABLE.ACTIONS.VIEW.TEST.STEPS",*/}
					{/*	      defaultMessage: 'View Test Steps' })}*/}
					{/*      className = "btn btn-icon btn-light btn-hover-primary btn-sm"*/}
					{/*>*/}
			        {/*<span className = "svg-icon svg-icon-md svg-icon-primary">*/}
				    {/*    <SVG title = {intl.formatMessage({*/}
					{/*        id: "DOMAINS.TESTS.TABLE.ACTIONS.VIEW.TEST.STEPS",*/}
					{/*        defaultMessage: 'View Test Steps' })}*/}
					{/*        src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}*/}
				    {/*    />*/}
			        {/*</span>*/}
					{/*</Link>*/}

				</div>
			);
		}
		return (
			<div className = {` ${classes.root}`}>
				<Link to = {`/test/${rowData.id}`}
				      title = {intl.formatMessage({
					      id: "DOMAINS.TESTS.TABLE.ACTIONS.EDIT.TEST.INFORMATIONS",
					      defaultMessage: 'Edit Test Informations' })}
				      className = "btn btn-icon btn-light btn-hover-success btn-sm"
				>
			        <span className = "svg-icon svg-icon-md svg-icon-success">
				        <SVG title = {intl.formatMessage({
					        id: "DOMAINS.TESTS.TABLE.ACTIONS.EDIT.TEST.INFORMATIONS",
					        defaultMessage: 'Edit Test Informations' })}
					        src = {toAbsoluteUrl("/media/svg/icons/Map/Location-arrow.svg")}
				        />
			        </span>
				</Link>
				{/*<Link to = {`/test/${rowData.id}/?edit=true`}*/}
				{/*      title = {intl.formatMessage({*/}
				{/*	      id: "DOMAINS.TESTS.TABLE.ACTIONS.EDIT.TEST.STEPS",*/}
				{/*	      defaultMessage: 'Edit Test Steps' })}*/}
				{/*      className = "btn btn-icon btn-light btn-hover-primary btn-sm"*/}
				{/*>*/}
			    {/*    <span className = "svg-icon svg-icon-md svg-icon-primary">*/}
				{/*        <SVG title = {intl.formatMessage({*/}
				{/*	        id: "DOMAINS.TESTS.TABLE.ACTIONS.EDIT.TEST.STEPS",*/}
				{/*	        defaultMessage: 'Edit Test Steps' })}*/}
				{/*	        src = {toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}*/}
				{/*        />*/}
			    {/*    </span>*/}
				{/*</Link>*/}
				<MoveTestToDomain toMoveTest={rowData}/>
				<a title = {intl.formatMessage({ id: "DOMAINS.TESTS.TABLE.ACTIONS.REMOVE.TEST",
					defaultMessage: 'Delete Test' })}
				   className = "btn btn-icon btn-light btn-hover-danger btn-sm "
					// onClick = {() => deleteTest(rowData.id)}
                   onClick = {() => {
	                   setTest(rowData);
	                   handleClickOpen();
                   }}
				>
			        <span className = "svg-icon svg-icon-md svg-icon-danger">
				        <SVG title = {intl.formatMessage({ id: "DOMAINS.TESTS.TABLE.ACTIONS.REMOVE.TEST",
					        defaultMessage: 'Delete Test' })}
					        src = {toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			        </span>
				</a>
			</div>
		);
	}

	const columns2 = [
		<Column key = 'label' columnKey = 'label' field = 'label'
		        header = {intl.formatMessage({ id: "DOMAINS.TESTS.TABLE.COLUMN.HEADER.NAME",
			        defaultMessage: 'Name' })}
		        body = {testNameBodyTemplate} sortable/>

	];
	if (localStorage.getItem('Top-Role') !== 'ROLE_CICD') {
		columns2.push(<Column key = 'actions'
		                      header = {intl.formatMessage({ id: "DOMAINS.TESTS.TABLE.COLUMN.HEADER.ACTIONS",
			                      defaultMessage: 'Actions' })}
		                      body = {testActionsBodyTemplate}
		                      style = {{width: '25%'}}
		                      className = 'p-text-right'
		/>);
	}

	let display = <Card className = "border-0 rounded-0">
		<Card.Body className='p-0'>

			{props.testError && <Alert variant = "filled" severity = "error"
				onClose={() => props.nullifyError()}>
				{props.testError}
			</Alert> }
			<DataTable value = {props.tests}
			           paginator className="p-datatable-striped"
			           paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
			           currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
			           rows = {20} rowsPerPageOptions = {[20, 50, 100, 500, 1000]}
					   emptyMessage = {intl.formatMessage({
						   id: "DOMAINS.NO.TESTS", defaultMessage: 'No Tests Exists For Domain'
					   })}
			>
				{columns2}
			</DataTable>
			<DeletionConfirmationDialog open={open} closeDialog={handleClose}
										message={<FormattedMessage id="DOMAINS.TESTS.TABLE.DIALOG.REMOVE.TEST" defaultMessage='Remove Test'/> }
										name={test ? `${test.label}` : ''}
										deleteAction={() => {
											handleClose();
											props.deleteTest(test.id);
										}}
			/>
		</Card.Body>
	</Card>;

	return display;
}

export default TestsDisplay;