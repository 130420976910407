import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

import CountriesList from "../../utility/CountriesList";
import {changeText, selectElement} from "./Shared";
import NormalUserAddressFormJSX from "./NormalUserAddressFormJSX";


const NormalUserAddressForm = props => {

	const intl = useIntl();

	useEffect(() => {
		if (props.includeAddress && route.value !== props.route) {
			// console.log('inside useEffect route.value !== props.route');
			let updatedElement = null;
			if(props.loggedInUser !== null){
				// let updatedElement = {...streetNumber};
				// updatedElement.value = props.streetNumber;
				// setStreetNumber(updatedElement);

				updatedElement = {...route};
				updatedElement.value = props.route;
				setRoute(updatedElement);

				updatedElement = {...locality};
				updatedElement.value = props.locality;
				setLocality(updatedElement);

				updatedElement = {...postalCode};
				updatedElement.value = props.postalCode;
				setPostalCode(updatedElement);

				updatedElement = {...state};
				updatedElement.value = props.state;
				setState(updatedElement);
			}

			updatedElement = {...country};
			updatedElement.selected = props.country;
			setCountry(updatedElement);
		}

	}, [props.route]);

	// Address
	// const [streetNumber, setStreetNumber] = useState({
	// 	value: props.streetNumber,
	// 	validation: {
	// 		required: true,
	// 		isNumeric: true,
	// 		minLength: 1,
	// 		maxLength: 3
	// 	},
	// 	valid: true,
	// 	validationMessage: null
	// });

	const [route, setRoute] = useState({
		value: props.route,
		validation: {
			required: props.completeInformationMsg ? true : false,
			minLength: props.completeInformationMsg ? 0 : 2,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [locality, setLocality] = useState({
		value: props.locality,
		validation: {
			required: props.completeInformationMsg ? true : false,
			minLength: props.completeInformationMsg ? 0 : 2,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [postalCode, setPostalCode] = useState({
		value: props.postalCode,
		validation: {
			required: props.completeInformationMsg ? true : false,
			minLength: props.completeInformationMsg ? 0 : 2,
			maxLength: 11,
			isNumeric: true
		},
		valid: true,
		validationMessage: null
	});

	const [state, setState] = useState({
		value: props.state,
		validation: {
			// required: props.completeInformationMsg ? true : false,
			// minLength: props.completeInformationMsg ? 0 : 2,
			maxLength: 255
		},
		valid: true,
		validationMessage: null
	});

	const [country, setCountry] = useState({
		selected: props.country,
		list: CountriesList,
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null
	});

	// const streetNumberChangeHandler = (event) => {
	// 	const updatedStreetNumber = changeText(streetNumber, event.target.value, 'streetNumber', intl);
	// 	setStreetNumber(updatedStreetNumber);
	// }

	const routeChangeHandler = (event) => {
		const updatedRoute = changeText(route, event.target.value, 'route', intl);
		setRoute(updatedRoute);
	}

	const localityChangeHandler = (event) => {
		const updatedLocality = changeText(locality, event.target.value, 'locality', intl);
		setLocality(updatedLocality);
	}

	const postalCodeChangeHandler = (event) => {
		const updatedPostalCode = changeText(postalCode, event.target.value, 'postalCode', intl);
		setPostalCode(updatedPostalCode);
	}

	const stateChangeHandler = (event) => {
		const updatedState = changeText(state, event.target.value, 'state', intl);
		setState(updatedState);
	}

	const countrySelectHandler = (event) => {
		const updatedCountry = selectElement(country, event.target.value, 'country', 'selected', intl);
		setCountry(updatedCountry);
		// onblur
		syncCountry(event.target.value);
	};

	const syncValueWithParentContainer = (element, role) => {
		switch (element) {
			// case 'streetNumber':
			// 	props.setStreetNumber(streetNumber.value);
			// 	break;
			case 'route':
				props.setRoute(route.value);
				break;
			case 'locality':
				props.setLocality(locality.value);
				break;
			case 'postalCode':
				props.setPostalCode(postalCode.value);
				break;
			case 'state':
				props.setState(state.value);
				break;
		}
	}

	const syncCountry = (country) => {
		props.setCountry(country);
	}

	const checkAllInputsValidity = () => {
		let allValid = true;

		if(props.loggedInUser !== null){
			// const updatedStreetNumber = changeText(streetNumber, streetNumber.value, 'streetNumber', intl);
			// setStreetNumber(updatedStreetNumber);
			// allValid = allValid && updatedStreetNumber.valid;

			const updatedRoute = changeText(route, route.value, 'route', intl);
			setRoute(updatedRoute);
			allValid = allValid && updatedRoute.valid;

			const updatedLocality = changeText(locality, locality.value, 'locality', intl);
			setLocality(updatedLocality);
			allValid = allValid && updatedLocality.valid;

			const updatedPostalCode = changeText(postalCode, postalCode.value, 'postalCode', intl);
			setPostalCode(updatedPostalCode);
			allValid = allValid && updatedPostalCode.valid;

			const updatedState = changeText(state, state.value, 'state', intl);
			setState(updatedState);
			allValid = allValid && updatedState.valid;
		}

		const updatedCountry = selectElement(country, country.selected, 'country', 'selected', intl);
		setCountry(updatedCountry);
		allValid = allValid && updatedCountry.valid;


		return allValid;
	}
	props.registerValidateNormalUserAddressFormCallback(checkAllInputsValidity);

	return <NormalUserAddressFormJSX loggedInUser={props.loggedInUser}
		 							 // streetNumber = {streetNumber}
	                                 // streetNumberChangeHandler = {streetNumberChangeHandler}
	                                 route = {route}
	                                 routeChangeHandler = {routeChangeHandler}
	                                 locality = {locality}
	                                 localityChangeHandler = {localityChangeHandler}
	                                 postalCode = {postalCode}
	                                 postalCodeChangeHandler = {postalCodeChangeHandler}
	                                 state = {state}
	                                 stateChangeHandler = {stateChangeHandler}
	                                 country = {country}
	                                 countrySelectHandler = {countrySelectHandler}
	                                 syncValueWithParentContainer = {syncValueWithParentContainer}
	/>

}

export default NormalUserAddressForm;