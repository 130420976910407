import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import {FormattedMessage} from "react-intl";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";

import AddIteratioToSprintForm from "../../containers/Sprint/AddIteratioToSprintForm";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const SprintIterationActions = props => {
	const classes = useStyles();

	const [maximumNumberOfIterationsReached, setMaximumNumberOfIterationsReached] = useState(false);

	return (
			<div className = {`bg-white p-1 text-center ${classes.root}`}>

				<AddIteratioToSprintForm {...props}/>

				{props.canAdd === true && <Button variant = "contained"
						size = 'small'
						startIcon = {<AddIcon/>}
						onClick = {() => {


							const names = [];
							if(props.sprint && props.sprint.iterations){
								props.sprint.iterations.forEach((it) => {
									names.push(it.label);
								})
							}
							let iterationLabel = 'Iteration ' + Math.floor(Math.random() * 1000).toString();
							let numberOfTries = 0;
							while (names.includes(iterationLabel)) {
								if(numberOfTries === 999){
									setMaximumNumberOfIterationsReached(true);
									break;
								}
								iterationLabel = 'Iteration ' + Math.floor(Math.random() * 1000).toString();
								numberOfTries++;
							}

							if(numberOfTries === 999){
								return;
							}

							let newIteration = {
								browser: props.project.browser,
								includeAllSuites: true,
								label: iterationLabel,
								sprintId: props.sprint.id
							};
							// update ui: don't because id is undefined
							// call saga
							// const existingIterations = [...props.iterations];
							// existingIterations.push(newIteration);
							// props.setIterations(existingIterations);
							props.createAndAddIterationToSprint(props.sprint.id, newIteration);
						}}
						className = ''>
					<FormattedMessage id = "SPRINT.ITERATION.FORM.BUTTON.ADD.EXECUTION" defaultMessage = 'Iteration'/>
				</Button> }

				{maximumNumberOfIterationsReached === true && <Alert elevation = {3} variant = "standard" severity = "error" >
					<FormattedMessage id = "SPRINT.ITERATION.MAX.REACHED"
									  defaultMessage = 'Maximum number of automatic iterations reached, create one manually or move to new sprint'/>
				</Alert>}

			</div>
	)
}

const mapStateToProps = state => {
	return {
		canAdd: state.iteration.canAdd,
		// canAddLoading: state.iteration.canAddLoading,
		// canAddError: state.iteration.canAddError,
	};
};

export default connect(mapStateToProps, null)(SprintIterationActions);