import React from "react";
import ImportExport from "@material-ui/icons/ImportExport";
import {Button, Input} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

import {componentsValues} from "../../components/Instruction/ActionTypes";
import * as Action from "./Actions";

const ImportTest = props => {

	const importTestFile = (event) => {

		const file = event.target.files[0];

		let reader = new FileReader();
		reader.readAsText(file);

		reader.onload = () => {
			const result = reader.result.toString();
			const json = JSON.parse(result);
			let description = json.cases[0].description;

			const instructions = fixIndexAndDragAndDropInstruction(json.cases[0].instructions);
			const testAsserts = json.cases[0].testAsserts;

			let newTest = {
				description: {value: description},
				instructions:{value: instructions},
				testAsserts: {value: testAsserts}
			}
			if(json.cases[0].description && json.cases[0].description !== ''){
				newTest = {
					instructions:{value: instructions},
					testAsserts: {value: testAsserts}
				}
			}
			props.updateBulk(newTest);
			props.setInstructions(instructions);
			props.setAsserts(testAsserts);
		};
	}

	const fixIndexAndDragAndDropInstruction = (instructions) => {
		const updatedInstructions = [];
		let index = 0;
		for(let inst of instructions){
			const updatedInstruction = {...inst};
			// set index
			updatedInstruction.index = index++;
			// fix drag and drop
			if(inst.action === Action.DRAG_AND_DROP){
				if(!inst.componentTo){
					const splitMe = inst.typeValue;
					const index = splitMe.indexOf('=');
					const typeValue = splitMe.substring(index + 1);
					const componentTo = splitMe.substring(0, index);
					// check if componentTo is valid
					if(componentsValues.indexOf(componentTo.toUpperCase()) > -1){
						updatedInstruction.typeValue = typeValue;
						updatedInstruction.componentTo = componentTo.toUpperCase();
					}
				}
			}
			updatedInstructions.push(updatedInstruction);
		}
		return updatedInstructions;
	}

	return (
		<Button
			component="label"
			variant = "contained"
		        size = 'small'
		        startIcon={<ImportExport/>}
		        className = ''>
			<FormattedMessage id="TEST.ACTIONS.IMPORT"
			                  defaultMessage='Import'/>
			<Input type = 'file'
			       id = 'import-test'
			       hidden = {true}
			       onChange = {(event) => importTestFile(event)}/>
		</Button>
	);
}

export default ImportTest;
