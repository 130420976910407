import {makeStyles} from "@material-ui/styles";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	buttons: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));
// const SITE_KEY = "6Le-4T4eAAAAAPPzRafq6_bVKfNXSVvYh_iUd9_9";

const RegistrationActions = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [registrationSnackbarOpen, setRegistrationSnackbarOpen] = useState(false);
	const [registrationSnackbarMessage, setRegistrationSnackbarMessage] = useState('');

	useEffect(() => {
		if(props.registrationLoading === true){
			showRegistrationSnackbar(intl.formatMessage({ id: "REGISTRATION.SNACKBAR.IN.PROGRESS",
				defaultMessage: 'Registration in progress..'}));
		}
		if (!props.registrationLoading && registrationSnackbarOpen) {
			showRegistrationSnackbar(intl.formatMessage({ id: "REGISTRATION.SNACKBAR.COMPLETED",
				defaultMessage: 'Registration completed'}));
		}
	}, [props.registrationLoading]);

	const showRegistrationSnackbar = (message) => {
		setRegistrationSnackbarOpen(true);
		setRegistrationSnackbarMessage(message);
	}

	const hideRegistrationSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setRegistrationSnackbarOpen(false);
	}

	return <>
		<div className = "form-group d-flex flex-wrap flex-center mb-0">
	        <button
				className = "btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 g-recaptcha"
				data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} data-callback='signupHandler'
				hidden={props.registrationLoading}>
				<span><FormattedMessage id='REGISTRATION.BUTTON.SUBMIT'
				                        defaultMessage='Submit'/></span>
			</button>

			<Link to = "/auth/login">
				<button  type = "button"
					className = "btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
					<FormattedMessage id='REGISTRATION.BUTTON.CANCEL'
					                  defaultMessage='Cancel'/>
				</button>
			</Link>
		</div>


		{/*<div className={classes.buttons}>*/}
		{/*	<Button variant = "contained" type = "submit" className='mr-6'>*/}
		{/*		<FormattedMessage id='REGISTRATION.BUTTON.SUBMIT'*/}
		{/*		                  defaultMessage='Submit'/>*/}
		{/*	</Button>*/}
		{/*	<Link to = "/auth/login">*/}
		{/*		<button  type = "button"*/}
		{/*		         className = "btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">*/}
		{/*			<FormattedMessage id='REGISTRATION.BUTTON.CANCEL'*/}
		{/*			                  defaultMessage='Cancel'/>*/}
		{/*		</button>*/}
		{/*	</Link>*/}
		{/*</div>*/}

		<Snackbar
			anchorOrigin = {{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open = {registrationSnackbarOpen}
			autoHideDuration = {2000}
			onClose = {hideRegistrationSnackbar}
			message = {registrationSnackbarMessage}
		>
			<Alert onClose = {hideRegistrationSnackbar} severity = "success">
				{registrationSnackbarMessage}
			</Alert>
		</Snackbar>
	</>

}

export default RegistrationActions;