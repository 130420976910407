import React, {useState} from "react";
import {AppBar, Button, Dialog, Slide, Toolbar, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";

import DeviceGroupManagement from "../../containers/Project/Device/DeviceGroupManagement";

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction = "up" ref = {ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const DeviceGroupManagementDialog = props => {
	const intl = useIntl();
	const classes = useStyles();

	const [showDeviceGroupDialog, setShowDeviceGroupDialog] = useState(false);

	const hideDeviceGroupDialog = () => {
		setShowDeviceGroupDialog(false);
	}

	return <>
		<Button variant='contained' color='primary' size='large' onClick={() => setShowDeviceGroupDialog(true)}>
			<FormattedMessage id = "DEVICE.GROUP.MANAGEMENT.ENTRY.BUTTON" defaultMessage = 'Manage Device Groups'/>
		</Button>

		<Dialog fullScreen open = {showDeviceGroupDialog} onClose = {hideDeviceGroupDialog} TransitionComponent = {Transition} style={{zIndex: 900}}>
			<AppBar className = {classes.appBar}>
				<Toolbar>
					<IconButton edge = "start" color = "inherit" onClick = {hideDeviceGroupDialog} aria-label = "close">
						<CloseIcon/>
					</IconButton>
					<Typography variant = "h6" className = {classes.title}>
						<FormattedMessage id = "DEVICE.GROUP.MANAGEMENT.DIALOG.TITLE" defaultMessage = 'Manage Device Groups'/>
					</Typography>
					{/*<Button autoFocus color = "inherit" onClick = {initiateUpdate}>*/}
					{/*	<FormattedMessage id="BUTTON.SAVE" defaultMessage='Save'/>*/}
					{/*</Button>*/}
				</Toolbar>
			</AppBar>
			<DeviceGroupManagement />
		</Dialog>
	</>
}

export default DeviceGroupManagementDialog;