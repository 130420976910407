import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";

import * as actions from "../../../store/actions";
import {selectAddRestRequisitesAction} from "../Shared";
import {checkArraySelectionValidity} from "../../../utility/utility";
import {UINames} from "../Messages";
import SuiteRequisitesAdditionForm from "../../../components/Suite/SuiteRequisitesAdditionForm";

const RequisitesAddition = props => {
	const intl = useIntl();

	const [addRequests, setAddRequests] = useState({
		selected: [],
		selectedNoIndex: [],
		validation: {
			required: true
		},
		valid: true,
		validationMessage: null,
		requests: []
	});

	useEffect(() => {
		if(props.project){
			props.onLoadProjectRestRequests(props.project.id);
		}
	}, [props.project]);

	useEffect(() => {
		if(props.restRequests){
			const updatedAddRequests = {...addRequests};
			let allRequests = [...props.restRequests];
			updatedAddRequests.requests = allRequests;
			setAddRequests(updatedAddRequests);
		}
	}, [props.restRequests]);

	// TODO test this selection when the backend is fixed
	useEffect(() => {
		if(Array.isArray(props.requisites)){
			const selectedRequestsByDefault = [];
			const selectedRequestsByDefaultNoIndex = [];
			for(const selectRequest of props.requisites){   // props.requisites is the list of addedRequests in the suite.
				// selectedTestsByDefault[selectRequest.testCase.id] = {checked: true, partialChecked: false};
				selectedRequestsByDefault.push(selectRequest);
				const selectRequestCopy = {...selectRequest}
				delete selectRequestCopy.index;
				selectedRequestsByDefaultNoIndex.push(selectRequestCopy);
			}
			const updatedAddRequests = {...addRequests};
			updatedAddRequests.selected = selectedRequestsByDefault;
			updatedAddRequests.selectedNoIndex = selectedRequestsByDefaultNoIndex;
			setAddRequests(updatedAddRequests);
		}
	}, [props.requisites]);

	const addRequestsSelectHandler = (value) => {
		const updatedAddRequests = selectAddRestRequisitesAction(addRequests, value, intl);
		setAddRequests(updatedAddRequests);
	};

	const checkValidityForAll = () => {
		let allValid = true;

		const updatedAddRequest = {...addRequests};
		let [valid, message] = checkArraySelectionValidity(updatedAddRequest, 'selectedNoIndex', UINames('rest-request', intl), intl);
		updatedAddRequest.valid = valid;
		updatedAddRequest.validationMessage = message;
		setAddRequests(updatedAddRequest);
		if (!valid) {
			allValid = false;
		}
		return allValid;
	}

	const submitAddRequestsHandler = () => {

		// validate all
		if (!checkValidityForAll()) {
			return false;
		}

		// let existingRequisites = [...props.requisites];
		let existingRequisites = [];
		let newRequests = [...addRequests.selectedNoIndex];
		let index = 0;
		newRequests = newRequests.map(request => {
			return {...request, index: index++};
		});
		existingRequisites.push(...newRequests);
		props.setRequisites(existingRequisites);
		props.updateRequisites(existingRequisites);
		clear();
		return true;
	}

	const clear = () => {
		// const updatedAddRequest = {...addRequests};
		// updatedAddRequest.selected = [];
		// setAddRequests(updatedAddRequest);
		props.onLoadProjectRestRequests(props.project.id);
	}

	const [selectedMethods, setSelectedMethods] = useState(null);

	const dt = useRef(null);

	const onMethodChange = (e) => {
		// console.log('onMethodChange event:', e);
		dt.current.filter(e.value, 'method', 'in');
		setSelectedMethods(e.value);
	}

	// console.log('addRequests', addRequests);
	return <SuiteRequisitesAdditionForm addRequests = {addRequests}
	                                    addRequestsSelectHandler = {addRequestsSelectHandler}
	                                    submitAddRequestsHandler = {submitAddRequestsHandler}
	                                    clear = {clear}
	                                    type={props.type}

										dt={dt}
										selectedMethods={selectedMethods}
										onMethodChange={onMethodChange}
	/>

}

const mapStateToProps = state => {
	return {
		// restRequestsNames: state.restRequest.restRequestsNames,
		restRequests: state.restRequest.listProjectRestRequests,
		project: state.project.project,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		// onLoadRestRequestNames: (id) => dispatch(actions.loadRestRequestsNames(id)),
		onLoadProjectRestRequests: (id) => dispatch(actions.loadProjectRestRequests(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequisitesAddition);